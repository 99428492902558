import { RadioProgramItem, DvrRadioPlayer } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"
import { ButtonRnederer } from "../../../radioWidgets/floatingPlayer/podcastPlayerButton";
import Slider from "react-slick"
import { useState, useEffect } from "react"

interface NasRadioHomepageTeaserProps {
    isMobileWeb: boolean
    startingIndex: number
    radioScheduleList: RadioProgramItem[]
    liveBroadCastSource: string
}

export const NasRadioHomepageTeaser = (props: NasRadioHomepageTeaserProps) => {
    const { isMobileWeb, startingIndex, radioScheduleList, liveBroadCastSource } = props
    const currentShow = radioScheduleList[startingIndex]
    const [isTitleOverFlowing, setIsTitleOverFlowing] = useState(null)
    const noShowsText = "لا توجد خطة للعب"

    useEffect(() => {
        getTitleOverflowStatus()
    }, [currentShow])

    const getTitleOverflowStatus = () => {
        const titleElement = document.querySelector(".homepage-teaser-show-title") as HTMLElement
        const wrapperElement = document.querySelector(".homepage-teaser-show-title-wrapper") as HTMLElement
        let titleOverflow = false
        if (titleElement && wrapperElement) {
            titleOverflow = titleElement.offsetWidth > wrapperElement.offsetWidth
        }
        setIsTitleOverFlowing(titleOverflow)
    }


    if (radioScheduleList.length === 0) {
        return (
            <div className="nas-radio-homepage-teaser-no-shows">
                <div className="no-shows-image"></div>
                <div className="no-shows-text">لا توجد برامج في الجدول الزمني</div>
            </div>
        )
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: currentShow.title.length * 200,
        autoplaySpeed: 0,
        rtl: true,
        arrows: false,
        cssEase: "linear"
    }

    return (
        <div className="nas-radio-homepage-teaser">
            <DvrRadioPlayer
                title={currentShow.title}
                site={"radionas"}
                isSimplePlayer={true}
                isMobileWeb={isMobileWeb}
                liveBroadCastDVRSource={""}
                startingIndex={startingIndex}
                radioScheduleList={radioScheduleList}
                liveBroadCastSource={liveBroadCastSource}
                playerComponentaRenderer={(props) => (<ButtonRnederer {...props} className={"audio-play-button"} />)}
            />

            <img src={currentShow.image} alt={currentShow.title} />

            <div className="show-details">
                <div className="homepage-teaser-title-content">
                    <div className="homepage-teaser-show-title-wrapper">
                        {isTitleOverFlowing ?
                            <>
                                <div className="fadder-right"></div>
                                <div className="slider-wrapper">
                                    <Slider {...settings}>
                                        <div className={"homepage-teaser-show-title"} dangerouslySetInnerHTML={{ __html: currentShow.title || noShowsText }}></div>
                                        <div className={"homepage-teaser-show-title"} dangerouslySetInnerHTML={{ __html: currentShow.title || noShowsText }}></div>
                                    </Slider>
                                </div>
                                <div className="fadder-left"></div>
                            </> :
                            <div className={"homepage-teaser-show-title"} dangerouslySetInnerHTML={{ __html: currentShow.title || noShowsText }}></div>
                        }
                    </div>
                </div>

                <div className="show-info">
                    <div className="show-anchor">{currentShow.anchor}</div>

                    <div className="show-hours">{currentShow.startTime} - {currentShow.endTime}</div>
                </div>
            </div>
        </div>
    )
}

NasRadioHomepageTeaser.siteScriptName = "NasRadioHomepageTeaser"