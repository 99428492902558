import {getTranslation} from "../../redMailTranslations";

interface ExtraDetailsYnetnewsProps {
    photographer: string
    sender: string
    mail: string
    phoneNumber: string
    validation: { mail: boolean, phoneNumber: boolean }
    onChange: (e: any) => void
    extraDetailsData: any
    lang: string
}

export class ExtraDetails extends React.Component<ExtraDetailsYnetnewsProps, State> {

    render() {
        const { photographer, sender, mail, phoneNumber, onChange, validation, extraDetailsData: { title, subTitle, mailPattern }, lang } = this.props

        return (
            <div className="RedMailExtraDetails">
                {title && <div className="ExtraDetailsTitle">{title}</div>}
                {subTitle && <div className="ExtraDetailsSubTitle">{subTitle}</div>}

                <div className="inputFieldWrapper">
                    <div className="inputField">
                        <input name="photographer" dir="ltr" value={photographer} onChange={(e) => onChange(e)} type="text" id="photographer" placeholder={getTranslation(lang, "add credit")} ></input>
                        <p className="title">{getTranslation(lang, "photo credit")}</p>
                    </div>

                    <div className="inputField">
                        <input name="sender" dir="ltr" value={sender} onChange={(e) => onChange(e)} type="text" id="sender" placeholder={getTranslation(lang, "type in your name")} ></input>
                        <p className="title">{getTranslation(lang, "sender name")}</p>
                    </div>


                    <div className="inputField">
                        <input name="mail" dir="ltr" value={mail} onChange={(e) => onChange(e)} type="email" id="mail" placeholder={getTranslation(lang, "type in your e-mail")} ></input>
                        <p className="title">{getTranslation(lang, "e-mail")}</p>
                        {(mail && !validation.mail) && <p className="redMail-error-validation">{mailPattern}</p>}
                    </div>

                    <div className="inputField">
                        <input name="phoneNumber" dir="ltr" value={phoneNumber} onChange={(e) => onChange(e)} type="text" id="phoneNumber" placeholder={getTranslation(lang, "type in your number")} ></input>
                        <p className="title">{getTranslation(lang, "phone number")}</p>
                        {(phoneNumber && !validation.phoneNumber) && <p className="redMail-error-validation">{getTranslation(lang, "phone validation text")}</p>}
                    </div>

                </div>
            </div>
        )
    }
}