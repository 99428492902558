import { CalcalistStockRecommendData, stockJson } from "../data/calcalistStockRecommendData"
import { NoData } from "../../../../../commonDataObjects/noData"

interface CalcalistStockRecommendationPointsProps {
    stock: stockJson
}

interface CalcalistStockRecommendationPointsState {
    selectedPoint: string
}

export class CalcalistStockRecommendationPoints extends React.Component<CalcalistStockRecommendationPointsProps, CalcalistStockRecommendationPointsState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedPoint: "",
        }
    }

    public static siteScriptName = "CalcalistStockRecommendationPoints";
    openPopup = (link, name, index) => {
        this.setState({ selectedPoint: index })
        window.open(link, `המלצה על מניית ${name}`, 'width=448,height=516')
    }

    getPoint = (color: string, urlType: string, text: string) => {
        const { stock } = this.props;
        const { selectedPoint } = this.state
        if (!stock) {
            return null
        }
        let voteLink = `https://www.calcalist.co.il/Ext/Comp/I-Invest/StockQuote/CdaStocks_PopUp/0,15255,L-0-${stock.id}-${urlType},00.html`
        return (
            <div className="item">
                <div className="item-point" style={{ border: `1px solid ${color}`, backgroundColor: selectedPoint === urlType ? color : 'white' }} onClick={() => { this.openPopup(voteLink, stock.name, urlType) }}></div>
                <div className="item-text" style={{ color: color }}>{text}</div>
            </div>
        )
    }


    public render() {
        const ratingUrlsPrefixes = [
            { urlType: "0", color: "#446a00", text: "מכירה חזקה" },
            { urlType: "1", color: "#749b24", text: "מכירה" },
            { urlType: "2", color: "#56564c", text: "המתן" },
            { urlType: "3", color: "#d93438", text: "קניה" },
            { urlType: "4", color: "#8c0015", text: "קניה חזקה" },
        ]

        const points = ratingUrlsPrefixes.map(item => {
            return (
                this.getPoint(item.color, item.urlType, item.text)
            )
        })
        return (
            <div className="points-box">
                {points}
                <div className="items-line" />
            </div>
        )
    }
}