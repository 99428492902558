import * as throttle from "lodash/throttle"
import { YplusMutamSideMenu } from "./yplusMutamSideMenu"
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { YPlusHeaderNavItems } from "../premiumArticleTitleImageComponenta/premiumArticleTitleImageMutamComponenta"
import { YnetPlusUserMenuItems } from "../../../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";

interface PremiumHeaderMutamProps {
    mainLogoLink: string
    parentSiteLink: string
    menuItems: YPlusHeaderNavItems[]
    siteId: string
    categoryId: string
    isMenuVisible: boolean
    searchPageLink: string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}

interface PremiumHeaderMutamState {
    isSticky: boolean
    isUserLoggedIn: boolean
}

export class PremiumMutamHeader extends React.Component<PremiumHeaderMutamProps, PremiumHeaderMutamState>{
    constructor() {
        super();
        this.state = {
            isSticky: false,
            isUserLoggedIn: false
        }
    }

    public static siteScriptName = "PremiumMutamHeader";

    private activateStickyHeader() {
        const headerPos = document.querySelector(".PremiumArticleTitleImageMutamComponenta").getBoundingClientRect().top
        if (headerPos < 0) {
            this.setState({ isSticky: true })
        } else {
            this.setState({ isSticky: false })
        }
    }

    private throttledScrollHandler = () => throttle(() => this.activateStickyHeader(), 200)

    private checkUserLoginStatus = () => {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLoggedIn: true })
        }
    }

    public componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
        this.activateStickyHeader()
        this.checkUserLoginStatus()
    }

    public componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }

    render() {
        const { mainLogoLink, parentSiteLink, menuItems, siteId, categoryId, isMenuVisible, searchPageLink, ynetPlusMenuItems } = this.props
        const { isSticky, isUserLoggedIn } = this.state

        return (
            <div className={`PremiumMutamHeader ${isSticky ? "sticky" : ""}`}>
                <YplusMutamSideMenu
                    menuItems={menuItems}
                    siteId={siteId}
                    categoryId={categoryId}
                    isMenuVisible={isMenuVisible}
                    searchPageLink={searchPageLink}
                    isUserLoggedIn={isUserLoggedIn}
                    mainLogoLink={mainLogoLink}
                    parentSiteLink={parentSiteLink}
                    ynetPlusMenuItems={ynetPlusMenuItems}
                />

                <SimpleLinkRenderer href={mainLogoLink}  >
                    <div className="yplusLogo"></div>
                </SimpleLinkRenderer>

                <a className="goBackArrow" href={"javascript:history.back()"}></a>
            </div>
        )
    }
}