import { SiteItemData, SiteVideoData } from "../../../../interfaces";

interface VideoGalleryVerticalItemMutamProps {
    videoItem: SiteItemData
    videoIndex: number
    lastIntersectedPromoIndex: number
    onOpenVideoGalleryView: (e) => void
    onVideoEnded: () => void
    getItemIntoViewIndex: (itemIntoViewIndex: number) => void
    onLastIntersectingPromoCHange: (lastPromoIndex: number) => void
    pushGA4Event: (action: string, isFeedDisplay: boolean) => void
}

interface VideoGalleryVerticalItemMutamState {
    isItemIntoView: boolean
}

export class DynamicVideoGalleryVerticalItemMutam extends React.Component<VideoGalleryVerticalItemMutamProps, VideoGalleryVerticalItemMutamState>{
    constructor(props) {
        super(props);
        this.state = {
            isItemIntoView: false,
        }
    }

    private videoRef;
    private slotRef;
    private intersectionObserver: IntersectionObserver;

    componentDidMount() {
        this.initObserver();
    }

    componentWillUnmount() {
        this.intersectionObserver && this.intersectionObserver.disconnect();
    }

    private onVideoIntersectViewport = () => {
        const { getItemIntoViewIndex, videoIndex, onLastIntersectingPromoCHange } = this.props;
        this.setState({ isItemIntoView: true });
        onLastIntersectingPromoCHange(videoIndex)
        getItemIntoViewIndex(videoIndex)
        if (this.videoRef) this.videoRef.play();
    }

    private onVideoLeaveViewport = () => {
        this.setState({ isItemIntoView: false });
        if (this.videoRef) this.videoRef.pause();
    }

    private secondsToMinutesHandler = (seconds: number) => {
        const minutes = Math.floor(seconds / 60).toString().padStart(2, "0");
        const newSeconds = (seconds % 60).toString().padStart(2, "0");
        return `${minutes}:${newSeconds}`;
    }

    private initObserver = () => {
        this.intersectionObserver = new IntersectionObserver(entry => {
            entry[0].isIntersecting ? this.onVideoIntersectViewport() : this.onVideoLeaveViewport();
        }, { threshold: 0.8 })
        this.intersectionObserver.observe(this.slotRef);
    }

    public render() {
        const { isItemIntoView } = this.state;
        const { videoItem, onOpenVideoGalleryView, videoIndex, onVideoEnded } = this.props;
        const { media, title } = videoItem;
        const { duration, poster, promoPath } = media as SiteVideoData;
        const shouldDisplayPromo = isItemIntoView && promoPath;
        const slotClasses = `slotView ${shouldDisplayPromo ? "active" : ""}`;

        return (
            <div
                className={slotClasses}
                onClick={() => onOpenVideoGalleryView(videoIndex)}
                ref={ref => this.slotRef = ref}
            >
                {shouldDisplayPromo ?
                    <video
                        autoPlay={true}
                        loop={false}
                        ref={ref => this.videoRef = ref}
                        muted
                        poster={poster}
                        onEnded={onVideoEnded}
                        className="promoVideo" src={promoPath}
                        playsInline
                        webkit-playsInline>
                    </video>
                    :
                    <img
                        src={poster}
                        alt="" />
                }
                <div className="contentDiv">
                    <div className="videoTitle" dangerouslySetInnerHTML={{ __html: title }} />
                    <div className="videoDuration"><span className="durationLogo"></span> <span>{this.secondsToMinutesHandler(Math.round(duration))}</span></div>
                </div>
            </div>
        )
    }
}
