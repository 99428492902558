require('./site_tickerPopUp.less')

import { ArticleShareButtons } from "../../../siteWidgets/socialShareButtons/ArticleShareButtons/ArticleShareButtons"
import { SiteTickerData } from "./tickerComponenta"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext"
import { PageComponentaContext } from "widgets/interfaces"
import { getImgTitle, getImgAlt, isAriaHidden } from "../../../widgetsUtils"

interface TickerPopUpContentProps {
    item: SiteTickerData
    onClickNext: () => void
    onClickBack: () => void
    selectedIndex: number
    canNavigateBack: boolean
    canNavigateNext: boolean
    isEnglish: boolean
}

@injectPageComponentaContext()
export class TickerPopUpContent extends React.Component<TickerPopUpContentProps & Partial<PageComponentaContext>, {}>{

    private popUpContainerDiv: HTMLDivElement;


    private textToShow = (item: SiteTickerData) => {
        switch (item.flashLinkType) {
            case "flash": return item.plainText
            case "text": return item.flashText;
            default: return null;
        }
    }

    render() {
        const { item, selectedIndex, onClickNext, onClickBack, getImage, canNavigateBack, canNavigateNext, isEnglish } = this.props
        let media;
        const image = item.itemImg || null
        const arrowNext = isEnglish ? "swipeRight" : "swipeLeft"
        const arrowPrev = isEnglish ? "swipeLeft" : "swipeRight"
        const titlePrev = isEnglish ? "Prev." : __("Prev. Flash")
        const titleNext = isEnglish ? "Next" : __("Next Flash")
        return (
            <div className="flashContainer">
                <div className="topArea">
                    <div className="titleArea">
                        <div className="flashTitle" dangerouslySetInnerHTML={{ __html: item.title }} />
                        <div className="flashDate">
                            {separateHourFromDateAndFormatToString(item.flashDate, { dateTimeSeperator: " | ", showDate: true, showHour: true })}
                        </div>

                    </div>
                    <ArticleShareButtons
                        url={item.link}
                        className="tickerShareBtns"
                        isCopyButtonVisible={item.flashLinkType === "flash" ? true : false}
                        isEnglish={isEnglish}
                        lang={isEnglish ? "en" : "he"}
                    />
                </div>
                <div className="Separator" />
                <div className="flashText">
                    <span>{item.flashText}</span>
                    {item.author !== "" && <span className="smallSpan">({item.author})</span>}
                    {!isEnglish && <span className="linkToMail"><a href="//redmail.ynet.co.il" target="_blank" title="מצאתם טעות במבזק? כתבו לנו" >מצאתם טעות במבזק? כתבו לנו</a></span>}
                </div>
                <div className="flashFooter">
                    {item && <div className="itemArrows">
                        {canNavigateNext && <button className={`navigationArrow ${arrowNext}`}
                            title={titleNext}
                            onClick={e => onClickNext()}>{titleNext}</button>}

                        {canNavigateBack && <button className={`navigationArrow ${arrowPrev}`}
                            title={titlePrev}
                            onClick={e => onClickBack()}>{titlePrev}</button>}
                    </div>}
                </div>

            </div>)


    }
}
