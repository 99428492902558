import { IdGenerator } from "utils/idGenerator"
import { DataObjectBase } from "widgets/commonDataObjects/dataObjectBase"
import { registerDataClass } from "widgets/dataObjectFacrories"

@registerDataClass
export class LinkedItemVideoSettings extends DataObjectBase {
    get isVideoLightbox(): boolean {
        return this.data.isVideoLightbox
    }
    setIsVideoLightbox(isVideoLightbox: boolean) {
        return this.setData({ isVideoLightbox });
    }
    get hideVideoAds(): boolean {
        return this.data.hideVideoAds
    }
    setHideVideoAds(hideVideoAds: boolean) {
        return this.setData({ hideVideoAds });
    }
    get isLive(): boolean {
        return this.data.isLive
    }
    setIsLive(isLive: boolean) {
        return this.setData({ isLive });
    }
    protected createNewInstance(data: any) {
        return new LinkedItemVideoSettings(data) as this;
    }
    protected GetLinkedResourcesForCurrent() { return [] }

    protected getDefaultData() {
        return {
            isVideoLightbox: true,
            hideVideoAds: false,
            isLive: false
        }
    }
}

