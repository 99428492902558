import { SitePplusLoadMoreData } from "./pplusLoadMoreView"
import * as apiUrls from "config/apiUrls"
import { getImgAlt, isAriaHidden, getImgTitle } from "../../../../widgetsUtils"
import * as classNames from "classnames"
import { PageComponentaContext } from "widgets/interfaces"
import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { CategoryLabel } from "../../pplusCategoryLabel/pplusCategoryLabel";
import { DateDisplay } from "widgets/commonComponents/inline/dateDisplay/dateDisplay"
import { PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak";
import { PplusShareMenu } from "../../pplusShareMenu/pplusShareMenu"
import { RenderReactAtSite } from "../../../../commonComponents/inline/renderReactAtSite"
import { ImageSize } from "constants/imageSizes";
import { SiteSimpleImage } from "../../../../commonComponents/inline/siteSimpleImage"

interface PplusLoadMoreItemSlotProps {
    article: Partial<SitePplusLoadMoreData>
    isCategoryVisible?: boolean
    isMobileWeb?: boolean
}

@injectPageComponentaContext()
export class PplusLoadMoreItemSlot extends React.Component<PplusLoadMoreItemSlotProps & Partial<PageComponentaContext>, {}>{
    static defaultProps = { isCategoryVisible: true }

    public render() {
        const { article, isCategoryVisible, isMobileWeb } = this.props
        const { title, subTitle, author, promotionImageDetails, articleId, link, hasVideo, category, dateUpdatedOnSite, firstAuthorImage, premiumExpireDays } = article

        const headLineSeperator = category.categoryColor ? category.categoryColor : '#272727';

        return (
            <div className={classNames("slotView", "fadeInUp", { "emptySlot": subTitle.length === 0 && title.length === 0 })} >
                <div className="headlineArea" >
                    <div className="lineContainer">
                        <div className="rightStar" style={{ backgroundColor: headLineSeperator }}></div>
                        <div className="line" style={{ backgroundColor: headLineSeperator }}></div>
                        <div className="leftStar" style={{ backgroundColor: headLineSeperator }}></div>
                    </div>
                    <div className="slotTitle" >
                        <a href={link} target="blank" >
                            {title}
                        </a>
                    </div>
                    <div className="articleInfo">
                        <div className="author">
                            {firstAuthorImage &&
                                <div className="authorImg">
                                    <SiteSimpleImage src={firstAuthorImage} />
                                </div>
                            }
                            <div className="Details">
                                <div className="authorName">
                                    {author}
                                </div>
                                <div className="timeOfPublishing">
                                    <DateDisplay date={dateUpdatedOnSite} />
                                </div>
                            </div>
                        </div>
                        <div className="socialMedia">
                            <RenderReactAtSite>
                                <PplusShareMenu url={link} title={title} isMobileWeb={isMobileWeb} />
                            </RenderReactAtSite>
                        </div>
                    </div>
                </div>
                <div className="mediaArea">
                    {isCategoryVisible &&
                        <CategoryLabel category={category} defaultBgColor={category.categoryColor} id={`categoryLabel_${articleId}`} />}
                    <PayWallArctilceIcon
                        premiumExpireDays={premiumExpireDays}
                        dateUpdatedOnSite={dateUpdatedOnSite}
                        className="onImage"
                    />
                    <a href={link}>
                        <img
                            src={apiUrls.getImageUrl(promotionImageDetails, { imageSize: ImageSize.xLarge })}
                            width={"100%"}
                            height={"auto"}
                            alt={getImgAlt(promotionImageDetails)}
                            title={getImgTitle(promotionImageDetails)}
                            aria-hidden={isAriaHidden(promotionImageDetails)}
                        />
                    </a>
                    <SlotIconDisplay itemId={articleId} icon={"video"} iconClassName="big" isIconVisible={hasVideo} />
                </div>
            </div>
        )
    }
}