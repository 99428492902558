import * as axios from "axios"
import * as classNames from "classnames"
import { BreakingNewsArrows } from "./arrowsWithAnimation/arrows"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";

interface SiteBreakingNewsComponentaProps {
    componentaId: string
}

interface SiteBreakingNewsComponentaState {
    breakingNewsTitle: string
    isSticky: boolean
}

export class SiteBreakingNewsComponenta extends React.Component<SiteBreakingNewsComponentaProps, SiteBreakingNewsComponentaState>{
    public static siteScriptName = "SiteBreakingNewsComponenta";

    constructor(props) {
        super(props)
        this.state = {
            breakingNewsTitle: "",
            isSticky: false,
        }
    }

    private activateStickyHandler = () => {
        var breakingNewsElement = null;
        var topPos = null;
        document.addEventListener("scroll", (e) => {
            const { isSticky } = this.state
            breakingNewsElement = breakingNewsElement ? breakingNewsElement : document.getElementsByClassName('BreakingNewsComponenta')[0];
            topPos = breakingNewsElement && !topPos ? breakingNewsElement.getBoundingClientRect().top + window.scrollY : topPos
            if (window.scrollY + 5 > topPos) {
                if (!isSticky) {
                    this.setState({ isSticky: true })
                }
            }
            else {
                if (isSticky) {
                    this.setState({ isSticky: false })
                }
            }
        })
    }

    private getBreakingNews() {
        const { componentaId } = this.props
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/api/getBreakingNews/${componentaId}`)
            .then((res: any) => {
                this.setState({ breakingNewsTitle: res.data.breakingNewsText });
            })
    }


    componentDidMount() {
        this.activateStickyHandler()

        this.getBreakingNews()
        setInterval(() => {
            this.getBreakingNews()
        }, 60000);
    }

    render() {
        const { breakingNewsTitle, isSticky } = this.state
        const containerVisibilityStyle = breakingNewsTitle.length > 0 ? { "display": "flex", "marginBottom": "20px" } : { "display": "none", "marginBottom": "0px" }
        const containerClassName = classNames("BreakingNewsComponenta", { "isSticky": isSticky })
        return (
            <div className={containerClassName} style={containerVisibilityStyle}>
                <div className="breakingNewsTitle">
                    <span>{`${__("Breaking News")}`}</span>
                    <BreakingNewsArrows />
                </div>
                <div className="breakingNews"> <span> {breakingNewsTitle} </span></div>
                <div className="moreInfoText"><span>{__("More Info Soon")} </span></div>
            </div >

        )
    }
}
