import * as classNames from "classnames"
require('./m_toogleSwitch.less');

interface ToggleSwitchProps {
    checked: boolean
    onSwitchPressed: () => void
}

export class ToggleSwitch extends React.Component<ToggleSwitchProps, {}> {
    public render() {
        const { checked, onSwitchPressed } = this.props
        return (
            <button className="ToggleSwitch" onClick={onSwitchPressed}>
                <div className={classNames("toggleContainer", { 'active': checked })}>
                    <div className={classNames("toggleBtn", { 'active': checked })}></div>
                </div>
            </button>
        )
    }
}