import {getTranslation} from "../../redMailTranslations";

interface PopUpYnetnewsProps {
    onClose: () => void
    loading: boolean
    lang: string
}
export class PopUpYnetnews extends React.Component<PopUpYnetnewsProps, {}> {
    public render() {
        const { loading, lang } = this.props;
        if (loading) {
            return (
                <div className="redMailPopUp">
                    <div className="loading"></div>
                    <p className="title">{getTranslation(lang, "just a moment")}</p>
                </div>
            )
        } else {
            return (
                <div className="redMailPopUp">
                    <div className="finish"></div>
                    <p className="title">{getTranslation(lang, "the information was received")}</p>
                    <p className="subTitle">{getTranslation(lang, "thank you for sharing")}</p>
                    <button className="popUpButton"><a href="/">{getTranslation(lang, "homepage")}</a></button>
                </div>
            )
        }
    }

}



