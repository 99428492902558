import { SiteItemData, SiteVideoData } from "../../../../interfaces";
import { SiteVideoInline } from "../../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";

interface VideoVerticalFeedItemMutamProps {
    videoItem: SiteItemData
    initialFeedSlide: number
    feedActiveSlide: number
    feedIndex: number
    arrowDisplay: string
    domain: string
    index: number
    onCloseVideoGalleryView: () => void
    onNextSlide: () => void
}

interface VideoVerticalFeedItemMutamState {
    isSubtitleOpen: boolean
}

const DICT = {
    nextVideo: "إلى الفيديو التالي",
    fullArticle: "إلى المقال كاملا",
}

export class VideoVerticalFeedItemMutam extends React.Component<VideoVerticalFeedItemMutamProps, VideoVerticalFeedItemMutamState>{
    constructor(props) {
        super(props);
        this.state = {
            isSubtitleOpen: false,
        }
    }

    componentDidMount() {
        this.initializeEndedEventListeners();
    }

    componentWillUnmount() {
        this.removeEndedEventListeners();
    }

    private initializeEndedEventListeners() {
        setTimeout(() => {
            const videoElements = document.querySelectorAll(".radionasVideoGalleryVerticalMutamComponenta .feedDisplay .slick-slide .videoVerticalFeedItemMutam #player .fp-engine") as NodeListOf<HTMLVideoElement>;
            if (videoElements.length) {
                videoElements.forEach((element) => {
                    element.addEventListener("ended", this.handleVideoEnded);
                })
            };
        }, 600)
    }

    private removeEndedEventListeners() {
        const videoElements = document.querySelectorAll(".radionasVideoGalleryVerticalMutamComponenta .feedDisplay .slick-slide .videoVerticalFeedItemMutam #player .fp-engine") as NodeListOf<HTMLVideoElement>;
        if (videoElements.length) {
            videoElements.forEach((element) => {
                element.removeEventListener("ended", this.handleVideoEnded)
            })
        };
    }

    private handleVideoEnded = (e) => {
        e.target.currentTime = 0;
        e.target.click();
        this.props.onNextSlide();
    }

    private onSubtitleModeToggle = () => {
        this.setState({ isSubtitleOpen: !this.state.isSubtitleOpen });
    }

    private openShare = async () => {
        const { domain, videoItem: { media } } = this.props;
        const shareUrl = `${domain}/video/${(media as SiteVideoData).videoPageId}`;
        const shareData = { url: shareUrl };
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    private pushGA4Event = () => {
        if (typeof window == "undefined" || !window.dataLayer) return;
        const { index } = this.props;

        window.dataLayer.push({
            event: "content_click",
            componenta_name: "vertical_video",
            content_type: "componenta",
            position_in_component: index,
            click_text:"לכתבה המלאה"
        });
    };

    public render() {
        const { isSubtitleOpen } = this.state;
        const { videoItem, onCloseVideoGalleryView, arrowDisplay, onNextSlide } = this.props;
        const { media, title, subTitle, titleLink, titleTargetLink } = videoItem;
        const subtitleClass = `videoSubtitle ${isSubtitleOpen ? "" : "close"}`;
        const feedItemClass = `videoVerticalFeedItemMutam ${isSubtitleOpen ? "openMode" : "standardMode"}`;
        const videoPageId = (media as SiteVideoData).videoPageId;

        return (
            <div className={feedItemClass}>
                <SiteVideoInline
                    data={media as SiteVideoData}
                    applyStickyness={true}
                    hideVideoAds={true}
                    isShareButton={false}
                />
                <div className={`arrow arrowNext ${arrowDisplay}`} onClick={onNextSlide}>{DICT.nextVideo}</div>
                <div className="closeBtn" onClick={onCloseVideoGalleryView}></div>
                {videoPageId && <div className="shareBtn" onClick={this.openShare}></div>}
                <div className="contentDiv">
                    <div className="videoTitle" dangerouslySetInnerHTML={{ __html: title }} />
                    <div className={subtitleClass} onClick={this.onSubtitleModeToggle} dangerouslySetInnerHTML={{ __html: subTitle }} />
                    {titleLink &&
                        <div onClick={this.pushGA4Event}>
                            <HyperLinkBuilder
                                className="videoLink"
                                href={titleLink}
                                linkTarget={titleTargetLink}
                            >
                                {DICT.fullArticle}
                        </HyperLinkBuilder>
                        </div>
                    }
                </div>
            </div>
        )
    }
}