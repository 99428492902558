


export function getVideoArticleLink(linkText: string, itemLinkUrl: string): string {
    if (linkText) {
        return `<a href="${itemLinkUrl}" target="_blank" >${linkText}<img class="campaing_title" aria-hidden="true" src="${getYnetTvArrow()}" /></a>`
    }

    return "";
}
export function getYnetTvVideoText(text: string, className: string): string {
    if (text) {
        return `<p class="${className}">${text}</p>`
    }
    return "";
}
export function addYnetTvLogoToHeader(playerContainer: HTMLDivElement) {
    const header = playerContainer.querySelector(".fp-header");
    const ynetTvLogo = document.createElement("img");
    ynetTvLogo.className = "ynetTvLogo"
    ynetTvLogo.src = getYnetTvLogoUrl()
    header.appendChild(ynetTvLogo);
}


export function getYnetTvLogoUrl() {
    return "//www.ynet.co.il/images/ynet_tv/mainlogo.png";
}
export function getYnetTvArrow() {
    return "//www.ynet.co.il/images/ynet_tv/arrow.png";
}

export function addClassToPlayer(playerContainer: HTMLDivElement) {
    playerContainer.classList.add("ynetTv")
}

export function addVideoInfoToFixList(playerContainer: HTMLDivElement, data: any) {
    const titleInnerHtml = getYnetTvVideoText(data.title, "vp-title");
    const subTitleInnerHtml = getYnetTvVideoText(data.subTitle, "vp-subTitle");
    const linkTextInnerHtml = getVideoArticleLink(data.linkText, data.itemLinkUrl);

    const playList = playerContainer.querySelector(".fp-playlist");

    const infoElement = document.createElement("div");
    infoElement.className = "vp-info";
    infoElement.innerHTML = titleInnerHtml + subTitleInnerHtml + linkTextInnerHtml;
    const oldInfo = playerContainer.querySelector(".vp-info")
    if (oldInfo) {
        oldInfo.remove();
        playList.appendChild(infoElement);
    }
    else playList.appendChild(infoElement);
}





