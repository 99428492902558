import * as axios from "axios"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";
import { BlogAutoFeedItemsContainer } from "./blogAutoFeedItemsContainer";
import { useEffect, useState } from "react";
require("./ynet_mynet_site_blogsAutoFeedComponent.less")

interface BlogsAutoFeedComponentProps {
    pinnedArticles: string[]
    feedTitleText: string
    categoryId: string
    tabBackgroundColor: string
    headerLinkUrl: string,
}

export interface AutoFeedBlogItem {
    id: string,
    date: Date,
    title: string,
    author: string,
    html: string, //The cut html from the DB
    hasMore: boolean
    isPinned: boolean
    isNew: boolean
}

export function BlogsAutoFeedComponent(props: BlogsAutoFeedComponentProps) {
    const { pinnedArticles, categoryId, feedTitleText, headerLinkUrl } = props;

    const [items, setBlogItems] = useState<AutoFeedBlogItem[]>([]);
    const [isReversed, setReversed] = useState<boolean>(false);

    useEffect(() => {

        getItemsData();
        const interval = setInterval(() => {
            getItemsData();
        }, 60000);

        return () => clearInterval(interval); // Unmount function, to clear interval to prevent memory leaks

    }, [isReversed]);

    const getItemsData = () => {
        if (categoryId) {
            const axiosInstance = axios.create(addAxiosDateTransformerSettings());
            return axiosInstance.get(`/iphone/json/api/article/blog/all/${categoryId}`)
                .then((res: any) => {

                    const items = res.data.items as AutoFeedBlogItem[];
                    let pinnedItems = !!pinnedArticles ? _.remove(items, item => pinnedArticles.includes(item.id)) : [];

                    pinnedItems.forEach(pinnedItem => {
                        pinnedItem.isPinned = true;
                    });

                    let blogItems = isReversed ? items.reverse() : items;

                    let indexOfLastNewArticle = 0;
                    const now = new Date();
                    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60000);
                    blogItems.forEach((item, index) => {
                        if (item.date > fiveMinutesAgo && index === indexOfLastNewArticle) {
                            item.isNew = true;
                            indexOfLastNewArticle++;
                        }
                    });                  
                    
                    const allItems = [...pinnedItems, ...blogItems];
                    setBlogItems(allItems)
                }).then(() => twttr.widgets.load())
                .catch(err => console.error("error geting blog items data:", err))
        }
    }


    return (
        <div className="blog-auto-feed">
            <BlogAutoFeedItemsContainer
                headerLinkUrl={headerLinkUrl}
                feedTitleText={feedTitleText}
                items={items}
                isReverseOrder={isReversed}
                setReverseOrder={setReversed}
            />
        </div>
    )
}

BlogsAutoFeedComponent.siteScriptName = "BlogAutoFeedComponentComponenta";