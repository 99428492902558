require('./ynet_site_ynetWeather.less')
var Cookies = require('js-cookie');

interface YnetWeatherState {
    selectedCity: string
}

interface YnetWeatherProps {
    weather: any
    defaultCityIndex: number
    weatherUrl?: string
}

export class YnetWeather extends React.Component<YnetWeatherProps, YnetWeatherState> {
    constructor(props) {
        super(props)
        let city = Cookies.get('weatherCity');

        this.state = {
            selectedCity: city || props.defaultCityIndex
        }

    }
    private updateTemperature = (data) => {
        const value = data.target.value
        this.setState({ selectedCity: value }, () => {
            Cookies.set('weatherCity', value, { expires: 365 });
        })
    }

    public render() {
        const { weather, weatherUrl } = this.props
        const { selectedCity } = this.state
        const selectedWeather = weather[selectedCity];
        return (
            <div className="main_search_elements" id="main_search_weather">
                <div id="cdanwmansrch_weather">
                    <div className="weathertempsdiv">
                        {selectedWeather &&
                            <a className="main_search_nounderlined_link" href={weatherUrl ? weatherUrl : "//www.ynet.co.il/home/0,7340,L-201,00.html"}>
                                <span className="weathertemps">{selectedWeather.temp}&deg;</span>
                            </a>}
                    </div>

                    <div className="weatherimage">
                        {selectedWeather &&
                            <a href={weatherUrl ? weatherUrl : "//www.ynet.co.il/home/0,7340,L-201,00.html"} aria-label="מזג האוויר">
                                <img height="20" src={"/Cnt/Images/Weather/" + selectedWeather.imageName} aria-hidden="true" width="20" />
                            </a>}
                    </div>

                    <div id="mainWeatherSelect">
                        <select id='weathercityselect' aria-label="בחרו עיר" className="weathercitieselect"
                            onChange={this.updateTemperature}
                            value={this.state.selectedCity}>
                            {weather.map((city, i) => <option key={city.city} value={i.toString()}>{city.city}</option>)}
                        </select>
                    </div>

                </div>
            </div>
        )
    }
}