import { getTranslation } from "./translations"
import { AddCommentFormRenderProps } from "./addCommentFormWrapper"
require('./site_ynet_addCommentFormYnetUI.less')
interface AddCommentFormYnetUIProps {
    props: AddCommentFormRenderProps
}

export class AddCommentFormYnetUI extends React.Component<AddCommentFormYnetUIProps, {}>{
    private getTermsLinkByLang = () => {
        const { lang } = this.props.props
        if (lang === "he") return "//www.ynet.co.il/articles/0,7340,L-3049332,00.html"
        else if (lang === "en") return "//www.ynetnews.com/articles/0,7340,L-3048342,00.html"
        else if (lang === "ru") return "https://www.vesty.co.il/articles/0,7340,L-4867560,00.html"
        else if (lang === "es") return "https://www.ynetespanol.com/article/BJia11GqWB"
        else return ""
    }

    private onConfirmAlertBtnPress = () => {
        const { closeAlert, resetFormData, handleCloseCommentForm } = this.props.props
        closeAlert()
        handleCloseCommentForm()
        resetFormData()
    }

    render() {
        const { isAddCommentFormVisible, isSent, title, name, email, location, comment, showAlert, formType,
            lang, alertBeforeClosing, closeAlert, onInputChanged, handleCommentSend } = this.props.props

        if (!isAddCommentFormVisible) { return null }
        return (
            <div className="AddCommentForm ynetRegular">
                {!isSent && <div className="AddCommentFormContainer">
                    <div className="topTitleWrapper">
                        <span className="formTitle"> {formType === "REPLY" ? getTranslation(lang, "Add New Comment To Comment") : getTranslation(lang, "Add New Comment")} </span>
                        <button className="closeBtnWrapper" title={getTranslation(lang, "Close")} onClick={e => alertBeforeClosing()}>
                            <span className="closeIcon" />
                            <span>{getTranslation(lang, "Close")}</span>
                        </button>
                    </div>
                    {showAlert && <div className="commentSentMessageWrapepr alertMessage">
                        <div className="alertText"><span>{`${getTranslation(lang, "Warning")}`}</span></div>
                        <div className="successfullySentText"><span>{`${getTranslation(lang, "This Action Will Delete You Comment")}`}</span></div>
                        <div className="bottomPanel">
                            <button className="closeText" title={getTranslation(lang, "Confirm")} onClick={this.onConfirmAlertBtnPress}><span>{getTranslation(lang, "Confirm")}</span></button>
                            <button className="closeText" title={getTranslation(lang, "Cancel")} onClick={e => closeAlert()}><span>{getTranslation(lang, "Cancel")}</span></button>
                        </div>
                    </div>}
                    <div className="userDetailsInputs">
                        <input className="userDetailsInput" maxLength={20} value={name} placeholder={getTranslation(lang, "Name")} onChange={(e) => onInputChanged(e, "name")} />
                        <input className="userDetailsInput" maxLength={35} value={email} placeholder={getTranslation(lang, "Email Talckbacks Placeholder")} onChange={(e) => onInputChanged(e, "email")} />
                        <input className="userDetailsInput" maxLength={20} value={location} placeholder={getTranslation(lang, "Location")} onChange={(e) => onInputChanged(e, "location")} />
                    </div>
                    <div className="commentInputs">
                        <input type="text" maxLength={60} className="title" value={title} placeholder={getTranslation(lang, "Title")} onChange={(e) => onInputChanged(e, "title")} />
                        <textarea type="text" maxLength={4000} className="comment" value={comment} placeholder={getTranslation(lang, "Content")} onChange={(e) => onInputChanged(e, "comment")}></textarea>
                    </div>
                    <div className="bottomPanel">
                        <div className="bottomPanelRow">
                            <div className="termsOfService">
                                {getTranslation(lang, "The commenter agrees to the privacy policy and agrees not to submit comments that violate the")}
                                <a href={this.getTermsLinkByLang()} target="_blank" title={getTranslation(lang, "terms of use")}>{getTranslation(lang, "terms of use")}</a>
                                {getTranslation(lang, ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.")}
                            </div>
                        </div>
                        <div className="bottomPanelRow">
                            <button className="sendBtn" id="addCommentSendButton" title={getTranslation(lang, "Post Comment")} onClick={e => handleCommentSend()}>
                                <span className="textDoubleBubleIcon" />
                                <span>{getTranslation(lang, "Post Comment")}</span>
                            </button>
                        </div>
                    </div>
                </div>}

                {isSent && <div className="commentSentMessageWrapepr">
                    <div className="successfullySentText"><span>{getTranslation(lang, "Thank You")},</span></div>
                    <div className="successfullySentText"><span>{getTranslation(lang, "Comment Sent Confirmation Message")}</span></div>
                    <div className="bottomPanel">
                        <a href="/" aria-label={getTranslation(lang, "Main Page")}><div className={`siteLogo ${lang}`} /></a>
                        <button className="closeText" title={getTranslation(lang, "Close(Order Male)")} onClick={this.onConfirmAlertBtnPress}><span>{getTranslation(lang, "Close(Order Male)")}</span></button>
                    </div>
                </div>}
            </div>
        )

    }
}