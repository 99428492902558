import { PodcastPlayerControls } from "./podcastPlayerControls"
import { AudioPlayButton } from "../../../../radioWidgets/floatingPlayer/audioPlayButton"
import { ArticleData } from "articlePage/stateInterfaces"
import { injectArticleComponentaContext } from "../../../../../commonComponents/base/injectArticleComponentaContext";
import { ArticleComponentaContext } from "../../../../../interfaces";

interface PodcastPlayerControlsComponentProps extends Partial<ArticleComponentaContext>{
    audioUrl: string
    durationSeconds: number  
    articleData:Partial<ArticleData>  
}

export class PodcastPlayerControlsComponent extends React.Component<PodcastPlayerControlsComponentProps> {
    public static siteScriptName = 'PodcastPlayerControlsComponent'
    render() {

        const { audioUrl, durationSeconds, articleData, articleId } = this.props;

       

        return (
            <AudioPlayButton
                audioUrl={audioUrl}
                durationSeconds={durationSeconds}
                renderer={() => null}
                articleData={articleData}
                floatingPlayerRenderer={(props) => (<PodcastPlayerControls {...props} />)}            
            />
        )
    }
}