import { SiteVideoMedia } from "../../../../siteWidgets/siteMedia"
import { SiteVideoData } from "../../../../interfaces";
import { useState } from "react";
import { VideoSettingsData } from "../../../../commonDataObjects/interfaces";

interface ArticleCommercialVideoPlayerProps {
    slotsData: SiteVideoData[]
    videoSettings: VideoSettingsData
}

export const ArticleCommercialVideoPlayer = (props: ArticleCommercialVideoPlayerProps) => {

    const [playingVideoIndex, setPlayingVideoIndex] = useState<number>(0)
    const { slotsData, videoSettings } = props

    const playingVideo = slotsData[playingVideoIndex]

    const playNextVideo = () => {
        if (slotsData.length - 1 >= playingVideoIndex + 1) {
            setPlayingVideoIndex(playingVideoIndex + 1)
        } else if (slotsData.length - 1 === playingVideoIndex) {
            setPlayingVideoIndex(0)
        }
    }

    return (
        <div className="article-commercial-video-player">
            <SiteVideoMedia
                data={playingVideo}
                onVideoEnded={playNextVideo}
                onVideoError={playNextVideo}
                videoSettings={{ ...videoSettings, isVideoLightbox: false }}
                key={`articleCommercialVideoPlayer_${playingVideo.mediaId}`}
            />
        </div>
    )
}

ArticleCommercialVideoPlayer.siteScriptName = "ArticleCommercialVideoPlayer"