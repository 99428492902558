import * as axios from "axios"
import * as map from "lodash/map"
import * as filter from "lodash/filter"
import { SiteMediaData, SiteItemData, SiteImageData } from "../../../../interfaces"
import { VideoSettingsData, LinkedItemType, ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { MediaContentTypes } from "constants/contentTypes"
import { PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak";
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { ContentTypes } from "constants/contentTypes"
import { calculateAutomaticComponentaUrl, getImgAlt, getImgTitle } from "../../../../widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { ImageDetails } from "images/interfaces"
import { Loader } from "../../../yPlusLoadMore/components/loader/loader";

interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    firstData: any
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    subTitleColor: string
    titleColor: string
    categoryId: string
    componentaId?: string
    numberOfVisibleItems: number
    isLoadMoreVisible: boolean
    defaultImageUrl: string
}

interface MultiImagesRowsState {
    articles: MultiImagesRowsData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export interface MultiImagesRowsData extends Partial<SiteItemData> {
    promotionImage: string
    promotionImageDetails: ImageDetails
}

export class MultiImagesRowsFront extends React.Component<MultiImagesFrontProps, MultiImagesRowsState> {
    public static siteScriptName = "MultiImagesRowsFront";

    constructor(props) {
        super(props)
        this.state = {
            articles: props.firstData,
            pageNumber: 1,
            showLoader: false,
            showButton: true
        }
    }

    componentDidUpdate(prevProps: MultiImagesFrontProps) {
        if (this.props.firstData !== prevProps.firstData) {
            this.setState(
                {
                    articles: this.props.firstData
                }
            );
        }
    }


    private showHIdeButton = (newArticles: MultiImagesRowsData[]) => {
        const { showLoader: loader, articles } = this.state;
        const showButton = !loader &&
            newArticles.length > 0 &&
            !(articles.length % this.props.numberOfVisibleItems)
        this.setState({ showButton })
    }

    private getArticles = () => {
        const { componentaId } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        this.setState(
            {
                articles: articles,
                showLoader: true,
                showButton: false
            }
        );

        axiosInstance.get(`/iphone/json/api/mynet_auto_articles/${componentaId}/${pageNumber}`)
            .then((res: any) => {
                const newArticles = res.data.data as MultiImagesRowsData[];

                this.setState(
                    {
                        articles: [...articles, ...newArticles] as MultiImagesRowsData[],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                        showButton: true
                    }
                );
                this.showHIdeButton(newArticles)
            })
            .catch(err => console.error("error geting headline data:", err))
    }


    private commaDateView = (itemType) => {
        const { fieldsDisplaySettings } = this.props
        if (itemType === LinkedItemType.ARTICLE && (fieldsDisplaySettings.isDateVisible || fieldsDisplaySettings.isHourVisible))
            return true
        else return false
    }
    private dateView = (itemType) => {
        const { fieldsDisplaySettings } = this.props
        if (itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isDateVisible)
            return true
        else return false
    }
    private hourView = (itemType) => {
        const { fieldsDisplaySettings } = this.props
        if (itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isHourVisible)
            return true
        else return false
    }
    private titleClassName = (isSubTitleVisible: boolean) => {
        if (isSubTitleVisible) return "slotTitle oneRow"
        else return "slotTitle twoRow"

    }
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }

    public render() {
        const { isLoadMoreVisible, videoSettings, fieldsDisplaySettings, titleColor, subTitleColor, imageWidth, imageHeight, defaultImageUrl } = this.props
        const { articles, showLoader: loader, showButton } = this.state;
        let itemList = []

        const targetTitleLink: LinkTargetProperties = {
            linkOpenType: "same",
            lightBoxBgColor: "",
            lightBoxHeight: null,
            lightBoxOpacity: null,
            lightBoxWidth: 0
        }
        if (articles) {
            if (Object.keys(articles).length > 0) {
                itemList = filter(map(articles, (article: any, index) => {
                    let image: Partial<ImageDetails>
                    const promotionImage = articles[index].promotionImage
                    if (typeof promotionImage !== 'undefined') {
                        image = articles[index].promotionImageDetails
                    } else image = {};

                    return {
                        media: {
                            mediaType: "MEDIA_IMAGE" as ContentTypes,
                            mediaId: article.articleId,
                            url: image.publishedLink ? apiUrls.getImageUrl(image, { w: imageWidth, h: imageHeight }) : defaultImageUrl,
                            title: getImgTitle(image),
                            alt: getImgAlt(image),
                            credit: image.credit,
                            className: "",
                        },
                        title: article.title,
                        subTitle: article.subTitle,
                        titleLink: calculateAutomaticComponentaUrl(article),
                        titleTargetLink: targetTitleLink,
                        itemId: article.articleId,
                        hasVideo: article.hasVideo,
                        itemType: "ARTICLE" as LinkedItemType,
                        icon: article.hasVideo ? "video" : "none",
                        dateUpdatedOnSite: article.dateUpdatedOnSite,
                        author: article.author,
                        premiumExpireDays: article.premiumExpireDays
                    } as SiteItemData
                }))
            }
        }


        let emptyMedia: SiteMediaData = { url: defaultImageUrl, mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const allMediaItems: SiteMediaData[] = itemList.map(item => { return item.media })
        return (
            <div className="slotsContent">
                <div className="slotListWrapper">
                    {itemList.map((slot, index) => {
                        return (
                            <div className="slotView" key={`slotItem_${slot.itemId}_${index}`} >
                                <div className="imageArea">
                                    <SlotIconDisplay itemId={slot.itemId} icon={this.getIcon(slot.icon, slot.hasVideo)} iconClassName="medium" isIconVisible={slot.hasVideo} />
                                    <PayWallArctilceIcon
                                        premiumExpireDays={slot.premiumExpireDays}
                                        dateUpdatedOnSite={slot.dateUpdatedOnSite}
                                        className="onImage" />
                                    <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                        <SiteMedia
                                            allMedias={allMediaItems}
                                            data={slot.media !== undefined ? slot.media : emptyMedia}
                                            width={imageWidth}
                                            height={imageHeight}
                                            videoSettings={videoSettings}
                                            itemLinkUrl={slot.titleLink}
                                            isImageLinkable={true}
                                            linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                        />
                                    </div>
                                </div>
                                <div className="slotTextArea">
                                    {fieldsDisplaySettings.isTitleVisible && <div className={this.titleClassName(fieldsDisplaySettings.isSubTitleVisible && slot.subTitle !== undefined)}>
                                        <HyperLinkBuilder
                                            href={slot.titleLink}
                                            linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                            children={slot.title} >
                                            <span style={{ color: titleColor }} >
                                                <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                                            </span>
                                        </HyperLinkBuilder>
                                    </div>}
                                    {fieldsDisplaySettings.isSubTitleVisible && slot.subTitle !== undefined && <div className="slotSubTitle" >
                                        <HyperLinkBuilder
                                            href={slot.titleLink}
                                            linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                            children={slot.subTitle} >
                                            <span style={{ color: subTitleColor }} >  <div dangerouslySetInnerHTML={{ __html: slot.subTitle }} /></span>
                                        </HyperLinkBuilder>
                                    </div>}
                                    <div className="moreDetails">
                                        {fieldsDisplaySettings.isAuthorVisible && slot.author.length > 0 && <span className="authorField">{slot.author}</span>}
                                        {fieldsDisplaySettings.isAuthorVisible && this.commaDateView(slot.itemType) && slot.author.length > 0 && <span>, </span>}
                                        {(this.dateView(slot.itemType) || this.hourView(slot.itemType)) && <span> {separateHourFromDateAndFormatToString(slot.dateUpdatedOnSite, { showDate: this.dateView(slot.itemType), showHour: this.hourView(slot.itemType), dateTimeSeperator: " / " })} </span>} </div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
                <div className="ButtonAndLoaderContainer">
                    {loader && <Loader />}
                    {isLoadMoreVisible && showButton && <div className={"loadMoreButton"} onClick={() => this.getArticles()} >טען עוד </div>}
                </div>
            </div>
        )
    }

}

