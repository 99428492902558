import * as classNames from "classnames"
import { RichInput } from "widgets/commonComponents/base/richInput"
import { isWcmEditor } from "config/serverConfig"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"
import { FlashLinkType } from "../../../ticker/windows/flashLinkSelector";
import { analyticsMynetTickerPushFunc } from "./tickerPopUp"


interface TickerItemSlotProps {
    titleColor: string
    subTitleColor: string
    title: string
    flashDate: Date
    linkTarget: LinkTargetProperties
    link: string
    flashId: string
    flashLinkType: FlashLinkType
    fieldsDisplaySettings: LinkedItemFieldDisplay
}

export class TickerItemSlotMynet extends React.Component<TickerItemSlotProps, {}>{
    public componentDidMount() {
        const { flashLinkType, link, flashId } = this.props
        if (flashLinkType === "link" && link) {
            const linkElm = document.getElementById(`mynetFlashLink-${flashId}`);
            if (linkElm) {
                linkElm.addEventListener("mouseup", () => analyticsMynetTickerPushFunc(flashId))
            }
        }
    }

    private renderLink(textStyle: any, popUpLink: any) {
        const { flashLinkType, link, linkTarget, title, flashId } = this.props
        if (flashLinkType === "link") {
            const extraProps = !!link ? { "id": `mynetFlashLink-${flashId}` } : {}
            return (
                <HyperLinkBuilder href={link}
                    linkTarget={linkTarget}
                    extraProps={extraProps}
                    children={title} >
                    <div dangerouslySetInnerHTML={{ __html: title }} style={textStyle} /></HyperLinkBuilder>

            )
        }
        else {
            return (popUpLink)
        }
    }


    public render() {
        const { titleColor, subTitleColor, title, flashDate, flashId, fieldsDisplaySettings } = this.props

        const dateStyle = {
            color: titleColor
        }
        const textStyle = {
            color: subTitleColor
        }
        const popUpLink = !isWcmEditor() ? <a tabIndex={0} className="flashItem" data-flashid={flashId} id={flashId}>
            <div dangerouslySetInnerHTML={{ __html: title }} style={textStyle} />
        </a> : <div dangerouslySetInnerHTML={{ __html: title }} style={textStyle} />
        return (
            <div className={classNames("slotView", { "inEditMode": isWcmEditor(), "emptySlot": title.length === 0 })}>
                <div className={classNames("slotTitle", { "withWidth": title.length === 0 })}>
                    <span style={dateStyle} >{separateHourFromDateAndFormatToString(flashDate, { showDate: fieldsDisplaySettings.isDateVisible, showHour: fieldsDisplaySettings.isHourVisible, isSlashSeparator: false, fullYear: false })}</span>
                </div>

                <div className="moreDetails" >
                    {this.renderLink(textStyle, popUpLink)}

                </div>
                <span className="separator" style={{ backgroundColor: subTitleColor }} />
            </div>
        )
    }
}

