import * as axios from "axios"
import { enablePageRefresh, disablePageRefresh } from "../../../siteWidgets/siteWidgetUtils"
import { isMobile } from "../../../../utils/isMobile"
import * as reject from "lodash/reject"
import * as has from "lodash/has"
import { RedMailComponentaYnet } from "./redMailComponentaYnet";
import { RedMailComponentaCalcalist } from "./redMailComponentaCalcalist";
import { RedMailComponentaYnetnews } from "../../ynetnews1280/redMail/components/redMailComponentaYnetnews";
import { RedMailComponentaRadionas } from "../../radionas/redmail/components/redMailComponentaRadionas";

declare var grecaptcha: any
declare var window: any
declare var grecaptcha: any
declare var window: any
declare var AWS: any

const MB = 1024 * 1024
const MAX_PART_SIZE = MB * 5
const MAX_SIZE_ALLOWED_PER_FILE = MB * 200
const maxFileAlertMessage = {
    ynetnews: "Up to 5 files",
    Ynet: "מקסימום 5 קבצים",
    ynet: "מקסימום 5 קבצים",
    Calcalist: "מקסימום 5 קבצים",
    vesty: "До 5 файлов",
}

interface State {
    textArea: string
    hashes: string[]
    photographer: string
    sender: string
    mail: string
    phoneNumber: string
    IsPopUp: boolean
    loading: boolean
    isTextAreaBlank: boolean
    validation: { mail: boolean, phoneNumber: boolean }
    numOfFiles: number
    isAgreementApproved: boolean
    countSubmitAttempts: number
    submitButtonDisabled: boolean
    selectedFiles: any
    s3Policy: any
    invalidFilesName: string[]
}

interface RedMailCoomponentaProps {
    siteName: string,
    isChecked: boolean
    languageCode: string
    siteSettingSitename: string
}

type Action = "add" | "remove"



export class RedMailSiteComponentaWrapper extends React.Component<RedMailCoomponentaProps, State>{
    public static siteScriptName = "RedMailSiteComponentaWrapper";
    public constructor(props) {
        super(props);
        this.state = {
            textArea: "",
            hashes: [],
            photographer: "",
            sender: "",
            mail: "",
            phoneNumber: "",
            IsPopUp: false,
            loading: false,
            numOfFiles: 0,
            isTextAreaBlank: true,
            validation: { mail: true, phoneNumber: true },
            isAgreementApproved: false,
            countSubmitAttempts: 0,
            submitButtonDisabled: false,
            selectedFiles: {},
            s3Policy: null,
            invalidFilesName: []
        };
        this.submitForm = this.submitForm.bind(this);
    }

    public s3

    componentDidMount() {
        this.createAWS3Script()
        disablePageRefresh()
    }

    componentWillUnmount() {
        enablePageRefresh()
    }

    private submitForm = () => {
        if (this.state.isTextAreaBlank || !this.state.validation.mail || !this.state.validation.phoneNumber || !this.state.isAgreementApproved) {
            if (isMobile()) {
                this.scrollScreenToErrorElement();
            }
            this.setState(prevState => ({ ...prevState, countSubmitAttempts: prevState.countSubmitAttempts + 1 }))
            return
        } else {
            this.setState({ loading: true });
            const isTest = window.location.search.includes("is_test=1");

            if (isTest) {
                this.sendSub("", true);
            } else if (window.wcmCaptchaSiteKey && grecaptcha) {
                grecaptcha.ready(() => {
                    grecaptcha.execute(window.wcmCaptchaSiteKey, { action: 'submit' }).then(token => {
                        this.sendSub(token, false);
                    })
                });
            }
        }
    }

    private sendSub = async (token: string, isTest: boolean) => {
        const { siteName, siteSettingSitename } = this.props
        const urlParams = new URLSearchParams(window.location.search.slice(0));
        const articleId = urlParams.get('notifyMistake') || null;
        const sitenameForCaptcha = siteName ? siteName.toLowerCase() : siteSettingSitename.toLowerCase();
        let data = {
            sender: this.state.sender,
            credit: this.state.photographer,
            email: this.state.mail,
            phone: this.state.phoneNumber,
            text: this.state.textArea,
            hashes: this.state.hashes,
            num_of_files: this.state.numOfFiles,
            token: token,
            siteName: window["siteID"],
            sent_from: sitenameForCaptcha
        }
        axios.post(`/api/red_mail/save/${articleId ? articleId : ''}${isTest ? "?is_test=1" : ""}`, data)
            .then((res: any) => res.status === 200 && this.setState({ IsPopUp: true, loading: false, countSubmitAttempts: 0 }))
            .catch(err => {
                console.log('err', err);
                this.setState({ loading: false });
            })
    }

    private handleChangeText = (e) => {
        const value = e.target.value;
        if (value.length === 0) {
            this.setState({ isTextAreaBlank: true })
        } else {
            this.setState({ isTextAreaBlank: false })
        }
        this.setState({ textArea: value })
    }

    private handleChangeExtraData = (e) => {
        const inputName = e.target.name
        const value = e.target.value;
        let isInputValid = true
        if (e.target.id === 'phoneNumber') {
            let pattern = /^[0-9\b]{10}$/;
            if (value) {
                isInputValid = pattern.test(value);
            }
        }

        if (e.target.id === 'mail') {
            let pattern = /^((\w[^\W]+)[\.\-]?){1,}\@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (value) {
                isInputValid = pattern.test(value);
            }
        }
        if (e.target.id === "agreementApproved") {
            this.setState(prevState => ({ ...prevState, isAgreementApproved: !prevState.isAgreementApproved }))
        }
        this.setState(prevState => ({ [inputName]: value, validation: { ...prevState.validation, [inputName]: isInputValid } }))
    }

    public onClose = () => {
        this.resetInputs();
    }

    private handleNumOfFiles = (action: Action) => {
        switch (action) {
            case "add":
                this.setState(prevState => ({ ...prevState, numOfFiles: prevState.numOfFiles + 1 }))
                break;
            case "remove":
                this.setState(prevState => ({ ...prevState, numOfFiles: prevState.numOfFiles - 1 }))
                break;
            default: null;
        }
    }

    private resetInputs = () => {
        this.setState({
            hashes: [],
            loading: false,
            IsPopUp: false,
            textArea: '',
            photographer: '',
            sender: '',
            mail: '',
            phoneNumber: '',
            numOfFiles: 0,
            validation: { mail: true, phoneNumber: true },
            isTextAreaBlank: true,
        })
    }

    private setSubmitButtonDisabled = () => {
        this.setState({ submitButtonDisabled: true })
    }

    private setSubmitButtonEnabled = () => {
        this.setState({ submitButtonDisabled: false })
    }

    private scrollScreenToErrorElement = () => {

        let errorElement = null;

        if (this.state.isTextAreaBlank) {
            errorElement = '#RedMailTextAreaElement';
        } else if (!this.state.validation.mail) {
            errorElement = '#mail';
        } else if (!this.state.validation.phoneNumber) {
            errorElement = '#PhoneNumber';
        } else if (!this.state.isAgreementApproved) {
            errorElement = '#agreementApproved';
        }

        const elementToScroll = document.querySelector(errorElement);
        elementToScroll.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    //------------AWS upload logic------------\\

    createAWS3Script = () => {
        const script = document.createElement("script");
        script.src = 'https://sdk.amazonaws.com/js/aws-sdk-2.945.0.min.js';
        script.type = "text/javascript";
        script.id = "amazonS3";
        document.body.appendChild(script);
    }

    sendS3 = (filesData, file: File) => {
        const { AccessKeyId, SecretAccessKey, SessionToken } = filesData.policy

        AWS.config.update({
            region: filesData.region,
            credentials: new AWS.Credentials(AccessKeyId, SecretAccessKey, SessionToken),
        });
        AWS.config.apiVersions = {
            s3: '2006-03-01',
        };
        this.s3 = new AWS.S3();
        const key = `${filesData.key}_${Math.random().toString().substr(2)}_${file.name.replace(/\s/g, '')}`;
        let uploadParams = {
            Bucket: filesData.bucket,
            Key: key,
            ContentType: file.type
        }

        this.s3.createMultipartUpload(uploadParams, (err, data) => {
            if (err) {
                console.error("S3 Upload error", err)
            }
            if (data) {
                this.setSubmitButtonDisabled;
                this.setState({ selectedFiles: { ...this.state.selectedFiles, [file["key"]]: { fileName: file.name, progress: 0, uploadKey: data.Key } } });
                this.uploadS3Part(data.UploadId, filesData.bucket, 1, Math.ceil(file.size / MAX_PART_SIZE), file, key, [])
            }
        })
    }

    uploadS3Part = (uploadid: string, bucket, partNum: number, totalParts: number, file: File, key: string, etags: string[]) => {
        const start = (partNum - 1) * MAX_PART_SIZE
        const end = partNum * MAX_PART_SIZE //file slice dosent reach beyond the size of the file
        const params = {
            Body: file.slice(start, end),
            Bucket: bucket,
            Key: key,
            PartNumber: partNum,
            UploadId: uploadid
        }
        //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#uploadPart-property
        this.s3.uploadPart(params, (err, data) => {
            if (err) {
                const existingFiles = this.state.selectedFiles;
                delete existingFiles[file["key"]].progress;
                this.setState({ selectedFiles: existingFiles })
                this.isUploadFinished();
                console.error(err, err.stack)
            }
            else {
                etags.push(data.ETag)
                if (partNum !== totalParts) {
                    this.setState({ selectedFiles: { ...this.state.selectedFiles, [file["key"]]: { fileName: file.name, progress: (partNum * 100) / totalParts, uploadKey: key } } });
                    this.uploadS3Part(uploadid, bucket, partNum + 1, totalParts, file, key, etags)
                } else {
                    this.finishS3Part(bucket, uploadid, key, etags)
                }
            }
        });
    }

    finishS3Part = (bucket, uploadId, key: string, etags: string[]) => {
        const params = {
            Bucket: bucket,
            Key: key,
            MultipartUpload: {
                Parts: etags.map((eTag, index) => (
                    {
                        ETag: eTag,
                        PartNumber: index + 1
                    })
                )
            },
            UploadId: uploadId
        };
        //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#completeMultipartUpload-property
        this.s3.completeMultipartUpload(params, (err, data) => {
            if (err) {
                console.error(err, err.stack)
            }
            else {
                this.handleNumOfFiles("add")
                Object.keys(this.state.selectedFiles).map((fileKey, index) => {
                    if (this.state.selectedFiles[fileKey].uploadKey === data.Key) {
                        const existingFiles = this.state.selectedFiles;
                        delete existingFiles[fileKey].progress;
                        this.setState({ selectedFiles: existingFiles })
                    }
                })
                this.isUploadFinished();
            };
        });
    }

    manageFileUpload = (policy: any, file: File) => {
        const filesData = policy
        const oldHashesArray = this.state.hashes;
        this.sendS3(filesData, file);
        this.setState({ hashes: [...oldHashesArray, policy.hash] });
    }

    getS3Policy = () => {
        const isTest = window.location.search.includes("is_test=1");
        return new Promise((resolve, reject) => {
            if(isTest) {
                let data = {token: "", content_type: ""}
                axios.post(`/api/red_mail/get_policy?is_test=1`, data).then(res => {
                    this.setState({ s3Policy: res.data })
                    return resolve(res.data)
                })
            }else if (window.wcmCaptchaSiteKey && grecaptcha) {
                grecaptcha.ready(() => {
                    grecaptcha.execute(window.wcmCaptchaSiteKey, { action: 'submit' }).then(token => {
                        let data = {token: token, content_type: ""}
                        axios.post(`/api/red_mail/get_policy`, data).then(res => {
                            this.setState({ s3Policy: res.data })
                            return resolve(res.data)
                        })
                    })
                })
            }
        })
    }

    handleSelectedFile = (e) => {
        const files = e.target.files;
        const { siteSettingSitename } = this.props;
        if (files.length > 5 || files.length + Object.keys(this.state.selectedFiles).length > 5) {
            alert(maxFileAlertMessage[siteSettingSitename]);
        } else {
            const validFiles = this.isFilesSizeValid(files)
            if (!this.state.s3Policy) {
                this.getS3Policy().then(res => {
                    this.sendFiles(validFiles);
                })
            } else {
                this.sendFiles(validFiles);
            }
        }
    };

    handleRemoveFile = (index: number) => {
        const { selectedFiles } = this.state
        const newFiles = reject(selectedFiles, (f, i) => i === index);
        this.setState({ selectedFiles: newFiles })
        this.handleNumOfFiles("remove");
    }

    sendFiles = (files: any) => {
        const { numOfFiles } = this.state
        for (let i = 0; i < files.length; i++) {
            let file: File = files[i];

            if (this.containsHeb(file.name)) {
                const ext = file.name.split('.').pop();
                file = this.renameFile(file, `custom_file_${numOfFiles + 1}.${ext}`)
            }
            const existingFiles = this.state.selectedFiles;
            const key = Date.now();
            existingFiles[key] = { fileName: file.name };
            file["key"] = key;
            this.setState({ selectedFiles: { ...existingFiles } });
            this.manageFileUpload(this.state.s3Policy, file);
        }
    }

    containsHeb = (str) => {
        return (/[\u0590-\u05FF]/).test(str);
    }

    renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    isUploadFinished = () => {
        let isUploadFinished: boolean;
        Object.keys(this.state.selectedFiles).forEach((fileKey) => {
            isUploadFinished = !has(this.state.selectedFiles[fileKey], "progress");
        });

        if (isUploadFinished) {
            this.setSubmitButtonEnabled;
        } else {
            this.setSubmitButtonDisabled;
        }
    }

    isFilesSizeValid = (files: File[]) => {
        const { invalidFilesName } = this.state
        const dt = new DataTransfer()
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > MAX_SIZE_ALLOWED_PER_FILE || file.size < 1) {
                this.setState({ invalidFilesName: [...invalidFilesName, file.name] })
                continue
            }
            dt.items.add(file)
        }
        return dt.files
    }

    showFileStatusProgress = (fileKey) => {
        if (this.state.selectedFiles[fileKey].progress) {
            return Math.round(this.state.selectedFiles[fileKey].progress) + "%";
        } else {
            return null;
        }
    }

    //------------/AWS upload logic------------\\

    public render() {
        const { siteName, isChecked, languageCode, siteSettingSitename } = this.props;
        const generalSiteName = siteName ? siteName : siteSettingSitename;

        const redMailComponentBySite = {
            Ynet: <RedMailComponentaYnet
                {...this.state}
                handleChangeText={this.handleChangeText}
                handleSelectedFile={this.handleSelectedFile}
                showFileStatusProgress={this.showFileStatusProgress}
                handleChangeExtraData={this.handleChangeExtraData}
                submitForm={this.submitForm}
                onClose={this.onClose}
                isChecked={isChecked}
                lang={languageCode}
            />,
            Calcalist: <RedMailComponentaCalcalist
                {...this.state}
                handleChangeText={this.handleChangeText}
                handleSelectedFile={this.handleSelectedFile}
                showFileStatusProgress={this.showFileStatusProgress}
                handleChangeExtraData={this.handleChangeExtraData}
                submitForm={this.submitForm}
                onClose={this.onClose}
                isChecked={isChecked}
                lang={languageCode}
            />,
            ynetnews: <RedMailComponentaYnetnews
                {...this.state}
                handleChangeText={this.handleChangeText}
                handleSelectedFile={this.handleSelectedFile}
                showFileStatusProgress={this.showFileStatusProgress}
                handleChangeExtraData={this.handleChangeExtraData}
                submitForm={this.submitForm}
                onClose={this.onClose}
                isChecked={isChecked}
                lang={languageCode}
            />,
            vesty: <RedMailComponentaYnetnews
                {...this.state}
                handleChangeText={this.handleChangeText}
                handleSelectedFile={this.handleSelectedFile}
                showFileStatusProgress={this.showFileStatusProgress}
                handleChangeExtraData={this.handleChangeExtraData}
                submitForm={this.submitForm}
                onClose={this.onClose}
                isChecked={isChecked}
                lang={languageCode}
            />,
           Radionas:  <RedMailComponentaRadionas
           {...this.state}
           handleChangeText={this.handleChangeText}
           handleSelectedFile={this.handleSelectedFile}
           showFileStatusProgress={this.showFileStatusProgress}
           handleChangeExtraData={this.handleChangeExtraData}
           submitForm={this.submitForm}
           onClose={this.onClose}
           isChecked={isChecked}
           lang={languageCode}
       />,
        }

        return (
            <>
                {redMailComponentBySite[generalSiteName]}
            </>
        )

    }
}



