import { LiveVideoPlayer } from "./liveVideoPlayer";
import * as axios from "axios"

interface LiveVideoPlayerMutamComponentaProps {
    showVideoOnMobile: boolean
    domain: string
}

interface LiveVideoPlayerMutamComponentaState {
    videoSource: string
}

export class LiveVideoPlayerMutamComponenta extends React.Component<LiveVideoPlayerMutamComponentaProps, LiveVideoPlayerMutamComponentaState> {
    static siteScriptName = "liveVideoPlayer"
    constructor() {
        super();
        this.state = {
            videoSource: "",
        }
    }

    private intervalId;

    componentDidMount() {
        this.getVideoSrc();
        this.intervalId = setInterval(() => {
            this.getVideoSrc();
        }, 600000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

    private getVideoSrc = () => {
        const { domain } = this.props;
        const url = domain === "https://dev-v3.ynet.co.il" ? "https://qa.ynet.co.il/iphone/json/mobile/v2/page/7757" : "/iphone/json/mobile/v2/page/7757";
        axios.get(url).then((res: any) => {
            const videoSource = res.data.components[0].items[0].linkData.link.href;
            if (res.data && videoSource) {
                if (videoSource !== this.state.videoSource) this.setState({ videoSource })
            }
        }).catch(err => console.error("error geting video source", err))
    }

    render() {
        const { showVideoOnMobile } = this.props;
        const { videoSource } = this.state;
        if (!showVideoOnMobile || !videoSource) return null;

        return (
            <div className="liveVideoPlayerMutamComponenta">
                <LiveVideoPlayer
                    videoSource={videoSource}
                    isCustomControllers={true} />
            </div>
        )
    }
}