const clickOutside = require('react-click-outside');


export class StockPopUpMobile extends React.Component<{}, {}> {
    public static siteScriptName = "StockPopUpMobile";




private closeStock=(stockEl:HTMLElement)=>{
    stockEl.innerHTML = "";
    stockEl.style.left="";
    stockEl.style.top = "";
    stockEl.parentElement.style.display="none";

}
    componentDidMount() {
        if (typeof window !== "undefined") {
            const elements = document.querySelectorAll(".stockPopUpLink");
            for (let el of elements) {
                el.addEventListener("click", (e) => {
                    const stockId = el.getAttribute("data-id");
                    const closeBtn =  document.createElement("div");
                    closeBtn.className="closeStockPopUp";
                    closeBtn.innerHTML = "x";
                    const newScript = document.createElement("script");
                    newScript.id = `${stockId}_stockpopUp`;
                    newScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js";
                    newScript.async = true;
                    newScript.innerHTML = `
                  {
                  "symbol": "${stockId}",
                  "width": 350,
                  "isTransparent": false,
                  "colorTheme": "dark",
                  "locale": "he-il",
                  "scrolling":"yes"
                }
                  `;
                    const stockElMask = document.querySelector(".tradingview-mask") as HTMLElement;
                    const stockEl = document.querySelector(".tradingview-widget-container") as HTMLElement;
                    stockElMask.style.display = "grid";
                    const newDiv = document.createElement("div");
                    newDiv.className = "tradingview-widget-container__widget";
                    stockEl.append(closeBtn);
                    stockEl.append(newDiv);
                    stockEl.append(newScript);
                    closeBtn.addEventListener("click", (e) => {
                        this.closeStock(stockEl)
                    })
                    stockElMask.addEventListener("click", (e) => {
                        this.closeStock(stockEl)
                    })
                })
            }
        }
    }

    public render() {  
        return (
           <div className="tradingview-mask"><div className="tradingview-widget-container" > </div></div> 

        )
    }
}
