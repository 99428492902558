
export class YplusAccessibiltyButton extends React.Component<{}, {}> {
    public static siteScriptName = "yplusAccessibiltyFixedBtn";

    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        return (
            <button className="accessibiltyFixedBtn" onClick={this.clickAcessibilityBtn} id="INDmenu-btn-fixed" title="אתר נגיש" aria-labelledby="INDbtnTooltip" accessKey="m"></button>
        )
    }
}