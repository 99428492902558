export interface ImageSIzeDefintion {
    w?: number
    h?: number
    imageSize?: ImageSize
}

export enum ImageSize {
    original,
    small,
    medium,
    large,
    xLarge,
    xxLarge
}

export function imageSizeConvertion(size: ImageSIzeDefintion): ImageSize {
    if (size.imageSize) return size.imageSize;
    //if (size.w <= 155) return ImageSize.small
    if (size.w <= 320) return ImageSize.medium
    if (size.w <= 490) return ImageSize.large
    if (size.w <= 1024) return ImageSize.xLarge
    return ImageSize.xxLarge
}
