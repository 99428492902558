require("./richInputExtras/richInput.less");
import * as classNames from "classnames";
import { ContentEditable } from "../../../global/components/contentEditable";
import { isWcmEditor } from "config/serverConfig";
import { FloatingColorPicker } from "./floatingColorPicker";

interface RichInputProps {
    value: string;
    className?: string;
    style?: React.CSSProperties;
    showToolbar?: boolean;
    isBlock?: boolean;
    tagName?: string;
    onChange: (text: string, plainText?: string) => void;
    colorName?: string;
    tbTitle?: boolean;
    tbDesc?: boolean;
    tbAuthor?: boolean;
    showBold?: boolean;
}

interface RichInputState {
    selection?: Selection;
    focus?: boolean;
}

interface ButtonPosition {
    top: number;
    left: number;
}

export class RichInput extends React.Component<RichInputProps, RichInputState> {
    public constructor(props) {
        super(props);
        let selection;
        if (typeof window !== "undefined") {
            selection = window.getSelection();
        }
        this.state = {
            selection,
        };
    }

    private containerRef: HTMLElement;
    private toolbarRef: FloatingColorPicker;

    private onChangeContentEditable = (e: KeyboardEvent, value: string, plainText?: string) => {
        this.props.onChange(value, plainText);
    };

    private onKeyDown = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    private setSelection = () => {
        const selection = window.getSelection();
        this.setState({ selection });
    };

    private getToolbarPosition = () => {
        const { selection } = this.state;
        let containerBoundingClientRect: ClientRect;
        let buttonPosition: ButtonPosition = { top: 0, left: 0 };

        if (selection && selection.rangeCount > 0 && this.containerRef) {
            containerBoundingClientRect =
                this.containerRef.getBoundingClientRect();
            const selectionRange = selection.getRangeAt(0);
            const selectionRect = selectionRange.getBoundingClientRect();
            const toolbarWidth = 80;
            const parentWidth = containerBoundingClientRect.width;

            // Calculate the maximum left and right positions the button can have without overflowing
            const maxLeftPosition = parentWidth - toolbarWidth;
            const maxRightPosition = 0;

            // Calculate the desired button position
            let desiredLeftPosition =
                selectionRect.left - containerBoundingClientRect.left;
            desiredLeftPosition = Math.max(
                maxRightPosition,
                Math.min(maxLeftPosition, desiredLeftPosition)
            );

            buttonPosition = {
                top: selectionRect.top - containerBoundingClientRect.top - 40,
                left: desiredLeftPosition,
            };
        }

        return buttonPosition;
    };

    private onFocus = () => {
        this.setState({ focus: true });
    };

    private onBlur = () => {
        this.setState({ focus: false });
    };

    private toolbar = () => {
        const { selection, focus } = this.state;
        const { showToolbar, showBold } = this.props;

        if (!selection) return null;
        const colorPickerPosition = this.getToolbarPosition();
        return (
            !selection.isCollapsed &&
            showToolbar &&
            focus && (
                <FloatingColorPicker
                    selectedText={selection}
                    top={colorPickerPosition.top}
                    left={colorPickerPosition.left}
                    ref={(ref) => (this.toolbarRef = ref)}
                    showBold={showBold}
                />
            )
        );
    };

    private onPaste = (e: ClipboardEvent) => {
        e.preventDefault();
        var text = e.clipboardData.getData("text/plain");
        var temp = document.createElement("span");
        temp.innerHTML = text;
        document.execCommand("insertHTML", false, temp.textContent);

        return false;
    };

    public render() {
        const {
            className,
            style,
            showToolbar,
            isBlock,
            tagName,
            tbTitle,
            tbDesc,
            tbAuthor,
        } = this.props;
        const value = this.props.value || "";
        const containerClassNames = classNames(
            { RichInput: isWcmEditor() },
            {
                withWidth:
                    value !== undefined && value.length === 0 && isWcmEditor(),
            },
            className,
            {
                IsBlock: isBlock,
            }
        );
        if (!isWcmEditor()) {
            return (
                <ContentEditable
                    tagName="span"
                    html={value}
                    style={style}
                    disabled={true}
                    onChange={null}
                    data-tb-title={tbTitle && ""}
                    className={containerClassNames}
                    data-tb-desc={tbDesc && ""}
                    data-tb-author={tbAuthor && ""}
                />
            );
        }
        return (
            <span
                className={containerClassNames}
                ref={(ref) => (this.containerRef = ref)}
                style={style}
            >
                <ContentEditable
                    tagName="span"
                    html={value}
                    onChange={this.onChangeContentEditable}
                    onMouseUp={() => this.setSelection()}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    onPaste={this.onPaste}
                    onKeyDown={this.onKeyDown}
                />
                {this.toolbar()}
            </span>
        );
    }
}
