import { MynetFooterData } from "../data/mynetFooterData";
import { ComponentaInstanceProps, PageComponentaContext } from "widgets/interfaces"
import { getNewlinkTargetProperties } from "widgets/widgetsUtils"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import * as classNames from "classnames"
import { isWcmEditor } from "config/serverConfig"

require('./mynet_site_mynetFooter.less')

interface mynetFooterPrps {
    curSubSite: string
    items: MynetSiteList[]

}
export class SiteMynetFooter extends React.Component<mynetFooterPrps, {}> {
    public static siteScriptName = "SiteMynetFooter"

    private analyticsMynetFooterPushFunc = () => {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'contact_us',
                Category: 'Contact Us',
                Action: 'footer'
            });
        }
    }

    render() {
        const { curSubSite, items } = this.props
        const emptyLinkItem = {
            contactCaption: "",
            contactLink: ""
        }
        const emptyMainItem = {
            name: "",
            url: "",
            link: "",
            linkTarget: getNewlinkTargetProperties(),
            mainSiteLinks: [emptyLinkItem],
            facebookLink: ""
        } as MynetSiteList
        const mainItem = items.find(x => x.subSite === curSubSite)
        let mainSite = mainItem !== undefined ? mainItem : emptyMainItem
        const hostname_url = typeof window !== "undefined" ? window.location.hostname : ""


        return (
            <div className="MynetFooter">
                <div className="logoArea">
                    <HyperLinkBuilder href={mainSite.link} ariaLabel={mainSite.name} linkTarget={mainSite.linkTarget}>
                        <SiteSimpleImage src={mainSite.url} alt={mainSite.name} title={mainSite.name} aria-hidden="true" aria-label="Page Logo" />
                    </HyperLinkBuilder>
                </div>
                <div className="menuArea">
                    <div className="citiesList">
                        {items.map((slot, index) =>
                            <div className={classNames("slotView", { "inEditMode": isWcmEditor(), "selected": mainItem === slot })} key={index}>
                                <HyperLinkBuilder href={slot.link} linkTarget={slot.linkTarget}  >
                                    <span> {slot.name}</span>
                                </HyperLinkBuilder>
                            </div>
                        )}
                    </div>

                    <div className="contacts">
                        <div className="infoArea">
                            <a onMouseUp={this.analyticsMynetFooterPushFunc} href="mailto:mynet@yedtik.co.il" className="">
                                פרסם <span title="פרסם אצלנו" className="icon publish" />
                            </a>
                            <span className="termSeparator" />
                            <HyperLinkBuilder href={`//www.facebook.com/${mainSite.facebookLink}`} linkTarget={getNewlinkTargetProperties()}>
                                פייסבוק <span title="הדף שלנו בפייסבוק" className="icon facebook" />
                            </HyperLinkBuilder>

                        </div>
                        <div className="termsOfUseLinks">
                            <span className="termSeparator" />
                            <a href="//z.ynet.co.il/short/content/2018/privacypolicy/terms.html" target="_blank">תנאי שימוש</a>{" "}
                            <span className="termSeparator" />
                            <a href="//z.ynet.co.il/short/content/2018/privacypolicy/policy.html" target="_blank">ומדיניות פרטיות</a>
                            <span className="termSeparator" />
                            <a href={`//www.yit.co.il/?utm_source=${hostname_url}&utm_medium=referral&utm_campaign=footer`} className="withLogo" target="_blank">אפיון, עיצוב ופיתוח <span className="yitLogo" /></a>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export interface MynetSiteList {
    name: string
    link: string
    linkTarget: LinkTargetProperties
    url?: string
    subSite?: string
    mainSiteLinks: ContactLinksItem[]
    facebookLink: string
}

export interface ContactLinksItem {
    contactCaption: string
    contactLink: string
}


