require("./../../site_videoPlayer.less")
import * as classNames from "classnames"
import * as delay from "lodash/delay"

import { SiteVideoData } from "../../../../interfaces"
import { getVideoSources, shouldAutoPlayVideo, isAboveTheFold } from "../../videoPlayerUtils"
import { sendPopularityEvent } from "../../videoAnalytics"
import { attachVideojsStickinessAPI } from "../../videojsStickiness"
import { buildAdTag } from "../../videoPlayerAds"
import { VideoJsGoogleAnalytics } from "./videoJsAnalytics"
import { enablePageRefresh, disablePageRefresh } from "../../../siteWidgetUtils"

interface VideoJsPlayerForInlineState {
    showCustomPlayBtn: boolean
    isMuted: boolean
}

interface VideoJsPlayerForInlineProps {
    data: SiteVideoData
    width?: number
    height?: number
    runArticleAutoPlayLogic?: boolean
    applyStickyness?: boolean
    hideVideoAds?: boolean
    isInLightbox?: boolean
    onPlayStarted?: () => void
    onPlayStopped?: () => void
}

export class VideoJsPlayerForInline extends React.Component<VideoJsPlayerForInlineProps, VideoJsPlayerForInlineState>{
    private player;
    private videoContainerRef;
    private videoNodeRef;
    private playerIdentifier;

    constructor(props) {
        super(props)
        this.playerIdentifier = `video_${this.props.data.mediaId}_${Date.now()}`
        this.state = {
            showCustomPlayBtn: false,
            isMuted: false
        }
    }

    componentWillMount() {
        const { isPlayerBarVisible: controls, isMuteAvailable: isMuted } = this.props.data
        if (!controls) {
            this.setState({ showCustomPlayBtn: true, isMuted })
        }

    }

    componentDidMount() {
        this.initializePlayer()
    }


    private initializePlayer = () => {
        const { runArticleAutoPlayLogic, isInLightbox, applyStickyness, hideVideoAds } = this.props
        const {
            poster,
            isAutoPlaying,
            isLoopPlaying: loop,
            isPlayerBarVisible: controls,
            isMuteAvailable: muted,
            url,
            downGradeUrl
        } = this.props.data;

        const shouldAutoPlay = isInLightbox ? true : (!runArticleAutoPlayLogic && isAutoPlaying) || shouldAutoPlayVideo(this.videoNodeRef, isAutoPlaying);

        const playerSettings = {
            controls,
            muted,
            loop,
            poster,
            sources: getVideoSources(url, downGradeUrl)
        }

        const videoJSPlayer = window.videojs(
            this.videoNodeRef,
            playerSettings,
            (player) => {
                this.player = window.videojs.getPlayer(this.playerIdentifier)
                this.attachPlayerEvents(shouldAutoPlay)
                applyStickyness && !isInLightbox && attachVideojsStickinessAPI(this.playerIdentifier, this.videoContainerRef, poster);
            }
        )

        if (!hideVideoAds) {
            this.initializeIma(videoJSPlayer, shouldAutoPlay);
        }
    }

    private initializeIma = (player, shouldAutoPlay: boolean) => {
        const { categoryId } = this.props.data
        const isATF = isAboveTheFold(this.videoContainerRef)
        const imaOptions = {
            id: this.videoNodeRef,
            locale: "il",
            adTagUrl: buildAdTag("Preroll", shouldAutoPlay, isATF, "vjs", null, categoryId)
        };
        window.hasOwnProperty("google") && player.ads && player.ima && player.ima(imaOptions)
    }

    private attachPlayerEvents = (shouldAutoPlay: boolean) => {
        this.player.on("ready", () => {
            if (shouldAutoPlay) {
                delay(() => {
                    this.player.play()
                    this.props.onPlayStarted && this.props.onPlayStarted()
                }, 500)
            }
        })

        this.player.on("play", () => {
            const { url, mediaId } = this.props.data
            window.detachCurrentVideo && window.detachCurrentVideo(null, true)
            this.attachStopToWindowObj()
            VideoJsGoogleAnalytics.reportVidoeStartPlaying(url)
            sendPopularityEvent(mediaId, this.player.cache_.duration, window["WCM_POPULARITY_LINK"])
            this.props.onPlayStarted && this.props.onPlayStarted()
            disablePageRefresh();
            this.setState({ showCustomPlayBtn: false })
        })

        this.player.on("adstart", () => {
            this.attachStopToWindowObj()
        })

        this.player.on("ended", () => {
            enablePageRefresh();
            this.setState({ showCustomPlayBtn: true })
            this.props.onPlayStopped && this.props.onPlayStopped()
        })

        this.player.on("pause", () => {
            enablePageRefresh();
            this.setState({ showCustomPlayBtn: true })
            this.props.onPlayStopped && this.props.onPlayStopped()
        })
    }

    private executeCurrentStopActionAttachedToWindow = () => {
        window.stopCurrentPlayingPlayer &&
            (window.stopCurrentPlayingPlayer !== this.stopPlayer) &&
            window.stopCurrentPlayingPlayer()
    }

    private attachStopToWindowObj = () => {
        this.executeCurrentStopActionAttachedToWindow()
        window.stopCurrentPlayingPlayer = this.stopPlayer
    }

    private stopPlayer = () => {
        this.player.pause()
        this.player.currentTime(0)
        this.props.onPlayStopped && this.props.onPlayStopped()
        const player = window.videojs('#' + this.playerIdentifier)
        window.hasOwnProperty("google") && player && player.ads && player.ima && player.ima.pauseAd()
    }

    componentWillUnmount() {
        enablePageRefresh();
        if (window.stopCurrentPlayingPlayer == this.stopPlayer) {
            window.stopCurrentPlayingPlayer = null
        }
        //this.player && this.player.dispose()
    }

    private onPlayClick = () => {
        this.player.play()
        this.props.onPlayStarted && this.props.onPlayStarted()
        this.setState({ showCustomPlayBtn: false })
    }

    private handleMuteClicked = (e: React.MouseEvent) => {
        e.stopPropagation()
        this.setState((pState) => { return { isMuted: !pState.isMuted } })
    }

    render() {
        const { width, height } = this.props
        const { isPlayerBarVisible, isSoundButtonVisible, isLive } = this.props.data;
        const { showCustomPlayBtn, isMuted } = this.state
        const muteButtonClassName = classNames("videojs_external_mute", { "muted": this.state.isMuted });
        const containerStyle = width && height ? { width: `${width}px`, height: `${height}px` } : null
        const containerClasses = classNames("videojsContainer", { "live": isLive })

        return (
            <div data-vjs-player ref={ref => this.videoContainerRef = ref} className={containerClasses} style={{ ...containerStyle }}>

                {showCustomPlayBtn &&
                    <button onClick={this.onPlayClick} className="vjs-big-play-button force-display" >
                        <span aria-hidden="true" className="vjs-icon-placeholder"></span>
                    </button>}
                <video
                    ref={ref => this.videoNodeRef = ref}
                    muted={isMuted}
                    id={this.playerIdentifier}
                    className="video-js inlinePlayer" />
                {isSoundButtonVisible && !isPlayerBarVisible && !showCustomPlayBtn &&
                    <button className={muteButtonClassName} title="Mute Video" onClick={this.handleMuteClicked} />}
            </div>
        )
    }
}


