import * as filter from "lodash/filter"
import * as map from "lodash/map"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { TickerList } from "../../ticker/components/flashList"
import { SiteTickerPopUp } from "../../ticker/components/tickerPopUp"
import { SiteTickerData } from "../../ticker/components/tickerComponenta"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { TickerViewData } from "../interfaces"

require("../../ticker/components/site_tickerComponenta.less")
require("../../englishTicker/ynetnews_vesty_site_englishTicker.less")
require("./../../tab/componentas/site_tabComponenta.less")

interface TickerAutoViewProps {
    data: TickerViewData
    height: number
    articles: any
    isEnglish?: boolean
}


export class TickerAutoView extends React.Component<TickerAutoViewProps, {}>  {

    public render() {
        const { data, height, articles, isEnglish } = this.props
        const tabStyles = { backgroundColor: data.tabBackgroundColor }
        const mainStyleBg = { backgroundColor: data.bgColor }
        const targetTitleLink: LinkTargetProperties = {
            linkOpenType: "new",
            lightBoxBgColor: "",
            lightBoxHeight: null,
            lightBoxOpacity: null,
            lightBoxWidth: 0
        }
        let itemList = [];

        if (Object.keys(articles).length > 0) {
            itemList = filter(map(articles, (article: any, index) => {
                if (index > data.numberOfVisibleItems - 1) return null
                let image = { url: '' }

                return {
                    articlePublishDate: article.launchDate,
                    articleUpdateDate: article.dateUpdatedOnSite,
                    slotId: article.articleId,
                    title: article.title,
                    flashText: article.plainText,
                    flashDate: article.dateUpdatedOnSite,
                    flashDateType: "update",
                    link: article.publishedLink,
                    linkTarget: data.itemLinkTarget ? data.itemLinkTarget : targetTitleLink,
                    flashLinkType: "flash",
                    itemImg: article.promotionImageDetails,
                    articleId: article.articleId,
                    author: article.author,
                } as SiteTickerData

            }))
        }
        let englishSite = this.props.isEnglish ? "englishSite" : ""
        return (
            <div className={`TickerComponenta ${englishSite}`} style={mainStyleBg}>
                {data.fieldsDisplaySettings.isTabVisible &&
                    <div className="TabComponenta tickerTab" style={tabStyles}>
                        <HyperLinkBuilder className="titleLink rightTitleLink" href={data.tabHref} linkTarget={data.tabTitleLinkTarget}>
                            {
                                englishSite
                                    ?
                                    <div className="rightTitleText">{data.tabTitleText}</div>
                                    :
                                    <h3 className="rightTitleText">{data.tabTitleText}</h3>

                            }
                        </HyperLinkBuilder>
                    </div>
                }
                <div className="slotsContent" style={{ height: `${height - 24}px` }}>
                    {data.fieldsDisplaySettings.isDateVisible && <div className="tickerDate" title={separateHourFromDateAndFormatToString(new Date(), { showDate: true, showHour: false, padDateWithZero: false })}>
                        {separateHourFromDateAndFormatToString(new Date(), { showDate: true, showHour: false, padDateWithZero: false })}
                    </div>}

                    <TickerList siteFlashList={itemList} titleColor={data.titleColor} subTitleColor={data.subTitleColor} isAuto={true} isEnglish={isEnglish} />
                    {!isEnglish && <SiteTickerPopUp items={itemList} popUpTitle={data.popUpTitle} popUpTitleColor={data.popUpTitleColor} isAuto={true} />}
                </div>
            </div>
        )
    }
} 