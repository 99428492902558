import { EditorState } from "draft-js"

import { StringEnum } from "utils/stringEnum"
import { ContentTypes } from "constants/contentTypes"
import { LinkedItem } from "./linkedItem"
import { DataList } from "./dataList"
import { BasicMediaData } from "widgets/commonDataObjects/basicMediaData"
import { LinkTargetProperties, LinkProperties, BaseLinkTypes } from "global/components/linkSettings/interfaces"
import { MediaContentTypes } from "constants/contentTypes"
import { GalleryViewType } from "../commonComponents/base/basicMediaCarousel"
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"
import { LinkedItemVideoSettings } from "widgets/commonDataObjects/linkedItemsVideoSettings"
import { ChannelIconDisplay } from "../commonComponents/settings/categoryIconDisplaySelect/categoryIconDisplaySelect";

export const ComponentaIconType = StringEnum("automatic", "video", "audio", "none");
export type ComponentaIconType = StringEnum<typeof ComponentaIconType>;
export interface ComponentaIcon {
    value: ComponentaIconType
    label: string
}
export const LinkedItemType = StringEnum(
    ContentTypes.ARTICLE,
    ContentTypes.CATEGORY,
    ContentTypes.TAG,
    ContentTypes.EXTERNAL_URL,
    ContentTypes.NONE,
    ContentTypes.COMMERCIAL,
    ContentTypes.FILE,
    ContentTypes.MAIL,
    ContentTypes.MEDIA_VIDEO,
    ContentTypes.STOCK  
);
export type LinkedItemType = StringEnum<typeof LinkedItemType>;

export interface IComponentaItem {
    itemType: LinkedItemType
}

export interface ILinkedItem extends IComponentaItem {
    itemType: LinkedItemType
    itemId: string
    title: string // RichInputData
    subTitle: string
    author: string
}

export interface IComponentaImageItem extends IComponentaItem {
    imageId: string

}

export interface IRichInputData {
    richInputState: EditorState
}

export interface ILinkedItemWidgetData {
    slots: DataList<ILinkedItemSlotData>
    fieldsDisplaySettings?: LinkedItemFieldDisplay
    titleColor?: string
    subTitleColor?: string
    bgColor?: string
    titleFont?: string
    subTitleFont?: string
    gallerySeconds?: number
    videoSettings?: LinkedItemVideoSettings
    galleryViewOption?: GalleryViewType
    channelIconDisplay?: ChannelIconDisplay
    channelIconUrl?: string
    displayType?: string
}
export interface IFieldDisplayData {
    isAuthorVisible?: boolean
    isTitleVisible?: boolean
    isSubTitleVisible?: boolean
    isTitleLinkVisible?: boolean
    isDateVisible?: boolean
    isHourVisible?: boolean
    isTabVisible?: boolean
    isLineVisible?: boolean
    isCategoryVisible?: boolean
    isChannelNameVisible?: boolean
    isAdVisible?: boolean
    isMobileYplusLogoVisible?: boolean,
    isDesktopYplusLogoVisible?: boolean,
    isRoofTitleVisible?: boolean,
}
export interface VideoSettingsData {
    isVideoLightbox?: boolean
    hideVideoAds?: boolean
}
export interface ILinkedItemSlotData {
    slotId: string
    item: Partial<LinkedItem>
    media: DataList<BasicMediaData>
    link: LinkProperties<BaseLinkTypes>
    linkTarget: LinkTargetProperties
    videoBehaivior?: VideoBehaiforData
}
export interface VideoBehaiforData {
    isLive?: boolean
    isAutoPlaying?: boolean
    isLoopPlaying?: boolean
    isPlayerBarVisible?: boolean
    isSoundButtonVisible?: boolean
    isMuteAvailable?: boolean
}
export type RoofTitleDisplay = "blinkdot" | "redlabel";
export type MoreTitlesDisplay = "articles" | "blogs";
export type ViewModeDisplay = "light" | "dark";
export enum SlotDeviceDisplay {
    desktop = 1,
    mobile = 2,
    both = 3,
}

export enum MobileTestPageOption {
    normal = 1,
    test = 2,
    both = 3,
}