import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { getDiffFromToday } from "../../../siteWidgets/formatDateDisplay";
import * as classNames from "classnames"
interface DateProps {
    date: Date
}
export class CtechDateDisplay extends React.Component<DateProps, {}> {

    private viewDate = (): boolean => {
        const { date } = this.props
        const dateToManipulateTemp = new Date(date)
        const diffFromToday = getDiffFromToday(dateToManipulateTemp)
        return diffFromToday > 0
    }

    public render() {
        const { date } = this.props;

        return (<span className={classNames("ctechDate", { "red": !this.viewDate() })} >{separateHourFromDateAndFormatToString(date, { showDate: this.viewDate(), showHour: !this.viewDate() })}</span>)

    }
}