import { secondsToHms } from "../../../siteWidgets/siteWidgetUtils";
import { getDevice } from "../../../siteWidgets/videoPlayer/videoPlayerAds";
import * as ResizeObserver from "resize-observer-polyfill";
require("./ynet_pplus_magazine_site_aodFloatingPlayer.less");

export interface AodFloatingPlayerProps {
    isAudioPlaying: boolean
    playbackTime: number
    onClose: () => void
    audioElement: HTMLAudioElement
    durationSeconds: number
    isFloatingPlayerVisible: boolean
    isControlsDisabled: boolean
    isSeeking: boolean
    onseeked: (playbackTime: number) => void
}

interface AodFloatingPlayerState {
    floatingPlayerBottomHeight: number,
    popupMessageCalssName: string,
}

export class AodFloatingPlayerMobileWeb extends React.Component<AodFloatingPlayerProps & { title: string, articleLink?: string }, AodFloatingPlayerState> {
    constructor() {
        super();
        this.state = {
            floatingPlayerBottomHeight: 0,
            popupMessageCalssName: "hide",
        }
    }

    private resizeObserver: ResizeObserver;

    componentDidMount() {
        const elementId = "ads.banner";
        const stickyAdElement = document.getElementById(elementId)

        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            this.onContentHeightChanged(r.contentRect.height)
        }))

        stickyAdElement && this.resizeObserver.observe(stickyAdElement);
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect()
    }

    private onContentHeightChanged = (height: number) => {
        this.setState({ floatingPlayerBottomHeight: height })
    }

    private onToggleAudioPlaying = () => {
        const { isAudioPlaying } = this.props
        isAudioPlaying ? this.props.audioElement.pause() : this.props.audioElement.play()
    }

    private jumpForward = () => {
        this.props.audioElement.currentTime += 15;
    }

    private jumpPrev = () => {
        this.props.audioElement.currentTime -= 15;
    }

    private updateCurrentTime = (e) => {
        this.props.onseeked(Number(e.target.value));
    }

    private openNativeShare = async () => {
        const shareData = { url: this.props.articleLink, title: this.props.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    private handleCopyLink = () => {
        if (typeof navigator !== "undefined") {
            this.openNativeShare();
        }
    }

    render() {
        const { isAudioPlaying, onClose, title, durationSeconds, audioElement, isFloatingPlayerVisible, isControlsDisabled, playbackTime, isSeeking, articleLink } = this.props
        const { floatingPlayerBottomHeight, popupMessageCalssName } = this.state
        const mobileDeviceType = getDevice();
        const duration = audioElement && audioElement.src !== "" && audioElement.duration && audioElement.duration !== Infinity ? audioElement.duration : durationSeconds;

        return (
            <div className={`aod-floating-player-wrapper ${isFloatingPlayerVisible ? "show" : "hide"}`} style={{ bottom: floatingPlayerBottomHeight }} >
                <button className="aod-player-close-sign" onClick={onClose} aria-label="close player"></button>

                <div className="aod-floating-player">

                    <div className="aod-player-controls">
                        <button className={`aod-play-button ${isControlsDisabled || isSeeking ? "aod-play-button-loader" : ""}`} onClick={this.onToggleAudioPlaying} aria-label={isAudioPlaying ? `pause - ${title}` : `play - ${title}`}>
                            <div className={`aod-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                        </button>

                        <button className={`forwardStep jumpto-button ${isControlsDisabled ? "disabled" : ""}`} onClick={this.jumpForward} disabled={isControlsDisabled} aria-label="skeep 15 seconds">15</button>
                        <button className={`prevStep jumpto-button ${isControlsDisabled ? "disabled" : ""}`} onClick={this.jumpPrev} disabled={isControlsDisabled} aria-label="go backwards 15 seconds">15</button>

                        <div className={`aod-player-controls-ruler-wrapper ${isControlsDisabled ? "disabled" : ""}`}>
                            <div className="aod-player-controls-ruler">
                                <div className="">
                                    <input type="range" className="timeline" max={duration} value={playbackTime} onChange={this.updateCurrentTime} disabled={isControlsDisabled} />
                                </div>

                                <div className="aod-player-durations">
                                    <span>{secondsToHms(playbackTime)}</span>
                                    <span>{secondsToHms(duration)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="aod-player-title-btn-container">
                        {articleLink &&
                            <div className="aod-player-copy-btn-wrapper">
                                <div className={`aod-player-copy-link-message ${popupMessageCalssName}`}>הקישור הועתק ללוח</div>
                                <button className="aod-player-copy-link-btn" onClick={this.handleCopyLink}>
                                    {mobileDeviceType === "iphone" ? <div className="copy-link-iphone-icon"></div> : <div className="copy-link-android-icon"></div>}
                                </button>
                            </div>}

                        <div className="aod-player-show-title-wrapper">
                            <div className={"aod-player-show-title"} dangerouslySetInnerHTML={{ __html: title || 'אין תוכנית לנגן' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}