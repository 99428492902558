import { SiteVideoData } from "../../../interfaces"
import { isYouTubeGlobalSettingEnabled } from "../videoPlayerUtils"
import { YoutubePlayerForLightbox } from "./youtubePlayerForLightbox/youtubePlayerForLightbox";
import { FlowPlayerForInline } from "../genericInlineVideoPlayer/flowPlayerForInline/flowPlayerForInline"
import { PlayersTypes } from "../genericInlineVideoPlayer/genericInlineVideoPlayer"
import { HolaPlayerForInline } from "../genericInlineVideoPlayer/holaPlayerForInline/holaPlayerForInline"
import { YITVideoJsPlayerForInline } from "../genericInlineVideoPlayer/YITVideoJsForInline"
import { VideoJsPlayerForInline } from "../genericInlineVideoPlayer/videoJsForInline/videoJsForInline"

interface GenericLightboxVideoPlayerProps {
    data: SiteVideoData
    width: number
    height: number
    hideVideoAds?: boolean
    applyStickyness: boolean
    focusCloseBtn?: () => void
    onSelect?: () => void
}

export class GenericLightboxVideoPlayer extends React.Component<GenericLightboxVideoPlayerProps, {}> {
    private getDefaultNativePlayer = (): PlayersTypes => {
        if (typeof window !== "undefined") {
            return window["defaultNativeVideoType"] as PlayersTypes
        }
        return null
    }

    componentDidMount() {
        window.YitVideo && window.YitVideo.InfrastructureReInitialize();
    }


    private renderPlayer(playerType: PlayersTypes) {
        const isYoudube = isYouTubeGlobalSettingEnabled(this.props.data)
        if (isYoudube) {
            return <YoutubePlayerForLightbox {...this.props} />
        }
        switch (playerType) {
            case "flowplayer":
                return <FlowPlayerForInline key={`${Math.random()}`} {...this.props} />

            case "hola":
                return <HolaPlayerForInline isInLightbox={true} key={`${Math.random()}`}  {...this.props} />

            case "videojs":
                return <VideoJsPlayerForInline isInLightbox={true} key={`${Math.random()}`}  {...this.props} />

            case "yitvideo":
                return <YITVideoJsPlayerForInline {...this.props} />
        }
        return null
    }

    render() {
        return (
            this.renderPlayer(this.getDefaultNativePlayer())
        )
    }
}