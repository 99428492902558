import { GridPlacedItem, WidgetLayoutData, LayoutType, GridItemResizeOption, WidgetLayoutContentData } from "pageGridLayout/interfaces"
import { ImageDetails } from "images/interfaces"
import { MediaVideoData } from "media/interfaces"
import { ComponentaProps, Componenta } from "pagesPage/stateInterfaces"
import { DataObjectBase } from "widgets/commonDataObjects/dataObjectBase"
import { CopyMediaImageCommand, CopyImageCommand } from "images/actions/imagesActions"
import { ArticleData, ArticleFrontOnlyData, ArticleTopStoryData, } from "../articlePage/stateInterfaces"
import { StringEnum } from "utils/stringEnum"
import { ContentTypes, MediaContentTypes } from "constants/contentTypes"
import { LoadComponentaArticleResourceCommand } from "pagesPage/actions/componentaActions"
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { VideoBehaiforData } from "widgets/commonDataObjects/interfaces"
import { WidgetJson, ImageDataJson } from "./jsonSerializer/widgetJsonInterfaces";
import { DeviceType } from "widgets/jsonSerializer/widgetJsonInterfaces"
import { PageData, CategoryTreeNode, CategoryData } from "../pagesPage/stateInterfaces"
import { TagsEntity } from "../tags/stateInterface"
import { AspectRatio } from "../media/components/videoUpload/newVIdeoUpload/videoEncoderContainer"
import { LabelPlace } from "./defenitions/topStory/labelIconUtils";

export const LinkedPageResourceTypes = StringEnum(
    ContentTypes.ARTICLE,
    ContentTypes.MEDIA_VIDEO,
    "IMAGE",
    ContentTypes.TAG,
    ContentTypes.CATEGORY,
    ContentTypes.FILE
)
export type LinkedPageResourceTypes = StringEnum<typeof LinkedPageResourceTypes>;

export interface WindowSizes {
    width: number
    minWidth: number
    maxWidth: number
    height: number
    minHeight: number
    maxHeight: number
}

export type LinkedPageResourceByType = { [T in LinkedPageResourceTypes]: string[] }

export interface LinkedPageResource {
    resourceType: LinkedPageResourceTypes
    resourceId: string
}

export type LoadComponentaExtraDataFunction = (componentaId: string, requestData: any, widgetType?: string) => void

export type PageComponentaContextTypes = { [K in keyof PageComponentaContext]: __React.Requireable<any> }

export interface PageComponentaContext {
    pageId: string
    componentaId: string
    getArticle(articleId: string): ArticleData
    getImage(imageId: string): ImageDetails
    getVideo(videoID: string): MediaVideoData
    getImageSize(slotIndex?: number): { width: number, height: number }
    getCurrentPage(): PageData
    getPageCategory(categoryId: string): CategoryTreeNode
    copyMediaImage: CopyMediaImageCommand
    copyImage: CopyImageCommand,
    loadComponentaArticleResource: LoadComponentaArticleResourceCommand
    loadComponentaExtraData: LoadComponentaExtraDataFunction
    colorList?: string[]
    getWidget?(type: string): PageWidgetType<any>
    getUrl?(link: LinkProperties<BaseLinkTypes>, shouldAddArticleAutoplayIndicator?: boolean): string
    widgetType?: string,
    orderedImages?: any,
    loadCategoryFields?(categoryId: string): void
    siteLayoutType: string
    getTag?: (tagId: string) => TagsEntity
}

export type ArticleContextTypes = { [K in keyof ArticleContext]: __React.Requireable<any> }
export interface ArticleContext {
    getUrl(link: LinkProperties<BaseLinkTypes>, shouldAddArticleAutoplayIndicator?: boolean): string
}

export type ArticleComponentaContextTypes = { [K in keyof ArticleComponentaContext]: __React.Requireable<any> }
export interface ArticleComponentaContext {
    articleId: string
    componentaId: string
    componentaGridColumnWidth?: number
    componentaHeight?: number
    getArticle(articleId: string): ArticleData
    getImage(imageId: string): ImageDetails
    getVideo(videoId: string): MediaVideoData
    onEditModeChanged?: (isStarting: boolean) => void
    getUrl?(link: LinkProperties<BaseLinkTypes>, shouldAddArticleAutoplayIndicator?: boolean): string
    copyImage: CopyImageCommand,
    loadComponentaArticleResource: LoadComponentaArticleResourceCommand
    copyMediaImage: CopyMediaImageCommand
    isTopStoryExist?: boolean
    siteLayoutType?: string
}

export interface ImmutableDataObject<T> {
    readonly immutableData: T
}

export interface ComponentaInstanceDataPropsBase<TComponentaData extends DataObjectBase> {
    data: TComponentaData
    extraData?: any
    onChange: (data: TComponentaData) => void
    widgetType?: string
}


export interface ComponentaInstancePropsBase<TComponentaData extends DataObjectBase> extends ComponentaInstanceDataPropsBase<TComponentaData> {
    inEdit?: boolean
    layoutData?: WidgetLayoutContentData
}

export interface ComponentaInstanceProps<TComponentaData extends DataObjectBase> extends ComponentaInstancePropsBase<TComponentaData> {
    onContentHeightChanged: (newHeight: number) => void
}

export interface ComponentaWindowInstanceProps<TComponentaData extends DataObjectBase> extends ComponentaInstanceDataPropsBase<TComponentaData> {
    itemLayoutData?: WidgetLayoutData
    componentaProps: Partial<ComponentaProps>
    onLayoutChange?: (layout: Partial<WidgetLayoutData>) => void
    onComponentaPropsChange?: (componentaProps: Partial<ComponentaProps>) => void
}

export interface WidgetsMap {
    [id: string]: WidgetType<any>
}
export interface WidgetType<TComponentaData> {
    type: string
    name: string
    iconUrl: string
    groupName?: string
    noPrint?: boolean
    hidden?: boolean
}

export interface PageWidgetRenderer<TComponentaData extends DataObjectBase> {
    (props: ComponentaInstanceProps<TComponentaData>): React.ReactElement<ComponentaInstanceProps<TComponentaData>>
}

export interface SiteComponentaProps<TComponentaData extends DataObjectBase> {
    data: TComponentaData
    extraData?: any
    layoutData?: WidgetLayoutContentData
}

export interface JsonProps<TComponentaData extends DataObjectBase> {
    data: TComponentaData
    componentaId: string
    extraData?: any
    getArticle(articleId: string): ArticleData & ArticleFrontOnlyData
    getImage(imageId: string): ImageDetails
    getVideo(videoID: string): MediaVideoData
    getUrl(link: LinkProperties<BaseLinkTypes>): string
    getCurrentPage(pageId: string): PageData
    getPageCategory(categoryId: string): CategoryTreeNode
    deviceType: DeviceType
    imagesDomain: string
    audioDomain: string
    domain: string
    defaultImageJson: ImageDataJson
    subTitleSameAsTitle?: boolean
    jsonVersion: number
    loadCategoryFields?(categoryId: string): void
    isRoofTitle?: boolean
    getTag?: (tagId: string) => TagsEntity
    inTest?: boolean
}

export interface JsonSerialzar<TComponentaData extends DataObjectBase> {
    (props: JsonProps<TComponentaData>): WidgetJson
}

export interface ArticleTopStoryEditorProps<TArticleTopStoryData extends object> {
    articleId: string
    widgetType: string
    data: TArticleTopStoryData
    onChange(widgetType: string, data: TArticleTopStoryData): void
}

export interface ComputeGeneratedHeightProps<TArticleTopStoryData extends object> {
    topStoryData: TArticleTopStoryData
    articleData: ArticleData
    state: any
    placedItemWidth: number
}

export interface ArticleTopStoryEditorRenderer<TArticleTopStoryData extends object> {
    renderer(props: ArticleTopStoryEditorProps<TArticleTopStoryData>): React.ReactElement<ArticleTopStoryEditorProps<TArticleTopStoryData>>
    getLinkedResources(data: TArticleTopStoryData): LinkedPageResource[]
    computeGeneratedHeight(props: ComputeGeneratedHeightProps<TArticleTopStoryData>): number
    convertFromSimpleObject?(obj: object): TArticleTopStoryData
    convertToSimpleObject?(obj: TArticleTopStoryData): object
    orderedImages?: {
        getorderedImagesIds(data: TArticleTopStoryData, isImageExists: (imageId: String) => boolean): string[]
        weight: number
    }
    showOnSettingsSide?: boolean
}

export interface PageWidgetType<TComponentaData extends DataObjectBase, TArticleTopStoryData extends object = {}> extends WidgetType<TComponentaData> {
    defaultColumnSpan?: number
    defaultHeight?: number
    getDefaultGridPlacedItem: () => GridPlacedItem
    mergeDefaultData: (oldData: { __version: number }) => TComponentaData
    render: PageWidgetRenderer<TComponentaData>
    articleRender?: (props: InlineArticleComponentProps<TComponentaData>) => React.ReactElement<InlineArticleComponentProps<TComponentaData>>
    articleRenderAtSite?: (props: InlineArticleComponentProps<TComponentaData>) => React.ReactElement<InlineArticleComponentProps<TComponentaData>>
    articleRenderMobileApp?: (props: InlineArticleComponentProps<TComponentaData>) => React.ReactElement<InlineArticleComponentProps<TComponentaData>>
    articleRenderMobileWeb?: (props: InlineArticleComponentProps<TComponentaData>) => React.ReactElement<InlineArticleComponentProps<TComponentaData>>
    windows: Array<WidgetWindowDefiniton<TComponentaData>>
    templateWidgetTypes?: string[]
    layoutType: LayoutType
    defaults?: PageWidgetDefaults
    resizeOption?: GridItemResizeOption
    heightOnHorizontalResize?: (columnSpan: number) => number
    minColumnSpan?: number
    maxColumnSpan?: number
    minHeight?: number
    verticalGrowStep?: number
    imageSize?: (props: { layoutSize: WidgetLayoutContentData, data: TComponentaData, slotIndex: number }) => { width: number, height: number }
    updateDefualtData?: (data: TComponentaData) => TComponentaData
    renderSite?: <P extends SiteComponentaProps<TComponentaData>>(props: SiteComponentaProps<TComponentaData>) => React.ReactElement<P>
    getExtraDataSpec?: (data: TComponentaData) => any
    getExtraUrlDataSpec?: (comp: Componenta, reduxData: any, pageId: string) => { url: string, runIntervalMinutes?: number, dataToSave?: any }
    isInheritingFromParent?: (data: TComponentaData) => boolean
    toJson?: JsonSerialzar<TComponentaData>
    showInMobile?: boolean
    renderMobile?: PageWidgetRenderer<TComponentaData>
    renderMobileApp?: PageWidgetRenderer<TComponentaData>
    renderResponsiveSite?: <P extends SiteComponentaProps<TComponentaData>>(props: SiteComponentaProps<TComponentaData>) => React.ReactElement<P>
    extraDataType?: string
    articleTopStoryEditorRenderer?: ArticleTopStoryEditorRenderer<TArticleTopStoryData>
    widgetTabName?: (data: TComponentaData) => string
    isFixedInHtmlLayout?: boolean
    inTest?: boolean
}

export interface SitePageWidgetType {
    [siteName: string]: PageWidgetType<any>[]
}

export interface PageWidgetDefaults {
    numberOfItems: number
}

export interface WidgetWindowDefiniton<TComponentaData extends DataObjectBase> {
    windowType: WidgetWindowType
    title?: string
    className?: string
    windowSizes: WindowSizes
    render: PageWidgetWindowRenderer<TComponentaData>
}

export interface PageWidgetWindowRenderer<TComponentaData extends DataObjectBase> {
    (props: ComponentaWindowInstanceProps<TComponentaData>): React.ReactElement<ComponentaWindowInstanceProps<TComponentaData>>
}

export interface WidgetWindowType {
    title?: string
    icon: string
    className?: string
}

export interface ArticleWidgetComponentaStyle {
    height: number
    width: number
    float: string
}

export interface ArticleSiteRenderProps<T> {
    articleData: ArticleData
    data: T
}

export interface ArticleWidgetType<TComponentaData extends DataObjectBase> extends WidgetType<TComponentaData> {
    render: (props: ArticleContentComponentaInstanceProps<TComponentaData>) => React.ReactElement<ArticleContentComponentaInstanceProps<TComponentaData>>
    articleRenderAtSite?: (props: ArticleSiteRenderProps<TComponentaData>) => React.ReactElement<ArticleSiteRenderProps<TComponentaData>>
    mobileAppRender?: (props: ArticleSiteRenderProps<TComponentaData>) => React.ReactElement<ArticleSiteRenderProps<TComponentaData>>
    mobileWebRenderAtSite?: (props: ArticleSiteRenderProps<TComponentaData>) => React.ReactElement<ArticleSiteRenderProps<TComponentaData>>
    mobileWebRender?: (props: ArticleContentComponentaInstanceProps<TComponentaData>) => React.ReactElement<ArticleContentComponentaInstanceProps<TComponentaData>>
    contentWindow?: ArticleContentWindow<TComponentaData>
    mergeDefaultData?: (oldData) => any
    defaults?: ArticleWidgetDefaults
    styleConstants?: React.CSSProperties
    toVgnData?: (props: { data: TComponentaData, getImage(imageId: string): ImageDetails }) => any
    getImages?: (props: { data: TComponentaData, getImage(imageId: string): ImageDetails }) => [{ imageId: string, domNodeId: string }]
    buttonPosition?: ArticleWidgetButtonsPosition
    breakButtonLine?: boolean
}

export interface SiteArticleWidgetType {
    [siteName: string]: ArticleWidgetType<any>[]
}

export interface ArticleContentWindow<ComponentaData extends DataObjectBase> {
    render: (props: ArticleContentComponentaInstanceProps<ComponentaData>) => React.ReactElement<ArticleContentComponentaInstanceProps<ComponentaData>>
    windowType: WidgetWindowType
    windowSizes: WindowSizes
    title?: string
    className?: string
}

interface ArticleWidgetDefaults {
    height: number
}

export type ArticleWidgetButtonsPosition = "inside" | "above";

export type TabLinkType = "image" | "text";

export type MobileDisplayType = "basic" | "special";
export interface InlineArticleComponentProps<TComponentaData extends DataObjectBase> {
    data: ArticleData
    componentaData?: TComponentaData
    onChange(articleData: ArticleData): void
    layoutData?: WidgetLayoutContentData
    getImage(imageId: string): ImageDetails
}
export interface ArticleContentComponentaInstanceProps<T extends DataObjectBase> {
    articleData?: ArticleData
    data: T
    height?: string
    alignment?: string
    orderInType?: number
    onChange: (data: T) => void,
    onChangeHeight?: (newHeight: string) => void,
    onMoveToTop?: () => void
}

export type SiteMediaData = SiteImageData | SiteVideoData | YnetTvSiteVideoData | SiteVideoLiveData
export interface SiteImageData {
    mediaType: typeof MediaContentTypes.MEDIA_IMAGE
    url: string
    title: string
    alt: string
    itemLinkUrl?: string
    className: string
    credit: string
    mediaId?: string
    ariaHidden?: boolean
    poster?: string
    labelText?:string
    textPlace?:LabelPlace
}
export interface SiteVideoLiveData extends BasicSiteVideo {
    mediaType: typeof MediaContentTypes.MEDIA_LIVE | typeof MediaContentTypes.MEDIA_IFRAME
}

export interface SiteVideoData extends BasicSiteVideo {
    mediaType: typeof MediaContentTypes.MEDIA_VIDEO
    categoryId?: string
}

export interface BasicSiteVideo extends VideoBehaiforData {
    mediaId?: string
    url: string
    downGradeUrl: string
    poster: string
    title?: string
    itemLinkUrl?: string
    isBigVideo?: boolean
    credit?: string
    youtube_id: string
    blockAbroadViews: boolean
    aspectRatio?: AspectRatio
    videoName?: string
    promoPath?: string
    duration?: number
    dateCreated?: Date
    videoType?: string
    videoPageId?: string
}

export interface YnetTvSiteVideoData extends VideoBehaiforData {
    mediaType: typeof MediaContentTypes.MEDIA_VIDEO
    url: string
    downGradeUrl: string
    poster: string
    title?: string
    subTitle?: string
    linkText?: string
    itemLinkUrl?: string
    isBigVideo?: boolean
    credit?: string
    mediaId?: string
    youtube_id: string
    blockAbroadViews: boolean
    aspectRatio?: AspectRatio
    promoPath?: string
    duration?: number
    videoType?: string
}

export interface SiteItemData {
    itemId?: string
    itemType?: string
    icon?: any
    dateUpdatedOnSite?: Date
    hasVideo?: boolean
    author?: string
    title?: string
    titleLink?: string
    titleTargetLink?: LinkTargetProperties
    subTitle?: string
    media: SiteMediaData
    liveMedia?: SiteVideoLiveData
    bottomText?: string
    bottomTextLink?: string
    bottomTextLinkTarget?: LinkTargetProperties
    labelUrl?: string
    labelLink?: string,
    labelLinkTarget?: LinkTargetProperties,
    labelName?: string
    premiumExpireDays?: number
    isCommertial?: boolean
    isDFP?: boolean
    dfpId?: string
    subchannelName?: string
    categoryName?: string
    isYplusItem?: boolean
    isMarketingContent?: boolean
    marketingContentText?: string
    labelObj?: any
    promotionText?: string
    redLabelText?:string 
    textPlace?:LabelPlace
    audioArticleData?:any
    roofTitle?:string
}

export enum DateFormat {
    dmy = "dmy",
    mdy = "mdy",
}
export interface dateData {
    dateFormat: DateFormat
    timeZone: string
}

export type ArticleImageWidth = "regular" | "thin" | "wide";
