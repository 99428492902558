import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"
import { CartoonArticleItemSlot } from "./cartoonArticleItemSlot"

interface CartoonArticleListProps {
    itemList: ArticleExtraData[]
    onSelectedItemIndexChange: (index) => void
}

export class CartoonArticleList extends React.Component<CartoonArticleListProps, {}>{
    public render() {
        const { itemList, onSelectedItemIndexChange } = this.props
        return (
            <div className="CartoonArticleList slotList" >
                {itemList.map((item, i) =>
                    <CartoonArticleItemSlot
                        key={i}
                        article={item}
                        onSelectedItemIndexChange={onSelectedItemIndexChange}
                        currIndex={i}
                    />)}
            </div>
        )
    }
}