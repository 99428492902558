declare var window: Window & {
    removeTaboolaContentOverwriteClass: any
    shouldRenderTaboolaOverwriteCb: any
}

export interface taboolaProps {
    mode: string
    container: string
    placement: string
    target_type: string
    isTabollaContainerOverwrite?: boolean
}

export class TaboolaComponenta extends React.Component<taboolaProps, {}>{
    public static siteScriptName = "TaboolaComponenta"

    componentDidMount() {
        window._taboola = window._taboola || [];
        window._taboola.push({
            mode: this.props.mode,
            container: this.props.container,
            placement: this.props.placement,
            target_type: this.props.target_type,
            on_error: this.props.isTabollaContainerOverwrite ? window.removeTaboolaContentOverwriteClass : undefined,
            should_render: this.props.isTabollaContainerOverwrite ? window.shouldRenderTaboolaOverwriteCb : undefined,
        })
    }
    render() {

        return <div id={this.props.container}></div>

    }
}    