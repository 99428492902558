require("./yplus_site_newsLetter.less");
import * as classNames from "classnames"
import * as axios from "axios"

interface NewsLetterSignBoxProps {

    componentaId: string
    changeMailValue: (value: string) => void
    mailValue: string
    accountName: string
    itemId: string
}

interface NewsLetterSignBoxState {
    sendStatus: SendStatus
    checkedTerms: boolean
    mailValue: string
}
enum SendStatus {
    beforeSend = 0,
    validationError = 1,
    sent = 2,
    networkError = 3,
    sendPending = 4,
}

declare var grecaptcha: any
declare var window: any



export class NewsLetterYplusSignUp extends React.Component<NewsLetterSignBoxProps, NewsLetterSignBoxState>{
    constructor(props) {
        super(props)
        this.state = {
            sendStatus: 0,
            checkedTerms: false,
            mailValue: "",
        }
    }



    private validateEmail = (email: string) => {
        if (!email) return false;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private showError = (isMailError: boolean, checkedTerms: boolean) => {
        const { sendStatus } = this.state
        let text = "";
        if (sendStatus === SendStatus.validationError) {
            if (!checkedTerms) {
                text = "נדרש לאשר הסכמה לתנאי השימוש ומדיניות הפרטיות";
            }
            if (isMailError) {
                text = "יש למלא כתובת מייל תקינה";
            }
        } else if (sendStatus === SendStatus.networkError) {
            text = "אירעה שגיאה בשליחה, נסה מאוחר יותר"
        }
        return text;
    }
    private changeTerms = () => {
        this.setState({ checkedTerms: !this.state.checkedTerms })
    }
    private onHandleSend = () => {
        const isTest = window.location.search.includes("is_test=1");
        if (isTest) {
            this.sendSub("", true);
        } else if (window.wcmCaptchaSiteKey && grecaptcha) {
            grecaptcha.ready(() => {
                grecaptcha.execute(window.wcmCaptchaSiteKey, { action: 'submit' }).then(token => {
                    this.sendSub(token, false);
                })
            })
        }
    }


    private sendSub = async (token: string, isTest: boolean) => {

        const { checkedTerms } = this.state
        let postData = {
            groupId: parseInt(this.props.itemId) || 0,
            email: this.props.mailValue,
            token,
            accountName: this.props.accountName
        }
        if (this.validateEmail(this.props.mailValue) && checkedTerms) {
            try {
                this.setState({ sendStatus: SendStatus.sendPending });
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({ 'event': 'GA_event', 'Category': 'Newsletter', 'Action': 'Registered to Newsletter', 'Label': "Yplus" });
                const response = await axios.post(`/api/newsletter/${this.props.componentaId}/subscribeToGroup${isTest ? "?isTest=1" : ""}`, postData)
                if (response.status === 200) {
                    this.setState({ sendStatus: SendStatus.sent });
                }
            } catch {

                this.setState({ sendStatus: SendStatus.networkError })
            }
        }
        else {
            this.setState({
                sendStatus: 1
            })
        }
    }

    render() {
        const { sendStatus, checkedTerms } = this.state
        const termsLink = <a href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html" target="_blank">תנאי השימוש</a>
        const policyLink = <a href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html" target="_blank">מדיניות הפרטיות</a>
        const emailError = sendStatus == SendStatus.validationError && !this.validateEmail(this.props.mailValue);
        const checkboxError = sendStatus === 1 && !checkedTerms ? "error" : "";
        const emailErrorClass = emailError ? "error" : "";

        return (
            <div className={classNames("NewsLetterYplusSignUp", { "messageSent": sendStatus === 2 })}>
                {sendStatus === 2 && <div className="emailInput success">הרשמתך התקבלה בהצלחה!</div>}
                {sendStatus !== 2 && <> <div className="emailInput">
                    <span>לעוד כתבות פלוס, הירשמו</span>
                    <input placeholder="דוא''ל" type="text" name="mail" required className={emailErrorClass} onChange={(e) => this.props.changeMailValue((e as any).target.value)} />
                    <button className="sendBtn" disabled={sendStatus === 4} onClick={this.onHandleSend}>{sendStatus === 4 ? "שולח..." : "להרשמה"}</button>
                    <div className="errorMessage">{this.showError(emailError, checkedTerms)}</div>
                </div>
                    {this.props.mailValue.length > 0 && <div className="acceptTerms">
                        <input type="checkbox" id="accept-terms-newsletter" className={checkboxError} onChange={this.changeTerms} />

                        <span> מסכים/ה ל{termsLink}, {policyLink} ולקבלת פניות שיווקיות מ-ynet כולל טלפוניות, מבלי שהנ"ל מהווה הסכמה לעריכת עסקה</span>
                    </div>}</>}
            </div>

        )


    }
}
