import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"

export interface LogoProps {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}

export class LogoComponenta extends React.Component<LogoProps, {}> {
    constructor(props) {
        super(props)
    }

    render() {
        const { url, mediaTitle, link, linkTarget } = this.props

        return (
            <div className="logo no-print">
                <HyperLinkBuilder href={link} linkTarget={linkTarget} ariaLabel={mediaTitle}>
                    <SiteSimpleImage src={url} alt={mediaTitle} title={mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                </HyperLinkBuilder>
            </div>
        )
    }
}