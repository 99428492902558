require('./site_articleRecipeRating.less')
import * as axios from "axios"
import { ArticleData } from "../../../articlePage/stateInterfaces";
import { ArticleRating } from "./articleRecipeRatingComponenta";

interface Props {
    articleId: string
}

interface State {
    rating: number
    mouseOver: number
    alreadyRated: boolean
}

export class SiteArticleRecipeRatingComponenta extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            rating: 0,
            mouseOver: 0,
            alreadyRated: false
        }
    }

    public static siteScriptName = "SiteArticleRecipeRatingComponenta";

    componentWillMount() {
        const rating = this.getRating();
        if (rating) {
            this.setState({
                rating: rating,
                alreadyRated: true
            })
        }
    }

    private onChangeRating = (rating: number) => {
        const { articleId } = this.props
        const { alreadyRated } = this.state

        if (alreadyRated) return;

        if (typeof localStorage == "undefined") return;

        const articleRatings = localStorage.getItem("articleRatings")
        let ratingsArray = articleRatings ? JSON.parse(articleRatings) as ArticleRating[] : [];
        let articleRating: ArticleRating = {};
        let previousVote: number;

        if (ratingsArray.length) {
            const articleRatingIndex = _.findIndex(ratingsArray, r => r[articleId]);
            if (articleRatingIndex > -1) {
                articleRating = ratingsArray[articleRatingIndex];
                previousVote = articleRating[articleId]
                articleRating[articleId] = rating
            } else {
                articleRating[articleId] = rating
                ratingsArray.unshift(articleRating);
                if (ratingsArray.length > 1000) {
                    ratingsArray.pop();
                }
            }
        } else { // no ratings yet
            articleRating = { [articleId]: rating }
            ratingsArray.push(articleRating);
        }

        localStorage.setItem("articleRatings", JSON.stringify(ratingsArray))
        this.setState({ rating, alreadyRated: true })

        const data = {
            "articleId": articleId,
            "starsNum": rating,
            "previousVote": previousVote
        }

        axios.post(`/api/article/save_rating/`, data)
            .catch(error => {
                console.log(error)
            })
    }

    private getRating = (): number => {
        const { articleId } = this.props
        if (typeof localStorage == "undefined") return;
        const ratingsArray = JSON.parse(localStorage.getItem("articleRatings")) as ArticleRating[];
        const articleRatingIndex = _.findIndex(ratingsArray, r => r[articleId]);
        if (articleRatingIndex > -1 && ratingsArray[articleRatingIndex]) {
            return ratingsArray[articleRatingIndex][articleId]
        }
        return 0;
    }


    private getStarFullOrEmpty = (star: number): string => {
        const { rating, mouseOver } = this.state
        if (mouseOver >= star || rating >= star) {
            return "full"
        }
        return "empty"
    }

    private setMouseOver = (star: number) => {
        if (this.state.alreadyRated) return;
        this.setState({ mouseOver: star })
    }

    private getIsBlack = (): string => {
        const { alreadyRated, mouseOver } = this.state
        if (!alreadyRated && !mouseOver) {
            return "black"
        }
        return ""
    }

    private getText = (): string => {
        const { alreadyRated } = this.state
        if (!alreadyRated) {
            return "אהבתם את המתכון? דרגו"
        } else {
            return "תודה שדירגתם"
        }
    }

    private renderStars = () => {
        const stars = [1, 2, 3, 4, 5]
        return stars.map((star) =>
            <div className={`recipe-rating-star star-${this.getStarFullOrEmpty(star)}`}
                onClick={() => this.onChangeRating(star)}
                onMouseOver={() => this.setMouseOver(star)}
                onMouseLeave={() => this.setMouseOver(0)}
                key={star}
            />)
    }

    public render() {
        const { alreadyRated } = this.state
        return (
            <div>
                <div className={`recipe-rating ${alreadyRated ? "alreadyRated" : ""}`} >
                    <div className="recipe-rating-text">{this.getText()}</div>
                    <div className={`recipe-rating-stars ${this.getIsBlack()}`}>
                        {this.renderStars()}
                    </div>
                </div>
            </div>

        )
    }
}
