import { getShareTranslations } from "../../../article/componentas/articleSideShareComponenta/translations"
import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../../widgetsUtils"
import { copyToClipboard } from "../../../../siteWidgets/socialShareButtons/utils"
require("./mynet_site_MynetArticleSideShare.less")

interface SiteMynetArticleSideShareProps {
    className?: string
    title?: string
}

export class SiteMynetArticleSideShare extends React.Component<SiteMynetArticleSideShareProps, {}> {
    public static siteScriptName = "SiteMynetArticleSideShare"
    private whatsappShareLinkElem;
    private handleShareBtnClicked = (button: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "facebook":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Side", title), "Article Side")
                    break;

                case "twitter":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Side", title), "Article Side")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, "heMynet", () => analyticsPushFunc("Mail", "Article Side", title), "Article Side")
                    break;

                case "copy":
                    copyToClipboard(current_url)
                    analyticsPushFunc("Link", "Article Side", title);
                    break;
            }
        }
    }

    private onWhatsappSharePress = () => {
        const {title} = this.props;
        analyticsPushFunc("Whatsapp", "Article Side", title);
        this.whatsappShareLinkElem.href = `https://api.whatsapp.com/send?text=${window.location.href}`
    }

    public render() {
        const lang = "he";
        const { className } = this.props
        return (
            <div className={`MynetArticleSideShareComponent ${className}`}>
                <div
                    className="facebook wrapper"
                    title="שיתוף בפייסבוק"
                    onClick={(e) => this.handleShareBtnClicked("facebook")}>
                    <span className="shareBtnLabel">
                        שיתוף בפייסבוק
                    </span>
                    <span className="shareIcon facebook"></span>
                </div>

                <div
                    className="twitter wrapper"
                    title="שיתוף בטוויטר"
                    onClick={(e) => this.handleShareBtnClicked("twitter")}>
                    <span className="shareBtnLabel">
                        שיתוף בטוויטר
                    </span>
                    <span className="shareIcon twitter"></span>
                </div>

                <div
                    className="whatsapp wrapper"
                    title="שיתוף בווטסאפ">
                    <a onClick={this.onWhatsappSharePress} ref={ref => this.whatsappShareLinkElem = ref} className="whatsappWrapper" href="" data-action="share/whatsapp/share">
                        <span className="shareBtnLabel">
                            שיתוף בווטסאפ
                        </span>
                        <span className="shareIcon whatsapp"></span>
                    </a>
                </div>

                <div
                    className="mail wrapper"
                    title="שלח לחבר"
                    onClick={(e) => this.handleShareBtnClicked("mailto")}>
                    <span className="shareBtnLabel mailto">
                        שלח לחבר
                    </span>
                    <span className="shareIcon mailto"></span>
                </div>

                <div
                    className="copy wrapper"
                    title={getShareTranslations(lang, "copy link")}
                    onClick={(e) => this.handleShareBtnClicked("copy")}>
                    <span className="shareBtnLabel">
                        {getShareTranslations(lang, "copy link")}
                    </span>
                    <span className="shareIcon copyLink"></span>
                </div>

            </div>

        )
    }
}