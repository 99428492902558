import { getDevice } from "../../../siteWidgets/videoPlayer/videoPlayerAds"

export function buildAdTag(isMobileWeb: boolean) {
    if (RUNNING_IN_SERVER) return "";
    const iu = getPlayerAdIu(isMobileWeb)
    const custParams = getCustomParmsString(window.dcPath, window.video_ad_yncd);

    return getAdTagTemplate(iu, window.video_ad_description_url, custParams)
}

const getAdTagTemplate = (iu: string, description_url: string, cust_params: string) =>
    `https://pubads.g.doubleclick.net/gampad/ads?iu=${iu}&description_url=${encodeURIComponent(description_url)}&tfcd=0&npa=0&ad_type=audio&sz=1x1&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&cust_params=${encodeURIComponent(cust_params)}`

function getPlayerAdIu(isMobileWeb: boolean) {
    let platformPart

    if (isMobileWeb) {
        platformPart = `mobileweb_${getDevice()}`;
    } else {
        platformPart = "desktop"
    }

    return `/6870/ynet/${platformPart}/radio.audio`
}

function getCustomParmsString(dcPath: string, yncd: string) {
    const searchUrlParams = new URLSearchParams(window.location.search);
    let value = `dcPath=${dcPath}&yncd=${yncd}`;

    if (searchUrlParams.has('dcStage')) {
        value += `&dcStage=${searchUrlParams.get('dcStage')}`;
    }

    return value;
}