import { openFacebookShare, analyticsPushFunc } from "../../../widgetsUtils";

interface FacebookShareButtonProps {
    textToShare?: string
    className?: string
    handleOnClick?: () => void
    url?: string
    iconSrc?: string
    isEnglish?: boolean
}

export class FacebookShareButton extends React.Component<FacebookShareButtonProps, {}>{

    private facebookIcon = "/images/icons/facebook_icon.png"
    static defaultProps = { url: "", iconSrc: "" }
    private current_url = window.location.href

    private getCurrentUrl = () => {
        const { url } = this.props
        let urlForPopUp: string;
        if (url !== "") {
            urlForPopUp = url
        }
        else { urlForPopUp = this.current_url }
        return urlForPopUp;
    }
    private getCurrentSrc = () => {
        const { iconSrc } = this.props
        let src: string;
        if (iconSrc !== "") {
            src = iconSrc
        }
        else { src = this.facebookIcon }
        return src;
    }

    private handleShareBtnClicked = () => {
        const { textToShare, handleOnClick } = this.props
        if (typeof window !== "undefined") {
            const current_url = window.location.href
            openFacebookShare(current_url, textToShare, () => analyticsPushFunc("Facebook", "Photo Gallery", textToShare), "Photo Gallery")
        }
        if (handleOnClick) { handleOnClick() }
    }

    render() {
        const { className, isEnglish } = this.props
        return <button className="fbBtn" title={isEnglish ? "Share on Facebook" : "שתפו בפייסבוק"} aria-label={isEnglish ? "Share on Facebook" : "שתפו בפייסבוק"} onClick={e => this.handleShareBtnClicked()}>
            <img src={this.getCurrentSrc()} alt={isEnglish ? "Share on Facebook" : "שתפו בפייסבוק"} className={className} aria-hidden={true} />
        </button>
    }
}


