
export type AdType = "Preroll" | "Postroll" | "Midroll"

declare let window: Window & {
    dcSite: string
}
const getAdTagTemplate = (iu: string, description_url: string, cust_params: string, midroll_params: string) =>
    `https://pubads.g.doubleclick.net/gampad/ads?sz=640x480|640x360&iu=${iu}&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=${encodeURIComponent(description_url)}&correlator=[timestamp]&plcmt=1&vpmute=0&cust_params=${encodeURIComponent(cust_params)}&${midroll_params}`

export const getCustomParmsString = (adType: AdType, dcPath: string, yncd: string, viewsCount: number, isAutoPlay: boolean, isATF: boolean, player: string, categoryId="") => {
    const viewStr = viewsCount ? `&view=${viewsCount}` : ""
    const playerStr = player ? `&player=${player}` : ""
    const permutiv = localStorage.getItem('_psegs');
    const dmpPublisherPrevDefinitionsIds = localStorage.getItem("dmp-publisher-prevDefinitionsIds");
    const searchUrlParams = new URLSearchParams(window.location.search);
    let value = `VideoPosition=${adType}&dcPath=${dcPath}&yncd=${yncd}${categoryId? `&category_id=${categoryId}`:""}&autoplay=${!!isAutoPlay}&VideoPosition_autoplay=${adType}_${!!isAutoPlay}${viewStr}&ynch=${dcPath}&user_intent=${isATF}&PositionInPage_Autoplay=${isATF ? 'ATF' : 'BTF'}_${!!isAutoPlay}${playerStr}`;
    if (permutiv) {
        value += `&permutive=${encodeURIComponent(permutiv.replace('[', '').replace(']', ''))}`
    }

    if (searchUrlParams.has('dcStage')) {
        value += `&dcStage=${searchUrlParams.get('dcStage')}`;
    }

    if (window.dcSite && window.dcSite.includes("mynet")) {
        let mynet_platform = (window.isMobile1) ? "mobile" : "desktop";
        let sitestr = window.dcSite;
        let mynet_cities = sitestr.substr(5, sitestr.length);
        value += `&mynet_platform=${mynet_platform}&mynet_cities=${mynet_cities}`;
    }

    if (WCM_FEATURES_FLAGS.ENABLE_YOUTUBE_CUST_PARAM_LOG) {
        console.log("cust params =>", value)
    }
    if (WCM_FEATURES_FLAGS.INSERT_ENV_TO_CUST_PARAMS) {
        value += `&Environment=qa`;
    }
    if (dmpPublisherPrevDefinitionsIds !== null && dmpPublisherPrevDefinitionsIds !== '') {
        value += `&dxseg=${encodeURIComponent(dmpPublisherPrevDefinitionsIds)}`
    }
    

    return value;
}

export function getImaSettings(isAutoPlay: boolean, isATF: boolean, player: string = "", duration?: number, categoryId?: string) {  
    let ads = [
        {
            time: 0,
            adTag: buildAdTag("Preroll", isAutoPlay, isATF, player, duration, categoryId),
        },
    ]
    if (window && window['videoPlayerAds']) {
        ads = window['videoPlayerAds']
    }
    return {
        locale: "he",
        ads
    };
}

const midrollIntervalInSeconds = 8 * 60;
const midrollLimitFromEndInSeconds = 2 * 60;
export function addMidrollAds(api: any, isAutoPlay: boolean, isATF: boolean, player: string = "") {
    //use one to only fire the event once so that multiple plays of the same video will not ad unnecessary ads. 
    api.one("ready", function () {
        let mid = midrollIntervalInSeconds;
        const maxDuration = api.video.duration - midrollLimitFromEndInSeconds;
        while (mid < maxDuration) {
            api.conf.ima && api.conf.ima.ads && api.conf.ima.ads.push({ time: mid, adTag: buildAdTag("Midroll", isAutoPlay, isATF, player) });
            mid += midrollIntervalInSeconds;
        }
    });
}

export function buildAdTag(adType: AdType, isAutoPlay: boolean, isATF: boolean, player: string, duration?: number, categoryId?: string) {
    const viewsCount = getNumberOfViews();
    const customParms = getCustomParmsString(adType, window.dcPath, window.video_ad_yncd, viewsCount, isAutoPlay, isATF, player, categoryId);
    const videoAdIu = getPlayerAdIu();
    const midRollParms = getMidrollParams(duration)
    return getAdTagTemplate(videoAdIu, window.video_ad_description_url, customParms, midRollParms);
}

export function getPlayerAdIu() {
    let videoAdIu = window.video_ad_iu;
    if (window.isMobile1) {
        videoAdIu = window.video_ad_iu_mobile;
        if (videoAdIu) {
            const device = getDevice();
            videoAdIu = videoAdIu.replace("{device}", device) // if there is no '{device}' in videoAdIu, this will do nothing
        }
    }
    return videoAdIu;
}

export function getNumberOfViews(): number {
    try {
        resetLocalStorageVideoViewCounter();
        return ++localStorage._ads_video_view;
    } catch (_) {
        return null;
    }
}

const threeHoursInMS = 3 * 60 * 60 * 1000;
function resetLocalStorageVideoViewCounter() {
    const dayOfMonthInThreeHours = new Date(Date.now() + threeHoursInMS).getUTCDate();
    if (dayOfMonthInThreeHours != localStorage._ads_video_view_Date) {
        localStorage._ads_video_view_Date = dayOfMonthInThreeHours;
        localStorage._ads_video_view = 0;
    }
}

export const getMidrollParams = (duration?: number): string => {
    const defaultVid_d = 90000
    const maxDuration = 25000
    const allcues = "30000,60000"
    const vid_d = duration || defaultVid_d
    return `max_ad_duration=${maxDuration}&allcues=${allcues}&vid_d=${vid_d}&ad_rule=1`
}

export function getDevice() {
    let device: "android" | "iphone" = 'android';
    if (typeof navigator !== "undefined") {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            device = 'iphone';
        }
    }
    return device;
}