import { PodcastCategoryItem } from "./podcastCategoryItem";
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";

interface podcastCategoryItemListProps {
    itemList: ArticleExtraData[]
    spotifyLink: string
    applePodcastLink: string
}

export class PodcastCategoryItemList extends React.Component<podcastCategoryItemListProps, {}>{
    public render() {
        const { itemList, spotifyLink, applePodcastLink } = this.props;

        return (
            <div className="slot-list" >
                {itemList.map((item, i) =>
                    <PodcastCategoryItem
                        key={i}
                        item={item}
                        spotifyLink={spotifyLink}
                        applePodcastLink={applePodcastLink}
                    />)}
            </div>
        )
    }
}