require("./ctech_calcalist_site_buzzItem.less")
import { isWcmEditor } from "../../../../../config/serverConfig"

export const BuzzItem = ({ item, position, isDotShow, isMobile }) => {
    return (
        <div className={isMobile?'slotItem':"buzzItem"}>
            <div className="positionHolder">
                <span className="position">{position}{isDotShow && `.`}</span>
            </div>
            <div className="itemTitle" data-tb-title>
                <a className="titleLink" href={!isWcmEditor() ? item.publishedLink : ""} data-tb-link>
                    {item.title}
                </a>
            </div>
        </div>
    )
}