import { GetDataObject } from "../dataObjectFacrories"
import { LinkedPageResource } from "../interfaces"
import { NoData } from "./noData"
export type VersionConverter<T> = (oldData: any) => T & { version: number }



export abstract class DataObjectBase {
    private readonly internalData: any;
    constructor(data?: any) {
        if (data && data.__setInternalData) {
            this.internalData = data.__setInternalData;
            return;
        }

        if (data && (<any>data).version < this.__version && this.getVersionConverters()) {
            data = <any>this.upgradeFromPreviousVersion(<any>data);
        }
        if (data instanceof DataObjectBase) {
            this.internalData = data.internalData;
        } else {
            this.internalData = this.create(data);
        }
    }

    protected abstract getDefaultData(): any

    protected abstract createNewInstance(data): this

    protected abstract GetLinkedResourcesForCurrent(): LinkedPageResource[]

    public get __version() { return 0 }

    public get __objectDataTypeName() { return this.constructor.name }

    public toJSON() {
        return { __version: this.__version, __objectDataTypeName: this.__objectDataTypeName, ...(this.internalData as any) }
    }

    public getLinkedResources() {
        return _(this._getLinkedResourcesArrayInternal()).filter(r => r && r.resourceId && r.resourceType).uniqWith(_.isEqual).value()
    }

    public _getLinkedResourcesArrayInternal() {
        let linkedResources = this.GetLinkedResourcesForCurrent();
        for (const key in this.internalData) {
            const data = this.internalData[key];
            if (data instanceof DataObjectBase) {
                linkedResources = linkedResources.concat(data._getLinkedResourcesArrayInternal())
            } else if (Array.isArray(data)) {
                for (const arrayData of data) {
                    if (arrayData instanceof DataObjectBase) {
                        linkedResources = linkedResources.concat(arrayData._getLinkedResourcesArrayInternal())
                    }
                }
            }
        }
        return linkedResources;
    }

    protected getVersionConverters(): VersionConverter<any>[] {
        return null;
    }

    protected create(data: Partial<any>): any {
        if (!data) {
            return this.getDefaultData() as any;
        }
        const newData = this.processSimpleData(data);
        return { ...(this.getDefaultData() as any), ...(newData as any) }
    }

    private processSimpleData(data: any) {
        if (!data || typeof data !== "object" || data instanceof DataObjectBase) return data;

        const newData = {};
        for (const key in data) {
            if (key.startsWith("_")) {
                continue;
            }
            let value = data[key];
            if (!(value instanceof DataObjectBase) && value) {
                if (value["__objectDataTypeName"]) {
                    value = GetDataObject(value["__objectDataTypeName"], MissingDataObjectDummy, value);
                } else if (Array.isArray(value)) {
                    value = _.map(value, item => (item["__objectDataTypeName"]) ? GetDataObject(item["__objectDataTypeName"], MissingDataObjectDummy, item) : item)
                }
            }
            newData[key] = value;
        }
        return newData;
    }

    public clone() {
        const src = this as any;
        const newData = {}
        if (!(src instanceof DataObjectBase)) {
            throw `src is not instanceof DataObjectBase ${JSON.stringify(src)}`
        }
        for (const key in src.internalData) {
            let value = src[key];
            if (value instanceof DataObjectBase) {
                value = value.clone()
            } else if (Array.isArray(value)) {
                value = _.map(value, v => (v instanceof DataObjectBase) ? v.clone() : v)
            }
            newData[key] = value;
        }
        return this.createNewInstance(newData);
    }

    protected setData(dataToUpdate: Partial<any>): this {
        let changed = false;
        for (let pair of _.toPairs(dataToUpdate)) {
            if (this.internalData[pair[0]] !== pair[1]) {
                changed = true;
                break;
            }
        }
        if (changed) {
            return this.createNewInstance({ __setInternalData: this.mergeCahnges(dataToUpdate) });
        }
        return this;
    }

    protected get data() { return this.internalData }

    private mergeCahnges(dataToUpdate: Partial<any>): any {
        return { ...(this.internalData as any), ...(dataToUpdate as any) }
    }

    private upgradeFromPreviousVersion(data: { version: number }) {
        let resultData = data;
        const versionConverters = this.getVersionConverters();
        for (let v = data.version; v < versionConverters.length; v++) {
            resultData = versionConverters[v](resultData);
        }
        return resultData;
    }

}


class MissingDataObjectDummy extends DataObjectBase {
    protected createNewInstance(data: any) {
        return new MissingDataObjectDummy(data) as this;
    }
    protected GetLinkedResourcesForCurrent() { return [] }

    protected getDefaultData() {
        return {};
    }
}