import WeatherTab from "./WeatherTab";
import WeatherMapTemprMap from "../WeatherMapExtendedComponents/WeatherTemprMap"
import WeatherPrecipitationMap from "../WeatherMapExtendedComponents/WeatherPrecipitationMap";
import WeatherHumidityMap from "../WeatherMapExtendedComponents/WeatherHumidityMap";
import WeatherWaveHeightMap from "../WeatherMapExtendedComponents/WeatherWaveHeightMap";
import WeatherUvMap from "../WeatherMapExtendedComponents/WeatherUvMap";
import WeatherAirPollutionMap from "../WeatherMapExtendedComponents/WeatherAirPollutionMap"

interface WeatherTabProps {
    lang: string
    citiesList: Array<City>
    pollutionList: Array<Pollution>
    isNewLayout: boolean
}

export interface Pollution {
    regionId: number
    index: number
}
export interface City {
    name: string
    nameHeb: string
    weatherCode: number
    minimumTemperature: number
    maximumTemperature: number
    humidity: number,
    precipitation: number,
    windDirection: Array<string>
    windSpeed?: string
    seaTemperature?: number
    wavesHeight?: string
    seaStateCode: number
    radiation: string
    radiationIndex: number
    url: string
}

export interface Tab {
    name: string,
}

interface State {
    selectedTab: number
}

export class WeatherMapExtendedTab extends React.Component<Partial<WeatherTabProps>, State> {
    static siteScriptName = "WeatherMapExtendedTab";
    constructor(props: WeatherTabProps) {
        super(props);
        this.state = {
            selectedTab: 0
        }
    }

    setSelectedTab = (index: number) => {
        this.setState({ selectedTab: index }, () => {
            this.analystFunc(this.getMapType())
        })
    }

    getComponentByTab = () => {
        const { citiesList, lang, pollutionList, isNewLayout } = this.props
        switch (this.state.selectedTab) {
            case 0:
                return <WeatherMapTemprMap key={"0"} citiesList={citiesList} isNewLayout={isNewLayout} />
                break;
            case 1:
                return <WeatherAirPollutionMap key={"1"} pollutionList={pollutionList} isNewLayout={isNewLayout} />
                break;
            case 2:
                return <WeatherPrecipitationMap key={"2"} citiesList={citiesList} isNewLayout={isNewLayout} />
                break;
            case 3:
                return <WeatherWaveHeightMap key={"3"} citiesList={citiesList} lang={lang} isNewLayout={isNewLayout} />
                break;
            case 4:
                return <WeatherHumidityMap key={"4"} citiesList={citiesList} isNewLayout={isNewLayout} />
                break;
            case 5:
                return <WeatherUvMap key={"4"} citiesList={citiesList} isNewLayout={isNewLayout} />
                break;
            default:
                return "1"
                break;
        }
    }

    getMapType = () => {
        switch (this.state.selectedTab) {
            case 0:
                return "Weather"
                break;
            case 1:
                return "Air pollution"
                break;
            case 2:
                return "Precipitation"
                break;
            case 3:
                return "Wave height"
                break;
            case 4:
                return "Humidity"
                break;
            case 5:
                return "UV index Map"
                break;
            default:
                return "Weather"
                break;
        }
    }

    analystFunc = (mapType: string) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'map_Events',
            Category: 'Weather',
            Action: "Weather Map",
            Label: mapType,
        });
    }

    public render() {
        const {isNewLayout} = this.props
        const tabs: Array<Tab> = [
            { name: "מזג אוויר" },
            { name: "זיהום אוויר" },
            { name: "משקעים" },
            { name: "גובה גלים" },
            { name: isNewLayout? "אחוזי לחות" : "לחות" },
            { name: "מדד UV" }
        ]

        const tabsDisplay = tabs.map((item, index) => {
            return (
                <div key={index} onClick={() => this.setSelectedTab(index)}>
                    <WeatherTab tab={item} isSelected={index === this.state.selectedTab} />
                </div>
            )
        })

        return (
            <div>
                <div className="tabs-container">
                    {tabsDisplay}
                </div>
                <div className="component-container">
                    {this.getComponentByTab()}
                </div>
            </div>
        )
    }
}