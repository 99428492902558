
require("./vesty_ynetnews_site_marketingSign.less")

interface MarketngProps {
    isSmallItem: boolean
    marketingContentText: string 
    languageCode?:string  
}
declare let window: Window & {
    languageCode: string
}


export class MarketingSign extends React.Component<MarketngProps> {
    public render() {
        const { isSmallItem, marketingContentText, languageCode } = this.props
        const lang =languageCode || (typeof window !== "undefined" &&  window.languageCode)  || "";
        const En = lang.includes("en");
        const Ru = lang.includes("ru")
        let mText;
        if (En) {
            mText = "Content  was produced by the commercial department of Ynet with the financial support of an external party and edited by our journalists.";
        }
        else if (Ru) {
            mText = 'Текст подготовлен коммерческим отделом "Вестей" при финансовом участии внешнего спонсора, опубликован после редактирования журналистами';
        }
        else{
            mText = "תוכן בשיתוף עם הגורם המפרסם, הכתבה הופקה בידי המחלקה המסחרית של ynet בהשתתפות מימונית של גורם חיצוני ופורסמה לאחר עריכה עיתונאית";
        }
        const text = marketingContentText !== "" ? marketingContentText : mText;
        return (
            <div className={`MarketingSign ${isSmallItem ? "smallItem" : ""}`}>
                {lang && (En || Ru)
                    ? <div className="marketingImage"  ></div> :
                    <img src="https://www.ynet.co.il/images/market_strip.png" className="marketingImage" />}
                <div className="marketingText"><span>{text}</span></div>
            </div>
        )
    }
}