import { PropTypes } from "prop-types"
import { PageComponentaContext, PageComponentaContextTypes } from "../../interfaces"
import { HOCDecorator } from "global/globalDefinitions"

//TODO:remopve `& any` from `WarrpedComponent: React.ComponentClass<T & any >` after TS ver 2.6 release
export const injectPageComponentaContext = <T extends PageComponentaContext>(): HOCDecorator => (WarrpedComponent: React.ComponentClass<T & any>) =>
    class InjectPageComponentaContext<T extends Partial<PageComponentaContext>> extends React.Component<T, {}>{
        context: PageComponentaContext;
        public static contextTypes: PageComponentaContextTypes = {
            pageId: PropTypes.string,
            componentaId: PropTypes.string,
            getArticle: PropTypes.func,
            getImage: PropTypes.func,
            getVideo: PropTypes.func,
            getTag: PropTypes.func,
            getImageSize: PropTypes.func,
            getCurrentPage: PropTypes.func,
            getPageCategory: PropTypes.func,
            copyMediaImage: PropTypes.func,
            loadComponentaArticleResource: PropTypes.func,
            loadComponentaExtraData: PropTypes.func,
            loadComponentaVideoResource: PropTypes.func,
            copyImage: PropTypes.func,
            colorList: PropTypes.array,
            getWidget: PropTypes.func,
            getUrl: PropTypes.func,
            widgetType: PropTypes.string,
            orderedImages: PropTypes.array,
            loadCategoryFields: PropTypes.func,
            siteLayoutType: PropTypes.string
        }

        public render() {
            return <WarrpedComponent {...this.context} {...this.props} />
        }
    }