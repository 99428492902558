import * as map from "lodash/map"
import { CurrencyObj } from "./ynetNewsSearch";

interface vestyCurrencyProps {
    currency: CurrencyObj[]
}
export class VestyCurrency extends React.Component<vestyCurrencyProps, {}> {


    public render() {
        const { currency } = this.props
        return (
            <div className="currency_list">
                {map(currency, (item, i) =>
                    <div className="currency" title={item.name} key={i}>
                        <span>{item.value.toFixed(3)}</span>
                        <img className="currency_logo" src={item.imageName} />
                    </div>
                )}
            </div>
        )

    }
}
