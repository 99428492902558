import { useState, useEffect, useRef, Ref } from "react"
require("./radionas_site_simpleAudioPlayer.less")

interface SimpleAudioPlayerProps {
    src: string
    duration: number
}

export const SimpleAudioPlayer = (props: SimpleAudioPlayerProps) => {

    const { src, duration } = props
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const [isFirstPlay, setIsFirstPlay] = useState<boolean>(true)
    const [playbackTime, setPlaybackTime] = useState<number>(0)

    const audioRef = useRef<HTMLAudioElement>()

    useEffect(() => {
        audioRef.current.addEventListener('timeupdate', () => {
            setPlaybackTime(Math.floor(audioRef.current.currentTime))
        });
        audioRef.current.addEventListener("ended", () => {
            setIsPlaying(false)
        });

        return () => {
            audioRef.current.removeEventListener('timeupdate', () => {
                setPlaybackTime(Math.floor(audioRef.current.currentTime))
            });
            audioRef.current.removeEventListener("ended", () => {
                setIsPlaying(false)
            });
        }
    }, [])


    const onPLayBtnClick = () => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false)
        } else {
            audioRef.current.play();
            setIsPlaying(true)
            if (isFirstPlay) {
                setIsFirstPlay(false)
            }
        }
    }

    const calculateTime = (secs: number) => {
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }

    return (
        <div className="simple-audio-player">
            <audio className="audio-element" src={src} ref={audioRef as any} />

            <div className="duration">{isFirstPlay ? calculateTime(duration) : calculateTime(playbackTime)}</div>

            <img className="waveform-gif" src={"https://www.ynet.co.il/images/radio/waveform-gif4.gif"} />

            <div className={`play-btn ${isPlaying ? "pause" : "play"}`} onClick={onPLayBtnClick}></div>
        </div>
    )
}
