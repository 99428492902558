import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { PodcastArchiveItem } from "./podcastArchiveItem";

interface PodcastArchiveItemListProps {
    itemList: ArticleExtraData[]
    spotifyLink: string
    applePodcastLink: string
}

export class PodcastArchiveItemList extends React.Component<PodcastArchiveItemListProps, {}>{
    public render() {
        const { itemList, spotifyLink, applePodcastLink } = this.props;

        return (
            <div className="slot-list" >
                {itemList.map((item, i) =>
                    <PodcastArchiveItem
                        key={i}
                        item={item}
                        spotifyLink={spotifyLink}
                        applePodcastLink={applePodcastLink}
                    />)}
            </div>
        )
    }
}