import { imageProps } from "./premiumArticleTitleImageComponenta";
import { yPlusHeaderMenuItem, YplusMenu } from "../../../../../ynetPlusHeader/components/menu";
import { YnetPlusUserMenuItems } from "../../../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";
interface PremiumArticleTitleImageProps {
    curCatId: string
    isMenuVisible: boolean
    image: imageProps
    menuItems: yPlusHeaderMenuItem[]
    imgHeight: number
    siteId: string
    seachPageLink: string
    mainLogoLink: string
    parentSiteLink: string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}
interface PremiumArticleTitleImageState {
    isInputOpened: boolean
    isUserLogged: boolean
}

export class SitePremiumArticleTitleImageComponenta extends React.Component<PremiumArticleTitleImageProps, PremiumArticleTitleImageState>{
    constructor(props) {
        super(props as PremiumArticleTitleImageProps);
        this.state = {
            isUserLogged: false,
            isInputOpened: false
        }
    }
    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLogged: true })
        }
    }

    private showInput = () => {
        this.setState({ isInputOpened: true })
    }
    private hideInput = () => {
        this.setState({ isInputOpened: false })
    }
    public static siteScriptName = "SitePremiumArticleTitleImageComponenta";
    render() {
        const { curCatId, isMenuVisible, image: { imageCaption, imageUrl, imageCredit }, menuItems, siteId,
            seachPageLink, mainLogoLink, parentSiteLink, ynetPlusMenuItems} = this.props
        const { isInputOpened, isUserLogged } = this.state
        const screenWidth = typeof window !== "undefined" ? `${window.innerWidth}px` : "1920px";
        if (imageUrl !== undefined) {
            return (
                <div className="PremiumArticleTitleImageComponenta" style={{ "--screenWidth": screenWidth }}>
                    <YplusMenu items={menuItems}
                        categoryId={curCatId}
                        isMenuVisible={isMenuVisible}
                        isArticle={true} 
                        siteId={siteId}
                        seachPageLink={seachPageLink}
                        isInputOpened={isInputOpened}
                        showInput={this.showInput}
                        onFocusOutSearch={this.hideInput}
                        isUserLogged={isUserLogged}
                        mainLogoLink={mainLogoLink}
                        parentSiteLink={parentSiteLink}
                        ynetPlusMenuItems={ynetPlusMenuItems}
                    />
                    <div className="bgDiv" title={imageCaption} style={{
                        backgroundImage: `url("${imageUrl}")`, backgroundRepeat: "no-repeat"
                    }} >
                        <span className="imgCredit">{imageCredit}</span>
                    </div>
                </div>
            )
        }
        else return <YplusMenu items={menuItems}
            categoryId={curCatId} isMenuVisible={isMenuVisible}
            isArticle={true} siteId={siteId}
            seachPageLink={seachPageLink}
            isInputOpened={isInputOpened}
            showInput={this.showInput}
            onFocusOutSearch={this.hideInput}
            isUserLogged={isUserLogged}
            mainLogoLink={mainLogoLink}
            parentSiteLink={parentSiteLink}
            ynetPlusMenuItems={ynetPlusMenuItems}
             />;


    }
}
