require("./site_mediaGalleryComponenta.less")
require("./../../tab/componentas/site_tabComponenta.less")
import { SiteMedia } from "../../../siteWidgets/siteMedia";
import * as classNames from "classnames"
import * as map from "lodash/map"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import { GroupItemData } from "./frontMediaGallery";
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { SiteSettings, SiteSettingsHOC } from "global/components/siteSettingsHOC"
import { getImagesSrc } from "../../../commonComponents/inline/siteSimpleImage";
import Slider from 'react-slick'

interface mediaGalleryState {
    activeSlide
    isLightBoxOpened: boolean
}

interface mediaGalleryProps {
    isEnglish?: boolean
    mainTitleText: string
    groupList: GroupItemData[]
    isTitleVisible?: boolean
    tabBackgroundColor: string
    tabBackgroundImageUrl: string
    componentaWidth: number
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    videoSettings: any
    imagesDomain: string
}



export class MediaGalleryMobile extends React.Component<mediaGalleryProps, mediaGalleryState>{
    constructor(props: mediaGalleryProps) {
        super(props);
        this.state = {
            activeSlide: 0,
            isLightBoxOpened: false
        }

    }
    private slider;
    private previous = () => {
        this.slider.slickPrev();
    }
    private next = () => {
        this.slider.slickNext();
    }

    private addCssOnLightBox = () => {
        this.setState({ isLightBoxOpened: true })

    }
    private removeCssOnLightBox = () => {
        this.setState({ isLightBoxOpened: false })

    }
    public static siteScriptName = "MediaGalleryMobile";

    public render() {
        const { isEnglish, groupList, tabLink, tabLinkTarget, isTitleVisible, videoSettings, mainTitleText,
            tabBackgroundColor, tabBackgroundImageUrl, componentaWidth, imagesDomain } = this.props
        const { isLightBoxOpened } = this.state
        const tabStyles = tabBackgroundColor ? { backgroundColor: tabBackgroundColor } : { backgroundImage: `url("${getImagesSrc(imagesDomain, tabBackgroundImageUrl)}")`, backgroundRepeat: "no-repeat" }

        let englishSite = isEnglish ? "englishSite" : ""
        const innerStyle = {
            width: (groupList.length * 167) + "px",
            right: (this.state.activeSlide * 167 * -1) + "px"
        };
        const innerEnglishStyle = {
            width: (groupList.length * 167) + "px",
            left: (this.state.activeSlide * 167 * -1) + "px"
        };
        var settings = {
            draggable: false,
            speed: 500,
            infinite: true,
            dots: false,
            slidesToShow: 1,
            variableWidth: true

        }
        const mainGallery = classNames("main-gallery", { "withoutTransform": isLightBoxOpened })
        return (
            <div className={`MediaGalleryComponenta ${englishSite}`}>
                <div className="TabComponenta mediaGalleryTab" style={tabStyles}>
                    <HyperLinkBuilder className="titleLink mainTitleLink" href={tabLink} linkTarget={tabLinkTarget}><div className="mainTitleText">{mainTitleText}</div></HyperLinkBuilder>
                </div>
                <div className={mainGallery}>

                    <Slider ref={slider => (this.slider = slider)} {...settings} className="slider"  >
                        {map(groupList, (item, index) =>
                            <div className={classNames("slotView")} key={index}>
                                <SiteMedia
                                    data={item.media}
                                    onLightBoxOpened={this.addCssOnLightBox}
                                    onLightBoxClosed={this.removeCssOnLightBox}
                                    videoSettings={videoSettings}
                                    key={item.media.url} width={211} height={118} />

                                {isTitleVisible && <div className="itemTitle">
                                    <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                    </HyperLinkBuilder>
                                </div>}

                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        )
    }
}


