require("../tagSeach/ynet_mynet_calcalist_site_tagSearchComponenta.less");
require("../tagSeach/vesty_ynetnews_ctech_site_tagSearchComponenta.less");
import * as axios from "axios";
import * as  debounce from "lodash/debounce";
import { TagListComponenta } from "./tagListComponeta";
const clickOutside = require('react-click-outside');

export interface tagObj {
    tagName: string
    tagLink: string
}

interface SearchState {
    tagValue: string
    tagsResultsValue: any[]
    selectedTag: tagObj
    isResultsOpened: boolean
}

declare let window: Window & {
    languageCode: string
}

interface TagsSearchComponentaProps {
    languageCode:string
    isLTR:boolean
}

enum ArrowKeyCode {
    Up = 38,
    Down = 40,
    Enter = 13,
    Esc = 27
}

export const TagsSearchDict = {
    "he": {
        searchTag: "חפש תגית",
        noResults: "לא נמצאו תגיות התואמות את החיפוש ",
    },
    "en": {
        searchTag: "Search Tag",
        noResults: "No tags matching your search were found",

    },
    "ru": {
        searchTag: "Поиск",
        noResults: "Теги по вашему запросу не найдены",
    }
}

@clickOutside
export class TagsSearchComponenta extends React.Component<TagsSearchComponentaProps, SearchState> {
    public static siteScriptName = "TagsSearchComponenta";
    private search: HTMLInputElement;
    constructor(props) {
        super(props)
        this.state = {
            tagValue: "",
            tagsResultsValue: null,
            selectedTag: null,
            isResultsOpened: false
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress)
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
    }

    private selectedItemChoosed = (item) => {
        this.setState({ selectedTag: item })
    }

    private getTagSearchResults = (value: string) => {
        this.setState({
            tagValue: value,
            isResultsOpened: true
        })

        if (value.length > 2) {
            axios.get(`/iphone/json/api/tag/search/${value}`, { responseType: 'json' })
                .then((res: any) => {
                    const items = res.data.options;
                    let itemsTosHow = items;
                    if (items.length > 5) {
                        itemsTosHow = items.filter((item, idx) => idx < 5)
                    }

                    const suggestions = [];

                    for (const item of itemsTosHow) {
                        suggestions.push({
                            tagName: item.name,
                            tagLink: item.publishedLink
                        })
                    }

                    this.setState(
                        {
                            tagsResultsValue: suggestions,
                            selectedTag: suggestions[0]

                        }
                    );
                })
                .catch(err => console.error("error geting headline data:", err))
        }
        else return;
    }

    private getTagSearchResultsDebounced = debounce(this.getTagSearchResults, 500)

    private handleKeyPress = (e) => {
        const { selectedTag, tagsResultsValue } = this.state
        const currentIndex = tagsResultsValue.length && tagsResultsValue.findIndex((item) => selectedTag.tagName === item.tagName) || 0
        if (e.keyCode === ArrowKeyCode.Esc) {
            this.setState({ tagValue: "" })
        }
        if (e.keyCode === ArrowKeyCode.Down && tagsResultsValue[currentIndex + 1]) {
            this.setState({ selectedTag: tagsResultsValue[currentIndex + 1] })
        }
        if (e.keyCode === ArrowKeyCode.Up && tagsResultsValue[currentIndex - 1]) {
            this.setState({ selectedTag: tagsResultsValue[currentIndex - 1] })
        }
        if (e.keyCode === ArrowKeyCode.Enter && selectedTag) {
            window.location.href = selectedTag.tagLink;
        }
    }

    render() {
        const { tagsResultsValue, selectedTag, tagValue } = this.state
        const {isLTR, languageCode} = this.props;
        const lang = languageCode ? languageCode.slice(0,2) : "he";
        const dir = isLTR ? 'ltr' : 'rtl' ;

        return (
            <div className="TagSearchComponenta">
                <div id="search_tag" className="search-field">
                    <input
                        type="text"
                        id="TagSuggest"
                        autoComplete="off"
                        onChange={e => this.getTagSearchResultsDebounced((e.target as HTMLInputElement).value)}
                        placeholder={TagsSearchDict[lang].searchTag}
                        ref={c => { this.search = c }}
                        dir={dir}
                    />
                    <span className="searchIcon"></span>
                    {tagsResultsValue !== null &&
                        tagValue.length > 2 &&
                        <TagListComponenta
                            items={tagsResultsValue}
                            onChooseItem={(item) => this.selectedItemChoosed(item)}
                            isOpened={this.state.isResultsOpened}
                            selectedItem={selectedTag && selectedTag.tagName}
                            lang={lang}
                        />}
                </div>
            </div>
        )
    }
}
