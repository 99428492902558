
import { calculateAutomaticComponentaUrl } from "widgets/widgetsUtils"
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { getDiffFromToday } from "../../../../siteWidgets/formatDateDisplay";
import { VideoArchiveArticle } from "./siteVideoArchiveComponenta";
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage";
import { ImageSize } from "../../../../../constants/imageSizes";

interface VideoArchiveArticleItemProps {
    article: VideoArchiveArticle
    isLTR?: boolean
    getFormattedDate: (dateUpdated: Date) => string
}
export class VideoArchiveArticleItem extends React.Component<VideoArchiveArticleItemProps, {}>{
    static defaultProps = { isCategoryVisible: true }

    private getIcon = (hasVideo: boolean) => {
        if (hasVideo) return "video"
        else return "none"
    }
    private viewDate = (): boolean => {
        const { article } = this.props
        const dateToManipulateTemp = new Date(article.dateUpdatedOnSite)
        const diffFromToday = getDiffFromToday(dateToManipulateTemp)
        return diffFromToday > 0
    }

    public render() {
        const { article, isLTR, getFormattedDate } = this.props;
        const { title, publishedLink, hasVideo, articleId, dateUpdatedOnSite, promotionImageDetails } = article
        const textStyle = (!this.viewDate() && !isLTR) ? { color: '#C82027' } : {}

        return (
            <div className="slotItem" data-wcm-article-id={articleId}>
                <div className="mediaArea CalcalistComponentaShareMenuMedia">
                    <div className="MediaCarousel" >
                        <a href={publishedLink}>
                            <img src={getFrontImageUrl(promotionImageDetails, { imageSize: ImageSize.medium })}
                                alt={title}
                                title={title}
                                style={{ width: 270, height: 146 }}
                                className="SiteImageMedia"
                            />
                        </a>
                    </div>
                    <SlotIconDisplay itemId={""} icon={this.getIcon(hasVideo)} iconClassName="medium" isIconVisible={hasVideo} />
                    <CalcalistShareMenu url={publishedLink} title={this.props.article.title} isLTR={true} isArticle={true} />
                </div>
                <a href={calculateAutomaticComponentaUrl(article)} className="textDiv" >
                    <div className="moreDetails">
                        <span className="dateView" style={textStyle}>
                            <span className="DateDisplay">{getFormattedDate(dateUpdatedOnSite)}</span>
                        </span>
                    </div>
                    <div className="slotTitle">
                        <span>{title}</span>
                    </div>
                </a>
            </div>
        )
    }
}