import { SiteTickerData } from "./tickerComponenta"
import { TickerPopUpContent } from "./popUpContent"
import { renderTaboolaThumbnails } from "./tickerPopUpTaboola"
import { SiteSimpleImage } from "../../../../commonComponents/inline/siteSimpleImage";


interface PopUpProps {
    items: SiteTickerData[]
    popUpTitle: string
    popUpTitleColor: string
    currentIndex: number
    swipeBack: () => void
    swipeNext: () => void
    close: () => void
    logoUrl: string
}

export class PopUpView extends React.Component<PopUpProps, {}>{
    private popUpCloseButton: HTMLButtonElement;

    componentDidMount() {
        this.popUpCloseButton.focus()
        var focusableEls = document.getElementById('SiteFlashPopUp').querySelectorAll('a[href], area[href], button:not([disabled]), [tabindex="0"]');
        document.addEventListener("keydown", (e) => this.handleKeyPress(e, focusableEls))
    }
    componentDidUpdate(_prevProps: PopUpProps) {
        if (_prevProps.currentIndex !== this.props.currentIndex) {
            this.popUpCloseButton.focus();
            var focusableEls = document.getElementById('SiteFlashPopUp').querySelectorAll('a[href], area[href], button:not([disabled]), [tabindex="0"]');
            document.addEventListener("keydown", (e) => this.handleKeyPress(e, focusableEls))
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", (e) => this.handleKeyPress(e, null))
    }



    private handleKeyPress = (e: any, focusableEls): void => {
        if (e.key === "Escape") { this.props.close() }
        if (e.key === "ArrowLeft") { this.props.swipeNext() }
        if (e.key === "ArrowRight") { this.props.swipeBack() }
        if (e.keyCode === 9) {
            if (focusableEls.length > 1) {
                if (e.shiftKey) {
                    this.handleBackwardTab(e, focusableEls);
                } else {
                    this.handleForwardTab(e, focusableEls);
                }
            }
            else e.preventDefault();
        }
    }
    private handleBackwardTab = (e, focusableEls) => {
        if (document.activeElement === focusableEls[0]) {
            e.preventDefault();
            focusableEls[focusableEls.length - 1].focus();
        }

    }
    private handleForwardTab = (e, focusableEls) => {
        if (document.activeElement === focusableEls[focusableEls.length - 1] && (document.activeElement.classList.contains("swipeLeft") || document.activeElement.classList.contains("swipeRight"))) {
            e.preventDefault();
            focusableEls[0].focus()
        }
    }
    render() {
        const { items, popUpTitle, popUpTitleColor, currentIndex, logoUrl } = this.props
        const canNavigateBack = !(currentIndex === 0)
        const canNavigateNext = !(currentIndex === items.length - 1)
        const item = items[currentIndex]
        let TaboolaTitle = "כתבות נוספות"
        this.handleKeyPress
        return (
            <div key={"SiteFlashPopUp"}
                id="SiteFlashPopUp" role="dialog" aria-label={`${popUpTitle}`} className={`SiteFlashPopUp `} onClick={this.props.close}  >
                <div className="ItemWrapper" onClick={e => e.stopPropagation()} tabIndex={0}>
                    <div className="flashHeader" >
                        <button className="closeBtn yicon-x" title={__("Close")} onClick={this.props.close} ref={ref => this.popUpCloseButton = ref} />
                        <div className="flashLogo">
                            <SiteSimpleImage src={logoUrl} alt={popUpTitle} title={popUpTitle} aria-hidden="true" aria-label="Page Logo" />
                        </div>
                        <div className="headerTitle"><span style={{ color: popUpTitleColor }}>{popUpTitle}</span></div>
                    </div>
                    <TickerPopUpContent
                        item={item}
                        onClickNext={this.props.swipeNext}
                        onClickBack={this.props.swipeBack}
                        selectedIndex={currentIndex}
                        canNavigateBack={canNavigateBack}
                        canNavigateNext={canNavigateNext}
                    />

                </div>
            </div>
        )
    }
}

/**   <div className="taboolaArea" > <div className="taboolaTitle">{TaboolaTitle}</div>
                        {renderTaboolaThumbnails()}</div> */