require('./site_pikudHaOrefAlerts.less');

declare var $: JQueryStatic

interface PikudHaOrefAlertsProps {
    jsonUrl: string
    interval: number
}
interface alertItem {
    guid: string
    pubdate: string
    title: string
    description: string
    link: string
}
interface alertIteminArray {
    [item: string]: alertItem
}
interface PikudHaOrefAlertsState {
    alerts: alertIteminArray[]
    hoveredItem: alertIteminArray
    missileAlertsIdSaver: string
    pikudHaorefTitle: string
}

export class PikudHaOrefAlerts extends React.Component<PikudHaOrefAlertsProps, PikudHaOrefAlertsState>{
    public static siteScriptName = "PikudHaOrefAlerts"

    constructor(props) {
        super(props)
        this.state = {
            alerts: [],
            missileAlertsIdSaver: null,
            hoveredItem: null,
            pikudHaorefTitle: "התרעות פיקוד העורף",
        }
    }


    componentDidMount() {
        const { jsonUrl, interval } = this.props;
        if (!$) return;
        try {
            setInterval(() => { this.sendPikudHaorefAjax(jsonUrl) }, interval)
        } catch (err) {
            $(window).error(function () {
                return true;
            });
        }

    }
    private missileAlertsCharsEsc = (str) => {
        if (str) {
            str.replace(/\n/g, '\n')
                .replace(/\'/g, '&#39;')
                .replace(/'/g, '&#39;')
                .replace(/"/g, '&#34;');
        }
        return str;
    };



    private sendPikudHaorefAjax = (url: string) => {
        url && $.ajax({
            type: 'GET',
            dataType: 'jsonp',
            contentType: 'application/json; charset=utf-8',
            data: null,
            jsonpCallback: 'jsonCallback' || function jsonCallback(json) { },
            cache: true,
            url,
            success: (json) => {
                if (json.alerts && json.alerts.items) {
                    const items: alertIteminArray[] = Array.isArray(json.alerts.items) ? json.alerts.items : [json.alerts.items.item]
                    if (items.length > 0) {
                        if (this.state.missileAlertsIdSaver != items[0].item.guid) {
                            this.setState({ alerts: items, missileAlertsIdSaver: items[0].item.guid })
                        }
                    } else {
                        this.setState({ alerts: [], missileAlertsIdSaver: null })
                    }
                } else {
                    this.setState({ alerts: [], missileAlertsIdSaver: null })
                }
                
                this.setState({ pikudHaorefTitle: json.title || "התרעות פיקוד העורף"})
            },
            error: function (json, error) {

            }
        });
    }


    private closeAlert = () => {
        this.setState({ alerts: [] })
    }

    private showHoveredItem = (item) => {
        this.setState({ hoveredItem: item })
    }


    private getPikudHaorefAlertStyle = () => {
        const element = document.getElementById("ads.sargel");
        if (element) {
            return {
                bottom: element.clientHeight + "px"
            }
        }
        return null
    }

    private wrapperRef: HTMLDivElement;

    render() {
        const { alerts, hoveredItem, pikudHaorefTitle } = this.state
        const openClass = hoveredItem !== null ? "open" : "";
        return (
            alerts.length > 0 &&
            <div className="pikudHaorefAlert" style={this.getPikudHaorefAlertStyle()}>
                <div className={`alertDescription ${openClass}`}>
                    <div className="alert-description-container">
                        {hoveredItem !== null && <span className="area-description-title">רשימת יישובים במרחב {hoveredItem.item.title}: </span>}
                        {hoveredItem !== null && <span className="area-description">{this.missileAlertsCharsEsc(hoveredItem.item.description)}</span>}
                    </div>
                    <div className="redArrow" />
                </div>
                <div className="alert-top">
                    <div className="alert-top-container">
                        <a href="//www.oref.org.il" target="_blank" className="alerts-oref-logo" />

                        <div className="alerts-top-data">
                            <div className="alert-title">
                                {pikudHaorefTitle}
                            </div>
                            <ul className="areas">
                                {alerts.map((item, i) => {
                                    return (
                                        <li key={i}
                                            onMouseEnter={() => this.showHoveredItem(item)}
                                            onMouseLeave={() => this.showHoveredItem(null)}
                                        >{item.item.title}</li>
                                    )
                                })}
                            </ul>

                        </div>
                        <div className="alert-left">
                            <div className="alerts-close" onClick={this.closeAlert}></div>
                        </div>
                    </div>

                </div>
            </div>


        )
    }
}
/**
 *          <div className="alerts-bottom">
                    <div className="alert-bottom-container">
                        <span className="alert-attention-text">
                            פיקוד העורף מזכיר: יש לחכות 10 דקות במרחב המוגן לפני שיוצאים החוצה
                      </span>
                        <a className="alert-permanent-link" href="//www.oref.org.il/1096-he/Pakar.aspx" target="_blank">למציאת מרחב ההתגוננות האישי שלכם</a>
                    </div>
                </div>
 */