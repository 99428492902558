import * as map from "lodash/map"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
const clickOutside = require('react-click-outside');

export interface CityList {
    name: string
    link: string
    linkTarget: LinkTargetProperties
    id: string
}

interface MyNetTabProps {
    cityItems: CityList[]
}

interface CityListState {
    isListOpen: boolean
}

@clickOutside
export class CityList extends React.Component<MyNetTabProps, CityListState>{
    constructor(props) {
        super(props);
        this.state = {
            isListOpen: false,
        }
    }
    public static siteScriptName = "CityList";
    private handleClickOutside = () => {
        this.setState({
            isListOpen: false
        });
    }


    private toggleCityList = () => {
        this.setState({
            isListOpen: !this.state.isListOpen,
        });
    }
    private getWidth = (itemLength) => {
        let numOfCol = Math.floor(itemLength / 4) + 1
        return 155 * numOfCol
    }
    public render() {
        const { isListOpen } = this.state
        const { cityItems } = this.props
        return (
            <div className="CityList" >
                <button className="cityList" title="בחר את העיר שלך" onClick={this.toggleCityList}>בחר את העיר שלך</button>
                {isListOpen && <div className="cityLinksList" style={{ width: this.getWidth(cityItems.length) }} >{map(cityItems, (city, i) =>
                    <div key={city.id}>
                        <HyperLinkBuilder href={city.link} linkTarget={city.linkTarget}>{city.name}</HyperLinkBuilder>
                    </div>
                )}</div>}
            </div>
        )
    }
}
