import classNames = require("classnames");
import { useState } from "react";
import { isMobile } from "utils/isMobile"
import * as axios from "axios"
import { CalcalistNewsLetterNativeComponentItem } from "./calcalistNewsLetterNativeComponenta";

declare var grecaptcha: any
declare var window: any



export const CalcalistNewsLetterNativeRegisterPopup = ({ onClose, selectedItems, componentaId, generalGroupId }) => {
    const [isRegisterSuccesss, setRegisterSuccess] = useState(false);
    const [inputsData, setInputData] = useState({});
    const activeTrailGeneralGroup = generalGroupId || 579;

    const handleRegisterUserToNewsletter = (e: React.MouseEvent) => {
        e.preventDefault();
        const isTest = window.location.search.includes("is_test=1");
        if (isTest) {
            sendPostRequests("", true);
        } else if (validateForm()) {
            if (window.wcmCaptchaSiteKey && grecaptcha) {
                grecaptcha.ready(() => {
                    grecaptcha.execute(window.wcmCaptchaSiteKey, { action: 'submit' }).then(token => {
                        sendPostRequests(token, false);
                    })
                })
            }
        }
    }

    const sendPostRequests = async (token: string, isTest: boolean) => {
        try {
            const mailingListIds = Object.values(selectedItems).map((item: CalcalistNewsLetterNativeComponentItem) => item.mailingListId).join(',');
            let postData = {
                groupId: parseInt(activeTrailGeneralGroup),
                mailingListId: mailingListIds || "",
                email: inputsData['email'],
                token,
            }
            const response = await axios.post(`/api/newsletter/${componentaId}/subscribeToGroup${isTest ? "?is_test=1" : ""}`, postData)
            if (response && response.status === 200) {
                setRegisterSuccess(true);
            }
        } catch (error) {
            console.error('Error in sending requests:', error);
        }
    }

    const onChangeInputFieldHandler = (inputElement) => {
        if (inputElement.name === 'checkbox') {
            setInputData(prevState => ({ ...prevState, [inputElement.name]: inputElement.checked }));
            inputElement.parentElement.classList.remove('error');
        } else {
            setInputData(prevState => ({ ...prevState, [inputElement.name]: inputElement.value }));
            inputElement.classList.remove('error');
        }
    }

    const validationRules = {
        email: [
            { validator: value => value.trim() !== '' },
            { validator: value => /\S+@\S+\.\S+/.test(value) }
        ],
        phone: [
            { validator: value => value.trim() !== '' },
            { validator: value => /^((\+972|972)|0)( |-)?([1-468-9]( |-)?\d{7}|(5|7)[0-9]( |-)?\d{7})$/i.test(value) }
        ],
        checkbox: [
            { validator: (value) => value }
        ]
    };

    const showErrorField = (fieldName) => {
        const elementInput = document.querySelector(`input[name='${fieldName}']`);
        const field = fieldName === 'checkbox' ? elementInput.parentElement : elementInput
        field.classList.add('error');
    }

    const validField = (fieldName) => {
        let fieldValid = true;
        const inputElement = (document.querySelector(`input[name='${fieldName}']`) as HTMLInputElement);
        const fieldValue = fieldName === 'checkbox' ? inputElement.checked : inputElement.value
        for (const rule of validationRules[fieldName]) {
            if (!rule.validator(fieldValue)) {
                fieldValid = false;
                showErrorField(fieldName);
            }
        }
        return fieldValid;
    }

    const validateForm = () => {
        let formValid = true;
        for (const fieldName in validationRules) {
            if (fieldName === 'checkbox' && isMobile()) continue;
            if (!validField(fieldName)) {
                formValid = false;
            }
        }
        return formValid;
    }

    return (
        <div className="CalcalistNewsLetterNativeRegisterPopup">
            <div className="modal">
                <div className={classNames('popup-content', { 'show': !isRegisterSuccesss })}>
                    <span onClick={onClose} className="x-icon"></span>
                    <p className="main-title">
                        הירשמו לניוזלטרים
                        של כלכליסט
                    </p>
                    <div className="form">
                        <input onChange={(e) => onChangeInputFieldHandler(e.target as HTMLInputElement)} placeholder="מייל" type="email" name="email"></input> <br />
                        <input onChange={(e) => onChangeInputFieldHandler(e.target as HTMLInputElement)} placeholder="טלפון" type="phone" name="phone"></input>
                        <button onClick={handleRegisterUserToNewsletter} type="submit">שליחה</button>
                        <div className="terms">
                            <div className="custom-checkbox no-small-vp">
                                <input onChange={(e) => onChangeInputFieldHandler(e.target as HTMLInputElement)} type="checkbox" name="checkbox" id="terms-checkbox"></input>
                                <label className="terms-checkbox" htmlFor="terms-checkbox"></label>
                            </div>
                            <label className="term-text no-small-vp">
                                מסכים/ה <a href="https://www.calcalist.co.il/home/0,7340,L-3856,00.html">לתנאי השימוש</a>, ל<a href="https://www.calcalist.co.il/home/0,7340,L-3855,00.html">מדיניות הפרטיות</a> ולקבלת פניות שיווקיות
                                מ-ynet כולל טלפוניות, מבלי שהנ״ל מהווה הסכמה לעריכת עסקה
                            </label>

                            <label className="term-text show-small-vp">
                                בעת הוספת הפרטים הינני מסכים/ה <a href="https://www.calcalist.co.il/home/0,7340,L-3856,00.html">לתנאי השימוש</a>, <a href="https://www.calcalist.co.il/home/0,7340,L-3855,00.html">מדיניות הפרטיות</a> ולקבלת פניות שיווקיות מ-ynet כולל טלפוניות, מבלי שהנ״ל מהווה הסכמה לעריכת עסקה
                            </label>

                        </div>
                    </div>
                </div>
                <div className={classNames('popup-content-success', { 'show': isRegisterSuccesss })}>
                    <div className="success-icon"></div>
                    <p className="success-title">נרשמת בהצלחה!</p>
                    <p className="success-sub-title">הכתבות הכי חמות כבר בדרך אליך.</p>
                    <a href="https://www.calcalist.co.il" className="return-button">לדף הבית</a>
                </div>
            </div>
        </div>
    )
}