import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton";
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../../widgetsUtils"

export class SiteArticleHeaderShare extends React.Component<{ lang: string, title?: string }, {}> {
    public static siteScriptName = "SiteArticleHeaderShare"
    private twitterIcon = "/images/twit.png"
    private facebookIcon = "/images/fb.png"
    private mailIcon = "/images/mailme.png"
    private telegramIcon = "/images/telegram50x50.png"
    private handleShareBtnClicked = (button: string, lang: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Top", title), "Article Top")
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Top", title), "Article Top")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, lang, () => analyticsPushFunc("Mail", "Article Top", title), "Article Top")
                    break;
                case "te":
                    location.href = 'https://t.me/vestyisrael';
                    break;
            }
        }
    }

    public render() {
        const { lang } = this.props

        let textMail
        let textTw
        let textFb
        let textTe
        if (lang === "he") {
            textMail = "שלחו כתבה"
            textTw = "שיתוף בטוויטר"
            textFb = "שיתוף בפייסבוק"
            textTe = "צפו בטלגרם"
        }
        else {
            textMail = ""
            textTw = ""
            textFb = ""
            textTe = ""
        }

        return (
            <span className="ArticleHeaderShareComponent">
                <button title={textMail} className="mailto"
                    onClick={e => this.handleShareBtnClicked("mailto", lang)}>
                    <img src={this.mailIcon} aria-hidden={true} /></button>
                <button title={textTw} className="tw"
                    onClick={e => this.handleShareBtnClicked("tw", lang)}>
                    <img src={this.twitterIcon} aria-hidden={true} /></button>
                <button title={textFb} className="fb" onClick={e => this.handleShareBtnClicked("fb", lang)}>
                    <img src={this.facebookIcon} aria-hidden={true} /></button>
                {(lang === "ru") && <button title={textTe} className="te" onClick={e => this.handleShareBtnClicked("te", lang)}>
                    <img src={this.telegramIcon} aria-hidden={true} /></button>
                }
            </span>

        )
    }
}

