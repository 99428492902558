import { ImageDisplayData } from "images/interfaces"
import { ImageSize, ImageSIzeDefintion, imageSizeConvertion } from "constants/imageSizes";
import { getDefaultplaceholderImage } from "../../config/serverConfig"

const imageTranslations = {
    [ImageSize.small]: "small",
    [ImageSize.medium]: "medium",
    [ImageSize.large]: "large",
    [ImageSize.xLarge]: "x-large",
    [ImageSize.xxLarge]: "xx-large"
}

export const defaultYnetImages = {
    small: "//www.ynet.co.il/images/small_defual_image_1024.png",
    big: "//www.ynet.co.il/images/big_defualt_image.png",
}

export function getFrontImageUrl(image: ImageDisplayData, size: ImageSIzeDefintion) {
    if (!size) {
        size = { imageSize: ImageSize.medium }
    }
    size = fixSize(image, size);
    const imageSize = imageSizeConvertion(size);

    if (image && image.publishedLink) {
        if (image.publishedLink.endsWith("_xxx.jpg")) {
            return image.publishedLink.replace(/_xxx\.jpg$/, `_${imageTranslations[imageSize]}.jpg`)
        } else if (image.publishedLink.endsWith("_xxx.gif")) {
            return image.publishedLink.replace(/_xxx\.gif$/, `_${imageTranslations[imageSize]}.gif`)
        } else {
            return image.publishedLink
        }
    }
    return getDefaultImage(size);
}

export function getDefaultImage(size: ImageSIzeDefintion) {
    const defaultPlaceholderImage = getDefaultplaceholderImage()
    const isBigImage = imageSizeConvertion(size) > ImageSize.medium;
    if (isBigImage) {
        return defaultPlaceholderImage.img_big ? defaultPlaceholderImage.img_big : defaultYnetImages.big;
    } else {
        return defaultPlaceholderImage.img_small ? defaultPlaceholderImage.img_small : defaultYnetImages.small;
    }
}

function fixSize(image: ImageDisplayData, size: ImageSIzeDefintion): ImageSIzeDefintion {
    if (size.w || size.imageSize || !size.h) {
        return size;
    }
    return {
        w: size.h * image.width / image.height,
        h: size.h
    }
}