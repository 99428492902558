import { convertHtmlToString } from "../../../../../config/utils";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import {getTranslation} from "../../../ynet1280/autoTickerRowWidget/autoTickerRowTranslations"

export interface LinkedFlashItem {
    date: Date
    text: string
    title: string
    articleId: string
    shareUrl: string
    author?:string
}

export interface ArticleItem {
    publishedLink?: string
}

interface LinkedFlashItemProps {
    item: LinkedFlashItem
    languageCode: string
}

export class LinkedFlashItem extends React.Component<LinkedFlashItemProps, {}> {

    private lastPublished = () => {
        const { item } = this.props
        let secDiff = Math.floor((Date.now() - new Date(item.date).getTime()) / 1000);
        let minutesDiff = Math.floor(secDiff / 60);
        return minutesDiff;
    }

    render() {
        const { item, languageCode } = this.props;
        const { date, shareUrl, title, author } = item;
        const newItem = this.lastPublished() < 1
        const newFlash = newItem ? "new" : "";
        const lang = languageCode ? languageCode.slice(0, 2) : 'en';
        const dateDisplay = newItem ? getTranslation(lang, "Now") : <DateDisplay date={date} />;
        const isAuthor = author && author.length > 0;
        return (
            <div className="AccordionSection">
                <div className={`titleRow ${newFlash}`}>
                    <div className="date">{dateDisplay}
                        <div className="itemSeparator" />
                    </div>
                    <a href={shareUrl} className="title link"> {convertHtmlToString(title)}
                    {isAuthor && <br/>}
                        {isAuthor && `(${author})`}
                    </a>
                </div>
                <div className='arrowIcon'/>
            </div>
        )
    }
}
