var clickOutside = require("react-click-outside");


interface colorPickerProps {
    colorPickerOpen: boolean
    colorOptions: {}
    className: string
    prefix: string
    style?: any
    toggleColor: (value: string) => void
    toggleColorPicker: () => void
}

interface colorPickerState {
}

@clickOutside
export class ColorPicker extends React.Component<colorPickerProps, colorPickerState>{
    private handleClickOutside = () => {
        if (this.props.colorPickerOpen) {
            this.props.toggleColorPicker();
        }
    }

    public render() {
        const { colorPickerOpen, colorOptions, toggleColorPicker, toggleColor, className, prefix, style } = this.props;
        return (
            <div className={className} onMouseUp={toggleColorPicker} style={style}>
                {colorPickerOpen ?
                    _.map<any, any>(colorOptions, i =>
                        <div className="color_picker"
                            style={{ borderRightColor: `${i.label}` }}
                            onMouseDown={(e) => { e.preventDefault(); toggleColor(`${prefix}#${i.value}`) }}
                            key={i.value}
                        >{i.label}</div>
                    ) : null
                }
            </div>
        )
    }

}