import * as classNames from "classnames"
import { RichInput } from "widgets/commonComponents/base/richInput"
import { isWcmEditor } from "config/serverConfig"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"

import { DateType } from "../windows/dateSelector"
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces"
import { FlashLinkType } from "../windows/flashLinkSelector"


interface TickerItemSlotProps {
    titleColor: string
    subTitleColor: string
    articlePublishDate: Date
    articleUpdateDate: Date
    title: string
    flashDate: Date
    flashDateType: DateType
    linkTarget: LinkTargetProperties
    link: string
    flashId: string
    flashLinkType: FlashLinkType
    articleId?: string
    isAuto?: boolean
    isEnglish: boolean
}

export class TickerItemSlot extends React.Component<TickerItemSlotProps, {}>{
    private titleRef: RichInput;
    private renderLink(textStyle: any, popUpLink: any) {
        const { flashLinkType, link, isEnglish, linkTarget, title } = this.props
        if (flashLinkType === "link" || isEnglish) {
                return (
                    <HyperLinkBuilder href={link}
                        linkTarget={linkTarget}
                        children={title} >
                        <div dangerouslySetInnerHTML={{ __html: title }} style={textStyle} /></HyperLinkBuilder>
                )
            }
            else {
            return (popUpLink)
        }
    }

    public render() {
        const { titleColor, subTitleColor, title, flashDate, flashId, isEnglish } = this.props

        const dateStyle = {
            color: titleColor
        }
        const textStyle = {
            color: subTitleColor
        }
        const popUpLink = !isWcmEditor() ? <a tabIndex={0} className="flashItem" data-flashid={flashId} id={flashId}>
            <div dangerouslySetInnerHTML={{ __html: title }} style={textStyle} />
        </a> : <div dangerouslySetInnerHTML={{ __html: title }} style={textStyle} />
        return (
            <div className={classNames("slotView", { "inEditMode": isWcmEditor(), "emptySlot": title.length === 0 })}>
                <div className={classNames("slotTitle", { "withWidth": title.length === 0 })}>
                    <span style={dateStyle} >{separateHourFromDateAndFormatToString(flashDate, { showDate: true, showHour: true, isSlashSeparator: true, fullYear: isEnglish, dateTimeSeperator: isEnglish ? ", " : " " })}</span>
                </div>

                <div className="moreDetails" >
                    {this.renderLink(textStyle, popUpLink)}

                </div>
            </div>
        )
    }
}