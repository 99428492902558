require("./site_footerLogos.less")

export const FooterLogos = () => {
    return (
        <div className="FooterLogos">
            <a href="//nadlan.ynet.co.il/?externalurl=true" title="Nadlan" className="nadlan" rel="nofollow"></a>
            <a href="//www.bigdeal.co.il/" title="BigDeal" className="bigdeal" rel="nofollow"></a>
            <a href="//www.pro.co.il/?utm_source=ynet.hp&amp;utm_medium=D.photer.logo&amp;utm_campaign=pro&amp;utm_content=pro-D.photer.logo" title="המקצוענים" className="professionals" rel="nofollow" target="_blank"></a>
            <a href="//b.ynet.co.il/?utm_source=ynet.hp&amp;utm_medium=D.photer.Inside.logoPage&amp;utm_campaign=bituach&amp;utm_content=bituach-D.photer.InsidePage.logo" title="ביטוח ynet" className="insurance" rel="nofollow" target="_blank"></a>
            <a href="//b.ynet.co.il/pearl/~v090820/clientzone/vehicle/proposal.html?utm_source=ynet.hp&amp;utm_medium=buy_save_146_82&amp;utm_campaign=bituach.car&amp;utm_content=bituach.car-buy_save_146_82#/" title="ynet מימון" className="mimun" rel="nofollow" target="_blank" />
            <a href="//www.ynetshops.co.il/?utm_source=ynet.hp&amp;utm_medium=d.photer.inside.logopage&amp;utm_campaign=ynetshops" title="YnetShops" className="shops" rel="nofollow" target="_blank"></a>
        </div>
    )
}