

import { VideoSettingsData, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { MultiImagesScroller, getItemMargin } from "../../../../commonComponents/base/multiImagesFrontScroller";
import { SiteItemData } from "widgets/interfaces"
import { ItemMagazineFront } from "./frontItem";
import { MultiImagesScrollPerItem } from "../../../../commonComponents/base/multiImagesScrollPerItem";




interface MultiImagesFrontProps {
    itemList: SiteItemData[]
    width: number
    fieldsDisplaySettings: IFieldDisplayData
    videoSettings: VideoSettingsData
    imageWidth: number
    imageHeight: number
    ads_infrastructure_type?: string
}



export class MultiImagesMagazineFront extends React.Component<MultiImagesFrontProps>{
    public static siteScriptName = "MultiImagesMagazineFront";
    public render() {
        const { imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings, width, ads_infrastructure_type } = this.props
        return (
            <MultiImagesScrollPerItem imageWidth={imageWidth} width={width} itemLength={itemList.length} marginRight={8}>
                {itemList.map((slot, index) => {
                    return (
                        <ItemMagazineFront
                            imageWidth={imageWidth}
                            key={index}
                            imageHeight={imageHeight}
                            videoSettings={videoSettings}
                            fieldsDisplaySettings={fieldsDisplaySettings}
                            item={slot}
                            marginLeft={8}
                            ads_infrastructure_type={ads_infrastructure_type}
                        />
                    )
                }
                )}
            </MultiImagesScrollPerItem >)
    }
}