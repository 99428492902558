require("./ynet_pplus_magazine_site_liveFloatingRadioPlayer.less")
import { RadioProgramItem } from "../radioHompage/components/radioHomepagePlayerComponenta";
import Slider from "react-slick"
import * as ResizeObserver from "resize-observer-polyfill"
import { TogglePlayAction } from "./audioPlayButton";

interface FloatingRadioPlayerProps {
    programItem: RadioProgramItem
    isMobileWeb: boolean
    isAudioPlaying: boolean
    isFloatingPlayerVisible: boolean
    audioElement: HTMLAudioElement
    onClose: () => void
    isMuted: boolean
    isSeeking: boolean
    handleMuteSwitch: () => void
    toPlayback: (startPostion: number) => void
    onTogglePlayPause: () => TogglePlayAction
}

interface FloatingRadioPlayerState {
    isTitleOverFlowing: boolean
    floatingPlayerBottomHeight: number
}

export class LiveFloatingRadioPlayer extends React.Component<FloatingRadioPlayerProps, FloatingRadioPlayerState> {
    constructor() {
        super()
        this.state = {
            isTitleOverFlowing: null,
            floatingPlayerBottomHeight: 0
        }
    }

    private resizeObserver: ResizeObserver;

    componentDidMount() {
        const elementId = this.props.isMobileWeb ? "ads.banner" : "ads.sargel"
        const stickyAdElement = document.getElementById(elementId)

        this.setState({ isTitleOverFlowing: this.getTitleOverflowStatus() })


        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            this.onContentHeightChanged(r.contentRect.height)
        }))

        stickyAdElement && this.resizeObserver.observe(stickyAdElement);
    }

    componentDidUpdate(prevProps) {
        if (this.props.programItem !== prevProps.programItem) {
            this.setState({ isTitleOverFlowing: this.getTitleOverflowStatus() })
        }
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect()
    }

    private getTitleOverflowStatus = () => {
        const titleElement = document.querySelector(".floating-player-show-title") as HTMLElement
        const wrapperElement = document.querySelector(".floating-player-show-title-wrapper") as HTMLElement
        let titleOverflow = false
        if (titleElement && wrapperElement) {
            titleOverflow = titleElement.offsetWidth > wrapperElement.offsetWidth
        }
        return titleOverflow
    }

    private getShowTime = (item: RadioProgramItem) => {
        if (item && !item.hideHours) {
            return `${item.startTime} - ${item.endTime}`
        }
        return ""
    }

    private onContentHeightChanged = (height: number) => {
        this.setState({ floatingPlayerBottomHeight: height })
    }

    render() {
        const { programItem, isAudioPlaying, isFloatingPlayerVisible, onClose, isMuted, isSeeking, handleMuteSwitch, isMobileWeb, onTogglePlayPause } = this.props
        const { floatingPlayerBottomHeight, isTitleOverFlowing } = this.state
        const progressBar = this.props.children
        const settings = {
            dots: false, infinite: true, slidesToShow: 1, slidesToScroll: 1, autoplay: true,
            speed: programItem.title.length * 200, autoplaySpeed: 0, rtl: true, arrows: false, cssEase: "linear"
        }

        return (
            <div className={`radio-floating-player ${isFloatingPlayerVisible ? "show" : "hide"}`} style={{ bottom: floatingPlayerBottomHeight }}>
                <button className="floating-player-close-sign" onClick={onClose} aria-label="close player"></button>

                <div className="floating-player-logic-container">
                    <button className={`floating-player-button ${isSeeking ? "floating-play-button-loader" : ""}`} onClick={onTogglePlayPause} aria-label={isAudioPlaying ? `pause - ${programItem.title}` : `play - ${programItem.title}`}>
                        <div className={`floating-player-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                    </button>

                    <div className="floating-player-control-ruler">
                        {!isMobileWeb && <div className="control-ruler-buttons">
                            <button className={`${isMuted ? 'sound-off-btn' : 'sound-on-btn'} ruler-btn`} onClick={handleMuteSwitch} aria-label={isMuted ? 'sound is off' : 'sound is on'}></button>
                        </div>}

                        {progressBar}
                    </div>
                </div>

                <div className="floating-player-content">
                    <div className="floating-player-show-details">
                        {!isMobileWeb && <img className="floating-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />}
                        {!programItem.hideHours && <div className="floating-player-show-time">{this.getShowTime(programItem)} | </div>}
                    </div>

                    <div className="floating-player-show-title-wrapper">
                        {isTitleOverFlowing ?
                            <>
                                <div className="fadder-right"></div>
                                <div className="slider-wrapper">
                                    <Slider {...settings}>
                                        <div className="floating-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                                        <div className="floating-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                                    </Slider>
                                </div>
                                <div className="fadder-left"></div>
                            </> :
                            <div className="floating-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}