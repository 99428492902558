/*
Based on React-ContentEditable: https://github.com/lovasoa/react-contenteditable
*/


interface ContentEditableProps {
    html: any
    className?: string
    style?: React.CSSProperties
    tagName?: any
    onFocus?: () => void
    onBlur?: () => void
    disabled?: boolean
    onChange: (e: KeyboardEvent, value: string, plainText?: string) => void
    onMouseUp?: (e: MouseEvent) => void
    onPaste?: (e: ClipboardEvent) => void
    onKeyDown?: (e: KeyboardEvent) => void
}

interface ContentEditableState { }

export class ContentEditable extends React.Component<ContentEditableProps, ContentEditableState> {
    public constructor() {
        super();
        this.emitChange = this.emitChange.bind(this);
    }
    public htmlEl: any;
    private lastHtml: string;

    public render() {
        var { tagName, html, ...props } = this.props;

        return React.createElement(
            tagName || 'div',
            {
                ...props,
                ref: (e) => this.htmlEl = e,
                onInput: this.emitChange,
                onBlur: this.props.onBlur || this.emitChange,
                onFocus: this.props.onFocus,
                contentEditable: !this.props.disabled,
                dangerouslySetInnerHTML: { __html: html },
                onMouseUp: this.props.onMouseUp,
                onPaste: this.props.onPaste,
                onKeyDown: this.props.onKeyDown,
            },
            this.props.children);
    }

    public shouldComponentUpdate(nextProps: ContentEditableProps) {
        // We need not rerender if the change of props simply reflects the user's
        // edits. Rerendering in this case would make the cursor/caret jump.
        return (
            // Rerender if there is no element yet... (somehow?)
            !this.htmlEl
            // ...or if html really changed... (programmatically, not by user edit)
            || (nextProps.html !== this.htmlEl.innerHTML
                && nextProps.html !== this.props.html)
            // ...or if editing is enabled or disabled.
            || this.props.disabled !== nextProps.disabled
            || this.props.className != nextProps.className
            || this.props.style != nextProps.style
        );
    }

    public componentDidUpdate() {
        if (this.htmlEl && this.props.html !== this.htmlEl.innerHTML) {
            // Perhaps React (whose VDOM gets outdated because we often prevent
            // rerendering) did not update the DOM. So we update it manually now.
            this.htmlEl.innerHTML = this.props.html;
        }
    }

    emitChange(evt) {
        if (!this.htmlEl) return;
        var html = this.htmlEl.innerHTML;
        let plainText = this.htmlEl.innerText;
        if (this.props.onChange && html !== this.lastHtml) {
            evt.target = { value: html, plainText};
            this.props.onChange(evt, evt.target.value, evt.target.plainText);
        }
        this.lastHtml = html;
    }
}