require("./yplus_site_ynetPlusHeaderComponenta-mobile.less")
require("./yplus_site_ynetPlusHeaderComponenta.less")
import * as throttle from "lodash/throttle"
import { YplusSubChannelMenuMobile } from "../../yPlusSubChannelHeader/components/mobileSubChannelHeader";
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import Slider from 'react-slick'
import { yPlusHeaderItem } from "./headerFront";
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import { CircleProgressbarAnimated } from "./circlePropgressBar";
import { getQueryStringValue } from "../../../widgetsUtils";

export interface yPlusHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    catId: string
}

interface yPlusHeaderMenuProps {
    items: yPlusHeaderItem[]
    categoryId: string
    id?: string
    isMenuVisible: boolean
    siteId?: string
    menuItems?: yPlusHeaderMenuItem[]
    gallerySeconds: number
    seachPageLink: string
    mainLogoLink: string
    parentSiteLink: string

}
interface yPlusHeaderMenuState {
    isScrolled: boolean
    activeSlide: number
    autoPlay: boolean
    isUserLogged: boolean
}
/**
 *   
 */
export class MobileYnetPlusHeader extends React.Component<yPlusHeaderMenuProps, yPlusHeaderMenuState>{
    public static siteScriptName = "MobileYnetPlusHeader";
    private lastIndex = this.props.items.length - 1;
    constructor(props) {
        super(props as yPlusHeaderMenuProps);
        this.state = {
            isScrolled: false,
            activeSlide: this.lastIndex,
            autoPlay: true,
            isUserLogged: false

        }

        this.previous = this.previous.bind(this);
    }
    private slider;
    private previous() {
        this.slider.slickPrev();
    }

    private stickyBar() {

        if (window.pageYOffset >= 80) {
            this.setState({ isScrolled: true })

        } else {
            this.setState({ isScrolled: false })
        }
    }

    private mobileAutoGalleryInterval: any;
    private startAutoMobileGallery = () => {
        const { gallerySeconds, items } = this.props
        if (typeof (gallerySeconds) === "number") {
            if (gallerySeconds > 0) {
                this.mobileAutoGalleryInterval = setInterval(() => {
                    this.previous();
                }, gallerySeconds * 1000);
            }
            else {
                clearInterval(this.mobileAutoGalleryInterval)
                this.setState({ autoPlay: false })
            }
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())

        setTimeout(() => {
            (document.getElementsByClassName("slick-slider")[0] as HTMLElement).style.visibility = "visible";
        }, 50);
        if (window.innerWidth <= 700) {
            if (this.props.items.length > 1) {
                this.startAutoMobileGallery();
            }

        }
        else {
            clearInterval(this.mobileAutoGalleryInterval)
            this.setState({ autoPlay: false })
        }
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLogged: true })
        }
    }


    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
        clearInterval(this.mobileAutoGalleryInterval)
        this.setState({ autoPlay: false })
    }

    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 250)

    private pauseSlider = () => {
        clearInterval(this.mobileAutoGalleryInterval)
        this.setState({ autoPlay: false })

    }
    private appendDots = (dots) => {
        return (
            <div>
                <ul style={{ margin: "auto", width: "auto" }} onClick={() => this.pauseSlider()}> {dots} </ul>
            </div>
        )
    }
    private costumPaging = (i) => {
        return (
            <button className={!this.state.autoPlay ? "onSelectWithoutAutoplay" : ""} onMouseUp={() => this.analyticsStoriesBarPushFunc(i)}>
                <CircleProgressbarAnimated
                    isActive={this.state.autoPlay && i === this.state.activeSlide}
                    index={this.lastIndex - i}
                    seconds={this.props.gallerySeconds}
                    sqSize={40}
                    key={i} />
            </button>
        )
    }
    private afterChange = (i) => {
        this.setState({ activeSlide: i })
    }

    private analyticsStoriesBarPushFunc = (itemIndex: number) => {
        const currIndex = this.lastIndex - itemIndex + 1
        const currArticleId = this.props.items[itemIndex].articleId

        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'Navigation_Events',
                Category: 'Ynet+ - Navigation',
                Action: `Story ${currIndex} Clicked`,
                Label: currArticleId
            });
        }
    }

    render() {
        var settings = {
            draggable: false,
            speed: 500,
            infinite: true,
            dots: true,
            onSwipe: () => this.pauseSlider(),
            appendDots: dots => this.appendDots(dots),
            customPaging: i => this.costumPaging(i),
            afterChange: (i) => this.afterChange(i),
            responsive: [{
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    initialSlide: 3,
                }
            }
            ],


        }
        const { items, categoryId, id, siteId, menuItems, isMenuVisible, seachPageLink, parentSiteLink, mainLogoLink } = this.props
        const { isScrolled, isUserLogged } = this.state

        let isHeaderForAppUnvisible = false;
        if (typeof window !== "undefined" && getQueryStringValue("IsApp")) {
            isHeaderForAppUnvisible = true
        }

        let logoImageUrl;
        let logoImageLink;
        let scrolled = isScrolled ? "scrolled" : ""
        return (
            <div className={`MobileYnetPlusHeader YnetPlusHeaderComponenta ${scrolled}`}  >
                <Slider ref={slider => (this.slider = slider)} {...settings}  >
                    {items.map((slot, index) => {
                        return (
                            <span key={index}>
                                <div className="bgDiv" style={{
                                    backgroundImage: `url("${slot.imgUrl}")`, backgroundRepeat: "no-repeat", direction: "rtl"
                                }}  >
                                    <span className="imgCredit">{slot.imgCredit}  </span>
                                    <div className="selectedItemText">
                                        <span className="categoryLabel" dangerouslySetInnerHTML={{ __html: slot.freeText }} />

                                        <HyperLinkBuilder className="mainTitle" articleId={slot.articleId} href={slot.link} linkTarget={slot.linkTarget}>
                                            <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                                        </HyperLinkBuilder>
                                        {slot.author.length > 0 && <div className="moreDetails">
                                            {slot.author}
                                        </div>}

                                    </div>
                                </div>
                            </span>
                        )
                    }
                    )}
                </Slider>
                <YplusSubChannelMenuMobile
                    items={menuItems} siteId={siteId}
                    categoryId={categoryId}
                    isMenuVisible={isMenuVisible}
                    isHeaderForAppUnvisible={isHeaderForAppUnvisible}
                    seachPageLink={seachPageLink}
                    isUserLogged={isUserLogged}
                    mainLogoLink={mainLogoLink}
                    parentSiteLink={parentSiteLink} />

            </div>
        )
    }
}



