import { SiteMediaData, SiteItemData, SiteImageData } from "../../../interfaces"
import { VideoSettingsData, LinkedItemType, ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { MediaContentTypes } from "constants/contentTypes"
import { LinkedItemFieldDisplay } from "../../../commonDataObjects/linkedItemsFieldsDisplay"
import { RenderReactAtSite } from "../../../commonComponents/inline/renderReactAtSite";
import { MutamMarketingSign } from "../../../commonComponents/inline/marketingSign/mutamMarketingSign";
import {separateHourFromDateAndFormatToString} from "widgets/widgetsUtils"

interface MultiImagesFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    itemList: SiteItemData[]
    videoSettings?: VideoSettingsData
    fieldDisplaySettings?: LinkedItemFieldDisplay
}


export class MultiImagesMobile extends React.Component<MultiImagesFrontProps, {}>{
    public static siteScriptName = "MultiImagesMobile";
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }
    public render() {
        const { imageWidth, itemList, imageHeight, videoSettings, fieldDisplaySettings } = this.props;
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const allMediaItems: SiteMediaData[] = itemList.map(item => { return item.media })

        return (<div className="slotsContent" >

            {itemList.map((slot, index) => {
                const shouldDisplayDateSeparator = fieldDisplaySettings.isAuthorVisible && (fieldDisplaySettings.isDateVisible || fieldDisplaySettings.isHourVisible) && slot.author && slot.author.length > 0;

                return (
                    <div className="slotView" key={`slotItem_${slot.itemId}_${index}`} >

                        <div className="imageArea">
                            {slot.marketingContentText && <div className="show-small-vp"><RenderReactAtSite>
                                <MutamMarketingSign isSmallItem={true} marketingContentText={slot.marketingContentText} />
                            </RenderReactAtSite></div>}
                            <SlotIconDisplay itemId={slot.itemId} icon={this.getIcon(slot.icon, slot.hasVideo)} iconClassName="medium" isIconVisible={slot.hasVideo} />
                            <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                <SiteMedia
                                    allMedias={allMediaItems}
                                    data={slot.media !== undefined ? slot.media : emptyMedia}
                                    width={imageWidth}
                                    height={imageHeight}
                                    videoSettings={videoSettings}
                                    itemLinkUrl={slot.titleLink}
                                    isImageLinkable={true}
                                    linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                />
                            </div> </div>
                        <div className="slotTextArea">
                            {fieldDisplaySettings.isTitleVisible && <div className="slotTitle">
                                <HyperLinkBuilder
                                    href={slot.titleLink}
                                    linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                    children={slot.title} >
                                    <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                                </HyperLinkBuilder>
                            </div>}
                            {fieldDisplaySettings.isSubTitleVisible && slot.subTitle !== undefined && <div className="slotSubTitle" >
                                <HyperLinkBuilder
                                    href={slot.titleLink}
                                    linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                    children={slot.subTitle} >
                                    <div dangerouslySetInnerHTML={{ __html: slot.subTitle }} />
                                </HyperLinkBuilder>
                            </div>}
                            <div className="moreDetails">
                                {fieldDisplaySettings.isAuthorVisible && slot.author && slot.author.length > 0 && <span className="authorField">{slot.author}</span>}
                                {shouldDisplayDateSeparator && <span>, </span>}
                                {(fieldDisplaySettings.isDateVisible || fieldDisplaySettings.isHourVisible) && <span>{separateHourFromDateAndFormatToString(slot.dateUpdatedOnSite, { showDate: fieldDisplaySettings.isDateVisible, showHour: fieldDisplaySettings.isHourVisible, dateTimeSeperator: " / " })} </span>}
                            </div>
                        </div>
                    </div>
                )
            }
            )}
        </div>

        )
    }
}
