import { CategoryListItem } from "./videoArchiveComponenta"


interface VideoArchiveArticleItemProps {
    categoryList: CategoryListItem[]
    toggleChannelMenu: () => void
    currChannel: CategoryListItem
    isChannelMenuVisible: boolean
    onChannelChanged: (newChannle: any) => void
    isLTR?: boolean
}
export class VideoArchiveChannelMenu extends React.Component<VideoArchiveArticleItemProps, {}>{
    public render() {
        const { toggleChannelMenu, currChannel, isChannelMenuVisible, categoryList, onChannelChanged, isLTR } = this.props;
        const categoryNameToDisplay = isLTR ? 'All channels' : 'כל הערוצים';
        const allList:CategoryListItem[] = [{description:categoryNameToDisplay, id:"1"}, ...categoryList];
        // console.log(allList, currChannel.description, currChannel)
        return (
            <div className="CtechChannelMenu">
                <span className="selectArrow" onClick={toggleChannelMenu} />
                <span className="selected" onClick={toggleChannelMenu}>{(currChannel.id === '1') ? categoryNameToDisplay : currChannel.description}</span>
                {isChannelMenuVisible &&
                    <div className="channleOptionsList">

                        {_.filter(_.map(allList, (categoryItem, index) => {
                            return (
                                <div key={index} className="menuOption" onClick={() => onChannelChanged(categoryItem)}>
                                    {(categoryItem.id === '5211' || categoryItem.id === '1') ? categoryNameToDisplay : categoryItem.description}</div>
                            )
                        }))
                        }
                    </div>}
            </div>
        )
    }
}