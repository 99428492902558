require("./ynet_site_redMailComponenta.less")

import { ExtraDetails } from "./extraDetails/extraDetails"
import { TextArea } from "./textArea/textArea"
import { PopUp } from "./popUp/popUp"
import { RedMailFileUploadYnet } from "./filesUpload/RedMailFileUploadYnet";
import { RedMailComponentaProps } from "../interfaces/redMailInterfaces";

export interface RedMailComponentaYnetProps extends RedMailComponentaProps {

}

export class RedMailComponentaYnet extends React.Component<RedMailComponentaYnetProps & {isChecked: boolean}, {}>{

    public render() {
        const { handleChangeText, isTextAreaBlank, countSubmitAttempts, textArea,
                handleSelectedFile, selectedFiles, hashes, invalidFilesName, showFileStatusProgress,
                photographer, sender, mail, phoneNumber, validation, handleChangeExtraData, isAgreementApproved,
                IsPopUp, submitForm, loading, onClose, submitButtonDisabled, isChecked } = this.props;

        const ynetTermsHref = "https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html";
        const ynetPolicyHref = "https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html";
        const ynetTermsLink = <a href={ynetTermsHref} className="termsClick">תנאי השימוש</a>
        const ynetPolicyLink = <a href={ynetPolicyHref} className="termsClick">מדיניות הפרטיות</a>

        const textData = {
            headlineText: "המייל האדום",
            textArea: {
                textAreaHeadline: "יש לכם מידע חשוב שטרם נחשף? צילומים מאירוע חדשותי? נשמח שתשתפו אותנו.",
                textAreaHeadlineShareButtonsActive: 'יש לכם מידע חשוב שטרם נחשף? צילומים מאירוע חדשותי?',
                textAreaHeadlineShareButtonsActiveSecondLine:" נשמח שתשתפו אותנו – בטופס זה, בווטסאפ או בטלגרם",
                placeholder: "תוכלו לשתף אותנו גם כאן",
            },
            extraDetailsData: {
                title: "פרטים נוספים (לא חובה)",
                subTitle: "",
                mailPattern: "צריך להיות בתבנית הבאה: ynet@ynet.com "
            },
            terms: <>הריני לאשר שהתוכן שאשלח: מקורי, לא מזויף או מבוים, לא מימנתי את הפקתו, הוא אינו מועתק או נגוע במעשה בלתי חוקי כגון הסגת גבול ופגיעה בפרטיות. התוכן לא נשלח לשום גורם אחר במקביל והוא מועבר לבעלותכם הבלעדית, ללא עלות וללא מגבלה. פרטיי מהימנים לטובת קרדיט לצד פרסום התוכן, אם תבחרו לפרסמו ובמידה ותידרשו למסור אותם לגורם רשמי. קראתי, הבנתי ומסכים לאמור ב{ynetTermsLink} וב{ynetPolicyLink} ולקבלת דיוורים מאתר ynet.</>
        }

        return (
            <div>
                <div className="RedMailComponenta">
                    <div className="redMailLeftSide">
                        <div className="redMailHeadline">
                            <div className="headlineImage" />
                            <p className="headlineText">המייל&nbsp;<span>האדום</span></p>
                        </div>
                        
                        <TextArea textAreaData={textData.textArea} value={textArea} isChecked={isChecked} onChange={handleChangeText} isTextAreaBlank={isTextAreaBlank && countSubmitAttempts > 0} />
                        {(isTextAreaBlank && countSubmitAttempts > 0) && <div className="contentErrorMessage">נראה ששכחת לצרף את תוכן ההודעה</div>}
                        <RedMailFileUploadYnet
                            handleSelectedFile={handleSelectedFile}
                            selectedFiles={selectedFiles}
                            hashes={hashes}
                            invalidFilesName={invalidFilesName}
                            showFileStatusProgress={showFileStatusProgress}
                        />

                        <ExtraDetails
                            photographer={photographer}
                            sender={sender}
                            mail={mail}
                            phoneNumber={phoneNumber}
                            onChange={handleChangeExtraData}
                            validation={validation}
                            extraDetailsData={textData.extraDetailsData}
                        />

                        <p className="terms">
                            <input className="checkBox" id="agreementApproved" type="checkbox" checked={isAgreementApproved} onChange={(e) => handleChangeExtraData(e)} />
                            {textData.terms}
                        </p>

                        {(!isAgreementApproved && countSubmitAttempts > 0) && <div className="contentErrorMessage terms-error">יש לאשר את תנאי השימוש</div>}
                        <button
                            disabled={submitButtonDisabled}
                            className="submitButton"
                            type="submit"
                            onClick={submitForm}>
                            שליחה
                        </button>
                        {IsPopUp && <PopUp onClose={onClose} loading={loading} />}
                    </div>
                </div>
            </div>
        )

    }
}



