import * as axios from "axios"
import * as map from "lodash/map"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { colorOptions } from "../../../../../textEditor/constants/linkProperties";
import Slider from 'react-slick'
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { isWcmEditor } from "config/serverConfig"
require("./calcalist_site_ynetFlashList.less")
interface listState {
    articles: any[]
}


export class YnetFlashListComponenta extends React.Component<{}, listState> {
    public static siteScriptName = "YnetFlashListComponenta"
    private slider;
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
        }
    }

    componentDidMount() {
        this.getArticles()
    }


    private getArticles = () => {
        const { articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());

        axiosInstance.get(`https://www.ynet.co.il/ticker/news/184/30/`)
            .then((res: any) => {
                this.setState({ articles: res.data });
            }).catch(err => {
                console.error(err, "lsa")
            })
    }


    private openFlashPopUp = (id: string) => {
        window.open(`https://www.ynet.co.il/Ext/Comp/CdaNewsFlash/0,2297,L-${id}_184,00.html`, '', 'height=600,width=500,location=no,status=no,directories=no,scrollbars=yes,menubars=no,toolbars=no,resizable=yes,left=50,top=50');
        return false;
    }


    public render() {
        const { articles } = this.state;
        const linkTarget: LinkTargetProperties = {
            linkOpenType: "new",
            lightBoxWidth: 0,
            lightBoxHeight: 0,
            lightBoxOpacity: 70,
            lightBoxBgColor: colorOptions[0],
        }

        var settings = {
            draggable: false,
            infinite: true,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            arrows: false

        }
        return (
            <div className={"YnetFlashListComponenta"}>
                <HyperLinkBuilder
                    className="TabOfList"
                    href="//www.ynet.co.il"
                    linkTarget={linkTarget}
                ><div className="mainTitleText" >ynet</div><div className="sep_line">|</div><div className="subTitleText">מבזקים</div></HyperLinkBuilder>


                <Slider ref={slider => (this.slider = slider)} {...settings} className="contentList"  >
                    {map(articles, (item, index) =>
                        <div className="slotView" key={index} onClick={isWcmEditor() ? () => null : () => this.openFlashPopUp(item.id)}>
                            <div className="itemDate">{separateHourFromDateAndFormatToString(new Date(item.datetime), { showHour: true, showDate: false })}</div>
                            <div className="itemTitle">
                                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                            </div>
                        </div>

                    )}
                </Slider>

                <HyperLinkBuilder
                    className="bottomLink"
                    href="//www.ynet.co.il/home/0,7340,L-184,00.html"
                    linkTarget={linkTarget}>לכל המבזקים ></HyperLinkBuilder>


            </div >
        )
    }
}