import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton";
import { WhatsAppShareButton } from "../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton";
import { openFacebookShare, analyticsPushFunc } from "../../../../widgetsUtils"

export class SiteArticleHeaderMobileShare extends React.Component<{ lang: string, title: string}, {}> {
    public static siteScriptName = "SiteArticleHeaderShareMobileWrapper"
    private whatsAppIcon = "/images/ynetnewsresp/footer-whats.png"
    private facebookIcon = "/images/ynetnewsresp/facebook_icon.png"
    private mailIcon = "/images/ynetnewsresp/send_icon.png"
    private telegramIcon = "/images/telegram50x50.png"

    private handleShareBtnClicked = (button: string, lang?: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Top", title), "Article Top")
                    break;

                case "whatsapp":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Article Top", title), "Article Top")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, lang, () => analyticsPushFunc("Mail", "Article Top", title), "Article Top")
                    break;

                case "te":
                    location.href = 'https://t.me/vestyisrael';
                    break;
            }
        }
    }

    public render() {
        const { lang } = this.props
        return (
            <span className="ArticleHeaderShareMobileComponent">
                {(lang === "ru") && <button title="telegram" className="te"
                    onClick={e => this.handleShareBtnClicked("te", lang)}>
                    <img src={this.telegramIcon} aria-hidden={true} /></button>
                }
                <button title="whatsapp" className="whatsapp"
                    onClick={e => this.handleShareBtnClicked("whatsapp")}>
                    <img src={this.whatsAppIcon} aria-hidden={true} /></button>
                <button title="facebook" className="fb" onClick={e => this.handleShareBtnClicked("fb")}>
                    <img src={this.facebookIcon} aria-hidden={true} /></button>
                <button title="send mail" className="mailto"
                    onClick={e => this.handleShareBtnClicked("mailto", lang)}>
                    <img src={this.mailIcon} aria-hidden={true} /></button>
            </span>

        )
    }
}