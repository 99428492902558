import { VideoSettingsData, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { MediaContentTypes } from "constants/contentTypes"
import { SiteItemData, SiteMediaData, SiteImageData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { MultiImagesScroller } from "../../../../commonComponents/base/multiImagesFrontScroller";
import { isHavakVisible } from "../../../../commonComponents/inline/payWallHavak/payWallHavak";
import { MarketingSign } from "../../../../commonComponents/inline/marketingSign/marketingSign"
import { googleScript } from "../../../../widgetsUtils"

interface MultiImagesFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    itemList: SiteItemData[]
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    commertialColor: string
    titleColor: string
    subTitleColor: string
    isCommertial: boolean
    isLtr?: boolean
    ads_infrastructure_type?: string
}


export class StripMarketingFront extends React.Component<MultiImagesFrontProps>{
    public static siteScriptName = "StripMarketingFront";

    private getItemMargin = (componentWidth: number, imageWidth: number) => {
        const singleItemWidth = imageWidth
        let numberofVisibleItems = Math.floor(componentWidth / singleItemWidth)
        let imagesWidth = numberofVisibleItems * singleItemWidth
        let spaceForMargin = componentWidth - imagesWidth
        let marginLeft = spaceForMargin / (numberofVisibleItems - 1)
        return marginLeft
    }



    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }
    public render() {
        const { width, imageWidth, itemList, imageHeight, videoSettings,
            fieldsDisplaySettings, commertialColor, titleColor,
            subTitleColor, isCommertial, isLtr, ads_infrastructure_type } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const allMediaItems: SiteMediaData[] = itemList.map(item => { return item.media })
        const dfpMarginStyle = isLtr ? { marginRight: this.getItemMargin(width, imageWidth) } : { marginLeft: this.getItemMargin(width, imageWidth) }
        return (<MultiImagesScroller width={width} imageWidth={imageWidth} itemLength={itemList.length}>
            <div>
                {itemList.map((slot, index) => {
                    if (slot.isDFP) return <div id={slot.dfpId} key={index} className="slotView dfpId" style={dfpMarginStyle}>
                                            {ads_infrastructure_type && ads_infrastructure_type === "new" && <script type="text/javascript" dangerouslySetInnerHTML={{ __html: googleScript(slot.dfpId) }}></script>}
                                           </div>
                    else return (
                        <div className="slotView" key={`slotItem_${slot.itemId}_${index}`}
                            style={{ marginLeft: this.getItemMargin(width, imageWidth) }}>
                            <div className="imageArea">
                                {!isHavakVisible({ premiumExpireDays: slot.premiumExpireDays, dateUpdatedOnSite: slot.dateUpdatedOnSite }) &&
                                    slot.isMarketingContent && <MarketingSign isSmallItem={true} marketingContentText={slot.marketingContentText} />}
                                <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                    <SiteMedia
                                        data={slot.media !== undefined ? slot.media : emptyMedia}
                                        width={imageWidth}
                                        height={imageHeight}
                                        videoSettings={videoSettings}
                                        itemLinkUrl={slot.titleLink}
                                        isImageLinkable={true}
                                        linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                    />
                                </div> </div>
                            <div className="slotTextArea">
                                {fieldsDisplaySettings.isTitleVisible && <div className="slotTitle small" style={{ color: titleColor }}>
                                    <HyperLinkBuilder
                                        href={slot.titleLink}
                                        linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                        children={slot.title} >
                                        <span>
                                            <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                                        </span>
                                    </HyperLinkBuilder>
                                </div>}
                                {fieldsDisplaySettings.isSubTitleVisible && slot.subTitle !== undefined && <div className="slotSubTitle" style={{ color: subTitleColor }}>
                                    <HyperLinkBuilder
                                        href={slot.titleLink}
                                        linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                        children={slot.subTitle} >
                                        <span>  <div dangerouslySetInnerHTML={{ __html: slot.subTitle }} /></span>
                                    </HyperLinkBuilder>
                                </div>}
                                {isCommertial && <div dangerouslySetInnerHTML={{ __html: slot.bottomText }} className="moreDetails" style={{ color: commertialColor }} />}
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        </MultiImagesScroller>
        )
    }
}
