export type SiteLanguage = "he" | "en" | "es"

const dict = {
    "ru": {
        "Weather": "Погода",
        "Weather in": "Погода в",
        "Weather data": "Погодные данные",
        "Provided by IMS": "Предоставлено IMS",
        "Provided by sunrise sunset": "Предоставлено sunrise sunset",
        "High/Low": "Мин/Макс",
        "Precipitation": "Вероятность осадков",
        "Humidity": "Влажность воздуха",
        "Wind": "Ветер",
        "Wave height": "Высота волн",
        "Water temp": "Температура воды",
        "Sunrise": "Восход",
        "Sunset": "Закат",
        "Now": "Сейчас",
        "Feels like": "Ощущается как",
        "Precipitation radar": "Погодный радар",
        "Weather map": "Погодная карта",
        "More international cities": "Погода в мире",
        "kph": "км/ч",
        "cm": "см",
        "North": "север",
        "South": "юг",
        "East": "Восток",
        "West": "Запад",
        "Choose city. type the start letters and hit enter": "Выбрать город, набрать первые буквы названия и нажать Enter",
        "mm": "mm",
        "Jellyfish map": 'карта медуз',
    },
    "he": {
        "Weather": "מזג אוויר",
        "Weather in": "מזג האוויר ב",
        "Weather data": "נתוני מזג האוויר",
        "Provided by IMS": "נתוני מזג אוויר באדיבות השירות המטאורולוגי",
        "Provided by sunrise sunset": "נתוני שעות זריחה ושקיעה",
        "High/Low": "טווח טמפרטורה",
        "Precipitation": "משקעים",
        "Humidity": "אחוזי לחות",
        "Wind": "Ветер",
        "Wave height": "גובה הגלים",
        "Water temp": "טמפ' המים",
        "Sunrise": "זריחה",
        "Sunset": "שקיעה",
        "Now": "עכשיו",
        "Feels like": "מרגיש",
        "Precipitation radar": 'מכ"מ משקעים',
        "Jellyfish map": 'מפת מדוזות',
        "Weather map": 'מכ"ם גשם',
        "More International cities": "ערים נוספות בעולם",
        "kph": 'ק"מ',
        "cm": 'ס"מ',
        "North": "צפון",
        "North East": "צפון מזרח",
        "South": "דרום",
        "East": "מזרח",
        "West": "מערב",
        "North West": "צפון מערב",
        "South West": "דרום מערב",
        "South East": "דרום מזרח",
        "to ": "ל",
        "Choose city. type the start letters and hit enter": "Выбрать город, набрать первые буквы названия и нажать Enter",
        "mm": "מ\"מ",
    }
}

export default (language) => {
    const lang = language
    return (phrase) => {
        if (language in dict) {
            return dict[lang][phrase] || phrase
        } else {
            return phrase
        }
    }
}

//import translate from "./tanslations/cityTranslations"
//const lang = "he"
//const t_ui = translate(lang)
//<div> t_ui("last update") </div>

