
import {LiveVideoPlayer} from "../../../ynet1280/liveVideoPlayerMutam/components/liveVideoPlayer"

export class RadionasVideoLiveMutamComponenta extends React.Component<{ videoSource: string }>{
    static siteScriptName = "RadionasVideoLiveMutamComponenta"
    public render() {
        const { videoSource } = this.props
        if (!videoSource) return null;
        return (
            <div className="liveVideoPlayerMutamWrapper">
                <LiveVideoPlayer
                    videoSource={videoSource}
                    isCustomControllers={true}
                    lang="ar" />
            </div>)
    }
}