import { YplusSubChannelMenuMobile } from "../../yPlusSubChannelHeader/components/mobileSubChannelHeader";
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { yPlusHeaderItem } from "./headerFront";
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import * as map from "lodash/map"
import { getQueryStringValue } from "../../../widgetsUtils";
import { YplusSearchInput } from "../../yPlusSubChannelHeader/components/searchInput";
import { ThreeDCarousel } from "../../../commonComponents/inline/3dCarousel/ThreeDCarousel";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { YnetPlusUserMenuItems } from "../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";

export interface yPlusHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    catId: string
}

interface yPlusHeaderMenuProps {
    items: yPlusHeaderItem[]
    categoryId: string
    id?: string
    isMenuVisible: boolean
    siteId?: string
    menuItems?: yPlusHeaderMenuItem[]
    gallerySeconds: number
    seachPageLink: string
    mainLogoLink: string
    parentSiteLink: string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]

}
interface yPlusHeaderMenuState {
    isScrolled: boolean
    activeSlide: number
    autoPlay: boolean
    isUserLogged: boolean
    isPianoUserValid:boolean
}

export class MobileYPlusTopStory extends React.Component<yPlusHeaderMenuProps, yPlusHeaderMenuState>{
    public static siteScriptName = "MobileYPlusTopStory";
    private lastIndex = this.props.items.length - 1;
    constructor(props) {
        super(props as yPlusHeaderMenuProps);
        this.state = {
            isScrolled: false,
            activeSlide: this.lastIndex,
            autoPlay: true,
            isUserLogged: false,
            isPianoUserValid:false
        }

        if (typeof window != 'undefined') {
            window.tp = window.tp || [];
        }
    }


    private stickyBar() {

        if (window.pageYOffset >= 80) {
            this.setState({ isScrolled: true })

        } else {
            this.setState({ isScrolled: false })
        }
    }

    private mobileAutoGalleryInterval: any;


    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLogged: true })
        }

        typeof window !== "undefined" && window.tp.push(["init", () => {
            this.setState({isPianoUserValid:window && window.tp && window.tp.pianoId.isUserValid()})
        }])

    }

    render() {

        const { items, categoryId, id, siteId, menuItems, isMenuVisible, seachPageLink, parentSiteLink, mainLogoLink, ynetPlusMenuItems } = this.props
        const { isScrolled, isUserLogged, isPianoUserValid} = this.state
        const isPianoLogin = typeof window !== "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;
        const isUserAuthenticated = isPianoLogin? isPianoUserValid : isUserLogged;



        let isHeaderForAppUnvisible = false;
        if (typeof window !== "undefined" && getQueryStringValue("IsApp")) {
            isHeaderForAppUnvisible = true
        }
        let itemsForMobile = items.reverse();
     
        const carouselItems = itemsForMobile.map((slot, index) => {
            return (

                <HyperLinkBuilder className="imageItem" key={index} articleId={slot.articleId} href={slot.link} linkTarget={slot.linkTarget} style={{
                    backgroundImage: `url("${slot.imgUrl}")`, backgroundRepeat: "no-repeat", direction: "rtl"
                }}  >
                    <div className="selectedItemText">
                        {slot.freeText && <span className="categoryLabel" dangerouslySetInnerHTML={{ __html: slot.freeText }} />}
                        <div className="mainTitle">
                            <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                        </div>
                        <span className="articleCredit">{slot.author}</span>
                        <span className="imgCredit">{slot.imgCredit}</span>

                    </div>
                    <SlotIconDisplay itemId={slot.articleId} icon={"video"} iconClassName="medium" isIconVisible={slot.hasVideo} />
                </HyperLinkBuilder>

            )
        }
        )
        return (
            <div className={`MobileYPlusTopStory YnetPlusHeaderComponenta scrolled`}  >
                <YplusSubChannelMenuMobile
                    items={menuItems} siteId={siteId}
                    categoryId={categoryId}
                    isMenuVisible={isMenuVisible}
                    isHeaderForAppUnvisible={isHeaderForAppUnvisible}
                    seachPageLink={seachPageLink}
                    isUserLogged={isUserLogged}
                    mainLogoLink={mainLogoLink}
                    parentSiteLink={parentSiteLink}
                    ynetPlusMenuItems={ynetPlusMenuItems}
                     />

                {seachPageLink !== undefined && isUserAuthenticated && <YplusSearchInput
                    className="mobileInputonPage"
                    seachPageLink={seachPageLink}
                    isInputOpened={true}
                    placeholder="מה מעניין אותך?"


                />}
                {isMenuVisible && <div className="HorizontalMenuList" >

                    {map(menuItems, (item, i) => {
                        return (
                            <div key={i} className="menuItem">
                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                </HyperLinkBuilder>
                            </div>
                        )
                    }
                    )}

                </div>}

                <ThreeDCarousel slides={carouselItems} />
            </div>
        )
    }
}



