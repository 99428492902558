import * as filter from "lodash/filter"
import * as map from "lodash/map"
import { LinkProperties } from "global/components/linkSettings/interfaces"
import { InfinityHeadlinesViewData } from "../interfaces"
import { InfinityHeadlinesList } from "./InfinityHeadlinesList"
import { ImageDetails } from "images/interfaces"
import { ArticleExtraData } from "../../../../articlePage/stateInterfaces"


export interface SiteInfinityHeadlinesData {
    articleId: string
    author: string
    categoryName: string
    dateUpdatedOnSite: Date,
    title: string,
    link: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean
    publishedLink: string
}

interface InfinityHeadlinesViewProps {
    height: number
    articles: ArticleExtraData[]
    fetchMoreHeadlines: () => void
    isCategoryVisible?: boolean
}

export class InfinityHeadlinesView extends React.Component<InfinityHeadlinesViewProps, {}>  {

    public render() {
        const { height, articles, fetchMoreHeadlines, isCategoryVisible } = this.props


        let itemList = [];

        if (Object.keys(articles).length > 0) {
            itemList = filter(map(articles, (article: ArticleExtraData, index) => {
                return {
                    articleId: article.articleId,
                    author: article.author,
                    categoryName: article.category.textToShow,
                    articlePublishDate: article.launchDate,
                    articleUpdateDate: article.dateUpdatedOnSite,
                    title: article.title,
                    dateUpdatedOnSite: article.dateUpdatedOnSite,
                    link: article.publishedLink,
                    promotionImageDetails: article.promotionImageDetails,
                    hasVideo: article.hasVideo,
                    publishedLink: article.publishedLink
                } as SiteInfinityHeadlinesData
            }))
        }

        return (
            <div className={"InfinityHeadlinesComponenta StripBlazerComponenta"}>
                <InfinityHeadlinesList
                    dataLength={articles.length}
                    itemList={itemList}
                    fetchMoreHeadlines={fetchMoreHeadlines}
                    height={height}
                    isCategoryVisible={isCategoryVisible}
                />
            </div>
        )
    }
} 