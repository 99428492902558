require("./ynetnews_vesty_site_redMailComponenta.less")
import { ExtraDetails } from "./extraDetails/extraDetailsYnetnews";
import { TextAreaYnetnews } from "./textArea/textAreaYnetnews";
import { PopUpYnetnews } from "./popUp/popUpYnetnews";
import { RedMailFileUploadYnetnews } from "./filesUpload/filesUploadYnetnews";
import { RedMailComponentaProps } from "../../../redMail/interfaces/redMailInterfaces";
import {getTranslation} from "../redMailTranslations";



export class RedMailComponentaYnetnews extends React.Component<RedMailComponentaProps & {isChecked: boolean}, {}>{

    public render() {
        const { handleChangeText, isTextAreaBlank, countSubmitAttempts, textArea,
                handleSelectedFile, selectedFiles, hashes, invalidFilesName, showFileStatusProgress,
                photographer, sender, mail, phoneNumber, validation, handleChangeExtraData, isAgreementApproved,
                IsPopUp, submitForm, loading, onClose, submitButtonDisabled, isChecked, lang } = this.props;

        const ynetnewsTermsHref = "https://www.ynetnews.com/articles/0,7340,L-3048342,00.html";
        const ynetnewsPolicyHref = "https://www.ynetnews.com/articles/0,7340,L-3048313,00.html";
        const vestyTermsHref = "https://www.vesty.co.il/articles/0,7340,L-4867560,00.html";
        const vestyPolicyHref = "https://www.vesty.co.il/articles/0,7340,L-4867557,00.html";
        const TermsLink = <a href={lang === "en-GB" ? ynetnewsTermsHref : vestyTermsHref} className="termsClick">{getTranslation(lang, "terms of use")}</a>
        const PolicyLink = <a href={lang === "en-GB" ? ynetnewsPolicyHref : vestyPolicyHref} className="termsClick">{getTranslation(lang, "privacy policy")}</a>

        const textData = {
            headlineText: <>{getTranslation(lang, "red")}<span>{getTranslation(lang, "mail")}</span></>,
            textArea: {
                textAreaHeadline: <>{getTranslation(lang, "headlineTitleRow1")}<br/>{getTranslation(lang, "headlineTitleRow2")}</>,
                placeholder: getTranslation(lang, "the information"),
            },
            extraDetailsData: {
                title: getTranslation(lang, "more details"),
                subTitle: "",
                mailPattern: getTranslation(lang, "mail pattern")
            },
            terms: <span>{getTranslation(lang, "terms1")} {TermsLink} {getTranslation(lang, "terms2")} {PolicyLink}{getTranslation(lang, "terms3")}</span>
        }
        const redMailClassName = `RedMailComponentaYnetnews ${lang === "ru-RU" ? "vesty" : ""}`
        
        return (
            <div>
                <div className={redMailClassName}>
                    <div className="redMailLeftSide">
                        <div className="redMailHeadline">
                            <p className="headlineText">{textData.headlineText}</p>
                            <div className="headlineImage" />
                        </div>
                        
                        <TextAreaYnetnews
                         textAreaData={textData.textArea} 
                         value={textArea} 
                         isChecked={isChecked} 
                         onChange={handleChangeText} 
                         isTextAreaBlank={isTextAreaBlank && countSubmitAttempts > 0} 
                         lang={lang}/>
                        {(isTextAreaBlank && countSubmitAttempts > 0) && <div className="contentErrorMessage">{getTranslation(lang, "required")}</div>}
                        <RedMailFileUploadYnetnews
                            handleSelectedFile={handleSelectedFile}
                            selectedFiles={selectedFiles}
                            hashes={hashes}
                            invalidFilesName={invalidFilesName}
                            showFileStatusProgress={showFileStatusProgress}
                            lang={lang}
                        />

                        <ExtraDetails
                            photographer={photographer}
                            sender={sender}
                            mail={mail}
                            phoneNumber={phoneNumber}
                            onChange={handleChangeExtraData}
                            validation={validation}
                            extraDetailsData={textData.extraDetailsData}
                            lang={lang}
                        />

                        <p className="terms">
                            {textData.terms}
                            <input className="checkBox" id="agreementApproved" type="checkbox" checked={isAgreementApproved} onChange={(e) => handleChangeExtraData(e)} />
                        </p>

                        {(!isAgreementApproved && countSubmitAttempts > 0) && <div className="contentErrorMessage terms-error">{getTranslation(lang, "terms of use must be approved")}</div>}
                        <button
                            disabled={submitButtonDisabled}
                            className="submitButton"
                            type="submit"
                            onClick={submitForm}>
                           {getTranslation(lang, "send")}
                        </button>
                        {IsPopUp && <PopUpYnetnews onClose={onClose} loading={loading} lang={lang} />}
                    </div>
                </div>
            </div>
        )

    }
}



