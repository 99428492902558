import { getCityNameTranslation } from "../../../ynetSearch/components/ynetWeather/cityNamesTranslator";

require('./ynetnews_vesty_site_ynetWeather.less')
var Cookies = require('js-cookie');

interface YnetWeatherState {
    selectedCity: string
}

export class YnetWeather extends React.Component<any, YnetWeatherState> {
    constructor(props) {
        super(props)
        let city = Cookies.get('weatherCity');
        if (city) {
            this.state = {
                ...this.state,
                selectedCity: city
            }
        }
        else {
            this.state = {
                ...this.state,
                selectedCity: '0'
            }
        }
    }
    private updateTemperature = (data) => {
        this.setState({
            ...this.state,
            selectedCity: data.target.value
        })

        Cookies.set('weatherCity', data.target.value, { expires: 365 });
    }
    public render() {
        const { weather, lang, weatherUrl } = this.props
        const { selectedCity } = this.state
        const selectedWeather = weather[selectedCity];
        return (

            <div id="main_search_weather" className="main_search_elements">
                <div id="cdanwmansrch_weather">
                    <span>{getCityNameTranslation(lang, "תחזית:")}</span>
                    <div id="cdanwmansrch_weathertempsdiv">
                        <div className="weathertempsdiv">
                            {selectedWeather && <a className="main_search_nounderlined_link" href={weatherUrl ? weatherUrl : "//www.ynet.co.il/home/0,7340,L-201,00.html"}><span className="weathertemps">{selectedWeather.temp + 'C'}</span></a>}
                        </div>
                        <div className="weatherimage">
                            {selectedWeather && <a href={weatherUrl ? weatherUrl : "//www.ynet.co.il/home/0,7340,L-201,00.html"} aria-label="WEATHER"><img height="20" src={"/Cnt/Images/Weather/" + selectedWeather.imageName} aria-hidden="true" width="20"></img></a>}
                        </div>
                        <div id="mainWeatherSelect">
                            <select id='weathercityselect' aria-label="CHOOSE A CITY" className="weathercitieselect" onChange={(data) => this.updateTemperature(data)} value={this.state.selectedCity}>
                                {weather.map((city, i) => <option key={i} value={i.toString()}>{getCityNameTranslation(lang, city.city)}</option>)}
                            </select>
                        </div>
                    </div></div>
                <div className="main_search_sep2 main_search_elements">
                    <img src="/images/mainsearch_sep.png" aria-hidden="true" />
                </div>
            </div>


        )
    }
}
