import * as classNames from "classnames"

interface Props {
    value: string
    onChange: (value: any) => void
    isTextAreaBlank: boolean
    textAreaData: any
    isChecked: boolean
}
export class TextArea extends React.Component<Props, State> {

    render() {
        const { value, onChange, isTextAreaBlank, textAreaData, isChecked } = this.props
        return (
            <div id="RedMailTextAreaElement" className="RedMailTextArea">
                <div className="redMailTextAreaTop">
                    <p className="TextAreaHeadline">{textAreaData.textAreaHeadlineShareButtonsActive}&nbsp;<span>מצאתם טעות בכתבה?</span></p>
                    <p className="TextAreaHeadline">{textAreaData.textAreaHeadlineShareButtonsActiveSecondLine}</p>
                    {isChecked &&
                        <div className="shareButtonsWrapper">
                            <a href="https://wa.me/972548243550" className="whatsappButton" target="_blank">
                                <img />
                                <div>Whatsapp</div>
                            </a>
                            <a href="https://t.me/ynetTbot" className="telegramButton" target="_blank">
                                <img />
                                <div>Telegram</div>
                            </a>
                        </div>
                    }
                </div>
                <div className="textAreaWrapper">
                    <div>{textAreaData.placeholder}</div>
                    <textarea dir="rtl" placeholder={textAreaData.placeholder} value={value} onChange={(e) => onChange(e)} required className={classNames("textAre", { "placeholderErrorStyle": isTextAreaBlank })}></textarea>
                </div>
            </div>
        )
    }
}