import { City } from '../WeatherMapExtendedTab/WeatherMapExtendedTab'

interface MapProps {
    citiesList: Array<City>
    isNewLayout: boolean
}


const WeatherHumidityMap = (props: MapProps) => {
    const { citiesList, isNewLayout } = props
    const oldIcon = `/images/weather/humidity.svg`
    const newIcon = `/images/weather-new-icons/weatherMapIcons/lahot_icon.svg`

    const chosenCities = ["Elat", "Ashdod", "Beer Sheva", "Bet Shean", "Haifa", "Tiberias", "Jerusalem", "Mizpe Ramon", "Zefat", "Tel Aviv Yafo"]

    let cities = citiesList && citiesList.map((city: City) => {
        if (city && city.name && (chosenCities as any).includes(city.name)) {
            return (
                <div key={city.name} className={`city-item ${city.name.toLowerCase().replace(/ /g, "-")}`}>
                    <div>
                        <img src={!!isNewLayout ? newIcon : oldIcon} className="humidity-img" />
                    </div>
                    <div className="info-title">
                        <span className="temp-info">{`${city.humidity}`}</span><span className="prec-text">%</span>
                        <br />
                        <span className="city-title">{city.nameHeb}</span>
                    </div>
                </div>
            )
        }
        return null
    })

    return (
        <div className="weather-extended-map-module" role="presentation">
            <div className="map-container">
                {cities}
            </div>
        </div>
    )
}

export default WeatherHumidityMap