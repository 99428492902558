import * as filter from "lodash/filter"
import { objectKeyToShow } from "../../../../../inEditTracker/const"

interface translationSettings {
    [lang: string]: any
}

export const shareButtonsTranslations =
{
    "he": {
        "facebook": "שיתוף בפייסבוק",
        "print": "הדפסה",
        "mailto": "שליחת כתבה",
        "comment": "תגובה לכתבה",
        "gift": "עיתון לשבועיים מתנה!",
        "share": "שתף ב-",
        "copy link": "העתק קישור",
    },
    "en": {
        "facebook": "facebook",
        "print": "print",
        "mailto": "send to friend",
        "comment": "comment",
        "gift": "",
        "share": "share",
        "copy link": "copy link"
    },
    "ru": {
        "facebook": "Фейсбук",
        "print": "печать",
        "mailto": "послать другу",
        "comment": "комментировать",
        "gift": "",
        "share": "поделиться",
        "copy link": ""
    },
    "es": {
        "facebook": "facebook",
        "print": "impresión",
        "mailto": "Enviar a un amigo",
        "comment": "Comentar",
        "gift": "",
        "share": "Compartir",
        "copy link": ""
    }
}


export const getShareTranslations = (lang, text: string) => {
    var obj = filter(shareButtonsTranslations, function (v, k) {
        return k === lang;
    });
    var content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]

}

