require("./menta_laisha_site_header-mobile.less")

import * as classNames from "classnames"
import * as map from "lodash/map"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces";
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"

export interface laishaHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
}

interface laishaHeaderMenuProps {
    items: laishaHeaderMenuItem[]
    id?: string
    parentSiteLogoUrl: string
    parentSiteLogoLink: string
    parentSiteLogoTarget?: any
    parentSiteLogoTitle?: any
    isOpened?: boolean
    onClose?: () => void
    instagramLink: string
    facebookLink: string
    contactusLink: string
}


export class LaishaSubChannelMenuMobile extends React.Component<laishaHeaderMenuProps, {}>{

    render() {
        const { items, instagramLink, facebookLink, contactusLink, parentSiteLogoLink, parentSiteLogoTarget, isOpened, onClose, parentSiteLogoTitle } = this.props     
        let LaishaMobileHeaderContainer = classNames("LaishaMobileHeaderContainer", { "opened": isOpened === true, "closed": isOpened === false })
        let logoImageUrl = "/Common/images/y-logo.png"
        
        return (
            <div className={LaishaMobileHeaderContainer} style={{ direction: "rtl" }}>
                <div className="closeDivArea" onClick={onClose} />
                <div className="MobileMenu" id="menu"  >
                    <div className="menuHeader">
                        <div className="menuContent">
                            <ul className="LaishaNavList" >
                                {map(items, (item, i) => {
                                    return (
                                        <li key={i} className={"menuItem"}>
                                            <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                            </HyperLinkBuilder>
                                        </li>
                                    )
                                }
                                )}
                            </ul>

                            {contactusLink ?
                                <div className="social contactus">
                                    <div className="icons">
                                        <a href={contactusLink} target="_blank">
                                            <div className="emailIcon" />
                                        </a>
                                    </div>
                                    <span className="contact"> צור קשר </span>
                                </div>
                                :
                                null
                            }    

                            <div className="social">
                                <span>עקבו אחרינו</span>
                                <div className="icons">
                                    <a href={instagramLink} target="_blank">
                                        <div className="instagramIcon" />
                                    </a>
                                    <span className="vl"></span>
                                    <a href={facebookLink} target="_blank">
                                        <div className="facebookIcon" />
                                    </a>
                                </div>
                            </div>



                            <HyperLinkBuilder className="mainSiteLogo" href={parentSiteLogoLink} linkTarget={parentSiteLogoTarget} rel="nofollow">
                                <SiteSimpleImage src={logoImageUrl} title={parentSiteLogoTitle} />
                                <span>    ראשי</span>

                            </HyperLinkBuilder>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

