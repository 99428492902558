import { analyticsPushFunc } from "../../../widgetsUtils";

interface FrontCopyButtonProps {
    popupText: string;
    classes?: string;
    children?: React.ReactChild;
    style?: any;
    title?: string;
    url?: string;
    blogPostId?: string;
}

interface FrontCopyButtonState {
    isPopupVisible: boolean;
}

export class FrontCopyButton extends React.Component<
    FrontCopyButtonProps,
    FrontCopyButtonState
> {
    public static siteScriptName = "FrontCopyButton";
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false,
        };
    }

    private copyToClipboard = () => {
        const { title, url, blogPostId } = this.props;
        if (url) {
            navigator.clipboard.writeText(url);
        } else {
            analyticsPushFunc("copy_url", "Share", title);
            navigator.clipboard.writeText(
                `${
                    window.location.href
                }?utm_source=ynet.co.il&utm_medium=Share&utm_campaign=copy_url${
                    blogPostId ? `#${blogPostId}` : ""
                }`
            );
        }

        this.setState({ isPopupVisible: true }, () => {
            setTimeout(() => {
                this.setState({ isPopupVisible: false });
            }, 3000);
        });
    };

    render() {
        const { children, classes, popupText, style } = this.props;
        const { isPopupVisible } = this.state;
        const buttonClass = classes ? classes : "frontCopyButton-pf";

        return (
            <>
                {isPopupVisible && (
                    <div className="copyPopup" style={style}>
                        {popupText}
                    </div>
                )}
                <div className={buttonClass} onClick={this.copyToClipboard}>
                    {children}
                </div>
            </>
        );
    }
}
