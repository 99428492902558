declare var window: Window & {
    languageCode: string
}
export const formatDateDisplay = (date: string, isArticleStr: string, isMinutesAgo?:boolean, hiddenYesterday?:boolean) => {
    if (date) {
        const isArticle = isArticleStr === "1"
        const dateToManipulateTemp = new Date(date)
        const diffFromToday = getDiffFromToday(dateToManipulateTemp)
        const arabSite = window && window.languageCode && window.languageCode.slice(0, 2) === "ar";
        switch (diffFromToday) {
            case 0: return getToday(dateToManipulateTemp, isMinutesAgo);
            case 1: return arabSite ? getRegularDate(dateToManipulateTemp, true) : getYesterday(dateToManipulateTemp, hiddenYesterday);
            default: return getRegularDate(dateToManipulateTemp, arabSite ? true : isArticle);
        }
    }
    else return ""
}
function formatNum(num: number) {
    const displayNum = num < 10 ? '0' + num : num;
    return displayNum
}
const hours = (date: Date) => {
    return formatNum(date.getHours())
}
const minutes = (date: Date) => {
    return formatNum(date.getMinutes())
}
const days = (date: Date) => {
    return formatNum(date.getDate())
}
const month = (date: Date) => {
    return formatNum(date.getMonth() + 1)
}

const year = (date: Date) => date.getFullYear().toString().slice(-2)

function getToday(date: Date, isMinutesAgo?: boolean) {
    let time = `${hours(date)}:${minutes(date)}`
    if (isMinutesAgo && minutesDiff(date) < 60){
        if (window && window.languageCode) {
            if (window.languageCode.slice(0, 2) === "en") {
                time = `${minutesDiff(date)} minutes ago`;
            }
            else if (window.languageCode === "ru-RU") {
                time = `${minutesDiff(date)} минут назад`;
            }
            else if (window.languageCode === "he-IL") {
                time = `לפני ${minutesDiff(date)} דקות`;
            }
        }
    }
        return time
}
function minutesDiff(date: Date) {
    let secDiff = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    let minutesDiff = Math.floor(secDiff / 60);
    return minutesDiff;
}


function getYesterday(date: Date, hiddenYesterday?:boolean) {
    let yesterday = "אתמול";
    if (window && window.languageCode) {
        if (window.languageCode.slice(0, 2) === "en") {
            yesterday = "Yesterday";
        }
        else if (window.languageCode === "ru-RU") {
            yesterday = "Вчера";
        }
        else if (window.languageCode === "he-IL") {
            yesterday = "אתמול";
        }
        if (window.languageCode.slice(0, 2) === "ar") {
            yesterday = "امس";
        }
    }
let time = `${yesterday} | ${hours(date)}:${minutes(date)}`;
if(hiddenYesterday){
    if (window && window.languageCode) {
        if (window.languageCode.slice(0, 2) === "en") {
          time = `${month(date)}.${days(date)}.${year(date)}`;   
        }
        else{
            time = `${days(date)}.${month(date)}.${year(date)}`
        }  
}
}
    return time;
}
function getRegularDate(date: Date, isArticle: boolean) {

    let dateView = `${days(date)}.${month(date)}.${year(date)}`
    if (window && window.languageCode) {
        if (window.languageCode.slice(0, 2) === "en") {
            dateView = `${month(date)}.${days(date)}.${year(date)}`
        }
    }
    const time = isArticle ? `${dateView} | ${hours(date)}:${minutes(date)}` : dateView;
    return time
}
export function getDiffFromToday(date: Date) {
    return dateDaysDiff(date, new Date())
}

function dateDaysDiff(date1: Date, date2: Date) {
    const timeDiff = getDateOnly(date2).getTime() - getDateOnly(date1).getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
}

function getDateOnly(date: Date) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
}

export const insertDateInSiteServerRender = () => {
    if (typeof window !== "undefined") {
        const elements = document.querySelectorAll('[data-wcmdate]')
        for (let el of elements) {
            el.innerHTML = formatDateDisplay(el.getAttribute("data-wcmdate"), el.getAttribute("data-wcm-is-article-date"));

        }
    }
}