interface IngredientListItemProps {
    name: string
    amount: any
    measureUnit: string
    description?: string
    onCheckBoxChange: () => void
    selected: boolean
}

export const IngredientListItem = (props: IngredientListItemProps) => {
    const { name, amount, measureUnit, description, selected, onCheckBoxChange } = props

    return (
        <div className="ingredient-row">
            <input type="checkbox" className="ingredient-checkbox" checked={selected} onChange={onCheckBoxChange} />

            <div className="ingredient-row-details">
                <div className="ingredient-main-details">
                    {!!amount && <div className="ingredient-quantity">{amount} </div>}
                    {!!measureUnit && <div className="ingredient-measure-unit">{measureUnit} </div>}
                    <div className="ingredient-name">{name}</div>
                </div>
                {description && <div className="ingredient-description">{description}</div>}
            </div>
        </div>
    )
}