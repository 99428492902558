import { Pollution } from '../WeatherMapExtendedTab/WeatherMapExtendedTab'

interface MapProps {
    pollutionList: Array<Pollution>
    isNewLayout: boolean
}

const getPollutionLevel = (index: number) => {
    if (index === null) {
        return null
    }
    if (index > 50) {
        return "L"
    } else if (index < 51 && index > -1) {
        return "M"
    } else if (index < 0 && index > -201) {
        return "H"
    } else {
        return "D"
    }
}

const getDotColor = (index: number) => {
    const pollution = getPollutionLevel(index)
    switch (pollution) {
        case "L":
            return { backgroundColor: "#62ac00" }
            break;
        case "M":
            return { backgroundColor: "#ecc500" }
            break;
        case "H":
            return { backgroundColor: "#e21818" }
            break;
        case "D":
            return { backgroundColor: "#8b2c09" }
            break;
        default:
            return { backgroundColor: "#707070" }
            break;
    }
}



const getRegionName = (regionId: number) => {
    switch (regionId) {
        case 1:
            return "צפון"
            break;
        case 2:
            return "חיפה וקריות"
            break;
        case 3:
            return "עמק יזרעאל"
            break;
        case 4:
            return "שרון כרמל"
            break;
        case 5:
            return "שומרון"
            break;
        case 6:
            return "שפלה פנימית"
            break;
        case 7:
            return "גוש דן"
            break;
        case 8:
            return "ירושלים"
            break;
        case 9:
            return "אזור יהודה"
            break;
        case 10:
            return "מישור החוף הדרומי"
            break;
        case 11:
            return "צפון הנגב"
            break;
        case 12:
            return "אילת"
            break;
        default:
            return null
            break;
    }
}

const WeatherAirPollutionMap = (props: MapProps) => {
    const { pollutionList, isNewLayout } = props

    let regions = pollutionList && pollutionList.map((region: Pollution) => {
        if (region && region.regionId) {
            return (
                <div key={region.regionId} className={`city-item region-item-${region.regionId}`} style={{ display: "block" }}>
                    <div className="info-title">
                        <div className="region-title">{getRegionName(region.regionId)}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="dot" style={getDotColor(region.index)} />
                        <div className="index-title">{region.index}</div>
                    </div>
                    {region.index === null ? <div className="missing-data-title">אין נתונים</div> : null}
                </div>
            )
        }
        return null
    })

    return (
        <div className="weather-extended-map-module pollution" role="presentation">
            <div className="map-container">
                {regions}
            </div>

            <div className="indicator-container">
                {isNewLayout ? <>
                    <div className="legend-color-block" />

                    <div className="indicator-info-container">
                        <div className="indicator-box">
                            <div className="indicator-info">
                                <div className="range">{`(400-) - (201-)`}</div>
                                <div className="desc">זיהום אוויר גבוה מאוד</div>
                            </div>
                        </div>

                        <div className="indicator-box">
                            <div className="indicator-info">
                                <div className="range">{`(200-) - (1-)`}</div>
                                <div className="desc">זיהום אוויר גבוה</div>
                            </div>
                        </div>

                        <div className="indicator-box">
                            <div className="indicator-info">
                                <div className="range">{`0 - 50`}</div>
                                <div className="desc">זיהום אוויר בינוני</div>
                            </div>
                        </div>

                        <div className="indicator-box">
                            <div className="indicator-info">
                                <div className="range">{`51 - 100`}</div>
                                <div className="desc">זיהום אוויר נמוך</div>
                            </div>
                        </div>

                    </div>
                </>
                    :
                    <>
                        <div className="indicator-box">
                            <div className="indicator-block border-radius-top" style={getDotColor(51)}></div>
                            <div className="indicator-info">
                                <div className="range">{`51 - 100`}</div>
                                <div className="desc">זיהום אוויר נמוך</div>
                            </div>
                        </div>


                        <div className="indicator-box">
                            <div className="indicator-block" style={getDotColor(0)}></div>
                            <div className="indicator-info">
                                <div className="range">{`0 - 50`}</div>
                                <div className="desc">זיהום אוויר בינוני</div>
                            </div>
                        </div>

                        <div className="indicator-box">
                            <div className="indicator-block" style={getDotColor(-1)}></div>
                            <div className="indicator-info">
                                <div className="range">{`(200-) - (1-)`}</div>
                                <div className="desc">זיהום אוויר גבוה</div>
                            </div>
                        </div>

                        <div className="indicator-box">
                            <div className="indicator-block border-radius-bottom" style={getDotColor(-201)}></div>
                            <div className="indicator-info">
                                <div className="range">{`(400-) - (201-)`}</div>
                                <div className="desc">זיהום אוויר גבוה מאוד</div>
                            </div>
                        </div>

                        <div className="pollution-provided-by">
                            באדיבות המשרד לאיכות הסביבה<br />
                            מרכז ניתור אוויר ארצי<br />
                            www.sviva.gov.il<br />
                            ובאדיבות איגוד ערים חיפה
                </div>

                        <div>
                            <img src={`/images/weather/ministry_of_environmental_protection.png`} className="sviva-img" />
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default WeatherAirPollutionMap