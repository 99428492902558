import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { CalcalistDetachedPlayer } from "../../calcalistPodcastPopupPlayer/calcalistDetachedPlayer"

interface podcastCategoryItemProps {
    item: Partial<ArticleExtraData>
    spotifyLink: string
    applePodcastLink: string
}

const MONTHS = {
    he: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
}

export class PodcastCategoryItem extends React.Component<podcastCategoryItemProps, {}>{
    static defaultProps = { isCategoryVisible: true };

    private getDate = (date: Date) => {
        const day = date.getDate();
        const month = MONTHS.he[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ב${month}, ${year}`;
    };

    public render() {
        const { item, spotifyLink, applePodcastLink } = this.props;
        const { title, subTitle, dateUpdatedOnSite } = item
        const { audioUrl, durationSeconds, imageUrl } = item.specialData.audioData

        return (
            <div className="slot-item" >
                <div className="slot-icons-wrapper">
                    <CalcalistDetachedPlayer
                        audioUrl={audioUrl}
                        title={title}
                        durationSeconds={durationSeconds}
                        podcastImage={imageUrl}
                        classNames={"icon play-icon"}
                        buttonTitle="Play"
                    />
                    <a href={applePodcastLink} target="blank" title="Apple Podcasts" className="icon apple-podcast-icon" />
                    <a href={spotifyLink} target="blank" title="Spotify" className="icon spotify-icon" />
                </div>

                <div className="text-div">
                    <a className="slot-title" href={item.publishedLink}>{title}</a>
                    <div className="slot-subtitle">{subTitle}</div>
                </div>

                <div className="date-view">{this.getDate(dateUpdatedOnSite)}</div>
            </div>
        )
    }
}