import * as classNames from "classnames"
import { ArticleCommentRenderProps } from "./articleCommentWrapper"
import { getTranslation } from "./translations"
import { AddCommentFormWrapper, AddCommentFormRenderProps } from "./addCommentFormWrapper"
import { PremiumAddCommentFormUI } from "./premiumAddCommentFormUI"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
require("./magazine_yplus_ynet_site_premiumArticleCommentUI.less")

interface PremiumArticleCommentUIProps {
    props: ArticleCommentRenderProps
}

export class PremiumArticleCommentUI extends React.Component<PremiumArticleCommentUIProps, {}>{


    render() {
        const { lang, isExpanded, vote, talkback_like, likes, unlikes, toggleOpenedState, commentFormType, articleId,
            isAddCommentFormVisible, handleVoteButtonsClick, handleFormVisibility, handleCloseCommentForm,
            comment: {
                id,
                number,
                title,
                text,
                author,
                pubDate,
                level,
                recommended,
                talkback_source,
            } } = this.props.props

        const articlePublishDate = separateHourFromDateAndFormatToString(pubDate, { showHour: false, dateTimeSeperator: "|" })
        const expandedCommentClasses = classNames({
            "ArticleComment": true,
            "expanded": isExpanded,
            "level1": level === 1,
            "level2": level === 2,
        })

        const likeClasses = classNames({
            "likeSvg": true,
            "likePressed": vote === 1,
        })


        const dislikeClasses = classNames({
            "dislikeSvg": true,
            "dislikePressed": vote === -1,
        })

        const commentTitleClasses = classNames({
            "commentTitle": true,
            "redTitleText": recommended && !isExpanded,
        })
        const commentTextStyles = classNames({
            "commentText": true,
            "level1": level === 1,
            "level2": level === 2,
        })
        const extendedBottomWrapperStyles = classNames({
            "bottomButtonsWrapper": true,
            "level1": level === 1,
            "level2": level === 2,
        })
        const commentNumClasses = classNames({
            "commentNum": true,
            "redTitleText": recommended,
        })
        const positiveLikes = likes < 0 ? 0 : likes
        const positiveUnlikes = unlikes < 0 ? 0 : unlikes
        return (
            <div id="PremiumArticleComment" className={expandedCommentClasses}>
                <div className="topView" onClick={toggleOpenedState}>
                    {level === 1 && <div className={commentNumClasses}>{number}.</div>}
                    {level === 2 && <div className="level2ArrowContainer"><span className="level2Arrow" /></div>}
                    <div className="topLeftView">
                        <div className="commentDetailsWrapper">
                            <div className="details">
                                {author !== "" ? <span className="author">{`${author}`}</span> : null}
                                <span className="publishDate"> {`${articlePublishDate}${talkback_source ? " , " + talkback_source : ""}`}</span>
                            </div>
                        </div>

                        <div className={commentTitleClasses}>
                            <span>{title}</span>
                            {text.length === 0 && <span>{getTranslation(lang, "Empty Content")}</span>}
                        </div>
                    </div>
                </div>

                {isExpanded &&
                    <div className="expandedView ">
                        <div className={commentTextStyles}>{text}</div>
                        <div className={extendedBottomWrapperStyles}>
                            <div className="newCommentButtonsWrapper">
                                <button className="newCommentBtn" title={getTranslation(lang, "New Comment")} disabled={isAddCommentFormVisible} onClick={e => handleFormVisibility("NEW")}>
                                    <span>  {getTranslation(lang, "New Comment")}</span>
                                </button>
                                <button className="commentToComent" title={getTranslation(lang, "Comment To Coment")} disabled={isAddCommentFormVisible}
                                    onClick={e => handleFormVisibility("REPLY")}>
                                    <span> {getTranslation(lang, "Comment To Coment")}</span>
                                </button>
                            </div>

                            <div className="voteButtonsWrapper">
                                <span className="likesCounter">{positiveLikes}</span>
                                <svg
                                    className={likeClasses}
                                    onClick={(e) => handleVoteButtonsClick(true, "3state")}
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.24 18">
                                    <g id="like" transform="translate(-31 -21.684)">
                                        <g id="Group_184" data-name="Group 184" transform="translate(31 21.684)">
                                            <g id="Group_183" data-name="Group 183">
                                                <path id="Path_14" d="M49.749 100.663a1.923 1.923 0 0 0-1.433-3.207h-3.734a12.435 12.435 0 0 0 .528-3.25v-.641A2.568 2.568 0 0 0 42.544 91H41.9a.641.641 0 0 0-.622.486l-.349 1.4c-.49 1.957-2.044 4.117-3.652 4.509a1.927 1.927 0 0 0-1.79-1.219h-3.846a.642.642 0 0 0-.641.641v11.544a.642.642 0 0 0 .641.641h3.848a1.926 1.926 0 0 0 1.737-1.1l2.2.735a7.039 7.039 0 0 0 2.231.362h5.373a1.925 1.925 0 0 0 1.789-2.633 1.923 1.923 0 0 0 .927-3.139 1.92 1.92 0 0 0 0-2.565zm-13.618 6.413a.642.642 0 0 1-.641.641h-3.207V97.456h3.207a.642.642 0 0 1 .641.641zm10.9-5.772h1.283a.641.641 0 0 1 0 1.283h-1.281a.641.641 0 0 0 0 1.283h1.283a.641.641 0 0 1 0 1.283h-1.283a.641.641 0 0 0 0 1.283.641.641 0 0 1 0 1.283h-5.372a5.76 5.76 0 0 1-1.826-.3l-2.422-.807v-7.934a5.168 5.168 0 0 0 2.88-1.871 9.358 9.358 0 0 0 1.883-3.612l.228-.912h.141a1.284 1.284 0 0 1 1.283 1.283v.641a10.148 10.148 0 0 1-.614 3.25H41.9a.641.641 0 0 0 0 1.283h6.413a.641.641 0 0 1 0 1.283h-1.28a.641.641 0 0 0 0 1.283z" className="like" data-name="Path 14" transform="translate(-31 -91)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>


                                <svg
                                    className={dislikeClasses}
                                    onClick={(e) => handleVoteButtonsClick(false, "3state")}
                                    xmlns="http://www.w3.org/2000/svg" id="like" viewBox="0 0 19.24 18">
                                    <g id="Group_184" data-name="Group 184">
                                        <g id="Group_183" data-name="Group 183">
                                            <path id="Path_14" d="M31.491 99.337a1.923 1.923 0 0 0 1.433 3.207h3.734a12.436 12.436 0 0 0-.528 3.25v.641A2.568 2.568 0 0 0 38.7 109h.641a.641.641 0 0 0 .622-.486l.349-1.4c.49-1.957 2.044-4.117 3.652-4.509a1.927 1.927 0 0 0 1.79 1.219H49.6a.642.642 0 0 0 .641-.641V91.641A.642.642 0 0 0 49.6 91h-3.849a1.926 1.926 0 0 0-1.737 1.1l-2.2-.735A7.039 7.039 0 0 0 39.579 91h-5.372a1.925 1.925 0 0 0-1.789 2.633 1.923 1.923 0 0 0-.927 3.139 1.92 1.92 0 0 0 0 2.565zm13.618-6.413a.642.642 0 0 1 .641-.641h3.207v10.261h-3.206a.642.642 0 0 1-.641-.641zM34.207 98.7h-1.283a.641.641 0 1 1 0-1.283h1.283a.641.641 0 0 0 0-1.283h-1.283a.641.641 0 0 1 0-1.283h1.283a.641.641 0 0 0 0-1.283.641.641 0 1 1 0-1.283h5.373a5.76 5.76 0 0 1 1.826.3l2.422.807v7.936a5.168 5.168 0 0 0-2.88 1.871 9.358 9.358 0 0 0-1.883 3.612l-.228.912H38.7a1.284 1.284 0 0 1-1.283-1.283v-.641a10.149 10.149 0 0 1 .614-3.25h1.31a.641.641 0 0 0 0-1.283h-6.417a.641.641 0 0 1 0-1.283h1.283a.641.641 0 0 0 0-1.283z" className="cls-1" data-name="Path 14" transform="translate(-31 -91)" />
                                        </g>
                                    </g>
                                </svg>
                                <span className="likesCounter unlikesCounter">{positiveUnlikes}</span>
                            </div>

                        </div>
                        <AddCommentFormWrapper
                            render={(props: AddCommentFormRenderProps) => <PremiumAddCommentFormUI props={props} />}
                            lang={lang}
                            articleId={articleId}
                            formType={commentFormType}
                            handleCloseCommentForm={handleCloseCommentForm}
                            parentTalkbackId={id}
                            isAddCommentFormVisible={isAddCommentFormVisible}
                        />
                    </div>
                }

            </div>)
    }
}