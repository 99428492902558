import * as classNames from "classnames"
import Slider from "react-slick"
import { SiteMediaData } from "../interfaces"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { SiteMedia } from "./siteMedia";
import { GalleryViewType } from "../commonComponents/base/basicMediaCarousel"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { LabelText } from "../defenitions/ynet1280/topStory/components/labelText";
import { SiteImageData } from "widgets/interfaces"

interface SiteMediaCarouselProps {
    medias: SiteMediaData[]
    height: number
    width: number
    arrowClassLeft?: string
    arrowClassRight?: string
    galleryViewOption?: GalleryViewType
    intervalTime?: number
    videoSettings?: VideoSettingsData
    title?: string
    itemLinkUrl?: string
    titlesContainerElementId?: string
    linkTarget: LinkTargetProperties
    shouldAddArticleAutoplayIndicator?: boolean
    isImageLinkable?: boolean
    articleId?: string
    galleryRatio?: string
    isDataTbLink?: boolean
    hasTextLabel?:boolean
}


function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button
            className={classNames(className)} onClick={onClick}
            title="left"
            aria-label="left"
        />
    );
}

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <button
            title="right"
            aria-label="right"
            className={classNames(className)}
            onClick={onClick} />
    );
}



export class SiteMediaMobileCarousel extends React.Component<SiteMediaCarouselProps>{
    public static siteScriptName = "SiteMediaMobileCarousel";


    private getRatio = () => {
        const { galleryRatio } = this.props;

        switch (galleryRatio) {
            case "16:9":
                return "56.25%"
            case "4:3":
                return "75%"
            case "2:3":
                return "150%"
            case "1:1":
                return "100%"
            default:
                break;
        }
    }

    private hideTitlesWhenVideoPlayStarts = () => {
        if (this.props.titlesContainerElementId) {
            const titlesContainerElement = document.getElementById(this.props.titlesContainerElementId) as HTMLElement
            if (titlesContainerElement) {
                titlesContainerElement.style.visibility = "hidden";
            }
        }
    }

    public render() {
        const { medias, arrowClassRight, arrowClassLeft, height, width,hasTextLabel, 
            galleryViewOption, videoSettings, itemLinkUrl, linkTarget, shouldAddArticleAutoplayIndicator, isImageLinkable, articleId, isDataTbLink, intervalTime } = this.props
        const leftArrow = classNames("left galleryArrow", arrowClassLeft)
        const rightArrow = classNames("right galleryArrow", arrowClassRight)
        //  const isImageClickable = medias.filter(obj => obj.mediaType === "MEDIA_VIDEO").length === 0 && isImageLinkable;
        const oneItem = medias.length > 1 ? "moreThenOneItemCarousel" : ""
        const isArrow = galleryViewOption === "arrows" || galleryViewOption === "arrowsAndPaging"
        const isPaging = galleryViewOption === "paging" || galleryViewOption === "arrowsAndPaging"
        let settings = {

            infinite: true,
            autoplay: intervalTime > 0,
            dots: isPaging,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
            arrows: isArrow,
            autoplaySpeed: intervalTime * 1000,
            rtl: false,
            prevArrow: <PrevArrow props={{ className: leftArrow }} />,
            nextArrow: <NextArrow props={{ className: rightArrow }} />

        }
        return (
            <Slider {...settings}   >
                {medias.map((media, i) =>
                    <div className={`MediaCarousel ${oneItem}`} style={{ height, width }} key={i} >
                        <div className="mediaItems" >
                      {hasTextLabel &&  <LabelText className={"mediaItemLabel"} 
                    labelText={(media as SiteImageData).labelText} mediaIconPlace={(media as SiteImageData).textPlace} onLabelTextChange={()=>null} />}
                            <SiteMedia
                                itemLinkUrl={itemLinkUrl}
                                linkTarget={linkTarget}
                                shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator}
                                onPlayStarted={this.hideTitlesWhenVideoPlayStarts}
                                isImageLinkable={isImageLinkable}
                                data={media}
                                height={height}
                                width={width}
                                key={media.url}
                                isDataTbLink={isDataTbLink}
                                videoSettings={videoSettings}
                                allMedias={medias}
                                articleId={articleId} />

                        </div>


                    </div>)}
            </Slider>
        )
    }
}

