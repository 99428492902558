import { analyticsPushFunc, openFacebookShare, openTwitterShare } from "../../../../widgetsUtils";

interface CalcalistCartoonActionsProps {
    articleTitle: string
    showComments: boolean
    publishedLink: string
}

export class CalcalistCartoonActions extends React.Component<CalcalistCartoonActionsProps, {}> {

    private printArticle = () => {
        if (typeof window != "undefined") {
            window.print();
        }
    }

    private handleShareBtnClicked = (button: string, lang: string) => {
        const {publishedLink, articleTitle} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;
            switch (button) {
                case "fb":
                    openFacebookShare(publishedLink, "", () => analyticsPushFunc("Facebook", "Article Side", articleTitle), "Article Side")
                    break;
                case "tw":
                    openTwitterShare(publishedLink, () => analyticsPushFunc("Twitter", "Article Side", articleTitle), "Article Side")
                    break;
            }
        }
    }

    private isTalkbackElement = () => {
        if (typeof window !== 'undefined') {
            return !!document.getElementById("SiteArticleComments");
        }
    }

    public render() {
        const { showComments, publishedLink } = this.props
        const lang = "heCalcalist";
        const _showComments = showComments || this.isTalkbackElement();

        return (
            <div className="CalcalistCartoonActions">
                <ul className="actionList" >
                    <li title="Print" className="share-icon print" onClick={this.printArticle} />
                    <li title="Twitter" className="share-icon twitter" onClick={(e) => this.handleShareBtnClicked("tw", lang)} />
                    <li title="Facebook" className="share-icon facebook" onClick={(e) => this.handleShareBtnClicked("fb", lang)} />
                </ul>
            </div>
        )
    }
}