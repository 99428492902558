import { isWcmEditor } from "../../../../../../../config/serverConfig"
import { isQaHost } from "../../../../../../widgetsUtils"
import { isMobile } from "../../../../../../../utils/isMobile"
import { logoutPremium, analyticsLogoutPushFunc } from "../../utils"
const clickOutside = require('react-click-outside');
require("./yplus_site_ynetPremiumLogin.less")
require("./yplus_site_ynetPremiumLogin-mobile.less")

export interface YnetPremiumLoginBaseRendererProps {
    user: { props: { firstName: string } }
    isMenuVisible: boolean
    toggleMenu: () => void,
    handleOnArticlesClicked: () => void,
    handleOnManageAccountClicked: () => void,
    handleOnLogoutMenuClicked: () => void,
    handleOnContactUsClicked: () => void
    closeLogoutPopup: () => void,
    logOutFromPopUp: () => void,
    isLogoutPopupOpen: boolean,
}

interface YnetPremiumLoginBaseProps {
    renderer: (props: YnetPremiumLoginBaseRendererProps) => React.ReactElement<any>
    isMobile?: boolean

}

interface YnetPremiumLoginBaseState {
    user: { props: { firstName: string } }
    isMenuVisible: boolean,
    isLogoutPopupOpen: boolean,
}

@clickOutside
export class YnetPremiumLoginBase extends React.Component<YnetPremiumLoginBaseProps, YnetPremiumLoginBaseState>{
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            isMenuVisible: false,
            isLogoutPopupOpen: false,
        }
    }

    private handleClickOutside = () => {
        !isMobile && this.setState({ isMenuVisible: false })
    }

    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({
                user: window.YitPaywall.user
            })
        }
        !this.props.isMobile && document.addEventListener("scroll", this.onScrollCallback)
    }

    componentWillUnmount() {
        !this.props.isMobile && document.removeEventListener("scroll", this.onScrollCallback)
    }

    private onScrollCallback = (e) => {
        this.setState({ isMenuVisible: false })
    }

    private analyticsMenuPushFunc = () => {
        if (typeof window !== "undefined") {
            const menuStatus = this.state.isMenuVisible ? 'Close' : 'Open';
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'Navigation_Events',
                Category: 'Ynet+ - Navigation',
                Action: `Dropdown Side Menu - ${menuStatus}`
            });
        }
    }

    private toggleMenu = () => {
        this.analyticsMenuPushFunc()
        this.setState(pstate => {
            return {
                isMenuVisible: !pstate.isMenuVisible,
            }
        })

    }


    private handleOnManageAccountClicked = () => {
        this.toggleMenu()
        if (!isWcmEditor()) {
            const hostname = window.location.hostname
            const qaLink = "https://qa-primium.ynet.co.il/Web/Manage"
            const prodLink = "https://premium.ynet.co.il/Web/Manage"
            const urlToOpen = isQaHost(hostname) ? qaLink : prodLink
            window.open(urlToOpen)
        }
    }

    private handleOnArticlesClicked = () => {
        this.toggleMenu()
        if (!isWcmEditor()) {
            const hostname = window.location.hostname
            const qaLink = "https://qa.ynet.co.il/plus"
            const prodLink = "https://www.ynet.co.il/plus"
            const urlToOpen = isQaHost(hostname) ? qaLink : prodLink
            window.open(urlToOpen)
        }
    }

    private handleOnContactUsClicked = () => {
        this.toggleMenu()
        if (!isWcmEditor()) {
            const hostname = window.location.hostname
            const qaLink = "//qa.ynet.co.il/plus/contact"
            const prodLink = "//www.ynet.co.il/plus/contact"
            const urlToOpen = isQaHost(hostname) ? qaLink : prodLink
            window.open(urlToOpen)
        }
    }
    private handleOnLogoutClicked = () => {
        analyticsLogoutPushFunc()
        logoutPremium()
        this.setState({ user: null })
    }




    private handleOnLogoutMenuClicked = () => {
        this.toggleMenu()
        this.setState(pstate => {
            return {
                ...pstate,
                isLogoutPopupOpen: true
            }
        })
    }

    private closeLogoutPopup = () => {
        this.setState(pstate => {
            return {
                ...pstate,
                isLogoutPopupOpen: false
            }
        })
    }




    private logOutFromPopUp = () => {
        this.closeLogoutPopup();
        this.handleOnLogoutClicked();
    }






    render() {
        const { user, isMenuVisible, isLogoutPopupOpen } = this.state
        var el = this.props.renderer({
            user, isMenuVisible, isLogoutPopupOpen,
            toggleMenu: this.toggleMenu,
            handleOnArticlesClicked: this.handleOnArticlesClicked,
            handleOnLogoutMenuClicked: this.handleOnLogoutMenuClicked,
            handleOnManageAccountClicked: this.handleOnManageAccountClicked,
            closeLogoutPopup: this.closeLogoutPopup,
            logOutFromPopUp: this.logOutFromPopUp,
            handleOnContactUsClicked: this.handleOnContactUsClicked,
        })
        return el;
    }
}