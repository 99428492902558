

import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { MultiImagesScroller, getItemMargin } from "widgets/commonComponents/base/multiImagesFrontScroller";
import { SiteItemData } from "widgets/interfaces"
import { YplusItemFront } from "./yplusItemFront";





interface MultiImagesFrontProps {
    itemList: SiteItemData[]
    width: number
    isLabelVisible: boolean
    videoSettings: VideoSettingsData
    imageWidth: number
    imageHeight: number
    isAuthorVisible: boolean
    displayType: any
}



export class YplusMultiImagesFront extends React.Component<MultiImagesFrontProps>{
    public static siteScriptName = "YplusMultiImagesFront";



    public render() {
        const { imageWidth, itemList, imageHeight, videoSettings, width, isLabelVisible, isAuthorVisible, displayType } = this.props

        return (
            <MultiImagesScroller imageWidth={imageWidth} width={width} itemLength={itemList.length} >
                {itemList.map((slot, index) => {
                  //  const forDesktopOnly = index > 3;
                    return (
                        <YplusItemFront
                            imageWidth={imageWidth}
                            key={index}
                            imageHeight={imageHeight}
                            videoSettings={videoSettings}
                            isLabelVisible={isLabelVisible}
                            item={slot}
                            marginLeft={getItemMargin(width, imageWidth)}
                            isSmallItem={true}
                            isAuthorVisible={isAuthorVisible}
                            displayType={displayType}
                        />
                    )
                }
                )}
            </MultiImagesScroller >)


    }
}