const clickOutside = require('react-click-outside');


interface shareProps {
    isMenuOpened: boolean
    openContactPopup: () => void
    onPrint: (e: any) => void
    findErrorLink: string
    lang?: string
}



@clickOutside
export class ShareButtonsDropdown extends React.Component<shareProps>{

    private authorScroll = () => {
        const elementToScroll = document.querySelector('#contactAuthor') as HTMLElement
        setTimeout(() => {
            elementToScroll.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }, 0)
        const elementToOpen = document.querySelector('#contactWrapper') as HTMLElement
        elementToOpen.click()
    }

    private checkIfAuthorExist = () => {
        if (typeof window !== 'undefined') {
            const authorExist = document.querySelector('#contactAuthor')
            if (authorExist) return true
            else return false
        }
    }



    render() {

        const { isMenuOpened, onPrint, openContactPopup, findErrorLink, lang } = this.props

        let sendToAuthor = "שליחת מייל לכותב/ת"
        let findError = "מצאתם טעות? דווחו לנו"
        const isMenuClassName = isMenuOpened ? "open" : "close"
        let textPrint = "הדפסה"
        if(lang ==="en"){
            sendToAuthor = "Send to Author";
        findError = "Find an error? Report us" ;
        textPrint = "Print";
        }
        if(lang ==="ru"){
            sendToAuthor = "Связь с редакцией";
            findError = "Нашли техпроблему? Сообщите нам" ;
            textPrint = "На печать";  
        }
        return (

            <div className={`menuMore ${isMenuClassName}`}>
                <a title={textPrint} onClick={onPrint}>{textPrint}</a>
                {/*this.checkIfAuthorExist() && <a title={sendToAuthor} onClick={this.authorScroll}>{sendToAuthor}</a>*/}
                <a title={findError} href={findErrorLink} target="_blank">{findError}</a> 
            </div>
        )
    }
}
