import { isWcmEditor } from "../../../../../config/serverConfig";
interface socialProp {
    openWindow: (url: string, name: string, width: number, height: number) => void
    lang: string
}

export class YnetNewsSocial extends React.Component<socialProp, {}> {
    private getSiteName = (lang: string, site: string) => {
        if (site === "fb") {
            switch (lang) {
                case "en": return "ynetenglish"
                case "es": return "ynetespanol"
                default: return "ynetenglish"
            }
        }
        else {
            switch (lang) {
                case "en": return "ynetnews"
                case "es": return "ynetespanol"
                default: return "ynetnews"
            }
        }
    }



    public render() {
        const { openWindow, lang } = this.props
        if (!isWcmEditor())
            return (
                <div className="main_search_social main_search_elements">
                    {lang === "es" && <a onClick={() => openWindow(`//instagram.com/ynetespanol`, "instagram window", 800, 600)} style={{ marginLeft: "30px" }}>
                        <img src="/images/instagram.png" alt="YnetEspanol on Instagram" title="YnetEspanol on Instagram" />
                    </a>}
                    <a onClick={() => openWindow(`//www.facebook.com/${lang !== "es" ? "pages/" : ""}${this.getSiteName(lang, "fb")}`, "facebook window", 800, 600)} style={{ marginLeft: "30px" }}>
                        <img src="/images/face.png" alt={`${this.getSiteName(lang, "")} on Facebook`} title={`${this.getSiteName(lang, "")} on Facebook`} />
                    </a>
                    <a onClick={() => openWindow(`//twitter.com/${this.getSiteName(lang, "tw")}`, "twitter window", 800, 600)}>
                        <img src="/images/twitter.png" alt={`${this.getSiteName(lang, "")} on Twitter`} title={`${this.getSiteName(lang, "")} on Twitter`} width={35} height={35} />
                    </a>
                </div>
            )
        else return null
    }
}
