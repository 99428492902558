
import * as classNames from "classnames"
import * as map from "lodash/map"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { PremiumHeader } from "../../article/componentas/premium/componentas/premiumHeader/premiumHeader";
import { YplusSearchInput } from "../../yPlusSubChannelHeader/components/searchInput";
import {yPlusHeaderMenuProps, yPlusHeaderMenuState,yPlusHeaderMenuItem } from "./menu"
const clickOutside = require('react-click-outside'); 


@clickOutside
export class YplusNotStickyMenu extends React.Component<yPlusHeaderMenuProps, yPlusHeaderMenuState>{
    constructor(props) {
        super(props as yPlusHeaderMenuProps);
        this.state = {
            isMenuOpened: false,

        }
    }
    public static siteScriptName = "YplusNotStickyMenu";
    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }
    private handleClickOutside = () => {
        this.setState({ isMenuOpened: false })
    }
   
    private onScrollCallback = (e) => {
        this.setState({ isMenuOpened: false })
    }
    componentDidMount() {
              document.addEventListener("scroll", this.onScrollCallback)
    }


    componentWillUnmount() {
             document.removeEventListener("scroll", this.onScrollCallback)
    }
    render() {
        const { items, categoryId, id, isMenuVisible, isArticle,
            seachPageLink, isInputOpened, onFocusOutSearch, showInput, isUserLogged, mainLogoLink, parentSiteLink, siteId, inputHasValue, ynetPlusMenuItems } = this.props
            let firstList: yPlusHeaderMenuItem[];
            let secondList: yPlusHeaderMenuItem[];
            const isMenuHidden = !this.state.isMenuOpened ? "hidden" : "";
            if (items.length > 6) {
                firstList = items.slice(0, 6)
                secondList = items.slice(6, items.length)
            }
            else firstList = items
        if (isMenuVisible) return (
            <div className="yPlusHeader">
                <PremiumHeader className="yPlusPageHeader" isArticlePage={isArticle} mainLogoLink={mainLogoLink}
                    parentSiteLink={parentSiteLink} siteId={siteId} ynetPlusMenuItems={ynetPlusMenuItems} />
                <div className={classNames("YplusNavigationList notStickyOnTopStory", {"growingHeight":inputHasValue})}>
                <ul>
                            {map(firstList, (item, i) => {
                                return (
                                    <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                        <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                        </HyperLinkBuilder>
                                    </li>

                                )
                            }
                            )}
                            {items.length > 6 && <li className="menuItem secondLevelH"><span onClick={this.toggleMenu}>עוד
                        </span>
                                <span className="menuTogglerWrapper" onClick={this.toggleMenu}>
                                    <span className="menuToggler"></span>
                                </span>
                                <ul className={`secondLevelMenu ${isMenuHidden}`}>
                                    {map(secondList, (item, i) => {
                                        return (
                                            <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                </HyperLinkBuilder>
                                            </li>

                                        )
                                    }
                                    )}
                                </ul>
                            </li>
                            }
                        </ul>
                    {seachPageLink !== undefined && (isUserLogged || (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL)) && <YplusSearchInput
                        className="mobileInput"
                        seachPageLink={seachPageLink}
                        isInputOpened={isInputOpened}
                        onFocusOutSearch={onFocusOutSearch}
                        showInput={showInput}


                    />}
                    {!(isUserLogged || (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL)) && this.props.children }
                </div>
            </div>
        )
        else return <div className="yPlusHeader">
            <PremiumHeader className="yPlusPageHeader"
                parentSiteLink={parentSiteLink}
                mainLogoLink={mainLogoLink}
                isArticlePage={isArticle}
                siteId={siteId}
                ynetPlusMenuItems={ynetPlusMenuItems}
            /><div className="YplusNavigationList transparent" /></div>
    }
}