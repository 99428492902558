import { SimpleLinkRenderer } from "../../../commonComponents/inline/hyperLink";
import { MessageToAuthor } from "./messageToAuthorBox";
import { getTalkbackNumberView, toggleSpotImPopUp } from "../../article/componentas/articleSocialShare1280Component/articleSocialShareNew1280";
import { removeHtmlTagsFromString } from "../../../jsonSerializer/common/jsonHelperUtils";
import { getTranslation } from "../../article/componentas/articleComments/translations";


interface SiteArticleContactProps {
    articleId: string
    categorySubChannel: number
    isCommertialArticle: boolean
    showComments: boolean
    version: number
    isSpotim?: boolean
    linkText: string
    linkUrl: string
    siteLayoutType: string
    lang?:string
}

interface SiteArticleContactState {
    isMessageWindowOpened: boolean
    wcmNumOfComments:string
}

declare let window: Window & {
    languageCode: string
}

export class AppArticleContact extends React.Component<SiteArticleContactProps, SiteArticleContactState>{
    static siteScriptName = "AppArticleContact"
    public ON_WCM_COMMENTS_RECIVED = "onWcmCommetnsRecived";
    constructor(props) {
        super(props)
        this.state = {
            isMessageWindowOpened: false,
            wcmNumOfComments:''
        }
    }

    componentDidMount() {
        document.addEventListener(this.ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
      }
      componentWillUnmount(): void {
        document.removeEventListener(this.ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
      }
      private handleOnWcmCommentsRecived = (e) => {
        this.setState({ wcmNumOfComments: e.detail.wcmNumOfComments });
      };


    private openContactPopup = () => {
        this.setState({
            isMessageWindowOpened: true
        })
    }
    private closeContactPopup = () => {
        this.setState({
            isMessageWindowOpened: false
        })
    }

    private handleCommentsPopupVisability = () => {
        if (typeof window !== "undefined") {
            window.handleCommentsPopupVisability && window.handleCommentsPopupVisability();
        }
    }

    private onCommentBtnClick = () => {
        const { isSpotim, siteLayoutType } = this.props
        const newCommentBtn = document.querySelector('[class*="newCommentBtn"]') as HTMLElement;
      //  document.querySelector(".newCommentBtn-pf")
        if (newCommentBtn) {
            newCommentBtn.click();
        }
        const elementToScroll = document.querySelector('#SiteArticleComments')

        if (elementToScroll) {
        setTimeout(() => {
            elementToScroll.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }, 0)
        }
        if (isSpotim ) {
            const commentsPopUp = document.querySelector('.spotimWrapper');  
            if (commentsPopUp && siteLayoutType!=="mobileApp") {
                    toggleSpotImPopUp(commentsPopUp)
                }
        }
    }
    private onGotoSpotimComments = () => {
        const {  siteLayoutType } = this.props
            const commentsPopUp = document.querySelector('.spotimWrapper');
                if (commentsPopUp) {
                    toggleSpotImPopUp(commentsPopUp)         
            }
    }
    private getLinkText = () => {
        const { isSpotim, siteLayoutType } = this.props
        const {wcmNumOfComments} = this.state;
        const commentNumber = getTalkbackNumberView(wcmNumOfComments ? wcmNumOfComments.toString() : "")
        let txtFirst = "היו הראשונים להגיב";
        let toThisArticle = "לכתבה זו ";
        let comments = " תגובות";
        const language = (typeof window !== "undefined" &&  window.languageCode)  || "";
        let lang;
        if (language.includes("ru")){
            lang = "ru";
        }
        else if(language.includes("en")){
            lang = "en";  
        }
        if(lang==="en"){
            txtFirst = "Be the first to comment" ;
            toThisArticle = "";
            comments=" Comments";

        }
      else  if(lang==="ru"){
            txtFirst = "Ваш комментарий – первый" ;
            toThisArticle = "";
            comments="Комментарии: ";

        }
        const firstCommentTxt = <div className="commentInfoText-pf">{txtFirst}</div>
        const commentExistTxt = <div className="commentInfoText-pf"> <a className="toAllComments-pf" href="go_to_all_comments">{lang==="ru" ? `${comments} ${commentNumber}` : `${commentNumber} ${comments}`} </a></div>;
        const cmntExistTxtInMobileWeb = <div className="commentInfoText-pf">  <a className="toAllComments-pf" onClick={this.handleCommentsPopupVisability}>{lang==="ru" ? `${comments} ${commentNumber}` : `${commentNumber} ${comments}`} </a></div>;
        const spotimElement = siteLayoutType==="mobileApp" ? <a id="bottomSpotimCounter" href="spotComments" /> : <a id="bottomSpotimCounterMobileWeb" onClick={this.onGotoSpotimComments} />
        if (isSpotim) {
            return spotimElement
        }
        else {
            if (commentNumber.length) {
                if (siteLayoutType == "mobileApp") {
                return commentExistTxt
                }
                else return cmntExistTxtInMobileWeb;
            }
            return firstCommentTxt
        }
    }

    private getUrlLink = () => {
        const { articleId, linkUrl } = this.props
        return linkUrl && linkUrl.replace('{article_id}', articleId)
    }

    render() {
        const { categorySubChannel, articleId, isCommertialArticle, showComments, version, linkText, linkUrl, siteLayoutType, isSpotim } = this.props
        const { isMessageWindowOpened } = this.state
        const language = (typeof window !== "undefined" &&  window.languageCode)  || "";
        let lang;
        if (language.includes("ru")){
            lang = "ru";
        }
        else if(language.includes("ru")){
            lang = "en";  
        }
        const visibleInApp = siteLayoutType === "mobileApp" && version > 2; 
        let addCommentTxt = "הוספת תגובה";
        let contactUsTxt = " מצאתם טעות בכתבה? כתבו לנו על זה";
        if(lang==="en"){
            addCommentTxt = "Add a comment";
            contactUsTxt  = "Contact us";
        }

        else if(lang==="ru"){
            addCommentTxt = "Обсудить";
            contactUsTxt  = "Связь с редакцией";
        }

        const urlLink = this.getUrlLink()
        return (
            <div className="SiteArticleContact">
                <div className="foundMistake-pf">
                    <SimpleLinkRenderer
                        href={urlLink ? urlLink : WCM_FEATURES_FLAGS.ENABLE_NEW_RED_MAIL ? "//ynet.co.il/redmail?notifyMistake=" + articleId : "//redmail.ynet.co.il/?notifyMistake=1"}
                        target="_blank"
                        className="mistakeText">
                        {linkText ? removeHtmlTagsFromString(linkText) : contactUsTxt}

                    </SimpleLinkRenderer>
                </div>
                {(subChannelIdsToAccept as any).includes(categorySubChannel) && !isCommertialArticle && (visibleInApp || siteLayoutType === "mobileWeb") ?
                    <div className="contactAuthor" id="contactAuthor">
                        <span className="contactWrapper" id="contactWrapper" onClick={() => this.openContactPopup()}>{`פנייה לכתב/ת`}</span>
                        {isMessageWindowOpened && <MessageToAuthor onClose={this.closeContactPopup} articleId={articleId} isApp={true} />}
                    </div>
                    : <div />}
                {showComments && (visibleInApp || siteLayoutType === "mobileWeb") && <div className="commentInfoBox-pf">
                    {this.getLinkText()}
                    <div>
                   { siteLayoutType === "mobileApp" && isSpotim  ?  <a href="spotComments" className="addNewComment-pf" title={addCommentTxt}>
                            <span>{addCommentTxt}</span>
                        </a>  : <button onClick={this.onCommentBtnClick} className="addNewComment-pf" title={addCommentTxt}>
                            <span>{addCommentTxt}</span>
                        </button>}
                    </div>
                </div>
                }
            </div>
        )
    }
}

const subChannelIdsToAccept = [8, 2, 6, 437, 3, 538, 1208, 544, 2142, 6377, 550, 598, 975, 194, 1069, 813, 5, 3262, 4872, 3052, 3925, 4403,
    1361, 4002, 3456, 3630, 5419, 5420, 5423, 5421, 5425, 5422, 5427, 5426, 5428, 5424, 5429, 2, 6, 3, 538, 544, 1208, 5363, 11679,
    8315, 598, 550, 975, 3925, 3052, 4403, 4880, 2142, 3262, 194, 8841, 11691, 13317]