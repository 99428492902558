export type SiteLanguage = "he" | "en" | "es"

const dict = {
    "ru": {
        "Sandstorm": "Песчаная буря",
        "Thunderstorms": "Грозы",
        "Snow": "Снег",
        "Light snow": "Легкий снег",
        "Sleet": "Мокрый снег",
        "Rain": "Дождь",
        "Drizzle": "Изморось",
        "Partly cloudy": "Переменная облачность",
        "Cloudy": "Облачно",
        "Clear": "Безоблачное небо",
        "Windy": "Ветрено",
        "Frost": "Иней",
        "Freezing": "Заморозки",
        "Hot": "Жарко",
        "Cold": "Холодно",
        "Stormy": "Шторм",
        "Heavy Snow": "Снегопад",
        "Partly cloudy, possible rain": "Переменная облачность, возможен дождь",
        "Cloudy, possible rain": "Облачно, возможен дождь",
        "Cloudy, light rain": "Облачно, небольшой дождь",
        "Haze": "Мгла",
        "Extremely hot": "Очень жарко",
        "Extremely cold": "Очень холодно",
    },
    "he": {
        "Sandstorm": "סופות חול",
        "Thunderstorms": "סופות רעמים וברקים",
        "Snow": "שלג",
        "Light snow": "שלג קל",
        "Sleet": "גשם מעורב בשלג",
        "Rain": "גשם",
        "Drizzle": "ערפל",
        "Partly cloudy": "מעונן חלקית",
        "Cloudy": "מעונן",
        "Clear": "בהיר",
        "Windy": "רוחות ערות",
        "Frost": "הביל",
        "Freezing": "קרה",
        "Hot": "חם",
        "Cold": "קר",
        "Stormy": "סוער",
        "Heavy Snow": "שלג כבד",
        "Partly cloudy, possible rain": "מעונן חלקית, אפשרות לגשם",
        "Cloudy, possible rain": "מעונן, אפשרות לגשם",
        "Cloudy, light rain": "מעונן, גשם קל",
        "Haze": "אביך",
        "Extremely hot": "חם מאוד",
        "Extremely cold": "קר מאוד",
    }
}

const translate = (language) => {
    const lang = language
    return (phrase) => {
        if (language in dict) {
            return dict[lang][phrase] || phrase
        } else {
            return phrase
        }
    }
}


export default (language) => {
    const t_ui = translate(language)
    return (weatherCode) => {
        const defaultCode = 10
        const weatherCodeDesc = {
            10: "דומם",
            20: "שקט",
            30: "נוח",
            40: "נוח עד גלי",
            50: "גלי",
            55: "גלי עד גבה גלים",
            60: "גבה גלים",
            70: "גבה גלים עד רוגש",
            80: "רוגש",
            90: "רוגש עד סוער",
            110: "סוער עד גועש",
            120: "סוער עד גועש",
            130: "גועש",
            140: "גועש עד זועף",
            150: "זועף",
            160: "זועף מאד",
            161: "נח בתחילה יעשה גלי",
            162: "נוח. במשך היום יעשה גלי",
            163: "נח. יעשה גלי עד גבה גלים מחר",
            164: "נח. יעשה גלי עד גבה גלים מחר",
            165: "נח עד גלי יעשה גבה גלים",
            166: "נח בגדה המערבית, גלי בגדה המזרחית",
            167: "נח עד גלי. יעשה גלי עד גבה גלים",
            168: "גלי. יעשה גבה גלים",
            169: "נח עד גלי. יעשה גבה גלים עד רוגש",
            170: "גלי בגדה המערבית, גבה גלים בגדה המזרחית",
            171: "גלי עד גבה גלים. יעשה גבה גלים עד רוגש",
        }
        let desc = ""

        if (weatherCode in weatherCodeDesc) {
            desc = weatherCodeDesc[weatherCode]
        } else {
            desc = weatherCodeDesc[defaultCode]
        }

        return t_ui(desc)
    }
}
//import translate from "./tanslations/uiTranslations"
//const lang = "he"
//const t_ui = translate(lang)
//<div> t_ui("last update") </div>

