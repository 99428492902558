require("./ynet_pplus_magazine_site_articleAudioPodcast.less")
import { PodcastPlayer } from "../../floatingPlayer/podcastPlayerButton"
import { secondsToHms } from "../../../../siteWidgets/siteWidgetUtils"
import { SignUpForPodcast } from "./signupForPodcast";
import { isWcmEditor } from "../../../../../config/serverConfig"
import { ArticleData } from "../../../../../articlePage/stateInterfaces";

interface AudioPodcastPlayerProps {
    title: string
    category: string
    categoryId?: string
    audioUrl: string
    durationSeconds: number
    imageUrl: string
    isMobileWeb?: boolean
    articleLink?: string
    articleData?: ArticleData
}

export const AudioPodcastPlayer = (props: AudioPodcastPlayerProps) => {
    const { title, category, categoryId, audioUrl, durationSeconds, imageUrl, isMobileWeb, articleLink } = props
    const podcastDuration = secondsToHms(durationSeconds);
    const browserDisplayMediaMetaData = { title: title, artist: "ynet", album: category }

    return (
        <div className="audio-podcast-player">
            <div className="audio-podcast-player-container">
                <div className="podcast-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                <div className="podcast-container-details-image-container">
                        <div className="container-background-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                    </div>
                <div className="podcast-container-details">
               
                    <div className="podcast-information">
                        <div className="podcast-category-rpf">{category}</div>
                        <div className="podcast-title-rpf">{title}</div>
                        <div className="duration-container">
                            <div className="duration-icon"></div>
                            <div className="podcast-duration-rpf">{podcastDuration}</div>
                        </div>
                    </div>

                    <div className="podcast-play-btn-container">
                        <PodcastPlayer
                            audioUrl={audioUrl}
                            title={title}
                            isMobileWeb={isMobileWeb}
                            className="audio-play-button"
                            durationSeconds={durationSeconds}
                            browserDisplayMediaMetaData={browserDisplayMediaMetaData}
                            articleLink={articleLink}
                            articleData={props.articleData}
                        />
                    </div>
                </div>
            </div>

            {!isWcmEditor() && <SignUpForPodcast categoryId={categoryId} />}
        </div>
    )
}
AudioPodcastPlayer.siteScriptName = "AudioPodcastPlayer"