import { City } from '../WeatherMapExtendedTab/WeatherMapExtendedTab'
import * as  ReactTooltip from "react-tooltip"
import translate from '../../../weather/translations/UiTranslation'
import seaTranslate from '../../../weather/translations/SeaCodeTranslations'
import isSubZero from '../../../weather/components/helpers/isSubZero'

interface MapProps {
    citiesList: Array<City>
    lang: string
    isNewLayout: boolean
}


const WeatherWaveHeightMap = (props: MapProps) => {
    const { citiesList, lang, isNewLayout } = props
    const t_ui = translate(lang)
    const t_sea = seaTranslate(lang)
    const newIcon = `/images/weather-new-icons/weatherMapIcons/wave_icon.svg`
    const oldIcon = `/images/weather/waveHeight.svg`

    const chosenCities = ["Elat", "Ashdod", "Beer Sheva", "Bet Shean", "Haifa", "Tiberias", "Jerusalem", "Mizpe Ramon", "Zefat", "Tel Aviv Yafo"]

    const getDirectionalWindTranslate = (windDirection: Array<String>) => {
        if (windDirection.length > 1) {
            return `${t_ui(windDirection[0])} ${t_ui("עד")} ${t_ui(windDirection[1])}`
        }
        return t_ui(windDirection[0])
    }

    let cities = citiesList && citiesList.map((city: City) => {
        if (city && city.name && (chosenCities as any).includes(city.name) && city.wavesHeight) {

            return (
                <div key={city.name}>
                    {window.isMobile1 ? null : <ReactTooltip place={"top"} type="light" effect="solid">
                        <div className="tool-tip-container">
                            <div className="tool-tip-item">
                                <div className="tool-tip-info">טמפרטורת פני הים:</div>
                                <div className="tool-tip-value">{isSubZero(city.seaTemperature) + "C"}</div>
                            </div>
                            <div className="tool-tip-item">
                                <div className="tool-tip-info">כיוון הרוח:</div>
                                <div className="tool-tip-value">{getDirectionalWindTranslate(city.windDirection)}</div>
                            </div>
                            <div className="tool-tip-item">
                                <div className="tool-tip-info">מהירות הרוח:</div>
                                <div className="tool-tip-value">{city.windSpeed + ' קמ"ש'}</div>
                            </div>
                            <div className="tool-tip-item">
                                <div className="tool-tip-info">גובה הגלים:</div>
                                <div className="tool-tip-value">{city.wavesHeight + ` ס"מ`}</div>
                            </div>
                            <div className="tool-tip-item">
                                <div className="tool-tip-info">מצב הים:</div>
                                <div className="tool-tip-value">{t_sea(city.seaStateCode)}</div>
                            </div>
                        </div>
                    </ReactTooltip>
                    }
                    <div
                        className={`city-item ${city.name.toLowerCase().replace(/ /g, "-")}`}
                        style={city.name === "Ashdod" ? { right: "67%" } : {}}
                        data-tip={"React-tooltip"}
                    >
                        <div>
                            <img src={!!isNewLayout ? newIcon : oldIcon} className="wave-height-img" />
                        </div>
                        <div className="info-title">
                            <span className="temp-info">{`${city.wavesHeight} `}</span><span className="preci-text">ס"מ</span>
                            <br />
                            <span className="city-title">{city.nameHeb}</span>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    })

    return (
        <div className="weather-extended-map-module" role="presentation">
            <div className="map-container">
                {cities}
            </div>
        </div>
    )
}

export default WeatherWaveHeightMap