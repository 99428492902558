import * as axios from "axios"
import { InfinityHeadlinesViewData } from "../interfaces"
import { InfinityHeadlinesView } from "./InfinityHeadlinesView"
import { ArticleExtraData } from "../../../../articlePage/stateInterfaces"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";

interface SiteInfinityHeadlinesComponentaProps {
    categoryId: string
    height?: number
    componentaId?: string
}

interface SiteInfinityHeadlinesComponentaState {
    articles: ArticleExtraData[]
    pageNumber: number
}

export class SiteInfinityHeadlinesComponenta extends React.Component<SiteInfinityHeadlinesComponentaProps, SiteInfinityHeadlinesComponentaState>{
    public static siteScriptName = "SiteInfinityHeadlinesComponenta"

    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0
        }
    }

    componentDidMount() {
        const { categoryId } = this.props;
        if (categoryId) {
            this.setArticles()
        }
    }

    private setArticles = () => {
        const { componentaId } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/api/blazer_auto_headlines/${componentaId}/${pageNumber}`)
            .then((res: any) => {
                this.setState(
                    {
                        articles: [...articles, ...res.data.data],
                        pageNumber: pageNumber + 1
                    }
                );
            })
            .catch(err => console.error("error geting headline data:", err))
    }

    render() {
        const { categoryId, height } = this.props
        const { articles } = this.state
        return (
            <InfinityHeadlinesView height={height} articles={articles} fetchMoreHeadlines={this.setArticles} />
        )
    }
}