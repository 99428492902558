import { SiteVideoData } from "../../../../interfaces"
import { generateVideoId } from "../../videoPlayerUtils"
import { attachYoutubeAnalytics } from "../../youtubePlayer/utils";

interface YoutubePlayerForLightboxProps {
    data: SiteVideoData
    width: number
    height: number
    focusCloseBtn?: () => void
}

export class YoutubePlayerForLightbox extends React.Component<YoutubePlayerForLightboxProps, any>{
    public player: YT.Player; // YT player instance
    public youtubePlayerAnchor; // player DOM ref
    public youtubePlayerContainerAnchor; // player container DOM ref
    private playerDomID = generateVideoId();

    componentDidMount() {
        this.createPlayer()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.player.loadVideoById(nextProps.data.youtube_id)
        }
    }

    private createPlayer = () => {
        const { data, width, height } = this.props;
        this.player = new YT.Player(this.youtubePlayerAnchor, {
            videoId: data.youtube_id,
            width,
            height,
            playerVars: {
                autoplay: data.isAutoPlaying ? 1 : 0,
                loop: data.isLoopPlaying ? 1 : 0,
                playlist: data.isLoopPlaying ? data.youtube_id : null, // Required for loop (see https://developers.google.com/youtube/player_parameters#loop).
                controls: data.isPlayerBarVisible ? 1 : 0,
                showinfo: 0,
                rel: 0
            } as YT.PlayerVars,
            events: {
                onReady: this.onPlayerReady,
                onStateChange: this.onPlayerStateChange
            } as YT.Events
        });
    }

    private onPlayerReady = (e) => {
        const { data, focusCloseBtn } = this.props
        const player = e.target;
        attachYoutubeAnalytics(this, data.youtube_id);

        if (data.isMuteAvailable) {
            player.mute()
        }
        if (data.isAutoPlaying) {
            player.playVideo();
        }
        focusCloseBtn && focusCloseBtn();
    }

    private onPlayerStateChange = (event) => {
        const player = event.target as YT.Player
        const status = event.data;

        // Pause other videos on play
        if (status == YT.PlayerState.PLAYING) {
            if (currentPlayingYoutubePlayer) {
                if (currentPlayingYoutubePlayer != player) {
                    currentPlayingYoutubePlayer.pauseVideo()
                }
            }
            currentPlayingYoutubePlayer = player;
        }
    }

    render() {
        return (
            <div ref={ref => this.youtubePlayerContainerAnchor = ref} id={this.playerDomID} className="youTubePlayer">
                <div ref={ref => this.youtubePlayerAnchor = ref} />
            </div>
        )
    }
}