
import { calculateAutomaticComponentaUrl, getImgAlt, isAriaHidden, getImgTitle } from "widgets/widgetsUtils";
import * as apiUrls from "config/apiUrls";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces";
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { SlotIconDisplay } from "../../../../commonComponents/inline/slotIconDisplay/slotIconDisplay";
import { PayWallArctilceIcon } from "../../../../commonComponents/inline/payWallHavak/payWallHavak";

interface TwentyFourSevenSlotItemProps {
    article: Partial<ArticleExtraData>
    twentyFourSevenFieldsDisplaySettings: IFieldDisplayData
    hideVideoAds?: boolean
}

export class TwentyFourSevenSlotItem extends React.Component<TwentyFourSevenSlotItemProps, {}>{

    private getIcon = (hasVideo: boolean) => {
        if (hasVideo) return "video"
        else return "none"
    }

    public render() {
        const { article, twentyFourSevenFieldsDisplaySettings } = this.props;
        const { publishedLink, promotionImageDetails, dateUpdatedOnSite, title, author, hasVideo, premiumExpireDays, category, subchannelName } = article;
        const { isTitleVisible, isAuthorVisible, isDateVisible, isCategoryVisible, isHourVisible } = twentyFourSevenFieldsDisplaySettings;
        const isMoreDetails = isAuthorVisible || isDateVisible || isCategoryVisible;
        const shouldDisplayAuthor = isAuthorVisible && author;

        return (
            <div className="slotItem">
                <div className="mediaArea">
                    <div className="MediaCarousel" >
                        <a href={publishedLink}>
                            <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 236, h: 132 })}
                                className="SiteImageMedia"
                                alt={getImgAlt(promotionImageDetails)}
                                aria-hidden={isAriaHidden(promotionImageDetails)}
                                title={getImgTitle(promotionImageDetails)}
                            />
                        </a>
                    </div>
                    {hasVideo && <SlotIconDisplay itemId={""} icon={this.getIcon(hasVideo)} iconClassName="medium" isIconVisible={hasVideo} />}
                    <PayWallArctilceIcon
                        premiumExpireDays={premiumExpireDays}
                        dateUpdatedOnSite={dateUpdatedOnSite}
                        className="onImage" />
                </div>
                <a href={calculateAutomaticComponentaUrl(article)} className="textDiv" >
                    {isTitleVisible && <div className="slotTitle">{title}</div>}
                    {isMoreDetails &&
                        <div className="moreDetails">
                            {isCategoryVisible &&
                                <>
                                    <div className="categoryView">{subchannelName}</div>
                                    {(isDateVisible || shouldDisplayAuthor) && <span className="separator">|</span>}
                                </>
                            }
                            {shouldDisplayAuthor &&
                                <>
                                    <span className="author">{author} </span>
                                    {isDateVisible && <span className="separator">|</span>}
                                </>
                            }
                            {isDateVisible &&
                                <span className="dateView">
                                    <DateDisplay date={dateUpdatedOnSite} isArticle={isHourVisible} />
                                </span>}
                        </div>}
                </a>
            </div>
        )
    }
}