import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { Group } from "./siteYnetHeaderComponenta";

import * as classNames from "classnames"
import { YnetSearchForm } from "../../../ynetNewsSearch/components/ynetSearchForm/ynetSearchForm";
const clickOutside = require('react-click-outside');

interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisibleMenu: boolean
    isVisibleSearch: boolean
    onCloseMenu: () => void
    onCloseSearch: () => void
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    searchPageLink: string
    lang:string
    displayType: string
}

@clickOutside
export class MenuPopupComponenta extends React.Component<MenuPopUpProps, {}> {

    private handleClickOutside = (e) => {
        const isVisible = this.props.isVisibleMenu || this.props.isVisibleSearch;
        if (isVisible && e.target && e.target.id !== "wcm_MenuDisplay" && e.target.id !== "wcm_MenuDisplay_moreBtn" && e.target.id !== "wcm_MenuDisplay_searchIcon" && e.target.id !== "wcm_SearchDisplay") {
            this.props.onCloseMenu();
            this.props.onCloseSearch();
        }

    }
    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    render() {
        const { groupsWithItems, searchPageLink, isVisibleSearch, lang, displayType } = this.props;
        const isVisible = this.props.isVisibleMenu || this.props.isVisibleSearch;
        const placeholderText = lang && lang === "ru" ? "Поиск" : "Search";
        const isLightMode = displayType && displayType === "light";
        return (
            <div className={classNames("popUpmenu", { "opened": isVisible, "closed": !isVisible, "searchPopUp": this.props.isVisibleSearch })} role="sub-navigation" aria-label="Sub Menu">
                 {!isLightMode && <YnetSearchForm placeholder={placeholderText} searchPageLink={searchPageLink} lang={lang} isGrid1280={true}/>}
                {!isVisibleSearch && <>
                  {isLightMode &&   <div id="mainAccessibilityButton" >
                    <a id="INDmenu-btn" onClick={this.clickAcessibilityBtn} title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button">
                        <span className="icon" ></span>
                    </a></div>}
                    <div className="linksGroups">
                        {_.map((groupsWithItems), (item: Group, index: number) =>
                            <div className="group" key={index}>
                                <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                                <ul className="groupList">
                                    {_.map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                        <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} style={{ color: subItem.color }}>
                                            <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                        </HyperLinkBuilder></li>
                                    )}
                                </ul>
                            </div>
                        )}</div>
                </>}
            </div>)
    }
}