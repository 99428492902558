
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink";
import { PplusMobileArticleHeader } from "./pplusMobileArticleHeader";
import { isWcmEditor } from "../../../../../config/serverConfig";
import * as throttle from "lodash/throttle"
import * as ClassNames from "classnames"
require('./m_pplus_pplusHeader.less')

export interface PplusMenuItem {
    id: string
    name: string
    link: string
    catId: string
    targetLink?: LinkTargetProperties
}

interface pplusHeaderProps {
    menuItems: PplusMenuItem[]
    categoryId: string
    isArticlePage?: boolean
}

interface SiteState {
    isSticky: boolean
    isMenuActive: boolean
    isSearchActive: boolean
    userInput: string
    bodyOverflowState: string
}

export class PplusMobileHeader extends React.Component<pplusHeaderProps, SiteState> {
    constructor(props) {
        super(props)
        this.state = {
            isSticky: false,
            isMenuActive: false,
            isSearchActive: false,
            userInput: "",
            bodyOverflowState: ""
        }
    }

    public static siteScriptName = "PplusMobileHeader";

    private activateStickyHeader() {
        const headerPos = document.querySelector(".pplus-header").getBoundingClientRect().top
        if (headerPos < 0) {
            this.setState({ isSticky: true })
        } else {
            this.setState({ isSticky: false })
        }
    }

    private throttledScrollHandler = () => throttle(() => this.activateStickyHeader(), 200)

    public componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
        if (!this.props.isArticlePage && !isWcmEditor()) { this.scrollCurrentCategoryIntoView() }
    }

    componentWillUnmount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
    }

    private scrollCurrentCategoryIntoView = () => {
        const { categoryId } = this.props

        const elementToScroll = document.getElementById(`category-${categoryId}`);
        elementToScroll && elementToScroll.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
    }

    private currentCatName = (catId: string) => {
        const { categoryId } = this.props
        if (categoryId === catId)
            return "current"
        else return "";
    }

    private activateSideMenu = () => {
        this.setState(state => ({ isMenuActive: !state.isMenuActive }), () => {
            if (this.state.isMenuActive) {
                this.setState({ bodyOverflowState: document.querySelector("body").style.overflow });
                document.querySelector("body").style.overflow = "hidden";
                document.body.classList.add("showNagish");
            } else {
                this.setState({ isSearchActive: false });
                document.querySelector("body").style.overflow = this.state.bodyOverflowState;
                document.body.classList.remove("showNagish");
            }
        });
    }

    private activateSearch = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive })
    }

    private updateInputValue = (e) => {
        this.setState({ userInput: e.target.value })
    }

    private search = () => {
        const { userInput: typedUserInput } = this.state;

        if (!typedUserInput) { return }

        let urlHead = 'https://www.google.com/search?pplus_q='
        let userInput = typedUserInput
        let site = `&q=site:m.pplus.ynet.co.il/+${typedUserInput}`
        let fullUrl = urlHead + userInput + site

        window.open(fullUrl, '_blank');
    }

    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }
    }

    render() {
        const { menuItems, isArticlePage } = this.props
        const { isSticky, isMenuActive, isSearchActive, userInput } = this.state
        const logoLink = "//pplus.ynet.co.il/home/0,7340,L-11211,00.html"

        if (isArticlePage) {
            return <PplusMobileArticleHeader logoLink={logoLink} />
        }
        else {
            return (
                <div className={ClassNames("PplusMobileHeader", { "sticky-header": isSticky && !isMenuActive, "enlargeHeader": isMenuActive })} id="PplusMobileHeader">

                    <div className="logo-wrapper">
                        <SimpleLinkRenderer className="pplusLogo" href={logoLink} />
                    </div>

                    <div className="header-content-wrapper">
                        <nav className={ClassNames("linksWrapper", { 'hide-categories': isMenuActive })} role="navigation" aria-label="Menu">
                            <ul>
                                {menuItems.map((item, index) => {
                                    return (
                                        <li className={`menuItem ${this.currentCatName(item.catId)}`} key={index} id={`category-${item.catId}`} >
                                            <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                                {item.name}
                                            </HyperLinkBuilder>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>

                        <div className="menu-btn-wrapper">
                            <div className={ClassNames("menu-btn", { "show-x-btn": isMenuActive })} onClick={this.activateSideMenu}></div>
                        </div>
                    </div>

                    <div className={ClassNames("side-menu-wrapper", { 'menu-active': isMenuActive })}>

                        <div className="search-wrapper">
                            <div className={ClassNames("open-search-box-btn", { "hide-search-box-btn": isSearchActive })} onClick={this.activateSearch}></div>
                            <input
                                type='text'
                                dir="rtl"
                                placeholder='חיפוש'
                                onChange={this.updateInputValue}
                                className={ClassNames("search-input", { "show-search-box": isSearchActive })}
                                onKeyPress={this.handleInputKeyPress}
                            >
                            </input>
                            <SimpleLinkRenderer
                                href={`//www.google.com/search?q=site%3Ahttps://m.pplus.ynet.co.il/+${userInput}`}
                                target="_blank"
                                className={ClassNames("search-btn", { "show-search-btn": isSearchActive })}
                            />
                        </div>

                        <div className="categories-wrapper">
                            <ul>
                                {menuItems.map((item, index) => {
                                    return (
                                        <li className={`menuItem ${this.currentCatName(item.catId)}`} key={index} id={`category-${item.catId}`} >
                                            <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                                {item.name}
                                            </HyperLinkBuilder>
                                        </li>
                                    )
                                })}
                            </ul>

                            <div className="ynet-logo-wrapper">
                                <SimpleLinkRenderer className="menu-ynet-logo" href="//www.ynet.co.il/home/0,7340,L-8,00.html" />
                            </div>
                        </div>

                        <div className="footer">
                            <div className="seperator"></div>
                            <div className="pplus-social">
                                <SimpleLinkRenderer href="//www.instagram.com/pnaiplus" target="_blank" className="socialBtn pplus_instagram" />
                                <SimpleLinkRenderer href="//www.facebook.com/pnai.plus" target="_blank" className="socialBtn pplus_fb" />
                                <SimpleLinkRenderer href="//www.tiktok.com/@pnai_plus" target="_blank" className="socialBtn pplus_tiktok" />
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }
}