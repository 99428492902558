import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { TwentyFourSevenSlotItem } from "./twentyFourSevenSlotItem";
import { IFieldDisplayData } from "../../../../commonDataObjects/interfaces";


interface TwentyFourSevenSlotListProps {
    twentyFourSevenArticles: ArticleExtraData[]
    twentyFourSevenFieldsDisplaySettings: IFieldDisplayData
    twentyFourSevenBottomLink: string
    twentyFourSevenBottomTitle: string
    shouldDisplayTicker: boolean
}

export class TwentyFourSevenSlotList extends React.Component<TwentyFourSevenSlotListProps, {}> {

    public render() {
        const { twentyFourSevenArticles, twentyFourSevenFieldsDisplaySettings, twentyFourSevenBottomLink, twentyFourSevenBottomTitle, shouldDisplayTicker } = this.props;
        const classNames = `twentyFourSevenSlotListWrapper ${shouldDisplayTicker ? "none" : ""}`;

        return (
            <div className={classNames}>
                <div className="twentyFourSevenSlotList">
                    {twentyFourSevenArticles.map((article) => (
                        <TwentyFourSevenSlotItem
                            article={article}
                            twentyFourSevenFieldsDisplaySettings={twentyFourSevenFieldsDisplaySettings}
                            key={article.articleId} />
                    ))}
                </div>
                <a href={twentyFourSevenBottomLink} className="bottomLink" id="twenty-four-seven-bottom-link">
                    <span className=" bottomLinkTitle">{twentyFourSevenBottomTitle}</span>
                    <div className="bottomLinkIcon" />
                </a>
            </div>
        )
    }
}