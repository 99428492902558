
import { calculateAutomaticComponentaUrl, separateHourFromDateAndFormatToString, getImgAlt, isAriaHidden, getImgTitle, calculateShouldAddArticleAutoplayIndicator } from "widgets/widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { ImageDetails } from "images/interfaces"
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { CtechDateDisplay } from "../../../../commonComponents/inline/dateDisplay/ctechDateDisplay"
import { MediaVideoData } from "../../../../../media/interfaces";
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { ArticleType } from "../../../../../articlePage/stateInterfaces";


interface CalcalistArticleHeadlinesItemProps {
    article: Partial<SiteArticleHeadlinesData>
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    showTaboola: boolean
    uid?: string
    isLTR?: boolean
    hideVideoAds?: boolean
}
export interface SiteArticleHeadlinesData {
    articleId: string
    author: string
    credit: string
    title: string
    subTitle: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean
    publishedLink: string
    publishedDate: Date
    roof?: string
    video?: MediaVideoData[]
    articleType?:ArticleType
}

export class CalcalistArticleHeadlinesItem extends React.Component<CalcalistArticleHeadlinesItemProps, {}>{
    static defaultProps = { isCategoryVisible: true }

    public componentDidMount() {
        if (this.props.showTaboola) {
            window._taboola = window._taboola || [];
            window._taboola.push({
                mode: 'thumbnails-i',
                container: 'taboola-category-mid-page-stream',
                placement: 'Category Mid Page Stream',
                target_type: 'mix'
            });
        }
    }

    public render() {
        const { article, fieldsDisplaySettings, titleColor, subTitleColor, showTaboola, uid, isLTR, hideVideoAds } = this.props;
        const { title, subTitle, author, credit, promotionImageDetails, publishedLink, publishedDate, roof, hasVideo, articleId, video, articleType } = article
        const isMoreDetails = fieldsDisplaySettings.isAuthorVisible || fieldsDisplaySettings.isDateVisible || fieldsDisplaySettings.isHourVisible
        const isDateView = fieldsDisplaySettings.isDateVisible || fieldsDisplaySettings.isHourVisible
        const articleMainVideo = article.video && article.video.length && article.video[0];
        const videoIcon = hasVideo ? "video" : "none";
        const iconType = articleType==="audio" ? "audio" : videoIcon;
        return (
            <>
                {showTaboola && <div id="taboola-category-mid-page-stream" />}
                <div className="slotItem" data-wcm-article-id={articleId} read-more-id={uid}>
                    <div className="mediaArea CalcalistComponentaShareMenuMedia">
                        <div className="MediaCarousel" >

                            {articleMainVideo ?
                                <a>
                                    <SiteMedia
                                        data={{
                                            ...articleMainVideo,
                                            url: articleMainVideo.main_link,
                                            poster: apiUrls.getImageUrl(promotionImageDetails, { w: 270, h: 146 }),
                                            mediaType: "MEDIA_VIDEO",
                                            title,
                                            showAds: !hideVideoAds
                                        } as any}
                                        width={270}
                                        height={146}
                                        videoSettings={{
                                            isVideoLightbox: true,
                                            hideVideoAds: hideVideoAds
                                        }}
                                        itemLinkUrl={title}
                                        isImageLinkable={true}
                                    />
                                </a>
                                :
                                <a href={publishedLink}>
                                <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 270, h: 146 })}
                                    style={{ width: 270, height: 146 }}
                                    className="SiteImageMedia"
                                    alt={getImgAlt(promotionImageDetails)}
                                    aria-hidden={isAriaHidden(promotionImageDetails)}
                                    title={getImgTitle(promotionImageDetails)}
                                    />
                                </a>}

                        </div>
                        {!video && <SlotIconDisplay itemId={""} icon={iconType} iconClassName="medium" isIconVisible={iconType!=="none"} />}
                        <CalcalistShareMenu url={publishedLink} title={this.props.article.title} isLTR={isLTR} isArticle={true} />
                    </div>
                    <a href={calculateAutomaticComponentaUrl(article)} className="textDiv" >
                        {fieldsDisplaySettings.isTitleVisible && <div className="slotTitle" style={{ color: titleColor }}>
                            {roof !== null && <span className="roofTitle" data-roof-text={`${roof} | `}>{roof} | </span>} <span>{title}</span>
                        </div>}
                        {isMoreDetails && <div className="moreDetails">
                            {isDateView &&
                                <span className="dateView">
                                {isLTR ? (fieldsDisplaySettings.isDateVisible && <CtechDateDisplay date={publishedDate} />) :
                                    separateHourFromDateAndFormatToString(publishedDate,
                                        {
                                            showDate: fieldsDisplaySettings.isDateVisible,
                                            showHour: fieldsDisplaySettings.isHourVisible
                                        })}
                                </span>}
                            {isLTR && <span> | </span>}
                            {fieldsDisplaySettings.isAuthorVisible && <span className="author">{author}</span>}
                        </div>}
                        {fieldsDisplaySettings.isSubTitleVisible && <div className="slotSubTitle" style={{ color: subTitleColor }}>
                            <span>{subTitle}</span>
                        </div>}
                    </a>
                </div>
            </>
        )
    }
}