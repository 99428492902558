import * as map from "lodash/map"

interface InfiniteArticleItemProps {
    article: {
        body: string
        id: string
    }
}

export class InfiniteArticleItem extends React.Component<InfiniteArticleItemProps, {}> {

    componentDidMount() {
        const { article } = this.props
        if (article) {
            const scriptsToRun = document.querySelectorAll(`#${article.id} script`);
            map(scriptsToRun, script => eval(script.innerHTML))
        }
    }

    render() {
        const { article } = this.props
        if (article) {
            return (
                article.id && <div id={article.id} dangerouslySetInnerHTML={{ __html: article.body }} />
            )
        } else {
            return null;
        }
    }
}