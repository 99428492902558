
import { HamburgerButtonComponenta } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/hamburgerButton"
import {  HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList"
import { CtechMenuList, BottomLinksItems } from "./ctechMenuList"
import { SearchComponenta } from "./SearchComponenta"
require('../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/ctech_calcalist_site_menu.less')
require('../../../calcalistWidgets/categoryHeader/components/searchInput/ctech_calcalist_site_search_area.less')

interface HamburgerProps {
    menuItems: HamburgerMenuItems[]
    width: number
    bottomLinksItems: BottomLinksItems[]
    domain:string
}

interface HamburgerState {
    isOpenedHam: boolean
    isOpenedSearch: boolean
}
export class CtechHeaderMenu extends React.Component<HamburgerProps, HamburgerState> {
    public static siteScriptName = "CtechHeaderMenu"
    constructor(props) {
        super(props)
        this.state = {
            isOpenedHam: false,
            isOpenedSearch: false
        }
    }

    private onToggleHam = () => {
        this.setState({ isOpenedHam: !this.state.isOpenedHam, isOpenedSearch: false })
    }

    render() {
        const { menuItems, width, bottomLinksItems, domain } = this.props
        return (

            <div className="CtechHeaderMenu">
                <SearchComponenta domain={domain}/>
                <HamburgerButtonComponenta onToggleMenu={this.onToggleHam} isOpened={this.state.isOpenedHam} />
                <CtechMenuList menuItems={menuItems} width={width} isOpenedHam={this.state.isOpenedHam} bottomLinksItems={bottomLinksItems} />
            </div>
        )


    }
}