import { isWcmEditor } from "../../../../../config/serverConfig";
interface socialProp {
    openWindow: (url: string, name: string, width: number, height: number) => void
}

export class VestySocial extends React.Component<socialProp, {}> {


    public render() {
        const { openWindow } = this.props
        if (!isWcmEditor())
            return (
                <div className="main_search_social main_search_elements">
                    <div className="main_search_sep2 main_search_elements">
                        <img src="/images/mainsearch_sep.png" aria-hidden="true" />
                    </div>
                    <a href="//www.vesty.co.il/home/0,7340,L-13433,00.html" ><img src="//www.vesty.co.il/images/rssIconVesty.png" alt="RSS" title="RSS" /></a>
                    <div className="main_search_sep2 main_search_elements">
                        <img src="/images/mainsearch_sep.png" aria-hidden="true" />
                    </div>
                    <a href="mailto:doaradom@vesty.co.il" ><img src="//www.vesty.co.il/images/emailVestyIcon.png" alt="Имейл" title="Имейл" /></a>

                    <div className="main_search_sep2 main_search_elements">
                        <img src="/images/mainsearch_sep.png" aria-hidden="true" />
                    </div>
                    <a href='//www.facebook.com/VestyIsrael/' target="_blank">
                        <img src="/images/faceVesty.png" alt="Вести в Фейсбуке" title="Вести в Фейсбуке" />
                    </a>
                </div>
            )
        else return null
    }
}
