interface ExtraDetailsProps {
    photographer: string
    sender: string
    mail: string
    phoneNumber: string
    validation: { mail: boolean, phoneNumber: boolean }
    onChange: (e: any) => void
    extraDetailsData: any
}

export class ExtraDetails extends React.Component<ExtraDetailsProps, State> {

    render() {
        const { photographer, sender, mail, phoneNumber, onChange, validation, extraDetailsData: { title, subTitle, mailPattern } } = this.props

        return (
            <div className="RedMailExtraDetails">
                {title && <div className="ExtraDetailsTitle">{title}</div>}
                {subTitle && <div className="ExtraDetailsSubTitle">{subTitle}</div>}

                <div className="inputFieldWrapper">
                    <div className="inputField">      
                        <input name="photographer" dir="rtl" value={photographer} onChange={(e) => onChange(e)} type="text" id="photographer" placeholder="اسم المصور" ></input>
                    </div>

                    <div className="inputField">      
                        <input name="sender" dir="rtl" value={sender} onChange={(e) => onChange(e)} type="text" id="sender" placeholder="اسم المرسل" ></input>
                    </div>

                    <div className="inputField">
                        <input name="mail" dir="rtl" value={mail} style={{borderColor:mail && !validation.mail ? "#D80000" : "#020202" }} onChange={(e) => onChange(e)} type="email" id="mail" placeholder="عنوان البريد الإلكتروني" ></input>
                        {(mail && !validation.mail) && <p className="redMail-error-validation">{mailPattern}</p>}
                    </div>
                    
                    <div className="inputField">
                        <input name="phoneNumber" dir="rtl" style={{borderColor:(phoneNumber && !validation.phoneNumber) ? "#D80000" : "#020202" }} value={phoneNumber} onChange={(e) => onChange(e)} type="text" id="phoneNumber" placeholder="رقم الهاتف" ></input>
                        {(phoneNumber && !validation.phoneNumber) && <p className="redMail-error-validation">الرقم يجب ان يتكون من 10 منازل، بدون اي رموز</p>}
                    </div>
                </div>
            </div>
        )
    }
}