import { CommentFormType } from "./interfaces"
import { getTranslation } from "./translations"
import * as axios from "axios"
import { analyticsPushFunc } from "../../../../widgetsUtils"

export interface AddCommentFormRenderProps {
    lang: string
    articleId: string
    formType: CommentFormType
    name: string
    email: string
    location: string
    title: string
    comment: string
    isSent: boolean
    isPreviewModeOn: boolean
    showAlert: boolean
    alertBeforeClosing: () => void
    closeAlert: () => void
    handleCommentSend: () => void
    handleCloseCommentForm: () => void
    resetFormData: () => void
    onInputChanged: any
    isAddCommentFormVisible: boolean
    isFirstComment?: boolean
}

export type AddCommentFormRender = (
    props: AddCommentFormRenderProps
) => React.ReactElement<any>

interface AddCommentFormWrapperProps {
    render: AddCommentFormRender
    articleId: string
    parentTalkbackId?: number
    formType: CommentFormType
    lang: string
    isAddCommentFormVisible: boolean
    handleCloseCommentForm: () => void
    isFirstComment?: boolean
}

interface AddCommentFormWrapperState {
    name: string
    email: string
    location: string
    title: string
    comment: string
    isSent: boolean
    isPreviewModeOn: boolean
    showAlert: boolean
}

export class AddCommentFormWrapper extends React.Component<AddCommentFormWrapperProps, AddCommentFormWrapperState>{
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            location: "",
            title: "",
            comment: "",
            isSent: false,
            isPreviewModeOn: false,
            showAlert: false
        }
    }

    private resetFormData = () => {
        this.setState({
            name: "",
            email: "",
            location: "",
            title: "",
            comment: "",
            isSent: false,
            isPreviewModeOn: false,
        })
    }

    private onInputChanged = (e, inputName: string) => {
        const inputValue = e.target.value as string
        this.setState({ [inputName]: inputValue })
    }

    private handleCommentSend = () => {
        const { articleId, parentTalkbackId, formType, lang, isFirstComment } = this.props
        const { title, name, email, location, comment } = this.state

        if (title.length > 0) {
            const postData = {
                talkback_title: title,
                talkback_content: comment,
                author_name: name,
                author_email: email,
                author_location: location,
                article_id: articleId,
                talkbackParentData: formType === "REPLY" ? parentTalkbackId : "",
                talkback_source: null
            }
            analyticsPushFunc("Success", "Send Comment", title)
            axios.post(`/iphone/json/api/talkbacks/add`, postData)
            this.setState({ isSent: true })
        }
        else {
            analyticsPushFunc("Fail", "Send Comment", title)
            alert(getTranslation(lang, "You Must Enter Title"))
        }
    }

    private togglePreviewMode = () => {
        const { isPreviewModeOn, title } = this.state
        if (title.length === 0) {
            alert(getTranslation(this.props.lang, "You Must Enter Title"))
        } else {
            this.setState({ isPreviewModeOn: !isPreviewModeOn })
        }
    }

    private alertBeforeClosing = () => {
        const { title, comment } = this.state
        const { lang, handleCloseCommentForm } = this.props
        if (lang === "he" && (title !== "" || comment !== "")) {
            this.setState({ showAlert: true })
        }
        else handleCloseCommentForm();
    }

    private closeAlert = () => {
        this.setState({ showAlert: false })
    }

    render() {
        const { isAddCommentFormVisible, lang, articleId, handleCloseCommentForm, isFirstComment } = this.props
        const { isSent, isPreviewModeOn, title, name, email, location, comment, showAlert } = this.state

        return (
            <div className="mainFormWrapper">
                {this.props.render(
                    {
                        lang,
                        articleId,
                        formType: "NEW",
                        handleCloseCommentForm,
                        name,
                        email,
                        location,
                        title,
                        comment,
                        isSent,
                        isPreviewModeOn,
                        showAlert,
                        isAddCommentFormVisible,
                        alertBeforeClosing: this.alertBeforeClosing,
                        closeAlert: this.closeAlert,
                        togglePreviewMode: this.togglePreviewMode,
                        handleCommentSend: this.handleCommentSend,
                        resetFormData: this.resetFormData,
                        onInputChanged: this.onInputChanged,
                        isFirstComment
                    }
                )}
            </div>)
    }
}