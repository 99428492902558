import * as filter from "lodash/filter";

export const autoTickerRowTranslations =
{
    "he": {
        "Now": "עכשיו",
        "ExtendedView": "תצוגה מורחבת",
        "StandardView": "תצוגה רגילה",
        "ShareByMail": "שיתוף במייל",
        "ShareByTwitter": "שיתוף בטוויטר",
        "ShareByFacebook": "שיתוף בפייסבוק",
        "ShareByWhatsApp": "שיתוף בואטסאפ",
    },
    "en": {
        "Now": "now",
        "ExtendedView": "Extended view",
        "StandardView": "Standard view",
        "ShareByMail": "Share by Mail",
        "ShareByTwitter": "Share by Twitter",
        "ShareByFacebook": "Share by Facebook",
        "ShareByWhatsApp": "Share by WhatsApp",
    },
    "ru": {
        "Now": "в настоящее время",
        "ExtendedView": "Расширенный вид",
        "StandardView": "Стандартный вид",
        "ShareByMail": "Поделиться по почте",
        "ShareByTwitter": "Поделиться в Твиттере",
        "ShareByFacebook": "Поделиться через Facebook",
        "ShareByWhatsApp": "Поделиться по WhatsApp",
    },
}


export const getTranslation = (lang, text: string) => {
    var obj = filter(autoTickerRowTranslations, function (v, k) {
        return k === lang;
    });
    var content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]

}