import { ArticleData, ArticleComponentaEntityData } from "../../articlePage/stateInterfaces"
import { convertFromRaw, EditorState, SelectionState, convertToRaw } from "draft-js"
import { ArticleSchema } from "constants/schemas"
import { EntityData } from "textEditor/interfaces"
import { addBlockComponent } from "textEditor/utils/addBlockComponent"
import { IdGenerator } from "../../utils/idGenerator"

export const injectContactLink = (articleData: ArticleData) => {
        const href = getLinkBySubsite(articleData.sub_site_id, articleData.articleId)
        if (!href) return;
        const textJson = articleData.textJson
        const textJsonBlocks = textJson["blocks"]
        const textJsonEntityMap = textJson["entityMap"]
        const entityUniqueKey = IdGenerator.generate()

        textJsonBlocks.push({
                "key": IdGenerator.generate(),
                "text": "מצאתם טעות בכתבה? כתבו לנו על זה",
                "type": "contactUsLink",
                "depth": 0,
                "data": {},
                "inlineStyleRanges": [],
                "entityRanges": [
                        {
                                "offset": 0,
                                "length": 47,
                                "key": entityUniqueKey
                        }
                ]
        })

        textJsonEntityMap[entityUniqueKey] = {
                data: {
                        activeTab: 'linkProperties',
                        linkProperties: {
                                link: {
                                        description: href,
                                        id: href,
                                        publishedLink: href
                                },
                                type: 'EXTERNAL_URL'
                        },
                        linkTargetProperties: {
                                lightBoxBgColor: '#000',
                                lightBoxHeight: '',
                                lightBoxOpacity: 70,
                                lightBoxWidth: '',
                                linkOpenType: 'same'
                        }
                },
                mutability: 'MUTABLE',
                type: 'LINK'
        }
}
export const getLinkBySubsite = (subSiteId: string, articleid:string) => {
        if (subSiteId != "ynet" && subSiteId != "mynet") return null

        let href = `//ynet.co.il/redmail?notifyMistake=${articleid}`

        if (subSiteId.toLowerCase().startsWith("mynet")) {
                href = "mailto:mynet@yedtik.co.il";
        }

        return href;
}