import * as map from "lodash/map"
import { SiteTickerData } from "../../../ticker/components/tickerComponenta";
import { TickerItemSlotWar } from "./tickerItemSlot";


interface TickerListProps {
    siteFlashList: Partial<SiteTickerData>[]

}

export class TickerList extends React.Component<TickerListProps, {}>{

    public render() {
        const { siteFlashList } = this.props
        const arrayOfTitles = map(siteFlashList, item => item.title);
        let n;
        if (arrayOfTitles.length > 0) { n = arrayOfTitles.reduce((a, b) => a + b) }
        else {
            n = 1;
        }
        const duration = arrayOfTitles.length > 3 ? n.length * 0.30 : n.length * 0.60;

        if (!siteFlashList || siteFlashList.length == 0) {
            return null
        }
        return (
            <div className="ticker-wrap"  >
                <div className="animationDiv" style={{ animationDuration: `${duration}s, ${duration}s`, animationDelay: `${0}s, ${duration}s` }}>
                    {siteFlashList.map((flashItem, i) => <TickerItemSlotWar
                        title={flashItem.title}
                        flashDate={flashItem.flashDate}
                        linkTarget={flashItem.linkTarget}
                        link={flashItem.link}
                        key={flashItem.slotId}
                        flashId={flashItem.slotId}
                        flashLinkType={flashItem.flashLinkType}
                    />)}
                </div>

            </div>
        )
    }
}