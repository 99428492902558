require("./site_articleImagesGallery.less")
import { CSSTransitionGroup } from "react-transition-group"
import { ExtededImageDetails } from "../../../../../images/interfaces"
import { ArticleGalleryView } from "./articleGalleryView";
import { SiteSettingsHOC } from "../../../../../global/components/siteSettingsHOC";
import { SiteSettings } from "global/components/siteSettingsHOC"
import { getImagesByIds } from "../../../../../images/selectors"
import { connect } from "react-redux"
import { ImageDetails } from "images/interfaces"

interface SiteArticleImagesGalleryProps {
    images: ExtededImageDetails[]
    articleId: string
    isLtr: boolean
}
interface SiteArticleImagesGalleryState {
    currentImageIndex: number
    isVisible: boolean
}

export class SiteArticleImagesGallery extends React.Component<SiteArticleImagesGalleryProps, SiteArticleImagesGalleryState>{
    constructor(props) {
        super(props)
        this.state = {
            currentImageIndex: 0,
            isVisible: false
        }
    }
    public static siteScriptName = "SiteArticleImagesGallery"

    componentDidMount() {
        const { images } = this.props;

        if (typeof window !== "undefined") {
            const elements = document.querySelectorAll(".gelleryOpener");
            for (let el of elements) {
                el.addEventListener("click", (e) => {
                    this.open();
                    const componentaId = el.getAttribute("data-image-id")
                    let indexOfImageMatchingCoponentaId = 0;
                    for (let i = 0; i < images.length; i++) {
                        if (images[i]) {
                            if (images[i].imageId === componentaId) { indexOfImageMatchingCoponentaId = i }
                        }
                    }
                    this.setState({ isVisible: true, currentImageIndex: indexOfImageMatchingCoponentaId })
                })
                el.addEventListener("keypress", (e) => {
                    const componentaId = el.getAttribute("data-image-id")
                    let indexOfImageMatchingCoponentaId = 0;
                    for (let i = 0; i < images.length; i++) {
                        if (images[i].componentaId === componentaId) { indexOfImageMatchingCoponentaId = i }
                    }
                    this.setState({ isVisible: true, currentImageIndex: indexOfImageMatchingCoponentaId })
                })

            }
        }

    }

    public swipeRight = (): void => {
        const lastImageIndex = this.props.images.length - 1
        const { currentImageIndex } = this.state
        const nextImageIndex = currentImageIndex === 0 ? lastImageIndex : currentImageIndex - 1
        analystFunc("Browse Right", this.props.articleId)
        this.setState({ currentImageIndex: nextImageIndex })
    }

    public swipeLeft = (): void => {
        const lastImageIndex = this.props.images.length - 1
        const { currentImageIndex } = this.state
        const nextImageIndex = currentImageIndex === lastImageIndex ? 0 : currentImageIndex + 1
        analystFunc("Browse Left", this.props.articleId)
        this.setState({ currentImageIndex: nextImageIndex })
    }

    private close = (): void => {
        const { images } = this.props
        const { currentImageIndex } = this.state
        document.getElementById(`image_${images[currentImageIndex].imageId}`).focus()
        analystFunc("Close Gallery", this.props.articleId)
        this.setState({ isVisible: false })

    }
    private open = (): void => {
        analystFunc("Open Gallery", this.props.articleId)
    }


    render() {
        const { images, articleId, isLtr } = this.props
        const { currentImageIndex, isVisible } = this.state


        if (isVisible) return (
            <CSSTransitionGroup
                transitionName="galleryLeave"
                transitionLeave={true}
                transitionLeaveTimeout={200}
                transitionEnterTimeout={200}>
                <ArticleGalleryView images={images}
                    currentImageIndex={currentImageIndex}
                    close={this.close}
                    swipeLeft={this.swipeLeft}
                    swipeRight={this.swipeRight}
                    isLtr={isLtr}
                />

            </CSSTransitionGroup >
        )
        else return null
    }
}

export const analystFunc = (action: string, articleId: string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'GA_Event',
        Category: 'Photo Gallery',
        Action: action,
        Label: articleId
    });
}
