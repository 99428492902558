import { getCityNameTranslation } from "../../../../ynetSearch/components/ynetWeather/cityNamesTranslator";
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
var Cookies = require('js-cookie');


interface YnetWeatherState {
    selectedCity: any
}

interface YnetWeatherProps {
    weather: any
    defaultCityIndex: number
    weatherUrl?: string
}
declare let window: Window & {
    languageCode: string
}


export class YnetWeatherMutam extends React.Component<YnetWeatherProps, YnetWeatherState> {
    constructor(props) {
        super(props)
        let city = Cookies.get('weatherCity');
        this.state = {
            selectedCity: city || props.defaultCityIndex,

        }

    }


    public render() {
        const { weather, weatherUrl } = this.props
        const { selectedCity } = this.state
        const selectedWeather = weather[selectedCity];
        // const imgSrc = `/images/weather/${iconTranslate(selectedWeather.weatherCode)}.svg`
        const imgSrc = `/images/weather-new-icons/newWeatherSvgs/${selectedWeather.weatherCode}.svg`;
        const urlToCity = getCityNameTranslation("link", selectedWeather.city) ? "/" + getCityNameTranslation("link", selectedWeather.city) : "";
        const weatherPageUrl = weatherUrl ? weatherUrl + urlToCity : "//www.ynet.co.il/home/0,7340,L-201,00.html";
        const language = (typeof window !== "undefined" &&  window.languageCode)  || "";
        const weatherTitle = language.includes("ru") ? "Погода" : "Weather";  
        return (
            <div className="WeatherView">
                <div className="weatherimage">
                    {selectedWeather &&
                        <SimpleLinkRenderer href={weatherPageUrl} aria-label="Weather">

                            <img src={imgSrc} aria-hidden="true" />
                        </SimpleLinkRenderer>}
                </div>
                <div className="weathertempsdiv">
                    {selectedWeather &&
                        <SimpleLinkRenderer className="weatherLink" href={weatherPageUrl}>
                            <span className="weathertemps">{selectedWeather.temp}&deg;</span>
                        </SimpleLinkRenderer>}
                </div>

                <div className="headerWeatherSelect">
                    <SimpleLinkRenderer href={weatherPageUrl} aria-label="Weather" className="selectedCity" >{weatherTitle}</SimpleLinkRenderer>
                    <div className={"menuToggler toOpen"} />

                </div>
            </div>
        )
    }
}