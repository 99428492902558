
import {  SiteVideoData } from "widgets/interfaces"
import { SiteVideoInline } from "../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline"


export interface VideoItemMutam {
  video:SiteVideoData 
  isTabVisible:boolean 
  videoCredit:string 
  videoCaption:string 
  isCaptionVisible:boolean
  isCreditVisible:boolean
  tabTitleText:string
  tabColor:string
  videoAdsHide:boolean
}


export class MutamVideoItem extends React.Component<VideoItemMutam, {}>{
    public static siteScriptName = "MutamVideoItem";
  private playVideoInComp =()=>{
    let VideoComponent: any = document.querySelector('.SideVideoMutamComponenta.VideoMutamComponentaCalcalist .videoPlayerPlaceholder video');
    if(VideoComponent){
        VideoComponent.togglePlay(true)
    }
  }

 


    public render() {
        const { video, isTabVisible, videoCredit, videoCaption, isCaptionVisible, isCreditVisible, tabTitleText, tabColor, videoAdsHide } = this.props;   
        return (
            <div className="SideVideoMutamComponenta VideoMutamComponentaCalcalist" >
               {isTabVisible &&  <h2 className="tabComponenta" onClick={this.playVideoInComp}>          
                    <span className={`iconImage-pf icon-video`}/>
                    <span className="tabTitle" style={{color:tabColor}}>{tabTitleText}</span>
                    <span className="arrowTo-pf"></span>        
            </h2>}
          
                <div className="SideVideoContent">
                <div className="mainVideo">
                    <div className="videoPlayerPlaceholder">
                    <SiteVideoInline
                        data={video}
                        applyStickyness={false}
                        hideVideoAds={videoAdsHide}
                        isShareButton={false} />
                    </div>
                    <span className="itemTitle" onClick={this.playVideoInComp}>
                    {isCaptionVisible &&  <span className="title" dangerouslySetInnerHTML={{ __html: videoCaption }} />}
                    {isCreditVisible && videoCredit.length>0 && <>{` (`}<span className="title" dangerouslySetInnerHTML={{ __html: videoCredit }} />{`)`}</>}
                    </span>
                </div>       
            </div>
            </div> 
        )
    }
}