import { RadioProgramItem } from "../../../radioHompage/components/radioHomepagePlayerComponenta";
import { getDiffFromToday } from "../../../../../siteWidgets/formatDateDisplay";
import { HyperLink, HyperLinkBuilder } from "../../../../../commonComponents/inline/hyperLink";

interface RadioScheduleListItemProps {
    item: RadioProgramItem
    isCurrentShow: boolean
    index: number
    isProgramFinished: boolean
}

const RadioScheduleListItem = (props: RadioScheduleListItemProps) => {
    const { item, isCurrentShow, isProgramFinished, index } = props
    const { startTime, endTime, title, startProgram, hideHours, linkData, linkTarget } = item

    const getDiffDateInString = () => {
        const diffInDays = getDiffFromToday(startProgram as Date)
        const day = String(new Date(startProgram).getDate()).padStart(2, '0');
        const month = String(new Date(startProgram).getMonth() + 1).padStart(2, '0');
        const year = new Date(startProgram).getFullYear().toString().substr(-2);
        const date = `${day}.${month}.${year}`

        if (diffInDays === 1) {
            return "אתמול"; // '2 days ago' etc.
        }
        if (diffInDays === -1) {
            return "מחר"
        }
        if (diffInDays > 1 || diffInDays < -1) {
            return date
        }
        return ""
    }

    const diff = getDiffDateInString()
    const broadcastHours = hideHours ? `${diff ? diff : ""}` : `${diff ? diff + " |" : ""} ${startTime} - ${endTime}`

    return (
        <div className={`schedule-list-item ${isProgramFinished ? "passed" : ""}`} id={`schedule-list-item_${index}`}>
            {isCurrentShow ?
                <div className="broadcast-live">
                    <div className="red-dot" />
                    <div className="live-title">
                        עכשיו בשידור
                </div>
                </div>
                :
                <div className="broadcast-hours">
                    {broadcastHours}
                </div>}
            <HyperLinkBuilder className="item-title" href={linkData && linkData.link && linkData.link.href} linkTarget={linkTarget} >
                {title}
            </HyperLinkBuilder>
        </div>
    )
}

export default RadioScheduleListItem