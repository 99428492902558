import * as axios from "axios"
import { CartoonArticleList } from "./cartoonArticleList"
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"
import { MainArticleItem } from "./mainArticleItem"
import { BaseLinkTypes, LinkProperties, LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils"

interface CartoonArchiveState {
    currPage: number
    articles: ArticleExtraData[]
    isLoadMore: boolean
    selectedItemIndex: number
    isDateMenuVisible: boolean
    limitDate: Date
    isInitialLimitDate: boolean
}
interface CartoonArchiveProps {
    firstPageArticles: any
    componentaId: string
    promotionImage: string
    promotionNotes: string
    publishedLink: string
    promotionImageText: string
    promotionImageLinkTarget: LinkTargetProperties
    promotionImageLink: string
    isLTR: boolean
    numberOfVisibleItems: number
}


export class CartoonArchiveComponenta extends React.Component<CartoonArchiveProps, CartoonArchiveState>  {
    public static siteScriptName = "CartoonArchiveComponenta"
    constructor(props) {
        super(props)
        this.state = {
            currPage: 0,
            articles: props.firstPageArticles ? props.firstPageArticles : [],
            isLoadMore: props.firstPageArticles && (props.firstPageArticles.length / this.props.numberOfVisibleItems) >= 1,
            selectedItemIndex: 0,
            isDateMenuVisible: false,
            limitDate:new Date() ,
            isInitialLimitDate: true
        }
    }

    componentDidUpdate(prevProps: CartoonArchiveProps, prevState: CartoonArchiveState, prevContext: any): void {
        if (prevState.limitDate !== this.state.limitDate ||
            prevState.currPage !== this.state.currPage) {
            this.getArticles();
        }
        if (prevState.articles !== this.state.articles) {
            this.setState({
                isLoadMore: (this.state.articles.length) / ((this.state.currPage + 1) * this.props.numberOfVisibleItems) >= 1
            })
        }
    }
    private getArticles = () => {
        const { articles, currPage, limitDate } = this.state;
        const { componentaId } = this.props;
        const startDate = new Date('1992-04-01');
        const formatedStartDate = formatDate(startDate,"YYYY-MM-DD");
        const formatedEndDate = formatDate(limitDate,"YYYY-MM-DD");
        axios.get(`/iphone/json/api/article_list/${componentaId}/startDate/${formatedStartDate}/endDate/${formatedEndDate}/pageNumber/${currPage}`).then((res: any) => {
            const recivedArticles = res.data.data;
            this.setState({
                articles: currPage > 0 ? [...articles, ...recivedArticles] : recivedArticles,
            })
        }).catch(err => console.error("error geting articles data:", err))
    }
    public onSelectedItemIndexChange = (index) => {
        this.setState({ selectedItemIndex: index })
    }
    private toggleDateMenu = () => {
        this.setState({
            isDateMenuVisible: !this.state.isDateMenuVisible,
        })

    }
    private handleDayClick = (day, modifiers = {}) => {
        if (modifiers['disabled']) {
            return;
        } else {
            this.setState({
                limitDate: day ? day : '',
                currPage: 0
            });
            this.toggleDateMenu();
            if (this.state.isInitialLimitDate) {
                this.setState({ isInitialLimitDate: false })
            }
        }
    }
    private loadMoreArticles = () => {
        this.setState({ currPage: this.state.currPage + 1 })
    }
    public render() {
        const { articles, selectedItemIndex, isDateMenuVisible, limitDate, isLoadMore, isInitialLimitDate } = this.state
        const { promotionImage, promotionImageLink, isLTR, promotionImageLinkTarget, publishedLink } = this.props

        return (
            <div className="CartoonArchiveComponenta">
                <div className="componentTitle">קריקטוריסט</div>
                {articles.length > 0 &&
                    <MainArticleItem
                        article={articles[selectedItemIndex]}
                        toggleDateMenu={this.toggleDateMenu}
                        isDateMenuVisible={isDateMenuVisible}
                        handleDayClick={this.handleDayClick}
                        limitDate={limitDate}
                        isInitialLimitDate={isInitialLimitDate}
                        isLTR={isLTR}
                        publishedLink={publishedLink} />}
                {promotionImage &&
                    <div className="promotionImagewrapper">
                        <HyperLinkBuilder href={promotionImageLink} linkTarget={promotionImageLinkTarget}>
                            <SiteSimpleImage src={promotionImage} className="promotionImage" />
                        </HyperLinkBuilder>
                    </div>
                }
                {articles.length > 0 &&
                    <div className="cartoons">
                        <span className="cartoonTitle">כל הקריקטורות</span>
                        <CartoonArticleList itemList={articles} onSelectedItemIndexChange={this.onSelectedItemIndexChange} />
                    </div>}
                {isLoadMore && <div className="loadMore">
                    <button className="loadMoreButton" disabled={!isLoadMore || articles.length === 0}
                        onClick={this.loadMoreArticles}>טען עוד</button>
                </div>}

            </div>
        )
    }

}


