import * as map from "lodash/map";
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { TwentyFourSeven1280SlotItem } from "./twentyFourSeven1280SlotItem";
import { separateHourFromDateAndFormatToString } from "../../../../widgetsUtils";
import {DICTIONARY} from "./twentyFourSeven1280Componenta";

interface TwentyFourSeven1280SlotListProps {
    articles: ArticleExtraData[]
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    tabBackgroundColor: string
    tabColor: string
    isMobileWeb: boolean
    sitename: string
}

export class TwentyFourSeven1280SlotList extends React.Component<TwentyFourSeven1280SlotListProps, {}>  {

    private todayOrYesterday = (date: Date) => {
        const {sitename} = this.props;
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        if (date.toLocaleDateString() === today.toLocaleDateString()) return `(${DICTIONARY[sitename].today})`;
        if (date.toLocaleDateString() === yesterday.toLocaleDateString()) return `(${DICTIONARY[sitename].yesterday})`;
        return "";
    };
    
    private tabDateFormatter = (date: Date) => {
        const {sitename} = this.props;
        const weekDays = DICTIONARY[sitename].weekDays;
        const weekDay = weekDays[date.getDay()];
        return `${weekDay} ${separateHourFromDateAndFormatToString(date,{showHour: false})} ${this.todayOrYesterday(date)}`;
    };

    public render() {
        const { articles, tabBackgroundColor, tabColor, isMobileWeb, fieldsDisplaySettings } = this.props
        const mainTabStyle = {backgroundColor: tabBackgroundColor, color: tabColor, display: `${fieldsDisplaySettings.isTabVisible ? "flex" : "none"}`};
        let prevDate = null;

        const itemList = map(articles, (article: ArticleExtraData, i) => {
            let dateTab = null;

            const itemData = {
                articleId: article.articleId,
                author: article.author,
                credit: article.promotionImageDetails ? article.promotionImageDetails.credit : null,
                title: article.title,
                subTitle: article.subTitle,
                promotionImageDetails: article.promotionImageDetails,
                hasVideo: article.hasVideo,
                publishedLink: article.publishedLink,
                dateUpdatedOnSite: article.dateUpdatedOnSite,
                video: article.video,
                premiumExpireDays: article.premiumExpireDays,
                category: article.category,
                subchannelName:article.subchannelName
            }
            if (i === 0 || (prevDate && prevDate !== itemData.dateUpdatedOnSite.toLocaleDateString())) {
                dateTab = <div className="mainTab" style={mainTabStyle}>{this.tabDateFormatter(itemData.dateUpdatedOnSite)}</div>
            }
            prevDate = itemData.dateUpdatedOnSite.toLocaleDateString();

            return (
              <>  {dateTab}
                <div key={itemData.articleId}>  
                   
                    <TwentyFourSeven1280SlotItem
                        key={itemData.articleId}
                        article={itemData}
                        isMobileWeb={isMobileWeb}
                        {...this.props}
                    />
                </div></>
            )
        })

        return (
            <div className="slotList" >
                {itemList}
            </div>
        )
    }
}

