
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink";
import { isWcmEditor } from "../../../../../config/serverConfig";
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
require('./pplus_site_pplusHeader.less')

export interface PplusMenuItem {
    id: string
    name: string
    link: string
    catId: string
    targetLink?: LinkTargetProperties
}
interface pplusHeaderProps {
    menuItems: PplusMenuItem[]
    categoryId: string
}
interface SiteState {
    isSticky: boolean
}

export class PplusHeaderFront extends React.Component<pplusHeaderProps, SiteState> {
    constructor(props) {
        super(props)
        this.state = {
            isSticky: false
        }
    }

    public static siteScriptName = "PplusHeaderFront";

    private activateStickyHeader() {
        if (!isWcmEditor()) {
            const headerPos = document.querySelector(".pplus-header").getBoundingClientRect().top
            if (headerPos + 34 <= 0) {
                this.setState({ isSticky: true })
            } else {
                this.setState({ isSticky: false })
            }
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
        this.activateStickyHeader()
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }

    private throttledScrollHandler = () => throttle(() => this.activateStickyHeader(), 200)

    private currentCatName = (catId: string) => {
        const { categoryId } = this.props
        if (categoryId === catId)
            return "current"
        else return "";
    }

    render() {
        const { menuItems } = this.props
        const { isSticky } = this.state

        return (
            <div className={classNames("PplusHeader", { "sticky-header": isSticky })} id="PplusHeader">

                <SimpleLinkRenderer className="pplusLogo" href={"//pplus.ynet.co.il/home/0,7340,L-11211,00.html"}/>

                <nav className="linksWrapper" role="navigation" aria-label="Menu">
                    <ul>
                        {menuItems.map((item, index) => {
                            return (
                                <li className={`menuItem ${this.currentCatName(item.catId)}`} key={index} id={item.id} >
                                    <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                        {item.name}
                                    </HyperLinkBuilder>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                <div className="stick-left-wrapper">
                    <div className="pplusSocial">
                        <SimpleLinkRenderer href="//www.instagram.com/pnaiplus" target="_blank" className="socialBtn pplus_instagram" />
                        <SimpleLinkRenderer href="//www.facebook.com/pnai.plus" target="_blank" className="socialBtn pplus_fb" />
                        <SimpleLinkRenderer href="//www.tiktok.com/@pnai_plus" target="_blank" className="socialBtn pplus_tiktok" />
                    </div>
                    <SimpleLinkRenderer className="ynetLogo" href="//www.ynet.co.il/home/0,7340,L-8,00.html" />
                </div>
            </div>
        )
    }
}