export type SiteLanguage = "he" | "en" | "es"

const dict = {
    "ru": {
        "Sandstorm, Duststorm, Sand, Dust": "Песчаная буря, Пыльная буря, Песок, Пыль",
        "Thunderstorm, Thundershowers, Storm": "Буря с грозами и молниями",
        "Hall": "Град",
        "Blowing Snoe, Blizzard, Snowdrift, Snow storm": "Метель",
        "Snow, Showers, Flurries": "Снег, ливни, вьюга",
        "Snow, Heavy Snow, Snowfall": "Снег, Снегопад",
        "Light Snow": "Легкий снег",
        "Sleet": "Мокрый снег",
        "Showers, Heavy Showers": "Ливни, тяжелые ливни",
        "Occasional Showers, Scattered, Scattered Showers": "Возможны дожди, моросящий дождь",
        "Isolated Showers": "Местами дождь ",
        "Light Showers": "Легкие дожди",
        "Freezing Rain": "Ледяной дождь",
        "Rain": "Дождь",
        "Drizzle, Light Rain": "Морось, легкий дождь",
        "Fog": "Туман",
        "Mist": "Легкий туман",
        "Smoke": "Дымка",
        "Haze": "Мгла",
        "Overcast": "Пасмурная погода",
        "Sunny Interval, No Rain, Clearing": "Временами солнечно, без дождя, с прояснениями",
        "Sunny Period, Partly Cloudy, Partly Bright Mild": "Переменная облачность",
        "Cloudy, Mostly Cloudy": "Облачно, преимущественно облачно",
        "Bright, Sunny, Fair": "Солнечно",
        "Fine, Clear": "Ясно",
        "Windy, Squall, Stormy, Gale": "Ветрено, Шквал,  Буря",
        "Wet, Humid": "Влажно",
        "Dry": "Сухо",
        "Freezing": "Заморозки",
        "Frost": "Мороз",
        "Hot": "Жарко",
        "Cold": "Холодно",
        "Warming": "Потепление",
        "Cooling": "Похолодание",
        "Stormy": "Шторм",
        "Heavy Snow": "Снегопад",
        "Partly cloudy, possible rain": "Переменная облачность, возможен дождь",
        "Cloudy, possible rain": "Облачно, возможен дождь",
        "Cloudy, light rain": "Облачно, небольшой дождь",
        "Dust": "Мгла",
        "Extremely hot": "Очень жарко",
        "Extremely cold": "Очень холодно",
    },
    "he": {
        "Sandstorm, Duststorm, Sand, Dust": "סופות חול, סופות אבק",
        "Thunderstorm, Thundershowers, Storm": "סופת רעמים וברקים",
        "Hall": "ברד",
        "Blowing Snoe, Blizzard, Snowdrift, Snow storm": "סופת שלג",
        "Snow, Showers, Flurries": "תזזיות שלג",
        "Snow, Heavy Snow, Snowfall": "שלג, שלג כבד",
        "Light Snow": "שלג קל",
        "Sleet": "גשם מעורב בשלב",
        "Showers, Heavy Showers": "ממטרים, ממטרים כבדים",
        "Occasional Showers, Scattered, Scattered Showers": "ממטרים פזורים, ממטרים לסרוגין",
        "Isolated Showers": "ממטרים מקומיים",
        "Light Showers": "ממטרים קלים",
        "Freezing Rain": "גשם קופא",
        "Rain": "גשם",
        "Drizzle, Light Rain": "רסס",
        "Fog": "ערפל",
        "Mist": "ערפל קל",
        "Smoke": "עשן",
        "Haze": "אובך",
        "Overcast": "כיסוי מלא בעננים",
        "Sunny Interval, No Rain, Clearing": "בדרך כלל מעונן",
        "Sunny Period, Partly Cloudy, Partly Bright Mild": "מעונן חלקית",
        "Cloudy, Mostly Cloudy": "מעונן",
        "Bright, Sunny, Fair": "נאה",
        "Fine, Clear": "בהיר, צח",
        "Windy, Squall, Stormy, Gale": "רוחות ערות, רוחות סערה",
        "Wet, Humid": "לח",
        "Dry": "יבש",
        "Freezing": "קפיאה",
        "Frost": "קרה",
        "Hot": "חם מאוד",
        "Cold": "קרה",
        "Warming": "התחממות",
        "Cooling": "התקררות",
        "Stormy": "סוער",
        "Heavy Snow": "שלג כבד",
        "Partly cloudy, possible rain": "מעונן חלקית, אפשרות לגשם",
        "Cloudy, possible rain": "מעונן, אפשרות לגשם",
        "Cloudy, light rain": "מעונן, גשם קל",
        "Dust": "אביך",
        "Extremely hot": "חם מאוד",
        "Extremely cold": "קר מאוד",
    }
}

const translate = (language) => {
    const lang = language
    return (phrase) => {
        if (language in dict) {
            return dict[lang][phrase] || phrase
        } else {
            return phrase
        }
    }
}


export default (language) => {
    const t_ui = translate(language)
    return (weatherCode) => {
        const defaultCode = 1250
        const weatherCodeDesc = {
            1010: "Sandstorm, Duststorm, Sand, Dust",
            1020: "Thunderstorm, Thundershowers, Storm",
            1030: "Hall",
            1040: "Blowing Snoe, Blizzard, Snowdrift, Snow storm",
            1050: "Snow, Showers, Flurries",
            1060: "Snow, Heavy Snow, Snowfall",
            1070: "Light Snow",
            1080: "Sleet",
            1090: "Showers, Heavy Showers",
            1100: "Occasional Showers, Scattered, Scattered Showers",
            1110: "Isolated Showers",
            1120: "Light Showers",
            1130: "Freezing Rain",
            1140: "Rain",
            1150: "Drizzle, Light Rain",
            1160: "Fog",
            1170: "Mist",
            1180: "Smoke",
            1190: "Haze",
            1200: "Overcast",
            1210: "Sunny Interval, No Rain, Clearing",
            1220: "Sunny Period, Partly Cloudy, Partly Bright Mild",
            1230: "Cloudy, Mostly Cloudy",
            1240: "Bright, Sunny, Fair",
            1250: "Fine, Clear",
            1260: "Windy, Squall, Stormy, Gale",
            1270: "Wet, Humid",
            1280: "Dry",
            1290: "Freezing",
            1300: "Frost",
            1310: "Hot",
            1320: "Cold",
            1330: "Warming",
            1340: "Cooling",
            1510: "Stormy",
            1520: "Heavy Snow",
            1530: "Partly cloudy, possible rain",
            1540: "Cloudy, possible rain",
            1560: "Cloudy, light rain",
            1570: "Dust",
            1580: "Extremely hot",
            1590: "Extremely cold",
        }
        let desc = ""

        if (weatherCode in weatherCodeDesc) {
            desc = weatherCodeDesc[weatherCode]
        } else {
            desc = weatherCodeDesc[defaultCode]
        }

        return t_ui(desc)
    }
}
//import translate from "./tanslations/uiTranslations"
//const lang = "he"
//const t_ui = translate(lang)
//<div> t_ui("last update") </div>

