import { ArticleCommentsTopTabRenderProps } from "./articleCommentsTopTabWrapper"
import { getTranslation } from "./translations"
require("./pplus_magazine_ynet_site_grid1280ArticleCommentsTopTabUI.less")

interface ArticleCommentsTopTabUIProps {
    props: ArticleCommentsTopTabRenderProps
}

export class Grid1280ArticleCommentsTopTabUI extends React.Component<ArticleCommentsTopTabUIProps, {}>{
    private handleOnAddCommentBtnPress = (e) => {
        const { handleFormVisibility } = this.props.props
        handleFormVisibility()
    }

    render() {
        const { lang, isLtr, numOfComments, numOfDiscussions, sortSelection, isAddCommentFormVisible, handleSortSelection } = this.props.props
        let commentNumStyle = isLtr ? { marginRight: "5px" } : {}

        return (
            <div className="Grid1280ArticleCommentsTopTabUI">
                <div className="tab">
                    {numOfComments === 1 &&
                        <div className="tabTextWraper">
                            <span>{getTranslation(lang, "This Article Has One Comment")}</span>
                        </div>}

                    {numOfComments !== 1 &&
                        <div className="tabTextWraper">
                            <span>{getTranslation(lang, "This Article Has ")} </span>
                            <span style={commentNumStyle}>{` ${numOfComments} `} </span>
                            <span>{`${getTranslation(lang, "Comments In")}`}</span>
                            <span>{!isLtr && ` ${numOfDiscussions} `}</span>
                            <span>{!isLtr && getTranslation(lang, "Discussions")}</span>
                        </div>}
                </div>

                <div className="topPanel">
                    <button
                        key="newCommentBtn"
                        className="newCommentBtn"
                        title={getTranslation(lang, "New Comment")}
                        disabled={isAddCommentFormVisible}
                        onClick={this.handleOnAddCommentBtnPress}>
                        <span>{getTranslation(lang, "New Comment(premium)")}</span>
                    </button>
                    {numOfComments > 0 &&
                        <div className="sortOptions">
                            <span className="selectLabel">{`${getTranslation(lang, "Sort Comments By")}`}</span>
                            <div className="togglerWrapper"  >
                            <div className="toggler" />
                            </div>
                            <select className="sortSelect" defaultValue={sortSelection} onChange={handleSortSelection}>
                                <option value="end_to_start">{getTranslation(lang, "End To Start")}</option>
                                {numOfComments > 1 && <option value="start_to_end">{getTranslation(lang, "Start To End")}</option>}
                                {numOfComments > 2 && <option value="recommended">{getTranslation(lang, "Recommended")}</option>}
                                {numOfComments > 2 && <option value="interest">{getTranslation(lang, "Interest")}</option>}
                            </select>
                        </div>}
                </div>
            </div >
        )
    }
}