import { AutoFeedBlogItem } from "./blogsAutoFeedComponent"
import { BlogAutoFeedItem } from "./blogAutoFeedItem";

interface BlogAutoFeedItemsContainerProps {
    headerLinkUrl: string
    feedTitleText: string
    items: AutoFeedBlogItem[]
    isReverseOrder: boolean
    setReverseOrder: (value: boolean) => void
}

interface BlogAutoFeedItemsContainerState {
    limitIndex: number
}

const CHUNCK_SIZE = 5
const SEQUENCE_FROM = 1

export class BlogAutoFeedItemsContainer extends React.Component<BlogAutoFeedItemsContainerProps, BlogAutoFeedItemsContainerState> {
    constructor(props) {
        super(props)
        this.state = {
            limitIndex: CHUNCK_SIZE - 1
        }
    }

    //@ts-ignore
    private intersectionObserver: IntersectionObserver;

    componentDidMount() {
        this.initObserver()
    }

    componentDidUpdate(prevProps) {
        if (this.props.items.length !== prevProps.items.length) {
            this.initObserver()
        }
    }

    componentWillUnmount() {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
        }
    }

    private initObserver = () => {
        const { items } = this.props
        if (!items.length) {
            return
        }
        if (IntersectionObserver) {
            this.intersectionObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    this.onNextItemsLoad(entry.isIntersecting)
                })
            })
        }
        this.getObserverTargetIndex()
    }

    private getObserverTargetIndex = () => {
        const { items } = this.props
        const { limitIndex } = this.state

        if (items.length > limitIndex) {
            this.intersectionObserver.observe(
                document.querySelectorAll(".blogs-item-wrapper")[limitIndex - SEQUENCE_FROM]
            );
        }
    }

    private reverseOrder = (reversed: boolean) => {
        const { setReverseOrder } = this.props;
        setReverseOrder(reversed)
    }

    private onNextItemsLoad = (isIntersecting: boolean) => {
        const { limitIndex } = this.state
        if (isIntersecting) {
            this.setState({ limitIndex: limitIndex + CHUNCK_SIZE }, () => {
                this.initObserver()
            })
        }
    }

    public render() {
        const { items, headerLinkUrl, feedTitleText, isReverseOrder } = this.props
        const { limitIndex: startIndex } = this.state
        return (
            <>
                <div style={{ display: "none" }} dangerouslySetInnerHTML={{
                    __html: `<script async onLoad="()=>twttr.widgets.load()" src="//platform.twitter.com/widgets.js" charSet="utf-8" > </script>
                <script async onLoad="()=> instgrm.Embeds.process()" src="//platform.instagram.com/en_US/embeds.js" ></script>`}}
                />
                <div className="blogs-auto-feed-header-wrapper">
                    <div className="blogs-auto-feed-header"><a href={headerLinkUrl}>{feedTitleText}</a></div>
                    <div className="orderCheckBoxes">
                        <div><input className="regularOrder" type="radio" checked={!isReverseOrder} onClick={() => this.reverseOrder(false)} /><div>הצג מהחדש לישן</div></div>
                        <div><input className="regularOrder" type="radio" checked={isReverseOrder} onClick={() => this.reverseOrder(true)} /><div>הצג מהישן לחדש</div></div>
                    </div>
                </div>
                <div className="blogs-auto-feed-items-container">

                    {!!items.length && items.filter((item, index) => index < startIndex).map((item, index) => (
                        <BlogAutoFeedItem
                            item={item}
                            key={item.id}
                            title={item.title}
                            index = {index}
                        />))
                    }
                </div>
            </>
        )
    }
}










