import { MediaContentTypes } from "../../../../../constants/contentTypes"
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { SiteMediaData, SiteImageData } from "../../../../interfaces"
import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu";
import { SlotIconDisplay } from "../../../../commonComponents/inline/slotIconDisplay/slotIconDisplay";
import { calculateShouldAddArticleAutoplayIndicator } from "widgets/widgetsUtils"
import classNames = require("classnames")
import { convertHtmlToString } from "../../../../../config/utils";
import { ComponentaIconType } from "widgets/commonDataObjects/interfaces"

require("./ctech_calcalist_site_calcalistSpecialContentItemFront.less")

interface CalcalistSpecialContentItemFrontProps {
    mediaWidth: number
    mediaHeight: number
    itemData: any
    videoSettings: LinkedItemVideoSettings
    marginLeft: number
    titleTextColor: string
    isLTR?: boolean
}

export class CalcalistSpecialContentItemFront extends React.Component<CalcalistSpecialContentItemFrontProps, {}>{
  
    render() {
        const { titleTextColor, marginLeft, videoSettings, itemData, mediaWidth, mediaHeight, isLTR } = this.props

        const shouldAddArticleAutoplayIndicator = calculateShouldAddArticleAutoplayIndicator(itemData.icon, itemData.media);
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        let iconType:ComponentaIconType = "none";
        if(itemData.hasVideo){
            iconType = "video";
        }
        if(itemData.articleType === "audio"){
            iconType = "audio";
        }
        return (
            <div className={classNames("CalcalistSpecialContentItemFront", { "Ctech": isLTR })} style={{ width: mediaWidth, marginLeft }}>
                <div className="mediaArea CalcalistComponentaShareMenuMedia">
                    <div className="MediaCarousel" style={{ width: mediaWidth, height: mediaHeight }}>
                        <SiteMedia
                            allMedias={[]}
                            data={itemData.media !== undefined ? itemData.media : emptyMedia}
                            width={mediaWidth}
                            height={mediaHeight}
                            videoSettings={videoSettings}
                            itemLinkUrl={itemData.titleLink}
                            isImageLinkable={true}
                            linkTarget={itemData.titleTargetLink !== undefined && itemData.titleTargetLink}
                            shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator}
                        />
                        <SlotIconDisplay itemId={itemData.itemId} icon={iconType} iconClassName="medium" isIconVisible={iconType!=="none"} />
                    </div>
                    <CalcalistShareMenu url={itemData.titleLink} isArticle={itemData.linkType === "ARTICLE"} title={convertHtmlToString(itemData.title)} />
                </div>
                <div className="titleWrapper" style={{ width: mediaWidth }}>
                    <HyperLinkBuilder
                        href={itemData.titleLink}
                        linkTarget={itemData.tabLinkTarget !== undefined && itemData.tabLinkTarget}
                        isDataTbLink>
                        <div style={{ color: titleTextColor }} dangerouslySetInnerHTML={{ __html: itemData.title }} />
                    </HyperLinkBuilder>
                </div>
            </div>
        )
    }
}