import * as axios from "axios";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { YnetNewsSlotItem } from "./ynetNewsSlotItem";

export interface IYnetnewsArticle {
    title: string
    sub_title: string
    publishedLink: string
    date:string
}

interface YnetNewsComponentaState {
    articles: IYnetnewsArticle[]
}

export class YnetNewsComponenta extends React.Component<{}, YnetNewsComponentaState> {
    public static siteScriptName = "YnetNewsComponenta"
    constructor(props) {
        super(props)
        this.state = {
            articles: null,
        }
    }

    componentDidMount() {
        this.getArticles();
    }
    private getArticles = async () => {
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());

        try {
            const response = await axiosInstance.get(`https://www.ynetnews.com/iphone/json/mobile/page/3083`);
            //@ts-ignore
            const topStoryArticle = response.data.components.find((component) => component.name === "TopStory");
            //@ts-ignore
            const stripsArticles = response.data.components.filter((component) => component.name === "Strip").slice(0, 3);
            const mergedArticles = [topStoryArticle, ...stripsArticles].map((article) => {
                return {
                    title: article.title,
                    sub_title: article.sub_title,
                    publishedLink: article.link_data.href,
                    date:article.link_data.article_data.pub_date
                }as IYnetnewsArticle
            })
            this.setState({ articles: mergedArticles });

        } catch (error) {
            console.error("Error getting headline data:", error);
        }
    }

    public render() {
        const { articles } = this.state;
        
        return (
            <div className="slotContentDiv">
                <div className="slotList">
                    {articles ? articles.map((article) => (
                        <YnetNewsSlotItem slotItem={article} />
                    )) :
                        <span className="loader"></span>
                    }
                </div>
            </div>
        )
    }
}