require('./ynet_site_newYnetWeather.less')
import { SimpleLinkRenderer } from "../../../../commonComponents/inline/hyperLink"
import * as classNames from "classnames"
import * as axios from "axios"
import weatherCodeTranslate from '../../../weather/translations/weatherCodeTranslations'
import { getCityNameTranslation } from "../../../ynetSearch/components/ynetWeather/cityNamesTranslator";
var Cookies = require('js-cookie');
const clickOutside = require('react-click-outside');

interface YnetWeatherState {
    selectedCityIndex: number
    isMenuOpened: boolean
    weatherDataList: WeatherData[]
}

export interface WeatherData {
    IconCode: number
    Id: number
    Latitude: number
    Longitude: number
    Name: string
    RoundedTemperature: string
    Url: string
}

interface YnetWeatherProps {
    placement?: string
    lang: string
    showDescription?: boolean
}


@clickOutside
export class YnetWeatherInHeader extends React.Component<YnetWeatherProps, YnetWeatherState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedCityIndex: null,
            isMenuOpened: false,
            weatherDataList: null
        }

    }
    private handleClickOutside = () => {
        this.setState({
            isMenuOpened: false
        });
    }

    componentDidMount() {
        this.getWeatherData();
    }

    private getWeatherData = async () => {
        let defaultCityIndex = await Cookies.get('weatherCity')
        const { data: { data: { items } } } = await axios.get('/weather/api/data/ynetapp');

        if (!defaultCityIndex && items) {
            let defaultCity =  items.find(city => city.Name === "תל אביב - יפו");
            defaultCityIndex = items.indexOf(defaultCity);
        }
        this.setState({
            weatherDataList: items,
            selectedCityIndex: defaultCityIndex
        });
    }

    private updateTemperature = (i) => {
        this.setState({ selectedCityIndex: i }, () => {
            Cookies.set('weatherCity', i, { expires: 365 });
        })
        this.toggleMenu()
    }
    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }
    public render() {
        const { placement, lang, showDescription } = this.props
        const { selectedCityIndex, isMenuOpened, weatherDataList } = this.state
        const selectedCityWeather: WeatherData = weatherDataList && weatherDataList[selectedCityIndex];
        const imgSrc = `//www.ynet.co.il/Cnt/Images/Weather/${selectedCityWeather && selectedCityWeather.IconCode}.png`;
        const weatherPageUrl = selectedCityWeather ? selectedCityWeather.Url : "//www.ynet.co.il/home/0,7340,L-201,00.html";
        const description = weatherCodeTranslate(lang)
        const descriptionByCity = description(selectedCityWeather && selectedCityWeather.IconCode)

        return (
            <>
                <div className={classNames("WeatherViewInHeader", placement)} id={placement ? placement : "main_header_weather"}>
                    <div className="weatherimage">
                        {selectedCityWeather &&
                            <SimpleLinkRenderer href={weatherPageUrl} aria-label="מזג האוויר">
                                <img src={imgSrc} aria-hidden="true" />
                            </SimpleLinkRenderer>}
                    </div>
                    <div className="weathertempsdiv">
                        {selectedCityWeather &&
                            <SimpleLinkRenderer className="weatherLink" href={weatherPageUrl}>
                                <span className="weathertemps">{selectedCityWeather.RoundedTemperature}&deg;</span>
                            </SimpleLinkRenderer>}
                    </div>

                    <div id={placement ? `${placement}_weatherselect` : `headerWeatherSelect`} className="headerWeatherSelect">
                        <SimpleLinkRenderer href={weatherPageUrl} aria-label="מזג האוויר" className="selectedCity" >{selectedCityWeather ? getCityNameTranslation(lang, selectedCityWeather && selectedCityWeather.Name) : selectedCityWeather && selectedCityWeather.Name}</SimpleLinkRenderer>
                        <div className={isMenuOpened ? " menuToggler toClose" : "menuToggler toOpen"} onClick={this.toggleMenu} />
                        {isMenuOpened && <div id='weatherCitySelect' aria-label="בחרו עיר" className="weatherCitieSelect">
                            {weatherDataList && weatherDataList.map((city, i) => <div key={city.Name} onClick={() => this.updateTemperature(i)}>{selectedCityWeather ? getCityNameTranslation(lang, city.Name) : city.Name}</div>)}
                        </div>}
                    </div>
                </div>
                <div className="descriptionWeather">
                    {showDescription && descriptionByCity}
                </div>
            </>
        )
    }
}