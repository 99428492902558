import { ArticleCommentsTopTabRenderProps } from "../articleCommentsTopTabWrapper"
import { getTranslation } from "../translations"
import { getTalkbackNumberView } from "../../articleSocialShare1280Component/articleSocialShareNew1280";

require("./pplus_ynet_site_articleCommentsTopTabUI1280.less")

interface ArticleCommentsTopTabUI1280rops {
    props: ArticleCommentsTopTabRenderProps
}

export class ArticleCommentsTopTabUI1280 extends React.Component<ArticleCommentsTopTabUI1280rops, {}>{
    private handleOnAddCommentBtnPress = (e) => {
        const { handleFormVisibility } = this.props.props
        handleFormVisibility()
    }

    render() {
        const { lang, isLtr, numOfComments, sortSelection, isAddCommentFormVisible,
            handleSortSelection } = this.props.props
        return (
            <div className="ArticleCommentsTopTabUI1280">

                <div className="tabTitle">
                    <span className="tbIcon">{getTalkbackNumberView(numOfComments.toString())}</span>
                    <span>{getTranslation(lang, "Comments")}</span></div>
                <div className="tabPanel">
                    {!isAddCommentFormVisible && <button
                        key="newCommentBtn"
                        className="newCommentBtn"
                        title={getTranslation(lang, "New Comment")}
                        disabled={isAddCommentFormVisible}
                        onClick={this.handleOnAddCommentBtnPress}>
                        <span>{getTranslation(lang, "New Comment(premium)")}</span>
                    </button>}
                </div>
            </div >
        )
    }
}