import { SiteMedia, SiteMediaProps } from "./siteMedia";


export class SiteMediaMobileWrapper extends React.Component<SiteMediaProps>{
    public static siteScriptName = "SiteMediaMobileWrapper";
    
    public render() {

        return (
            <SiteMedia {...this.props} />
        )
    }
}
