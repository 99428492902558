import * as classNames from "classnames"
import * as map from "lodash/map"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { BaseLinkTypes, LinkProperties, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { getNewlinkTargetProperties } from "widgets/widgetsUtils"
import { CitiesMenuComponenta } from "./citiesMenu";
import { MynetSearchForm } from "./myNetSearchInput";
import { CSSTransitionGroup } from "react-transition-group"


export interface SiteList {
    name: string
    link: string
    linkTarget: LinkTargetProperties
    url?: string
    subSite?: string
    mainSiteSubNav: SubNavItem[]
}
export interface SubNavItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
}
interface MenuProps {
    mainSite: SiteList
    siteList: SiteList[]
    onClose: () => void
    isCitiesOpened: boolean
    onCloseCities: () => void
    onOpenCities: () => void
    isOpen: boolean
    isIndependedHeader?: boolean
    changingFieldText: string
    changingFieldLink: LinkProperties<BaseLinkTypes>
    changingFieldLinkTarget: LinkTargetProperties
    changingFieldColor: string
}

export class MynetMenuComponenta extends React.Component<MenuProps, {}> {


    private analyticsMynetAccessibilityPushFunc = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'accessibility',
                Category: 'Accessibility',
                Action: 'Accessibility Clicked'
            });
        }

    }
    private analyticsMynetRedMailPushFunc = () => {

        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'contact_us',
                Category: 'Contact Us',
                Action: 'red_mail'
            });
        }

    }

    render() {
        const { mainSite, siteList, onClose, isCitiesOpened, onCloseCities, onOpenCities, isOpen, isIndependedHeader,
            changingFieldText, changingFieldLink, changingFieldLinkTarget, changingFieldColor } = this.props
        let menuClassName = classNames("MobileMenu", { "menuOpen": isOpen === true, "menuClose": isOpen === false })
        const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;
        return (
            <div className={menuClassName} id="menu">
                <div className="mainHeader">
                    <button className="menuClsBtn btn" title="פתיחת תפריט" onClick={onClose} />
                    <div className="logo">
                        <HyperLinkBuilder href={mainSite.link} ariaLabel={mainSite.name} linkTarget={mainSite.linkTarget}>
                            <SiteSimpleImage src={mainSite.url} alt={mainSite.name} title={mainSite.name} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>
                    </div>
                </div>
                <MynetSearchForm />
                <ul className="MynetNavList" >
                    {map(mainSite.mainSiteSubNav, (item, i) => {
                        return (
                            <li key={i} className={classNames("menuItem")}>
                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                </HyperLinkBuilder>
                            </li>

                        )
                    }
                    )}
                    {!isIndependedHeader && <li className={classNames("menuItem")}>
                        <button className="openCities" onClick={onOpenCities}>
                            עבור עיר
                        </button>
                    </li>}
                    {changingFieldText && <li className={classNames("menuItem")}>
                        <HyperLinkBuilder href={changingFieldLink.link.publishedLink} linkTarget={changingFieldLinkTarget} className="usefulLink changingField">
                            {changingFieldText}
                        </HyperLinkBuilder>
                    </li>}
                    <li className={classNames("menuItem")}>
                        <a onMouseUp={this.analyticsMynetRedMailPushFunc} href="mailto:mynet@yedtik.co.il" className="usefulLink redMail">
                            המייל האדום
                        </a>
                    </li>
                    {isApp && <li className={classNames("menuItem")}>
                        <a href="open_push_options" className="usefulLink push-pf">
                            התראות
                        </a>
                    </li>}
                    <li className={classNames("menuItem")} style={{ position: "relative" }}>
                        <a id="INDmenu-btn3"
                            onMouseUp={this.analyticsMynetAccessibilityPushFunc}
                            className="follow"
                            tabIndex={0}
                            style={{ fontFamily: "Arimo !important", top: "unset", left: "unset" }}
                            accessKey="m" aria-labelledby="INDbtnTooltip" >
                            <img src="https://www.ynet.co.il/images/nagishot.png" style={{ width: "21px", height: "21px", marginLeft: "10px" }} />
                            נגישות</a>
                    </li>
                </ul>
                {!isIndependedHeader && <CSSTransitionGroup transitionName="subMenuApperiance" transitionEnterTimeout={500} transitionLeave={true} transitionLeaveTimeout={500}>
                    {isCitiesOpened && <CitiesMenuComponenta mainSite={mainSite} siteList={siteList} onClose={onCloseCities} />}
                </CSSTransitionGroup>}
            </div>
        )

    }
}