import { formatDateDisplay } from "../../../siteWidgets/formatDateDisplay";
import { SiteSettingsHOC } from "../../../../global/components/siteSettingsHOC";
require('./site_dateDisplay.less')


interface DateDisplayProps {
    date: Date | string
    isArticle?: boolean
    isTbDate?: boolean
    isMinutesAgo?:boolean
    hiddenYesterday?:boolean
}
@SiteSettingsHOC()
export class DateDisplay extends React.Component<DateDisplayProps, {}> {
    public render() {
        const { date, isArticle, isTbDate, isMinutesAgo, hiddenYesterday } = this.props;
        const isArtceStr = isArticle ? "1" : "0"
        let datePlaceHolder;
        if (RUNNING_IN_SERVER) {
            datePlaceHolder = <time className="DateDisplay" dateTime={getDataWcmDate(date)} data-wcmdate={getDataWcmDate(date)} data-wcm-is-article-date={isArtceStr} data-tb-date={isTbDate && ''} ></time>
        }
        else {
            datePlaceHolder = <time className="DateDisplay" dateTime={getDataWcmDate(date)} data-tb-date={isTbDate && ''}>{formatDateDisplay(getDataWcmDate(date), isArtceStr, isMinutesAgo, hiddenYesterday)}</time>
        }
        return (datePlaceHolder)
    }
}

function getDataWcmDate(date: string | Date) {
    if (!date) return undefined;
    if (typeof date === "string") {
        return date;
    }
    try {
        return date.toJSON();
    } catch{
        if (WCM_FEATURES_FLAGS.THROW_ON_UNKOWN_DATE_TYPE) {
            throw `Uknown date type: ${date.toString()}`;
        } else {
            return undefined;
        }
    }
}