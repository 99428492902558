import * as map from "lodash/map"
import * as filter from "lodash/filter"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { TickerViewData } from "../../../tickerAuto/interfaces"
import { SiteTickerData } from "../../../ticker/components/tickerComponenta";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteTickerPopUp } from "../../../ticker/components/tickerPopUp";
import { TickerList } from "./flashList";

require("./ynet_site_tickerWar.less")
require("../../../ticker/components/site_tickerComponenta.less")


interface TickerAutoViewProps {
    data: TickerViewData
    articles: any
}


export class TickerWarView extends React.Component<TickerAutoViewProps, {}>  {

    public render() {
        const { data, articles } = this.props
        const mainStyleBg = { backgroundColor: "#000000" }
        const targetTitleLink: LinkTargetProperties = {
            linkOpenType: "new",
            lightBoxBgColor: "",
            lightBoxHeight: null,
            lightBoxOpacity: null,
            lightBoxWidth: 0
        }
        let itemList = [];

        if (Object.keys(articles).length > 0) {
            itemList = filter(map(articles, (article: any, index) => {
                if (index > data.numberOfVisibleItems - 1) return null
                let image = { url: '' }

                return {
                    articlePublishDate: article.launchDate,
                    articleUpdateDate: article.dateUpdatedOnSite,
                    slotId: article.articleId,
                    title: article.title,
                    flashText: article.plainText,
                    flashDate: article.dateUpdatedOnSite,
                    flashDateType: "update",
                    link: article.publishedLink,
                    linkTarget: targetTitleLink,
                    flashLinkType: "flash",
                    itemImg: article.promotionImageDetails,
                    articleId: article.articleId,
                    author: article.author,
                } as SiteTickerData

            }))
        }
        return (
            <div className={`TickerWarComponenta`} style={mainStyleBg}>
                {data.fieldsDisplaySettings.isTabVisible &&
                    <div className="tickerTab">
                        <HyperLinkBuilder href={data.tabHref} linkTarget={data.tabTitleLinkTarget}>
                            {data.tabTitleText}
                        </HyperLinkBuilder>
                    </div>
                }
                <div className="slotsContent" >

                    <TickerList siteFlashList={itemList} isAuto={true} />
                    <SiteTickerPopUp items={itemList} popUpTitle={data.popUpTitle} popUpTitleColor={data.popUpTitleColor} isAuto={true} />
                </div>
            </div>
        )
    }
} 