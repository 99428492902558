import { SimpleLinkRenderer } from "../../../../commonComponents/inline/hyperLink"


interface ynetShopsProps {
    items: any[]
    width: number
}

interface ynetShopsState {
    rightOffset: number
    selectedIndex: number
}
export class YnetShopsGallery extends React.Component<ynetShopsProps, ynetShopsState> {
    public static siteScriptName = "YnetShopsGallery";
    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: 0,
            rightOffset: 0,
        }
    }
    private numberOfImagesToView = (width, imageWidth) => {
        const itemWidth = imageWidth + 20
        const numberOfImagesToView = Math.floor(width / itemWidth)
        return numberOfImagesToView;
    }

    private handleScrollLeftClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { items, width } = this.props
        const single_media_item_width = 202
        let newIndex = selectedIndex + 1;
        const scrollBarWidth = width + 20
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - this.numberOfImagesToView(scrollBarWidth, 182) * single_media_item_width)
        const newRightOffset = rightOffset - single_media_item_width
        const lastNewOffset = rightOffset - lastScrollingsize
        if (selectedIndex < items.length - this.numberOfImagesToView(scrollBarWidth, 182) - 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === items.length - this.numberOfImagesToView(scrollBarWidth, 182) - 1) {
            this.setState({
                rightOffset: lastNewOffset,
                selectedIndex: newIndex
            })
        }

    }

    private handleScrollRightClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { items, width } = this.props
        const scrollBarWidth = width + 20
        const single_media_item_width = 202
        let newIndex = selectedIndex - 1
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - this.numberOfImagesToView(scrollBarWidth, 182) * single_media_item_width)
        const firstOffset = rightOffset + lastScrollingsize
        const newRightOffset = rightOffset + single_media_item_width

        if (selectedIndex > 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === 1) {
            this.setState({
                rightOffset: 0,
                selectedIndex: 0
            })
        }
    }

    render() {
        const { items, width } = this.props
        const { selectedIndex, rightOffset } = this.state
        let isRightArrowAvailble = selectedIndex !== 0
        let isLeftArrowAvailble = selectedIndex !== items.length - this.numberOfImagesToView(width + 20, 182)

        return (
            <div className="slotsContent">
                {items.length > 6 && <button
                    disabled={!isRightArrowAvailble}
                    className="scrollRightBtn slotListArrow"
                    aria-label="scroll right"
                    title="scroll right"
                    onClick={(e) => this.handleScrollRightClicked()} />}
                <div className="frame_forList">
                    <div className="slotListWrapper" style={{ "marginRight": rightOffset }} >
                        {items.map((item, index) =>
                            <div className="slotView" key={`slotItem_${index}`} >

                                <SimpleLinkRenderer href={item.url} target="_blank">
                                    <img src={item.image} title={item.title} />
                                    <div className="titleItem">
                                        {item.title}
                                    </div>
                                    <div className="price">
                                        {item.price_surfix} {item.price} ₪
                            </div>
                                </SimpleLinkRenderer>
                            </div>)}</div>
                </div>
                {items.length > 6 && <button
                    disabled={!isLeftArrowAvailble}
                    title="scroll left"
                    aria-label="scroll left"
                    className="scrollLeftBtn slotListArrow"
                    onClick={(e) => this.handleScrollLeftClicked()} />}


            </div>

        )

    }
}
