import { GalleryItems } from "./sitePhotoArchiveComponenta";
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage";
import { ImageSize } from "../../../../../constants/imageSizes";
import { getImgTitle } from "../../../../widgetsUtils";

interface PhotoArchiveArticleItemProps {
    item: GalleryItems
}
export class PhotoArchiveSlotItem extends React.Component<PhotoArchiveArticleItemProps, {}>{
    static defaultProps = { isCategoryVisible: true }


    public render() {
        const { item } = this.props;
        const { title, publishedLink, id, promotionImageDetails, credit, dateUpdatedOnSite } = item

        return (
            <div className="slotItem" key={id} >
                <div className="mediaArea">
                    <a href={publishedLink} title={title}>
                        <img src={getFrontImageUrl(promotionImageDetails, { imageSize: ImageSize.xxLarge })}  className="SiteImageMedia" alt={title} title={getImgTitle(promotionImageDetails)}/>
                    </a>
                    <CalcalistShareMenu url={publishedLink} title={title} isLTR={true} isArticle={true} />
                </div>

                <a href={publishedLink} className="textDiv" >
                    <div className="moreDetails">
                        <span className="dateView">
                            <DateDisplay date={dateUpdatedOnSite} />
                        </span>
                    </div>
                    <div className="slotTitle">
                        <span>{title}</span>
                    </div>
                </a>
            </div>
        )
    }
}