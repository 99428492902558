const clickOutside = require('react-click-outside');

@clickOutside
export class StockPopUp extends React.Component<{}, {}> {
    public static siteScriptName = "StockPopUp";


    private handleClickOutside = () => {
        const stockEl = document.querySelector(".tradingview-widget-container") as HTMLElement;
        this.closeStock(stockEl)
    }

private closeStock=(stockEl:HTMLElement)=>{
    stockEl.innerHTML = "";
    stockEl.style.left="";
    stockEl.style.top = "";
    stockEl.style.display="none";

}
    componentDidMount() {
        if (typeof window !== "undefined") {
            const elements = document.querySelectorAll(".stockPopUpLink");
            for (let el of elements) {
                el.addEventListener("click", (e) => {
                    const stockId = el.getAttribute("data-id");
                    const closeBtn =  document.createElement("div");
                    closeBtn.className="closeStockPopUp";
                    closeBtn.innerHTML = "x";
                    const newScript = document.createElement("script");
                    newScript.id = `${stockId}_stockpopUp`;
                    newScript.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js";
                    newScript.async = true;
                    newScript.innerHTML = `
                  {
                  "symbol": "${stockId}",
                  "width": 450,
                  "isTransparent": false,
                  "colorTheme": "light",
                  "locale": "he-il",
                  "scrolling":"yes"
                }
                  `;
                    const stockEl = document.querySelector(".tradingview-widget-container") as HTMLElement;
                    stockEl.style.display = "block";
                    const top = el.getBoundingClientRect().top>300 ?  el.getBoundingClientRect().top - 150 : el.getBoundingClientRect().top+30 ;
                    const left= el.getBoundingClientRect().left - 450;
                    stockEl.style.left = left + "px";
                    stockEl.style.top = top  + "px";
                    const newDiv = document.createElement("div");
                    newDiv.className = "tradingview-widget-container__widget";
                    stockEl.append(closeBtn);
                    stockEl.append(newDiv);
                    stockEl.append(newScript);
                    closeBtn.addEventListener("click", (e) => {
                        this.closeStock(stockEl)
                    })
                })
            }
        }
    }

    public render() {  
        return (
            <div className="tradingview-widget-container" > </div>

        )
    }
}
