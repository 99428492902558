require("./yplus_site_subChannelHeader-mobile.less")
import * as classNames from "classnames"
import * as map from "lodash/map"
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { HyperLinkBuilder, SimpleLinkRenderer } from "../../../commonComponents/inline/hyperLink"
import { YnetPremiumLoginMobile } from "../../article/componentas/premium/componentas/ynetPremiumLogin/ynetPremiumLoginMobile";
import { getQueryStringValue } from "../../../widgetsUtils";
import { YplusSearchInput } from "./searchInput";
import { YnetPlusUserMenuItems, YnetPianoLogin } from "../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";
declare var $: JQueryStatic

export interface yPlusHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    catId: string
}

interface yPlusHeaderMenuProps {
    items: yPlusHeaderMenuItem[]
    categoryId: string
    id?: string
    isMenuVisible: boolean
    siteId?: string
    isInline?: boolean
    isHeaderForAppUnvisible?: boolean
    seachPageLink: string
    isUserLogged?: boolean
    mainLogoLink: string
    parentSiteLink: string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}
interface yplusheaderMenuState {
    isOpened: boolean
}
export class YplusSubChannelMenuMobile extends React.Component<yPlusHeaderMenuProps, yplusheaderMenuState>{
    constructor(props) {
        super(props)
        this.state = {
            isOpened: null
        }
    }

    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }


    private analyticsMenuPushFunc = () => {
        const { isOpened } = this.state
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            const menuStatus = isOpened ? 'Close' : 'Open';
            window.dataLayer.push({
                event: 'Navigation_Events',
                Category: 'Ynet+ - Navigation',
                Action: `Side Menu - ${menuStatus}`
            });
        }
    }

    private openMenu = () => {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
        this.setState({ isOpened: true })
        this.analyticsMenuPushFunc()

    }
    private closeMenu = () => {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        this.setState({ isOpened: false })
        this.analyticsMenuPushFunc()
    }
   
    render() {
        const { items, categoryId, isMenuVisible, siteId, isHeaderForAppUnvisible,
            seachPageLink, isUserLogged, mainLogoLink, parentSiteLink,ynetPlusMenuItems } = this.props
        const { isOpened } = this.state
        let logoImageUrl;
        logoImageUrl = siteId === "pplus" ? "/Common/images/pplus-logo.png" : "/Common/images/y-logo.png"
        let menuClassName = classNames("MobileMenu", { "opened": isOpened === true, "closed": isOpened === false })
        const isPianoLogin = typeof window !== "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;
        const isPianoUserValid =  typeof window != "undefined" && window.tp && window.tp.pianoId &&  window.tp.pianoId.isUserValid();
        const isUserAuthenticated = isPianoLogin? isPianoUserValid : isUserLogged;

        if (isHeaderForAppUnvisible) return null
        else return (
            <div className="YplusMobileHeaderContainer" style={{ direction: "rtl" }}>

                <div className={menuClassName} id="menu"  >
                    <div className="menuContent">
                        <div className="menuHeader">
                            <SimpleLinkRenderer className="midLogo withBgLogo" href={mainLogoLink} />
                            <div className="headerButtonsWrapper">
                                <button id="INDmenu-btn3" className="headerAccessibiltyBtn"
                                    accessKey="m" aria-labelledby="INDbtnTooltip" onClick={this.clickAcessibilityBtn}>
                                </button>
                                <button className="hamMenuClsBtn" onClick={this.closeMenu} />
                            </div>
                        </div>
           
                        {isPianoLogin? <YnetPianoLogin ynetPlusMenuItems={ynetPlusMenuItems} isPremiumHeaderArticle={true} /> : <YnetPremiumLoginMobile />}

                        {seachPageLink !== undefined && (isUserAuthenticated || (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL)) && <YplusSearchInput
                            className="mobileInput"
                            seachPageLink={seachPageLink}
                            isInputOpened={true}


                        />}
                        <ul className="YplusNavList" >
                            {map(items, (item, i) => {
                                return (
                                    <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                        <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                        </HyperLinkBuilder>
                                    </li>
                                )
                            }
                            )}
                        </ul>
                        <div className="contactLink" >
                            <div className="contact-row">
                                <a href="https://www.ynet.co.il/plus/contact" target="_blank" id="contactUs"> שירות לקוחות </a>
                            </div>
                            <div className="mini-contact-link" style={{ position: "relative" }}>
                                <a href="//z.ynet.co.il/short/content/2018/privacypolicy/policy.html" target="_blank" id="privay">
                                    {` מדיניות פרטיות | `}
                                </a>
                                <a href="//www.ynet.co.il/article/hklworybp" target="_blank" id="term">
                                    {` תנאי שימוש | `}
                                </a>
                                <a href="https://www.ynet.co.il/plus/contact" target="_blank" id="contact">
                                {` צור קשר ` }
                            </a>
                            </div>
                        </div>
                        <SimpleLinkRenderer className="mainSiteLogo" href={parentSiteLink} >
                            <img src={logoImageUrl} />
                            <span>    ראשי</span>
                        </SimpleLinkRenderer>
                    </div>
                    <div className="closeDivArea" onClick={this.closeMenu} />
                </div>
                <nav role="navigation" className="YplusMobileHeader">
                    {isMenuVisible ? <div id="menuToggle">
                        <button className="yplusHamBtn" onClick={this.openMenu} />


                    </div>
                        :
                        <SimpleLinkRenderer className="leftSideHeader" href={parentSiteLink} >
                            <img src={logoImageUrl} />
                            <span>    ראשי</span>
                        </SimpleLinkRenderer>}
                    <SimpleLinkRenderer className="midLogo withBgLogo" href={mainLogoLink} />

                    <div className="menuWrapper">
                        <SimpleLinkRenderer href="javascript:history.back()"><div className="arrowBack" /></SimpleLinkRenderer>
                    </div>
                </nav>

            </div>
        )
    }
}
interface yPlusHeaderMenuState {

    isUserLogged: boolean
}

export class YplusSubChannelMenuMobileSite extends React.Component<yPlusHeaderMenuProps, yPlusHeaderMenuState>{
    public static siteScriptName = "YplusSubChannelMenuMobileSite";
    constructor(props) {
        super(props as yPlusHeaderMenuProps);
        this.state = {
            isUserLogged: false
        }
    }
    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLogged: true })
        }
    }

    public render() {
        const { items, categoryId, isMenuVisible, seachPageLink, mainLogoLink, parentSiteLink, ynetPlusMenuItems } = this.props
        const { isUserLogged } = this.state
        let isHeaderForAppUnvisible = false;
        if (typeof window !== "undefined" && getQueryStringValue("IsApp")) {
            isHeaderForAppUnvisible = true
        }

        return (
            <YplusSubChannelMenuMobile
                items={items} categoryId={categoryId}
                isMenuVisible={isMenuVisible}
                isInline={false}
                isHeaderForAppUnvisible={isHeaderForAppUnvisible}
                seachPageLink={seachPageLink}
                isUserLogged={isUserLogged}
                parentSiteLink={parentSiteLink}
                mainLogoLink={mainLogoLink}
                ynetPlusMenuItems={ynetPlusMenuItems}
            />
        )
    }
}