import { openLinkedinShare, openFacebookShare, analyticsPushFunc, openTwitterShare } from "../../../widgetsUtils"
import { WhatsAppShareButton } from "widgets/siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"
import { MailShareButton } from "widgets/siteWidgets/socialShareButtons/mail/mailShareButton"

export class SiteArticleBottomShareCtech extends React.Component<{ articleId?: string, title: string }, {}> {
    public static siteScriptName = "SiteArticleBottomShareCtech"


    private handleShareBtnClicked = (button: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, document.title, () => analyticsPushFunc("Facebook", "Article Bottom", title), "Article Bottom")
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Bottom", title), "Article Bottom")
                    break;
                case "ld":
                    openLinkedinShare(current_url, () => analyticsPushFunc("Linkedin", "Article Bottom", title))
                    break;
                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Article Bottom", title), "Article Bottom")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, "en", () => analyticsPushFunc("Mail", "Article Bottom", title), "Article Bottom")
                    break;
            }
        }
    }

    public render() {
        return (
            <div className="articleBottomShareCtech">
                <div title="Facebook" className="share-icon facebook" onClick={(e) => this.handleShareBtnClicked("fb")} />
                <div title="Twitter" className="share-icon twitter" onClick={(e) => this.handleShareBtnClicked("tw")} />
                <div title="Linkedin" className="share-icon linkedin" onClick={(e) => this.handleShareBtnClicked("ld")} />
                <div title="Whatsapp" className="share-icon whatsapp" onClick={(e) => this.handleShareBtnClicked("wa")} />
                <div title="Email" className="share-icon email" onClick={e => this.handleShareBtnClicked("mailto")} />
            </div>
        )
    }
}