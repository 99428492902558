require('./site_tickerPopUp.less')

import { SiteTickerData } from "./tickerComponenta"
import { PopUpView } from "./popupView"


interface SiteTickerPopUpProps {
    items: SiteTickerData[]
    popUpTitle: string
    popUpTitleColor: string
    isAuto?: boolean

}
interface SiteTickerPopUpState {
    currentFlashId: string
}
export class SiteTickerPopUp extends React.Component<SiteTickerPopUpProps, SiteTickerPopUpState>{
    constructor(props) {
        super(props)
        this.state = {
            currentFlashId: null,
        }
    }
    public static siteScriptName = "SiteTickerPopUp"

    private handleElementsClick = (e: MouseEvent) => {
        const flashId = (e.currentTarget as HTMLElement).getAttribute("data-flashid")
        this.setState({ currentFlashId: flashId })
        document.body.style.overflow = "hidden"

    }

    private findItemIndexByFlashId = (flashId: string): number => {
        const { items } = this.props
        for (let i = 0; i < items.length; i++) {
            if (items[i].slotId === flashId) { return i }
        }
    }

    private attachClickEventToFlashElements = () => {
        if (typeof window !== "undefined") {
            const elements = document.querySelectorAll(".slotView  .flashItem")
            for (let el of elements) {
                el.addEventListener("keypress", this.handleElementsClick)
                el.addEventListener("click", this.handleElementsClick)
            }
        }
    }

    componentDidMount() {
        this.attachClickEventToFlashElements()
    }

    componentDidUpdate() {
        this.attachClickEventToFlashElements()
    }
    shouldComponentUpdate(nextProps: SiteTickerPopUpProps, nextState: SiteTickerPopUpState) {
        this.attachClickEventToFlashElements()
        return this.state.currentFlashId !== nextState.currentFlashId
            || this.props.items.length !== nextProps.items.length;
    }
    // Go to previous
    public swipeBack = (): void => {
        const { currentFlashId } = this.state
        const { items } = this.props
        const currentItemIndex = this.findItemIndexByFlashId(currentFlashId)
        const nextItemIndex = currentItemIndex > 0 ? currentItemIndex - 1 : 0
        this.setState({ currentFlashId: items[nextItemIndex].slotId })

    }

    // Go to next
    public swipeNext = (): void => {
        const { currentFlashId } = this.state
        const { items } = this.props
        const currentItemIndex = this.findItemIndexByFlashId(currentFlashId)
        const nextItemIndex = currentItemIndex < items.length - 1 ? currentItemIndex + 1 : items.length - 1
        this.setState({ currentFlashId: items[nextItemIndex].slotId })

    }

    private close = (): void => {
        let focusedId: string = this.state.currentFlashId;
        document.getElementById(focusedId).focus()
        this.setState({ currentFlashId: null })
        document.body.style.overflow = ""

    }

    //
    render() {
        const { items, popUpTitle, popUpTitleColor, isAuto } = this.props
        const { currentFlashId } = this.state
        const currentItemIndex = this.findItemIndexByFlashId(currentFlashId)
        const canNavigateBack = !(currentItemIndex === 0)
        const canNavigateNext = !(currentItemIndex === items.length - 1)

        const item = items[currentItemIndex]
        if (currentFlashId) { analystFlashFunc(item.title, isAuto, item.articleId) }
        if (currentFlashId) return (
            <PopUpView
                items={items}
                currentIndex={currentItemIndex}
                swipeBack={this.swipeBack}
                swipeNext={this.swipeNext}
                popUpTitleColor={popUpTitleColor}
                close={this.close}
                popUpTitle={popUpTitle}
            />
        )
        else return null;
    }
}
//  
export const analystFlashFunc = (title: string, isAuto: boolean, articleId: string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: isAuto ? `/NewsFlash/${articleId}/prof=${window.dcPath}` : `/ManualNewsFlash/prof=${window.dcPath}`,
        virtualPageTitle: title
    });
}