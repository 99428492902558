require("./yplus_site_menu.less")
import * as classNames from "classnames"
import * as throttle from "lodash/throttle"
import * as map from "lodash/map"
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { PremiumHeader } from "../../article/componentas/premium/componentas/premiumHeader/premiumHeader";
import { isWcmEditor } from "../../../../config/serverConfig";
import { YplusSearchInput } from "../../yPlusSubChannelHeader/components/searchInput";
import { YplusNotStickyMenu } from "./nonStickyNavList";
import { YnetPlusUserMenuItems } from "../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";
const clickOutside = require('react-click-outside');

export interface yPlusHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    catId: string
}

export interface yPlusHeaderMenuProps {
    items: yPlusHeaderMenuItem[]
    categoryId: string
    id?: string
    isSticky?: boolean
    isMenuVisible: boolean
    isArticle?: boolean
    siteId?: string
    isSubChannelPage?: boolean
    seachPageLink: string
    isInputOpened: boolean
    onFocusOutSearch: () => void
    showInput: () => void
    isUserLogged: boolean
    mainLogoLink: string
    parentSiteLink: string
    inputHasValue?:boolean
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}

export class YplusMenu extends React.Component<yPlusHeaderMenuProps, {}>{

    render() {
        const { items, categoryId, id, isMenuVisible, isSubChannelPage } = this.props
        const YplusMenuClassName = classNames("YplusMenu", isSubChannelPage ? "subChannelPageMenu" : "")

        return (
            <div className={YplusMenuClassName} >
                {!isWcmEditor() && <YplusStickyMenu {...this.props} />}
                {!isSubChannelPage && <YplusNotStickyMenu {...this.props} />}
            </div>
        )

    }
}

export interface yPlusHeaderMenuState {
    isSticky?: boolean
    isMenuOpened: boolean
}
@clickOutside
export class YplusStickyMenu extends React.Component<yPlusHeaderMenuProps, yPlusHeaderMenuState>{
    constructor(props) {
        super(props as yPlusHeaderMenuProps);
        this.state = {
            isSticky: null,
            isMenuOpened: false,

        }
    }
    public static siteScriptName = "YplusStickyMenu";
    private stickyBar(target: HTMLElement) {
        var sticky = target.offsetTop + 140;
        if (window.pageYOffset >= sticky) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }
    }
    componentDidMount() {
        const navbar = document.getElementById("styckyNav");
        document.addEventListener("scroll", this.throttledScrollHandler(navbar))
        document.addEventListener("scroll", this.onScrollCallback)
    }


    componentWillUnmount() {
        const navbar = document.getElementById("styckyNav");
        document.removeEventListener("scroll", this.throttledScrollHandler(navbar))
        document.removeEventListener("scroll", this.onScrollCallback)
    }

    private throttledScrollHandler = (navbar) => throttle(() => this.stickyBar(navbar), 250)
    private getClassName = (isSticky: boolean) => {
        switch (isSticky) {
            case true: return "sticky"
            case false: return "notSticky"
            default: return ""
        }
    }
    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }

    private handleClickOutside = () => {
        this.setState({ isMenuOpened: false })
    }
    private onScrollCallback = (e) => {
        this.setState({ isMenuOpened: false })
    }

    render() {
        const { items, categoryId, id, isMenuVisible, isArticle, isInputOpened, onFocusOutSearch, showInput,
            isSubChannelPage, seachPageLink, isUserLogged, mainLogoLink, parentSiteLink, siteId, ynetPlusMenuItems } = this.props
        const { isSticky } = this.state
        const stickyClassName = classNames("yPlusStyckyHeader", isSubChannelPage ? "stickyAllTime" : this.getClassName(isSticky))

        let firstList: yPlusHeaderMenuItem[];
        let secondList: yPlusHeaderMenuItem[];
        if (items.length > 6) {
            firstList = items.slice(0, 6)
            secondList = items.slice(6, items.length)
        }
        else firstList = items
        const isMenuHidden = !this.state.isMenuOpened ? "hidden" : ""
        return (
            <div className={stickyClassName} id="styckyNav">
                <PremiumHeader className="yPlusPageHeader" isArticlePage={isArticle}
                    isStyckyBar={true}
                    mainLogoLink={mainLogoLink}
                    parentSiteLink={parentSiteLink}
                    ynetPlusMenuItems={ynetPlusMenuItems}
                    siteId={siteId} >
                    {isMenuVisible && <div className="YplusNavigationList">
                        <ul>
                            {map(firstList, (item, i) => {
                                return (
                                    <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                        <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                        </HyperLinkBuilder>
                                    </li>

                                )
                            }
                            )}
                            {items.length > 6 && <li className="menuItem secondLevelH"><span onClick={this.toggleMenu}>עוד
                        </span>
                                <span className="menuTogglerWrapper" onClick={this.toggleMenu}>
                                    <span className="menuToggler"></span>
                                </span>
                                <ul className={`secondLevelMenu ${isMenuHidden}`}>
                                    {map(secondList, (item, i) => {
                                        return (
                                            <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                </HyperLinkBuilder>
                                            </li>

                                        )
                                    }
                                    )}
                                </ul>
                            </li>
                            }
                        </ul>
                        {seachPageLink !== undefined && (isUserLogged || (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL)) && <YplusSearchInput
                            className="mobileInput"
                            seachPageLink={seachPageLink}
                            isInputOpened={isInputOpened}
                            onFocusOutSearch={onFocusOutSearch}
                            showInput={showInput}

                        />}
                    </div>}

                </PremiumHeader>
            </div>
        )
    }
}

