require("./site_aboutLinks.less")

export const AboutLinks = () => {
    return (
        <div className="AboutLinksList">
            <ul>
                <li><a href="//www.ynet.co.il/home/0,7340,L-824,00.html" title="כתבו אלינו" target="_blank">כתבו אלינו</a></li>
                <li><a href="//www.ynet.co.il/home/0,7340,L-1893,00.html" title="עזרה" target="_blank">עזרה</a></li>
                <li><a href="//z.ynet.co.il/short/content/2018/privacypolicy/policy.html" title="מדיניות פרטיות" target="_blank">מדיניות פרטיות</a></li>
                <li><a href="//z.ynet.co.il/short/content/2018/privacypolicy/terms.html" title="תנאי שימוש" target="_blank">תנאי שימוש</a></li>
                <li ><a href="//www.ynet.co.il/home/0,7340,L-4080,00.html" title="מפת האתר" target="_blank">מפת האתר</a></li>
            </ul>

            <ul>
                <li><a href="//www.visit.yedioth.co.il/" title="מרכזי המבקרים" target="_blank">מרכזי המבקרים</a></li>
                <li><a href="//www.ynetnews.com/home/0,7340,L-3083,00.html" title="Israel News" target="_blank">Israel News</a></li>
                <li><a href="//www.ynet.co.il/home/0,7340,L-822,00.html" title="אודות האתר" target="_blank">אודות האתר</a></li>
                <li ><a href="//www.ynet.co.il/articles/0,7340,L-3369891,00.html" title="RSS" target="_blank">RSS</a></li>
            </ul>

            <ul>
                <li><a href="//www.ynet.co.il/home/0,7340,L-8,00.html" title="הפוך לדף הבית" target="_blank">הפוך לדף הבית</a></li>
                <li><a href="//ynetads-10fd1.firebaseapp.com/" title="פרסמו אצלנו" target="_blank">פרסמו אצלנו</a></li>
                <li><a href="//www.ynet.co.il/home/0,7340,L-1361,00.html" title="אנציקלופדיה" target="_blank">אנציקלופדיה</a></li>
                <li><a href="//premium.ynet.co.il/Web/Manage/Main" className="bold" title="רכישת מנוי +ynet" target="_blank">רכישת מנוי +ynet</a></li>
            </ul>
        </div>
    )
}