import { YnetSearchForm } from "../../../ynetNewsSearch/components/ynetSearchForm/ynetSearchForm";

const clickOutside = require('react-click-outside');

interface SearchProps {
    searchPageLink: string
    lang:string
}
interface SearchState {
    isSeachInputVisible: boolean
}

@clickOutside
export class SearchBtnDisplay extends React.Component<SearchProps, SearchState> {
    constructor(props) {
        super(props)
        this.state = {
            isSeachInputVisible: false,

        }
    }
    private openSearch=()=>{
        this.setState({isSeachInputVisible:true})
    }
    private closeSearch=()=>{
        this.setState({isSeachInputVisible:false})
    }
    private handleClickOutside = (e) => {
        this.setState({isSeachInputVisible:false})
    }
    render() {
        const {lang, searchPageLink} = this.props
        const placeholderText = lang && lang === "ru" ? "Поиск" : "Search";
        return (
            <div className="searchArea">
                <button className="searchBtn" onClick={this.openSearch}>{placeholderText}</button>
                {this.state.isSeachInputVisible &&   <YnetSearchForm placeholder={placeholderText}
                  closeX={true} closeSearchInput={this.closeSearch}
                 searchPageLink={searchPageLink} lang={lang} 
                 isGrid1280={true}/>
                }
            </div>

        )


    }
}