import { isWcmEditor } from "config/serverConfig"
import { calculateAutomaticComponentaUrl } from "widgets/widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { getImgAlt, isAriaHidden, getImgTitle } from "../../../widgetsUtils"
import { SiteInfinityHeadlinesData } from "./InfinityHeadlinesView"
import { LinkedItemFieldDisplay } from "../../../commonDataObjects/linkedItemsFieldsDisplay";

interface InfinityHeadlinesItemSlotProps {
    article: Partial<SiteInfinityHeadlinesData>
    isCategoryVisible?: boolean
}

export class InfinityHeadlinesItemSlot extends React.Component<InfinityHeadlinesItemSlotProps, {}>{
    static defaultProps = { isCategoryVisible: true }

    private getIcon = (hasVideo: boolean) => {
        if (hasVideo) return "video"
        else return "none"
    }
    public render() {
        const { article, isCategoryVisible } = this.props;
        const { categoryName, title, author, promotionImageDetails } = article
        const bgColorStyle = {
            backgroundColor: "#f2f1f2"
        }
        const titleStyle = {
            color: "#000000",
        }

        if (!isWcmEditor()) return (
            <a href={calculateAutomaticComponentaUrl(article)} className="slotView">
                <div className="mediaArea" style={bgColorStyle}>
                    <div className="MediaCarousel" style={{ width: 332, height: 187 }}>
                        <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 332, h: 187 })} style={{ width: 332, height: 187 }} alt={getImgAlt(promotionImageDetails)} aria-hidden={isAriaHidden(promotionImageDetails)} title={getImgTitle(promotionImageDetails)} />
                        <SlotIconDisplay itemId={article.articleId} icon={this.getIcon(article.hasVideo)} iconClassName="medium" isIconVisible={article.hasVideo} />
                    </div>
                </div>
                <div className="textDiv" style={bgColorStyle}>
                    {isCategoryVisible && <div className="categoryName"
                        style={{ color: "#4681f5", borderBottom: "2px solid #4681f5" }}>
                        {categoryName}
                    </div>}

                    <div className="slotTitle">
                        <span style={titleStyle}>{title}</span>
                    </div>
                    {author.length > 0 && <div className="moreDetails">
                        <span className="authorIcon" />
                        <span>{author} </span></div>}
                </div>
            </a>

        )
        else return null
    }
}