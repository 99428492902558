import Slider from "react-slick"
import { ExtededImageDetails } from "images/interfaces"
import * as apiUrls from "config/apiUrls"
import { ImageSize } from "constants/imageSizes"

interface MobileArticleImagesGalleryProps {
    images: ExtededImageDetails[]
    articleId: string
    articleTitle: string

}
interface MobileArticleImagesGalleryState {
    currentImageIndex: number
    isVisible: boolean
}

export class MobileArticleImagesGallery extends React.Component<MobileArticleImagesGalleryProps, MobileArticleImagesGalleryState>{
    constructor(props) {
        super(props)
        this.state = {
            currentImageIndex: 0,
            isVisible: false
        }
    }

    public static siteScriptName = "MobileArticleImagesGallery"

    componentDidMount() {
        const { images } = this.props;

        if (typeof window !== "undefined") {
            const elements = document.querySelectorAll(".gelleryOpener");
            for (let el of elements) {
                el.addEventListener("click", (e) => {
                    this.open();
                    const componentaId = el.getAttribute("data-image-id")
                    let indexOfImageMatchingCoponentaId = 0;
                    for (let i = 0; i < images.length; i++) {
                        if (images[i]) {
                            if (images[i].imageId === componentaId) { indexOfImageMatchingCoponentaId = i }
                        }
                    }
                    this.setState({ isVisible: true, currentImageIndex: indexOfImageMatchingCoponentaId })
                })
                el.addEventListener("keypress", (e) => {
                    const componentaId = el.getAttribute("data-image-id")
                    let indexOfImageMatchingCoponentaId = 0;
                    for (let i = 0; i < images.length; i++) {
                        if (images[i].componentaId === componentaId) { indexOfImageMatchingCoponentaId = i }
                    }
                    this.setState({ isVisible: true, currentImageIndex: indexOfImageMatchingCoponentaId })
                })

            }
        }
    }

    public swipeRight = (): void => {
        const lastImageIndex = this.props.images.length - 1
        const { currentImageIndex } = this.state
        const nextImageIndex = currentImageIndex === 0 ? lastImageIndex : currentImageIndex - 1
        analystFunc("Browse Right", this.props.articleId)
        this.setState({ currentImageIndex: nextImageIndex })
    }

    public swipeLeft = (): void => {
        const lastImageIndex = this.props.images.length - 1
        const { currentImageIndex } = this.state
        const nextImageIndex = currentImageIndex === lastImageIndex ? 0 : currentImageIndex + 1
        analystFunc("Browse Left", this.props.articleId)
        this.setState({ currentImageIndex: nextImageIndex })
    }

    private close = (): void => {
        analystFunc("Close Gallery", this.props.articleId)
        this.bodyScroll();
        this.setState({ isVisible: false })
    }

    private open = (): void => {
        this.bodyFixScroll();
        analystFunc("Open Gallery", this.props.articleId)
    }

    private bodyFixScroll = () => {
        const scrollPosition = document.body.getBoundingClientRect().top;
        document.body.style.top = scrollPosition + 'px';
        document.body.style.position = "fixed";
        document.body.style.overflow = "hidden";

    }
    private bodyScroll = () => {
        const imagetoBack = document.querySelectorAll(".gelleryOpener")[this.state.currentImageIndex -1]
        setTimeout(() => {
            imagetoBack.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }, 0)  
        document.body.style.position = null;
        document.body.style.overflow = null;   
    
    }

    private openShare = async () => {
        const shareData = { url: window.location.href, title: this.props.articleTitle }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    render() {
        const { images } = this.props
        const { currentImageIndex, isVisible } = this.state
        const settings = {
            infinite: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            arrows: false,
            rtl: false,
            touchMove: true,
            initialSlide: currentImageIndex,
            afterChange: (next) => this.setState({ currentImageIndex: next }),
        }
        let numOfImages = images.length ? images.length : 0
        const isImageGotCaption = images && images[currentImageIndex] && images[currentImageIndex].caption
        const isImageGotCredit = images && images[currentImageIndex] && (images[currentImageIndex].credit !== "") && (images[currentImageIndex].credit !== " ")

        if (isVisible) return (
            <div key={"MobileArticleImagesGallery"} role="dialog"
                aria-label="images gallery"
                id="images_gallery" className="MobileArticleImagesGallery"
                onClick={close} tabIndex={0}>

                <div className="GalleryWrapper" onClick={e => e.stopPropagation()}>
                    <div className={`galleryHeaderWrapper`}>
                        <button className="closeBtn yicon-x"
                            title={__("Close")} aria-label={__("Close")} onClick={this.close} />
                        {numOfImages > 1 && <div className="imagesCounter">
                            <span className="current">{currentImageIndex + 1 < 10 ? `${currentImageIndex + 1}` : currentImageIndex + 1}/</span>
                            <span className="totalNumOfImages">{numOfImages < 10 ? `${numOfImages}` : numOfImages}</span>
                        </div>}
                        <button className="shareImageBtn" onClick={this.openShare}></button>
                    </div>
                    <Slider {...settings} >
                        {_.map(images, (image) =>
                            <div className="imageContainer" key={image.imageId}>
                                <img src={apiUrls.getImageUrl(image, { imageSize: ImageSize.xLarge })} role="image" aria-label={image.alt} />
                            </div>
                        )}
                    </Slider>
                    <div className={`imageInfoAndShareOptions`}>
                        <div className="imageInfo">
                            {isImageGotCaption && <div className="caption">{images[currentImageIndex].caption}</div>}
                            {isImageGotCredit && <div className="credit">({images[currentImageIndex].credit})</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
        else return null
    }
}

export const analystFunc = (action: string, articleId: string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'GA_Event',
        Category: 'Photo Gallery',
        Action: action,
        Label: articleId
    });
}

