import * as map from "lodash/map"
import * as filter from "lodash/filter"
import { separateHourFromDateAndFormatToString, getNewlinkTargetProperties } from "widgets/widgetsUtils"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { TickerViewData } from "../tickerAuto/interfaces";
import { SiteTickerData } from "../ticker/components/tickerComponenta";
import { HyperLinkBuilder } from "../../commonComponents/inline/hyperLink"
import { CSSTransitionGroup } from "react-transition-group";
require("./ynetnews_vesty_site_englishTicker.less")


interface TickerAutoViewProps {
    data: TickerViewData
    articles: Array<any>

}

interface MobileTickerState {
    flashIndex: number

}

export class MobileTickerAutoView extends React.Component<TickerAutoViewProps, MobileTickerState>  {
    private timer = undefined;
    constructor(props) {
        super(props)
        this.state = {
            flashIndex: 0,

        }
    }
    private getHeaderPositon = (tickerEnLength: number, tickeRuLength: number, header: Element, header1: Element) => {
        if ((tickerEnLength !== 0 || tickeRuLength !== 0) && header !== undefined) {
            header.classList.add("withTicker")
            header1.classList.add("withTicker")
        }
    }
    componentDidMount() {
        var tickerElEn = document.getElementsByClassName('ticker-auto-english')
        var tickerElRu = document.getElementsByClassName('ticker-auto-russian')
        var header = document.getElementsByClassName('YnetNewsHeaderMobileComponenta')[0]
        var header1 = document.getElementsByClassName('YnetNewsHeaderMobile ')[0]
        this.getHeaderPositon(tickerElEn.length, tickerElRu.length, header, header1)
    }
    componentDidUpdate() {
        if (this.props.articles && this.props.articles.length > 0 && this.timer === undefined) {
            this.intervalTickerAnimation();
        }

    }
    componentWillUnmount() {
        const { articles } = this.props
        clearInterval(this.timer);
    }
    private nextFlash = () => {
        const { flashIndex } = this.state
        const { articles } = this.props
        if (flashIndex === articles.length - 1) {
            this.setState({
                flashIndex: 0
            });

        }
        else {
            this.setState({
                flashIndex: this.state.flashIndex + 1
            });
        }

    }

    private intervalTickerAnimation = () => {
        if (this.props.data.numberOfVisibleItems > 0) {
            this.callTimer();
        }
    }

    private callTimer = () => {
        this.timer = setTimeout(() => {
            this.nextFlash()
            this.callTimer();
        }, this.calculateTime() + 200)
    }

    private calculateTime = (): number => {
        let timeInterval: number;

        timeInterval = this.props.articles[this.state.flashIndex].title.length * 200
        return timeInterval

    }

    public render() {
        const { data, articles } = this.props
        const { flashIndex } = this.state

        const targetTitleLink: LinkTargetProperties = {
            linkOpenType: "new",
            lightBoxBgColor: "",
            lightBoxHeight: null,
            lightBoxOpacity: null,
            lightBoxWidth: 0
        }
        let itemList = [];
        // 
        if (articles && articles.length > 0) {
            itemList = filter(map(articles, (article: any, index) => {
                if (index > data.numberOfVisibleItems - 1) return null
                let image = { url: '' }

                return {
                    title: article.title,
                    flashDate: article.dateUpdatedOnSite,
                    link: article.publishedLink,
                    linkTarget: targetTitleLink,
                    id: article.articleId
                } as Partial<SiteTickerData>

            }))
        }

        return (
            <div className="MobileTickerComponenta">
                <div className="MobileTicker">
                    <HyperLinkBuilder className="tickerPageLink" href={data.tabHref} linkTarget={getNewlinkTargetProperties()} >
                        <span>+</span>
                    </HyperLinkBuilder>
                    {itemList.length > 0 && <CSSTransitionGroup transitionName="tickerDate" transitionEnterTimeout={500} transitionLeave={true} transitionLeaveTimeout={500}>
                        <div className="articleInfo"
                            key={itemList[flashIndex].id}
                            id={itemList[flashIndex].id}>
                            <span className="dateOfItem" >
                                {separateHourFromDateAndFormatToString(itemList[flashIndex].flashDate, { showDate: false, showHour: true })} |
                            </span>
                            <HyperLinkBuilder href={itemList[flashIndex].link}
                                className="titleOfItem"
                                linkTarget={getNewlinkTargetProperties()}
                                children={itemList[flashIndex].title} >
                                <div style={{ animationDuration: `${this.calculateTime()}ms` }} dangerouslySetInnerHTML={{ __html: itemList[flashIndex].title }} />
                            </HyperLinkBuilder>
                        </div>
                    </CSSTransitionGroup>}
                    <HyperLinkBuilder className="openTicker" href={(itemList.length == 0) ? null : itemList[flashIndex].link}
                        linkTarget={(itemList.length == 0) ? null : itemList[flashIndex].linkTarget} />
                </div>
            </div>
        )
    }
} 
