import * as classNames from "classnames"
import { ArticleCommentRenderProps } from "./articleCommentWrapper"
import { getTranslation } from "./translations"
import { AddCommentFormYnetUI } from "./addCommentFormYnetUI"
import { AddCommentFormWrapper, AddCommentFormRenderProps } from "./addCommentFormWrapper"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
require('./site_ynet_articleCommentYnetUi.less')
export interface ArticleCommentYnetUiProps {
    props: ArticleCommentRenderProps
}

export class ArticleCommentYnetUi extends React.Component<ArticleCommentYnetUiProps, {}>{
    render() {
        const { lang, isExpanded, vote, talkback_like, toggleOpenedState, commentFormType, articleId,
            isAddCommentFormVisible, handleVoteButtonsClick, handleFormVisibility, handleCloseCommentForm,
            comment: {
                id,
                number,
                title,
                text,
                author,
                pubDate,
                level,
                recommended,
                authorLocation,
                talkback_source,
            } } = this.props.props

        //const articlePublishDate = pubDate.split("T")[0].split("-").reverse().join(".")
        const articlePublishDate = separateHourFromDateAndFormatToString(pubDate, { showHour: false })

        const expandedCommentClasses = classNames({
            "ArticleComment": true,
            "expanded": isExpanded,
        });

        const arrowsClasses = classNames({
            "level2Arrow": true,
            "arrowDown": isExpanded,
        });

        const likeClasses = classNames({
            "like": true,
            "btnWithOpacity": vote === 1,
        });

        const likesCounter = classNames({
            "likesCounter": true,
            "likesPositive": talkback_like >= 0,
            "likesNegative": talkback_like < 0,
        });

        const dislikeClasses = classNames({
            "dislike": true,
            "btnWithOpacity": vote === -1,
        });

        const commentTitleClasses = classNames({
            "commentTitle": true,
            "redTitleText": recommended && !isExpanded,
        });

        return (
            <div id="articleComments" className={expandedCommentClasses}>
                <div className="topView" onClick={(e) => toggleOpenedState()}>
                    <div className={commentTitleClasses}>
                        {level === 1 && <span>{number}. </span>}
                        {level === 2 && <span className={arrowsClasses}></span>}
                        <span>{title}</span>
                        {text.length === 0 && <span>{getTranslation(lang, "Empty Content")}</span>}
                    </div>
                    <div className="authorDetails">
                        {author !== "" ? <span>  {`${author}, `}   </span> : null}
                        {authorLocation !== "" ? <span>  {`${authorLocation}, `} </span> : null}
                        <span> ({`${articlePublishDate}${talkback_source ? " , " + talkback_source : ""}`})</span>
                    </div>
                </div>

                {isExpanded &&
                    <div className="expandedView ">
                        <div className="commentText">{text}</div>
                        <div className="spacer"></div>
                        <div className="bottomButtonsWrapper">
                            <div className="newCommentButtonsWrapper">
                                <button className="newCommentBtn" title={getTranslation(lang, "New Comment")} disabled={isAddCommentFormVisible} onClick={e => handleFormVisibility("NEW")}>
                                    <span className="textBubleIcon" />
                                    <span>  {getTranslation(lang, "New Comment")}</span>
                                </button>
                                <button className="commentToComent" title={getTranslation(lang, "Comment To Coment")} disabled={isAddCommentFormVisible}
                                    onClick={e => handleFormVisibility("REPLY")}>
                                    <span className="textDoubleBubleIcon" />
                                    <span> {getTranslation(lang, "Comment To Coment")}</span>
                                </button>
                            </div>
                            <div className="voteButtonsWrapper">
                                <button className={likeClasses} aria-label="like comment" onClick={(e) => handleVoteButtonsClick(true, "2state")} />
                                <button className={dislikeClasses} aria-label="dislike comment" onClick={(e) => handleVoteButtonsClick(false, "2state")} />
                                <button className={likesCounter}>{talkback_like}</button>
                            </div>
                        </div>

                        <AddCommentFormWrapper
                            render={(props: AddCommentFormRenderProps) => <AddCommentFormYnetUI props={props} />}
                            lang={lang}
                            articleId={articleId}
                            formType={commentFormType}
                            handleCloseCommentForm={handleCloseCommentForm}
                            parentTalkbackId={id}
                            isAddCommentFormVisible={isAddCommentFormVisible}
                        />

                    </div>
                }

            </div>)
    }
}