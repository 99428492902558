import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { SimpleLinkRenderer } from "../../../commonComponents/inline/hyperLink";
import { MessageToAuthor } from "./messageToAuthorBox";
import { getTalkbackNumberView, toggleSpotImPopUp } from "../../article/componentas/articleSocialShare1280Component/articleSocialShareNew1280";
import { disablePageRefresh, enablePageRefresh } from "../../../siteWidgets/siteWidgetUtils";
import { removeHtmlTagsFromString } from "../../../jsonSerializer/common/jsonHelperUtils";


interface SiteArticleContactProps {
    articleId: string
    categorySubChannel: number
    isCommertialArticle: boolean
    showComments: boolean
    isSpotim?: boolean
    linkText: string
    linkUrl: string
    lang?:string
}

interface SiteArticleContactState {
    isMessageWindowOpened: boolean
    wcmNumOfComments:string
}

declare let window: Window & {
    languageCode: string
}

export class SiteArticleContact extends React.Component<SiteArticleContactProps, SiteArticleContactState>{
    static siteScriptName = "SiteArticleContact";
    public ON_WCM_COMMENTS_RECIVED = "onWcmCommetnsRecived";
    constructor(props) {
        super(props)
        this.state = {
            isMessageWindowOpened: false,
            wcmNumOfComments:''
        }
    }

    componentDidMount() {
        document.addEventListener(this.ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
      }
    componentWillUnmount(): void {
        document.removeEventListener(this.ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
      }
      private handleOnWcmCommentsRecived = (e) => {
        this.setState({ wcmNumOfComments: e.detail.wcmNumOfComments });
      };

    private openContactPopup = () => {
        this.setState({
            isMessageWindowOpened: true
        })
    }
    private closeContactPopup = () => {
        this.setState({
            isMessageWindowOpened: false
        })
    }
    private onCommentBtnClick = () => {
        const newCommentBtn = document.querySelector(".newCommentBtn") as HTMLElement;
        const elementToScroll = document.querySelector('#SiteArticleComments');
        const popupComment = document.querySelector('.spotimWrapper');
        if (newCommentBtn) {
            newCommentBtn.click();
        }

        if (elementToScroll) {
            setTimeout(() => {
                elementToScroll.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }, 0)
        }
        if (popupComment) {
            toggleSpotImPopUp(popupComment)
        }

    }


    private goToComments = () => {
        const elementToScroll = document.querySelector('#SiteArticleComments')
        const popupComment = document.querySelector('.spotimWrapper')
        if (elementToScroll) {
            setTimeout(() => {
                elementToScroll.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }, 0)
        }
        if (popupComment) {
            toggleSpotImPopUp(popupComment);
        }
    }

    private getUrlLink = () => {
        const { articleId, linkUrl } = this.props
        return linkUrl && linkUrl.replace('{article_id}', articleId)
    }

    render() {
        const { categorySubChannel, articleId, isCommertialArticle, showComments, isSpotim, linkText } = this.props
        const { isMessageWindowOpened,wcmNumOfComments} = this.state
        const commentNumber = getTalkbackNumberView(wcmNumOfComments ? wcmNumOfComments.toString() : "")
        const language = (typeof window !== "undefined" &&  window.languageCode)  ||  "";
        let lang;
        if (language.includes("ru")){
            lang = "ru";
        }
        else if(language.includes("en")){
            lang = "en";  
        } 
        let firstCommentTxt = "לכתבה זו לא התפרסמו תגובות, היו הראשונים להגיב"
        let toThisArticle = "";
        let comments = " תגובות";
        let readAllComments = "לקריאת כל התגובות";
        let addCommentTxt = "הוספת תגובה";
        
        let contactUsTxt = " מצאתם טעות בכתבה? כתבו לנו על זה";
        if(lang==="en"){
            firstCommentTxt = "Be the first to comment" ;
            toThisArticle = "";
            comments=" Comments";
            readAllComments="Read all comments";
            addCommentTxt = "Add a comment";
            contactUsTxt  = "Contact us";
        }
        else if(lang==="ru"){
            firstCommentTxt = "Ваш комментарий – первый" ;
            toThisArticle = "";
            comments="Комментарии:";
            readAllComments="Все комментарии";
            addCommentTxt = "Обсудить";
            contactUsTxt  = "Связь с редакцией"; 
        }
      const infoTExtByLang = lang==="en" ? `${comments} ${commentNumber} ` : `${commentNumber}  ${comments}`;
        const infoText = commentNumber.length > 0 ? infoTExtByLang : firstCommentTxt;
        const urlLink = this.getUrlLink()
        return (
            <div className="SiteArticleContact">
                {(subChannelIdsToAccept as any).includes(categorySubChannel) && !isCommertialArticle ?
                    <div className="contactAuthor" id="contactAuthor">
                        <span className="contactWrapper" id="contactWrapper" onClick={() => this.openContactPopup()}>{`פנייה לכתב/ת`}</span>
                        {isMessageWindowOpened && <MessageToAuthor onClose={this.closeContactPopup} articleId={articleId} />}
                    </div>
                    : <div />}


                <div className="foundMistake">
                    <SimpleLinkRenderer
                        href={urlLink ? urlLink : WCM_FEATURES_FLAGS.ENABLE_NEW_RED_MAIL ? "//ynet.co.il/redmail?notifyMistake=" + articleId : "//redmail.ynet.co.il/?notifyMistake=1"}
                        target="_blank"
                        className="mistakeText">
                        {linkText ? removeHtmlTagsFromString(linkText) : contactUsTxt}
                    </SimpleLinkRenderer>
                </div>
                {showComments && <div className="commentInfoBox">
                <div className="toAllComments" onClick={this.goToComments} id="bottomSpotimCommentsExist"> {commentNumber.length > 0 && !isSpotim && readAllComments}</div>
                    <div>
                    {isSpotim ? <div id="bottomSpotimCounter" /> : <div className="commentInfoText">{infoText}</div>}
                       
                        <button onClick={this.onCommentBtnClick} className="addNewComment" title={addCommentTxt}>
                            <span className="shareIcon1280" />
                            <span>{addCommentTxt}</span>
                        </button>
                    </div>
                </div>
                }

            </div>
        )
    }
}

const subChannelIdsToAccept = [8, 2, 6, 437, 3, 538, 1208, 544, 2142, 6377, 550, 598, 975, 194, 1069, 813, 5, 3262, 4872, 3052, 3925, 4403,
    1361, 4002, 3456, 3630, 5419, 5420, 5423, 5421, 5425, 5422, 5427, 5426, 5428, 5424, 5429, 2, 6, 3, 538,  544, 1208, 5363, 11679,
    8315, 598, 550, 975, 3925, 3052, 4403, 4880, 2142, 3262, 194, 8841, 11691]

