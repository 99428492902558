import * as axios from "axios"
import { ArticleExtraData } from "articlePage/stateInterfaces"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { Loader } from "../../../yPlusLoadMore/components/loader/loader";
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";
import { GoogleSearchComponenta } from "../../../googleSearch/components/googleSearchComponenta";
import { PodcastArchiveItemList } from "./podcastArchiveItemList";

interface PodcastArchiveComponentaProps {
    categoryId: string
    componentaId?: string
    firstPageArticles?: ArticleExtraData[]
    numberOfVisibleItems: number
    spotifyLink: string
    applePodcastLink: string
    archivePageLink: string
    archivePageLinkTarget: LinkTargetProperties
    pageSearchLink: string
    showSearchField: boolean
}

interface PodcastArchiveComponentaState {
    articles: ArticleExtraData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export class PodcastArchiveComponenta extends React.Component<PodcastArchiveComponentaProps, PodcastArchiveComponentaState> {
    public static siteScriptName = "PodcastArchiveComponenta"
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0,
            showLoader: false,
            showButton: true
        }
    }

    componentDidUpdate(prevProps: PodcastArchiveComponentaProps) {
        if (this.props.firstPageArticles !== prevProps.firstPageArticles) {
            this.setState(
                {
                    articles: this.props.firstPageArticles
                }
            );
        }
    }

    componentDidMount() {
        const { categoryId } = this.props;
        if (categoryId) {
            this.getArticles()
        }
    }

    private getArticles = () => {
        const { componentaId, } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        this.setState(
            {
                articles: articles,
                showLoader: true,
                showButton: false
            }
        );


        axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/pageNumber/${pageNumber}`)
            .then((res: any) => {
                const recivedArticles = res.data.data as ArticleExtraData[];
                this.setState(
                    {
                        articles: [...articles, ...recivedArticles],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                        showButton: recivedArticles && recivedArticles.length === this.props.numberOfVisibleItems * 4,
                    }
                );
            })
            .catch(err => console.error("error geting headline data:", err))
    }

    public render() {
        const { spotifyLink, applePodcastLink, pageSearchLink, showSearchField } = this.props;
        const { articles, showLoader: loader, showButton } = this.state;

        return (
            <div className="podcast-multi-strip-componenta">
                {showSearchField &&
                    <div className="search-wrapper">
                        <GoogleSearchComponenta placeholder="מה תרצו לחפש?" link={pageSearchLink} disableInWcm={"all"} />
                    </div>
                }
                <PodcastArchiveItemList
                    itemList={articles}
                    spotifyLink={spotifyLink}
                    applePodcastLink={applePodcastLink} />
                <div className="button-container">
                    {loader && <Loader />}
                    {showButton &&
                        <div className="load-more-button" onClick={() => this.getArticles()}>
                            <div className="load-more-text">טען עוד פודקאסטים</div>
                            <div className="load-more-icon"></div>
                        </div>}
                </div>
            </div >
        )
    }
}