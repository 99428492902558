import { YnetPremiumLoginBase, YnetPremiumLoginBaseRendererProps } from "./ynetPremiumLoginBase";
import { LogoutPopupComponent } from '../../../../../../../../app/widgets/defenitions/ynetSearch/components/ynetPremiumLogin/LogoutPopupComponent'
import * as classNames from "classnames"

export class YnetPremiumLoginMobile extends React.Component<{}, {}>{
    public static siteScriptName = "YnetPremiumLoginMobile";

    private handleOnClick() {
        window.YitPaywall.openLoginPopUp();
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'Login_Events',
            Category: 'Ynet+ - Login',
            Action: 'Login Source',
            Label: 'Side Menu'
        });
    }

    public render() {

        return <YnetPremiumLoginBase renderer={({ user, isMenuVisible, isLogoutPopupOpen, closeLogoutPopup, handleOnContactUsClicked,
            handleOnArticlesClicked, logOutFromPopUp, toggleMenu, handleOnLogoutMenuClicked, handleOnManageAccountClicked }: YnetPremiumLoginBaseRendererProps) => (

            <div className="YnetArticlePremiumLogin" >
                {!user &&
                    <a className="login_premium"
                        onClick={() => this.handleOnClick()}>
                        התחברות לחשבון
                    </a>}

                {user &&
                    <div className={classNames("loggedUser", { "isNotVisible": isMenuVisible })} onClick={toggleMenu}>
                        {`שלום ${user.props.firstName}`}
                    </div>
                }
                {user && <div className={classNames("userMenu", { "isVisible": isMenuVisible })}>
                        <div className="menuOption bold"> </div>
                        <div className="menuOption" onClick={handleOnManageAccountClicked} id="manageAccount">ניהול חשבון</div>
                        <div className="menuOption" onClick={handleOnLogoutMenuClicked} id="logoutMobile">התנתקות</div>
                </div>}

                {(isLogoutPopupOpen && user) && <LogoutPopupComponent logOutFromPopUp={logOutFromPopUp} closeLogoutPopupOpen={closeLogoutPopup} />}

            </div>

        )} isMobile={true} />

    }
}
