import * as classNames from "classnames"
import * as delay from "lodash/delay"
import { SiteVideoData } from "../../../../interfaces"
import { getVideoSources, shouldAutoPlayVideo, isAboveTheFold } from "../../videoPlayerUtils"
import { sendPopularityEvent } from "../../videoAnalytics"
import { buildAdTag } from "../../videoPlayerAds"
import { VideoJsGoogleAnalytics } from "../videoJsForInline/videoJsAnalytics"
require("./../../site_videoPlayer.less")

interface HolaPlayerForInlineProps {
    data: SiteVideoData
    width?: number
    height?: number
    runArticleAutoPlayLogic?: boolean
    applyStickyness?: boolean
    isInLightbox?: boolean
    onPlayStarted?: () => void
    onPlayStopped?: () => void
}

interface HolaPlayerForInlineState {
    isMuted: boolean
}

export class HolaPlayerForInline extends React.Component<HolaPlayerForInlineProps, HolaPlayerForInlineState>{
    private player;
    private holaPlayerContainerRef;
    private playerIdentifier;

    constructor(props) {
        super(props)
        this.playerIdentifier = `video_${this.props.data.mediaId}_${Date.now()}`
        this.state = {
            isMuted: false
        }
    }

    componentWillMount() {
        const { isMuteAvailable: isMuted } = this.props.data
        this.setState({ isMuted })
    }

    componentDidMount() {
        this.initializePlayer()
    }

    componentWillUnmount() {
        this.player && this.player.dispose()
    }

    private initializePlayer = () => {
        const { runArticleAutoPlayLogic, isInLightbox } = this.props
        const {
            poster,
            isAutoPlaying,
            isLoopPlaying: loop,
            isPlayerBarVisible: controls,
            isMuteAvailable: muted,
            url,
            downGradeUrl,
        } = this.props.data;

        const shouldAutoPlay = isInLightbox ? true : (!runArticleAutoPlayLogic && isAutoPlaying) || shouldAutoPlayVideo(this.holaPlayerContainerRef, isAutoPlaying);

        const holaPlayerSettings = {
            player: `#${this.playerIdentifier}`,
            controls,
            muted,
            loop,
            poster,
            sources: getVideoSources(url, downGradeUrl),
            ads: { adTagUrl: buildAdTag("Preroll", shouldAutoPlay, isAboveTheFold(this.holaPlayerContainerRef), "") }
        }

        window.hola_player(
            holaPlayerSettings,
            (player) => {
                this.player = player
                this.attachPlayerEvents(shouldAutoPlay)
            })
    }

    private attachPlayerEvents = (shouldAutoPlay: boolean) => {
        this.player.on("ready", () => {
            if (shouldAutoPlay) {
                delay(() => {
                    this.player.play(),
                        this.props.onPlayStarted && this.props.onPlayStarted()
                }, 500)
            }
        })

        this.player.on("playing", () => {
            const { url, mediaId } = this.props.data
            this.attachStopToWindowObj()
            this.props.onPlayStarted && this.props.onPlayStarted()
            VideoJsGoogleAnalytics.reportVidoeStartPlaying(url)
            sendPopularityEvent(mediaId, this.player.cache_.duration, window["WCM_POPULARITY_LINK"])
        })

        this.player.on("adstart", () => {
            this.attachStopToWindowObj()
            this.props.onPlayStarted && this.props.onPlayStarted()
        })
    }

    private executeCurrentStopAttachedToWindow = () => {
        window.stopCurrentPlayingPlayer &&
            (window.stopCurrentPlayingPlayer !== this.stopPlayer) &&
            window.stopCurrentPlayingPlayer()

    };

    private attachStopToWindowObj = () => {
        this.executeCurrentStopAttachedToWindow()
        window.stopCurrentPlayingPlayer = this.stopPlayer
    }

    private stopPlayer = () => {
        // stop player
        this.player.pause();
        this.props.onPlayStopped && this.props.onPlayStopped()
        this.player.currentTime(0);
        //stop ad
        if (window.hola_player('#' + this.playerIdentifier)) {
            window.hola_player('#' + this.playerIdentifier).vjs.ima.pauseAd()
        }
    }

    private handleMuteClicked = (e: React.MouseEvent) => {
        e.stopPropagation()
        this.setState((pState) => { return { isMuted: !pState.isMuted } })
    }

    render() {
        const { isMuted } = this.state
        const { width, height } = this.props
        const { isPlayerBarVisible, isSoundButtonVisible, isLive } = this.props.data;
        const containerStyle = width && height ? { width: `${width}px`, height: `${height}px` } : null
        const muteButtonClassName = classNames("hola_external_mute", { "muted": this.state.isMuted });
        const containerClasses = classNames("holaWrapper", { "live": isLive })
        return (
            <div className={containerClasses} style={{ ...containerStyle }}>
                <video
                    ref={ref => this.holaPlayerContainerRef = ref}
                    id={this.playerIdentifier}
                    style={{ ...containerStyle }}
                    muted={isMuted}
                    className="video-js inlinePlayer" />
                {isSoundButtonVisible && !isPlayerBarVisible &&
                    <button className={muteButtonClassName} title="Mute Video" onClick={this.handleMuteClicked} />}
            </div>
        )
    }
}


