import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { SiteVideoData } from "../../../../interfaces";
import { VideoArchiveArticle } from "./siteVideoArchiveComponenta"
import { calculateAutomaticComponentaUrl } from "../../../../widgetsUtils";

interface VideoArchiveMainItemProps {
    mainArticle: VideoArchiveArticle
    getFormattedDate: (dateUpdated: Date) => string
}
export default class VideoArchiveMainItem extends React.Component<VideoArchiveMainItemProps, {}> {

    render() {
        const { mainArticle, getFormattedDate } = this.props;
        const { mediaVideoId, credit, main_link, low_res_link, posterSrc, youtube_id, blockAbroadViews, videoName } = mainArticle.video[0];
        const videoData = {
            mediaType: "MEDIA_VIDEO",
            mediaId: mediaVideoId,
            credit: credit,
            url: main_link,
            downGradeUrl: low_res_link,
            poster: posterSrc,
            youtube_id: youtube_id,
            blockAbroadViews: blockAbroadViews,
            videoName: videoName,
        } as SiteVideoData;
        const mainVideoStripText = `${videoData.videoName} ${videoData.credit}`;

        return (
            <div className="mainVideo">
                <div className="mainVideoWrapper">
                    <SiteMedia
                        key={videoData.mediaId}
                        data={videoData}
                        width={750}
                        height={422}
                        videoSettings={{ isVideoLightbox: false, hideVideoAds: true }}
                        itemLinkUrl={null}
                        isImageLinkable={false}
                        linkTarget={undefined}
                    />
                </div>
                <div className="mainVideoStrip">
                    {mainVideoStripText}
                </div>
                <div className="videoText">
                    <div className="textRight">
                        <a href={calculateAutomaticComponentaUrl(mainArticle)} className="mainVideoTitle">{mainArticle.title}</a>
                        <div className="mainVideoDate">
                            {getFormattedDate(mainArticle.dateUpdatedOnSite)}
                        </div>
                    </div>
                    <div className="textLeft">
                        <a href={calculateAutomaticComponentaUrl(mainArticle)} className="mainVideoSubTitle">{mainArticle.subTitle}</a>
                        <div className="mainVideoAuthor">{mainArticle.author}</div>
                    </div>
                </div>
            </div>
        )
    }
}
