import * as throttle from "lodash/throttle"
import { sendVideoStikyClose, sendVideoStikyShow } from "./videoAnalytics";
import { windowIsWideEnoughForStickyVideo } from "./videoPlayerUtils";

export const attachVideojsStickinessAPI = (playerIdentifier: string, container: HTMLDivElement, poster: string) => {
    let isAttached = null
    const api = window.videojs.getPlayer(playerIdentifier)
    if (!container.classList) return; //not supported in IE <10
    if (!windowIsWideEnoughForStickyVideo()) return;

    const trashhold = container.getBoundingClientRect().height / 4;

    const attachSticky = () => {
        if (isAttached !== playerIdentifier) {
            isAttached = playerIdentifier;
            showStickyIfNotInViewPort();
            window.addEventListener("scroll", throttledOnScroll);
        }
        window.detachCurrentVideo = detachSticky
    }

    const detachSticky = (e: any, closeAction?: boolean) => {
        if (isAttached === playerIdentifier) {
            if (!closeAction && (container.classList.contains("vjs-scrubbing") || container.classList.contains("vjs-paused"))) { return }
            isAttached = null;
            window.removeEventListener("scroll", throttledOnScroll);
            container.classList.remove("is-sticky");
            removePlaceholder();
        }
    }

    addCloseButton(container, api, detachSticky);

    const showStickyIfNotInViewPort = () => {
        const rect = container.parentElement.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;
        if (rect.top < -trashhold || rect.bottom > viewportHeight + trashhold) {
            if (!container.classList.contains("is-sticky")) {
                sendVideoStikyShow(api.currentSrc());
                addPlaceholder(container, poster);
                if (document.getElementById('ads.sargel')) {
                    container.classList.add("is-sticky-sargel");
                }
                container.classList.add("is-sticky");
            }
        } else {
            removePlaceholder()
            container.classList.remove("is-sticky");
        }
    }
    const throttledOnScroll = throttle(showStickyIfNotInViewPort, 250);

    api.on("play", attachSticky)
    window.removeEventListener("scroll", throttledOnScroll);
}

const addCloseButton = (playerContainer: HTMLDivElement, api: any, detachStickyPlayer: (e: any, closeAction: boolean) => void) => {
    const anchorElement = document.createElement("a");
    anchorElement.className = "ynetfp-close-button";
    anchorElement.onclick = () => {
        sendVideoStikyClose(api.currentSrc());
        detachStickyPlayer(null, true)
    }
    playerContainer.appendChild(anchorElement);
}

const addPlaceholder = (playerContainer: HTMLDivElement, poster: string) => {
    let containerPlaceholder = playerContainer.cloneNode(false) as HTMLDivElement;
    containerPlaceholder.id = "[placeholder]_" + playerContainer.id;
    containerPlaceholder.classList.add("StickyPlayerPlaceholder")
    containerPlaceholder.classList.remove("is-playing"); // this class holds the rule: "background-image: none !important" which blocks the poster
    containerPlaceholder.style.backgroundImage = `url(${poster})`;
    containerPlaceholder.style.height = playerContainer.clientHeight.toString() + "px";
    playerContainer.parentElement.insertBefore(containerPlaceholder, playerContainer);
}

const removePlaceholder = () => {
    let placeholders = document.getElementsByClassName('StickyPlayerPlaceholder');
    while (placeholders[0]) {
        placeholders[0].parentNode.removeChild(placeholders[0]);
    }
}
