import { SiteItemData } from "../../../../interfaces";
import { VideoGalleryVerticalMutam } from "./videoGalleryVerticalMutam";

export interface categoryObj {
    id: string
    categoryColor: string
    name:string
}

interface VideoGalleryVerticalComponentaProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    firstAdPlace: number
    maxAds: number
    repeatAds: number
    shouldDisplayMobileAds: boolean
    category: categoryObj
    hideMoreButton:boolean 
    moreButtonText:string
    domain: string
}

export class VideoGalleryVerticalAutoMutamComponenta extends React.Component<VideoGalleryVerticalComponentaProps, {}>{
    public static siteScriptName = "RadionasVideoGalleryVerticalAutoMutamComponenta";

    public render() {
        const { videoList, isTabVisible, tabTitleText, repeatAds, maxAds, firstAdPlace, category, domain, hideMoreButton, moreButtonText, shouldDisplayMobileAds } = this.props;

        return (
            <div className="radionasVideoGalleryVerticalAutoMutamComponenta">
                <VideoGalleryVerticalMutam
                     videoList={videoList}
                     isTabVisible={isTabVisible}
                     tabTitleText={tabTitleText}
                     repeatAds={repeatAds}
                     maxAds={maxAds}
                     firstAdPlace={firstAdPlace}
                     hideMoreButton={hideMoreButton}
                     moreButtonText={moreButtonText}
                     shouldDisplayMobileAds={shouldDisplayMobileAds}
                     category={category}
                     domain={domain}/>
            </div>
        )
    }
}
