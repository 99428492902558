require("./site_articleSideShareComponent.less")
require("../site_shareButtons.less")

import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton";
import { getShareTranslations } from "./translations";
import { openFacebookShare, analyticsPushFunc } from "../../../../widgetsUtils"

interface SiteArticleSideShareProps {
    isLTR: boolean
    lang: string
    title: string
}

export class SiteArticleSideShare extends React.Component<SiteArticleSideShareProps, {}> {
    public static siteScriptName = "SiteArticleSideShare"

    private handleShareBtnClicked = (button: string, lang?: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Side", title), "Article Side")
                    break;

                case "print":
                    window.print();
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, lang, () => analyticsPushFunc("Mail", "Article Side", title), "Article Side")
                    break;

                case "talkback":
                    const newCommentBtn = document.querySelector(".newCommentBtn") as HTMLElement
                    if (newCommentBtn) {
                        newCommentBtn.click();
                    }
                    const elementToScroll = document.querySelector('#addCommentSendButton') || document.querySelector('.spotimWrapper')
                    setImmediate(() => {
                        elementToScroll.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    })

                    break;
            }
        }
    }





    public render() {
        const { isLTR, lang } = this.props
        let ltrClassName = isLTR ? "englishSite" : ""
        return (
            <div className={`ArticleSideShareComponent ${ltrClassName}`}>
                <ul>
                    <li >
                        <button className=" ArticleSideShareComponent_facebook"
                            title={getShareTranslations(lang, "facebook")}
                            onClick={(e) => this.handleShareBtnClicked("fb")}>
                            <span className="articleShareButton facebook" />{getShareTranslations(lang, "facebook")}</button>
                    </li>

                    <li>
                        <button className="ArticleSideShareComponent_print"
                            title={getShareTranslations(lang, "print")}
                            onClick={(e) => this.handleShareBtnClicked("print")}>
                            <span className="articleShareButton print" />{getShareTranslations(lang, "print")}</button></li>

                    <li>
                        <button className="ArticleSideShareComponent_mailto"
                            title={getShareTranslations(lang, "mailto")}
                            onClick={(e) => this.handleShareBtnClicked("mailto", lang)}
                        ><span className="articleShareButton mailto" />{getShareTranslations(lang, "mailto")}</button></li>

                    <li >
                        <button className="ArticleSideShareComponent_talkback" title={getShareTranslations(lang, "comment")}
                            onClick={(e) => this.handleShareBtnClicked("talkback")}>
                            <span className="articleShareButton talkback" />{getShareTranslations(lang, "comment")}</button></li>

                </ul>
            </div>

        )
    }
}