import * as map from "lodash/map"
require("./site_mediaGalleryComponenta.less")
require("./../../tab/componentas/site_tabComponenta.less")
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteMediaData } from "../../../interfaces"
import * as classNames from "classnames"
import { SiteMedia } from "../../../siteWidgets/siteMedia";
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { getImagesSrc } from "../../../commonComponents/inline/siteSimpleImage";
import { GroupItemSlotData } from "../../multiArticleRotation/data/groupItemSlotData";





interface mediaGalleryState {
    selectedIndex: number
}
export interface GroupItemData {
    media: SiteMediaData,
    title: string,
    link: string,
    targetLink: LinkTargetProperties,
}
interface mediaGalleryProps {
    isEnglish?: boolean
    mainTitleText: string
    groupList: GroupItemData[]
    isTitleVisible?: boolean
    tabBackgroundColor: string
    tabBackgroundImageUrl: string
    componentaWidth: number
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    groupItemsArray: GroupItemSlotData[]
    videoSettings: any
    imagesDomain: string
}


export class FrontGalleryComponenta extends React.Component<mediaGalleryProps, mediaGalleryState>{
    public static siteScriptName = "FrontGalleryComponenta";
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0
        }
    }
    private setActiveItem = (index) => {
        this.setState({ selectedIndex: index });
    }
    private slidePrev = () => {
        let newIndex;
        if (this.state.selectedIndex !== 0) { newIndex = this.state.selectedIndex - 1 }
        this.setState({ selectedIndex: newIndex });
    }
    private slideNext = () => {
        const { groupItemsArray } = this.props
        let newIndex;
        if (this.state.selectedIndex < groupItemsArray.length - 1) {
            newIndex = this.state.selectedIndex + 1
        }
        this.setState({ selectedIndex: newIndex });
    }
    public render() {
        const { isEnglish, groupList, tabLink, tabLinkTarget, isTitleVisible, videoSettings, mainTitleText,
            groupItemsArray, tabBackgroundColor, tabBackgroundImageUrl, componentaWidth, imagesDomain } = this.props

        const tabStyles = tabBackgroundColor ? { backgroundColor: tabBackgroundColor } : { backgroundImage: `url("${getImagesSrc(imagesDomain, tabBackgroundImageUrl)}")`, backgroundRepeat: "no-repeat" }
        const allMedia = groupList.map(item => item.media)
        let englishSite = isEnglish ? "englishSite" : ""
        const { selectedIndex } = this.state


        let disabledNext = selectedIndex === groupItemsArray.length - 1
        let disabledPrev = selectedIndex === 0
        const innerStyle = {
            width: (groupItemsArray.length * componentaWidth) + "px",
            right: (selectedIndex * componentaWidth * -1) + "px"
        };
        const bigItem: GroupItemData = groupList[selectedIndex * 3]
        const smallItems = groupList.slice(selectedIndex * 3 + 1, selectedIndex * 3 + 3)

        return (
            <div className={`MediaGalleryComponenta ${englishSite}`}  >
                <div className="TabComponenta mediaGalleryTab" style={tabStyles}>
                    <HyperLinkBuilder className="titleLink mainTitleLink" href={tabLink} linkTarget={tabLinkTarget}><div className="mainTitleText">{mainTitleText}</div></HyperLinkBuilder>
                    {groupItemsArray.length > 1 && <div className="pager">
                        {map(groupItemsArray, (item, index) =>
                            <button
                                className={index === selectedIndex ? "isActive" : ""}
                                onClick={() => this.setActiveItem(index)}
                                key={index}
                            />)}
                    </div>}
                    {groupItemsArray.length > 1 && <div className="gallery_arrows">
                        <button className="gallery_arrow_right" onClick={isEnglish ? this.slideNext : this.slidePrev} disabled={isEnglish ? disabledNext : disabledPrev} title={isEnglish ? "next" : "previos"} />
                        <div className="gallery_separator" />
                        <button className="gallery_arrow_left" onClick={isEnglish ? this.slidePrev : this.slideNext} disabled={isEnglish ? disabledPrev : disabledNext} title={isEnglish ? "previos" : "next"} />
                    </div>}
                </div>
                <div className="main-gallery">
                    <div className="slider" style={innerStyle}>  {map(groupItemsArray, (item, i) =>
                        <div className="GroupContent" key={i}>
                            <div className="firstItemInGroup">
                                <div className={classNames("slotView")}>
                                    <SiteMedia
                                        allMedias={allMedia}
                                        data={bigItem.media}
                                        videoSettings={videoSettings} key={bigItem.media.url} width={437} height={246} />

                                    {isTitleVisible && <div className="itemTitle">
                                        <HyperLinkBuilder href={bigItem.link} linkTarget={bigItem.targetLink}>
                                            <div dangerouslySetInnerHTML={{ __html: bigItem.title }} />
                                        </HyperLinkBuilder>
                                    </div>}

                                </div>
                            </div>
                            <div className="slotList">
                                <div>
                                    {smallItems.map((item, index) => <div className={classNames("slotView")} key={index}>
                                        <SiteMedia
                                            allMedias={allMedia}
                                            data={item.media}
                                            videoSettings={videoSettings} key={item.media.url} width={211} height={118} />

                                        {isTitleVisible && <div className="itemTitle">
                                            <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                            </HyperLinkBuilder>
                                        </div>}

                                    </div>)}
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        )
    }
}
