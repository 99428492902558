require("./ynet_site_taboolaGrid1280.less")

export interface taboolaProps {
    mode: string
    container: string
    placement: string
    target_type: string
}

export class TaboolaComponentaGrid1280 extends React.Component<taboolaProps, {}>{
    public static siteScriptName = "TaboolaComponentaGrid1280"

    componentDidMount() {
        window._taboola = window._taboola || [];
        window._taboola.push({
            mode: this.props.mode,
            container: this.props.container,
            placement: this.props.placement,
            target_type: this.props.target_type,
        })
    }
    render() {

        return <div className="sb150Width260"><div id={this.props.container}></div></div>

    }
}    