import { DataList } from "widgets/commonDataObjects/dataList"
import { MainNavItemSlotData } from "../../data/mainNav/mainNavItemSlotData"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { LinkTargetProperties } from "../../../../../../global/components/linkSettings/interfaces"

interface MainNavProps {
    items: MainNavitem[]
}

export interface MainNavitem {
    link: string
    linkTarget: LinkTargetProperties
    mediaTitle: string
    color: string
    url: string
    title: string
}

export class MainNavComponenta extends React.Component<MainNavProps, {}> {
    constructor(props) {
        super(props)
    }

    render() {
        const { items } = this.props
        return (
            <div className="mainNav no-print" role="navigation" aria-label="Main Menu">
                <ul>
                    {_.map((items), (item: any, index: number) =>
                        <li key={index}>
                            <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} ariaLabel={item.mediaTitle} style={{ color: item.color }}>
                                {item.url !== "" ? <SiteSimpleImage src={item.url} alt={item.mediaTitle} title={item.mediaTitle} width={"auto"} height={"auto"} /> :
                                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                }
                            </HyperLinkBuilder>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}