import { CategoryListItem } from "./siteCtechWideBuzz"


interface CtechArticleItemProps {
    categoryList: CategoryListItem[]
    toggleChannelMenu: () => void
    currChannle: any
    isChannelMenuVisible: boolean
    onChannelChanged: (newChannle: any) => void
    isLTR?: boolean
}
export class CtechChannelMenu extends React.Component<CtechArticleItemProps, {}>{
    public render() {
        const { toggleChannelMenu, currChannle, isChannelMenuVisible, categoryList, onChannelChanged, isLTR } = this.props
        const ctechDisplayName = isLTR ? 'All channels' : 'כל הערוצים'
        return (
            <div className="CtechChannelMenu">
                <span className="selectArrow" onClick={toggleChannelMenu} />
                <span className="selected" onClick={toggleChannelMenu}>{(currChannle.id === '5211' || currChannle.id === '1') ? ctechDisplayName : currChannle.description}</span>
                {isChannelMenuVisible &&
                    <div className="channleOptionsList">
                        {_.filter(_.map(categoryList, (categoryItem, index) => {
                            return (
                                <div key={index} className="menuOption" onClick={() => onChannelChanged(categoryItem)}>
                                    {(categoryItem.id === '5211' || categoryItem.id === '1') ? ctechDisplayName : categoryItem.description}</div>
                            )
                        }))
                        }
                    </div>}
            </div>
        )
    }
}