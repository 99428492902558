import { SiteSettingsHOC, SiteSettings } from "../../../global/components/siteSettingsHOC"



@SiteSettingsHOC(true)
export class SiteSimpleImage extends React.Component<React.HTMLProps<HTMLImageElement> & Partial<{ siteSettings: SiteSettings }>, {}>{
    public render() {
        const { siteSettings, src, ...rest } = this.props
        var imagesDomain = siteSettings ? siteSettings.imagesDomain : undefined;
        return <img {...rest} src={getImagesSrc(imagesDomain, src)} />
    }
}


export const getImagesSrc = (imagesDomain: string, src: string) => {
    var newSrc = src;
    if (src && src.startsWith("/")) {
        newSrc = (imagesDomain || "") + src
    }
    return newSrc
}
