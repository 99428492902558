import { InfinityHeadlinesItemSlot } from "./infinityHeadlinesItemSlot"
import * as InfiniteScroll from "react-infinite-scroll-component"
import { SiteInfinityHeadlinesData } from "./InfinityHeadlinesView"


interface InfinityHeadlinesListProps {
    itemList: SiteInfinityHeadlinesData[]
    fetchMoreHeadlines: () => void
    height: number
    dataLength: number
    isCategoryVisible?: boolean
}

export class InfinityHeadlinesList extends React.Component<InfinityHeadlinesListProps, {}>{

    public render() {
        const { itemList, fetchMoreHeadlines, dataLength, isCategoryVisible } = this.props


        return (
            <div className="slotList" >
                <InfiniteScroll
                    dataLength={dataLength}
                    next={fetchMoreHeadlines}
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                >
                    {itemList.map((item, i) =>
                        <InfinityHeadlinesItemSlot
                            key={i}
                            article={item}
                            {...this.props}
                        />)}

                </InfiniteScroll>
            </div>
        )
    }
}