
import { ArticleShareButtons } from "../../../../siteWidgets/socialShareButtons/ArticleShareButtons/ArticleShareButtons";
import { ArticleImageGallery } from "../articleImageGalleryComponenta/articleImageGalleryComponenta"

interface ArticleImagesFullScreenGalleryProps {
    images: ArticleImageGallery[]
    currentImageIndex: number
    isLtr: boolean
    close: (index: number) => void
}


const ArticleImagesFullScreenGalleryMain = ({ currentImageIndex, isLtr, images, close }: ArticleImagesFullScreenGalleryProps) => {
    let ltrClassName = isLtr ? "englishSite" : ""
    return (
        <>
            <div className={`closeBtnWrapper ${ltrClassName}`}><button className="closeBtn yicon-x"
                title={__("Close")} aria-label={__("Close")} onClick={() => close(currentImageIndex)}
                ref={ref => this.popUpCloseButton = ref}
            /></div>
            <div className="imageContainer" role="image" aria-label={images[currentImageIndex].caption} style={{ backgroundImage: `url(${images[currentImageIndex].imageSrc})` }}></div>
            <div className={`imageInfoAndShareOptions ${ltrClassName}`}>
                <div className="imageInfo">
                    {images[currentImageIndex] && images[currentImageIndex].caption && <div className="caption">{images[currentImageIndex].caption}</div>}
                    {images[currentImageIndex] && images[currentImageIndex].credit && images[currentImageIndex].credit !== " " && <div className="credit"><span>(</span><span>{images[currentImageIndex].credit})</span></div>}
                </div>
                <div className="shareOptions">
                    <ArticleShareButtons className={"ArticleImageGalleryShareButtons"} lang={isLtr ? "en" : "he"} />
                </div>
            </div>
        </>
    )
}

export default ArticleImagesFullScreenGalleryMain