import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import { getNewlinkTargetProperties } from "../../../widgetsUtils";
import { CategorySelectionItem } from "../../../../global/components/categorySelectInput/categorySelectInput";

require('./pplus_site_categoryLabel.less')


interface CategoryLabelProps {
    category: CategorySelectionItem
    defaultBgColor?: string
    id?: string
}

export class CategoryLabel extends React.Component<CategoryLabelProps, {}> {
    public render() {
        const { category, defaultBgColor, id } = this.props;
        const categoryBgColor = category.categoryColor && category.categoryColor !== '' ?
            category.categoryColor : defaultBgColor && defaultBgColor !== '' ?
                defaultBgColor : "#272727";
        return (
            <HyperLinkBuilder href={category.publishedLink} linkTarget={getNewlinkTargetProperties()} style={{ color: '#fff' }}>
                <div className="CategoryLabel" id={id} style={{ backgroundColor: categoryBgColor, color: '#fff' }}>
                    <div>{category.textToShow}</div>
                </div>
            </HyperLinkBuilder>
        )
    }
}