import { YnetSearchForm } from "../../../ynetSearch/components/ynetSearchForm/ynetSearchForm"
const clickOutside = require('react-click-outside');

interface SearchProps {
    ynetSearchPageLink: string
}
interface SearchState {
    isSeachInputVisible: boolean
}

@clickOutside
export class SearchBtnDisplay extends React.Component<SearchProps, SearchState> {
    constructor(props) {
        super(props)
        this.state = {
            isSeachInputVisible: false,

        }
    }
    private openSearch=()=>{
        this.setState({isSeachInputVisible:true})
    }
    private closeSearch=()=>{
        this.setState({isSeachInputVisible:false})
    }
    private handleClickOutside = (e) => {
        this.setState({isSeachInputVisible:false})
    }
    render() {
        return (
            <div className="searchArea">
                <button className="searchBtn" onClick={this.openSearch}>חיפוש</button>
                {this.state.isSeachInputVisible && <YnetSearchForm placeholder="מה מעניין אותך?" ynetSearchPageLink={this.props.ynetSearchPageLink} closeX={true} closeSearchInput={this.closeSearch} />}
            </div>

        )


    }
}