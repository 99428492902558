import * as classNames from "classnames"
require('./ctech_calcalist_site_hamburgerButton.less')

interface HamburgerProps {
    onToggleMenu: () => void
    isOpened: boolean
}

export class HamburgerButtonComponenta extends React.Component<HamburgerProps, {}> {
    constructor(props) {
        super(props)
    }
    render() {
        const { onToggleMenu, isOpened } = this.props
        let hamburgerCalssName = classNames("hamburger", { "active": isOpened })
        return (

            <div id="menu-button" className={hamburgerCalssName} onClick={onToggleMenu}>
                <span></span>
            </div>
        )


    }
}