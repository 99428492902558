import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SiteArticleData, CtechArticleItem } from "./ctechArticleItem"



interface CtechArticleListProps {
    itemList: SiteArticleData[]
    isLTR?: boolean
}

export class CtechArticleList extends React.Component<CtechArticleListProps, {}>{
    public render() {
        const { itemList, isLTR } = this.props
        return (
            <div className="slotList" >
                {itemList.map((item, i) =>
                    <CtechArticleItem
                        key={i}
                        article={item}
                        index={i}
                        isLTR={isLTR}
                        {...this.props}
                    />)}
            </div>
        )
    }
}