import { StoriesEntity } from "./storiesAutoComponenta";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";

interface StoriesAutoSlotItemProps {
    entity: StoriesEntity
    onStoriesOpen: (clickedIndex: number) => void
    index: number
    isAllStoriesFinished: boolean
}

export class StoriesAutoSlotItem extends React.Component<StoriesAutoSlotItemProps, {}> {

    public render() {
        const { entity: { promotionName, media, linkTarget, link }, onStoriesOpen, index, isAllStoriesFinished } = this.props;
        const slotClass = `slotView ${isAllStoriesFinished ? "finished" : ""}`

        return (
            <div className={slotClass} onClick={() => onStoriesOpen(index)}>
                <div className="MediaCarousel">
                    <SiteMedia
                        data={media}
                        width={100}
                        height={100}
                        videoSettings={null}
                        itemLinkUrl={link.link.publishedLink}
                        isImageLinkable={true}
                        linkTarget={linkTarget}
                    />
                </div>
                <div className="contentDiv">
                    <div className="slotTitle">{promotionName}</div>
                </div>
            </div >
        )
    }
}