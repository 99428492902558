import * as axios from "axios";
import { ArticleExtraData } from "articlePage/stateInterfaces";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { Loader } from "../../../yPlusLoadMore/components/loader/loader";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { TwentyFourSeven1280SlotList } from "./twentyFourSeven1280SlotList";
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils";

interface TwentyFourSeven1280ComponentaProps {
    categoryId: string
    componentaId?: string
    firstPageArticles?: ArticleExtraData[]
    numberOfVisibleItems: number
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    isLoadMoreVisible: boolean
    tabColor: string
    includeVideo: boolean
    hideVideoAds?: boolean
    tabBackgroundColor?: string
    isMobileWeb: boolean
    numberOfVisibleDays: number
    isLTR: boolean
    sitename: string
}

interface TwentyFourSeven1280ComponentaState {
    articles: ArticleExtraData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export const DICTIONARY = {
    ynet: {
        weekDays: ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"],
        today: "היום",
        yesterday: "אתמול",
        loadMore: "טען עוד כתבות",
    },
    ynetnews: {
        weekDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        today: "Today",
        yesterday: "Yesterday",
        loadMore: "Load more articles",
    },
    vesty: {
        weekDays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        today: "Сегодня",
        yesterday: "Вчера",
        loadMore: "Загрузить еще",
    },
}

export class TwentyFourSeven1280Componenta extends React.Component<TwentyFourSeven1280ComponentaProps, TwentyFourSeven1280ComponentaState> {
    public static siteScriptName = "SiteTwentyFourSevenComponenta"
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0,
            showLoader: false,
            showButton: true
        }
    }

    componentDidMount() {
        const { categoryId } = this.props;
        if (categoryId) {
            this.getArticles()
        }
    }

    private analystFunc = (action: string) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'GA_Event',
            Category: 'Article Headline',
            Action: action,
            Label: "",
        });
    }

    private getStartDate = (numberOfDays: number) => {
        const today = new Date();
        const startDate = new Date(today.getTime() - ((numberOfDays - 1) * 24 * 60 * 60 * 1000));
        return startDate;
    }

    private getArticles = () => {
        const { componentaId, numberOfVisibleDays } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        const formatedStartDate = formatDate(new Date(this.getStartDate(numberOfVisibleDays)), "YYYY-MM-DD");
        this.setState({
            articles: articles,
            showLoader: true,
            showButton: false
        });

        axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/pageNumber/${pageNumber}/startDate/${formatedStartDate}`)
            .then((res: any) => {
                const recivedArticles = res.data.data as ArticleExtraData[];
                this.setState(
                    {
                        articles: [...articles, ...recivedArticles],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                        showButton: recivedArticles && recivedArticles.length === this.props.numberOfVisibleItems,
                    }
                );
            })
            .catch(err => console.error("error geting headline data:", err));

        this.analystFunc("Load more articles");
    }

    public render() {
        const { isLoadMoreVisible, fieldsDisplaySettings, tabColor, tabBackgroundColor, isMobileWeb, titleColor, subTitleColor, isLTR, sitename } = this.props;
        const { articles, showLoader, showButton } = this.state;
        const twentyFourSevenClassName = `twentyFourSeven1280Componenta ${isLTR ? "ltr" : ""}`;

        return (
            <div className={twentyFourSevenClassName}>
                <TwentyFourSeven1280SlotList
                    articles={articles}
                    tabBackgroundColor={tabBackgroundColor}
                    tabColor={tabColor}
                    fieldsDisplaySettings={fieldsDisplaySettings}
                    isMobileWeb={isMobileWeb}
                    titleColor={titleColor}
                    subTitleColor={subTitleColor}
                    sitename={sitename}
                />
                <div className="ButtonAndLoaderContainer">
                    {showLoader && <Loader />}
                    {isLoadMoreVisible && showButton && <div className={"loadMoreButton"} onClick={() => this.getArticles()} style={{ backgroundColor: tabBackgroundColor }} >{DICTIONARY[sitename].loadMore}</div>}
                </div>
            </div >
        )
    }
}