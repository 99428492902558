require("./site_toolsLinks.less")

export const ToolsLinks = () => {
    return (
        <div className="ToolsLinksList">
            <ul>
                <li><span className="seperator">|</span>
                    <a href="//www.bigdeal.co.il/AllCamp.aspx?s=1&amp;categoryid=3" title="קניות" target="_blank">קניות</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.calcalist.co.il/stocks/home/0,7340,L-6,00.html" title="מניות" target="_blank">מניות</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.calcalist.co.il/stocks/home/0,7340,L-4135-22212222,00.html" title="שער הדולר" target="_blank">שער הדולר</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.calcalist.co.il/stocks/home/0,7340,L-4135-22242222,00.html" title="שער היורו" target="_blank">שער היורו</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.alljobs.co.il/?utm_source=ynet&amp;utm_medium=cooperation&amp;utm_content=Internal%20Pages%20Footer&amp;utm_campaign=Placements%20-%20Permanent" title="דרושים" target="_blank">דרושים</a>
                </li>

                <li><span className="seperator">|</span>
                    <a href="//nadlan.ynet.co.il/search/apartments-for-rent" title="דירות להשכרה" target="_blank">דירות להשכרה</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.bigdeal.co.il/" title="קופונים" target="_blank">קופונים</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//nadlan.ynet.co.il/search/apartments-for-sale" title="דירות למכירה" target="_blank">דירות למכירה</a>
                </li>
                <li>
                    <span className="seperator">|</span>
                    <a href="//www.ynet.co.il/home/0,7340,L-10978,00.html?utm_source=ynet.ros&amp;utm_medium=ros_D.photer&amp;utm_campaign=baaley.miktzua&amp;utm_content=baaley.miktzua-ros_D.photer" title="בעלי מקצוע" target="_blank">בעלי מקצוע</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//bit.ly/1GS78ns" title="עברית" target="_blank">עברית</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//nadlan.winwin.co.il/" title="דירות חדשות" target="_blank">דירות חדשות</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.ynetart.co.il/?utm_source=ynet.ros&amp;utm_medium=ros_D.photer&amp;utm_campaign=general&amp;utm_content=ynetart-ros_D.photer" title="ynetArt" target="_blank">ynetArt</a>
                </li>
                <li>
                    <span className="seperator">|</span>
                    <a href="//www.yedschool.co.il/" title="ידיעות בתי ספר" target="_blank">ידיעות בתי ספר</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="http://yedioth-magazine.co.il/" title="מנוי למגזין" target="_blank">מנוי למגזין</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.yediot.co.il/home/0,7340,L-3630,00.html" title="ידיעות אחרונות" target="_blank">ידיעות אחרונות</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="https://yit.co.il/#utm_source=ynet.co.il&utm_medium=referral&utm_campaign=footer3&utm_term=web%20development" title="פיתוח אתרים" target="_blank">פיתוח אתרים</a></li>
                <li><span className="seperator">|</span>
                    <a href="https://yit.co.il/#utm_source=ynet.co.il&utm_medium=referral&utm_campaign=footer3&utm_term=apps%20development" title="פיתוח אפליקציות" target="_blank">פיתוח אפליקציות</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.yad2.co.il/realestate/rent" title="חיפוש דירות להשכרה" target="_blank">חיפוש דירות להשכרה</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.yad2.co.il/realestate/forsale" title="דירות למכירה מיד שנייה" target="_blank">דירות למכירה מיד שנייה</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//www.sefereshet.org.il/#/home" title="ספרשת" target="_blank">ספרשת</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="//yschool.co.il/" title="ymath - בני גורן" target="_blank">ymath - בני גורן</a>
                </li>
                <li><span className="seperator">|</span>
                    <a href="https://nadlan.ynet.co.il/?externalurl=true" title="דירות בפרויקטים חדשים" target="_blank">דירות בפרויקטים חדשים</a>
                </li>
            </ul>
        </div>
    )
}