import { CategorySelectionItem } from "global/components/categorySelectInput/categorySelectInput"
import { TagSelectItem } from "articlePage/stateInterfaces"
import { ArticleSelectionItem } from "global/components/articleSelectInput/articleSelectInput"
import { StringEnum } from "utils/stringEnum"
import { ContentTypes } from "constants/contentTypes"
import { SelectionState } from "draft-js"

export const BaseLinkTypes = StringEnum(
    ContentTypes.NONE,
    ContentTypes.ARTICLE,
    ContentTypes.CATEGORY,
    ContentTypes.EXTERNAL_URL,
    ContentTypes.TAG,
    ContentTypes.COMMERCIAL,
    ContentTypes.FILE,
    ContentTypes.MAIL,
    ContentTypes.MEDIA_VIDEO,
    ContentTypes.STOCK
)
export type BaseLinkTypes = StringEnum<typeof BaseLinkTypes>;
export interface LinkPropertiesObject<T extends string> {
    linkProperties: LinkProperties<T>
    linkTargetProperties: LinkTargetProperties
    articleLinkId?: string;
    isArticleDrag?: boolean
    selection?: SelectionState
}

export interface LinkProperties<T extends string> {
    type: T,
    link: LinkData,
}

export type LinkOpenType = "same" | "new" | "lightBox"

export interface LinkTargetProperties {
    linkOpenType: LinkOpenType,
    lightBoxWidth: number,
    lightBoxHeight: number,
    lightBoxOpacity: number,
    lightBoxBgColor: string,
}

export interface LinkUrlOption<T extends string> {
    value: T
    label: string
    inputRenderer?: LinkInputRenderer
}

export type LinkInputRenderer = (data: LinkData, onChange: (data: LinkData) => void) => __React.ReactElement<{ data: LinkData, onChange: (data: LinkData) => void }>

export interface LinkData {
    id: string
    description: string
    publishedLink?: string
    extraLinkData?: any
    isSeriesVOD?: boolean
}

