import * as axios from "axios"
import * as moment from "moment"
import { CtechChannelMenu } from "../../ctechWideBuzz/components/ctechChannelMenu"
import { CtechDatePickerMenu } from "../../ctechWideBuzz/components/ctechDatePickerMenu"
import { TaboolaComponenta } from "../../../taboola/taboolaComponenta"
import { CtechTwentyFourSevenWideComponentaItem } from "./ctechTwentyFourSevenWideItem"
import { Ref } from "react"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils"

declare var window: Window & {
    googletag:any;
    AdUnitPropertiesClassObj:any;
}

interface CtechTwentyFourSevenWideState {
    currChannle: any
    isChannelMenuVisible: boolean
    isDateMenuVisible: boolean
    currPage: number
    type: string
    limitDate: string
    articles: any[]
    isLoadMore: boolean
    isDateFilterMode: boolean
    videoOnly: boolean
    currDate: Date
    currentActiveTab: tabNameCategory
}

enum tabNameCategory {
    ARTICLES = "ARTICLES",
    VIDEOS = "VIDEOS"
};

interface CtechTwentyFourSevenWideProps {
    categoryList: CategoryListItem[]
    useSubcategories: boolean
    numberOfItems: number
    isLTR: boolean
}

export interface CategoryListItem {
    description: string
    id: string
}
export class SiteCtechTwentyFourSevenWideComponenta extends React.Component<CtechTwentyFourSevenWideProps, CtechTwentyFourSevenWideState> {
    public static siteScriptName = "SiteCtechTwentyFourSevenWideComponenta"
    constructor(props) {
        super(props)
        this.handleDayClick = this.handleDayClick.bind(this);
        const { isLTR } = this.props;

        this.state = {
            currChannle: isLTR ? { description: 'Ctech', id: '5211' } : { description: 'כל הערוצים', id: '1' },
            isChannelMenuVisible: false,
            isDateMenuVisible: false,
            currPage: 0,
            limitDate: '',
            type: "CATEGORY",
            articles: [],
            isLoadMore: false,
            isDateFilterMode: false,
            videoOnly: false,
            currDate: new Date(),
            currentActiveTab: tabNameCategory.ARTICLES
        }
    }
    componentDidMount() {
        this.getArticles()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.limitDate !== prevState.limitDate ||
            this.state.currPage !== prevState.currPage ||
            this.state.currChannle !== prevState.currChannle ||
            this.state.videoOnly !== prevState.videoOnly) {
            this.getArticles();
        }
    }
    private onChannelChanged = (newChannle) => {
        this.toggleChannelMenu()
        this.setState({ currChannle: newChannle })
    }
    private toggleChannelMenu = () => {
        this.setState({
            isChannelMenuVisible: !this.state.isChannelMenuVisible,
            isDateMenuVisible: (this.state.isDateMenuVisible && !this.state.isChannelMenuVisible) ? false : this.state.isDateMenuVisible
        })
    }
    private toggleDateMenu = () => {
        this.setState({
            isDateMenuVisible: !this.state.isDateMenuVisible,
            isChannelMenuVisible: (this.state.isChannelMenuVisible && !this.state.isDateMenuVisible) ? false : this.state.isChannelMenuVisible
        })

    }
    private handleDayClick = (day, modifiers = {}) => {
        if (modifiers['disabled']) {
            return;
        } else {
            this.setState({
                limitDate: day ? day : '',
                isDateFilterMode: true,
                currPage: 0
            });
            this.toggleDateMenu();
        }
    }
    private loadMoreArticles = () => {
        this.setState({ currPage: this.state.currPage + 1 })
    }
    private getArticles = () => {
        const { numberOfItems, useSubcategories } = this.props
        const { currChannle, type, currPage, limitDate, articles, isDateFilterMode, videoOnly } = this.state
        const videoOnlyRequest = Number(videoOnly);
        const useSubcategoriesRequest = Number(useSubcategories);
        let limitDateRequest:string;

        if (isDateFilterMode && limitDate) {
            limitDateRequest = formatDate(new Date(limitDate),"YYYY-MM-DD");
        }else{
            limitDateRequest = formatDate(new Date(),"YYYY-MM-DD");
        }
        axios.get(`/iphone/json/api/twenty_four_seven_wide/${currChannle.id}/${numberOfItems}/${useSubcategoriesRequest}/${videoOnlyRequest}/${currPage}/${limitDateRequest}`)
            .then((res: any) => {
                const recivedArticles = res.data.data;
                this.setState({
                    articles: (currPage > 0) ? [...articles, ...recivedArticles] : recivedArticles,
                    isLoadMore: (recivedArticles.length < numberOfItems) ? false : true,
                },()=>this.onDfpRefresh())
            })
            .catch(err => console.error("error geting articles data:", err))
    }
    private getUTCHDateByIndex = (currDate, index) => {
        return new Date(new Date().setDate(currDate.getDate() - index))
    }

    private handleTabPick = (event) => {
        const selectedTab = event.target.getAttribute("data-value");
        const isVideoOnly = selectedTab === tabNameCategory.VIDEOS;
        this.setState({ currentActiveTab: selectedTab, videoOnly: isVideoOnly })
    }

    private onDfpRefresh=()=>{
        if( window.googletag && window.AdUnitPropertiesClassObj && window.AdUnitPropertiesClassObj.Define_slots["flash.central"]){
            window.googletag.cmd.push(function() { window.googletag.pubads().refresh([window.AdUnitPropertiesClassObj.Define_slots["flash.central"]]); });
        }
    }

    private showDayByString=(date:Date)=>{
            return date.toLocaleDateString(this.props.isLTR?'en-US':"he", { weekday: 'long' })
    }


    render() {
        const { categoryList ,isLTR } = this.props;
        const { currChannle, isChannelMenuVisible, limitDate, articles, isDateMenuVisible, isLoadMore, currDate, currentActiveTab } = this.state
        let headings: Map<string, { text: string, used: boolean }> = new Map();
        const endWeek = currDate.getDay()
        headings.set('default', { text: isLTR?'More Articles':'עוד כתבות', used: false })
        for (let i = 0; i < endWeek + 1; i++) {
            if (i > endWeek) {
                headings.set(toDateWithoutHour(this.getUTCHDateByIndex(currDate, i)), { text: isLTR?'More Articles':'עוד כתבות', used: false })
            } else {
                switch (i) {
                    case 0:
                        headings.set(toDateWithoutHour(currDate), { text: `${this.showDayByString(currDate)} ${toDateWithoutHour(currDate)} (${isLTR?'Today':'היום'})`, used: false })
                        break;
                    case 1:
                        headings.set(toDateWithoutHour(this.getUTCHDateByIndex(currDate, i)), { text: `${this.showDayByString(this.getUTCHDateByIndex(currDate,i))} ${toDateWithoutHour(this.getUTCHDateByIndex(currDate, i))} (${isLTR?'Yesterday':'אתמול'})`, used: false })
                        break;
                    case 2:
                        headings.set(toDateWithoutHour(this.getUTCHDateByIndex(currDate, i)),{ text: `${this.showDayByString(this.getUTCHDateByIndex(currDate,i))} ${toDateWithoutHour(this.getUTCHDateByIndex(currDate, i))} (${isLTR?'Two days ago':'יומיים'})`, used: false })
                        break;
                    default:
                        headings.set(toDateWithoutHour(this.getUTCHDateByIndex(currDate, i)),{ text: `${this.showDayByString(this.getUTCHDateByIndex(currDate,i))} ${toDateWithoutHour(this.getUTCHDateByIndex(currDate, i))} (${i} ${isLTR?'Days ago':'ימים עברו'})`, used: false })
                        break;
                }
            }
        }
        const dayPickerPreferences = {
            showOutsideDays: true,
            month: new Date(),
            toMonth: new Date(),
            fixedWeeks: true,
            disabledDays: [
                {
                    after: new Date(new Date().setDate(new Date().getDate()))
                }
            ]
        }

        const itemComponents = []
        articles.forEach((item, index) => {

            if(!isLTR){
                if (index == 2) {
                    itemComponents.push(<div className="dfp-item item" key="dfp" id="flash.central"></div>);
                }
                if (index == 9) {
                    itemComponents.push(<div key="taboola" className="item"> <TaboolaComponenta container="taboola-category-mid-page-link" mode="text-links-a" placement="Category Mid Page Link" target_type="mix" /></div>)
                }
            }

            let headingDiv = null;
            const headingKey = toDateWithoutHour(new Date(item.dateUpdatedOnSite))
            const headingDetials = headings.get(headingKey)
            if (!headingDetials && !headings.get('default').used) {
                headingDiv = <div className="dayHeader" key={headingKey}>{headings.get('default').text}</div>
                headings.get('default').used = true;
            }

            if (headingDetials && !headingDetials.used) {
                headingDiv = <div className="dayHeader" key={headingKey}>{headingDetials.text}</div>
                headingDetials.used = true;
            }

            itemComponents.push(
                <div key={item.articleId}>
                    {headingDiv}
                    <CtechTwentyFourSevenWideComponentaItem
                        endWeek={endWeek}
                        isLTR={isLTR}
                        item={item}
                        index={index}
                        articles={articles}
                         />
                </div>
            )
        });
        


        return (
            <div className="SiteCtechTwentyFourSevenWideComponenta">
                <div className="leftSide">
                    {!this.props.isLTR &&
                        <div className="mainMenu">
                            <span data-value={tabNameCategory.ARTICLES} onClick={this.handleTabPick} className={`tab ${currentActiveTab === tabNameCategory.ARTICLES ? 'active' : ''}`}>כתבות</span>
                            <span data-value={tabNameCategory.VIDEOS} onClick={this.handleTabPick} className={`tab ${currentActiveTab === tabNameCategory.VIDEOS ? 'active' : ''}`}>וידאו</span>
                        </div>
                    }
                    <CtechChannelMenu toggleChannelMenu={this.toggleChannelMenu} currChannle={currChannle}
                        isChannelMenuVisible={isChannelMenuVisible} categoryList={categoryList} onChannelChanged={this.onChannelChanged} isLTR={isLTR} />
                    <CtechDatePickerMenu toggleDateMenu={this.toggleDateMenu} limitDate={limitDate}
                        isDateMenuVisible={isDateMenuVisible} handleDayClick={this.handleDayClick}
                        dayPickerPreferences={dayPickerPreferences} isLTR={isLTR} />
                </div>


                <div className="rightSide">
                    {
                        itemComponents
                    }
                    <div className="loadMore">
                        <button className="loadMoreButton" disabled={!isLoadMore || articles.length === 0} onClick={this.loadMoreArticles}>{isLTR?'Load more':'טען עוד'}</button>
                    </div>
                </div>
            </div>

        )

    }
}

export function toDateWithoutHour(date: Date) {
    return `${formatNum(date.getDate())}.${formatNum(date.getMonth() + 1)}.${date.getFullYear().toString().substr(-2)}`

}
export function toHourWithoutDate(date: Date) {
    const minutes = formatNum(date.getMinutes())
    const hours = formatNum(date.getHours())
    return `${hours}:${minutes}`

}
export function datesAreOnSameDay(first: Date, second: Date) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
}
function formatNum(num: number) {
    const displayNum = num < 10 ? '0' + num : num;
    return displayNum
}
export function datesAreOnSameWeek(first: Date, second: Date, endOfTheWeek: number) {
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = second.getTime() - first.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays >= -endOfTheWeek
}

