import { SortingOptions, CommentFormType } from "./interfaces"


export interface ArticleCommentsTopTabRenderProps {
    lang: string
    isLtr: boolean
    numOfComments: number
    numOfDiscussions: number
    sortSelection: SortingOptions
    areAllCommentsExpanded: boolean
    isAddCommentFormVisible: boolean
    expandAllComments: (shouldExpandComments: boolean) => void
    handleFormVisibility: () => void
    handleSortSelection: (e: any) => void
    handleCommentsPopupVisibility: () => void
}

export type ArticleCommentsTopTabRender = (
    props: ArticleCommentsTopTabRenderProps
) => React.ReactElement<any>

interface ArticleCommentsTopTabWrapperProps {
    render: ArticleCommentsTopTabRender
    lang: string
    isLtr: boolean
    numOfComments: number
    numOfDiscussions: number
    sortSelection: SortingOptions
    areAllCommentsExpanded: boolean
    isAddCommentFormVisible: boolean
    expandAllComments: (shouldExpandComments: boolean) => void
    handleFormVisibility: () => void
    handleSortSelection: (e: any) => void
    handleCommentsPopupVisibility: () => void
}

export class ArticleCommentsTopTabWrapper extends React.Component<ArticleCommentsTopTabWrapperProps, {}>{

    render() {
        const { lang, isLtr, numOfComments, numOfDiscussions, sortSelection, areAllCommentsExpanded,
            isAddCommentFormVisible, expandAllComments, handleFormVisibility, handleSortSelection, handleCommentsPopupVisibility } = this.props
        return (
            <div>
                {this.props.render({
                    lang,
                    isLtr,
                    numOfComments,
                    numOfDiscussions,
                    sortSelection,
                    areAllCommentsExpanded,
                    isAddCommentFormVisible,
                    expandAllComments,
                    handleFormVisibility,
                    handleSortSelection,
                    handleCommentsPopupVisibility
                })}
            </div>
        )
    }
}