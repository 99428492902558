//https://www.the-art-of-web.com/javascript/creating-sounds/
//https://marcgg.com/blog/2016/11/01/javascript-audio/
//https://ourcodeworld.com/articles/read/1627/how-to-easily-generate-a-beep-notification-sound-with-javascript

import {disablePageRefresh, enablePageRefresh} from "../../../../../siteWidgets/siteWidgetUtils";
import {isWcmEditor} from "../../../../../../config/serverConfig";

interface TimerSectionProps {
    timerSeconds: number
    inlineTimerToShow: string
}

interface TimeSectioState {
    isRunning: boolean
    isPaused: boolean
    isMuted: boolean
    isPlayBtnDisabled: boolean
    isCloseBtnVisable: boolean
    timerTime: number
}

declare var window: Window & {
    AudioContext: typeof AudioContext
    webkitAudioContext: typeof AudioContext
}

export class TimerComponenta extends React.Component<TimerSectionProps, TimeSectioState> {
    public static siteScriptName = "TimerComponenta";
    private timerInterval;
    private audioContext = null;
    private shouldStopTimer: boolean = false

    constructor(props) {
        super(props)
        this.state = {
            isRunning: false,
            isPaused: false,
            isMuted: false,
            isPlayBtnDisabled: false,
            isCloseBtnVisable: false,
            timerTime: props.timerSeconds,
        }
    }

    componentDidMount() {
        const audioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new audioContext();
    }

    generateNote = (frequency: number) => {
        if (this.state.isMuted) return;
        const oscillator = this.audioContext.createOscillator();
        const gainNode = this.audioContext.createGain();

        oscillator.type = "sine";
        oscillator.frequency.value = frequency;
        oscillator.connect(gainNode)
        gainNode.connect(this.audioContext.destination)

        oscillator.start(0); // immediately starts when triggered
        gainNode.gain.exponentialRampToValueAtTime(0.00001, this.audioContext.currentTime + 3);
        oscillator.stop(this.audioContext.currentTime + 3);
    }

    playTimerAlarmSound = async () => {
        const { timerSeconds } = this.props
        const c = 523.25;
        const e = 659.26;
        const g = 783.99;
        const notesGap = 200;
        const sequenceGap = 1000;

        for (let i = 0; i < 6; i++) {
            if (this.shouldStopTimer) {
                this.shouldStopTimer = false
                break;
            }

            await Promise.resolve()
                .then(() => this.generateNote(c))
                .then(() => delay(notesGap))
                .then(() => this.generateNote(e))
                .then(() => delay(notesGap))
                .then(() => this.generateNote(g))
                .then(() => delay(sequenceGap))
        }

        this.setState({ timerTime: timerSeconds, isRunning: false, isCloseBtnVisable: false, isPlayBtnDisabled: false });
        enablePageRefresh();
    }

    handlePlayBtnClick = () => {
        const { isRunning, isPaused } = this.state

        if (isRunning) {
            clearInterval(this.timerInterval);
            this.setState({ isPaused: true })
        } else {
            this.audioContext.resume() //necessary for webkitAudioContext (safari)
            this.timerInterval = setInterval(() => this.updateTimer(), 1000)

            if (isPaused) {
                this.setState({ isPaused: false })
            }

            this.setState({ isCloseBtnVisable: true })
            disablePageRefresh();
        }

        this.setState({ isRunning: !isRunning })
    }

    updateTimer = () => {
        const { timerTime } = this.state

        this.setState({ timerTime: timerTime - 1 });

        if (timerTime < 1) {
            this.setState({ isPlayBtnDisabled: true })
            this.playTimerAlarmSound()
            clearInterval(this.timerInterval);
        }
    }

    handleMuteBtnClick = () => {
        this.setState({ isMuted: !this.state.isMuted })
    }

    handleCloseBtnClick = () => {
        clearInterval(this.timerInterval);
        this.shouldStopTimer = true;
        this.setState({ timerTime: this.props.timerSeconds, isRunning: false, isCloseBtnVisable: false, isPlayBtnDisabled: false });
    }

    getTimeToDisplay = () => {
        const { timerTime } = this.state

        const h = Math.floor(timerTime / (60 * 60)).toString();
        const m = Math.floor(timerTime % 3600 / 60).toString();
        const s = Math.floor(timerTime % 3600 % 60).toString();
        
        const hours = h.length < 2 ? '0' + h : h
        const minutes = m.length < 2 ? '0' + m : m
        const seconds = s.length < 2 ? '0' + s : s
        
        return timerTime < 0 ? '00:00:00' : `${hours}:${minutes}:${seconds}`
    }
    
    render() {
        const { isRunning, isMuted, isCloseBtnVisable, isPlayBtnDisabled, timerTime } = this.state;
        const {inlineTimerToShow} = this.props;
        const wcmEditorClass = isWcmEditor() ? 'editor-mode' : '';
        const timerDisplayContent = isWcmEditor() ? inlineTimerToShow : this.getTimeToDisplay() ;

        return (
            <div className={`article-recipe-timer-left-section ${wcmEditorClass}`}>
                <div className="timer-display">{timerDisplayContent}</div>

                <div className="timer-btns-wrapper">
                    <button className={`timer-btn-container ${isPlayBtnDisabled ? 'disabled' : ""}`} disabled={isPlayBtnDisabled} onClick={this.handlePlayBtnClick}>
                        <div className={`${isRunning ? "timer-pause-btn" : "timer-play-btn"} timer-icon`}></div>
                    </button>

                    <button className="timer-btn-container" onClick={this.handleMuteBtnClick}>
                        <div className={`${isMuted ? "timer-sound-off-btn" : "timer-sound-on-btn"} timer-icon`}></div>
                    </button>

                    {isCloseBtnVisable &&
                        <button onClick={this.handleCloseBtnClick} className="timer-close-btn-container">
                            <div className="timer-icon timer-close-btn"></div>
                        </button>}
                </div>
            </div>
        )
    }
}

const delay = (duration) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), duration);
    });
}