import { isWcmEditor } from "config/serverConfig"
import * as map from "lodash/map"

interface SearchProps {
    width: number
    domain: string

}
export type SearchType = "articles" | "stocks" | "video" | "internet";
export interface SearchList {
    type: SearchType
    title: string
}
export interface stockObj {
    sug: string
    linkto: string
}
interface SearchState {
    searchType: SearchType
    typedUserInput: string,
    stocksResultsValue: any[]
    stockValue: string
    selectedStock: stockObj
}
export class CalcalistSearchComponenta extends React.Component<SearchProps, SearchState> {
    constructor(props) {
        super(props)
        this.state = {
            searchType: "articles",
            typedUserInput: "",
            stocksResultsValue: null,
            selectedStock: null,
            stockValue: ""

        }
    }
    private updateInputValue = (evt) => {
        this.setState({ typedUserInput: evt.target.value });
    }

    private search = () => {
        const { typedUserInput } = this.state;
        const { domain } = this.props
        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let urlTail = ""

        if (!isWcmEditor()) {
            urlHead = `${domain}/category/3735`
            userInput = typedUserInput
            urlTail = "?cx=partner-pub-0047680307646695:9309925491&cof=FORID:10&ie=UTF-8&q="
            fullUrl = urlHead + urlTail + userInput
            location.href = fullUrl
        }

    }

    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }






    render() {
        const { width } = this.props
        const { typedUserInput } = this.state
        let typeList: SearchList[] =
            [{ type: "articles", title: "כתבות" }]
        let style = { width: `${width}px` }
        return (
            <div className="SearchArea" style={style}>
                <div className="searchContainer">
                    <ul className="searchTypeList">
                        {map(typeList, (item, i) =>
                            <li key={i} id={item.type} >
                                <a data-name={item.type} className={"active"}>{item.title}</a>
                            </li>
                        )}
                    </ul>
                    <div className="search-field">
                        <input
                            type="text"
                            id="queryString"
                            placeholder="חפש"
                            maxLength={25}
                            onKeyPress={this.handleInputKeyPress}
                            onChange={this.updateInputValue}
                            value={typedUserInput} />
                        <span className="search-btn"></span>
                    </div>
                </div>
            </div>
        )
    }
}
