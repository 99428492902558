
import DayPicker from "react-day-picker"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils"


export const CtechDatePickerMenu = ({ toggleDateMenu, limitDate, isDateMenuVisible, handleDayClick, dayPickerPreferences, isLTR, isInitialLimitDate = false }) => {
    const dateTitle = isLTR ? 'Date' : 'תאריך'
    const local = isLTR ? 'en' : 'he'
    const months = {
        he: [
            "ינואר",
            "פברואר",
            "מרץ",
            "אפריל",
            "מאי",
            "יוני",
            "יולי",
            "אוגוסט",
            "ספטמבר",
            "אוקטובר",
            "נובמבר",
            "דצמבר"
        ]
    }
    const weekDaysLong = { he: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"] }
    const weekdaysShort = { he: ["א", "ב", "ג", "ד", "ה", "ו", "ש"] }
    const firstDayOfWeek = { he: 0 }
    const labels = { he: { nextMonth: "חודש הבא", previousMonth: "חודש קודם" } }
    return (
        <div className="CtechDatePickerMenu">
            <span className="selectDateArrow" onClick={toggleDateMenu} />
            <span className={`selected ${limitDate && 'limitDate'}`} onClick={toggleDateMenu}>{(limitDate && !isInitialLimitDate) ? formatDate(limitDate,"DD/MM/YYYY") : dateTitle}</span>
            {isDateMenuVisible && <DayPicker
                showOutsideDays={dayPickerPreferences.showOutsideDays}
                month={dayPickerPreferences.month}
                fromMonth={dayPickerPreferences.fromMonth}
                toMonth={dayPickerPreferences.toMonth}
                fixedWeeks={dayPickerPreferences.fixedWeeks}
                disabledDays={dayPickerPreferences.disabledDays}
                onDayClick={handleDayClick}
                // 
                local={local}
                months={months[local]}
                weekdaysLong={weekDaysLong[local]}
                weekdaysShort={weekdaysShort[local]}
                firstDayOfWeek={firstDayOfWeek[local]}
                labels={labels[local]}
            />}
        </div>
    )
}