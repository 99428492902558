import * as axios from "axios"
import { AutoFeedBlogItem } from "./blogsAutoFeedComponent"
import { getFullDate, getHourAndMinutes, scrollToElement, isToday, isYesterday, isXMinutesOld } from "../blogsAutoFeedUtils"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";
import { Loader } from "./loader/loader";
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../widgetsUtils";
import { WhatsAppShareButton } from "../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton";
import { MailShareButton } from "../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { executeScriptsFromHtml } from "../../../siteWidgets/siteWidgetUtils";

interface BlogAutoFeedItemsProps {
    item: AutoFeedBlogItem
    title: string
    index: number
}

interface BlogAutoFeedItemsState {
    isOpen: boolean,
    fullHtml: string
    loading: boolean
}

export class BlogAutoFeedItem extends React.Component<BlogAutoFeedItemsProps, BlogAutoFeedItemsState> {
    constructor(props: BlogAutoFeedItemsProps) {
        super(props)
        this.state = {
            isOpen: false,
            fullHtml: "",
            loading: false,
        }
    }

    componentDidMount() {
        const { item } = this.props


        const itemId = window.location.hash.slice(1);

        this.loadEmbedScripts();
        executeScriptsFromHtml(item.html);

        if (itemId && itemId === item.id) { // linked blog
            setTimeout(() => {
                this.scrollToElement(itemId);
                this.handleReadMore();
            }, 1300);
        }
    }

    private scrollToElement = (id: string) => {
        const el = document.querySelector(`.${id}`)
        el && el.scrollIntoView({ block: "start" })
    }

    private loadEmbedScripts = () => {
        typeof twttr !== "undefined" && twttr.widgets.load();
        typeof instgrm !== "undefined" && instgrm.Embeds.process();
    }

    private dispatchCustomEvent = (title: string) => {
        document.dispatchEvent(
            new CustomEvent("blogReadMoreChange",
                {
                    detail: {
                        label: title,
                    }
                }
            ))
    }


    handleReadMore = () => {
        const { item } = this.props
        const { fullHtml } = this.state
        if (fullHtml) {
            this.setState({ isOpen: true });
            setTimeout(() => {
                this.loadEmbedScripts();
                executeScriptsFromHtml(fullHtml);
            }, 250);
        } else {
            this.setState({ loading: true, isOpen: true }, () => {
                const axiosInstance = axios.create(addAxiosDateTransformerSettings());
                axiosInstance.get(`/iphone/json/api/article/blog/article/${item.id}`)
                    .then((res: any) => {
                        const data = res.data.html as string
                        this.setState({ fullHtml: data, loading: false });
                        setTimeout(() => {
                            this.loadEmbedScripts();
                            executeScriptsFromHtml(data);
                        }, 250);
                    })
                    .catch(err => console.error("error getting blog item HTML:", err))
            })
        }

        this.dispatchCustomEvent(item.title);
    }

    handleCloseReadMore = () => {
        const { item } = this.props

        this.setState({ isOpen: false }, () => {
            setTimeout(() => {
                this.loadEmbedScripts();
                executeScriptsFromHtml(item.html)
            }, 250);
        })
        document.getElementById(item.id).scrollIntoView();
        this.dispatchCustomEvent(item.title);
    }


    private handleShareBtnClicked = (button: string) => {
        const { item, title } = this.props
        if (typeof window !== "undefined") {
            let current_url = `${document.querySelector("link[rel=canonical]") ? (document.querySelector("link[rel=canonical]") as HTMLLinkElement).href : window.location.href}`;
            current_url = current_url.includes("#") ? current_url.split("#")[0] : current_url; // remove hash if exists e.g. resharing
            if (!current_url) return;
            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Blogs Auto Feed", title), "Blogs Auto Feed", item.id, item.id)
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Blogs Auto Feed", title), "Blogs Auto Feed", item.id, item.id)
                    break;

                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Blogs Auto Feed", title), "Blogs Auto Feed", item.id, item.id)
                    break;
                case "mail":
                    MailShareButton.openMailShare(item.title, current_url, "heBlog", () => analyticsPushFunc("Mail", "Blogs Auto Feed", title), "Blogs Auto Feed", item.id, item.id)
                    break;
                case "mobile":
                    this.openShare(current_url);
            }
        }
    }

    private openShare = async (current_url: string) => {
        const { item } = this.props
        const shareData = { url: current_url + "#" + item.id, title: item.title }
      
            try {
                // @ts-ignore 
                await navigator.share(shareData)
            } catch (err) {
                console.log("error with sharing function", err);
            }
        
      
    }
  /*  private shareLinkInApp =(id)=>{
        let current_url = `${document.querySelector("link[rel=canonical]") ? (document.querySelector("link[rel=canonical]") as HTMLLinkElement).href : window.location.href}`;
        current_url = current_url.includes("?") ? current_url.split("?")[0] : current_url;
       return current_url+"#"+id+"/share_article";
    }*/

    public render() {
        const { item } = this.props
        const { isOpen, fullHtml, loading } = this.state
       // const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;
      //  const url = typeof window !== "undefined" && window.location.href+"#"+item.id;
        return (
            <div className={`blogs-item-wrapper ${item.isPinned && "isPinned"}`} id={item.id}>
                {item.isPinned && <div className="pinIcon" />}
                {/* {this.isNew() && <div className="now-red-dot" />} */}
                <div className={`blogs-auto-feed-item responsive ${item.isPinned && "isPinned"} ${item.id}`}>
                    <div className="item-info">
                        <div className="title-row">
                            <div className="date-section">
                                {item.isNew && <div className="title-row__now">עכשיו</div>}
                                {isYesterday(item.date) && <div className="title-row__yesterday">אתמול</div>}
                                {isYesterday(item.date) && <span className="yesterdayComma">,</span>}
                                <div className="hour">
                                    {(isToday(item.date) || isYesterday(item.date)) && !item.isNew && getHourAndMinutes(item.date)}
                                </div>
                                <div className="date">
                                    {!isYesterday(item.date) && !isToday(item.date) && getFullDate(item.date, ".", "shortYear")}
                                </div>
                            </div>
                            <div className="title-row__separator"></div>
                            <div className="title-section">
                                <h3>{item.title}</h3>
                            </div>
                        </div>
                        <div className="authorAndSocial">
                            {item.author && <div className="auth-row">
                                {item.author}
                            </div>}
                            {item.author && <div className="auth-row__separator"></div>}
                           {/* {isApp ? <a className="mobileShareBtn"  href={this.shareLinkInApp(item.id)}></a> : */}
                           <div className="mobileShareBtn" onClick={() => this.handleShareBtnClicked("mobile")} />
                            <div className="social-icon mail" onClick={() => this.handleShareBtnClicked("mail")} />
                            <div className="social-icon fb" onClick={() => this.handleShareBtnClicked("fb")} />
                            <div className="social-icon tw" onClick={() => this.handleShareBtnClicked("tw")} />
                            <div className="social-icon wtap" onClick={() => this.handleShareBtnClicked("wa")} />
                        </div>
                    </div>
                    {loading ?
                        <div className="html-container loading-auto-feed-blog"><Loader /></div> :
                        <div className="html-container" dangerouslySetInnerHTML={{ __html: isOpen ? fullHtml : item.html }} />}
                    <div className="bottom-row">
                        {<div className="continue-read-wrapper" onClick={isOpen ? this.handleCloseReadMore : this.handleReadMore}>
                            {item.hasMore ? isOpen ?
                                <>
                                    <div className="continue-read">צמצם</div>
                                    <div className="little-arrow-pf up" />
                                </>
                                :
                                <>
                                    <div className="continue-read">
                                        להמשך קריאה
                                </div>
                                    <div className="little-arrow-pf" />
                                </>
                                :
                                null
                            }
                        </div>}

                    </div>
                </div>
            </div>

        )
    }
}