import { stampaType } from "../windows/logo/stampaSelector";

interface StampaProps {
    stampa: stampaType
}


export class StampaComponenta extends React.Component<StampaProps, {}> {



    render() {
        const { stampa } = this.props
        let imgSrc;
        if (stampa === "holiday") {
            imgSrc = "happy.png"
        }
        else if (stampa === "independence") {
            imgSrc = "NavbarPatch_M.png"
        }
        else if (stampa === "newyear") {
            imgSrc = "shana.png"
        }
        if (stampa === "none") return null
        else return (
            <img className={`stampa no-print ${stampa}`} src={`/images/1280/header/${imgSrc}`} />

        )


    }
}