import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage";

interface GallreyTabProps {
    text: string
    icon: string
    isVisible: boolean
}

export class VideoGalleryTab extends React.Component<GallreyTabProps, {}> {

    public render() {
        const { text, icon, isVisible } = this.props;
        if (isVisible) {
            return (
                <div className="VideoGalleryTab" >
                    {icon.length > 0 && <SiteSimpleImage className="tabIcon" src={icon} />}
                    {text}

                </div>
            )
        }
        else return null
    }
}

