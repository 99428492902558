
import { calculateAutomaticComponentaUrl } from "widgets/widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { ImageDetails } from "images/interfaces"
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { MediaVideoData } from "../../../../../media/interfaces";
import { ArticleType } from "../../../../../articlePage/stateInterfaces";
import { StandartSlotItemMutam, GeneralItemProp } from "../../generalMutamComps/standartSlotItemMutam";



interface CalcalistArticleHeadlinesItemProps {
    article: Partial<SiteArticleHeadlinesData>
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    showTaboola: boolean
    uid?: string
    isLTR?: boolean
}
export interface SiteArticleHeadlinesData {
    articleId: string
    author: string
    credit: string
    title: string
    subTitle: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean
    publishedLink: string
    publishedDate: Date
    roof?: string
    video?: MediaVideoData[]
    articleType?:ArticleType
}

export class CalcalistArticleHeadlinesItemMutam extends React.Component<CalcalistArticleHeadlinesItemProps, {}>{
    static defaultProps = { isCategoryVisible: true }

    public componentDidMount() {
        if (this.props.showTaboola) {
            window._taboola = window._taboola || [];
            window._taboola.push({
                mode: 'thumbnails-i',
                container: 'taboola-category-mid-page-stream',
                placement: 'Category Mid Page Stream',
                target_type: 'mix'
            });
        }
    }

    public render() {
        const { article, fieldsDisplaySettings, showTaboola } = this.props;
        const { title, author, promotionImageDetails, publishedDate, roof, hasVideo } = article
        const item = {
            title: title,
            roofTitle: roof && roof.length>0 ? roof : "",
            link: calculateAutomaticComponentaUrl(article),
            imageSrc:  apiUrls.getImageUrl(promotionImageDetails, { w: 270, h: 146 }),
            publishedDate: publishedDate,
            author: author,
            hasVideo: hasVideo,
        } as GeneralItemProp;
        return (
            <>
                {showTaboola && <div id="taboola-category-mid-page-stream" />}
                <StandartSlotItemMutam
                    fieldsDisplaySettings={fieldsDisplaySettings}
                    item={item}
                />
            </>
        )
    }
}