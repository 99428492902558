require("./copyButton.less")

interface CopyButtonProps {
    textToCopy: string
    classes?: string
    id: string
    isAlert?: boolean
    children?: React.ReactChild
}

export class CopyButton extends React.Component<CopyButtonProps, {}>{
    static defaultProps = { isAlert: false }

    private stopPropagationEvent = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    private copyToClipboard = (e, id) => {
        var node = document.createElement("div")
        node.innerHTML = __("Link copied");
        node.setAttribute("id", "alertCopyLink")
        { this.props.isAlert && document.getElementById(id).appendChild(node) }
        this.stopPropagationEvent(e);
        const { textToCopy } = this.props
        var textField = document.createElement('textarea')
        textField.innerText = textToCopy;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
    }

    private removeMessage = (e, id) => {
        { this.props.isAlert && document.getElementById("alertCopyLink") && document.getElementById("alertCopyLink").remove() }
    }

    render() {
        const { textToCopy, classes, id, children } = this.props
        return (
            <button onMouseOut={(e) => this.removeMessage(e, id)} aria-label={`${__("Copy link")} ${textToCopy}`} title={`${__("Copy link")} ${textToCopy}`}  id={id} className={`CopyButton yicon-link${NEW_TAMKA_UI ? "" : "-copy"} ${classes}`} onClick={(e) => this.copyToClipboard(e, id)} >{children}</button>
        )
    }
}