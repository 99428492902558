import * as classNames from "classnames"
require('./ctech_calcalist_site_search_area.less')

interface SearchProps {
    onToggleSearch: () => void
    isOpened: boolean
}

export class SearchButtonComponenta extends React.Component<SearchProps, {}> {
    constructor(props) {
        super(props)
    }
    render() {
        const { onToggleSearch, isOpened } = this.props
        let searchClassName = classNames("searchButton", { "active": isOpened })
        return (

            <div id="search-button" className={searchClassName} onClick={onToggleSearch}>
                <img alt="חיפוש" src="/images/1280/header/h_search.png" />
            </div>
        )


    }
}