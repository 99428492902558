require("./calcalist_site_newsLetterNativeComponenta.less");
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage";
import { isWcmEditor } from "../../../../../config/serverConfig";
import classNames = require("classnames");



interface CalcalistNewsLetterNativeItemProps {
  slotId: string;
  imgUrl: string;
  title: string;
  subTitle: string;
  frequency: string;
  groupId: string;
  onChange : (selectedItem:string)=> void
  isChecked : boolean
}


const CalcalistNewsLetterNativeItemCheckbox=({isChecked,onChange})=>{
  return(
    <div className="custom-checkbox">
        <input onChange={onChange} type="checkbox" checked={isChecked}></input>
        <span className="custom-checkbox-box"></span>
    </div>
  )
}

export class CalcalistNewsLetterNativeItem extends React.Component<CalcalistNewsLetterNativeItemProps, {}> {

  constructor(props) {
    super(props);
  }

  public render() {
    const { imgUrl, title, subTitle, frequency, groupId, onChange, isChecked } = this.props;
    const isFrequency = frequency !== 'ללא'
    return (
      <div className={classNames("newsLetterItem",{'checked':isChecked})} onClick={isWcmEditor()?null:()=>onChange(groupId)}>
       <CalcalistNewsLetterNativeItemCheckbox onChange={()=>onChange(groupId)} isChecked={isChecked} />
        <div className="newsLetterHeadear">
          <div className={"newsLetterItemTitle"}>{title}</div>
        </div>

        <div className="newsLetterItemBody">
          <div className="newsLetterItemImage">
            <SiteSimpleImage src={imgUrl} />
          </div>
          <div className={"newsLetterItemSubTitle"}>{subTitle}</div>
         { isFrequency && <div className="newsLetterItemFrequency"><span className="divider"></span><span className="text">{`עדכון ${frequency}`}</span><span className="divider"></span></div>}
        </div>
      </div>
    );
  }
}
