
import Slider from "react-slick";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"
import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { ContentSiteItemData } from "./commericalTeaserComponenta";
import { SiteMediaData } from "../../../../interfaces";
import { MediaContentTypes } from "constants/contentTypes"
import { SiteImageData } from "widgets/interfaces"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"


interface CommertialTeaserMobileProps {
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    tabBackgroundImage: string
    imageWidth: number
    imageHeight: number
    itemList: ContentSiteItemData[]
    fieldsDisplaySettings: any
    videoSettings: LinkedItemVideoSettings
    numberOfVisibleItems: number
    bottomLinkText: string
    tabTitleText: string
    tabSubTitleText: string
    commercialText: string
    gallerySeconds: number
    commericalTeaserType: string
}
interface ArrowProps {
    onClick?: () => void;
}

export class CommertialTeaserMobile extends React.Component<CommertialTeaserMobileProps, { currentSlide: number }>{
    public static siteScriptName = "CommertialTeaserMobile"
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0
        }
    }

    render() {
        const { tabLink, tabLinkTarget, tabBackgroundImage, imageWidth, imageHeight, itemList, fieldsDisplaySettings, videoSettings, numberOfVisibleItems,
            bottomLinkText, tabTitleText, tabSubTitleText, commercialText, commericalTeaserType } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const isMore = fieldsDisplaySettings.isAuthorVisible || fieldsDisplaySettings.isHourVisible || fieldsDisplaySettings.isDateVisible
        const isComma = fieldsDisplaySettings.isAuthorVisible && (fieldsDisplaySettings.isHourVisible || fieldsDisplaySettings.isDateVisible);
        const { currentSlide } = this.state;

        const NextArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == itemList.length - 1} onClick={onClick} className="arrows-pf LeftArrow-pf"></button>);
        const PrevArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == 0} onClick={onClick} className="arrows-pf RightArrow-pf"></button>);

        let settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            rtl: true,
            className: 'commercial-teaser-carusel',
            prevArrow: <NextArrow />,
            nextArrow: <PrevArrow />,
            afterChange: (index) => {
                this.setState({ currentSlide: itemList.length - 1 - index });
            }
        }

        return (
            <div className={`CommertialTeaserMobile-pf ${commericalTeaserType}`} >

                {<div className="header">
                    <div className="commercial-text"><span>תוכן שיווקי</span></div>
                    <HyperLinkBuilder className="tabTitleLink"
                        title={tabTitleText}
                        href={tabLink}
                        linkTarget={tabLinkTarget}>
                        <span className="tabTitle profitLine">{tabTitleText}</span>
                        <div className="Linkable">
                            <span>כדאי</span>&nbsp;<span>להכיר</span>
                        </div>
                    </HyperLinkBuilder>
                    <div className="tabSubTitleLink">
                        <span className="tabSubTitle">בשיתוף visionet</span>
                    </div>
                </div>}

                <div className="CommericalTeaserTab">
                    <HyperLinkBuilder className="tabTitleLink"
                        title={tabTitleText}
                        href={tabLink}
                        linkTarget={tabLinkTarget}>
                        <span className="tabTitle">{tabTitleText}</span>
                    </HyperLinkBuilder>
                    <span className="tabSubTitle">{tabSubTitleText}</span>
                </div>

                <div className="slotList">
                    <Slider {...settings}>
                        {itemList.map(item => (
                            <article key={item.itemId} className="slotView">
                                <div className="MediaCarousel">
                                    <SiteMedia
                                        allMedias={[]}
                                        data={item.media !== undefined ? item.media : emptyMedia}
                                        width={imageWidth}
                                        height={imageHeight}
                                        videoSettings={videoSettings}
                                        itemLinkUrl={item.titleLink}
                                        isImageLinkable={true}
                                        linkTarget={item.titleTargetLink}
                                    />
                                </div>
                                <div className="slotTitle">
                                    <HyperLinkBuilder
                                        href={item.titleLink}
                                        linkTarget={item.titleTargetLink}>
                                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                    </HyperLinkBuilder>
                                    {isMore && <div className="moreDetails">
                                        <span className="dateView">{separateHourFromDateAndFormatToString(item.date,
                                            { showHour: false, showDate: fieldsDisplaySettings.isDateVisible })} </span>
                                        {isComma && <span className="commaView"> | </span>}
                                        {fieldsDisplaySettings.isAuthorVisible && <span className="authorInfo" dangerouslySetInnerHTML={{ __html: item.author }} />}
                                    </div>}
                                </div>
                            </article>
                        ))}
                    </Slider>
                    <HyperLinkBuilder className="bottomTitleLink" href={tabLink} linkTarget={tabLinkTarget}>{bottomLinkText}</HyperLinkBuilder>
                </div>
            </div >
        )
    }
}





