import { SiteTickerData } from "./tickerComponenta";
import { TickerItemSlot } from "./tickerItemSlot"


interface TickerListProps {
    siteFlashList: Partial<SiteTickerData>[]
    titleColor: string
    subTitleColor: string
    isAuto: boolean
    isEnglish?: boolean
}

export class TickerList extends React.Component<TickerListProps, {}>{
    private getNumberofDuplicates = () => {
        const { siteFlashList } = this.props
        let number;
        if (siteFlashList.length > 0 && siteFlashList.length < 40) {
            number = Math.round(40 / siteFlashList.length)
        }
        else { number = 1 }

        return number

    }

    private replicateArray(array, n) {
        // Create an array of size "n" with undefined values
        var arrays = Array.apply(null, new Array(n));

        // Replace each "undefined" with our array, resulting in an array of n copies of our array
        arrays = arrays.map(function () { return array });

        // Flatten our array of arrays
        const r = [].concat.apply([], arrays);
        return r;
    }
    public render() {
        const { siteFlashList, titleColor, subTitleColor, isAuto, isEnglish } = this.props

        if (!siteFlashList || siteFlashList.length == 0) {
            return null
        }
        // const duplicatedList = siteFlashList.concat(siteFlashList.concat(siteFlashList))
        const duration = siteFlashList.length * 3 * this.getNumberofDuplicates()
        return (
            <div className="slotList"  >
                <div className="animationDiv" style={{ animationDuration: `${duration}s, ${duration}s`, animationDelay: `${0}s, ${0}s` }}>
                    {this.replicateArray(siteFlashList, this.getNumberofDuplicates()).map((flashItem, i) => <TickerItemSlot
                        articlePublishDate={flashItem.articlePublishDate}
                        articleUpdateDate={flashItem.articleUpdateDate}
                        title={flashItem.title}
                        flashDate={flashItem.flashDate}
                        flashDateType={flashItem.flashDateType}
                        linkTarget={flashItem.linkTarget}
                        link={flashItem.link}
                        key={i}
                        titleColor={titleColor}
                        subTitleColor={subTitleColor}
                        flashId={flashItem.slotId}
                        flashLinkType={flashItem.flashLinkType}
                        isAuto={isAuto}
                        articleId={flashItem.articleId}
                        isEnglish={isEnglish}
                    />)}

                </div>
            </div>
        )
    }
}