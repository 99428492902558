import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
interface promoYplusBtnProps {
    link: string
    text: string
    linkTarget: LinkTargetProperties
    isBottomButton?: boolean

}
export class PromoYplusBtn extends React.Component<promoYplusBtnProps, {}>{
    public static siteScriptName = "PromoYplusBtn";

    constructor() {
        super();
    }

    private analyticsPushPremium = (link: string, linkTarget: LinkTargetProperties, btnClassName: string) => {
        if (btnClassName == "bottomGoToLink") {
            let category = "Home Page CTR"
            let action = "Click on Componenta All ynet+ Articles button"
            let label = "button all articles"
            sessionStorage.setItem('Saved_Event_For_Tag_Manager', JSON.stringify({
                event: "GA_Event",
                Category: category,
                Action: action,
                Label: label
            }));
        }
        let target = "_self"
        if (linkTarget.linkOpenType && linkTarget.linkOpenType == 'new') {
            target = '_blank'
        }
        setTimeout(function () { window.open(link, target) }, 2000);
    }

    render() {

        const { text, link, linkTarget, isBottomButton } = this.props
        const btnClassName = isBottomButton ? "bottomGoToLink" : "promoBtn";

        if (text.length === 0) return null
        else {
            return (
                <button className={btnClassName} onClick={() => this.analyticsPushPremium(link, linkTarget, btnClassName)}>
                    <div>{text}</div>
                </button>
            )
        }
    }
}





