import { DataObjectBase } from "widgets/commonDataObjects/dataObjectBase"

type DataObjectFactory<T> = (data: Partial<T>) => DataObjectBase & T

const factories = new Map<string, DataObjectFactory<any>>()

export const registerDataClass = <T extends new (data: any) => any>(cls: T) => {
    if (!factories.get(cls.name)) {
        factories.set(cls.name, (data) => new cls(data));
    } else if (DEV_MODE) {
        console.error("duplicate data class: ", cls.name);
    }
    return cls as any
}

export function GetDataObject<T>(className: string, MissigDataClass: new () => DataObjectBase, data?: T) {
    const factory = factories.get(className);
    if (factory) {
        return factory(data);
    }
    console.error(`class name ${className} was not registered in data object factories`)
    return new MissigDataClass();
}


