import { RadioProgramItem } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"
import { useState } from "react"
import { CurrentShowItem } from "./currentShowItem";
import { NextShowsList } from "./nextShowsList";
import { RadioDayItemData } from "../../../radioWidgets/radioSchedule/data/radioDayItemData";

interface RadioScheduleComponentProps {
    liveBroadCastSource: string
    liveBroadCastDVRSource: string
    radioScheduleList: RadioProgramItem[]
    daysSchedule: RadioDayItemData[]
    startingIndex: number
    isMobileWeb: boolean
    numberOfVisibleDays: number
}

export const NasRadioScheduleComponent = (props: RadioScheduleComponentProps) => {
    const { liveBroadCastSource, radioScheduleList, daysSchedule, startingIndex, isMobileWeb, numberOfVisibleDays } = props
    const [currentShow, setCurrentShow] = useState(startingIndex)

    const handleCurrentShowIndexChange = (currentShowIndex: number) => {
        setCurrentShow(currentShowIndex)
    }

    return (
        <div className={isMobileWeb ? "mutam-nas-radio-schedule-wrapper" : "nas-radio-schedule-wrapper"}>
            <div className="component-title">راديو الناس</div>

            <CurrentShowItem
                isMobileWeb={isMobileWeb}
                currentShowIndex={currentShow}
                radioScheduleList={radioScheduleList}
                liveBroadCastSource={liveBroadCastSource}
                handleCurrentShowIndexChange={handleCurrentShowIndexChange}
            />

            <NextShowsList
                isMobileWeb={isMobileWeb}
                daysSchedule={daysSchedule}
                radioScheduleList={radioScheduleList}
                numberOfVisibleDays={numberOfVisibleDays}
                nextShow={radioScheduleList[currentShow + 1]}
            />
        </div>
    )
}

NasRadioScheduleComponent.siteScriptName = "NasRadioScheduleComponent"