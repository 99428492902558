import { GroupItemAtSite, GroupSlotItemData } from "./groupItemAtSite"
import { SimpleBottomLinkItemData } from "../data/bottomLinkItemData";
import { MultiArticleRotationDispalyDate } from "../data/multiArticleRotationData";


interface GroupsProps extends MultiArticleRotationDispalyDate {
    groupItemData: GroupSlotItemData[]
    bottomLinks: { [packNumber: number]: SimpleBottomLinkItemData[] }
    numberOfVisibleItems: number
    totalNumberofGroups: number
}

export class GroupsSite extends React.Component<GroupsProps, {}>{
    public static siteScriptName = "GroupsSite";

    private rotateGroupsView = () => {
        const { timeInterval, totalNumberofGroups } = this.props
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes()
        let timeMinutes = h * 60 + m
        let packNumber = Math.floor((timeMinutes / timeInterval) % totalNumberofGroups)
        return packNumber
    }
    public render() {
        const { groupItemData, numberOfVisibleItems, bottomLinks,
            ...rest } = this.props
        const packNumber = this.rotateGroupsView();
        const groupVisibleList = groupItemData
        let startSlot = packNumber * numberOfVisibleItems;
        let endSlot = startSlot + numberOfVisibleItems
        let groupSlotsList = groupItemData.slice(startSlot, endSlot)
        let bootomLinksToShow = bottomLinks[packNumber]
        return (
            <GroupItemAtSite
                {...rest}
                groupItemData={groupSlotsList}
                bottomLinks={bootomLinksToShow} />)
    }
}
