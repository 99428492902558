import { isQaHost } from "../../../../widgetsUtils"
import { logoutPremium, analyticsLogoutPushFunc, analyticsPremiumPushFunc } from "../../../article/componentas/premium/utils"
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { LogoutPopupComponent } from './LogoutPopupComponent'
require('./ynet_site_ynetPremiumLogin.less')
const clickOutside = require('react-click-outside');


interface YnetPremiumLoginState {
    user: { props: { firstName: string } }
    isMenuVisible: boolean
    isOpened?: boolean
    isLogoutPopupOpen?: boolean
}

@clickOutside
export class YnetPremiumLogin extends React.Component<{ isYnet1280?: boolean, promoText?: string, promoLink?: string, yplusLink?: string, layout?: string }, YnetPremiumLoginState>{
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            isMenuVisible: false,
            isOpened: false,
            isLogoutPopupOpen: false
        }
    }

    private handleClickOutside = () => {
        this.setState(pstate => {
            return {
                isMenuVisible: false,
                isOpened: false
            }
        })
    }

    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({
                user: window.YitPaywall.user
            })
        }
    }

    private toggleMenu = () => {
        this.setState(pstate => {
            return {
                isMenuVisible: !pstate.isMenuVisible,
                isOpened: !pstate.isOpened
            }
        })
    }

    private handleOnManageAccountClicked = () => {
        this.toggleMenu()
        analyticsPremiumPushFunc('ניהול חשבון')
        const hostname = window.location.hostname
        const qaLink = "https://qa-primium.ynet.co.il/Web/Manage"
        const prodLink = "https://premium.ynet.co.il/Web/Manage"
        const urlToOpen = isQaHost(hostname) ? qaLink : prodLink
        window.open(urlToOpen)
    }

    private handleOnArticlesClicked = () => {
        this.toggleMenu()
        analyticsPremiumPushFunc('כתבות +ynet')
        const hostname = window.location.hostname
        const qaLink = "https://qa-primium.ynet.co.il/Web/Manage/Main"
        const prodLink = "https://premium.ynet.co.il/Web/Manage/Main"
        const urlToOpen = isQaHost(hostname) ? qaLink : prodLink
        window.open(urlToOpen)
    }

    private handleOnLogoutClicked = () => {
        this.handleClickOutside();
        analyticsPremiumPushFunc('התנתקות')
        logoutPremium()
        this.setState({ user: null })
    }

    private handleOnContactUsClicked = () => {
        this.toggleMenu()
        analyticsPremiumPushFunc('שירות לקוחות')
        const hostname = window.location.hostname
        const prodLink = "https://www.ynet.co.il/plus/contact"
        const urlToOpen = prodLink
        window.open(urlToOpen)
    }

    private handleOnLogoutClickedIn1280 = () => {
        this.toggleMenu();
        this.setState(pstate => {
            return {
                ...pstate,
                isLogoutPopupOpen: true
            }
        })
    }

    private closeLogoutPopup = () => {
        this.setState(pstate => {
            return {
                ...pstate,
                isLogoutPopupOpen: false
            }
        })
    }

    private logOutFromPopUp = () => {
        this.closeLogoutPopup();
        this.handleOnLogoutClicked();
    }

    render() {
        const { user, isMenuVisible, isOpened, isLogoutPopupOpen } = this.state
        const loginClassName = this.props.isYnet1280 ? "YnetPremiumHeaderLogin" : "YnetPremiumLogin";
        const { layout } = this.props
        const promoLink = layout==="mobileWeb" ? `javascript:YitPaywall.openLoginPopUp()` : this.props.promoLink;
        return (
            <div className={loginClassName}>
                {!user && <a className="login_premium"
                    href={layout==="mobileWeb" ? `javascript:YitPaywall.openLoginPopUp()` : `javascript:YitPaywall.openLoginPopUp(false,{ redirect_to_plus : true })`}>
                    <div className="loginBtn">
                        <span className="headerIcon loggedUser" />
                        {this.props.isYnet1280 ? "התחברות" : <img src="/images/ynet_user_login.png" />}
                    </div>
                </a>}

                {user &&
                    <div className="loggedUser" onClick={() => this.toggleMenu()}>
                        <span className="headerIcon loggedUser" />
                        <span> שלום, <b>{user.props.firstName}</b></span>
                        <div className="menuTogglerWrapper" >
                            <div className={isOpened ? "menuToggler toClose" : "menuToggler toOpen"} />
                        </div>
                    </div>
                }

                {isMenuVisible && <div className="menu">
                    <div className="menuTogglerUpsideDown" />
                    <div className="menuOption" onClick={this.handleOnArticlesClicked}>כתבות +ynet</div>
                    <div className="menuOption" onClick={this.handleOnManageAccountClicked}>ניהול חשבון</div>
                    {this.props.isYnet1280 && <div className="menuOption" onClick={this.handleOnContactUsClicked}>שירות לקוחות</div>}
                    <div className="menuOption" onClick={this.props.isYnet1280 ? this.handleOnLogoutClickedIn1280 : this.handleOnLogoutClicked} id="logoutDesktopYnet">התנתקות</div>
                </div>}

                {(this.props.isYnet1280 && !user) && <SimpleLinkRenderer href={promoLink} className="promoLabel">{this.props.promoText}</SimpleLinkRenderer>}
                {(this.props.isYnet1280 && user) && <SimpleLinkRenderer href={this.props.yplusLink} className="yPlusLabel" ></SimpleLinkRenderer>}
                {(isLogoutPopupOpen && user) && <LogoutPopupComponent logOutFromPopUp={this.logOutFromPopUp} closeLogoutPopupOpen={this.closeLogoutPopup} />}
            </div>
        )
    }
}