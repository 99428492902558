import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { Logo } from "./siteYnetHeaderComponenta";

interface MainNavProps {
    mainNavItems: HamburgerMenuItems[]
    onToggleMenu: () => void
    isOpened: boolean
    tabBgColor: string
    isSticky: boolean
    logoData: Logo
    onToggleSearch: () => void
    displayType: string
}

export class MainNavHeaderComponenta extends React.Component<MainNavProps, {}> {
    private getSelectedClassName = (isSelected: boolean) => {
        if (isSelected) {
            return "selected"
        }
    }
    render() {
        const { mainNavItems, isOpened, onToggleMenu, tabBgColor, isSticky, logoData, onToggleSearch, displayType } = this.props
        let defaultColors = { tabBgColor: "#de1a1a" }
        const tabBackgruondStyle = tabBgColor ? tabBgColor : defaultColors.tabBgColor;
        const isLightMode = displayType && displayType === "light";
        const navStyle = isLightMode ? { borderBottom: `1px solid ${tabBackgruondStyle}` } : { backgroundColor: tabBackgruondStyle }
        return (
            <div className="mainNav" role="navigation" aria-label="Main Menu" style={navStyle}>
                {isSticky && <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle} className="ynetLogoInSticky" />}
                <div className="navList">
                    {_.map((mainNavItems), (item: HamburgerMenuItems, index: number) => <div key={index} >
                        <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} className={this.getSelectedClassName(item.isSelected)} style={{ color: isLightMode ? "#000000" : item.color, "--menuItemColor": isLightMode ? "#000000" : item.color, }}>
                            <span dangerouslySetInnerHTML={{ __html: item.title }} />
                        </HyperLinkBuilder>
                    </div>)}</div>
                <div onClick={onToggleMenu} id="wcm_MenuDisplay"><span className="moreBtn" id="wcm_MenuDisplay_moreBtn">עוד<span className={isOpened ? "moreIcon toClose" : "moreIcon toOpen"} /></span></div>
                {!isLightMode && <div onClick={onToggleSearch} id="wcm_SearchDisplay"><span className="searchIcon" id="wcm_MenuDisplay_searchIcon">חיפוש</span ></div>}

            </div>
        )
    }
}