import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { IFieldDisplayData } from "../../../../commonDataObjects/interfaces";
import { TickerSlotItem } from "./tickerSlotItem";

interface TickerSlotListProps {
    tickerArticles: ArticleExtraData[]
    tickerFieldsDisplaySettings: IFieldDisplayData
    isFirstInterval: boolean
    languageCode: string
    tickerBottomLink: string
    shouldDisplayLoader: boolean
    tickerBottomTitle: string
    shouldDisplayTicker: boolean
    highlightingDuration:number
    mobileDisplayType:string
}

export class TickerSlotList extends React.Component<TickerSlotListProps, {}> {

    public render() {
        const { mobileDisplayType, tickerArticles, tickerFieldsDisplaySettings, isFirstInterval, languageCode, tickerBottomLink, shouldDisplayLoader, tickerBottomTitle, shouldDisplayTicker, highlightingDuration } = this.props;
        const tickerLoaderClass = `newTickerLoader ${shouldDisplayLoader ? "" : "none"}`;
        const classNames = `tickerSlotListWrapper ${shouldDisplayTicker ? "" : "none"}`;

        return (
            <div className={classNames}>
                <img className={tickerLoaderClass} src={"https://www.ynet.co.il/images/NewsFlash/news-flashes-bell.gif"} />
                <div className="tickerSlotList">
                    {tickerArticles.map((article) => (
                        <TickerSlotItem
                            article={article}
                            tickerFieldsDisplaySettings={tickerFieldsDisplaySettings}
                            isFirstInterval={isFirstInterval}
                            languageCode={languageCode}
                            key={article.articleId} 
                            highlightingDuration={highlightingDuration}
                            mobileDisplayType={mobileDisplayType}/>
                    ))}
                </div>
                <a href={tickerBottomLink} className="bottomLink" id="ticker-bottom-link">
                    <span className=" bottomLinkTitle">{tickerBottomTitle}</span>
                    <div className="bottomLinkIcon" />
                </a>
            </div>
        )
    }
}