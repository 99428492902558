import { SiteSimpleImage } from "../siteSimpleImage";
require('./site_imageSlider.less')


interface imageSliderProps {
    sliderTitle?: string
    isSliderTitle: boolean
    imageUrl1: string
    title1: string
    credit1: string
    imageUrl2: string
    title2: string
    credit2: string
}
interface imageSliderState {
    sliderValue: number
    innerDivWidth: number
}

export class ImageSlider extends React.Component<imageSliderProps, imageSliderState> {
    public imageSlider: HTMLDivElement;
    constructor(props) {
        super(props)
        this.state = {
            sliderValue: 50,
            innerDivWidth: null
        }
    }
    public componentDidMount() {
        const sideBarHeight = this.imageSlider.clientWidth;
        this.setState({
            innerDivWidth: sideBarHeight
        })
    }

    public static siteScriptName = "ImageSlider"


    private updateSliderValue = (e: HTMLInputElement) => {
        const value = parseInt(e.value)
        this.setState({ sliderValue: value })

    }


    public render() {
        const { sliderTitle, isSliderTitle, imageUrl1, imageUrl2, credit1, credit2, title1, title2 } = this.props;
        const { sliderValue, innerDivWidth } = this.state
        return (
            <div className="ImageSlider" ref={ref => this.imageSlider = ref} >
                <div>
                    <div className="bottomPicture" >
                        <SiteSimpleImage src={imageUrl1} title={credit1} alt={credit1} />
                        <div className={`bottomImg imgTitle ${sliderValue < 60 ? "visible" : "hidden"}`} >{title1}</div>

                    </div>
                    <div className="topPicture" style={{ width: `${this.state.sliderValue}%` }}>
                        <div id="innerSliderDiv" style={{ width: `${innerDivWidth}px` }}>
                            <SiteSimpleImage src={imageUrl2} title={credit2} alt={credit2} />
                            <div className={`topImg imgTitle ${sliderValue > 40 ? "visible" : "hidden"}`}>{title2}</div>
                        </div>

                    </div>
                    {isSliderTitle && <div className="imagesSliderTitle" >{sliderTitle}</div>}
                    <input type="range" min="0" max="100" value={`${this.state.sliderValue}`} id="slider"
                        onChange={(e) => this.updateSliderValue(e.target as HTMLInputElement)}  ></input>
                </div>
            </div>
        )
    }
}
