import * as map from "lodash/map"
import { YnetTvSiteVideoData } from "../../../interfaces";
import { generateVideoId, attachPageRefreshUtilsYoutube } from "../videoPlayerUtils"
import { attachYoutubeAnalytics } from "./utils";

require("../site_videoPlayer.less");

const LIGHTBOX_WIDTH = 640;
const YT_DEFAULT_ASPECT_RATIO = 16 / 9;
const LIGHTBOX_HEIGHT = LIGHTBOX_WIDTH / YT_DEFAULT_ASPECT_RATIO;

const NUM_OF_THUMBS_TO_SHOW = 5;

interface SiteLightboxPlaylistVideoPlayerProps {
    data: YnetTvSiteVideoData
    allVideos: YnetTvSiteVideoData[]
    hideVideoAds: boolean
    onSelect?: () => void
}

interface state {
    lightBox?: boolean
    thumbsList?: YnetTvSiteVideoData[]
}

export class SiteYnetTvLightboxPlaylistYouTubePlayer extends React.Component<SiteLightboxPlaylistVideoPlayerProps, state>{
    public constructor(props: SiteLightboxPlaylistVideoPlayerProps) {
        super(props);
        this.state = {
            lightBox: false,
            thumbsList: props.allVideos.slice(0, NUM_OF_THUMBS_TO_SHOW)
        }
    }

    public player: YT.Player; // YT player instance

    public youtubePlayerAnchor; // player DOM ref

    public youtubePlayerContainerAnchor: HTMLDivElement; // player container DOM ref

    private playerDomID = generateVideoId();

    private posterId = generateVideoId();

    public componentDidMount() {
        const { data, allVideos, hideVideoAds } = this.props;

        window.YoutubeAPIControlller.load(this);

    }

    private onPlayerReady = (e) => {
        const { data } = this.props
        const player = e.target;

        attachYoutubeAnalytics(this, data.youtube_id);
        attachPageRefreshUtilsYoutube(this.player);

        if (data.isMuteAvailable) {
            player.mute()
        }
        if (data.isAutoPlaying) {
            player.playVideo();
        }
    }

    private onPlayerStateChange = (event) => {
        const player = event.target as YT.Player
        const status = event.data;

        // Pause other videos on play
        if (status == YT.PlayerState.PLAYING) {
            if (currentPlayingYoutubePlayer) {
                if (currentPlayingYoutubePlayer != player) {
                    currentPlayingYoutubePlayer.pauseVideo()
                }
            }
            currentPlayingYoutubePlayer = player;
        }
    }

    public createPlayer = () => {
        const { data } = this.props;
        this.player = new YT.Player(this.youtubePlayerAnchor, {
            videoId: data.youtube_id,
            width: LIGHTBOX_WIDTH,
            height: LIGHTBOX_HEIGHT,
            playerVars: {
                autoplay: data.isAutoPlaying ? 1 : 0,
                loop: data.isLoopPlaying ? 1 : 0,
                playlist: data.isLoopPlaying ? data.youtube_id : null, // Required for loop (see https://developers.google.com/youtube/player_parameters#loop).
                controls: data.isPlayerBarVisible ? 1 : 0,
                showinfo: 0,
                rel: 0
            } as YT.PlayerVars,
            events: {
                onReady: this.onPlayerReady,
                onStateChange: this.onPlayerStateChange
            } as YT.Events
        });
    }

    private onPlay = (e: React.MouseEvent) => {
        this.setState({
            lightBox: true
        })
    }

    private onClose = (e: React.MouseEvent) => {
        this.player.stopVideo();
        this.setState({
            lightBox: false
        })
    }

    private onClickOutside = (e: React.MouseEvent) => {
        this.state.lightBox ? this.onClose(e) : null;
    }

    private onClickCarouselButton = (side: "left" | "right") => {
        const { thumbsList } = this.state;

        const len = thumbsList.length
        const distance = side == "left" ? 1 : -1

        this.setState({
            // using modular arithmetic for circulation
            thumbsList: map(thumbsList, (e, i, thumbsInView) => thumbsInView[(i + (len + distance % len)) % len])
        })

    }

    public render() {
        const { data, allVideos, onSelect } = this.props;
        const { lightBox, thumbsList } = this.state;

        return (

            <div ref={ref => this.youtubePlayerContainerAnchor = ref} className={"siteYnetTvLightBoxYoutubePlayer"}>
                <div style={{ ...lightBoxStyle, display: lightBox ? "flex" : "none" }} onClick={this.onClickOutside}>

                    <div style={lightBoxPlayerStyle} onClick={e => { e.stopPropagation() }/*prevent invoking this.onClickOutside */}>
                        <div className="siteYnetTvLightBoxYoutubePlayer-header">
                            <img className="ynetTvLogo" src="//www.ynet.co.il/images/ynet_tv/mainlogo.png" />
                            <div className="ynetTvCloseButton" onClick={this.onClose} />
                        </div>
                        {/*actual player:*/}
                        <div ref={ref => this.youtubePlayerAnchor = ref} id={this.playerDomID} />

                        <div id="siteYnetTvLightBoxYoutubePlayer-footer">
                            <div id="siteYnetTvLightBoxYoutubePlayer-footer_inner">

                                <img src="https://www.ynet.co.il/images/ynet_tv/right_arrow.png" className="carouselButton carouselButton_right" onClick={() => this.onClickCarouselButton("right")} />
                                {thumbsList.map((v, k) =>
                                    <img className="siteYnetTvLightBoxYoutubePlayer-footer-thumbnail" src={v.poster} onClick={() => this.player.loadVideoById(v.youtube_id)} key={k} />
                                )}
                                <img src="https://www.ynet.co.il/images/ynet_tv/left_arrow.png" className="carouselButton carouselButton_left" onClick={() => this.onClickCarouselButton("left")} />

                            </div>
                        </div>
                    </div>



                </div>
                <img src={data.poster} onClick={this.onPlay} style={posterStyle} />
                <span className="videoIcon bigInCenter" style={{ pointerEvents: "none" }}>
                    <span className="icon" />
                </span>
            </div>
        )
    }
}



// Based on current (flow player) css:
const lightBoxPlayerStyle = {
    display: "block",
    maxWidth: "1000px",
    background: "black",
    position: "relative"

}

const lightBoxStyle = {
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.75)",
    zIndex: 9999
}

const posterStyle = {
    height: "100%",
    width: "100%",
}

