interface imaAdProps {
    adTag: string
    audioElement: HTMLAudioElement
    onContentPauseRequested: () => void
    onContentResumeRequested: () => void
    onAdProgressUpdate: (cuurentTime: number) => void
    updateAdDuration: (adDuration: number) => void
    onAdPlayStarted: () => void
    onAdPlayPause: () => void
    onAdPlayComplete: () => void
}

declare var google: any

export class ImaAd extends React.Component<imaAdProps> {

    private adDisplayContainer
    private adsLoader
    private adsManager
    private adLastPlayedTime = new Date().setMinutes(-5)

    public playAds = () => {
        try {
            if (this.shouldPlayAd()) {
                this.adDisplayContainer.initialize()
                this.adsManager.init()
                this.adsManager.start()
            } else {
                this.props.audioElement.play();
            }
        } catch (adError) {
            this.props.audioElement.play();
            console.error(adError);
        }
    }

    public pauseAd = () => {
        this.adsManager && this.adsManager.pause()
    }

    public resumeAd = () => {
        this.adsManager && this.adsManager.resume()
    }

    public stopAd = () => {
        this.adsManager && this.adsManager.stop()
    }

    public setAdVolume = (volume: number) => {
        this.adsManager && this.adsManager.setVolume(volume)
    }


    public restartAds = (shouldResetAdTimer?: boolean) => {
        if(shouldResetAdTimer) this.adLastPlayedTime = new Date().setMinutes(-5);
        this.adsLoader.contentComplete();
        this.adsManager.destroy();
        this.requestAds(this.props.adTag)
    }

    private initAds = (adsContainer: HTMLDivElement) => {
        const { audioElement } = this.props
        try {
            this.adDisplayContainer = new google.ima.AdDisplayContainer(adsContainer, audioElement); // Create the ad display container.
            this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer); // Create ads loader.

            this.requestAds(this.props.adTag)

            this.adsLoader.addEventListener(google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, this.onAdsManagerLoaded, false);
        } catch{
            console.error("Cannot init google.ima")
        }
    }

    private requestAds = (adTag: string) => {
        const adsRequest = new google.ima.AdsRequest(); // Create ads requset.
        adsRequest.adTagUrl = adTag
        this.adsLoader.requestAds(adsRequest); // Send ads request
    }

    private onAdsManagerLoaded = (adsManagerLoadedEvent) => {
        const { audioElement, onContentPauseRequested, onContentResumeRequested, onAdProgressUpdate } = this.props

        // Get the ads manager.
        const adsRenderingSettings = new google.ima.AdsRenderingSettings();
        adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
        // Instantiate the AdsManager from the adsLoader response and pass it the video element
        this.adsManager = adsManagerLoadedEvent.getAdsManager(audioElement, adsRenderingSettings);

        // Add listeners to the required events.
        this.adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, onContentResumeRequested);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, this.onAdEvent);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.AD_PROGRESS, (IMAEvent) => onAdProgressUpdate(IMAEvent.getAdData().currentTime));

        // Listen to any additional events, if necessary.
        this.adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, this.onAdEvent);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, this.onAdEvent);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, this.onAdEvent);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.PAUSED, this.onAdEvent);
        this.adsManager.addEventListener(google.ima.AdEvent.Type.RESUMED, this.onAdEvent);
    }

    /**
    * Handles ad errors.
    * @param {!google.ima.AdErrorEvent} adErrorEvent
    */
    private onAdError = (adErrorEvent) => {
        console.error(adErrorEvent.getError());
        this.adsManager.destroy();
    }

    /**
    * Handles actions taken in response to ad events.
    * @param {!google.ima.AdEvent} adEvent
    */
    private onAdEvent = (adEvent) => {
        // Retrieve the ad from the event. Some events (for example, ALL_ADS_COMPLETED) don't have ad object associated.
        const ad = adEvent.getAd();

        switch (adEvent.type) {
            case google.ima.AdEvent.Type.LOADED:
                // This is the first event sent for an ad - it is possible to determine whether the ad is a video ad or an overlay.
                if (!ad.isLinear()) {
                }
                break;

            case google.ima.AdEvent.Type.STARTED:
                if (ad.isLinear()) {
                    this.props.onAdPlayStarted()
                    this.props.updateAdDuration(ad.getDuration());
                }
                break;

            case google.ima.AdEvent.Type.PAUSED:
                if (ad.isLinear()) {
                    this.props.onAdPlayPause();
                }
                break;

            case google.ima.AdEvent.Type.RESUMED:
                if (ad.isLinear()) {
                    this.props.onAdPlayStarted()
                }
                break;

            case google.ima.AdEvent.Type.COMPLETE:
                if (ad.isLinear()) {
                    this.adLastPlayedTime = new Date().getTime()
                }
                this.props.onAdPlayComplete();
                break;

            case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
                if (ad.isLinear()) {
                    this.restartAds()
                }
                break;
        }
    }

    private shouldPlayAd = () => {
        // check if 5 minutes has passed
        return (new Date().getTime() - this.adLastPlayedTime) > 300000
    }

    render() {
        const { adTag } = this.props

        if (!adTag) return null

        return <div className="radio-player-ad-container" style={{ display: "none" }} ref={this.initAds} />
    }
}