require("./ynet_site_miltiArticleRotationComponenta.less")
require("./../../tab/componentas/site_tabComponenta.less")
import * as map from "lodash/map"
import { MultiArticleRotationDispalyDate } from "../data/multiArticleRotationData"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { SiteMediaData } from "../../../interfaces"
import { SimpleBottomLinkItemData } from "../data/bottomLinkItemData"
import { SiteMedia } from "../../../siteWidgets/siteMedia"




export interface GroupSlotItemData {
    title?: string
    link?: string
    subTitle: string
    targetLink?: LinkTargetProperties
    media: SiteMediaData
    commertialText: string
    isVisible: boolean
}

export interface GroupItemAtSiteProps extends MultiArticleRotationDispalyDate {
    groupItemData: GroupSlotItemData[]
    bottomLinks: SimpleBottomLinkItemData[]
}


export class GroupItemAtSite extends React.Component<GroupItemAtSiteProps, {}>{
    public render() {
        const { bottomLinks, groupItemData, isBottomLinksVisible, titleColor,
            subTitleColor, commertialColor, bgColor, fieldsDisplaySettings,
            isCommertialText, videoSettings,
            imageWidth, imageHeight } = this.props
        return (
            <div className="GroupContent" style={{ background: bgColor }} >
                <div className="slotsContent">

                    {groupItemData.map((item, index) =>
                        <div key={index}> <div><div><div className="slotView">
                            <div className="MediaCarousel" style={{ "height": imageHeight, "width": imageWidth, "overflow": "hidden" }}>
                                <SiteMedia data={item.media} videoSettings={videoSettings} key={item.media.url} width={imageWidth} height={imageHeight} />
                            </div>
                            {fieldsDisplaySettings.isTitleVisible && <div className="slotTitle" style={{ color: titleColor }}>
                                <HyperLinkBuilder href={item.link} linkTarget={item.targetLink} style={{ color: titleColor }}
                                > <div dangerouslySetInnerHTML={{ __html: item.title }} /></HyperLinkBuilder> </div>}
                            {fieldsDisplaySettings.isSubTitleVisible && <div className="slotSubTitle" style={{ color: subTitleColor }} >
                                <HyperLinkBuilder href={item.link} linkTarget={item.targetLink} style={{ color: subTitleColor }}
                                > <div dangerouslySetInnerHTML={{ __html: item.subTitle }} /></HyperLinkBuilder>
                            </div>}

                            {isCommertialText && <div className="commertialContent" style={{ color: commertialColor }} >
                                <HyperLinkBuilder href={item.link} linkTarget={item.targetLink} style={{ color: commertialColor }}
                                ><div dangerouslySetInnerHTML={{ __html: item.commertialText }} /></HyperLinkBuilder>
                            </div>}

                        </div></div></div></div>
                    )}
                </div>
                {isBottomLinksVisible && <ul className="bottomLinks">
                    {map(bottomLinks, (item) => <li key={item.slotId} style={{ color: item.color }}>
                        <HyperLinkBuilder style={{ color: item.color }} href={item.link} linkTarget={item.linkTarget} children={item.name} />
                    </li>)}
                </ul>}
            </div>
        )
    }
}

