import { YnetWeather } from "../ynetWeather/ynetWeather";
import { YnetSearchForm } from "../ynetSearchForm/ynetSearchForm";
import * as classNames from "classnames"
import { YnetPremiumLogin } from "../ynetPremiumLogin/ynetPremiumLogin"
import { isWcmEditor } from "../../../../../config/serverConfig"
require('./ynet_site_ynetSearch.less')

interface YnetSearchProps {
    weather: WeatherObject[]
    isGrid1280?: boolean
    defaultCityIndex: number
    weatherUrl?: string
}
interface WeatherObject {
    city: string
    temp: number
    imageName: string
}

interface YnetSearchProps { }

export class YnetSearch extends React.Component<YnetSearchProps, {}> {
    public static siteScriptName = "YnetSearch";

    private openFacebookWindow = () => {
        if (!isWcmEditor()) {
            window.open('https://www.facebook.com/ynetnews/')
        }
    }

    private openYoutubeWindow = () => {
        if (!isWcmEditor()) {
            window.open('https://www.youtube.com/channel/UCpSSzrovhI4fA2PQNItecUA')
        }
    }
    private openYad2Window = () => {
        if (!isWcmEditor()) {
            window.open('https://www.yad2.co.il/?utm_source=ynet&amp;utm_medium=link_footer')
        }
    }
    private openInstagramWindow = () => {
        if (!isWcmEditor()) {
            window.open('https://www.instagram.com/ynetgram/')
        }
    }
    public render() {
        const { weatherUrl, weather, isGrid1280 } = this.props
        const ynetSearchClasses = classNames({
            "ynetSearch": true,
            "disableLinks": isWcmEditor()
        })
        return (
            <div className={ynetSearchClasses}>
                <div className="main_search" data-adid="183500||440|||" data-dy-content="183500">
                    {weather && <YnetWeather weather={weather} defaultCityIndex={this.props.defaultCityIndex} weatherUrl={weatherUrl} />}

                    {isGrid1280 ? <div className="access">

                        <div id="mainAccessibilityButton" >
                            <a id="INDmenu-btn" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button">
                                <div className="rulerIcon accessIcon" />

                            </a>

                        </div>
                        <a className="socialBtn" role="button" onClick={this.openFacebookWindow} >
                            <div className="rulerIcon fbIcon" />
                        </a>
                        <a className="socialBtn" role="button" onClick={this.openYoutubeWindow} >
                            <div className="rulerIcon yTubeIcon" />
                        </a>
                        <a className="socialBtn" role="button" onClick={this.openInstagramWindow} >
                            <div className="rulerIcon instIcon" />
                        </a>
                        <a className="yad2" role="button" onClick={this.openYad2Window} target="_blank" title="יד 2">
                            <img src="/images/yad2Newlogo.png" width={51} height={30} />
                        </a>
                    </div> :
                        <div className="access">
                            <div id="mainAccessibilityButton" >
                                <a id="INDmenu-btn" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button">
                                    <img src="/images/Accessibility_button.png" aria-hidden="true" width={20} height={20} />
                                </a>


                                <a className="socialBtn" role="button" onClick={this.openFacebookWindow} >
                                    <img src="/images/new_facebook_button.png" width={20} height={20} />
                                </a>
                                <a className="socialBtn" role="button" onClick={this.openYoutubeWindow} >
                                    <img src="/images/new_youtube_button.png" width={20} height={20} />
                                </a>
                                <a className="yad2" role="button" onClick={this.openYad2Window} target="_blank" title="יד 2">
                                    <img src="/images/yad2Newlogo.png" width={51} height={30} />
                                </a>
                            </div>
                        </div>}

                    <YnetSearchForm isGrid1280={isGrid1280} />
                </div>
                <YnetPremiumLogin />
            </div>
        )
    }
}
