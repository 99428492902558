require("./site_lightbox.less")

export class SiteLinkLightBoxInjector extends React.Component<{}, {}>{
    public static siteScriptName = "SiteLinkLightBoxInjector";

    componentDidMount() {
        if (typeof document["injectLinkLightBox"] !== "function") {
            document["injectLinkLightBox"] = function (href, width, height, isSuperFarm, iframeFinalBg, id, isPremiumPay = false) {
                const backgroundProp = "background:" + iframeFinalBg + ";"
                const lbStyle = "position: fixed;"
                    + "display: flex;"
                    + "justify-content: center;"
                    + "width: 100%;"
                    + "height: 100%;"
                    + "top: 0;"
                    + "left: 0;"
                    + "z-index: 999;"
                const lightBoxStyle =
                    lbStyle
                    + backgroundProp

                const superfarmStyle =
                    lbStyle
                    + "background: rgba(255,255,255,0.7);"
                const premiumPayStyle =
                    lbStyle
                    + "padding-top: 50px;"
                    + "background: rgba(1,1,1,0.5);"
                const btn = "width:26px;"
                    + "height:26px;"
                    + "border-radius:50%;"
                    + "background: white;"
                    + "text-align:center;"
                    + "vertical-align: center;"
                    + "position: absolute;"
                    + "font-weight: bold;"
                    + "top:" + btnTopPosition(height) + "px;"
                    + "left:" + btnLeftPosition(width) + "px;"
                    + "cursor: pointer;"
                    + "padding:0;"
                const SFcloseButton =
                    btn
                    + "border:2px solid red;"
                    + "color:red;"
                const closeButton =
                    btn
                    + "border:2px solid black;"
                    + "color:black;"
                const PremiumPayCloseButton =
                    closeButton
                    + "top: 15px;"
                const iframeStyle = "box-shadow:1px 1px 3px rgba(136, 136, 136, 0.65);"
                    + "background:#fff;"
                    + "border-width:0;"

                const lightBox = document.createElement("div")
                lightBox.setAttribute('class', isSuperFarm ? 'lightBox superpharmLB' : 'lightBox')
                lightBox.setAttribute('style', isSuperFarm ? superfarmStyle : lightBoxStyle)
                lightBox.setAttribute('style', isPremiumPay ? premiumPayStyle : lightBoxStyle)
                lightBox.setAttribute('role', 'dialog')
                const iframe = document.createElement("iframe")
                iframe.setAttribute('src', href)
                iframe.setAttribute('width', width)
                iframe.setAttribute('height', height)
                iframe.setAttribute('scrolling', 'no')
                iframe.setAttribute('style', iframeStyle)
                iframe.setAttribute('id', "lightbox_iframe_id")
                lightBox.appendChild(iframe)
                var closeBtn = document.createElement("input");
                closeBtn.type = "button";
                closeBtn.value = "X";
                closeBtn.setAttribute('style', isSuperFarm ? SFcloseButton : closeButton)
                closeBtn.setAttribute('style', isPremiumPay ? PremiumPayCloseButton : closeButton)
                lightBox.appendChild(closeBtn)
                document.body.appendChild(lightBox)
                lightBox.setAttribute('tabindex', '0')
                closeBtn.focus();
                var focusableEls = lightBox.querySelectorAll('input:not([disabled]), a[href], area[href], button:not([disabled]), [tabindex="0"], iframe[src]');
                lightBox.querySelector("iframe").onkeypress = function (e) {
                    if (e.key === "Escape") {
                        var lightboxDomElement = document.querySelector('.lightBox');
                        lightboxDomElement.parentNode.removeChild(lightboxDomElement)
                        document.getElementById(id).focus()
                        e.preventDefault();
                    }
                    else e.preventDefault();
                }
                lightBox.onkeydown = function (e) {
                    if (e.key === "Escape") {
                        var lightboxDomElement = document.querySelector('.lightBox');
                        lightboxDomElement.parentNode.removeChild(lightboxDomElement)
                        document.getElementById(id).focus()
                    }
                    else if (e.keyCode === 9) {
                        if (focusableEls.length > 1) {
                            if (e.shiftKey) {
                                handleBackwardTab(e, focusableEls);
                            } else {
                                handleForwardTab(e, focusableEls);
                            }
                        }
                        else e.preventDefault();
                    }
                };

                closeBtn.onclick = function (e) {
                    var lightboxDomElement = document.querySelector('.lightBox');
                    lightboxDomElement.parentNode.removeChild(lightboxDomElement);
                    document.getElementById(id).focus()
                };
            }
        }

    }

    render() {
        return null
    }
}
const btnLeftPosition = (width) => { return (window.innerWidth - width) / 2 }
const btnTopPosition = (height) => { return (window.innerHeight - height) / 2 - 32 }



const handleBackwardTab = (e, focusableEls) => {
    if (document.activeElement === focusableEls[0]) {
        e.preventDefault();
        focusableEls[focusableEls.length - 1].focus();
    }

}
const handleForwardTab = (e, focusableEls) => {
    if (document.activeElement === focusableEls[focusableEls.length - 1]) {
        e.preventDefault();
        focusableEls[0].focus()
    }
}
