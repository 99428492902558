import * as filter from "lodash/filter"

interface translationSettings {
    [lang: string]: any
}


export const cityNames =
{
    "he": {
        "תחזית:": "תחזית:",
        "תל אביב": "תל אביב",
        "תל אביב - יפו": "תל אביב",
        "עפולה": "עפולה",
        "אשדוד": "אשדוד",
        "באר שבע": "באר שבע",
        "בית שאן": "בית שאן",
        "ירושלים": "ירושלים",
        "קצרין": "קצרין",
        "לוד": "לוד",
        "מצפה רמון": "מצפה רמון",
        "נצרת": "נצרת",
        "טבריה": "טבריה",
        "חיפה": "חיפה",
        "צפת": "צפת",
        "אילת": "אילת",
        "עין גדי": "עין גדי",

    },
    "en": {
        "תחזית:": "Weather:",
        "תל אביב": "Tel Aviv-Jaffa",
        "תל אביב - יפו": "Tel Aviv-Jaffa",
        "עפולה": "Afula",
        "אשדוד": "Ashdod",
        "באר שבע": "Beer Sheva",
        "בית שאן": "Bet Shean",
        "ירושלים": "Jerusalem",
        "קצרין": "Qazrin",
        "לוד": "Lod",
        "מצפה רמון": "Mizpe Ramon",
        "נצרת": "Nazareth",
        "טבריה": "Tiberias",
        "חיפה": "Haifa",
        "צפת": "Zefat",
        "אילת": "Eilat",
        "עין גדי": "En Gedi",
    },
    "ru": {
        "תחזית:": "Погода:",
        "תל אביב": "Тель-Авив",
        "תל אביב - יפו": "Тель-Авив",
        "עפולה": "Афула",
        "אשדוד": "Ашдод",
        "באר שבע": "Беэр-Шева",
        "בית שאן": "Бейт-Шеан",
        "ירושלים": "Иерусалим",
        "קצרין": "Кацрин",
        "לוד": "Лод",
        "מצפה רמון": "Мицпе-Рамон",
        "נצרת": "Назарет",
        "טבריה": "Тверия",
        "חיפה": "Хайфа",
        "צפת": "Цфат",
        "אילת": "Эйлат",
        "עין גדי": "Эйн-Геди",
    },
    "link": {
        "תל אביב": "tel-aviv-yafo",
        "תל אביב - יפו": "tel-aviv-yafo",
        "עפולה": "afula",
        "אשדוד": "ashdod",
        "באר שבע": "Beer Sheva",
        "בית שאן": "bet-shean",
        "ירושלים": "jerusalem",
        "קצרין": "qazrin",
        "לוד": "lod",
        "מצפה רמון": "mizpe-ramon",
        "נצרת": "nazareth",
        "טבריה": "tiberias",
        "חיפה": "haifa",
        "צפת": "Zefat",
        "אילת": "elat",
        "עין גדי": "en-gedi",
    }
}


export const getCityNameTranslation = (lang, text: string) => {
    var obj = filter(cityNames, function (v, k) {
        return k === lang;
    });
    var content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]

}

