import { DateFormat } from "./interfaces";

export interface DateFormatOptions {
    showDate?: boolean
    showHour?: boolean
    dateTimeSeperator?: string
    padDateWithZero?: boolean
    padHourWithZero?: boolean
    fullYear?: boolean
    isSlashSeparator?: boolean
    isDateFirst?: boolean
    datePartOrder?: DateFormat
}
export interface SiteDateFormatType {
    [siteName: string]: DateFormatOptions
}
const defaultDateFormatOptions: DateFormatOptions = {

}


export const siteDateformat: SiteDateFormatType = {
    "il-he": {
        showDate: true,
        showHour: true,
        dateTimeSeperator: ", ",
        padDateWithZero: true,
        padHourWithZero: true,
        fullYear: false,
        isSlashSeparator: false,
        isDateFirst: false,
        datePartOrder: DateFormat.dmy,
    },
    "dmy": { //TODO:delete after change DB to il-he
        showDate: true,
        showHour: true,
        dateTimeSeperator: ", ",
        padDateWithZero: true,
        padHourWithZero: true,
        fullYear: false,
        isSlashSeparator: false,
        isDateFirst: false,
        datePartOrder: DateFormat.dmy,
    },
    "il-mdy": {
        showDate: true,
        showHour: true,
        dateTimeSeperator: ", ",
        padDateWithZero: true,
        padHourWithZero: true,
        fullYear: false,
        isSlashSeparator: false,
        isDateFirst: false,
        datePartOrder: DateFormat.mdy,
    },
    "mdy": {  //TODO:delete after change DB to il-mdy
        showDate: true,
        showHour: true,
        dateTimeSeperator: ", ",
        padDateWithZero: true,
        padHourWithZero: true,
        fullYear: false,
        isSlashSeparator: false,
        isDateFirst: false,
        datePartOrder: DateFormat.mdy,
    },
}
export const getDefaultDateFormatBySite = (df: string): DateFormatOptions => {
    return siteDateformat[df]
}