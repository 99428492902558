import { MainNavHeaderComponenta } from "./mainNavComponenta";
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { MenuPopupComponenta } from "./menuPopUpComponenta";
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { YnetPremiumLogin } from "../../../ynetSearch/components/ynetPremiumLogin/ynetPremiumLogin";
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { YnetWeatherInHeader } from "./newYnetWeatherComponenta"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { CommercialHeaderButton } from "./commercialHeaderButton";
import { YnetPianoLogin, YnetPlusUserMenuItems } from "../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";
import { SearchBtnDisplay } from "./newSearchButton";

interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isSearchOpened: boolean
}

interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    flashPageLink: string
    flashLinkTarget: LinkTargetProperties
    redMailLink: string
    mailLinkTarget: LinkTargetProperties
    logoData: Logo
    promoText: string
    promoLink: string
    tabBgColor: string
    yplusLink: string
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    enableSticky: boolean
    ynetSearchPageLink: string
    commercialBtnTitle: string
    commercialLinksList:CommertialItem[]
    followUsLink:string
    folowUsLinkTarget:LinkTargetProperties
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
    domain:string
    displayType:string
}

export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}

export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}
export interface CommertialItem{
    title:string
    link:string
    linkTarget: LinkTargetProperties
}

export class SiteYnetHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteYnetHeaderComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isSearchOpened: false
        }
    }

    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened, isSearchOpened: false, })
    }

    private openSearchMenu = () => {
        const isExternalPage = typeof window != "undefined" && window.isExternalPage;
        if (isExternalPage) {
            top.location.href = 'https://www.ynet.co.il/category/3340';
        }
        else this.setState({ isSearchOpened: !this.state.isSearchOpened, isOpened: false })
    }

    private stickyBar() {
        const headerPos = document.getElementById("YnetHeader").getBoundingClientRect().top
        if (headerPos + 130 < 0) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())

    }

    componentWillUnmount() {
        const navbar = document.getElementById("YnetHeader")
        document.removeEventListener("scroll", this.throttledScrollHandler())

    }

    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        const { mainNavItems, groupsWithItems, flashPageLink, flashLinkTarget, logoData, promoText, enableSticky,
            mailLinkTarget, redMailLink, promoLink, tabBgColor, yplusLink, followUsText, linkToInst, linkToFb, linkToTw, linkToYtube,
            ynetSearchPageLink, commercialBtnTitle, commercialLinksList, followUsLink,  folowUsLinkTarget, ynetPlusMenuItems, domain, displayType} = this.props
        const { isSticky } = this.state
        const isStickyAvailable = isSticky && enableSticky;
        const isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;
        const isLightMode = displayType && displayType==="light";
        return (
            <div className={classNames("YnetHeader", displayType)} id="YnetHeader">
                <button className="accessibiltyFixedBtn" onClick={this.clickAcessibilityBtn} id="INDmenu-btn-fixed" title="אתר נגיש" aria-labelledby="INDbtnTooltip"  accessKey="m"></button>
                <div className="headerTop">
                    <div className="rightSideLinks">
                        <HyperLinkBuilder href={flashPageLink} linkTarget={flashLinkTarget} >
                            <span className="headerIcon flash" id="flashBell" /> <span className="flashText">מבזקים</span>
                        </HyperLinkBuilder>
                        <HyperLinkBuilder href={redMailLink} linkTarget={mailLinkTarget} >
                            <span className="headerIcon redMail" /> <span className="redMailText">המייל האדום</span>
                        </HyperLinkBuilder>
                        {commercialBtnTitle && <CommercialHeaderButton btnTitle={commercialBtnTitle} linksData={commercialLinksList} followUsLink={followUsLink} followUsLinkTarget={folowUsLinkTarget} />}
                        <YnetWeatherInHeader lang={"he"} />
                    </div>

                    <div className="logo">
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                            <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>

                    </div>
                    {isLightMode ? <div className="leftHeader">
                        <SearchBtnDisplay  ynetSearchPageLink={ynetSearchPageLink}/>
                        { isPianoLogin ? <YnetPianoLogin domain={domain} ynetPlusMenuItems={ynetPlusMenuItems} promoText={promoText} promoLink={promoLink}  yplusLink={yplusLink}/>  : <YnetPremiumLogin promoText={promoText} promoLink={promoLink} yplusLink={yplusLink} isYnet1280={true} />}
                    </div> :
                 isPianoLogin ? <YnetPianoLogin domain={domain} ynetPlusMenuItems={ynetPlusMenuItems} promoText={promoText} promoLink={promoLink}  yplusLink={yplusLink}/>  : <YnetPremiumLogin promoText={promoText} promoLink={promoLink} yplusLink={yplusLink} isYnet1280={true} />}
                </div>

                <div className={classNames("BottomHeaderArea", { "sticky": isStickyAvailable })} id="BottomHeaderArea">
                    <MainNavHeaderComponenta
                        isSticky={isStickyAvailable}
                        mainNavItems={mainNavItems}
                        logoData={logoData}
                        onToggleMenu={this.openMenu}
                        onToggleSearch={this.openSearchMenu}
                        isOpened={this.state.isOpened}
                        tabBgColor={tabBgColor}
                        displayType={displayType}
                    />

                    <MenuPopupComponenta
                        groupsWithItems={groupsWithItems}
                        isVisibleMenu={this.state.isOpened}
                        isVisibleSearch={this.state.isSearchOpened}
                        onCloseMenu={() => this.setState({ isOpened: false })}
                        onCloseSearch={() => this.setState({ isSearchOpened: false })}
                        followUsText={followUsText}
                        linkToInst={linkToInst}
                        linkToFb={linkToFb}
                        linkToTw={linkToTw}
                        linkToYtube={linkToYtube}
                        ynetSearchPageLink={ynetSearchPageLink}
                        displayType={displayType}
                    />
                </div>
            </div>
        )
    }
}