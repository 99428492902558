import { VideoGalleryVerticalItem } from "./videoGalleryVerticalItem";
import Slider from "react-slick"
import { SiteItemData } from "../../../../interfaces";
import { VideoVerticalFeedItem } from "./videoVerticalFeedItem";
import { PromoCarousel } from "../../../ynet1280/videoGalleryVerticalWidget/components/promoCarousel";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { categoryObj } from "./radionasVideoGalleryVerticalComponenta";

interface VideoGalleryVerticalDesktopProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    category: categoryObj
    domain: string
}

interface VideoGalleryVerticalDesktopState {
    feedActiveSlide: number
    isFeedDisplay: boolean
    initialFeedSlide: number
    togglePromoActive: boolean
    currentPromoIndex: number
}

export class VideoGalleryVerticalDesktop extends React.Component<VideoGalleryVerticalDesktopProps, VideoGalleryVerticalDesktopState>{
    constructor(props) {
        super(props);
        this.state = {
            feedActiveSlide: 0,
            isFeedDisplay: false,
            initialFeedSlide: 0,
            togglePromoActive: false,
            currentPromoIndex: 0
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    private slider;

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }


    private onOpenVideoGalleryView = (videoIndex: number) => {
        const { videoList } = this.props;
        const initialFeedSlide = videoList.findIndex((element) => element.itemId === videoList[videoIndex].itemId);
        this.setState({ initialFeedSlide: initialFeedSlide, feedActiveSlide: initialFeedSlide }, () => { this.setState({ isFeedDisplay: true }) });
        disablePageRefresh();
    }

    private onCloseVideoGalleryView = () => {
        this.setState({ isFeedDisplay: false });
        enablePageRefresh();
    }

    private onPromoMouseHover = (currentPromoIndex: number) => {
        this.setState({ currentPromoIndex: currentPromoIndex });
    }

    private handleKeyPress(e) {
        if (e.keyCode === 27) {
            this.onCloseVideoGalleryView();
        }
    }

    private onNextSlide = () => {
        this.slider.slickPrev();
        this.pushGA4Event("swipe_left", true);
    }

    private pushGA4Event = (action: string, isFeedDisplay: boolean) => {
        if (typeof window == "undefined" || !window.dataLayer) return;
        const { category } = this.props;
        const actionPrefix = category && category.name ? (category.name === "דף הבית") ? "homepage" : category.name : "homepage";
        const gaAction = isFeedDisplay ? `video_${action}` : `${actionPrefix}_${action}`;

        window.dataLayer.push({
            event: "content_click",
            vertical_video_action: gaAction,
        });
    };

    public render() {
        const { videoList, isTabVisible, tabTitleText, domain } = this.props;
        const { isFeedDisplay, initialFeedSlide, feedActiveSlide, togglePromoActive, currentPromoIndex } = this.state;

        const feedDisplaySettings = {
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: initialFeedSlide,
            draggable: false,
            rtl: true,
            beforeChange: (current, next) => this.setState({ feedActiveSlide: Math.abs(next - videoList.length + 1) }),
            nextArrow: <SampleNextArrow feedActiveSlide={feedActiveSlide} listLength={videoList.length} pushGA4Event={this.pushGA4Event}/>,
            prevArrow: <SamplePrevArrow feedActiveSlide={feedActiveSlide} listLength={videoList.length} pushGA4Event={this.pushGA4Event}/>,
        };

        return (
            <div className="videoGalleryVerticalDesktopWrapper">
                {isTabVisible && <div className="videoGalleryVerticalTab componentaTab"><div className="rightTitleText">{tabTitleText}</div></div>}
                {isFeedDisplay && <div className="closeBtn" onClick={this.onCloseVideoGalleryView}></div>}
                <PromoCarousel
                    imageWidth={273}
                    width={1240}
                    itemListLength={videoList.length}
                    marginRight={20}
                    togglePromoActive={togglePromoActive}
                    currentPromoIndex={currentPromoIndex}
                    videoList={videoList}
                    pushGA4Event={this.pushGA4Event}
                >
                    {videoList.map((videoItem, index) => {
                        return (
                            <VideoGalleryVerticalItem
                                videoItem={videoItem}
                                onOpenVideoGalleryView={this.onOpenVideoGalleryView}
                                videoIndex={index}
                                key={videoItem.itemId}
                                onPromoMouseHover={this.onPromoMouseHover}
                            />
                        )
                    })}
                </PromoCarousel >
                {isFeedDisplay &&
                    <div className="feedDisplay">
                        <Slider {...feedDisplaySettings} className="slider" ref={slider => (this.slider = slider)} >
                            {videoList.map((videoItem,index) => (
                                   <VideoVerticalFeedItem
                                   videoItem={videoItem}
                                   key={videoItem.itemId}
                                   feedActiveSlide={feedActiveSlide}
                                   onNextSlide={this.onNextSlide}
                                   onCloseVideoGalleryView={this.onCloseVideoGalleryView}
                                   domain={domain}
                                   index={index} />
                            ))}
                        </Slider>
                    </div>
                }
            </div>
        )
    }
}

const SampleNextArrow = (props) => {
    const { onClick, feedActiveSlide, pushGA4Event } = props;
    const shouldDisplayArrow = feedActiveSlide !== 0;
    const handleClick = () => {
        onClick();
        pushGA4Event("swipe_right",true);
    }

    return (
        <button
            className={`slickArrow slickNext ${shouldDisplayArrow ? "" : "slickDisabled"}`}
            onClick={handleClick}
        ></button>
    );
}

const SamplePrevArrow = (props) => {
    const { onClick, feedActiveSlide, listLength, pushGA4Event } = props;
    const shouldDisplayArrow = (feedActiveSlide + 1) !== listLength;
    const handleClick = () => {
        onClick();
        pushGA4Event("swipe_left",true);
    }
    return (
        <button
            className={`slickArrow slickPrev ${shouldDisplayArrow ? "" : "slickDisabled"}`}
            onClick={handleClick}
        ></button>
    );
}