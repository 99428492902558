import { secondsToHms } from "../../../siteWidgets/siteWidgetUtils"

interface AdsProgressBarProps {
    adDuration: number
    adPlaybackTime: number
    onSeeked: (num: number) => void
}

export class AdsProgressBar extends React.Component<AdsProgressBarProps> {

    private updateCurrentTime = (e) => {
        this.props.onSeeked(Number(e.target.value));
    }

    render() {
        const { adDuration, adPlaybackTime } = this.props
        const percentage = (adPlaybackTime - 0) * 100 / (adDuration - 0);
        const rangeBackground = `linear-gradient(90deg, #fec300 ${percentage}%, #cacdce ${percentage}%)`;

        return (
            <>
                <div className="control-ruler-buttons">
                    <button className="go-live-btn ruler-btn disabled" aria-label="go to live" disabled={true}></button>
                    <button className="start-over-btn ruler-btn disabled" aria-label="start program over" disabled={true}></button>
                </div>

                <div className="ads-ruler-timeline">
                    <div className="range-wrap">
                        <input type="range" className="dvr-timeline" max={adDuration} value={adPlaybackTime} onChange={this.updateCurrentTime} style={{ background: rangeBackground }} />
                    </div>

                    <div className="control-ruler-bottom-details">
                        <div className="progress-time">{`${secondsToHms(adPlaybackTime)}/${secondsToHms(adDuration)}`}</div>

                        <div className="live-feedback">
                            <div className="dot"></div>
                            <div className="live-text">מודעה</div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}