require("../../calcalistWidgets/calcalistRedMail/calcalist_site_redMailComponenta.less")
import { ExtraDetails } from "./extraDetails/extraDetails"
import { TextArea } from "./textArea/textArea"
import { PopUp } from "./popUp/popUp"
import { RedMailFileUploadCalcalist } from "./filesUpload/RedMailFileUploadCalcalist";
import { RedMailComponentaProps } from "../interfaces/redMailInterfaces";

export interface RedMailComponentaCalcalistProps extends RedMailComponentaProps {

}



export class RedMailComponentaCalcalist extends React.Component<RedMailComponentaCalcalistProps & {isChecked: boolean}, {}>{

    public render() {
        const { handleChangeText, isTextAreaBlank, countSubmitAttempts, textArea, handleSelectedFile, selectedFiles, hashes, invalidFilesName, showFileStatusProgress,
            photographer, sender, mail, phoneNumber, validation, handleChangeExtraData, isAgreementApproved, IsPopUp, submitForm, loading, onClose, submitButtonDisabled,
            isChecked} = this.props;


        const calcalistTermsHref = "https://www.calcalist.co.il/articles/1,7340,L-3060415,00.html";
        const calcalistTermsLink = <a href={calcalistTermsHref} className="termsClick" target="_blank">הסכם משלוח התכנים</a>
        
        const textData = {
            headlineText: "דואר אדום",
            textArea: {
                textAreaHeadline: "יש לכם מידע חשוב שטרם נחשף? צילומים מארוע חדשותי? נשמח שתשתפו אותנו. אנחנו נבדוק, נאמת ונפרסם בהתאם להחלטת מערכת כלכליסט.",
                placeholder: "מה רצית לשתף איתנו?"
            },
            extraDetailsData: {
                title: "פרטים נוספים",
                subTitle: "אין שום בעיה שתישארו אנונימיים, אבל מספר הטלפון או כתובת המייל שלכם יקלו עלינו לטפל במידע. חשוב לדעת שבמקרה שאתם עושים שימוש בתמונה או קובץ שמוגנים בזכויות יוצרים - חובה לצרף פרטים מזהים.",
                mailPattern: "צריך להיות בתבנית הבאה: calcalist@calcalist.com "
            },
            terms: <> בלחיצה על שליחה, קראתי והבנתי את {calcalistTermsLink} לכלכליסט, והסכמתי לתנאיו</>
        }

        return (
            <div>
                <div className="RedMailComponenta">
                    <div className="redMailHeadline">
                        <div className="headlineImage" />
                        <p className="headlineText">
                            <a className="headlineTitle" href="https://m.calcalist.co.il/"></a> 
                            <span className="headlineSubTitle">{textData.headlineText}</span>
                        </p>
                    </div>

                    <div className="redMailLeftSide">
                        <TextArea textAreaData={textData.textArea} value={textArea} isChecked={isChecked}  onChange={handleChangeText} isTextAreaBlank={isTextAreaBlank && countSubmitAttempts > 0} />
                        {(isTextAreaBlank && countSubmitAttempts > 0) && <div className="contentErrorMessage">נראה ששכחת לצרף את תוכן ההודעה</div>}
                        <RedMailFileUploadCalcalist
                            handleSelectedFile={handleSelectedFile}
                            selectedFiles={selectedFiles}
                            hashes={hashes}
                            invalidFilesName={invalidFilesName}
                            showFileStatusProgress={showFileStatusProgress}
                        />

                        <div className="separator" />

                        <ExtraDetails
                            photographer={photographer}
                            sender={sender}
                            mail={mail}
                            phoneNumber={phoneNumber}
                            onChange={handleChangeExtraData}
                            validation={validation}
                            extraDetailsData={textData.extraDetailsData}
                        />

                        <div className="separator" />
                        <div className="bottomArea">
                            <p className="terms">
                                <input className="checkBox" id="agreementApproved" type="checkbox" checked={isAgreementApproved} onChange={(e) => handleChangeExtraData(e)} />
                                {textData.terms}
                                {(!isAgreementApproved && countSubmitAttempts > 0) && <div className="contentErrorMessage terms-error ">יש לאשר את תנאי השימוש</div>}
                            </p>

                            <button
                                disabled={submitButtonDisabled}
                                className="submitButton"
                                type="submit"
                                onClick={submitForm}>
                                שליחה
                        </button>
                        </div>

                        {IsPopUp && <PopUp onClose={onClose} loading={loading} />}
                    </div>
                </div>
            </div>
        )

    }
}



