import { SiteItemData } from "../../../../interfaces";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { SlotIconDisplay } from "../../../../commonComponents/inline/slotIconDisplay/slotIconDisplay";
import { useState } from "react"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { LinkedItemFieldDisplay } from "../../../../commonDataObjects/linkedItemsFieldsDisplay"

interface MagazineGalleryImagesProps {
    items: SiteItemData[]
    isMobile?: boolean
    componentColor: string
    fieldsDisplaySettings: LinkedItemFieldDisplay
}

export const MagazineGalleryImages = (props: MagazineGalleryImagesProps) => {

    const [presentedArticleIndex, setPresentedArticleIndex] = useState(0)
    const [shouldAnimate, setShouldAnimate] = useState(false)
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const { items, isMobile, componentColor, fieldsDisplaySettings: { isTitleVisible, isAuthorVisible, isCategoryVisible, isDateVisible } } = props
    const arrowZindex = items.length + 2;
    const showAnimation = isMobile && shouldAnimate;
    const animationDuration = 400; //make sure to update the animation duration on the css file as well so they match
    const minSwipeDistance = 50 // the required distance between touchStart and touchEnd to be detected as a swipe

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            showPrevImage()
        }else if (isRightSwipe) {
            showNextImage()
        }
    }

    const showNextImage = () => {
        if (presentedArticleIndex === items.length - 1) {
            setPresentedArticleIndex(0)
            setShouldAnimate(true)
        } else if (presentedArticleIndex < items.length - 1) {
            setPresentedArticleIndex(presentedArticleIndex + 1)
            setShouldAnimate(true)
        }
        setTimeout(() => {
            setShouldAnimate(false)
        }, animationDuration);
    }

    const showPrevImage = () => {
        if (presentedArticleIndex === 0) {
            setPresentedArticleIndex(items.length - 1)
            setShouldAnimate(true)
        } else if (presentedArticleIndex <= items.length - 1) {
            setPresentedArticleIndex(presentedArticleIndex - 1)
            setShouldAnimate(true)
        }
        setTimeout(() => {
            setShouldAnimate(false)
        }, animationDuration);
    }

    return (
        <div className="magazine-gallery-images" style={{ "--component-color": componentColor }}>
            <div className="magazine-gallery-images-container">
                <div className="right-arrow arrow" style={{ zIndex: arrowZindex }} onClick={showPrevImage}></div>

                <div className="magazine-gallery-images-list">

                    {items.map((item, index) =>
                        <div
                            key={item.itemId}
                            onTouchEnd={onTouchEnd}
                            onTouchMove={onTouchMove}
                            onTouchStart={onTouchStart}
                            className={`magazine-gallery-article ${showAnimation ? "animate-article" : ""}`}
                            style={{ zIndex: presentedArticleIndex === index ? items.length + 1 : items.length - index }}
                        >
                            <img className="magazine-gallery-image" src={item.media.url} title={item.media.title} alt={item.media.title} />

                            <div className="dark-area">
                                {/* <div className={`gradient ${showAnimation ? "animate-gradient" : ""}`} /> */}
                                <HyperLinkBuilder href={item.titleLink} linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink} children={item.title} >
                                    <div className="article-data">
                                        {isTitleVisible && <div className="article-title">{item.title}</div>}

                                        <div className="article-details">
                                            {isCategoryVisible && <div className="category">{item.categoryName}</div>}
                                            {isCategoryVisible && item.categoryName && <div className="separator" />}
                                            {isAuthorVisible && <div className="author">{item.author}</div>}
                                            {isAuthorVisible && item.author && <div className="separator" />}
                                            {isDateVisible && <DateDisplay date={item.dateUpdatedOnSite} />}
                                        </div>
                                    </div>
                                </HyperLinkBuilder>

                                <SlotIconDisplay itemId={item.itemId} icon={"video"} iconClassName={"big"} isIconVisible={item.hasVideo} />
                            </div>
                        </div>)}
                </div>

                <div className="left-arrow arrow" style={{ zIndex: arrowZindex }} onClick={showNextImage}></div>
            </div>

            <div className="magazine-gallery-images-lines">
                <div className={`line-1 line ${showAnimation ? "animate1" : ""}`}></div>
                <div className={`line-2 line ${showAnimation ? "animate2" : ""}`}></div>
                <div className={`line-3 line ${showAnimation ? "animate3" : ""}`}></div>
                <div className={`hidden-line line ${showAnimation ? "animate-hidden" : ""}`}></div>
            </div>

            <div className="magazine-gallery-dots">
                {items.map((item, index) => <div className={`dot ${index === presentedArticleIndex ? "selected" : ""}`} key={index} />)}
            </div>
        </div >
    )
}

MagazineGalleryImages.siteScriptName = "MagazineGalleryImages";