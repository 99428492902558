import { ArtilceHotUpdateItems } from "./articleHotUpdateComponent";

interface articleHotUpdateItemProps extends ArtilceHotUpdateItems {
    updatedTime: string
    updateTimeColor: string
}

export const ArticleHotUpdateItem = (props: articleHotUpdateItemProps) => {
    const { updateTimeColor, updatedTime, title, publishLink } = props
    const isNew = updatedTime === "עכשיו"
    return (
        <div className={isNew ? "hot-update-item-pf now" : "hot-update-item-pf"}>
            <a href={publishLink ? publishLink : "javascript:void(0)"} style={publishLink ? {} : { cursor: "context-menu" }}>
                <div className="time-row" style={{ display: "flex", alignItems: "center" }}>
                    <div className="item-circle-pf" />
                    <div className="item-time">{updatedTime}</div>     
                </div>
                <div className="item-title space-pf">{title}</div>
            </a>
        </div>
    )
}

ArticleHotUpdateItem.displayName = 'ArticleHotUpdateItem' as any
