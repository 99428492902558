import * as classNames from "classnames"
import { YnetSearchForm } from "../../../ynetSearch/components/ynetSearchForm/ynetSearchForm";
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { Group } from "./siteRadionasHeaderComponenta";
import * as map from "lodash/map"
const clickOutside = require('react-click-outside');

interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisibleMenu: boolean
    isVisibleSearch: boolean
    onCloseMenu: () => void
    onCloseSearch: () => void
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToTiktok: string
    linkToRadioNas: string
    ynetSearchPageLink: string
}

@clickOutside
export class MenuPopupComponenta extends React.Component<MenuPopUpProps, {}> {

    private handleClickOutside = (e) => {
        const isVisible = this.props.isVisibleMenu || this.props.isVisibleSearch;
        if (isVisible && e.target && e.target.id !== "wcm_MenuDisplay" && e.target.id !== "wcm_MenuDisplay_moreBtn" && e.target.id !== "wcm_MenuDisplay_searchIcon" && e.target.id !== "wcm_SearchDisplay") {
            this.props.onCloseMenu();
            this.props.onCloseSearch();
        }

    }
    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    render() {
        const { groupsWithItems, followUsText, linkToInst, linkToFb, linkToRadioNas, linkToTiktok, ynetSearchPageLink, isVisibleSearch } = this.props
        const isVisible = this.props.isVisibleMenu || this.props.isVisibleSearch;

        return (
            <div className={classNames("popUpmenu", { "opened": isVisible, "closed": !isVisible, "searchPopUp": this.props.isVisibleSearch })} role="sub-navigation" aria-label="Sub Menu">
                <YnetSearchForm placeholder="يوم التأسيس الوطني" ynetSearchPageLink={ynetSearchPageLink} />
                {!isVisibleSearch && <>  <div id="mainAccessibilityButton" >
                    <a id="INDmenu-btn" onClick={this.clickAcessibilityBtn} title="موقع يمكن الوصول إليه" accessKey="m" aria-labelledby="INDbtnTooltip" role="button">
                        <span className="icon" ></span>
                    </a></div>
                    <div className="linksGroups">
                        {map((groupsWithItems), (item: Group, index: number) =>
                            <div className="group" key={index}>
                                <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                                <ul className="groupList">
                                    {map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                        <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} style={{ color: subItem.color }}>
                                            <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                        </HyperLinkBuilder></li>
                                    )}
                                </ul>
                            </div>
                        )}
                           <div className="followUsLinks group">
                        {followUsText !== "" && <div className="followUs groupTitle">{followUsText}</div>}
                        <div className="mediaLinks">
                        {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                            {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="mediaIcon linkToInst" />}
                            {linkToTiktok !== "" && <SimpleLinkRenderer href={linkToTiktok} target="_blank" className="mediaIcon linkToTiktok" />}
                           {linkToRadioNas !== "" && <SimpleLinkRenderer href={linkToRadioNas} target="_blank" className="mediaIcon linkToRadio" />}
                        </div>
                    </div>
                        </div>
                 
                </>}
            </div>)
    }
}