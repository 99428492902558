import { VideoSettingsData, LinkedItemType, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { SiteItemData, SiteMediaData, SiteImageData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { isHavakVisible, PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { MediaContentTypes } from "constants/contentTypes"
import * as classNames from "classnames"
import { MarketingSign } from "../../../../commonComponents/inline/marketingSign/marketingSign"
import { googleScript } from "../../../../widgetsUtils"


interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    item?: SiteItemData
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    marginLeft?: number
    ads_infrastructure_type?: string
}



export class ItemMagazineFront extends React.Component<MultiImagesFrontProps>{
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }
    public render() {
        const { imageWidth, item, imageHeight, videoSettings, marginLeft, fieldsDisplaySettings, ads_infrastructure_type } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        if (item.isDFP) return <div className="slotView dfpId" style={{ marginLeft: marginLeft }} id={item.dfpId}>
            {ads_infrastructure_type && ads_infrastructure_type === "new" && <script type="text/javascript" dangerouslySetInnerHTML={{ __html: googleScript(item.dfpId) }}></script>}
        </div>
        return (<div className="slotView" style={{ marginLeft: marginLeft }}>
            <div className="imageArea"  data-tb-thumbnail=''>
                <SlotIconDisplay itemId={item.itemId} icon={this.getIcon(item.icon, item.hasVideo)} iconClassName="medium" isIconVisible={item.hasVideo} />
                {!isHavakVisible({ premiumExpireDays: item.premiumExpireDays, dateUpdatedOnSite: item.dateUpdatedOnSite }) &&
                    item.isMarketingContent && <MarketingSign isSmallItem={true} marketingContentText={item.marketingContentText} />}
                <PayWallArctilceIcon
                    premiumExpireDays={item.premiumExpireDays}
                    dateUpdatedOnSite={item.dateUpdatedOnSite}
                    className={`onImage`} />
                <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                    <SiteMedia
                        data={item.media !== undefined ? item.media : emptyMedia}
                        width={imageWidth}
                        height={imageHeight}
                        videoSettings={videoSettings}
                        itemLinkUrl={item.titleLink}
                        isImageLinkable={true}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                    />
                </div> </div>
            <div className="slotTextArea">

                <div className="upInfoArea">
                    {fieldsDisplaySettings.isChannelNameVisible && item.subchannelName !== null && item.subchannelName}
                    {fieldsDisplaySettings.isCategoryVisible && !fieldsDisplaySettings.isChannelNameVisible && item.categoryName !== null && item.categoryName}
                    {item.itemType !== LinkedItemType.ARTICLE && item.promotionText}
                </div>
                {fieldsDisplaySettings.isTitleVisible && <div className={classNames("slotTitle medium", item.isCommertial ? "commertialTitle" : "")}>
                    <HyperLinkBuilder
                        href={item.titleLink}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        children={item.title}
                        isDataTbLink >
                        <div dangerouslySetInnerHTML={{ __html: item.title }} data-tb-title/>
                    </HyperLinkBuilder>
                </div>}
                {fieldsDisplaySettings.isSubTitleVisible && item.subTitle !== undefined && <div className="slotSubTitle" >
                    <HyperLinkBuilder
                        href={item.titleLink}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        children={item.subTitle} >
                        <div dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                    </HyperLinkBuilder>
                </div>}
                <div className="moreDetails">
                    {fieldsDisplaySettings.isAuthorVisible && <span className="authorField" data-tb-author>{item.author}</span>}
                    {fieldsDisplaySettings.isAuthorVisible && item.itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isDateVisible && item.author.length > 0 && <span>| </span>}
                    {item.itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isDateVisible && <DateDisplay date={item.dateUpdatedOnSite} isTbDate/>}
                </div>
            </div>
            <div className={classNames("commertialTitleLine", item.isCommertial ? "showLine" : "")}> </div>
        </div>
        )
    }
}
// <div >