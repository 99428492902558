import * as map from "lodash/map"
import { SiteVideoData } from "../../../interfaces"
import { isYouTubeGlobalSettingEnabled, getVideoSources } from "../videoPlayerUtils"
import { YoutubePlayerForInline } from "./youtubePlayerForInline/youtubePlayerForInline"
import { FlowPlayerForInline } from "./flowPlayerForInline/flowPlayerForInline"
import { HolaPlayerForInline } from "./holaPlayerForInline/holaPlayerForInline"
import { VideoJsPlayerForInline } from "./videoJsForInline/videoJsForInline"
import { YITVideoJsPlayerForInline } from "./YITVideoJsForInline"


export type PlayersTypes = "youtube" | "flowplayer" | "hola" | "videojs" | "yitvideo"

interface GenericInlineVideoPlayerProps {
    data: SiteVideoData
    width?: number
    height?: number
    runArticleAutoPlayLogic?: boolean
    hideVideoAds?: boolean
    isShareButton?: boolean
    videoShareLink?: string
    isArticle?: boolean
    applyStickyness: boolean
    onSelect?: () => void
    onPlayStarted?: () => void
    onPlayStopped?: () => void
    onVideoEnded?: () => void
    onVideoError?: () => void
}


export class GenericInlineVideoPlayer extends React.Component<GenericInlineVideoPlayerProps, {}> {
    private getDefaultNativePlayer = (): PlayersTypes => {
        if (typeof window !== "undefined") {
            return window["defaultNativeVideoType"] as PlayersTypes
        }
        return null
    }

    private renderPlayer(nativePlayerType: PlayersTypes) {
        if (!nativePlayerType) { return null }
        const {
            isAutoPlaying,
            isLoopPlaying,
            isPlayerBarVisible,
            isSoundButtonVisible,
            isMuteAvailable,
            url,
            downGradeUrl,
        } = this.props.data

        if (isAutoPlaying && isLoopPlaying && !isPlayerBarVisible && !isSoundButtonVisible && isMuteAvailable) {
            return <video style={{ width: "100%", height: "100%" }} autoPlay muted controls={false}>
                {map(getVideoSources(url, downGradeUrl), ({ type, src }) => <source src={src} type={type} />)}
            </video>
        }
        const isYoutube = isYouTubeGlobalSettingEnabled(this.props.data)
        if (isYoutube) {
            return <YoutubePlayerForInline {...this.props} />
        }

        switch (nativePlayerType) {
            case "flowplayer":
                return <FlowPlayerForInline {...this.props} />

            case "hola":
                return <HolaPlayerForInline {...this.props} />

            case "videojs":
                return <VideoJsPlayerForInline {...this.props} />

            case "yitvideo":
                return <YITVideoJsPlayerForInline {...this.props} hideVideoAds={this.props.hideVideoAds} />
        }
        return null
    }

    render() {
        return (
            this.renderPlayer(this.getDefaultNativePlayer())
        )
    }
}