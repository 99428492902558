import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"

export const YnetShareButtons = (props) => {
    const { facebookLink, telegramLink, instagramLink } = props

    const clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    return (
        <div className="ynet-share-buttons">
            <SimpleLinkRenderer href={facebookLink} aria-label="facebook">
                <div className="facebook-icon share-btn"></div>
            </SimpleLinkRenderer>

            <SimpleLinkRenderer href={telegramLink} aria-label="telegram">
                <div className="telegram-icon share-btn"></div>
            </SimpleLinkRenderer>

            <SimpleLinkRenderer href={instagramLink} aria-label="instagram">
                <div className="instagram-icon share-btn"></div>
            </SimpleLinkRenderer>

            <a className="accessability-btn" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button" onClick={clickAcessibilityBtn}>
                <div className="accessability-icon share-btn"></div>
            </a>
        </div>
    )
}