import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"
import { SiteItemData, SiteVideoData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { VideoGalleryTab } from "./videoGalleryTab";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay";

interface PlainGalleryProps {
    medias: SiteItemData[]
    fieldsDisplaySettings: LinkedItemFieldDisplay
    channelIconUrl: string
    tabTitleText: string
    isLTR: boolean
    fullArticleText: string
}

interface PlainGalleryState {
    selectedItemId: string
    videoPlayStarted: boolean
    isMediaSelected: boolean
}

export class FrontVideoGallery extends React.Component<PlainGalleryProps, PlainGalleryState>{
    public static siteScriptName = "FrontVideoGallery";
    constructor(props: PlainGalleryProps) {
        super(props as PlainGalleryProps);

        this.state = {
            selectedItemId: props.medias[0].itemId,
            videoPlayStarted: false,
            isMediaSelected: false,

        }
    }


    componentDidUpdate(_, prevState: PlainGalleryState) {
        if (this.state.selectedItemId !== prevState.selectedItemId) {
            // @ts-ignore
            if (window.YitVideo) {
                window.YitVideo.options.callback = function () {
                    let YtvComponent: any = document.querySelector(`${this.props.isLTR ? 'div.VideoGalleryEnglishComponenta .video-wrap[initialized="true"] video' : 'div.VideoGalleryComponenta .video-wrap[initialized="true"] video'} `);
                    YtvComponent && YtvComponent.paused && YtvComponent.play();
                }
                window.YitVideo.InfrastructureReInitialize();
            }
            if (window["defaultNativeVideoType"] === "flowplayer") {

                let VideoComponent: any = document.querySelector(`${this.props.isLTR ? 'div.VideoGalleryEnglishComponenta .mainVideo video' : 'div.VideoGalleryComponenta .mainVideo video'}`);
                const selectedItem = this.props.medias.find(item => item.itemId === this.state.selectedItemId)
                const media = selectedItem.media as SiteVideoData;
                const isMuteAvailable = media.isMuteAvailable;
                if(VideoComponent && VideoComponent.setSrc(selectedItem.media.url)) {VideoComponent.togglePlay(); VideoComponent.muted = true;}
                VideoComponent.on("cuepointend", function(e) {
                    if(!isMuteAvailable)  { VideoComponent.muted=false;}
                })


                //  window["defaultNativeVideoType"].InfrastructureReInitialize();
            }

        }
    }

    private onSelectMedia = (e, id: string) => {
        const isVideo = this.props.medias.find(x => x.itemId === id).media.mediaType === "MEDIA_VIDEO"
        if (!this.state.isMediaSelected) {
            _.forEach(this.props.medias, mediaItem => {
                if (mediaItem.media) {
                    (mediaItem.media as SiteVideoData).isAutoPlaying = true;
                }
            });
            this.setState({ isMediaSelected: true })
        }
        this.setState({ selectedItemId: id, videoPlayStarted: true })
    }


    private handleOnVideoPlayStarted = () => {
        this.setState({ videoPlayStarted: true })
    }
    private handleOnVideoPlayStopped = () => {
        this.setState({ videoPlayStarted: false })
    }



    render() {
        const { medias, fieldsDisplaySettings, channelIconUrl, tabTitleText, isLTR, fullArticleText } = this.props
        const { selectedItemId } = this.state
        const { isTitleVisible, isSubTitleVisible, isTabVisible } = fieldsDisplaySettings
        const selectedItem = medias.find(item => item.itemId === selectedItemId)
        const withlink = selectedItem.titleLink ? "withLink" : "";
        const notSelectedItems = medias.filter(item => item.itemId !== selectedItemId)
        if (medias.length === 0) { return null }
        return (
            <div className={isLTR ? 'VideoGalleryEnglishComponenta' : "VideoGalleryComponenta"} >
                <VideoGalleryTab isVisible={isTabVisible} text={tabTitleText} icon={channelIconUrl} />
                <div className="SelectedVideo" >
                    <div className={`slotDetails ${withlink}`} >
                        { isTitleVisible && <div className="slotTitle" dangerouslySetInnerHTML={{ __html: selectedItem.title }} />}
                        {isSubTitleVisible && <div className="slotSubTitle" dangerouslySetInnerHTML={{ __html: selectedItem.subTitle }} />}
                  
                    </div>
                    {selectedItem.titleLink &&
                        <HyperLinkBuilder
                            className="linkTo"
                            href={selectedItem.titleLink}
                            linkTarget={selectedItem.titleTargetLink}
                        >
                            {fullArticleText}
                        </HyperLinkBuilder>}
                    <div className="mainVideo">
                        {selectedItem.media &&
                            <SiteMedia
                                onPlayStarted={() => this.handleOnVideoPlayStarted()}
                                onPlayStopped={() => this.handleOnVideoPlayStopped()}
                                data={selectedItem.media} videoSettings={{ hideVideoAds: false, isVideoLightbox: false }} width={590} height={331}
                            />}
                    </div>

                </div>
                <div className="mediaListContainer">
                    {notSelectedItems.map((item, index) => {
                        if (item.media) return (<div key={index} onClick={(e) => this.onSelectMedia(e, item.itemId)} className="videoItem">
                            <div className="lisPreviewImage">
                                <img src={item.media.poster} />
                                <SlotIconDisplay itemId={item.itemId} icon={"video"} iconClassName="medium" isIconVisible={true} />
                            </div>
                            {isTitleVisible && <div className="slotTitle" dangerouslySetInnerHTML={{ __html: item.title }} />}
                        </div>)
                    })}
                </div>
            </div>
        )
    }
}
