import { ArticleCommentsTopTabRenderProps } from "../articleCommentsTopTabWrapper"
import { getTranslation } from "../translations";

require("./calcalist_site_commentsTabUI.less")

interface PremiumArticleCommentsTopTabUIProps {
    props: ArticleCommentsTopTabRenderProps
}

export class CalcalistArticleCommentsTopTabUI extends React.Component<PremiumArticleCommentsTopTabUIProps, {}>{
    private handleOnAddCommentBtnPress = (e) => {
        const { handleFormVisibility } = this.props.props
        handleFormVisibility()
    }

    render() {
        const { lang, isLtr, numOfComments, numOfDiscussions, sortSelection, areAllCommentsExpanded, isAddCommentFormVisible,
            expandAllComments, handleFormVisibility, handleSortSelection } = this.props.props
        let commentNumStyle = isLtr ? { marginRight: "5px" } : {}
        const buttonTitle = areAllCommentsExpanded ? "סגירת כל התגובות" : "פתיחת כל התגובות"
        return (
            <div className="CalcalistArticleCommentsTopTabUI">
                <div className="tab">
                    {numOfComments === 1 &&
                        <div className="tabTextWraper">
                            <span>לכתבה זו פורסמה תגובה אחת</span>
                        </div>}

                    {numOfComments !== 1 &&
                        <div className="tabTextWraper">
                            <span>לכתבה זו פורסמו </span>
                            <span style={commentNumStyle}>{` ${numOfComments} `} </span>
                            <span>תגובות ב </span>
                            <span>{numOfDiscussions} </span>
                            <span>דיונים</span>
                        </div>}
                    {((sortSelection === "end_to_start" || sortSelection === "start_to_end")) && numOfComments > 0 &&
                        <button className="expandAllCommentsBtn " title={buttonTitle} onClick={e => expandAllComments(!areAllCommentsExpanded)}>
                            <span>>> {buttonTitle}</span>
                        </button>}
                </div>

                <div className="topPanel">
                    <button
                        key="newCommentBtn"
                        className="newCommentBtn"
                        title="תגובה חדשה"
                        disabled={isAddCommentFormVisible}
                        onClick={this.handleOnAddCommentBtnPress}>
                        <span className="icon-comment" />
                        <span>תגובה חדשה</span>
                    </button>
                    {numOfComments > 0 &&
                        <div className="sortOptions">
                            <span className="selectLabel">הצגת:</span>
                            <div className="togglerWrapper"  >
                                <div className="toggler" />
                            </div>
                            <select className="sortSelect" defaultValue={sortSelection} onChange={handleSortSelection}>
                                <option value="end_to_start">{getTranslation(lang, "End To Start")}</option>
                                {numOfComments > 1 && <option value="start_to_end">{getTranslation(lang, "Start To End")}</option>}
                                {numOfComments > 2 && <option value="recommended">{getTranslation(lang, "Recommended")}</option>}
                                {numOfComments > 2 && <option value="interest">{getTranslation(lang, "Interest")}</option>}
                            </select>
                        </div>}
                </div>
            </div >
        )
    }
}