import * as classNames from "classnames"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { ArticleCommentRenderProps } from "../articleCommentWrapper";
import { getTranslation } from "../translations";
import { AddCommentFormWrapper, AddCommentFormRenderProps } from "../addCommentFormWrapper";
import { MobileWebAddCommentFormUI } from "./mutamAddCommentFormUI";

interface ArticleCommentsUIMobileWebProps {
    props: ArticleCommentRenderProps
}

export class ArticleCommentMobileWebUI extends React.Component<ArticleCommentsUIMobileWebProps, {}>{
    render() {
        const { lang, isExpanded, vote, talkback_like, likes, unlikes, toggleOpenedState, commentFormType, articleId,
            isAddCommentFormVisible, handleVoteButtonsClick, handleFormVisibility, handleCloseCommentForm,
            comment: {
                id,
                number,
                title,
                text,
                author,
                pubDate,
                level,
                recommended,
                talkback_source,
            } } = this.props.props
        const articlePublishDate = separateHourFromDateAndFormatToString(pubDate, { showHour: false, dateTimeSeperator: "|" })
        const expandedCommentClasses = classNames({
            "ArticleCommentMobileWeb": true,
            "expanded": isExpanded,
            "level1": level === 1,
            "level2": level === 2,
        })
        // const likeClasses = classNames({
        //     "likeSvg": true,
        //     "likePressed": vote === 1,
        // })
        // const dislikeClasses = classNames({
        //     "dislikeSvg": true,
        //     "dislikePressed": vote === -1,
        // })
        const commentTitleClasses = classNames({
            "commentTitle": true,
            "redTitleText": recommended,
            "expanded": isExpanded
        })
        const commentTextStyles = classNames({
            "commentText": true,
            "level1": level === 1,
            "level2": level === 2,
            "expanded": isExpanded
        })
        // const extendedBottomWrapperStyles = classNames({
        //     "bottomButtonsWrapper": true,
        //     "level1": level === 1,
        //     "level2": level === 2,
        // })
        const commentNumClasses = classNames({
            "commentNum": true,
            // "redTitleText": recommended,
        })
        // const positiveLikes = likes < 0 ? 0 : likes
        // const positiveUnlikes = unlikes < 0 ? 0 : unlikes
        return (
            <div id="ArticleCommentMobileWeb" className={expandedCommentClasses}>
                <div className="topView" >
                    {level === 1 && <div className={commentNumClasses} onClick={(e) => toggleOpenedState()}>{number}.</div>}
                    {level === 2 && <div className="level2ArrowContainer" onClick={(e) => toggleOpenedState()}><span className="level2Arrow" /></div>}

                    <div className="topLeftView">

                        <div className="commentDetailsWrapper" >
                            <div className={commentTitleClasses} onClick={(e) => toggleOpenedState()}>
                                <span className="titleText">{title}</span>
                                {text.length === 0 && <span>{getTranslation(lang, "Empty Content")}</span>}
                            </div>
                        </div>

                        <div className={commentTextStyles}>{text}</div>

                        <div className="details">
                            <div>  {author !== "" ? <span className="author">{`${author}`}</span> : null}
                                {author !== "" && <span>|</span>}
                                <span className="publishDate"> {`${articlePublishDate}${talkback_source ? " , " + talkback_source : ""}`}</span></div>
                            <div>

                                {/* <div className="voteButtonsWrapper">
                                    <span className="likesCounter">{positiveLikes}</span>
                                    <div
                                        className={likeClasses}
                                        onClick={(e) => handleVoteButtonsClick(true, "3state")}>
                                    </div>
                                    <span className="likesCounter unlikesCounter">{positiveUnlikes}</span>
                                    <div
                                        className={dislikeClasses}
                                        onClick={(e) => handleVoteButtonsClick(false, "3state")} >
                                    </div>
                                </div> */}

                                <button className="commentToComent" title={getTranslation(lang, "Comment To Coment")} disabled={isAddCommentFormVisible}
                                    onClick={e => handleFormVisibility("REPLY")}>
                                    <span> {getTranslation(lang, "Comment To Coment")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <AddCommentFormWrapper
                    render={(props: AddCommentFormRenderProps) => <MobileWebAddCommentFormUI props={props} />}
                    lang={lang}
                    articleId={articleId}
                    formType={commentFormType}
                    handleCloseCommentForm={handleCloseCommentForm}
                    parentTalkbackId={id}
                    isAddCommentFormVisible={isAddCommentFormVisible}
                />
            </div>
        )
    }
}