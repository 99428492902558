import * as indexOf from "lodash/indexOf"
import { SiteVideoData } from "../../../interfaces"
import { GenericLightboxVideoPlayer } from "./genericLightboxVideoPlayer"
import { PlayersTypes } from "../genericInlineVideoPlayer/genericInlineVideoPlayer"
import { removeHtmlTagsFromString } from "../../../jsonSerializer/common/jsonHelperUtils";
import { AbroadBlocked } from "../genericInlineVideoPlayer/abroadBlockedVideo";
require("./../site_videoPlayer.less")
require("./../site_videoPlayer-mobile.less")



interface SiteVideoLightboxProps {
    data: SiteVideoData
    playerType?: PlayersTypes
    allVideos: SiteVideoData[]
    hideVideoAds?: boolean
    applyStickyness: boolean
    loadVideoById?: (videoId: string) => void
    onSelect?: () => void
    onLightBoxOpened?: () => void
    onLightBoxClosed?: () => void
}

interface SiteVideoLightboxState {
    lightBox: boolean
}

export class SiteVideoLightbox extends React.Component<SiteVideoLightboxProps, SiteVideoLightboxState>{
    public constructor() {
        super();
        this.state = {
            lightBox: false,
        }
    }

    public youtubePlayerContainerAnchor: HTMLDivElement; // player container DOM ref

    private onPlay = (e: React.MouseEvent) => {
        this.setState({
            lightBox: true
        }, () =>
                this.props.onLightBoxOpened && this.props.onLightBoxOpened()
        )
    }

    private onClose = (e: React.MouseEvent) => {
        this.setState({
            lightBox: false,
        }, () =>
                this.props.onLightBoxClosed && this.props.onLightBoxClosed()
        )
    }

    public render() {
        const { lightBox } = this.state
        const { data, allVideos } = this.props
        const { isBigVideo } = data
        const initialIndexToPlayInLightbox = indexOf(allVideos, data)
        return (
            <div ref={ref => this.youtubePlayerContainerAnchor = ref} className="SiteVideoLightbox">
                {lightBox && <Lightbox initialIndexToPlayInLightbox={initialIndexToPlayInLightbox} onClose={this.onClose} {...this.props} />}
                <img src={data.poster} onClick={this.onPlay} style={posterStyle} />
                <span className={`iconOnPoster ${isBigVideo ? "big" : "small"}`} style={{ pointerEvents: "none" }}></span>
            </div>
        )
    }
}



const PLAYER_WIDTH = 640;
const YT_DEFAULT_ASPECT_RATIO = 16 / 9;
const PLAYER_HEIGHT = PLAYER_WIDTH / YT_DEFAULT_ASPECT_RATIO;

interface LightboxProps {
    playerType?: PlayersTypes
    allVideos: SiteVideoData[]
    hideVideoAds?: boolean
    applyStickyness: boolean
    initialIndexToPlayInLightbox: number
    onClose: (e: React.MouseEvent) => void
    loadVideoById?: (videoId: string) => void
    onSelect?: () => void
}

interface LightboxState {
    currentVideoData: SiteVideoData
}

class Lightbox extends React.Component<LightboxProps, LightboxState>{
    public closeButton: HTMLButtonElement;

    public constructor() {
        super();
        this.state = {
            currentVideoData: null
        }
    }

    componentDidMount() {
        const { initialIndexToPlayInLightbox } = this.props
        this.loadVideoData(initialIndexToPlayInLightbox)
    }

    private focusCloseButton = () => {
        this.closeButton.focus()
    }

    private loadVideoData = (index: number): void => {
        const { allVideos } = this.props
        this.setState({ currentVideoData: allVideos[index] })
    }

    render() {
        const { currentVideoData } = this.state
        const { allVideos, playerType, hideVideoAds, onSelect, applyStickyness, onClose } = this.props
        const isAbroad = typeof window !== "undefined" && window.isAbroad
        if (!currentVideoData) { return null }
        if (currentVideoData.blockAbroadViews && isAbroad) return <AbroadBlocked poster={currentVideoData.poster} />
        return (
            <div style={{ ...lightBoxStyle, display: "flex" }} onClick={onClose}>
                <div style={lightBoxPlayerStyle} className="video-container" onClick={e => { e.stopPropagation() }/*prevent invoking this.onClickOutside */}>
                    <div id="ynet_lightbox_video_header">
                        <div className="ynet_lightbox_video_header_p">
                            <div className="ynet_lightbox_floatr ynet_lightbox_video_header_title_wrap">
                                <span className="ynet_lightbox_video_header_title">{currentVideoData.title !== undefined && removeHtmlTagsFromString(currentVideoData.title)}</span>
                                &nbsp;
                            </div>
                            <button className="ynet_lightbox_floatl ynet_lightbox_video_close ynet_lightbox_pointer" onClick={onClose} ref={ref => this.closeButton = ref}>
                                <img className="ynet_lightbox_floatl" src="https://www.ynet.co.il/images/hp_player_close.gif" />
                            </button>
                        </div>
                    </div>

                    <GenericLightboxVideoPlayer
                        data={currentVideoData}
                        applyStickyness={applyStickyness}
                        hideVideoAds={hideVideoAds}
                        focusCloseBtn={this.focusCloseButton}
                        onSelect={onSelect}
                        width={PLAYER_WIDTH}
                        height={PLAYER_HEIGHT}
                    />

                    {allVideos.length > 1 &&
                        <div id="ynet_lightbox_video_footer">
                            <div id="ynet_lightbox_video_footer_inner">
                                {allVideos.map((video, index) =>
                                    <div className="thumbnailWrapper" key={index}>
                                        <div className="innerWrapper" >
                                            <img
                                                src={video.poster}
                                                onClick={e => this.loadVideoData(index)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}



// Based on current (flow player) css:
const lightBoxPlayerStyle = {
    display: "block",
    width: "640px",
}

const lightBoxStyle = {
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.75)",
    zIndex: 9999
}

const posterStyle = {
    height: "100%",
    width: "100%",
}