
import Slider from "react-slick";
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { LinkTargetProperties, BaseLinkTypes } from "../../../../../global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { LinkProperties } from "global/components/linkSettings/interfaces"
import { carouselItems } from '../../articleCarousel/components/carousel';
import { isAriaHidden, getImgAlt, getImgTitle, getNewlinkTargetProperties } from '../../../../widgetsUtils';

interface ArrowProps {
    onClick?: () => void;
}

interface CaricaturistMobileComponentaProps {
    data: carouselItems[]
    tabTitleLink: LinkProperties<BaseLinkTypes>
    tabTitleLinkTarget: LinkTargetProperties
    tabTitleText: string
    tabStyles: React.CSSProperties
}

interface CaricaturistMobileComponentaState {
    currentSlide: number
}

export class CalcalistWidePhotoMobileComponenta extends React.Component<CaricaturistMobileComponentaProps, CaricaturistMobileComponentaState> {
    public static siteScriptName = "CalcalistWidePhotoMobileComponenta"

    constructor() {
        super();
        this.state = {
            currentSlide: 0
        }
    }

    render() {
        const widePhotoItems = this.props.data;
        const { tabTitleLink, tabTitleLinkTarget, tabTitleText, tabStyles } = this.props;
        const { currentSlide } = this.state;
        const NextArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == widePhotoItems.length - 1} onClick={onClick} className="custom-arrow left"><span className="arrow-icon left"></span></button>);
        const PrevArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == 0} onClick={onClick} className="custom-arrow right"><span className="arrow-icon right"></span></button>);
        const linkTarget = getNewlinkTargetProperties();
        let settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            rtl: true,
            prevArrow: <NextArrow />,
            nextArrow: <PrevArrow />,
            afterChange: (current) => {
                this.setState({ currentSlide: widePhotoItems.length - 1 - current });
            }
        }

        return (
            <div className='calcalistWidePhotoMobileComponenta'>
                <HyperLinkBuilder className="header" title='פוטו כלכליסט' href={tabTitleLink.link.publishedLink} linkTarget={tabTitleLinkTarget}>
                    <span className="widePhotoIcon-pf"></span>
                    <span style={tabStyles} className="wide-photo-header-title">{tabTitleText}</span>
                    <span className="arrow-icon"></span>
                </HyperLinkBuilder>
                <Slider {...settings} >
                    {widePhotoItems.map(item => {
                        const { title, publishedLink, path, id, promotionImageDetails } = item;
                        return <HyperLinkBuilder key={id} href={publishedLink} linkTarget={linkTarget}>
                            <div className='image-container' title={title}>
                                <SiteSimpleImage
                                    src={path}
                                    alt={getImgAlt(promotionImageDetails)}
                                    aria-hidden={isAriaHidden(promotionImageDetails)}
                                    title={getImgTitle(promotionImageDetails)} />
                                <div className="title">{title}</div>
                            </div>
                        </HyperLinkBuilder>
                    })}
                </Slider>
            </div>
        )

    }
}

