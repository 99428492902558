import { IdGenerator } from "utils/idGenerator"
import { isServerRendering } from "config/serverConfig";


export class RenderReactAtSite extends React.Component<any, {}>{
    public render() {
        const child = React.Children.only(this.props.children)
        if (isServerRendering()) {
            const childPropsString = JSON.stringify(child.props)
            const siteScriptName = (child.type as any).siteScriptName || (child.type as any).name;
            const parentId = IdGenerator.generate();
            const script = `window.YITSiteWidgets.push(['${parentId}','${siteScriptName}',${childPropsString}]);`;
            return (
                <span>
                    <span id={parentId}  >{child}</span>
                    <script dangerouslySetInnerHTML={{ __html: script }} />
                </span>
            )
        } else {
            return child;
        }
    }
}
