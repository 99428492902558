import { Tab } from "./WeatherMapExtendedTab"

interface WeatherTabProps {
    tab: Tab
    isSelected: boolean
}

const WeatherTab = (props: WeatherTabProps) => {
    const { isSelected, tab } = props

    return (
        <div className={isSelected ? 'tab-item selected' : 'tab-item'}>
            <div>{tab.name}</div>
        </div>
    )
}

export default WeatherTab