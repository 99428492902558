require('./ynet_site_arrows.less')

export class BreakingNewsArrows extends React.Component<{}, {}>{
    public render() {
        return (
            <div className="BreakingNewsArrows">
                <div className="newsArrow one" ></div>
                <div className="newsArrow two" ></div>
                <div className="newsArrow three" ></div>
            </div>
        )
    }
}