import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"

import { datesAreOnSameDay, datesAreOnSameWeek, toDateWithoutHour, toHourWithoutDate } from "./siteCtechTwentyFourSevenWideComponenta";

interface CtechTwentyFourSevenWideComponentaItemProps {
    item:any
    index:number
    articles:any[]
    endWeek:number
    isLTR:boolean
}

export class CtechTwentyFourSevenWideComponentaItem extends React.Component<CtechTwentyFourSevenWideComponentaItemProps> {
    render() {
     const {item,articles,index,endWeek,isLTR} = this.props;
             return (
            <>
            <div className="item" style={(articles.length - 1 === index) ? { border: 'unset' } : {}}>                          
            <span className="date no-small-vp">{datesAreOnSameWeek(new Date(), new Date(item.dateUpdatedOnSite), endWeek) ?
                    toHourWithoutDate(new Date(item.dateUpdatedOnSite)) : toDateWithoutHour(new Date(item.dateUpdatedOnSite))}</span>
                <div className="textDiv no-small-vp">
                    <SimpleLinkRenderer href={item.publishedLink} className="itemTitle" title="">{item.title}</SimpleLinkRenderer>
                    {item.hasVideo && <img className="twentyFourSevenVideoIcon" src={`https://images1.calcalist.co.il/images/vds${isLTR?'-EN':''}.gif`} />}
                    <SimpleLinkRenderer className="category" href={item.category.publishedLink}>({item.category.textToShow})</SimpleLinkRenderer>
                </div>

                <SimpleLinkRenderer className="mobileItemContent show-small-vp" href={item.publishedLink}>
                    <div className="date">
                        {datesAreOnSameDay(new Date(), new Date(item.dateUpdatedOnSite)) ?
                            toHourWithoutDate(new Date(item.dateUpdatedOnSite)) : toDateWithoutHour(new Date(item.dateUpdatedOnSite)).substring(0, 5)}
                    </div>
                    <span className="itemTitle">{item.title}</span>
                </SimpleLinkRenderer>      
            </div>   
            </> 
        );

    }
}