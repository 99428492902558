import { AccordionItem, AccordionSection } from "./accordionSection";
import * as classNames from "classnames"
import { ToggleSwitch } from "../../commonComponents/inline/toggleSwitch/toggleSwitch"
import { LinkedFlashItem } from "../../defenitions/ynetnews1280/linkedFlashWidget/components/linkedFlashItem";
import {getTranslation} from "../../defenitions/ynet1280/autoTickerRowWidget/autoTickerRowTranslations"

interface accordionProps {
    items: AccordionItem[]
    isMobileWeb?: boolean
    isFirmaComp?: boolean
    languageCode: string
    hasArticleLink: boolean
}
interface accordionState {
    openedItemIndex: string
    typeOfView: string
    openedItems: any[]
}

export class Accordion extends React.Component<accordionProps, accordionState>{
    public static siteScriptName = "Accordion"
    constructor(props) {
        super(props)
        this.state = {
            openedItemIndex: this.getSharedItem() || null,
            typeOfView: "regular",
            openedItems: []
        }
    }
    getSharedItem = () => {
        const url = typeof window !== "undefined" ? window.location.href : "";
        const urlSliceIndex = url.indexOf("#")
        if (urlSliceIndex !== -1) {
            return url.slice(urlSliceIndex + 1)
        }
        return ""
    }
    private itemToggle = (i) => {
        const { openedItemIndex, typeOfView, openedItems } = this.state
        const { items } = this.props
        if (typeOfView === "expanded") {
            const idx = openedItems.findIndex(id => id === items[i].articleId);
            const articleId = items[i].articleId;
            (idx !== -1) ? this.closeItem(articleId) : this.openItem(articleId)
        } else {
            if (items[i].articleId === openedItemIndex) {
                this.setState({
                    openedItemIndex: null
                })
            }
            else {
                this.setState({
                    openedItemIndex: items[i].articleId
                })
            }
        }
    }
    private handelViewChange = (ev) => {
        const { items } = this.props
        const { openedItems } = this.state
        const newTypeView = ev.target.value
        this.setState((prevState: accordionState) => {
            return {
                ...prevState,
                typeOfView: newTypeView
            }
        })
        for (let i = 0; i < items.length; i++) {
            const idx = openedItems.findIndex(idx => idx === items[i].articleId)
            if (idx === -1) this.openItem(items[i].articleId)
        }
    }
    private openItem = (id) => {
        this.setState((prevState: accordionState) => {
            return {
                ...prevState,
                openedItems: [...prevState.openedItems, id]
            }
        })
    }
    private closeItem = (id) => {
        this.setState((prevState: accordionState) => {
            const newOpenedItems = prevState.openedItems.filter(i => i !== id)
            return {
                ...prevState,
                openedItems: newOpenedItems
            }
        })
    }
    private onSwitchChange = () => {
        const { typeOfView, openedItems } = this.state
        const { items } = this.props
        if (typeOfView === "regular") {
            this.setState({ typeOfView: "expanded" })
            for (let i = 0; i < items.length; i++) {
                const idx = openedItems.findIndex(id => id === items[i].articleId)
                if (idx === -1) this.openItem(items[i].articleId)
            }
        }
        else if (typeOfView === "expanded") {
            this.setState({ typeOfView: "regular" })
            for (let i = 0; i < openedItems.length; i++) {
                this.closeItem(openedItems[i])
            }
        }
    }

    render() {
        const { items, isMobileWeb, isFirmaComp, languageCode, hasArticleLink } = this.props;
        const { openedItemIndex, typeOfView, openedItems } = this.state;
        const lang = languageCode ? languageCode.slice(0,2) : "he";

        return (
            <div className="Accordion">

                {isMobileWeb ?
                    isFirmaComp ?
                        <div className="displayModeToggle">
                            <div className="toggleTitle">{getTranslation(lang, "ExtendedView")}</div>
                            <ToggleSwitch
                                checked={this.state.typeOfView === "expanded"}
                                onSwitchPressed={this.onSwitchChange}
                            />
                        </div> : ""
                    : <div className="radioViewsPreference">
                        <label>{getTranslation(lang, "StandardView")}
                            <input className="typeOfViewInput" type="radio" name="typeOfView" value="regular" onChange={this.handelViewChange} checked={this.state.typeOfView === "regular"} />
                            <div className={classNames("blackCircleInput", { "radioOn": typeOfView === "regular" })}></div>
                            <div className="backgroundOfInput"></div>
                        </label>
                        <label>{getTranslation(lang, "ExtendedView")}
                            <input className="typeOfViewInput" type="radio" name="typeOfView" value="expanded" onChange={this.handelViewChange} checked={this.state.typeOfView === "expanded"} />
                            <div className={classNames("blackCircleInput", { "radioOn": typeOfView === "expanded" })}></div>
                            <div className="backgroundOfInput"></div>
                        </label>
                    </div>}

                {items.map((item, i) => (
                    hasArticleLink ?
                        <LinkedFlashItem key={item.articleId} item={item as LinkedFlashItem} languageCode={languageCode} />
                        :
                        
                        <AccordionSection
                            key={item.articleId}
                            isOpen={(typeOfView === "regular" && openedItemIndex === item.articleId) || (typeOfView === "expanded" && openedItems.find(i => i === item.articleId))}
                            accordionItem={item}
                            onClick={() => this.itemToggle(i)}
                            shareUrl={item.shareUrl}
                            isMobileWeb={isMobileWeb}
                            languageCode={languageCode}
                        />
                ))}
            </div>
        )
    }
}
