import { YnetWeather } from "../ynetWeather/ynetWeather";
import { YnetSearchForm } from "../ynetSearchForm/ynetSearchForm";
import { YnetNewsSocial } from "./ynetnewsSocialButtons";
import { VestySocial } from "./vestySocialButtons";
import { VestyCurrency } from "./vestyCurrency";
import { isWcmEditor } from "../../../../../config/serverConfig"
var Cookies = require('js-cookie');
require('./ynetnews_vesty_site_ynetSearch.less')

interface YnetSearchProps {
    weather: WeatherObject[]
    weatherUrl?: string
    lang: string
    currency: CurrencyObj[]
}
interface WeatherObject {
    city: string
    temp: number
    imageName: string
}
export interface CurrencyObj {
    name: string
    value: number
    imageName: string
}
interface YnetSearchState {
    selectedCity: string
    selectedSearchVal: string
}
interface YnetSearchProps { }

export class YnetNewsSearch extends React.Component<YnetSearchProps, YnetSearchState> {
    public static siteScriptName = "YnetNewsSearch";
    constructor(props) {
        super(props)
    }
    private openInnewWindow = (url: string, name: string, width: number, height: number) => {
        if (!isWcmEditor()) {
            window.open(url, name, `toolbar=no,location=yes,directories=yes,status=yes,menubar=no,scrollbars=no,resizable=no,width=${width},height=${height}`)
        }
    }

    public render() {
        const { weather, lang, currency, weatherUrl } = this.props

        return (
            <div className={`ynetNewsSearch ${lang}`}>
                <div className="main_search">
                    <YnetSearchForm lang={lang} />
                    {lang === "ru" && <VestyCurrency currency={currency} />}
                    <div className="iframe_combina_container">
                        {isWcmEditor() && <div className="iframe_combina_kesem"></div>}
                        {lang !== "ru" ? <YnetNewsSocial openWindow={this.openInnewWindow} lang={lang} /> : <VestySocial openWindow={this.openInnewWindow} />}
                    </div>
                    {lang !== "es" && weather && <YnetWeather weather={weather} lang={lang} weatherUrl={weatherUrl} />}
                </div>
            </div>
        )
    }
}
