

import * as axios from "axios"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer"
import { TickerViewData } from "../../../tickerAuto/interfaces"
import { TickerAutoRowView } from "./tickerAutoRowView"
import { TickerAutoRowViewMobileWeb } from "./tickerAutoRowViewMobileWeb"

interface SiteTickerAutoRowComponentaProps {
    data: TickerViewData
    height: number
    componentaId: string
    isMobileWeb: boolean
    isEnglish: boolean
    languageCode: string
    hasArticleLink: boolean
}

interface SiteTickerAutoRowComponentaState {
    articles: any
}

const MINUTES_TO_SHOW_NEW_FLASH_ALERT = 5

export class SiteTickerAutoRowComponenta extends React.Component<SiteTickerAutoRowComponentaProps, SiteTickerAutoRowComponentaState>{
    public static siteScriptName = "SiteTickerAutoRowComponenta"

    static defaultProps = { isWar: false }
    constructor(props) {
        super(props)
        this.state = {
            articles: []
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setArticles(newProps.data)
        }
    }

    componentDidMount() {
        const { data } = this.props;
        if (data.category.link.id) {
            this.setArticles(data)
            setInterval(() => {
                this.setArticles(data)
            }, 60000);
        }
    }

    private setArticles(data) {
        const { componentaId } = this.props
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/iphone/json/api/auto_ticker/${componentaId}`)
            .then((res: any) => {
                const articles = res.data.data;
                if (articles && articles[0] && document.getElementById('flashBell')) {
                    const articleDate = articles[0].dateUpdatedOnSite;
                    if (new Date().getTime() - articleDate < MINUTES_TO_SHOW_NEW_FLASH_ALERT * 60 * 1000) {
                        document.getElementById('flashBell').classList.add("newFlash");
                    } else {
                        document.getElementById('flashBell').classList.remove("newFlash");
                    }
                }
                this.setState({ articles });
            })
            .catch(err => console.error("error geting ticker data:", err))
    }

    render() {
        const { data, height, isMobileWeb, isEnglish, languageCode, hasArticleLink } = this.props
        const { articles } = this.state
        return (
            <div>
                {isMobileWeb ?
                    <TickerAutoRowViewMobileWeb data={data} height={height} articles={articles} isMobileWeb={isMobileWeb} isEnglish={isEnglish} languageCode={languageCode} hasArticleLink={hasArticleLink}/> :
                    <TickerAutoRowView data={data} height={height} articles={articles} isEnglish={isEnglish} hasArticleLink={hasArticleLink}/>
                }
            </div>
        )
    }
}