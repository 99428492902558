import { AddCommentFormRenderProps } from "../addCommentFormWrapper";
import { getTranslation } from "../translations";
import { disablePageRefresh, enablePageRefresh } from "../../../../../siteWidgets/siteWidgetUtils";

interface AddCommentFormProps {
    props: AddCommentFormRenderProps
}

interface AddCommentFormState {
    isVisibleAfterAlertClose: boolean
}

const FORM_CLOSE_ANIMTAION_TIME = 500;
const LINK_DICT = {
    "he": "https://www.ynet.co.il/home/1,7340,L-827,00.html",
    "en": "https://www.ynetnews.com/articles/0,7340,L-3048342,00.html",
    "ru": "https://www.vesty.co.il/articles/0,7340,L-4867560,00.html",
};

export class MobileWebCalcalistAddCommentFormUI extends React.Component<AddCommentFormProps, AddCommentFormState>{
    constructor() {
        super()
        this.state = {
            isVisibleAfterAlertClose: false,
        }
    }

    shouldComponentUpdate(nextProps: AddCommentFormProps, nextState: AddCommentFormState) {
        return this.state.isVisibleAfterAlertClose !== nextState.isVisibleAfterAlertClose
            || this.props.props.isAddCommentFormVisible !== nextProps.props.isAddCommentFormVisible
            || this.props.props.isSent !== nextProps.props.isSent
            || this.props.props.title !== nextProps.props.title
            || this.props.props.name !== nextProps.props.name
            || this.props.props.comment !== nextProps.props.comment
            || this.props.props.isFirstComment !== nextProps.props.isFirstComment
    }

    private formContarinerRef: HTMLDivElement;
    private formRef: HTMLDivElement;
    private sentAlertRef: HTMLDivElement;

    private onConfirmAlertBtnPress = () => {
        const { closeAlert, resetFormData, handleCloseCommentForm } = this.props.props

        this.setState({ isVisibleAfterAlertClose: true })
        closeAlert()
        handleCloseCommentForm()
        resetFormData()
        setTimeout(() => {
            this.setState({ isVisibleAfterAlertClose: false })
        }, FORM_CLOSE_ANIMTAION_TIME);
    }

    handleInputFocus = () => {
        enablePageRefresh()
    }

    handleInputOutOfFocus = () => {
        disablePageRefresh()
    }

    render() {
        const { isAddCommentFormVisible, isSent, title, name, comment, lang, handleCloseCommentForm, onInputChanged, handleCommentSend, isFirstComment } = this.props.props
        const { isVisibleAfterAlertClose } = this.state
        const termsLink = LINK_DICT[lang];

        return (
            <div className="MobileWebAddCommentFormUI" style={{ maxHeight: isAddCommentFormVisible ? this.formContarinerRef.scrollHeight : 0 }} ref={ref => this.formContarinerRef = ref}>
                <div className="AddCommentForm">
                    <div className="AddCommentFormContainer" style={{ maxHeight: !isSent && !isVisibleAfterAlertClose ? this.formRef && this.formRef.scrollHeight : 0 }} ref={ref => this.formRef = ref}>
                        {!isFirstComment &&
                            <div className="topTitleWrapper">
                                <button className="closeBtnWrapper" title={getTranslation(lang, "Close(Order Male)")} onClick={e => handleCloseCommentForm()}>
                                    <span className="closeIcon" />
                                </button>
                            </div>}

                        <div className="userDetailsInputs">
                            <input
                                className="userDetailsInput inputStyle"
                                maxLength={20}
                                value={name}
                                placeholder={getTranslation(lang, "Name")}
                                onChange={(e) => onInputChanged(e, "name")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            />
                        </div>

                        <div className="commentInputs">
                            <input
                                className="title inputStyle"
                                type="text"
                                maxLength={60}
                                value={title}
                                placeholder={getTranslation(lang, "Title")}
                                onChange={(e) => onInputChanged(e, "title")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            />

                            <textarea
                                className="comment inputStyle"
                                type="text" maxLength={4000}
                                value={comment}
                                placeholder={getTranslation(lang, "Comment Content")}
                                onChange={(e) => onInputChanged(e, "comment")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            >
                            </textarea>
                        </div>

                        <div className="bottomPanel">
                            <div className="bottomPanelRow">
                            <div className="panelRowTerms">
                                    {" אין לשלוח תגובות הכוללות מידע המפר את"}
                                <a href="//z.calcalist.co.il/mvc/long/2018/OrganizationalStructure/About/Terms.html" target="_blank" title={getTranslation(lang, "terms of use")} style={{ margin: "auto 5px" }} >תנאי השימוש של כלכליסט</a>
                                    {" לרבות דברי הסתה, דיבה וסגנון החורג מהטעם הטוב."}
                                </div>
                                <button className="sendBtn" id="addCommentSendButton" title={getTranslation(lang, "Post Comment")} onClick={e => handleCommentSend()}>
                                    <span>{getTranslation(lang, "Post Comment")}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="commentSentMessageWrapepr" style={{ maxHeight: isSent ? this.sentAlertRef && this.sentAlertRef.scrollHeight : 0 }} ref={ref => this.sentAlertRef = ref}>
                        <div className="thankYouWrapper">
                            <div className="thankYou"><strong>{getTranslation(lang, "Thank You")},</strong> <span>{getTranslation(lang, "Comment Sent Confirmation Message")}</span></div>
                            <button className="closeTextWrapper" title={getTranslation(lang, "Close")} onClick={this.onConfirmAlertBtnPress}>
                                <span className="closeIcon" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}