import { SimpleLinkRenderer } from "../../../../commonComponents/inline/hyperLink"
import * as classNames from "classnames"
require('./ynetnews_vesty_site_newYnetWeather.less')
import { getCityNameTranslation } from "../../../ynetSearch/components/ynetWeather/cityNamesTranslator";
var Cookies = require('js-cookie');
const clickOutside = require('react-click-outside');

interface YnetWeatherState {
    selectedCity: any
    isMenuOpened: boolean
}

interface YnetWeatherProps {
    weather: any
    defaultCityIndex: number
    weatherUrl?: string
    placement?: string
}

declare let window: Window & {
    languageCode: string
}


@clickOutside
export class YnetNewsWeatherInHeader extends React.Component<YnetWeatherProps, YnetWeatherState> {
    constructor(props) {
        super(props)
        let city = Cookies.get('weatherCity');
        this.state = {
            selectedCity: city || props.defaultCityIndex,
            isMenuOpened: false
        }

    }
    private handleClickOutside = () => {
        this.setState({
            isMenuOpened: false
        });
    }

    private updateTemperature = (i) => {
        this.setState({ selectedCity: i }, () => {
            Cookies.set('weatherCity', i, { expires: 365 });
        })
        this.toggleMenu()
    }
    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }
    public render() {
        const { weather, weatherUrl, placement } = this.props
        const { selectedCity, isMenuOpened } = this.state
        const selectedWeather = weather[selectedCity] ? weather[selectedCity]  : {city:"", imageName:"", temp:""};
        const language = (typeof window !== "undefined" &&  window.languageCode)  || "";
        const lang = language.includes("ru") ? "ru" : "en"; 
        const siteName =    lang=="ru" ? "https://www.vesty.co.il" : "https://www.ynetnews.com";
        const imgSrc = `${siteName}/Cnt/Images/Weather/${selectedWeather.imageName}`;
        const urlToCity = getCityNameTranslation("link", selectedWeather.city) ? "/" + getCityNameTranslation("link", selectedWeather.city) : "";
        const weatherPageUrl = weatherUrl ? weatherUrl + urlToCity : "https://www.ynetnews.com/weather";
        return (
            <div className={classNames("WeatherViewInHeader", placement)} id={placement ? placement : "main_header_weather"}>


                <div className="weatherimage">
                    {selectedWeather &&
                        <SimpleLinkRenderer href={weatherPageUrl} aria-label="Weather">

                            <img src={imgSrc} aria-hidden="true" />
                        </SimpleLinkRenderer>}
                </div>
                <div className="weathertempsdiv">
                    {selectedWeather &&
                        <SimpleLinkRenderer className="weatherLink" href={weatherPageUrl}>
                            <span className="weathertemps">{selectedWeather.temp}&deg;</span>
                        </SimpleLinkRenderer>}
                </div>

                <div id={placement ? `${placement}_weatherselect` : `headerWeatherSelect`} className="headerWeatherSelect">

                    <SimpleLinkRenderer href={weatherPageUrl} aria-label="weather" className="selectedCity" >{selectedWeather && getCityNameTranslation(lang, selectedWeather.city)}</SimpleLinkRenderer>

                    <div className={isMenuOpened ? " menuToggler toClose" : "menuToggler toOpen"} onClick={this.toggleMenu} />
                    {isMenuOpened && <div id='weatherCitySelect' aria-label="choose a city" className="weatherCitieSelect">
                        {weather.map((city, i) => <div key={getCityNameTranslation(lang, city.city)} onClick={() => this.updateTemperature(i)}>{getCityNameTranslation(lang, city.city)}</div>)}
                    </div>}

                </div>
            </div>
        )
    }
}