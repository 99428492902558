import { sprintf } from "sprintf-js"
import * as map from "lodash/map"
import * as escape from "lodash/escape"
import { WarppedItemProps, WidgetContentItemWarpperProps } from "widgets/commonComponents/base/widgetContentItemWarpper"
import { LinkedItemSlotData } from "widgets/commonDataObjects/linkedItemSlotData"
import { BasicMediaData } from "./commonDataObjects/basicMediaData"
import { ComponentaArticleResource, CategoryTreeNode, ComponentaVideoResource } from "pagesPage/stateInterfaces"
import { LinkedPageResource, SiteImageData, SiteVideoData, SiteMediaData, DateFormat, PageWidgetType } from "./interfaces"
import { CopyImageCommand } from "images/actions/imagesActions"
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { ContentTypes, MediaContentTypes } from "constants/contentTypes"
import * as ApiUrls from "config/apiUrls"
import { ImageDetails, ExtededImageDetails } from "images/interfaces"
import { MediaVideoData } from "media/interfaces"
import { VideoBehaiforData } from "widgets/commonDataObjects/interfaces"
import { ImageSIzeDefintion, ImageSize } from "constants/imageSizes"
import { ComponentaIconType, ILinkedItemWidgetData } from "./commonDataObjects/interfaces"
import { DataList } from "widgets/commonDataObjects/dataList"
import settings from "config/settings"
import { getLocaleDateFormat, getTimezone } from "../config/serverConfig"
import { DateFormatOptions, getDefaultDateFormatBySite } from "./defaultDateFormatOptionsBySite";
import { getFrontImageUrl } from "front/staticServerRendering/frontImage"
import { format, register } from 'timeago.js'
import { ChannelIconDisplay } from "./commonComponents/settings/categoryIconDisplaySelect/categoryIconDisplaySelect"
import { DataObjectBase } from "./commonDataObjects/dataObjectBase"
import { WebPlatform } from "../front/staticServerRendering/renderMobileArticle";
//import { getVodPromotionImageId } from "../articlePage/selectors";
const getVodPromotionImageId = (videoId: string) => "dsfdsf";


export const BuildLinkedSlotProps = <T extends LinkedItemSlotData>(index, slot: T, inEdit, handleItemChanged, handleItemDroped, moreProps?): WarppedItemProps<T> & WidgetContentItemWarpperProps => ({
    data: slot,
    isInEditMode: inEdit,
    getItemType: () => slot.item.itemType,
    onChange: (data: T) => handleItemChanged(index, data),
    onItemDroped: (itemType: string, data: T, isSameWidgetType: boolean) => handleItemDroped(index, itemType, data, isSameWidgetType),
    ...moreProps,
})

interface LinkedSlotUpdateAfterArticleRecourceLoadedOptions {
    setTitle: boolean
    setSubtitle: boolean
    setAuthor?: boolean
    insertRoofToTitle?: boolean
    setRoofTitle?: boolean
    shouldCopyToMobile?: boolean
    keepMedia?: boolean
}

interface LinkedSlotUpdateAfterVideoRecourceLoadedOptions extends Partial<LinkedSlotUpdateAfterArticleRecourceLoadedOptions> { }
export const linkedSlotUpdateAfterArticleRecourceLoaded = <T extends LinkedItemSlotData>(data: T, { copyImage }: { copyImage: CopyImageCommand }, { articleData, image }: ComponentaArticleResource, { setSubtitle, setTitle, setAuthor, insertRoofToTitle, shouldCopyToMobile, keepMedia }: LinkedSlotUpdateAfterArticleRecourceLoadedOptions) => {
    if (insertRoofToTitle === undefined) {
        insertRoofToTitle = WCM_FEATURES_FLAGS.INSERT_ARTICLE_ROOF_TO_TITLE;
    }
    let item = data.item;
    if (setTitle) {
        const title = escape(articleData.title);
        const articleRoof = articleData.roof
        let roof = getRoofWithColor(insertRoofToTitle, articleRoof);
        item = item.setTitle(roof + title);
    }
    if (setSubtitle) {
        item = item.setSubTitle(escape(articleData.subTitle));
    }
    if (setAuthor) {
        item = item.setAuthor(escape(articleData.author));
    }
    if (!insertRoofToTitle) {
        const articleRoof = articleData.roof
        item = item.setRoofTitle(escape(articleRoof));
    }

    let newData = data.setItem(item).setMarketingContentText(articleData.marketingContentText).setIsMarketingContent(articleData.isMarketingContent);

    if (!keepMedia) {
        let media = data.media;
        if (image && image.imageId) {
            const imageId = media.get(0).mediaId;
            copyImage(image.imageId, imageId, shouldCopyToMobile);
            const mobileImageId = shouldCopyToMobile ? media.get(0).mediaId + "_mobile" : null
            media = media.set(0, media.get(0).setMediaType("MEDIA_IMAGE").setResourceId(imageId).setMobileImageId(mobileImageId));
        }
        newData = newData.setMedia(media);
    }
    return newData
}
export const linkedSlotUpdateAfterVideoRecourceLoaded = <T extends LinkedItemSlotData>(data: T, { copyImage }: { copyImage: CopyImageCommand }, { videoData, vodPromotionImageId }: ComponentaVideoResource, { setTitle, setSubtitle, setAuthor }: LinkedSlotUpdateAfterVideoRecourceLoadedOptions) => {

    let item = data.item;
    if (setTitle) {
        const title = videoData.vodTitle ? escape(videoData.vodTitle) : "";


        item = item.setTitle(title);
    }
    if (setSubtitle) {
        const subTitle = videoData.vodDetailTitle ? escape(videoData.vodDetailTitle) : "";
        item = item.setSubTitle(subTitle);
    }

    if (setAuthor) {
        item = item.setAuthor(escape(videoData.credit));
    }
    let newData = data.setItem(item);
    let media = data.media;

    if (vodPromotionImageId) {
        const imageId = media.get(0).mediaId;
        copyImage(vodPromotionImageId, imageId, false);

        media = media.set(0, media.get(0).setMediaType("MEDIA_IMAGE").setResourceId(imageId));
    }
    newData = newData.setMedia(media);


    return newData
}

function getRoofWithColor(insertRoofToTitle: boolean, articleRoof: string) {
    if (insertRoofToTitle && articleRoof && articleRoof.trim()) {
        const color = hexToRgbA(settings.defaultTitleColorPickerColors[1]);
        if (color) {
            return `<span style="color: ${color};" data-roof-text="${escape(articleRoof)} | ">${escape(articleRoof)} | </span>`
        }
    }
    return "";
}

export class SiteWebStorage {
    static getLocalStorageItem = (key: string) => {
        try { return JSON.parse(typeof localStorage !== 'undefined' ? localStorage.getItem(key) : null) } catch (error) { console.error(error) }
    }
    static setLocalStorageItem = (key: string, value: any) => {
        try { typeof localStorage !== 'undefined' ? localStorage.setItem(key, JSON.stringify(value)) : null } catch (error) { console.error(error) }
    }
    static removeLocalStorageItem = (key: string) => {
        try { typeof localStorage !== 'undefined' ? localStorage.removeItem(key) : null } catch (error) { console.error(error) }
    }

    static getSessionStorageItem = (key: string): any => {
        try { return JSON.parse(typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(key) : null) } catch (error) { console.error(error) }
    }

    static setSessionStorageItem = (key: string, value: any) => {
        try { typeof sessionStorage !== 'undefined' ? sessionStorage.setItem(key, JSON.stringify(value)) : null } catch (error) { console.error(error) }
    }

    static removeSessionStorageItem = (key: string) => {
        try { typeof sessionStorage !== 'undefined' ? sessionStorage.removeItem(key) : null } catch (error) { console.error(error) }
    }
}

export const getNewLinkProperties = (): LinkProperties<BaseLinkTypes> => ({
    type: BaseLinkTypes.NONE,
    link: { id: "", description: "" },
})

export const getMobileGalleryArrayObj = (image: ImageDetails | ExtededImageDetails, platform:WebPlatform) => {
    const imageSize = platform && platform==="tablet" ? ImageSize.xLarge : ImageSize.large;
    const imageUrl = getFrontImageUrl(image, { imageSize: imageSize })
    return {
        'url': imageUrl,
        'imgNote': `${image.caption} - ${image.credit}`
    }
}


export const getNewlinkTargetProperties = (): LinkTargetProperties => ({
    linkOpenType: "same",
    lightBoxWidth: 803,
    lightBoxHeight: 700,
    lightBoxOpacity: 70,
    lightBoxBgColor: "#000000",
})
export const getNewWindowOpenTargetProperties = (): LinkTargetProperties => ({
    linkOpenType: "new",
    lightBoxWidth: 803,
    lightBoxHeight: 700,
    lightBoxOpacity: 70,
    lightBoxBgColor: "#000000",
})



export function getLinkResource(link: LinkProperties<BaseLinkTypes>): LinkedPageResource | null {
    switch (link.type) {
        case ContentTypes.ARTICLE:
        case ContentTypes.TAG:
        case ContentTypes.CATEGORY:
        case ContentTypes.FILE:
            return { resourceId: link.link.id, resourceType: link.type };
        default:
            return null;
    }
}

export function getLinkResourceWithVideo(link: LinkProperties<BaseLinkTypes | "MEDIA_VIDEO">): LinkedPageResource | null {
    switch (link.type) {
        case ContentTypes.MEDIA_VIDEO:
            return { resourceId: link.link.id, resourceType: link.type };
        default:
            return getLinkResource(link as LinkProperties<BaseLinkTypes>)
    }
}

export const getImgTitle = (image: ImageDetails, hideAlt?:boolean) => {
    let title: string;
    if (image) {
        let caption ="";
        if(!hideAlt){
            caption =image.caption ? image.caption : "";
        }
        const shouldAddBrekets = (image.credit && image.credit.trim() && caption && caption.trim());
        title = `${(caption || "")}${(shouldAddBrekets && " (")}${(image.credit || "")}${(shouldAddBrekets && ") ")}`
    }
    else { title = "" }

    return title;
}
export const getImgAlt = (image: ImageDetails) => {
    let alt: string;
    if (image) {
        if (image.alt && image.alt.trim() !== "") {
            alt = image.alt
        }
        else {
            alt = image.caption
        }

    }
    else { alt = "" }

    return alt;
}
export const isAriaHidden = (image: ImageDetails) => {
    let isAriaHidden: boolean;
    if (image && image.publishedLink) {

        isAriaHidden = false;


    }
    else { isAriaHidden = true }

    return isAriaHidden;
}

export const siteMediaConverter = (imageSize: ImageSIzeDefintion, mediaData: BasicMediaData[], getImage: (imageId: string) => ImageDetails, getVideo: (videoId: string) => MediaVideoData, videoBehavior: VideoBehaiforData, itemTitle: string, itemLinkUrl: string, isBigVideo?: boolean, isMutam?: boolean): SiteMediaData[] => {
    const medias = map(mediaData, (m, i) => {
        if (m.mediaType === MediaContentTypes.MEDIA_IMAGE) {
            const image = (isMutam && m.mobileImageId ? getImage(m.mobileImageId) : getImage(m.resourceId)) || {};
            const siteImage: SiteImageData = {
                mediaType: MediaContentTypes.MEDIA_IMAGE,
                url: ApiUrls.getImageUrl(image, imageSize),
                alt: getImgAlt(image),
                credit: image.credit,
                ariaHidden: isAriaHidden(image),
                title: getImgTitle(image),
                className: `gallery${i} `,
                labelText:m.labelText,
                textPlace:m.textPlace

            };
            return siteImage;
        } else {
            const video = getVideo(m.resourceId);
            const siteVideo: SiteVideoData = {
                ...videoBehavior,
                mediaType: MediaContentTypes.MEDIA_VIDEO,
                url: video.main_link,
                downGradeUrl: video.low_res_link,
                poster: video.posterSrc,
                title: itemTitle,
                itemLinkUrl,
                isBigVideo,
                youtube_id: video.youtube_id,
                mediaId: video.mediaVideoId,
                blockAbroadViews: video.blockAbroadViews
            };
            return siteVideo;
        }
    });
    return medias;
}

export const hexToRgbA = (hex: string, opacity?: string): string => {
    if (hex.startsWith("##")) {
        hex = hex.replace(/##/g, "#")
    }
    const defaultColor = "1, 1, 1", defaultOpacity = "1"
    let color;
    try {
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            color = hex.substring(1).split('')
            if (color.length == 3) {
                color = [color[0], color[0], color[1], color[1], color[2], color[2]]
            }
            color = `0x${color.join('')} `
            return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(',')}, ${opacity || defaultOpacity}) `
        }
    } catch (e) {
        return `rgba(${defaultColor}, ${defaultOpacity}) `
    }
    return `rgba(${defaultColor}, ${defaultOpacity}) `
}

export const formatProperArticleDateForRender = (launchDate: Date | string, dateUpdatedOnSite: Date | string): string => {
    if (dateUpdatedOnSite) {
        const dateUpdatedOnSiteDateObj = typeof dateUpdatedOnSite === "string" ? new Date(dateUpdatedOnSite) : dateUpdatedOnSite
        return separateHourFromDateAndFormatToString(dateUpdatedOnSiteDateObj)
    }
    if (launchDate) {
        const launchDateDateObj = typeof launchDate === "string" ? new Date(launchDate) : launchDate
        return separateHourFromDateAndFormatToString(launchDateDateObj)
    }
    return ""
}

export const concatStringsWithCommaSeperator = (strings: string[]): string => {
    let string = ""
    for (const s of strings) {
        string = string.concat(`${s}, `)
    }
    return string.replace(/,\s*$/, "");
}


const defaultDateFormatOptions: DateFormatOptions = {
    showDate: true,
    showHour: true,
    dateTimeSeperator: ", ",
    padDateWithZero: true,
    padHourWithZero: true,
    fullYear: false,
    isSlashSeparator: false,
    isDateFirst: false,
    datePartOrder: DateFormat.dmy,
}


export const separateHourFromDateAndFormatToString = (dateToManipulate: Date | string, options?: DateFormatOptions): string => {
    const dateToManipulateTemp = typeof dateToManipulate === "string" ? new Date(dateToManipulate) : dateToManipulate
    if (!dateToManipulateTemp || !isFinite(dateToManipulateTemp as any) || !dateToManipulateTemp.getDate) { return "" }
    options = { ...getDefaultDateFormatBySite(getLocaleDateFormat()), ...options };
    const {
        showDate,
        showHour,
        dateTimeSeperator,
        padDateWithZero,
        padHourWithZero,
        fullYear,
        isSlashSeparator,
        isDateFirst,
        datePartOrder,
    } = options;
    const dateParts = getDateParts(dateToManipulateTemp, getTimezone());
    const dd = dateParts.day;
    const mm = dateParts.month;
    const yy = dateParts.year.toString().substr(-2);
    const yyyy = dateParts.year
    const hh = dateParts.hour == 24 ? 0 : dateParts.hour
    const MM = dateParts.minute
    let yearFormat = fullYear ? yyyy : yy
    let datePartFormat = padDateWithZero ? "%02d" : "%d";
    let hourPartFormat = padHourWithZero ? "%02d" : "%d";
    let separatorFormat = isSlashSeparator ? "/" : "."
    const date = datePartOrder === DateFormat.mdy ? sprintf(`${datePartFormat}${separatorFormat}${datePartFormat}${separatorFormat}%d`, mm, dd, yearFormat)
        : sprintf(`${datePartFormat}${separatorFormat}${datePartFormat}${separatorFormat}%d`, dd, mm, yearFormat);
    const time = sprintf(`${hourPartFormat}:%02d`, hh, MM)
    if (showDate && showHour) return isDateFirst ? `${date}${dateTimeSeperator}${time} ` : `${time}${dateTimeSeperator}${date} `;
    if (showDate) return date;
    if (showHour) return time;
    return "";
}


export const calculateShouldAddArticleAutoplayIndicator = (icon: ComponentaIconType, media: SiteMediaData | DataList<BasicMediaData>, isIconVisible?: boolean, widgetType?: string): boolean => {
    let isImageMedia: boolean;
    if (isIconVisible) return icon === "video" || icon === "automatic";
    else {
        if ((media as SiteImageData).mediaType) {
            isImageMedia = (media as SiteImageData).mediaType === MediaContentTypes.MEDIA_IMAGE;
        } 
         else if (widgetType === "article-blogs-hot-titles") {            
            const m = media as any;
            isImageMedia = m.array.length <= 1 && m.array[0].mediaType === MediaContentTypes.MEDIA_IMAGE
         }
        else {
            const m = media as DataList<BasicMediaData>;
            isImageMedia = m.array.length <= 1 && m.get(0).mediaType === MediaContentTypes.MEDIA_IMAGE
        }

        return isImageMedia && (icon === "video" || icon === "automatic")
    }

}

export const AUTOPLAY_HASH_TAG = "#autoplay";
export const calculateAutomaticComponentaUrl = (item) => item.publishedLink + (item.hasVideo ? AUTOPLAY_HASH_TAG : "")
export const getQueryStringValue = (key) => {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export const isQaHost = (hostUrl: string): boolean => {
    return hostUrl.toLocaleLowerCase().includes("qa")
}

export const openFacebookShare = (url: string, textToShare: string = "", analyticsCb?: () => void, content: string = "", current_article_id?: string, hashPart?: string) => {
    const widthAndHeight = `width=500, height=500`
    const hostname = window.location.hostname.replace("www.", "");
    const qText = textToShare.trim() ? `&qtext=${textToShare}` : "";
    const utmContent = content.trim() ? `&utm_content=${content}` : "";
    const utmTerm = `${current_article_id || window.articleId ? `utm_term=${current_article_id ? current_article_id : window.articleId}` : ""}`;
    url = `${url}?utm_source=${hostname}&utm_medium=Share&utm_campaign=Facebook&${utmTerm}${utmContent}${qText}`;
    url = hashPart ? url + "#" + hashPart : url;
    window.open("//www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url), "", widthAndHeight);
    analyticsCb && typeof analyticsCb === "function" && analyticsCb()
}
export const openLinkedinShare = (url: string, analyticsCb?: () => void, hashPart?: string) => {
    const widthAndHeight = `width=500, height=500`
    const hostname = window.location.hostname.replace("www.", "");
    // const qText = textToShare.trim() ? `&qtext=${textToShare}` : "";
    //  const utmContent = content.trim() ? `&utm_content=${content}` : "";
    url = hashPart ? url + "#" + hashPart : url;
    window.open("//linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(url), "", widthAndHeight);
    analyticsCb && typeof analyticsCb === "function" && analyticsCb()
}

export const openMailShare = (body: string, siteName: string, url: string, analyticsCb?: () => void, content: string = "", hashPart?: string) => {
    const convertedBody = replaceCharacters(body)
    const title = "כתבה מעניינת מ"
    const hostname = window.location.hostname.replace("www.", "");
    const utmContent = content.trim() ? `&utm_content=${encodeURIComponent(content)}` : "";
    url = `${url}?utm_source=${encodeURIComponent(hostname)}&utm_medium=Share&utm_campaign=Mail${utmContent}`;
    url = hashPart ? url + "#" + hashPart : url;
    window.location.href = `mailto:?subject=${title}${siteName}&body=${encodeURIComponent(convertedBody)} %0d %0d ${encodeURIComponent(url)}`;
    analyticsCb && typeof analyticsCb === "function" && analyticsCb()
}
export const openTwitterShare = (url: string, analyticsCb?: () => void, content: string = "", current_article_id?: string, hashPart?: string) => {
    const widthAndHeight = `width=500, height=500`
    const hostname = window.location.hostname.replace("www.", "");
    const utmContent = content.trim() ? `&utm_content=${encodeURIComponent(content)}` : "";
    const utmTerm = `${current_article_id || window.articleId ? `utm_term=${current_article_id ? current_article_id : window.articleId}` : ""}`;
    url = `${url}?utm_source=${hostname}&utm_medium=Share&utm_campaign=Twitter${utmTerm}${utmContent}`;
    url = hashPart ? url + "#" + hashPart : url;
    const urlEncoded = encodeURIComponent(url)
    window.open("//twitter.com/intent/tweet?original_referer=" + urlEncoded + "&text=" + encodeURIComponent(document.title) + "&url=" + urlEncoded, "", widthAndHeight);
    analyticsCb && typeof analyticsCb === "function" && analyticsCb()
}

export const openTelegramShare = (url: string, analyticsCb?: () => void, content: string = "", current_article_id?: string, hashPart?: string) => {
    const widthAndHeight = `width=500, height=500`
    const hostname = window.location.hostname.replace("www.", "");
    const utmContent = content.trim() ? `&utm_content=${encodeURIComponent(content)}` : "";
    url = `${url}?utm_source=${hostname}&utm_medium=Share&utm_campaign=Telegram&utm_term=${current_article_id ? current_article_id : window.articleId}${utmContent}`;
    url = hashPart ? url + "#" + hashPart : url;
    const urlEncoded = encodeURIComponent(url)
    window.open("https://telegram.me/share/url?url=" + urlEncoded + "&text=" + encodeURIComponent(document.title) + "&url=" + urlEncoded, "", widthAndHeight);
    analyticsCb && typeof analyticsCb === "function" && analyticsCb()
}

export const openWhatsAppShare = (url: string, analyticsCb?: () => void, content: string = "", current_article_id?: string, hashPart?: string) => {
    const hostname = window.location.hostname.replace("www.", "");
    const utmContent = content.trim() ? `&utm_content=${encodeURIComponent(content)}` : "";
    url = `${url}?utm_source=${hostname}&utm_medium=Share&utm_campaign=Whatsapp&utm_term=${current_article_id ? current_article_id : window.articleId}${utmContent}`;
    url = hashPart ? url + "#" + hashPart : url;
    window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(url))
    analyticsCb && typeof analyticsCb === "function" && analyticsCb()
}
interface DateParts {
    month: number
    day: number
    year: number
    hour: number
    minute: number
    second: number
}

export const analyticsPushFunc = (action: string, category: string, title?:string) => {
    window.dataLayer = window.dataLayer || []
    const eventText = category == "Send Comment" ? "comments_events" : "GA_Events"
    let categoryText = category == "Send Comment" ? "Article" : (category == "News Flash Tab" ? category : "Share")
    window.dataLayer.push({
        event: eventText,
        Category: categoryText, 
        Action:  action,
        Label: title ? title : category,
    });
}

const getDateParts = (date: Date, timezone: string): DateParts => {
    if (RUNNING_IN_SERVER) {
        return getDatePartsUsingMoment(date, timezone);
    } else {
        return getDatePartsUsingIntl(date, timezone);
    }
}
const getDatePartsUsingIntl = (date: Date, timezone: string): DateParts => {
    const intel = new Intl.DateTimeFormat("en-us", { timeZone: timezone, hour12: false, year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }) as any
    const partsArray = intel.formatToParts(date)
    const parts = {} as any;
    for (const part of partsArray) {
        switch (part.type) {
            case "month":
            case "day":
            case "year":
            case "hour":
            case "minute":
            case "second":
                parts[part.type] = Number(part.value);
                break;
        }
    }
    return parts;
}

const getDatePartsUsingMoment = (date: Date, timezone: string): DateParts => {
    const momentTZ = RUNNING_IN_SERVER && require('moment-timezone');
    const dateToManipulateTemp = momentTZ.tz(date, timezone);
    return {
        day: dateToManipulateTemp.date(),
        month: dateToManipulateTemp.month() + 1, //January is 0
        year: dateToManipulateTemp.year(),
        hour: dateToManipulateTemp.hour(),
        minute: dateToManipulateTemp.minute(),
        second: dateToManipulateTemp.second(),
    }
}

const temp_getDatePartWithoutIntelSupport = (dateToManipulateTemp: Date, _timezone: string): DateParts => {
    return {
        day: dateToManipulateTemp.getDate(),
        month: dateToManipulateTemp.getMonth() + 1, //January is 0
        year: dateToManipulateTemp.getFullYear(),
        hour: dateToManipulateTemp.getHours(),
        minute: dateToManipulateTemp.getMinutes(),
        second: 0,
    }
}
export const googleScript = (name) => {return `if (window.innerWidth >= '768') { googletag.cmd.push(function() { googletag.display("` + name + `"); }); }`}
export const timeAgo = (date: Date, lang: string) => {
    const startTime = date;
    const endTime = new Date();
    const difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    const resultInMinutes = Math.floor(difference / 60000);
    const finalResult = resultInMinutes < 2 ? "עכשיו" : format(date, lang)
    return finalResult
}

export interface ITitleLink extends DataObjectBase {
    readonly channelIconDisplay?: ChannelIconDisplay
    readonly channelIconUrl?: string
    rightTitleLink?: LinkProperties<BaseLinkTypes>
    tabLink?: LinkProperties<BaseLinkTypes>
}
export const channelIconUrl = (data: ITitleLink, getPageCategory: (categoryId: string) => CategoryTreeNode) => {
    let icon = null;
    if (data.channelIconDisplay === "manual") {
        icon = data.channelIconUrl;
    }
    else if (data.channelIconDisplay === "category") {
        let category;
        if (data.rightTitleLink) {
            category = getPageCategory(data.rightTitleLink.link.id)
        } else {
            category = getPageCategory(data.tabLink.link.id)
        }
        icon = category && category.icon ? category.icon : null;

    }

    return icon
}
export const replaceCharacters = (str: string) => {
    const obj = { '&quot;': `"`, '&lt;': `<`, '&gt;': `>`, '&amp;': `&`, '<\/?span[^>]*>': ``, '&#39;': `'` }
    for (var x in obj) {
        str = str.replace(new RegExp(x, 'g'), obj[x]);
    }
    return str;
}