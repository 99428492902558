import { StringEnum } from "../utils/stringEnum"

export const ContentTypes = StringEnum(
    "ARTICLE",
    "CATEGORY",
    "MEDIA_IMAGE",
    "MEDIA_VIDEO",
    "MEDIA_LIVE",
    "MEDIA_IFRAME",
    "EXTERNAL_URL",
    "TAG",
    "NONE",
    "COMMERCIAL",
    "FILE",
    "MAIL",
    "STOCK",
);

export type ContentTypes = StringEnum<typeof ContentTypes>;

export const MediaContentTypes = StringEnum(
    ContentTypes.MEDIA_IMAGE,
    ContentTypes.MEDIA_VIDEO,
    ContentTypes.FILE,
    ContentTypes.MEDIA_LIVE,
    ContentTypes.MEDIA_IFRAME
);
export type MediaContentTypes = StringEnum<typeof MediaContentTypes>;