import { City } from '../WeatherMapExtendedTab/WeatherMapExtendedTab'

interface MapProps {
    citiesList: Array<City>
    isNewLayout: boolean
}


const WeatherPrecipitationMap = (props: MapProps) => {
    const { citiesList, isNewLayout } = props
    const chosenCities = ["Elat", "Ashdod", "Beer Sheva", "Bet Shean", "Haifa", "Tiberias", "Jerusalem", "Mizpe Ramon", "Zefat", "Tel Aviv Yafo"]
    const newPrepIcon = `/images/weather-new-icons/weatherMapIcons/precipation_icon.svg`
    const oldPrepIcon = `/images/weather/waterDrop.png`

    let cities = citiesList && citiesList.map((city: City) => {
        if (city && city.name && (chosenCities as any).includes(city.name)) {
            return (
                <div key={city.name} className={`city-item ${city.name.toLowerCase().replace(/ /g, "-")}`}>
                    <div>
                        <img src={!!isNewLayout ? newPrepIcon : oldPrepIcon} className="weather-drop-img" />
                    </div>
                    <div className="info-title">
                        <span className="temp-info">{`${city.precipitation} `}</span><span className="preci-text">מ"מ</span>
                        <br />
                        <span className="city-title">{city.nameHeb}</span>
                    </div>
                </div>
            )
        }
        return null
    })

    return (
        <div className="weather-extended-map-module" role="presentation">
            <div className="map-container">
                {cities}
            </div>
        </div>
    )
}

export default WeatherPrecipitationMap