require("./m_ynet_tickerAutoRowView.less")
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { TickerViewData } from "../../../tickerAuto/interfaces"
import { Accordion } from "../../../../siteWidgets/accordion/accordion";

interface TickerAutoRowViewProps {
    data: TickerViewData
    articles: Array<any>
    isMobileWeb?: boolean
    isEnglish: boolean
    languageCode:string
    hasArticleLink: boolean
}

export class TickerAutoRowViewMobileWeb extends React.Component<TickerAutoRowViewProps, {}>  {

    public render() {
        const { data, articles, isMobileWeb, isEnglish, languageCode, hasArticleLink } = this.props

        let visibleItems = [];

        if (articles.length > 0) {
            for (var i = 0; i < 3; i++) {
                if (typeof articles[i] !== 'undefined') {
                    const item = {
                        articleId: articles[i].articleId,
                        date: articles[i].dateUpdatedOnSite,
                        text: articles[i].plainText,
                        title: articles[i].title,
                        shareUrl: articles[i].publishedLink,
                        author:articles[i].author,
                    }
                    visibleItems.push(item)
                }
            }
        }

        return (
            <div className={`TickerComponentaMobileWeb ${isEnglish ? "ltr" : ""}`}>
                <div className="mobileHeader" style={{ backgroundColor: data.tabBackgroundColor }}>
                {isEnglish  ? data.tabTitleText : "מבזקים"}
                </div>

                <div className="slotsContent">
                    <Accordion items={visibleItems} isMobileWeb={isMobileWeb} languageCode={languageCode} hasArticleLink={hasArticleLink}/>
                </div>

                <HyperLinkBuilder href={data.tabHref} linkTarget={data.tabTitleLinkTarget}>
                    <div className="moreTickersLink">
                        {data.tabTitleText}
                    </div>
                </HyperLinkBuilder>
            </div>
        )
    }
}