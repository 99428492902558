require("./calcalist_site_commertialComponenta.less")
import Slider from 'react-slick'
import { SiteMediaData } from "widgets/interfaces"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteMedia } from '../../../../siteWidgets/siteMedia';
import { actionsOnArticles } from "../../../../../config/apiUrls"
import * as classNames from "classnames"
import * as map from "lodash/map"

export interface CommertialItemData {
    media: SiteMediaData
    title: string
    subTitle: string
    link: string
    targetLink: LinkTargetProperties
}

interface commertialState {
    activeSlide
}

interface commertialProps {
    tabTitle: string
    itemList: CommertialItemData[]
    tabColor: string
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    titleColor: string
    subTitleColor: string
    gallerySeconds: number
}



export class CommertialFrontComponenta extends React.Component<commertialProps, commertialState>{
    constructor(props: commertialProps) {
        super(props);
        this.state = {
            activeSlide: 0
        }

    }
    private slider;
    //private throttledMouseEnterHandler = (i) => throttle(this.setState({ activeSlide: i }), 10)
    private previous = () => {
        this.slider.slickPrev();
    }
    private next = () => {
        this.slider.slickNext();

    }

    private goToSlide = (i) => {
        this.slider.slickGoTo(i);
        this.setState({ activeSlide: i })
    }
    private componentaHeight = (numOfItems: number) => {
        switch (numOfItems) {
            case 3: return "277px"
            case 6: return "311px"
            case 9: return "345px"
            case 12: return "379px"

        }
    }
    public static siteScriptName = "CommertialFrontComponenta";

    public render() {
        const { itemList, tabLink, tabLinkTarget, tabColor, tabTitle, titleColor, subTitleColor, gallerySeconds } = this.props
        const { activeSlide } = this.state


        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            autoplaySpeed: gallerySeconds * 1000,

            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
        return (
            <div className={`CommertialComponenta`} style={{ height: this.componentaHeight(itemList.length) }}>
                <div className="TabComponenta" >
                    <HyperLinkBuilder className="titleLink mainTitleLink" href={tabLink} linkTarget={tabLinkTarget} style={{ color: tabColor }}><div className="mainTitleText" >{tabTitle}</div></HyperLinkBuilder>
                </div>
                <div className="commertialSlider" >
                    <div className="sliderList">
                        {map(itemList, (item, i) =>
                            <button className={classNames("itemTitle", { "activeSlide": i === activeSlide })} key={i} onMouseEnter={() => this.goToSlide(i)}>
                                <HyperLinkBuilder href={item.link} linkTarget={item.targetLink} style={{ color: titleColor }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                </HyperLinkBuilder>
                            </button>
                        )}

                    </div>
                    <Slider ref={slider => (this.slider = slider)} {...settings} className="slider"  >
                        {map(itemList, (item, index) =>
                            <div className="slotView" key={index}>
                                <div className="imgArea">
                                    <SiteMedia
                                        isImageLinkable={true}
                                        itemLinkUrl={item.link}
                                        linkTarget={item.targetLink}
                                        data={item.media}
                                        videoSettings={null}
                                        key={item.media.url} width={280} height={160}
                                    />
                                </div>
                                <HyperLinkBuilder href={item.link} linkTarget={item.targetLink} className="subTitle" style={{ color: subTitleColor }}>
                                    <div dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                                </HyperLinkBuilder>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        )
    }
}

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slickArrow slickPrev"
            onClick={onClick}
        />
    );
}

const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slickArrow slickNext"
            onClick={onClick}
        />
    );
}
