import { ArticleCommentsTopTabRenderProps } from "../articleCommentsTopTabWrapper"
import { getTalkbackNumberView } from "../../articleSocialShare1280Component/articleSocialShareNew1280";

interface ArticleCommentsTopTabUI1280rops {
    props: ArticleCommentsTopTabRenderProps
}

export class ArticleCommentsTopTabAppUI extends React.Component<ArticleCommentsTopTabUI1280rops, {}>{


    render() {
        const { numOfComments } = this.props.props
        return (
            <div className="ArticleCommentsTopTabAppUI-pf">

                <div className="tabTitle">
                    <span className="tbIcon-pf">{getTalkbackNumberView(numOfComments.toString())}</span>
                    <span className="com-pf">תגובות</span></div>
                <div className="tabPanel">
                    <a
                        key="newCommentBtn"
                        className="newCommentBtn-pf"
                        title="הוספת תגובה"
                        href="add_new_comment"
                    >
                        <span>הוספת תגובה</span>
                    </a>
                </div>
            </div >
        )
    }
}