import { VideoSettingsData, LinkedItemType, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { SiteItemData, SiteMediaData, SiteImageData } from "widgets/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { isHavakVisible, PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak"
import { MediaContentTypes } from "constants/contentTypes"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"



interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    item?: SiteItemData
    videoSettings?: VideoSettingsData
    isLabelVisible: boolean
    marginLeft?: number
    isSmallItem?: boolean
    isAuthorVisible?: boolean
    displayType: any
}



export class YplusItemFront extends React.Component<MultiImagesFrontProps>{
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }
    public render() {
        const { imageWidth, item, imageHeight, videoSettings, marginLeft, isSmallItem, isLabelVisible, isAuthorVisible, displayType } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const slotViewStyle = { marginLeft: marginLeft }

        const icon = item.icon ? item.icon : "automatic"
     //   const desktopOnly = forDesktopOnly && displayType !== "gallery" ? "desktopOnly" : ""
        return (
            <div className={`slotView`} style={slotViewStyle}>

                <div className="imageArea">
                    <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                        <SiteMedia
                            data={item.media !== undefined ? item.media : emptyMedia}
                            width={imageWidth}
                            height={imageHeight}
                            videoSettings={videoSettings}
                            itemLinkUrl={item.titleLink}
                            isImageLinkable={true}
                            linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        />
                        <SlotIconDisplay itemId={item.itemId} icon={this.getIcon(icon, item.hasVideo)} iconClassName="medium" isIconVisible={item.hasVideo || icon === "video" || icon === "automatic"} />
                        {isLabelVisible && <div className="mediaLabel" >
                            {item.labelObj && <SiteSimpleImage src={item.labelObj.labelUrl} title={item.labelObj.labelName} width={"auto"} height={24} />}
                        </div>}
                    </div>

                </div>

                <HyperLinkBuilder
                    href={item.titleLink}
                    linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                    children={item.title}
                    className="slotTextArea" >


                    <div className={`slotTitle`} dangerouslySetInnerHTML={{ __html: item.title }} />


                    <div className={`slotSubTitle`} dangerouslySetInnerHTML={{ __html: item.subTitle }} />

                    {isAuthorVisible && <div className="moreDetails">
                        <span className="authorField">{item.author}</span>

                    </div>}

                </HyperLinkBuilder>
            </div>
        )
    }
}
