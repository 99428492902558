export const stockLinkInlineInfo = (stockData) => {

    const graphImg = require('./images/graph.png')

    const stockTrend = stockData.dailyChgPercent > 0 ? {
        src: require('./images/green_arrow.png'),
        arrowClassName: "si-info-img-up",
        percentageClassName: "si-info-positive"

    } :

        {
            src: require('./images/red_arrow.png'),
            arrowClassName: "si-info-img-down",
            percentageClassName: "si-info-negative"
        }

    return `<span class="si-brk-open">(</span><img class="si-info-img" src=${graphImg} /><span class="si-value">${stockData.lastShaar}</span><img class=${stockTrend.arrowClassName} src=${stockTrend.src} /><span class='si-value-percent ${stockTrend.percentageClassName}'}>${stockData.dailyChgPercent.replace('-', '')}%</span><span class="si-brk-close">)</span>`
}