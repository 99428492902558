import * as classNames from "classnames"
import * as map from "lodash/map"
import { menuItems } from "./mobileMenu"
var Cookies = require('js-cookie');
require('./ynetnews_vesty_site_ynetNewsHeader.less')


interface MobileMenuProps {
    isOpened: boolean
    items: menuItems[]
}
export class EsMobileMenuComponenta extends React.Component<MobileMenuProps, {}> {

    render() {
        const { isOpened, items } = this.props

        let menuClassName = classNames("MobileMenu", { "opened": isOpened === true, "closed": isOpened === false })
        const dataItems = items ? items : [{ name: "", link: "", color: "" }]
        return (
            <div className={menuClassName} >
                <div className="head">
                    <span className="cp-big-h1">Canales</span>
                </div>
                <ul>     {map(dataItems, (item, i) => <li key={i}>
                    <div className="color"></div>
                    <a href={item.link}>
                        <div className="icon" style={{ color: item.color }}>{item.name} </div>
                    </a></li>

                )}
                </ul>

            </div>
        )

    }
}