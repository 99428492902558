import { RadionasNewsFlashHomepageViewData } from "../utils";
import { RadionasFlashItem } from "./radionasFlashItem";
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";

interface RadionasNewsFlashItemsListProps {
    data: RadionasNewsFlashHomepageViewData
    articles: ArticleExtraData[]
    isMobileWeb: boolean
    audioDomain: string
    listHeight: number
}

export const RadionasNewsFlashItemsList = (props: RadionasNewsFlashItemsListProps) => {
    const { data, articles, isMobileWeb, audioDomain, listHeight } = props
    const { numberOfVisibleItemsDesktop, numberOfVisibleItemsMobile, authorCaptionColor, dateCaptionColor, newsflashCaptionColor, componentBackgroundColor } = data
    const numberOfItemsToShow = isMobileWeb ? numberOfVisibleItemsMobile : numberOfVisibleItemsDesktop
    const styles = isMobileWeb ? { backgroundColor: data.componentBackgroundColor } : { backgroundColor: data.componentBackgroundColor, height: listHeight }

    return (
        <div className="radionas-newsflash-items-list" style={styles}>
            {articles && articles.length > 0 ?
                articles.slice(0, numberOfItemsToShow).map(article =>
                    <RadionasFlashItem
                        article={article}
                        key={article.articleId}
                        isMobileWeb={isMobileWeb}
                        audioDomain={audioDomain}
                        authorCaptionColor={authorCaptionColor}
                        dateCaptionColor={dateCaptionColor}
                        newsflashCaptionColor={newsflashCaptionColor}
                        componentBackgroundColor={componentBackgroundColor}
                    />
                )
                :
                <div className="no-newsflash-alert">لا توجد ومضات للعرض</div>
            }
        </div>
    )
}