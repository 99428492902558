require("./ynet_pplus_magazine_site_signupForPodcast.less")

interface SignUpForPodcastProps {
    categoryId: string
}
interface PodcastShareLInks {
    google: string
    apple: string
    spotify: string
    youtube:string
}

declare let window: Window & {
    podcastsShareLinksList: any
}

export const SignUpForPodcast = (props: SignUpForPodcastProps) => {
    const { categoryId } = props
    const podcastsShareLinksList = typeof window !== "undefined" && window.podcastsShareLinksList;
    const categoryShareLinks = podcastsShareLinksList && podcastsShareLinksList[categoryId] ? podcastsShareLinksList[categoryId] as PodcastShareLInks : null;

    return (
        <div className="signup-for-podcast" style={!categoryShareLinks ? { display: 'none' } : {}}>
            <div className="signup-for-podcast-title-rpf">עקבו אחר הפודקאסט</div>
            <div className="signup-for-podcast-buttons">
                <a className="spotify-signup-btn podcast-signup-btn" href={categoryShareLinks ? categoryShareLinks.spotify : ""} target="_blank" />
                <a className="apple-podcasts-signup-btn podcast-signup-btn" href={categoryShareLinks ? categoryShareLinks.apple : ""} target="_blank" />
               { categoryShareLinks && categoryShareLinks.youtube ? 
               <a className="youtube-podcasts-signup-btn podcast-signup-btn" href={categoryShareLinks.youtube} target="_blank" /> 
               : <a className="youtube-podcasts-signup-btn podcast-signup-btn" style={{cursor:"inherit"}}  />}
            </div>
        </div>
    )
}

SignUpForPodcast.siteScriptName = "SignUpForPodcast"