require("./calcalist_site_newsLetterNativeComponenta.less")
import { CalcalistNewsLetterNativeItem } from "./calcalistNewsLetterNativeItem";
import { CalcalistNewsLetterNativeRegisterPopup } from "./calcalistNewsLetterNativeRegisterPopup"
import { isWcmEditor } from "../../../../../config/serverConfig"


enum AppStates {
    NewsLetterScreen, RegisterScreen
}

export  interface CalcalistNewsLetterNativeComponentItem {
    slotId: string
    imgUrl: string
    title: string
    subTitle: string
    frequency: string
    groupId: string
    mailingListId:string
}

interface CalcalistNewsLetterNativeComponentaProps {
    newsLetterItems: CalcalistNewsLetterNativeComponentItem[]
    componentaId: string
    generalGroupId:string
}

interface CalcalistNewsLetterNativeComponentState {
    selectedItems: { [key: string]: CalcalistNewsLetterNativeComponentItem };
    state: AppStates
    bodyOverflow:string
}

export class CalcalistNewsLetterNativeComponenta extends React.Component<CalcalistNewsLetterNativeComponentaProps, CalcalistNewsLetterNativeComponentState>{

    public static siteScriptName = "CalcalistNewsLetterNativeComponenta";

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: {},
            state: AppStates.NewsLetterScreen,
            bodyOverflow:''
        };

    }

    componentDidMount() {
        this.setState({ bodyOverflow: document.body.style.overflow })
    }


    handleNewsletterItem = (selectedItem : CalcalistNewsLetterNativeComponentItem) => {
        this.setState((prevState: CalcalistNewsLetterNativeComponentState): Partial<CalcalistNewsLetterNativeComponentState> => {
            const { selectedItems } = prevState;
            let updatedSelectedItems = null;
            if (_.has(selectedItems,[selectedItem.slotId])){
                 updatedSelectedItems = _.omit(selectedItems, [selectedItem.slotId]); 
            }else{
                updatedSelectedItems = {...selectedItems,[selectedItem.slotId]:selectedItem}
            }
            return {
              selectedItems: updatedSelectedItems,
            };
          });
    }

    handleOnRegisterBtn = () => {
        this.setState({ state: AppStates.RegisterScreen })
        document.body.style.overflow = 'hidden'; 

    }


    handleCloseRegisterPopup =()=>{
        this.setState({ state: AppStates.NewsLetterScreen })
        document.body.style.overflow = this.state.bodyOverflow; 
    }


    public render() {
        const { newsLetterItems, componentaId ,generalGroupId } = this.props;
        const { selectedItems, state } = this.state;

        return (
                    <div className="CalcalistNewsLetterNativeComponenta">
                        <div className="CalcalistNewsLetterNativeHeader">
                            <div className="headerImage"></div>
                            <div className="separator"></div>
                            <div className="headerTitle no-small-vp">סמנו את הניוזלטרים שמעניינים אתכם, הירשמו ותקבלו אותם ישירות לתיבת המייל שלכם</div>
                            {!isWcmEditor() && <div className="headerTitle show-small-vp">סמנו והירשמו לכל הניוזלטרים שמעניינים אתכם</div>}
                        </div>
                        <div className="itemsList">
                            {_.map(newsLetterItems, item => <CalcalistNewsLetterNativeItem isChecked={_.has(selectedItems,[item.slotId])} onChange={() => this.handleNewsletterItem(item)} key={item.slotId} {...item} />)}
                        </div>
                        <button disabled={isWcmEditor() || !_.keys(selectedItems).length} type="submit" onClick={this.handleOnRegisterBtn} className="registerButton">הרשמה</button>
                        {state === AppStates.RegisterScreen && <CalcalistNewsLetterNativeRegisterPopup generalGroupId={generalGroupId} componentaId={componentaId} selectedItems={selectedItems} onClose={this.handleCloseRegisterPopup} />}
                    </div>        
        )
    }
}
