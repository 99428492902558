import { HOCDecorator } from "../globalDefinitions";
import { PropTypes } from "prop-types"
import { WebPlatform } from "../../front/staticServerRendering/renderMobileArticle";

interface categoryObj {
    id: string
    categoryColor: string
    name:string
}
export interface SiteSettings {
    isLTR?: boolean
    fbAppId?: string
    cutForPremium?: boolean
    category?: categoryObj
    imagesDomain?: string,
    is_new_gpt?: boolean,
    ads_infrastructure_type?: string,
    ads_mutam_infrastructure_type?: string,
    hideAdsAutoSpot17?: boolean,
    showAdsSpot17?: boolean,
    spotImDate?: string | boolean,
    rootCss?: string
    url?: string
    sitename?: string
    platform?: WebPlatform
    languageCode?: string
    orderedImages?: string[]
    spotimTalkbackCount?: string
    domain?: string
    currentEntityId?: string
    version?: number
    setTopStoryDisplayed?: () => void,
    getTopStoryDisplayed?: () => boolean,
    isArticlePage?: boolean,
    audioDomain?: string
    articleBodyAdsConfig?: any
    font?:string
}

export type SetSiteSettingsContextTypes = { [K in keyof SiteSettings]: __React.Requireable<any> }

export const SiteSettingsHOC = (inserAsObject?: boolean): HOCDecorator => ComposedComponent => {
    return class extends React.Component<any, any>{
        context: SiteSettings;
        public static contextTypes: SetSiteSettingsContextTypes = {
            isLTR: PropTypes.bool,
            fbAppId: PropTypes.string,
            cutForPremium: PropTypes.bool,
            category: PropTypes.object,
            imagesDomain: PropTypes.string,
            is_new_gpt: PropTypes.bool,
            ads_infrastructure_type: PropTypes.string,
            ads_mutam_infrastructure_type: PropTypes.string,
            url: PropTypes.string,
            sitename: PropTypes.string,
            hideAdsAutoSpot17: PropTypes.bool,
            spotImDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            showAdsSpot17: PropTypes.bool,
            platform: PropTypes.string,
            languageCode: PropTypes.string,
            orderedImages: PropTypes.array,
            spotimTalkbackCount: PropTypes.string,
            domain: PropTypes.string,
            currentEntityId: PropTypes.string,
            version: PropTypes.number,
            setTopStoryDisplayed: PropTypes.func,
            getTopStoryDisplayed: PropTypes.func,
            isArticlePage: PropTypes.bool,
            audioDomain: PropTypes.string,
            articleBodyAdsConfig: PropTypes.object
        }

        public render() {
            const siteSettings: SiteSettings = (IS_WCM_EDITOR && { isLTR: WCM_EDITOR_LTR }) || this.context;
            if (inserAsObject) {
                return <ComposedComponent {...this.props} siteSettings={siteSettings} />;
            } else {
                return <ComposedComponent {...this.props} {...siteSettings} />;
            }
        }
    }
}

export class SetSiteSettingsContext extends React.Component<SiteSettings, {}>{
    private isTopStoryDisplayed = false;

    private setTopStoryDisplayed = () => {
        if (!this.isTopStoryDisplayed) {
            this.isTopStoryDisplayed = true;
        }
    }

    private getTopStoryDisplayed = () => this.isTopStoryDisplayed;

    public static childContextTypes: SetSiteSettingsContextTypes = {
        isLTR: PropTypes.bool,
        fbAppId: PropTypes.string,
        cutForPremium: PropTypes.bool,
        category: PropTypes.object,
        imagesDomain: PropTypes.string,
        is_new_gpt: PropTypes.bool,
        ads_infrastructure_type: PropTypes.string,
        ads_mutam_infrastructure_type: PropTypes.string,
        url: PropTypes.string,
        sitename: PropTypes.string,
        hideAdsAutoSpot17: PropTypes.bool,
        spotImDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        showAdsSpot17: PropTypes.bool,
        platform: PropTypes.string,
        languageCode: PropTypes.string,
        orderedImages: PropTypes.array,
        spotimTalkbackCount: PropTypes.string,
        domain: PropTypes.string,
        currentEntityId: PropTypes.string,
        version: PropTypes.number,
        setTopStoryDisplayed: PropTypes.func,
        getTopStoryDisplayed: PropTypes.func,
        isArticlePage: PropTypes.bool,
        audioDomain: PropTypes.string,
        articleBodyAdsConfig: PropTypes.object,
        font:PropTypes.string

    }

    public getChildContext(): SiteSettings {
        const { languageCode, isLTR, fbAppId, cutForPremium, category, imagesDomain, is_new_gpt, ads_infrastructure_type, ads_mutam_infrastructure_type, url, sitename, spotImDate,
            hideAdsAutoSpot17, showAdsSpot17, platform, orderedImages, spotimTalkbackCount, domain, currentEntityId, version,
            isArticlePage, audioDomain, articleBodyAdsConfig, font } = this.props;
        return {
            isLTR,
            fbAppId,
            cutForPremium,
            category,
            imagesDomain,
            is_new_gpt,
            ads_infrastructure_type,
            ads_mutam_infrastructure_type,
            url,
            sitename,
            spotImDate,
            hideAdsAutoSpot17,
            showAdsSpot17,
            platform,
            languageCode,
            orderedImages,
            spotimTalkbackCount,
            domain,
            currentEntityId,
            version,
            setTopStoryDisplayed: this.setTopStoryDisplayed,
            getTopStoryDisplayed: this.getTopStoryDisplayed,
            isArticlePage,
            audioDomain,
            articleBodyAdsConfig,
            font
        }
    }

    public render() {
        return (
            <div id="site_container" className={this.props.font}>{this.props.children}</div>
        )
    }
}
