import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { CSSTransitionGroup } from "react-transition-group"
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"
import { SiteItemData } from "widgets/interfaces"
import { GalleryViewType } from "widgets/commonComponents/base/basicMediaCarousel"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { MultiImagesScrollPerItem } from "../../../../commonComponents/base/multiImagesScrollPerItem"

interface PlainGalleryProps {
    medias: SiteItemData[]
    width: number
    galleryViewOption: GalleryViewType
    videoSettings: VideoSettingsData
    intervalTime: number
    fieldsDisplaySettings: LinkedItemFieldDisplay
}

interface PlainGalleryState {
    selectedMediaIndex: number,
    videoPlayStarted: boolean
}

export class Gallery1280 extends React.Component<PlainGalleryProps, PlainGalleryState>{
    constructor(props) {
        super(props as PlainGalleryProps);
        this.state = {
            selectedMediaIndex: 0,
            videoPlayStarted: false
        }
    }


    private autoGalleryInterval: any;
    private startAutoGallery = () => {
        const { intervalTime, medias } = this.props
        const self = this;
        if (typeof (intervalTime) === "number") {
            if (intervalTime > 0) {
                this.autoGalleryInterval = setInterval(() => {
                    this.setState((prevState: PlainGalleryState, props: PlainGalleryProps) => {
                        let newIndex = ++prevState.selectedMediaIndex;
                        if (newIndex >= props.medias.length) newIndex = 0;
                        return { selectedMediaIndex: newIndex }
                    });
                }, intervalTime * 1000);
            }
            else {
                clearInterval(this.autoGalleryInterval)
            }
        }
    }
    componentDidUpdate(_, prevState: PlainGalleryState) {
        if (this.state.selectedMediaIndex !== prevState.selectedMediaIndex) {
            window.YitVideo && window.YitVideo.InfrastructureReInitialize();
        }
    }

    componentDidMount() {
        if (this.props.medias.length > 1) {
            this.startAutoGallery();
        }
        window.YitVideo && window.YitVideo.InfrastructureReInitialize();

    }
    public static siteScriptName = "Gallery1280";
    private single_List_item_width = 283;



    private showPrevMedia = (medias) => {
        this.setState(state => state.selectedMediaIndex > 0 ? { selectedMediaIndex: state.selectedMediaIndex - 1 } : { selectedMediaIndex: medias.length - 1 })
        this.stopAutoGallery();
    }
    private showNextMedia = (medias) => {
        this.setState(state => state.selectedMediaIndex < medias.length - 1 ? { selectedMediaIndex: state.selectedMediaIndex + 1 } : { selectedMediaIndex: 0 })
        this.stopAutoGallery();
    }
    private stopAutoGallery = () => {
        if (this.props.intervalTime !== 0) {
            clearInterval(this.autoGalleryInterval)
        }

    }
    private onSelectMedia = (e, index: number) => {
        this.stopAutoGallery();
        const isVideo = this.props.medias[this.state.selectedMediaIndex].media.mediaType === "MEDIA_VIDEO"
        const videoPlayStarted = this.state.selectedMediaIndex === index && isVideo
        this.setState({ selectedMediaIndex: index, videoPlayStarted: false })
    }

    private handleOnVideoPlayStarted = () => {
        this.setState({ videoPlayStarted: true })
    }
    private handleOnVideoPlayStopped = () => {
        this.setState({ videoPlayStarted: false })
    }


    render() {
        const { medias, width, galleryViewOption, videoSettings, fieldsDisplaySettings } = this.props
        const { selectedMediaIndex, videoPlayStarted } = this.state
        const { isTitleLinkVisible } = fieldsDisplaySettings
        const noTitleLink = !medias[selectedMediaIndex].titleLink
        const mediaHeight = Math.floor(width / 1.77)

        if (medias.length === 0) { return null }
        const selectedDetailsStyle = { zIndex: 1 }
        const selectedMediaItem = medias[selectedMediaIndex]

        return (
            <span>
                <div className="gallerySelectedMedia">

                    {selectedMediaItem && (videoPlayStarted === false || null) &&
                        <div className="selectedMediaDetails" style={selectedDetailsStyle}>
                            {!noTitleLink && isTitleLinkVisible ?
                                <HyperLinkBuilder
                                    href={selectedMediaItem.titleLink}
                                    linkTarget={selectedMediaItem.titleTargetLink}
                                    children={selectedMediaItem.title} >
                                <div className="title"> <div dangerouslySetInnerHTML={{ __html: selectedMediaItem.title }} /></div>
                                </HyperLinkBuilder> :
                                <div className="title" dangerouslySetInnerHTML={{ __html: selectedMediaItem.title }} />}
                             <div className="credit">{selectedMediaItem.media.credit} </div>
                        </div>}

                    <div className="galleryDroppedImage" style={{ height: mediaHeight }}>
                        {galleryViewOption === "arrows" && <button className="scrollRightBtn galleryArrow" title="scroll right"
                            aria-label="scroll right"
                            onClick={() => this.showPrevMedia(medias)} />}
                        <CSSTransitionGroup transitionName="media" transitionEnterTimeout={500} transitionLeave={false} >
                            <SiteMedia
                                onPlayStarted={this.handleOnVideoPlayStarted}
                                onPlayStopped={this.handleOnVideoPlayStopped}
                                data={selectedMediaItem.media} videoSettings={videoSettings} width={width} height={mediaHeight} onSelect={this.stopAutoGallery} />
                        </CSSTransitionGroup >
                        {galleryViewOption === "arrows" && <button className="scrollLeftBtn galleryArrow" title="scroll left" aria-label="scroll left" onClick={() => this.showNextMedia(medias)} />}
                    </div>
                </div>

                {galleryViewOption === "bar" && <div className="mediaListContainer">
                    <MultiImagesScrollPerItem imageWidth={this.single_List_item_width} width={width} itemLength={medias.length} marginRight={8}>
                        {medias.map((item, index) =>
                            <div
                                key={`galleryItem_${item.media.mediaId}`}
                                onClick={(e) => this.onSelectMedia(e, index)}
                                style={{ marginLeft: "8px" }}
                                className="mediaWrapper">
                                {item.media.mediaType === "MEDIA_VIDEO" && <img className="lisPreviewImage" src={item.media.poster} />}
                                {item.media.mediaType === "MEDIA_IMAGE" && <img className="lisPreviewImage" title={item.media.title} aria-hidden={item.media.ariaHidden} alt={item.media.alt} src={item.media.url} />}
                            </div>)}
                    </MultiImagesScrollPerItem>
                </div>}

            </span>
        )
    }
}
