import { SiteArticleBlogsReadMoreHandler } from "./siteArticleBlogsReadMoreHandler";
import { RenderReactAtSite } from "../../../widgets/commonComponents/inline/renderReactAtSite";


export class ArticleBlogsReadMoreHandler extends React.Component<{}, {}> {
    public static siteScriptName = "ArticleBlogsReadMoreHandler"
    public render() {
        return (
           <SiteArticleBlogsReadMoreHandler />
        )
    }
}