import * as axios from "axios"
import { addAxiosDateTransformerSettings } from "../../../../../../utils/network/axiosDateTransformer";
import { format, register } from 'timeago.js'
import { hebrewTimeAgoTranslate, russianTimeAgoTranslate } from "../../../../../siteWidgets/siteWidgetUtils";
import { ArticleHotUpdateItem } from "./articleHotUpdateItem";

interface ArticleHotUpdateComponentProps {
    hotUpdateId: string
    lang: string
    domain: string
}

interface ArticleHotUpdateComponentState {
    name: string
    updateTimeColor: string
    borderColor: string
    items: ArtilceHotUpdateItems[]
}

export interface ArtilceHotUpdateItems {
    title: string
    itemId: string
    publishLink: string
    createdAt: Date
}

export class ArticleHotUpdateComponent extends React.Component<ArticleHotUpdateComponentProps, ArticleHotUpdateComponentState>{
    public static siteScriptName = "SiteHotUpdateComponenta"
    constructor(props: ArticleHotUpdateComponentProps) {
        super(props)
        this.state = {
            name: "",
            updateTimeColor: "",
            borderColor: "",
            items: []
        }
    }

    componentDidMount() {
        const { hotUpdateId, lang } = this.props;
        if (hotUpdateId) {
            this.setLangTranslate(lang)
            this.setArticles(hotUpdateId)
            setInterval(() => {
                this.setArticles(hotUpdateId)
            }, 60000);
        }
    }

    setLangTranslate = (lang: string) => {
        switch (lang) {
            case "he":
                register(lang, hebrewTimeAgoTranslate);
                break;
            case "ru":
                register(lang, russianTimeAgoTranslate);
                break;
            default:
                //default lang is en
                break;
        }
    }

    private setArticles(hotUpdateId) {
        const { domain } = this.props
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`${domain}/iphone/json/api/hot_updates/${hotUpdateId}`)
            .then((res: any) => {
                const hotUpdateEntity = res.data as ArticleHotUpdateComponentState
                this.setState(hotUpdateEntity);
            })
            .catch(err => console.error("error geting hot update data:", err))
    }

    render() {
        const { items, name, updateTimeColor, borderColor } = this.state
        const { lang } = this.props

        const updates = !!items.length && _.map(items, i => {
            const startTime = i.createdAt;
            const endTime = new Date();
            const difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
            const resultInMinutes = Math.floor(difference / 60000);
            const finalResult = resultInMinutes < 2 ? "עכשיו" : format(i.createdAt, lang)
            return (
                <ArticleHotUpdateItem
                    {...i}
                    key={i.itemId}
                    updatedTime={finalResult}
                    updateTimeColor={updateTimeColor}
                />
            )
        })

        return (
            <div>
                {!!items.length &&
                    <div className="article-hot-updates">
                        <div className="hot-update-header" style={{ backgroundColor: borderColor }}>
                            <div className="hot-update-event-title">
                            <div className="small-clock-pf"></div>
                            <div>{name}</div>
                            </div>
                        </div>
                    <div className="hot-update-item-container">
                            {updates}
                        </div>
                    </div>}
            </div>
        )
    }
}