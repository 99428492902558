import { MobileMenuComponenta, menuItems } from "./mobileMenu"
import * as classNames from "classnames"


interface MobileMenuProps {
    isOpened: boolean
    items: menuItems[]
}

export class VestiMobileMenuComponenta extends React.Component<MobileMenuProps, {}> {



    render() {
        const { isOpened, items } = this.props
        let opened;

        let vestyMenuClassName = classNames("MobileMenu Vesty", { "opened": isOpened === true, "closed": isOpened === false })

        return (
            <div className={vestyMenuClassName} >
                <div className="head">
                    <span className="cp-big-h1">Меню</span>
                    <a id="INDmenu-btn3" className="follow"
                        accessKey="m" aria-labelledby="INDbtnTooltip" style={{ position: "absolute", top: "15px", left: "5%" }}>
                        <img src="//www.vesty.co.il/images/nagishot.png" style={{ width: "21px", height: "21px" }} />
                    </a>
                </div>
                < MobileMenuComponenta items={items} >
                    <li aria-label="Новости-молнии"><div className="color" />
                        <a href="/home/0,7340,L-13148,00.html">
                            <div className="icon">Новости-молнии
                             <img src="//www.vesty.co.il/images/ynetnewsresp/mivzakim_menu_item.png" className="menu-img-item menu-img-size" /></div>
                        </a>
                    </li>
                </MobileMenuComponenta>

            </div>
        )

    }
}