import * as classNames from "classnames"
import { SiteVideoData } from "../../../interfaces";
import { buildAdTag } from "../videoPlayerAds";
import { removeHtmlTagsFromString } from "../../../jsonSerializer/common/jsonHelperUtils";
require("./../../videoPlayer/site_videoPlayer.less")

interface VideoJsPlayerForInlineProps {
    data: SiteVideoData
    height?: number
    runArticleAutoPlayLogic?: boolean
    hideVideoAds?: boolean
    applyStickyness: boolean
}

export class YITVideoJsPlayerForInline extends React.Component<VideoJsPlayerForInlineProps, {}>{



    render() {
        const { data, height, runArticleAutoPlayLogic, hideVideoAds, applyStickyness } = this.props
        const {
            url,
            isLive,
            mediaId,
            poster,
            isAutoPlaying,
            isLoopPlaying: loop,
            isPlayerBarVisible: controls,
        } = this.props.data;

        const html =
            `<div
                ${height > 0 ? `height=${height}px` : ""}
                class="yitvideo_container"
                data-title=""
                style="background-image: url(${poster});background-repeat: no-repeat; background-size: cover;"
                data-yitvideo='${isLive ? "live" : mediaId}'
                data-stream='${isLive ? url : ""}'
                data-poster='${poster}' 
                ${runArticleAutoPlayLogic ? "" : `data-autoplay=${isAutoPlaying}`} 
                data-loop= ${loop}
                data-controls= ${controls}
                data-muted= ${data.isMuteAvailable}
                data-adTag= '${buildAdTag("Preroll", isAutoPlaying, true, "")}'
                data-ads = ${!hideVideoAds ? 1 : 0}
                >
                        <div class='loader'></div>
                </div>`

        return (
            <div dangerouslySetInnerHTML={{ __html: html }} />
        )
    }
}




