require('./site_galleryPaging.less')


interface galleryPagingProps {
    data: Array<any>
    onSelect: (index: number) => void
    selectedIndex: number
}


export class GalleryPaging extends React.Component<galleryPagingProps, {}> {
    public render() {
        const { data, onSelect, selectedIndex } = this.props;
        if (!data) return null;
        return (
            <div className="GalleryPaging">
                {data.map((m, i) => <button key={i} title={(i + 1).toString()} aria-label={(i + 1).toString()} className={selectedIndex === i ? "selected" : ""} onClick={() => onSelect(i)} >{i + 1}</button>)}
            </div>
        )
    }
}