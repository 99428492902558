import { IdGenerator } from "../../../../utils/idGenerator"
export const renderTaboolaThumbnails = () => {
    window._taboola = window._taboola || [];
    window._taboola.push({
        mode: 'alternating-thumbnails-a',
        container: 'taboola-below-main-column-thumbnails',
        placement: 'Below News Update Thumbnails',
        target_type: 'mix'
    });

    return (
        <div id='taboola-below-main-column-thumbnails' key={IdGenerator.generate()} className='trc_related_container trc_spotlight_widget trc_elastic trc_elastic_trc_50898 '></div>
    )
}