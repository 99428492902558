export default (weatherCode: number) => {
    const defaultCode = 1250
    const weatherCodeDesc = {
        1010: "Sandstorm",
        1020: "Thunderstorms",
        1060: "Snow",
        1070: "Light snow",
        1080: "Sleet",
        1140: "Rain",
        1160: "Drizzle",
        1220: "Partly cloudy",
        1230: "Cloudy",
        1250: "Clear",
        1260: "Windy",
        1270: "Frost",
        1300: "Freezing",
        1310: "Hot",
        1320: "Cold",
        1510: "Stormy",
        1520: "Heavy Snow",
        1530: "Partly cloudy, possible rain",
        1540: "Cloudy, possible rain",
        1560: "Cloudy, light rain",
        1570: "Haze",
        1580: "Extremely hot",
        1590: "Extremely cold",
    }

    if (weatherCode in weatherCodeDesc) {
        return weatherCode
    }
    return defaultCode
}
