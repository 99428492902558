import { getCityNameTranslation } from "../../../../ynetSearch/components/ynetWeather/cityNamesTranslator";
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import getWeatherCode from "../../../../weather/components/helpers/getWeatherCode";
import { WeatherData } from "../newYnetWeatherComponenta";
import * as axios from "axios"
var Cookies = require('js-cookie');


interface YnetWeatherState {
    selectedCityIndex: number
    weatherDataList: WeatherData[]
}

export class YnetWeatherMutam extends React.Component<{}, YnetWeatherState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedCityIndex: null,
            weatherDataList: null
        }
    }

    
    componentDidMount() {
        this.getWeatherData();
    }

    private getWeatherData = async () => {
        let defaultCityIndex = await Cookies.get('weatherCity')
        const { data: { data: { items } } } = await axios.get('/weather/api/data/ynetapp');

        if (!defaultCityIndex && items) {
            let defaultCity =  items.find(city => city.Name === "תל אביב - יפו");
            defaultCityIndex = items.indexOf(defaultCity);
        }
        this.setState({
            weatherDataList: items,
            selectedCityIndex: defaultCityIndex
        });
    }


    public render() {
        const { } = this.props
        const { selectedCityIndex, weatherDataList } = this.state
        const selectedCityWeather: WeatherData = weatherDataList && weatherDataList[selectedCityIndex];
        const imgSrc = getWeatherCode(selectedCityWeather && selectedCityWeather.IconCode, true);
        const urlToCity = getCityNameTranslation("link", selectedCityWeather && selectedCityWeather.Name) ? "/" + getCityNameTranslation("link", selectedCityWeather && selectedCityWeather.Name) : "";
        const weatherPageUrl = selectedCityWeather ? selectedCityWeather.Url  : "//www.ynet.co.il/home/0,7340,L-201,00.html";
        return (
            <div className="WeatherView">
                <div className="weatherimage">
                    {selectedCityWeather &&
                        <SimpleLinkRenderer href={weatherPageUrl} aria-label="מזג האוויר">
                            <img src={imgSrc} aria-hidden="true" className="iconWeather"/>
                        </SimpleLinkRenderer>}
                </div>
                <div className="weathertempsdiv">
                    {selectedCityWeather &&
                        <SimpleLinkRenderer className="weatherLink" href={weatherPageUrl}>
                            <span className="weathertemps">{selectedCityWeather.RoundedTemperature}&deg;</span>
                        </SimpleLinkRenderer>}
                </div>

                <div className="headerWeatherSelect">
                    <SimpleLinkRenderer href={weatherPageUrl} aria-label="מזג האוויר" className="selectedCity" >מזג אוויר</SimpleLinkRenderer>
                    <div className={"menuToggler toOpen"} />

                </div>
            </div>
        )
    }
}