import { SiteItemData, SiteVideoData } from "../../../../interfaces";
import { SiteVideoInline } from "../../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";

interface VideoVerticalFeedItemProps {
    videoItem: SiteItemData
    feedActiveSlide: number
    domain: string
    index: number
    onNextSlide: () => void
    onCloseVideoGalleryView: () => void
}

interface VideoVerticalFeedItemState {
    isSubtitleOpen: boolean
}

const DICT = {
    fullArticle: "לכתבה המלאה",
}
export class VideoVerticalFeedItem extends React.Component<VideoVerticalFeedItemProps, VideoVerticalFeedItemState>{
    constructor(props) {
        super(props);
        this.state = {
            isSubtitleOpen: false,
        }
    }

    componentDidMount() {
        this.initializeEndedEventListeners();
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        this.removeEndedEventListeners();
        document.removeEventListener('click', this.handleClickOutside);
    }

    private handleClickOutside = (event: MouseEvent) => {
        if ((event.target as Element).className.includes("slick-slide")) this.props.onCloseVideoGalleryView();
    };

    private initializeEndedEventListeners() {
        setTimeout(() => {
            const videoElements = document.querySelectorAll(".radionasVideoGalleryVerticalComponenta .feedDisplay .slick-slide .videoVerticalFeedItem #player .fp-engine") as NodeListOf<HTMLVideoElement>;
            if (videoElements.length) {
                videoElements.forEach((element) => {
                    element.addEventListener("ended", this.handleVideoEnded);
                })
            };
        }, 600)
    }

    private removeEndedEventListeners() {
        const videoElements = document.querySelectorAll(".radionasVideoGalleryVerticalComponenta .feedDisplay .slick-slide .videoVerticalFeedItem #player .fp-engine") as NodeListOf<HTMLVideoElement>;
        if (videoElements.length) {
            videoElements.forEach((element) => {
                element.removeEventListener("ended", this.handleVideoEnded)
            })
        };
    }

    private handleVideoEnded = (e) => {
        e.target.currentTime = 0;
        e.target.click();
        this.props.onNextSlide();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.feedActiveSlide !== this.props.feedActiveSlide) {
            setTimeout(() => {
                const videoElement = document.querySelector(".radionasVideoGalleryVerticalComponenta .feedDisplay .slick-active .videoVerticalFeedItem #player.is-paused .fp-engine") as HTMLVideoElement;
                const unActiveVideoElement = document.querySelector(".radionasVideoGalleryVerticalComponenta .feedDisplay .slick-slide:not(.slick-active) .videoVerticalFeedItem #player.is-playing .fp-engine") as HTMLVideoElement;
                if (videoElement) {
                    setTimeout(() => {
                        if (videoElement.paused) {
                            videoElement.pause();
                            videoElement.currentTime = 0;
                            videoElement.play();
                        }
                    }, 500)
                };
                if (unActiveVideoElement) unActiveVideoElement.click();
            }, 600)
        }
    }

    private onSubtitleModeToggle = () => {
        this.setState({ isSubtitleOpen: !this.state.isSubtitleOpen });
    }

    private pushGA4Event = () => {
        if (typeof window == "undefined" || !window.dataLayer) return;
        const { index } = this.props;

        window.dataLayer.push({
            event: "content_click",
            componenta_name: "vertical_video",
            content_type: "componenta",
            position_in_component: index,
            click_text:"לכתבה המלאה"
        });
    };

    public render() {
        const { isSubtitleOpen} = this.state;
        const { videoItem } = this.props;
        const { media, title, subTitle, titleLink, titleTargetLink } = videoItem;
        const subtitleClass = `videoSubtitle ${isSubtitleOpen ? "" : "close"}`;

        return (
            <div className="videoVerticalFeedItem">
                <SiteVideoInline
                    data={media as SiteVideoData}
                    applyStickyness={true}
                    width={400}
                    height={714}
                    hideVideoAds={true}
                    isShareButton={true}
                    runArticleAutoPlayLogic={false} />
                <div className="contentDiv">
                    <div className="videoTitle" dangerouslySetInnerHTML={{ __html: title }} />
                    <div className={subtitleClass} onClick={this.onSubtitleModeToggle} dangerouslySetInnerHTML={{ __html: subTitle }} />
                    {titleLink &&
                        <div onClick={this.pushGA4Event}>
                            <HyperLinkBuilder
                                className="videoLink"
                                href={titleLink}
                                linkTarget={titleTargetLink}
                            >
                                {DICT.fullArticle}
                        </HyperLinkBuilder>
                        </div>
                    }
                </div>
            </div>
        )
    }
}