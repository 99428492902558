import { VideoSettingsData, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SiteItemData } from "widgets/interfaces"
import { MultiArticlesSectionItemFront } from "./multiArticlesSectionItemFront";

interface MultiImagesFrontProps {
    itemList: SiteItemData[]
    fieldsDisplaySettings: IFieldDisplayData
    videoSettings: VideoSettingsData
    imageWidth: number
    imageHeight: number
    ads_infrastructure_type?: string
}

interface MultiImagesFrontState {
    lastHovered: number
}

export class MultiArticlesSectionFront extends React.Component<MultiImagesFrontProps, MultiImagesFrontState>{
    public static siteScriptName = "MultiArticlesSectionFront";
    constructor() {
        super();
        this.state = {
            lastHovered: 0,
        };
    }

    private slotHoverHandler = (slotIndex: number) => this.setState({lastHovered: slotIndex});

    public render() {
        const { imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings } = this.props;
        const {lastHovered} = this.state;

        return (
            <div className="slotList">{
                itemList.map((slot, index) => {
                    return (
                        <MultiArticlesSectionItemFront
                            imageWidth={imageWidth}
                            key={index}
                            index={index}
                            imageHeight={imageHeight}
                            videoSettings={videoSettings}
                            fieldsDisplaySettings={fieldsDisplaySettings}
                            item={slot}
                            marginLeft={20}
                            lastHovered={lastHovered}
                            slotHoverHandler={this.slotHoverHandler}
                        />
                    )
                })}
            </div>
        )
    }
}