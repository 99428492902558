import { RadioDayItemData } from "../../../radioWidgets/radioSchedule/data/radioDayItemData"
import { SingleShowItem } from "./singleShowItem";
import { RadioProgramItem } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"
import { useState, useEffect, useRef } from "react"

interface DailyScheduleItemProps {
    isMobileWeb: boolean
    dayItem: RadioDayItemData
    isTogglesOpen: boolean
    radioScheduleList: RadioProgramItem[]
}

export const DailyScheduleItem = (props: DailyScheduleItemProps) => {

    const { dayItem: { dayProgramsSchedule, dayItemId, dayDate }, isTogglesOpen, radioScheduleList, isMobileWeb } = props
    const [toggleState, setToggleState] = useState(isTogglesOpen)
    const accordionWrapperRef = useRef<HTMLDivElement>()
    const date = new Date(dayDate)
    const dateToShow = `${date.toLocaleDateString("ar", { weekday: 'long' })} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

    useEffect(() => {
        setToggleState(isTogglesOpen)
    }, [isTogglesOpen])

    const onToggleClick = () => {
        setToggleState(!toggleState)
    }

    const list = (
        //@ts-ignore
        <div className="daily-shows-list" style={toggleState ? { maxHeight: accordionWrapperRef.current.scrollHeight } : { maxHeight: 0, borderBottom: "1px solid #BCBCBC" }} ref={accordionWrapperRef}>
            {dayProgramsSchedule && dayProgramsSchedule.array.map(show => {
                const showItem = radioScheduleList.find(showItem => showItem.showId === show.showId)
                if (showItem) return <SingleShowItem show={showItem} key={`${dayItemId}_${showItem.showId}`} dayDate={dayDate} isMobileWeb={isMobileWeb} />
            })}
        </div>
    )

    return (
        <div className="show-day-schedule">
            <div className="accordion-toggle-wrapper" onClick={onToggleClick}>
                <div className="day-date">{dateToShow}</div>
                <div className={`toggle-btn ${toggleState ? "open" : ""}`}></div>
            </div>

            {list}
        </div>
    )
}