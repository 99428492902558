import * as classNames from "classnames"
import { RichInput } from "widgets/commonComponents/base/richInput"
import { isWcmEditor } from "config/serverConfig"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { FlashLinkType } from "../../../ticker/windows/flashLinkSelector";


interface TickerItemSlotProps {
    title: string
    flashDate: Date
    linkTarget: LinkTargetProperties
    link: string
    flashId: string
    flashLinkType: FlashLinkType
}

export class TickerItemSlotWar extends React.Component<TickerItemSlotProps, {}>{
    public static siteScriptName = "TickerItemSlotWar"
    private titleRef: RichInput;

    private renderLink(popUpLink: any) {
        const { flashLinkType, link, linkTarget, title, flashId } = this.props
        if (flashLinkType === "link") {

            return (
                <HyperLinkBuilder href={link}
                    linkTarget={linkTarget}
                    children={title} >
                    <div dangerouslySetInnerHTML={{ __html: title }} /></HyperLinkBuilder>

            )
        }
        else {
            return (popUpLink)
        }
    }


    public render() {
        const { title, flashDate, flashId } = this.props

        const popUpLink = !isWcmEditor() ? <a tabIndex={0} className="flashItem" data-flashid={flashId} id={flashId}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
        </a> : <div dangerouslySetInnerHTML={{ __html: title }} />
        return (
            <div className={classNames("slotView", { "inEditMode": isWcmEditor(), "emptySlot": title.length === 0 })}>
                <span className="bullet" />
                <div className={classNames("slotTitle", { "withWidth": title.length === 0 })}>
                    <span  >{separateHourFromDateAndFormatToString(flashDate, { showDate: true, showHour: true, isSlashSeparator: true, fullYear: true })}</span>
                </div>

                <div className="moreDetails" >
                    {this.renderLink(popUpLink)}

                </div>

            </div>
        )
    }
}

