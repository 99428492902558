require("./ynet_site_radioHomepagePlayerComponenta.less")
require("../../radioArticleLivePlayer/components/ynet_site_radioArticleLivePlayerComponenta.less")
import { RadioProgramJsonItem } from "../../../../jsonSerializer/radioScheduleJsonSerializer"
import { FrontRadioHomepagePlayer } from "./frontRadioHompagePlayer";
import { MutamRadioHomepagePlayer } from "./mutamRadioHomepagePlayer";
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { FrontRadioArticleLivePlayer } from "../../radioArticleLivePlayer/components/frontRadioArticleLivePlayer";
import { MutamRadioArticleLivePlayer } from "../../radioArticleLivePlayer/components/mutamRadioArticleLivePlayer";
import { DvrRadioPlayer } from "../../dvrRadioPlayer/dvrRadioPlayer";

export interface RadioProgramItem extends Partial<RadioProgramJsonItem> {
    startTime: string
    endTime: string
}

export interface RadioHomepagePlayerComponentaProps {
    tabColor: string;
    title: string;
    tabButtonTitle: string
    tabButtonHref: string
    tabButtonLink: LinkProperties<BaseLinkTypes>
    tabButtonLinkTarget: LinkTargetProperties
    liveBroadCastSource: string
    liveBroadCastDVRSource: string
    radioScheduleList: RadioProgramItem[]
    startingIndex: number
    isMobileWeb: boolean
    isArticleLivePlayer: boolean
}

export class RadioHomepagePlayerComponenta extends React.Component<RadioHomepagePlayerComponentaProps> {
    public static siteScriptName = "RadioHomepagePlayerComponenta"

    render() {
        const { tabColor, title, tabButtonTitle, tabButtonHref, tabButtonLink, tabButtonLinkTarget, radioScheduleList, isMobileWeb, startingIndex,
            liveBroadCastDVRSource, isArticleLivePlayer, liveBroadCastSource } = this.props
        const Player = isMobileWeb ? MutamRadioHomepagePlayer : FrontRadioHomepagePlayer;
        const ArticlePlayer = isMobileWeb ? MutamRadioArticleLivePlayer : FrontRadioArticleLivePlayer;
        return (
            <DvrRadioPlayer
                title={title}
                isMobileWeb={isMobileWeb}
                startingIndex={startingIndex}
                radioScheduleList={radioScheduleList}
                liveBroadCastDVRSource={liveBroadCastDVRSource}
                liveBroadCastSource={liveBroadCastSource}
                shouldAppearOnComponentOutOfView={true}
                playerComponentaRenderer={({ isAudioPlaying, onTogglePlayPause, onRefChanged, programItem }) => isArticleLivePlayer ?
                    <ArticlePlayer
                        buttonTitle={tabButtonTitle}
                        buttonHref={tabButtonHref}
                        buttonLink={tabButtonLink}
                        buttonLinkTarget={tabButtonLinkTarget}
                        programItem={programItem}
                        isAudioPlaying={isAudioPlaying}
                        onTogglePlayPause={onTogglePlayPause}
                        onRefChanged={onRefChanged}
                    />
                    :
                    <Player
                        tabColor={tabColor}
                        title={title}
                        tabButtonTitle={tabButtonTitle}
                        tabButtonHref={tabButtonHref}
                        tabButtonLink={tabButtonLink}
                        tabButtonLinkTarget={tabButtonLinkTarget}
                        programItem={programItem}
                        isAudioPlaying={isAudioPlaying}
                        onTogglePlayPause={onTogglePlayPause}
                        onRefChanged={onRefChanged}
                    />
                }
            />
        )
    }
}
