import { getImgAlt, isAriaHidden, getImgTitle, calculateAutomaticComponentaUrl } from "../../../../widgetsUtils"
import { GalleryItems } from "./photoArchiveComponenta";
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage";
import { ImageSize } from "../../../../../constants/imageSizes";

interface Props {
    item: GalleryItems
}

export const PhotoArchiveMainImage = (props: Props) => {
    if (!props.item) {
        return <div></div>
    }
    const { item } = props
    const { title, promotionImageDetails } = item

    return (
        // <div className="slotTitle">
            <a href={calculateAutomaticComponentaUrl(item)} className="mainItem">
                <img src={getFrontImageUrl(promotionImageDetails, { imageSize: ImageSize.xxLarge })}
                    style={{ width: 1182, height: 665 }}
                    className="SiteImageMedia"
                    alt={getImgAlt(promotionImageDetails)}
                    aria-hidden={isAriaHidden(promotionImageDetails)}
                    title={getImgTitle(promotionImageDetails)} />
                    <div className="blackLayer">
                        <div className="blackLayerText">{title}</div>
                    </div>
            </a>
        // </div>
    )
}
