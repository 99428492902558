
import { TickerAutoView } from "./tickerAutoView"
import { TickerViewData } from "../interfaces"
import * as axios from "axios"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";
import { TickerWarView } from "../../newYnetWidgets/tickerWar/components/tickerWarView"

interface SiteTickerAutoComponentaProps {
    data: TickerViewData
    height: number
    componentaId: string
    isEnglish: boolean
    isWar?: boolean
}

interface SiteTickerAutoComponentaState {
    articles: any
}

export class SiteTickerAutoComponenta extends React.Component<SiteTickerAutoComponentaProps, SiteTickerAutoComponentaState>{
    public static siteScriptName = "SiteTickerAutoComponenta"
    static defaultProps = { isWar: false }
    constructor(props) {
        super(props)
        this.state = {
            articles: []
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setArticles(newProps.data)
        }
    }

    componentDidMount() {
        const { data } = this.props;
        if (data.category.link.id) {
            this.setArticles(data)
            setInterval(() => {
                this.setArticles(data)
            }, 60000);
        }
    }

    private setArticles(data) {
        const { componentaId } = this.props
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/iphone/json/api/auto_ticker/${componentaId}`)
            .then((res: any) => {
                this.setState({ articles: res.data.data });
            })
            .catch(err => console.error("error geting ticker data:", err))
    }


    render() {
        const { data, height, isEnglish, isWar } = this.props
        const { articles } = this.state
        if (isWar) {
            return (
                <TickerWarView data={data} articles={articles} />
            )
        }
        else {
            return (
                <TickerAutoView data={data} height={height} articles={articles} isEnglish={isEnglish} />
            )
        }

    }
}