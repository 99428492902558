import { ArticleCommentsTopTabRenderProps } from "./articleCommentsTopTabWrapper"
import { getTranslation } from "./translations"
require('./site_ynet_articleCommentsTopTabYnetUi.less')
export interface ArticleCommentsTopTabYnetUiProps {
    props: ArticleCommentsTopTabRenderProps
}

export class ArticleCommentsTopTabYnetUi extends React.Component<ArticleCommentsTopTabYnetUiProps, {}>{

    render() {
        const { lang, isLtr, numOfComments, numOfDiscussions, sortSelection, areAllCommentsExpanded, isAddCommentFormVisible,
            expandAllComments, handleFormVisibility, handleSortSelection } = this.props.props
        let commentNumStyle = isLtr ? { marginRight: "5px" } : {}
        const buttonTitle = areAllCommentsExpanded ? getTranslation(lang, "Close All Comments") : getTranslation(lang, "Open All Comments")

        return (
            <div className="ArticleCommentsTopTabYnetUi">
                <div className="tab">
                    {numOfComments === 1 &&
                        <div className="tabTextWraper">
                            <span>{getTranslation(lang, "This Article Has One Comment")}</span>
                        </div>}

                    {numOfComments !== 1 &&
                        <div className={`tabTextWraper ${lang}`}>
                            <span>{getTranslation(lang, "This Article Has ")} </span>
                            <span style={commentNumStyle}>{` ${numOfComments} `} </span>
                            <span>{`${getTranslation(lang, "Comments In")}`}</span>
                            <span>{!isLtr && `${numOfDiscussions} `}</span>
                            <span>{!isLtr && getTranslation(lang, "Discussions")}</span>
                        </div>}
                    {((sortSelection === "end_to_start" || sortSelection === "start_to_end")) && numOfComments > 0 &&
                        <button className="expandAllCommentsBtn " title={buttonTitle} onClick={e => expandAllComments(!areAllCommentsExpanded)}>
                            <span> {buttonTitle}</span>
                        </button>}
                </div>

                <div className="topPanel">
                    <button className="newCommentBtn"
                        title={getTranslation(lang, "New Comment")}
                        disabled={isAddCommentFormVisible} onClick={(e) => handleFormVisibility()}>
                        <span className="textBubleIcon" />
                        <span>{getTranslation(lang, "New Comment")}</span>
                    </button>

                    {numOfComments > 0 &&
                        <div className="sortOptions">
                            <span className="selectLabel">{`${getTranslation(lang, "Present")}`}</span>
                            <select className="sortSelect" defaultValue={sortSelection} onChange={handleSortSelection}>
                                <option value="end_to_start">{getTranslation(lang, "End To Start")}</option>
                                {numOfComments > 1 && <option value="start_to_end">{getTranslation(lang, "Start To End")}</option>}
                                {numOfComments > 2 && <option value="recommended">{getTranslation(lang, "Recommended")}</option>}
                                {numOfComments > 2 && <option value="interest">{getTranslation(lang, "Interest")}</option>}
                            </select>
                        </div>}
                </div>
            </div>
        )
    }
}