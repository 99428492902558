import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";

interface GeneralSlotItemProps {
    fieldsDisplaySettings?: Partial<IFieldDisplayData>
    item: GeneralItemProp
}

export interface GeneralItemProp {
    title: string
    linkTarget?: LinkTargetProperties
    link: string
    imageSrc: string
    publishedDate: Date
    author: string
}

export class PodcastStandartSlotItemMutam extends React.Component<GeneralSlotItemProps, {}>{

    public render() {
        const { item, fieldsDisplaySettings, } = this.props
        const { title, link, linkTarget, imageSrc, publishedDate, author } = item
        const basiclinkTargetProperties = {
            linkOpenType: "same",
            lightBoxWidth: 803,
            lightBoxHeight: 700,
            lightBoxOpacity: 70,
            lightBoxBgColor: "#000000",
        } as LinkTargetProperties;
        return (

            <HyperLinkBuilder href={link} linkTarget={linkTarget ? linkTarget : basiclinkTargetProperties} title={title} className="podcast-category-slotItem">
                <span className="slotTitle">{title}</span>
                <div className="slotDetails">
                    {<DateDisplay date={publishedDate} hiddenYesterday={true} />}
                    { author && author.length > 0 && <span className="separator"> | </span>}
                    {author && author.length > 0 &&<span>{author}</span>}
                </div>
            </HyperLinkBuilder>

        )
    }

}