import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../widgetsUtils";
import { WhatsAppShareButton } from "../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton";

export class SiteArticleVideoComponentaShareButtons extends React.Component<{ articleLink: string, title: string}, {}> {
    public static siteScriptName = "SiteArticleVideoComponentaShareButtons";
    public render() {
        const { articleLink, title} = this.props;
        const isMobile = "undefined" != typeof window ? window.isMobile1 : false;
        return (
            <div className="videoComponentaShare">
                <div id="fbShare">
                    <img src="https://www.ynet.co.il/Common/Api/Scripts/youtube/facebook-s.png" className="share-image" onClick={() => openFacebookShare(articleLink, "", () => analyticsPushFunc("Facebook", "Video", title), "Video")} />
                </div>
                <div id="twShare">
                    <img src="https://www.ynet.co.il/Common/Api/Scripts/youtube/twitter-s.png" className="share-image" onClick={() => openTwitterShare(articleLink, () => analyticsPushFunc("Twitter", "Video", title), "Video")} />
                </div>
                {isMobile && <div id="waShare">
                    <img src="https://www.ynet.co.il/Common/Api/Scripts/youtube/whatsapp-s.png" className="share-image" onClick={() => WhatsAppShareButton.openWhatsAppShare(articleLink, () => analyticsPushFunc("Whatsapp", "Video", title), "Video")} />
                </div>}
            </div>
        )
    }
}