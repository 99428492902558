
    export const copyToClipboard = (textToCopy) => {
        const node = document.createElement("div")
        node.style.position = "absolute";
        node.style.fontSize = "12px";
        node.style.marginRight = "-15px";
        node.style.marginTop = "35px";
        node.style.color = "#b7b5b6";
        node.innerHTML = "הקישור הועתק";
        node.setAttribute("id", "alertCopyLink")
        const textField = document.createElement('textarea')
        textField.innerText = textToCopy;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
    }