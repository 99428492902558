interface toolbarButtonProps {
    onMouseDown?: () => void
    className: string
    iconClassName: string
    title: string
    id?: string
    style?: Object
    onClick: (value?: any) => void
}


export class ToolbarButton extends React.Component<toolbarButtonProps, {}> {
    private handleClick = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onClick(event);
    }
    public render() {
        const { style, className, iconClassName, title, id } = this.props;
        return (
            <div
                onMouseDown={e => { e.preventDefault(); e.stopPropagation() }}
                onClick={this.handleClick}
                className={className}
                title={title}
                id={id}
                style={style}
            >
                <div className={iconClassName} />
                {this.props.children}
            </div>
        )
    }
}