import { isWcmEditor } from "../../../../../config/serverConfig";

require("./ynet_site_ynetSearchForm.less")

type SelectedTypes = "web" | "pro" | "ynet" | "ynetshops" | "suppliers";

interface YnetSearchFormState {
    selectedSearchVal: SelectedTypes
    userInput: string
    isInFocus: boolean
    backgroundImg?: string
    placeholder?: string
}
interface YnetSearchFormProps {
    isGrid1280?: boolean
    placeholder?: string
    ynetSearchPageLink?: string
    closeX?:boolean
    closeSearchInput?:()=>void
}

export class YnetSearchForm extends React.Component<YnetSearchFormProps, YnetSearchFormState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedSearchVal: "ynet",
            userInput: "",
            isInFocus: false,
            placeholder: props.placeholder ? props.placeholder : ""
        }
    }

    private isZoomdEnabled() {
        return false;
    }

    private setZoomdAtrribute(ref: HTMLInputElement) {
        if (!isWcmEditor() && this.isZoomdEnabled()) {
            if (ref) {
                ref.setAttribute("zoomdSearch", "{'trigger':'onClick'}");
                const script = document.createElement("script");
                script.src = "//zdwidget3-bs.sphereup.com/zoomd/SearchUi/Script?clientId=93896527";
                script.async = true;

                document.body.appendChild(script);
            }
        }
    }

    private proffesionsUtf8Encode = (src: string): string => {
        let encodedString = ""
        for (let i = 0; i < src.length; i++) {
            let cha = src.charCodeAt(i)
            if (cha >= 1488 && cha <= 1514) {
                cha -= 1264
                encodedString += "%" + cha.toString(16).toUpperCase()
            } else {
                encodedString += encodeURI(src.charAt(i))
            }
        }
        return encodedString
    }
    private search = () => {
        const { selectedSearchVal, userInput: typedUserInput } = this.state;
        const { ynetSearchPageLink } = this.props
        if (!typedUserInput) {
            return
        }

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let urlTail = ""

        if (!isWcmEditor()) {
            switch (selectedSearchVal) {
                case "web":
                    urlHead = ynetSearchPageLink && ynetSearchPageLink.length > 0 ? `${ynetSearchPageLink}?q=` : "//www.ynet.co.il/home/0,7340,L-9600,00.html?q="
                    userInput = typedUserInput
                    urlTail = "&cx=partner-pub-4207657971126930:3262695125&cof=GIMP:009900;T:000000;ALC:FF9900;GFNT:B0B0B0;LC:0000FF;BRC:FFFFFF;BGC:FFFFFF;VLC:666666;GALT:36A200;LBGC:FF0000;DIV:FFFFEE;FORID:9;&as_qdr=all&hq=more:recent4&ynet_search_type=web"
                    fullUrl = urlHead + userInput + urlTail

                    location.href = fullUrl
                    break;
                case "ynet":
                    urlHead = ynetSearchPageLink && ynetSearchPageLink.length > 0 ? `${ynetSearchPageLink}?q=` : "//www.ynet.co.il/home/0,7340,L-9600,00.html?q="
                    userInput = typedUserInput
                    urlTail = "&cx=partner-pub-4207657971126930:3067011121&cof=GIMP:009900;T:000000;ALC:FF9900;GFNT:B0B0B0;LC:0000FF;BRC:FFFFFF;BGC:FFFFFF;VLC:666666;GALT:36A200;LBGC:FF0000;DIV:FFFFEE;FORID:9;&as_qdr=all&hq=more:recent4&ynet_search_type=ynet"
                    fullUrl = urlHead + userInput + urlTail

                    location.href = fullUrl
                    break;
                case "pro":
                    urlHead = "//www.ynet.co.il/home/0,7340,L-9919,00.html?q="
                    userInput = this.proffesionsUtf8Encode(typedUserInput)
                    fullUrl = urlHead + userInput

                    window.open(fullUrl, "ProWindow")
                    break;
                case "ynetshops":
                    urlHead = "//www.ynetshops.co.il/Search.aspx?Search="
                    userInput = typedUserInput
                    urlTail = "&utm_source=ynet&utm_medium=search.pane&utm_campaign=search.ynetshops"
                    fullUrl = urlHead + userInput + urlTail

                    location.href = fullUrl
                    break;
                case "suppliers":
                    urlHead = "//hasapakim.co.il/?s="
                    userInput = typedUserInput
                    urlTail = "&type=searchCategory"
                    fullUrl = urlHead + userInput + urlTail

                    window.open(fullUrl, "SuppliersWindow")
                    break;
            }
        }

    }

    private updateSearchSelection = (data) => {
        this.setState({ selectedSearchVal: data.target.value });
    }

    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }

    private handleInputFocus = () => {
        this.setState({ isInFocus: true });
    }

    private handleInputBlur = () => {
        this.setState({ isInFocus: false });
    }

    private getBackgroundImage = () => {
        const { selectedSearchVal, userInput, isInFocus } = this.state
        if (!userInput && !isInFocus && selectedSearchVal == "web") {
            return "/images/google_56X20.png";
        }
        return "";
    }

    private getPlaceHolderText = () => {
        const { selectedSearchVal, userInput, isInFocus, placeholder } = this.state
        if (!userInput && !isInFocus) {
            switch (selectedSearchVal) {
                case "pro":
                    return "הקלידו תחום - לדוגמה: אינסטלטור";
                case "suppliers":
                    return "לדוגמה: בתי דפוס";
            }
        }
        return placeholder;
    }

    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }

    public render() {
        const { selectedSearchVal, userInput } = this.state;
        const backgroundImg = this.getBackgroundImage();
        const placeholder = this.getPlaceHolderText();
        const showZoomd = this.state.selectedSearchVal === "ynet" && this.isZoomdEnabled()
        const searchBoxStyles = backgroundImg ? { background: `url(${backgroundImg}) center right no-repeat #fff` } : null

        return (
            <div className="main_search_elements" id="mainSrchElements">
                <div id="main_search_bg">

                    <div id="mainSrchBoxInput">
                        {!showZoomd && <input
                            alt="חיפוש"
                            className="mainSrchclass"
                            id="mainSrchBox"
                            aria-label="חיפוש"
                            type="text"
                            value={userInput}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            onChange={this.updateInputValue}
                            onKeyPress={this.handleInputKeyPress}
                            placeholder={placeholder}
                            style={searchBoxStyles}
                            ref={input => input && !this.props.isGrid1280 && !isWcmEditor() && input.focus()}

                        />}
                        {showZoomd && <input
                            ref={ref => this.setZoomdAtrribute(ref)}
                            alt="חיפוש"
                            className="mainSrchclassynet"
                            id="mainSrchBox2"
                            aria-label="חיפוש"
                            type="text"
                            value=""
                        />}
                    </div>
                    <div id="mainSearchSelect">
                        <select id="mainSearchSelectType" aria-label="בחרו סוג חיפוש" onChange={(data) => this.updateSearchSelection(data)} value={selectedSearchVal}>
                            <option value="ynet">
                                ynet
                            </option>
                            <option value="web">
                                אינטרנט
                            </option>
                            <option value="pro">
                                בעלי מקצוע
                            </option>
                            {!this.props.isGrid1280 && <option value="ynetshops">
                                קניות
                            </option>}
                            {!this.props.isGrid1280 && <option value="suppliers">
                                ספקים למשרד
                            </option>}
                        </select>
                    </div>
                </div>
                <a className="dyother dyMonitor" data-adid="172594||445|||" data-dy-article="1" href="javascript:void(0);" id="MsBtn" tabIndex={102} role="button" onClick={this.search}></a>
           {this.props.closeX &&<button className="closeBtn" onClick={this.props.closeSearchInput}></button>}
            </div>
        )
    }
}
