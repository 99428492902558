import * as classNames from 'classnames';
import { SiteLayoutType } from '../../../../pagesPage/stateInterfaces';
//import { isMobile } from '../../../../utils/isMobile';

interface ArticleMagazineTopStoryHeaderProps {
  siteLayoutType: SiteLayoutType;
}

interface ArticleMagazineTopStoryHeaderState {
  chapters: string[];
  currentChapterIndex: number;
  carouselIndex: number;
  isStickyMenu: boolean
}

export class ArticleMagazineChapterMenu extends React.Component<
  ArticleMagazineTopStoryHeaderProps,
  ArticleMagazineTopStoryHeaderState
  > {
  public static siteScriptName = 'ArticleMagazineChapterMenu';
  private chatpersObserver: IntersectionObserver | null = null;
  private headerObserver: IntersectionObserver | null = null;
  private chapterRefs: HTMLElement[] = [];
  private chaptersNodes: HTMLElement[] = [];
  private HEIGHT_OFFSET = 0;
  private SECONDRY_HEIGHT_OFFSET = 0;
  private disableObserver = false;
  constructor(props: ArticleMagazineTopStoryHeaderProps) {
    super(props);
    this.state = {
      chapters: [],
      carouselIndex: 0,
      currentChapterIndex: 0,
      isStickyMenu: false
    };
  }

  componentDidMount() {
    this.initHeaderOffsetHeight();
    this.initChapters();
    this.initObserver();
    this.initChapterHeight();
    this.SECONDRY_HEIGHT_OFFSET = document.querySelector('.article-magazine-chapter-menu').clientHeight;
  }

  componentWillUnmount() {
    if (this.chatpersObserver) {
      this.chatpersObserver.disconnect();
    }
    if (this.headerObserver) {
      this.headerObserver.disconnect();
    }
  }

  componentDidUpdate(prevProps: ArticleMagazineTopStoryHeaderProps, prevState: ArticleMagazineTopStoryHeaderState) {
    if (prevState.chapters !== this.state.chapters || prevState.carouselIndex !== this.state.carouselIndex || prevState.currentChapterIndex !==this.state.currentChapterIndex) {
      this.forceUpdate();
    }
  }

  getTotalPageNumber = () => {
    if (typeof document === 'undefined') {
      return 0;
    }

    const container = document.querySelector('.article-magazine-chapter-menu');
    const itemsContainer = document.querySelector('.chapters-container');

    if (!container || !itemsContainer) {
      return 0;
    }

    const containerWidth = container.clientWidth;
    const itemContainerWidth = itemsContainer.scrollWidth;

    if (itemContainerWidth <= containerWidth) {
      return 0;
    }

    return Math.ceil(itemContainerWidth / containerWidth);
  };

  initHeaderOffsetHeight = () => {
    const headerEl =
      document.querySelector('.YnetMutamHeader') || document.querySelector('.article-magazine-top-story-header');
    if (!headerEl) {
      return;
    }
    this.HEIGHT_OFFSET = headerEl.clientHeight;
  };

  initChapters = () => {
    const chapters: string[] = [];
    const chaptersNodes: HTMLElement[] = [];
    document.querySelectorAll('h3.chatper_title').forEach((chapter: HTMLElement) => {
      const textValue = (chapter as HTMLElement).innerText.trim();

      if (textValue) {
        chapters.push(textValue);
        chaptersNodes.push(chapter);
      }
    });
    this.chaptersNodes = chaptersNodes;
    this.setState({ chapters });
  };

  initChapterHeight = () => {
    const chapterContainers = document.querySelectorAll('.chapter_title');
    const { siteLayoutType } = this.props;
    const isMobile = siteLayoutType === 'mobileApp' || siteLayoutType === 'mobileWeb';
    const marginDelta = {
      ["mobileApp"]: 0,
      ["mobileWeb"]: 0.1,
      ["desktop"]: 0.2
    };
    for (let i = 0; i < chapterContainers.length; i++) {
      const chapterContainer = chapterContainers[i] as HTMLElement;
      let nextChapterContainer: HTMLElement | null = null;

      if (i + 1 < chapterContainers.length) {
        nextChapterContainer = chapterContainers[i + 1] as HTMLElement;
      }

      if (isMobile) {
        const chapterHeight = this.chaptersNodes[i].clientHeight;
        chapterContainer.style.height = `${chapterHeight * 1.2}px`;
        this.chaptersNodes[i].style.marginBottom = `${chapterHeight * 1.1}px`;
        this.chaptersNodes[i].style.fontSize = "21px"
      }

      if (nextChapterContainer) {
        const delta = nextChapterContainer.getBoundingClientRect().top - chapterContainer.getBoundingClientRect().top;
        this.chaptersNodes[i].style.height = `${delta - delta * marginDelta[siteLayoutType]}px`;
      }
    }
  };

  initObserver = () => {
    if (IntersectionObserver) {
      const { siteLayoutType } = this.props;
      const isMobile = siteLayoutType === 'mobileApp' || siteLayoutType === 'mobileWeb';
      const mobileHeadersMargin = this.HEIGHT_OFFSET + this.SECONDRY_HEIGHT_OFFSET;
      const chaptersOberveseroptions: IntersectionObserverInit = {
        rootMargin: `-${isMobile ? mobileHeadersMargin : this.HEIGHT_OFFSET}px 0px -${
          (window.innerHeight / 3) * 2
          }px 0px`,
        threshold: 0,
      };

      const headerObserverOptions: IntersectionObserverInit = isMobile
        ? {
          root: null,
          rootMargin: `-${mobileHeadersMargin}px 0px -${mobileHeadersMargin}px 0px`,
          threshold: 0,
        }
        : {
          rootMargin: `-${1}px`,
          threshold: 1,
        };

      this.chatpersObserver = new IntersectionObserver(
        (entries) => this.handleChaptersIntersections(entries),
        chaptersOberveseroptions,
      );
      this.headerObserver = new IntersectionObserver(
        ([entry]) => this.handleMenuHeaderIntersection(entry),
        headerObserverOptions,
      );
      document
        .querySelectorAll('h3.chatper_title')
        .forEach((node) => (node as any).innerText.trim() && this.chatpersObserver.observe(node));
      this.headerObserver.observe(document.querySelector('.layoutItem.Magazine-Article-Chapter-Menu'));
    }
  };

  handleMenuHeaderIntersection = (entry: IntersectionObserverEntry) => {
    if (IntersectionObserver) {
      const { siteLayoutType } = this.props;
      const isMobile = siteLayoutType === 'mobileApp' || siteLayoutType === 'mobileWeb';
      const headerElement = document.querySelector('.article-magazine-top-story-header');
      const menuTempPlaceholder = document.querySelector('.menu-temp-placeholder') as HTMLElement;

      // Check if the entry is intersecting and its top position relative to the viewport
      const isSticky = !entry.isIntersecting && entry.boundingClientRect.top < 0;
      this.setState({ isStickyMenu: isSticky })
      // Apply or remove sticky logic based on the calculated isSticky
      entry.target.classList.toggle('isSticky', isSticky);

      if (!isMobile && headerElement) {
        headerElement.classList.toggle('stickyMode', isSticky);
      }

      if (menuTempPlaceholder) {
        menuTempPlaceholder.style.display = isSticky ? 'block' : 'none';
      }
    }
  };

  handleChaptersIntersections = (entries: IntersectionObserverEntry[]) => {
    // Filter and sort entries to get the top-most intersecting element
    if (this.disableObserver) {
      // return
    }
    const halfScreenHeight = window.innerHeight / 2;
    const filteredEntries = entries
      .filter((entry) => entry.isIntersecting && !!(entry.target as any).innerText.trim())
      .sort((a, b) => b.boundingClientRect.top - a.boundingClientRect.top);

    console.log(
      'Filtered entries:',
      filteredEntries.map((entry) => ({
        target: entry.target,
        top: entry.boundingClientRect.top,
        isIntersecting: entry.isIntersecting,
      })),
    ); // Debugging log

    if (filteredEntries.length > 0) {
      const topEntry = filteredEntries[0];
      const element = topEntry.target as HTMLElement;
   //   console.log('Top visible element:', element); // Debugging log

      if (element.classList.contains('layoutItem') && element.classList.contains('Magazine-Article-Chapter-Menu')) {
        element.classList.toggle('isSticky', topEntry.intersectionRatio < 1);
      }

      const chapText = element.innerText.trim();
      const index = this.state.chapters.findIndex((chapter) => chapter === chapText);
  //    console.log('Chapter index found:', index); // Debugging log
      this.handleChapterMenuCurrentIndexChange(index);
    }
  };

  getFirstElementInViewport = (elements: Element[] | NodeListOf<Element>) => {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (!(element as any).innerText.trim()) {
        continue;
      }
      if (this.isElementInViewport(element)) {
        return element;
      }
    }
  };

  isElementInViewport = (element: Element) => {
    if (!element) return false;
    if (1 !== element.nodeType) return false;

    var html = document.documentElement;
    var { bottom, left, right, top } = element.getBoundingClientRect();

    return bottom >= 0 && right >= 0 && left <= html.clientWidth && top - this.HEIGHT_OFFSET <= html.clientHeight;
  };

  handleChapterClick = (chapterIndex: number) => {
    const { currentChapterIndex } = this.state;
    const { siteLayoutType } = this.props
    const { top } = this.chaptersNodes[chapterIndex].getBoundingClientRect();
    const isMobile = siteLayoutType === 'mobileApp' || siteLayoutType === 'mobileWeb';

    const y =
      window.scrollY +
      top -
      this.HEIGHT_OFFSET -
      this.SECONDRY_HEIGHT_OFFSET -
      (isMobile && (!currentChapterIndex || !chapterIndex) ? this.SECONDRY_HEIGHT_OFFSET : 0) -
      (!isMobile && (!currentChapterIndex || !chapterIndex) ? this.SECONDRY_HEIGHT_OFFSET * 2 : 0);
    this.disableObserver = true;
    setTimeout(() => {
      this.disableObserver = false;
    }, 750);
   // console.log(y)
    window.scrollTo({ top: isMobile ? y - 60 : y, behavior: 'smooth' });

    const onScrollEnd = () => {
      window.removeEventListener('scroll', onScrollEnd);
      this.setState({ currentChapterIndex: chapterIndex });
    };
    window.addEventListener('scroll', onScrollEnd);
  };

  handleChapterMenuCurrentIndexChange = (index: number) => {
    const itemsPerPage = this.getTotalPageNumber();
    let newPageIndex = 0;
    if (itemsPerPage > 1) {
      newPageIndex = Math.floor(index / itemsPerPage);
    }
    const containerElements = document.querySelectorAll('.chapter-item');
    const chapterItem = containerElements[index] as HTMLElement;
    const container = document.querySelector('.chapters-container') as any;

    if (!chapterItem) {
      return;
    }

    this.setState({ currentChapterIndex: index, carouselIndex: newPageIndex }, () => {
      const containerRect = container.getBoundingClientRect();
      const chapterItemRect = chapterItem.getBoundingClientRect();
      const scrollAmount =
        chapterItemRect.left +
        container.scrollLeft -
        containerRect.left -
        containerRect.width / 2 +
        chapterItemRect.width / 2;
      container.scrollTo({ top: 0, left: scrollAmount, behavior: 'smooth' });
    });
  };

  handleArrowClick = (dir: 'left' | 'right') => {
    const { carouselIndex } = this.state;
    const totalPages = this.getTotalPageNumber();
    const container = document.querySelector('.chapters-container') as HTMLElement;
    let newIndex = dir === 'left' ? carouselIndex + 1 : carouselIndex - 1;

    if (newIndex < 0) newIndex = 0;
    if (newIndex >= totalPages - 1) newIndex = totalPages - 1;

    this.setState({ carouselIndex: newIndex }, () => {
      container.scrollLeft = -1 * (container.clientWidth * newIndex);
    });
  };

  shouldArrowBeVisibile = (dir: 'left' | 'right') => {
    const { carouselIndex } = this.state;
    const totalPages = this.getTotalPageNumber();
    const parentContainer = document.querySelector(".article-magazine-chapter-menu");
    const itemLength = document.querySelectorAll('.chapter-item').length;
    const lastItem = document.querySelectorAll('.chapter-item')[itemLength - 1]
    const firstItem =  document.querySelectorAll('.chapter-item')[0]; 
    // @ts-ignore 
    const parentContainerOffsetLeft = parentContainer && parentContainer.getBoundingClientRect().x 
    const firstItemRightPos =firstItem && firstItem.getBoundingClientRect().right;
    const parentposRight = parentContainer &&  parentContainer.getBoundingClientRect().right;
     // @ts-ignore 
    const lastItemOffsetLeft = lastItem && lastItem.getBoundingClientRect().x ;
    if (dir === 'left' && lastItemOffsetLeft + 60 < parentContainerOffsetLeft) {
      return true;
    }
    if (dir === 'right' &&  firstItemRightPos  > parentposRight + 60 ) {
      return true;
    }
    return false;
  };

  render() {
    const { chapters, currentChapterIndex, isStickyMenu } = this.state;
    const { siteLayoutType } = this.props
    const chapterItems = chapters.map((chapter, index) => (
      siteLayoutType === 'mobileApp' ? <a key={index}
        className={classNames('chapter-item', { selected: index === currentChapterIndex })}
        ref={(ref) => { this.chapterRefs[index] = ref; }}
        onClick={() => this.handleChapterClick(index)}
        target="_blank"
        href={`/componenta_name=${isStickyMenu ? "chaptered_article_header_menu" : "chaptered_article_body_menu"}&content_type=menu&click_text=${chapter}`}
      >  {chapter}</a> : <div
        key={index}
        className={classNames('chapter-item', { selected: index === currentChapterIndex })}
        ref={(ref) => {
          this.chapterRefs[index] = ref;
        }}
        onClick={() => this.handleChapterClick(index)}
      >
          {chapter}
        </div>
    ));

    return (
      <div className={`article-magazine-chapter-menu ${chapterItems.length > 0 ? "" : "noChapters"}`}>
        <div className="chapters-container">

          {!!chapterItems.length && (
            <div className="arrow-item" style={{ display: this.shouldArrowBeVisibile('right') ? 'flex' : 'none' }}>
              <div className="arrow-icon" onClick={() => this.handleArrowClick('right')} />
            </div>
          )}

          {chapterItems}

          {!!chapterItems.length && (
            <div className="arrow-item opposite" style={{ display: this.shouldArrowBeVisibile('left') ? 'flex' : 'none' }}>
              <div className="arrow-icon" onClick={() => this.handleArrowClick('left')} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
