require("./menta_laisha_site_header-mobile.less")
import { LinkTargetProperties } from "global/components/linkSettings/interfaces";
import { LaishaSubChannelMenuMobile } from "./laishaMobileSubChannelHeader";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { getImagesSrc } from "../../../../commonComponents/inline/siteSimpleImage"

export interface laishaHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
}

interface laishaHeaderMenuProps {
    items: laishaHeaderMenuItem[]
    id?: string
    siteLogoUrl: string
    parentSiteLogoUrl: string
    siteLogoLink: string
    parentSiteLogoLink: string
    parentSiteLogoTarget?: any
    siteLogoTarget?: any
    siteLogoTitle?: any
    parentSiteLogoTitle?: any
    instagramLink: string
    facebookLink: string
    contactusLink: string
    imagesDomain: string
}

interface MobileLaishaHeaderState {
    isOpened: boolean
}

export class MobileLaishaHeader extends React.Component<laishaHeaderMenuProps, MobileLaishaHeaderState>{
    public static siteScriptName = "MobileLaishaHeader";
    constructor(props) {
        super(props)
        this.state = {
            isOpened: null
        }
    }

    private openMenu = () => {
        this.setState({ isOpened: true })
        document.body.style.overflow = "hidden";

    }
    private closeMenu = () => {
        this.setState({ isOpened: false })
        document.body.style.overflow = "auto";
    }

    render() {

        const { items, instagramLink, facebookLink, contactusLink, siteLogoUrl, parentSiteLogoUrl, siteLogoLink, parentSiteLogoTitle, siteLogoTitle,
            parentSiteLogoLink, siteLogoTarget, parentSiteLogoTarget, imagesDomain } = this.props
        const { isOpened } = this.state
        return (
            <div className={`MobileLaishaHeader LaishaHeaderComponenta`}  >
                <div className="topHeader">
                    {!isOpened && <button className="HamBtn" onClick={this.openMenu} />}
                    {isOpened && <button className="hamMenuClsBtn" onClick={this.closeMenu} />
                    }

                    <HyperLinkBuilder className="mainSiteLogo" href={siteLogoLink} linkTarget={siteLogoTarget} rel="nofollow">
                        <SiteSimpleImage src={getImagesSrc(imagesDomain, siteLogoUrl)} title={siteLogoTitle} />
                    </HyperLinkBuilder>

                    <a href="javascript:history.back()">
                        <div className="arrowBack" />
                    </a>


                </div>
                <LaishaSubChannelMenuMobile
                    items={items}
                    parentSiteLogoUrl={parentSiteLogoUrl}
                    parentSiteLogoLink={parentSiteLogoLink}
                    parentSiteLogoTarget={parentSiteLogoTarget}
                    parentSiteLogoTitle={parentSiteLogoTitle}
                    isOpened={isOpened}
                    onClose={this.closeMenu}
                    instagramLink={instagramLink}
                    facebookLink={facebookLink}
                    contactusLink={contactusLink}
                />

            </div>
        )
    }
}



