import * as Route from "route-parser"
import { NODE_NEW_ID } from "pagesPage/consts"

export const ARTICLES = "articles";
export const DUPLICATE_ARTICLES = "duplicateArticles";
export const FLASHES = "articleFlashes";
export const ARTICLE = `${ARTICLES}/:articleId`
export const UITEST = "uitest";
export const PAGES = "pages";
export const PAGE = "(:pageId)"
export const PAGE_PREVIEW = "pagePreview/:pageId"
export const NEWPAGE = "new"
export const CATEGORY = "category/:categoryId"
export const ARTICLETEMPLATEDETAILS = "articleTemplateDetails/:articleTemplateDetailsId"
export const TALKBACKS = "talkbacks";
export const USERS = "users";
export const USERS_EDIT = `edit/:userId`;
export const ROLES = "roles";
export const WORDS = "words";
export const TAGS = "tags";
export const MEDIA = "media";
export const IMAGES_UPLOAD = "imagesUpload";
export const VIDEO_UPLOAD = "videoUpload";
export const VIDEO_YOUTUBE_UPLOAD = "videoYoutubeUpload/";
export const VIDEO_YOUTUBE_EDIT = "edit/:videoId";
export const VIDEO_MANAGER = "videosManager";
export const CREATE_TRANSCRIPTION = "createTranscription";
export const TRANSCRIPTION_MANAGER = "transcriptionManager";
export const FILES_UPLOAD = "filesUpload";
export const IMAGES_MANAGER = "imagesManager";
export const VIDEOS_MANAGER = "videosManager";
export const FILES_MANAGER = "filesManager";
export const PUSH = "push";
export const PUSH_ITEM = "edit/:pushId";
export const MARKETING = "marketing";
export const HOTUPDATES = `hotUpdates`
export const REPORTS = 'reports'
export const REAL_TIME = `${REPORTS}/realTime`
export const ARTICLE_REPORT = `${REPORTS}/articleReport`
export const AKUM_AND_POPULARITY_REPORTS = `${REPORTS}/akumAndPopularityReports`
export const YOKRA = 'yokra'
export const CONFIG_FILE_EDITOR = 'configFileEditor'
export const ARTICLE_BODY_ADS_CONFIG = 'articleBodyAdsConfig'
export const WRITERS_SALARY_REPORTS = `${REPORTS}/writesSalaryReports`

const addStartingSlashIfMissing = (path: string) => path && path.startsWith("/") ? path : "/" + path;


const articlesRoute = new Route(`/${ARTICLES}`);
export function matchArticlesPath(path: string) {
    return articlesRoute.match(addStartingSlashIfMissing(path))
}
const duplicateArticlesRoute = new Route(`/${DUPLICATE_ARTICLES}`);
export function matchDuplicateArticlesPath(path: string) {
    return duplicateArticlesRoute.match(addStartingSlashIfMissing(path))
}

const articleFlashesRoute = new Route(`/${FLASHES}`);
export function matchArticleFlashesPath(path: string) {
    return articleFlashesRoute.match(addStartingSlashIfMissing(path))
}

const articleRoute = new Route(`/${ARTICLE}`);
export function articlePath(articleId: string) {
    return articleRoute.reverse({ articleId });
}
export function matchArticlePath(path: string) {
    return articleRoute.match(addStartingSlashIfMissing(path));
}

const pagesRoute = new Route(`/${PAGES}`)
export function matchPagesPath(path: string) {
    return pagesRoute.match(addStartingSlashIfMissing(path))
}
const pageRoute = new Route(`/${PAGES}/${PAGE}`)
export function pagePath(pageId: string) {
    return pageRoute.reverse({ pageId })
}
export function matchPagePath(path: string) {
    return pageRoute.match(addStartingSlashIfMissing(path))
}

const pagePreviewRoute = new Route(`/${PAGES}/${PAGE_PREVIEW}`);
export function pagePreviewPath(pageId: string) {
    return pagePreviewRoute.reverse({ pageId })
}

const newPageRoute = new Route(`/${PAGES}/${NEWPAGE}`)
export function newPagePath() {
    return `/${PAGES}/${NEWPAGE}`
}
export function matchNewPagePath(path: string) {
    return newPageRoute.match(addStartingSlashIfMissing(path))
}

const categoryRoute = new Route(`/${PAGES}/${CATEGORY}`)
export function categoryPath(categoryId: string) {
    return categoryRoute.reverse({ categoryId })
}
export function matchCategoryPath(path: string) {
    return categoryRoute.match(addStartingSlashIfMissing(path))
}

const articleTemplateDetailsRoute = new Route(`/${PAGES}/${ARTICLETEMPLATEDETAILS}`)
export function articleTemplateDetailsPath(articleTemplateDetailsId: string) {
    return articleTemplateDetailsRoute.reverse({ articleTemplateDetailsId })
}
export function matchArticleTemplateDetailsPath(path: string) {
    return articleTemplateDetailsRoute.match(addStartingSlashIfMissing(path))
}

const userEditRoute = new Route(`/${USERS}/${USERS_EDIT}`)
const userSearchRoute = new Route(`/${USERS}`)
export function usersSearchPath() {
    return userSearchRoute.reverse({});
}
export function userEditPath(userId: string) {
    return userEditRoute.reverse({ userId });
}
export function matchUserEditPath(path: string) {
    return userEditRoute.match(addStartingSlashIfMissing(path));
}
export function matchUsersEditPath(path: string) {
    return userSearchRoute.match(addStartingSlashIfMissing(path))
}

const talkbacksRoute = new Route(`/${TALKBACKS}`);
export function matchTalkbacksPath(path: string) {
    return talkbacksRoute.match(addStartingSlashIfMissing(path))
}
const wordsRoute = new Route(`/${TALKBACKS}/${WORDS}`)
export function matchWordsPath(path: string) {
    return wordsRoute.match(addStartingSlashIfMissing(path))
}
const tagsRoute = new Route(`/${TAGS}`);
export function matchTagsPath(path: string) {
    return tagsRoute.match(addStartingSlashIfMissing(path))
}

const mediaRoute = new Route(`/${MEDIA}`)
export function matchMediaVRoute(path: string) {
    return mediaRoute.match(addStartingSlashIfMissing(path))
}

const imagesUpload = new Route(`/${MEDIA}/${IMAGES_UPLOAD}`)
export function matchImagesUpload(path: string) {
    return imagesUpload.match(addStartingSlashIfMissing(path))
}

const mediaVideoUploadRoute = new Route(`/${MEDIA}/${VIDEO_UPLOAD}`);
export function matchMediaVideoUploadRoute(path: string) {
    return mediaVideoUploadRoute.match(addStartingSlashIfMissing(path))
}
const mediaVideoYoutubeUploadRoute = new Route(`/${MEDIA}/${VIDEO_YOUTUBE_UPLOAD}`);
export function matchMediaVideoYoutubeUploadRoute(path: string) {
    return mediaVideoYoutubeUploadRoute.match(addStartingSlashIfMissing(path))
}

const mediaVideoYoutubeEditRoute = new Route(`/${MEDIA}/${VIDEO_YOUTUBE_UPLOAD}/${VIDEO_YOUTUBE_EDIT}`);
export function matchMediaVideoYoutubeEditRoute(path: string) {
    return mediaVideoYoutubeEditRoute.match(addStartingSlashIfMissing(path))
}

const mediaVideoManagerRoute = new Route(`/${MEDIA}/${VIDEO_MANAGER}`);
export function matchMediaVideoManagerRoute(path: string) {
    return mediaVideoManagerRoute.match(addStartingSlashIfMissing(path))
}

const mediaCreateTranscriptionRoute = new Route(`/${MEDIA}/${CREATE_TRANSCRIPTION}`);
export function matchCreateTranscriptionRoute(path: string) {
    return mediaCreateTranscriptionRoute.match(addStartingSlashIfMissing(path))
}

const mediaTranscriptionManagerRoute = new Route(`/${MEDIA}/${TRANSCRIPTION_MANAGER}`);
export function matchTranscriptionManagerRoute(path: string) {
    return mediaTranscriptionManagerRoute.match(addStartingSlashIfMissing(path))
}

const filesUploadRoute = new Route(`/${MEDIA}/${FILES_UPLOAD}`);
export function matchFilesUpload(path: string) {
    return filesUploadRoute.match(addStartingSlashIfMissing(path))
}

const userGroupsRoute = new Route(`/${USERS}/${ROLES}`);
export function matchUserGroups(path: string) {
    return userGroupsRoute.match(addStartingSlashIfMissing(path))
}

const marketingPixels = new Route(`/${MARKETING}`);
export function matchMarketingPixelsPath(path: string) {
    return marketingPixels.match(addStartingSlashIfMissing(path))
}
const pushRoute = new Route(`/${PUSH}`);
export function matchPushPath(path: string) {
    return pushRoute.match(addStartingSlashIfMissing(path))
}

const pushItemRoute = new Route(`/${PUSH}/${PUSH_ITEM}`);
export function pushItemPath(pushId: string) {
    return pushItemRoute.reverse({ pushId });
}
export function matchPushItemRoute(path: string) {
    return pushItemRoute.match(addStartingSlashIfMissing(path));
}

const hotUpdates = new Route(`/${HOTUPDATES}`);
export function matchHotUpdatesPath(path: string) {
    return hotUpdates.match(addStartingSlashIfMissing(path))
}

const realTime = new Route(`/${REAL_TIME}`);
export function matchRealTimePath(path: string) {
    return realTime.match(addStartingSlashIfMissing(path))
}

const articleReport = new Route(`/${ARTICLE_REPORT}`);
export function matchArticleReportPath(path: string) {
    return articleReport.match(addStartingSlashIfMissing(path))
}

const akumAndPopularityReports = new Route(`/${AKUM_AND_POPULARITY_REPORTS}`);
export function matchAkumAndPopularityReportsPath(path: string) {
    return akumAndPopularityReports.match(addStartingSlashIfMissing(path))
}

const yokra = new Route(`/${YOKRA}`);
export function matchYokraPath(path: string) {
    return yokra.match(addStartingSlashIfMissing(path))
}

const configFileEditor = new Route(`/${CONFIG_FILE_EDITOR}`);
export function matchConfigFilePath(path: string) {
    return configFileEditor.match(addStartingSlashIfMissing(path))
}

const articleBodyAdsConfig = new Route(`/${ARTICLE_BODY_ADS_CONFIG}`);
export function matchArticleAdsManagerPath(path: string) {
    return articleBodyAdsConfig.match(addStartingSlashIfMissing(path))
}

const writesSalaryReports = new Route(`/${WRITERS_SALARY_REPORTS}`);
export function matchWritesSalaryReportPath(path: string) {
    return writesSalaryReports.match(addStartingSlashIfMissing(path))
}
