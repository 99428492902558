import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { BaseLinkTypes, LinkProperties, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { MynetMenuComponenta } from "./mobileMenu"
import { CSSTransitionGroup } from "react-transition-group"

export interface SiteList {
    name: string
    link: string
    linkTarget: LinkTargetProperties
    url?: string
    subSite?: string
    mainSiteSubNav: SubNavItem[]
}
export interface SubNavItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
}
interface SiteMynetHeaderProps {
    mainSite: SiteList
    siteList: SiteList[]
    isIndependedHeader?: boolean
    changingFieldText: string
    changingFieldLink: LinkProperties<BaseLinkTypes>
    changingFieldLinkTarget: LinkTargetProperties
    changingFieldColor: string
}
interface SiteMynetHeaderState {
    isMenuOpen: boolean
    isCitiesOpened: boolean
}
export class SiteMynetHeaderMobileComponenta extends React.Component<SiteMynetHeaderProps, SiteMynetHeaderState> {
    public static siteScriptName = "SiteMynetHeaderMobileComponenta";
    constructor(props) {
        super(props)
        this.state = { isMenuOpen: null, isCitiesOpened: false };
    }
    private openMenu = () => {
        this.setState({ isMenuOpen: true })
    }
    private closeMenu = () => {
        this.setState({ isMenuOpen: false })
    }
    private openCitiesList = () => {
        this.setState({ isCitiesOpened: true })
    }
    private closeCitiesList = () => {
        this.setState({ isCitiesOpened: false })
    }
    render() {
        const { mainSite, siteList, isIndependedHeader, changingFieldText, changingFieldLink, changingFieldLinkTarget, changingFieldColor } = this.props
        const { isMenuOpen, isCitiesOpened } = this.state

        return (
            <div className="MynetHeaderMobileComponenta">
                <div className="mainHeader">
                    <button className="menuBtn btn" title="פתיחת תפריט" onClick={this.openMenu} />
                    <div className="logo">
                        <HyperLinkBuilder href={mainSite.link} ariaLabel={mainSite.name} linkTarget={mainSite.linkTarget}>
                            <SiteSimpleImage src={mainSite.url} alt={mainSite.name} title={mainSite.name} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>
                    </div>
                    <a href={"javascript:history.back()"} className="goBackBtn" title="back" />
                </div>
                <MynetMenuComponenta
                    mainSite={mainSite}
                    siteList={siteList}
                    onClose={this.closeMenu}
                    onOpenCities={this.openCitiesList}
                    onCloseCities={this.closeCitiesList}
                    isCitiesOpened={this.state.isCitiesOpened}
                    isOpen={isMenuOpen}
                    isIndependedHeader={isIndependedHeader}
                    changingFieldText={changingFieldText}
                    changingFieldLink={changingFieldLink}
                    changingFieldLinkTarget={changingFieldLinkTarget}
                    changingFieldColor={changingFieldColor} />
            </div>
        )

    }
}