import { SiteTickerData } from "./tickerComponenta"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext"
import { PageComponentaContext } from "widgets/interfaces"
import { ArticleShareButtons } from "../../../../siteWidgets/socialShareButtons/ArticleShareButtons/ArticleShareButtons"


interface TickerPopUpContentProps {
    item: SiteTickerData
    onClickNext: () => void
    onClickBack: () => void
    selectedIndex: number
    canNavigateBack: boolean
    canNavigateNext: boolean
}

@injectPageComponentaContext()
export class TickerPopUpContent extends React.Component<TickerPopUpContentProps & Partial<PageComponentaContext>, {}>{

    private popUpContainerDiv: HTMLDivElement;




    render() {
        const { item, selectedIndex, onClickNext, onClickBack, getImage, canNavigateBack, canNavigateNext } = this.props
        let media;
        const image = item.itemImg || null
        const arrowNext = "swipeLeft"
        const arrowPrev = "swipeRight"
        const titlePrev = __("Prev. Flash")
        const titleNext = __("Next Flash")
        return (
            <div className="flashContainer">
                <div className="topArea">
                    <div className="imgArea" />

                    <div className="titleArea">
                        <div className="flashTitle" dangerouslySetInnerHTML={{ __html: item.title }} />
                        <div className="flashDate">
                            {separateHourFromDateAndFormatToString(item.flashDate, { dateTimeSeperator: " | ", showDate: true, showHour: true })}
                        </div>

                    </div>
                    <ArticleShareButtons
                        url={item.link}
                        className="tickerShareBtns"
                        isCopyButtonVisible={false}
                        lang="he"
                    />
                </div>
                <div className="Separator" />
                <div className="flashText">
                    <span>{item.flashText}</span>
                </div>
                <div className="flashFooter">
                    {item && <div className="itemArrows">
                        {canNavigateNext && <button className={`navigationArrow ${arrowNext}`}
                            title={titleNext}
                            onClick={e => onClickNext()}>{titleNext}</button>}

                        {canNavigateBack && <button className={`navigationArrow ${arrowPrev}`}
                            title={titlePrev}
                            onClick={e => onClickBack()}>{titlePrev}</button>}
                    </div>}
                </div>

            </div>)


    }
}
