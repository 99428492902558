require("./ynet_pplus_magazine_site_siteArticleSocialShare1280.less")
require("./ynetnews_vesty_site_articleShareBtns1280.less")
require("./radionas_site_articleShareBtns1280.less")
import { openFacebookShare, openTwitterShare, analyticsPushFunc, openTelegramShare } from "../../../../widgetsUtils"
import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { WhatsAppShareButton } from "../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"
import { getLinkBySubsite } from "../../../../../front/staticServerRendering/contactParagraphInjection";
import { ShareButtonsDropdown } from "./articleDropdownShareComponenta";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { FrontCopyButton } from "../../../../commonComponents/inline/frontCopyButton/frontCopyButton";
const clickOutside = require('react-click-outside');
const ON_WCM_COMMENTS_RECIVED = "onWcmCommetnsRecived";

interface shareState {
    isMenuOpened: boolean
    wcmNumOfComments:string
}
interface shareProps {
    categorySubChannel: string
    author: string
    articleId: string
    isBottomButtons?: boolean
    showComments: boolean
    isSpotim: boolean
    lang?: string
    verticalSidebar?: boolean
    title?: string
}
export function getTalkbackNumberView(tbNumber: string) {
    let number = tbNumber
    if (tbNumber.length > 3) {
        number = tbNumber.charAt(0) + "k"
    }
    else if (parseInt(tbNumber) === 0) {
        number = "";
    }
    return number

}
@clickOutside
export class SiteArticleSocialShareNew1280 extends React.Component<shareProps, shareState>{
    static siteScriptName = "SiteArticleSocialShareNew1280"
    constructor() {
        super();
        this.state = { isMenuOpened: null,
            wcmNumOfComments:''
        }
    }

    private handleClickOutside = () => {
        this.setState({
            isMenuOpened: false
        });
    }

    
  componentDidMount() {
    document.addEventListener(ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
  }
  componentWillUnmount(): void {
    document.removeEventListener(ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
  }
  private handleOnWcmCommentsRecived = (e) => {
    this.setState({ wcmNumOfComments: e.detail.wcmNumOfComments });
  };
    
    private handleShareBtnClicked = (button: string) => {
        const { verticalSidebar, title, lang } = this.props;
        this.setState({ isMenuOpened: false })
        const langToUse = lang.slice(0, 2);
        if (typeof window !== "undefined") {
            const current_url = window.location.href;
            const analyticsCategory = verticalSidebar ? "Article Side" : "Article Top";

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", analyticsCategory, title), analyticsCategory)
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", analyticsCategory, title), analyticsCategory)
                    break;
                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, langToUse, () => analyticsPushFunc("Mail", analyticsCategory, title), analyticsCategory)
                    break;
                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", analyticsCategory, title), analyticsCategory)
                    break;
                case "tg":
                    openTelegramShare(current_url, () => analyticsPushFunc("Telegram", analyticsCategory, title), analyticsCategory)
                    break;
                case "print":
                    window.print();
                    break;

                case "tb":
                    const newCommentBtn = document.querySelector(".newCommentBtn") as HTMLElement
                    if (newCommentBtn) {
                        newCommentBtn.click();
                    }
                    const elementToScroll = document.querySelector('#SiteArticleComments')
                    const commentsPopUp = document.querySelector('.spotimWrapper')
                    if (elementToScroll) {
                        setTimeout(() => {
                            elementToScroll.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }, 0)
                    }
                    if (commentsPopUp) {
                        toggleSpotImPopUp(commentsPopUp)
                    }
                    break;

            }
        }
    }
    private toggleMenu = () => {
        if (this.state.isMenuOpened === null) {
            this.setState({ isMenuOpened: true })
        }
        else this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }
    private constructContactFormUrl = () => {
        const { categorySubChannel, author, articleId } = this.props
        return `/Ext/Comp/ArticleLayout/CdaSendArticleResponse/0,12358,L-${categorySubChannel},00.html?author=${author}&articleID=${articleId}`
    }

    private openContactPopup = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
        const newwindow = window.open(this.constructContactFormUrl(), __("Article response"), 'height=543,width=380');
        if (window.focus) { newwindow.focus() }
        return false;
    }
    render() {
        const { isBottomButtons, showComments, articleId, isSpotim, lang, verticalSidebar, title } = this.props
        const {wcmNumOfComments} = this.state;
        const En = lang.includes("en");
        const Ru = lang.includes("ru");
        const he = lang.includes("he");
        const Ar = lang.includes("ar");
        const langToUse = lang.slice(0, 2);
        let textMail = "שלחו כתבה";
        let textTw = "שיתוף בטוויטר";
        let textFb = "שיתוף בפייסבוק";
        let textWa = "שיתוף בואטסאפ";
        let textTg = "שיתוף בטלגרם";
        let textMore = "עוד";
        let addCommentTxt = "הוספת תגובה";
        let commets = "תגובות"
        if (En) {
            textMail = "Send Email";
            textTw = "Twitter";
            textFb = "Facebook";
            textWa = "Whatsapp";
            textMore = "More";
            addCommentTxt = "Add a comment";
            commets = "comments";
            textTg = "Telegram";
        }
        if (Ru) {
            textMail = "Эл. почта";
            textTw = "Твиттер";
            textFb = "Фейсбук";
            textWa = "Ватсап";
            textMore = "Ещё";
            addCommentTxt = "Обсудить";
            commets = "Комментарии: ";
            textTg = "Телеграм";
        }
        if(Ar){
            textMail = "ارسل بريد الكتروني";
            textTw = "Twitter";
            textFb = "Facebook";
            textWa = "Whatsapp";
            textMore = "أكثر";
            addCommentTxt = "اضف تعليق";
            commets = "التعليقات";
            textTg = "Telegram"; 
        }
        const isBottomClassName = isBottomButtons ? "bottomBtns" : "";
        const commentNumber = getTalkbackNumberView(wcmNumOfComments ? wcmNumOfComments.toString() : "")
        const txtbylang = Ru ? `${commets} ${commentNumber}` : `${commentNumber} ${commets}`
        const ourCmntTxt = <span>{commentNumber.length > 0 ? txtbylang : addCommentTxt}</span>
        const spotCounter = verticalSidebar? null :  <span id="spotimCmtCounter" />
        const tbText = isSpotim ? spotCounter : ourCmntTxt;
        const shareclassName = "SiteArticleSocialShareNew1280";
        return (
            <div className={`${shareclassName} ${isBottomClassName} ${langToUse}`}>
                {showComments && <button onClick={e => this.handleShareBtnClicked("tb")} className="addNewComment tb" id="addNewComment-header" title={addCommentTxt}>
                    <span className="shareIcon1280" />
                    <span className="verticalSidebarCommentNumber">{commentNumber}</span>
                    {tbText}
                </button>}
                <button title={textWa} className="shareIcon1280 wa" onClick={e => this.handleShareBtnClicked("wa")}></button>
                <button title={textFb} className="shareIcon1280 fb" onClick={e => this.handleShareBtnClicked("fb")}></button>
                <button title={Ru ? textTg : textTw} className={`shareIcon1280 ${Ru ? "tg" : "tw"}`} onClick={e => this.handleShareBtnClicked(Ru ?"tg" : "tw")}></button>
                <button title={textMail} className="shareIcon1280 mailto" onClick={e => this.handleShareBtnClicked("mailto")}></button>
                {he && <FrontCopyButton popupText="הקישור הועתק ללוח" title={title} />}
              {!Ar &&  <button title={textMore} className="shareIcon1280 more" onClick={this.toggleMenu}></button>}
                <ShareButtonsDropdown isMenuOpened={this.state.isMenuOpened}
                    onPrint={e => this.handleShareBtnClicked("print")}
                    findErrorLink={getLinkBySubsite("ynet",articleId )}
                    openContactPopup={() => this.openContactPopup()}
                    lang={langToUse} />
            </div>
        )
    }
}

export const toggleSpotImPopUp = (popupComment: Element) => {
    if (!popupComment.classList.contains("isOpened") && !popupComment.classList.contains("isClosed")) {
        setTimeout(() => {
            disablePageRefresh();
            popupComment.classList.add("isOpened")
        }, 0)

    }
    else if (popupComment.classList.contains("isClosed")) {
        setTimeout(() => {
            disablePageRefresh();
            popupComment.classList.replace("isClosed", "isOpened")
        }, 0)

    }
    else if (popupComment.classList.contains("isOpened")) {
        setTimeout(() => {
            enablePageRefresh();
            popupComment.classList.replace("isOpened", "isClosed")
        }, 0)

    }
}