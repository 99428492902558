import { SiteVideoData } from "../../../../interfaces"
import { generateVideoId, shouldAutoPlayVideo, attachPageRefrashUtils, attachPageRefreshUtilsYoutube } from "../../videoPlayerUtils"
import { isMobile } from "../../../../../utils/isMobile"
import { attachStickinessYouTubeAPI } from "../../youtubeStickiness"
import * as classNames from "classnames"
import { attachYoutubeAnalytics } from "../../youtubePlayer/utils";
import { getPlayerAdIu, getCustomParmsString, getNumberOfViews } from "../../videoPlayerAds";
require("../../site_videoPlayer.less")

const YT_DEFAULT_ASPECT_RATIO = 16 / 9;

interface YoutubePlayerForInlineProps {
    data: SiteVideoData
    width?: number
    height?: number
    runArticleAutoPlayLogic?: boolean
    applyStickyness: boolean
    onPlayStarted?: () => void
    onPlayStopped?: () => void
}

interface YoutubePlayerForInlineState {
    isMuted?: boolean
    viewMode?: "mobile" | "desktop"
}

export class YoutubePlayerForInline extends React.Component<YoutubePlayerForInlineProps, YoutubePlayerForInlineState>{
    private player: YT.Player; // YT player instance
    public youtubePlayerAnchor; // player DOM ref
    public youtubePlayerContainerAnchor; // player container DOM ref
    private playerTagID = generateVideoId();

    public constructor(props: YoutubePlayerForInlineProps) {
        super(props)
        this.state = {
            isMuted: props.data.isMuteAvailable,
        }
        this.handleMuteClicked = this.handleMuteClicked.bind(this);
    }

    componentDidMount() {
        window.YoutubeAPIControlller.load(this);
        this.setViewMode()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.player.loadVideoById(nextProps.data.youtube_id)
        }
    }

    public setViewMode = () => {
        this.setState({
            viewMode: isMobile() ? "mobile" : "desktop"
        })
    }

    private executeCurrentStopAttachedToWindow = () => {
        window.stopCurrentPlayingPlayer &&
            (window.stopCurrentPlayingPlayer !== this.stopPlayer) &&
            window.stopCurrentPlayingPlayer()
    };

    private attachStopToWindowObj = () => {
        this.executeCurrentStopAttachedToWindow()
        window.stopCurrentPlayingPlayer = this.stopPlayer
        this.props.onPlayStopped && this.props.onPlayStopped()
    }

    private stopPlayer = () => {
        this.player.pauseVideo()
        this.player.stopVideo()
        this.props.onPlayStopped && this.props.onPlayStopped()

    }

    private onPlayerReady = (e) => {
        const { data, runArticleAutoPlayLogic, applyStickyness } = this.props
        const player = e.target;

        attachYoutubeAnalytics(this, data.youtube_id);

        if (data.isMuteAvailable) {
            player.mute()
        }
        const shouldAutoPlay = (!runArticleAutoPlayLogic && data.isAutoPlaying) || shouldAutoPlayVideo(this.youtubePlayerContainerAnchor, data.isAutoPlaying);

        if (shouldAutoPlay) {
            player.playVideo();
            this.props.onPlayStarted && this.props.onPlayStarted()
        }

        applyStickyness ? attachStickinessYouTubeAPI(this.player, this.youtubePlayerContainerAnchor, `url(${this.props.data.poster})`) : null;
        attachPageRefreshUtilsYoutube(this.player)
    }

    private onPlayerStateChange = (event) => {
        const player = event.target as YT.Player
        if (event.data == YT.PlayerState.PLAYING) {
            this.props.onPlayStarted && this.props.onPlayStarted()
            this.attachStopToWindowObj();
            if (currentPlayingYoutubePlayer && (currentPlayingYoutubePlayer != player)) {
                // Pause other videos on play
                currentPlayingYoutubePlayer.pauseVideo();
                this.props.onPlayStopped()
            }
            currentPlayingYoutubePlayer = player;
        }
    }

    public createPlayer = () => {
        const { height, width, data } = this.props;
        const { viewMode } = this.state;
        this.player = new YT.Player(this.youtubePlayerAnchor, {
            videoId: data.youtube_id,
            height: height || this.getHeight(),
            width: width || this.getWidth(),
            embedConfig: {
                adsConfig: {
                    adTagParameters: {
                        iu: getPlayerAdIu(),
                        cust_params: getCustomParmsString("Preroll", window.dcPath, window.video_ad_yncd, getNumberOfViews(), data.isAutoPlaying, shouldAutoPlayVideo(this.youtubePlayerContainerAnchor, data.isAutoPlaying), "pfp"),
                        pmnd: 5000,
                        pmxd: 40000,
                        pmad: 2
                    }
                }
            },
            playerVars: {
                autoPlay: data.isAutoPlaying && shouldAutoPlayVideo(this.youtubePlayerContainerAnchor, data.isAutoPlaying) ? 1 : 0,
                loop: data.isLoopPlaying ? 1 : 0,
                playlist: data.isLoopPlaying ? data.youtube_id : null, // Required for loop (see https://developers.google.com/youtube/player_parameters#loop).
                controls: data.isPlayerBarVisible ? 1 : 0,
                showinfo: 0,
                rel: 0,
                modestbranding: viewMode == "mobile" ? 1 : 0
            } as YT.PlayerVars,
            events: {
                onReady: this.onPlayerReady,
                onStateChange: this.onPlayerStateChange,
            } as YT.Events
        });
    }

    private getWidth() {
        let width;
        if (this.youtubePlayerContainerAnchor) { width = this.youtubePlayerContainerAnchor.getBoundingClientRect().width; }
        else width = null
        return width;
    }

    private getHeight = () => {
        return Math.floor(this.getWidth() / YT_DEFAULT_ASPECT_RATIO);
    }

    private handleMuteClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
        this.setState({ isMuted: !this.player.isMuted() }, () => this.player.isMuted() ? this.player.unMute() : this.player.mute());
    }

    render() {
        const { data } = this.props;
        const muteButtonClassName = classNames("youtube_external_mute", { "muted": this.state.isMuted });
        return (
            <div ref={ref => this.youtubePlayerContainerAnchor = ref} id={this.playerTagID} className={"youTubePlayer"}>
                <div ref={ref => this.youtubePlayerAnchor = ref} />
                {data.isSoundButtonVisible && !data.isPlayerBarVisible && <div className={muteButtonClassName} onClick={this.handleMuteClicked} />}
            </div>
        )
    }
}