interface YnetLiveFloatingPlayerProps {
    isMobileWeb: boolean
    isAudioPlaying: boolean
    isFloatingPlayerVisible: boolean
    floatingPlayerBottomHeight: number
    onClose: () => void
    children?: any
    title: string
    onTogglePlayPause: () => void
}
interface YnetLiveFloatingPlayerState {
    isTitleOverFlowing: boolean
    programTitleWidth: string
}
const MUTAM_SCREEN_WIDTH = 640;
export class YnetLiveFloatingPlayer extends React.Component<YnetLiveFloatingPlayerProps, YnetLiveFloatingPlayerState> {
    constructor(props) {
        super(props)
        this.state = {
            programTitleWidth: "0vw",
            isTitleOverFlowing: null
        }
    }
    private pxToVw = (titleOffsetWidth: number) => {
        const calculatedWidth = (100 * titleOffsetWidth) / MUTAM_SCREEN_WIDTH
        return calculatedWidth + "vw"
    }

    private calculateIsTitleOverFlowing = (ref: HTMLDivElement) => {
        const { isMobileWeb } = this.props

        if (ref) {
            const wrapperElement = document.querySelector(".floating-player-title-wrapper") as HTMLElement
            const titleOverflow = wrapperElement ? ref.offsetWidth > wrapperElement.offsetWidth : false
            this.setState({
                isTitleOverFlowing: titleOverflow,
                programTitleWidth: isMobileWeb ? this.pxToVw(ref.offsetWidth) : ref.offsetWidth + "px"
            });
        }
    }
    render() {
        const { isAudioPlaying, isFloatingPlayerVisible, isMobileWeb, onClose, title, onTogglePlayPause, floatingPlayerBottomHeight } = this.props;
        const { isTitleOverFlowing, programTitleWidth } = this.state
        const mainTitle = isMobileWeb ? "האזינו לשידור" : "עכשיו בשידור";
        const progressBar = this.props.children;
        const mobileBanner = document.getElementById("ads.banner");
        const mobileStyle = mobileBanner ? mobileBanner.clientHeight : 65;
        const noShowsText = 'אין תוכנית לנגן'
  
        return (
            <div className={`ynet-live-floating-player ${isFloatingPlayerVisible ? "show" : "hide"}`} style={{ bottom: `${isMobileWeb ? mobileStyle : floatingPlayerBottomHeight}px` }}>

                <div className="floating-player-content">
                    <div className="floating-player-show-details">
                        <button className="floating-player-button" onClick={onTogglePlayPause} aria-label={isAudioPlaying ? `pause - ${title}` : `play - ${title}`}>
                            <div className={`floating-player-button-status ${isAudioPlaying ? "pause" : "play"}`} ></div>
                        </button>
                        {progressBar}
                        <img className="floating-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />
                    </div>
                    <div className="floating-player-show-title-wrapper">
                        <div className="floating-player-show-main-title">{mainTitle}</div>
                        <div className="separator"></div>

                        {!isMobileWeb ?
                            <div className="floating-player-show-title">{title || 'אין תוכנית לנגן'}</div> :
                            <div className="floating-player-title-wrapper">
                            {(isTitleOverFlowing ?
                                <>
                                    <div className="fadder-right"></div>
                                    <div className="slider-wrapper">
                                    <div className={"floating-player-show-title"} style={{ "--titleLength":`${title.length*0.2}s`}} dangerouslySetInnerHTML={{ __html: title || noShowsText }}></div>
                                          
                                            <div className={"floating-player-show-title"} aria-hidden   style={{ "--titleLength":`${title.length*0.2}s`}} dangerouslySetInnerHTML={{ __html: title || noShowsText }}></div>
                                       
                                    </div>
                                    <div className="fadder-left"></div>
                                </> :
                                <div className={"floating-player-show-title"} ref={this.calculateIsTitleOverFlowing} dangerouslySetInnerHTML={{ __html: title || noShowsText }}></div>
                            )}</div>}
                    </div>
                </div>
                <button className="floating-player-close-sign" onClick={onClose} aria-label="close player"></button>
            </div>
        )
    }
}