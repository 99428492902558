import { AudioPlayButton, AudioRendererProps, MediaMetadata } from "./audioPlayButton";
import { AodFloatingPlayer } from "./aodFloatingPlayer";
import { ArticleData } from "../../../../articlePage/stateInterfaces";

interface PodcastPlayerProps {
    audioUrl: string
    title: string
    durationSeconds: number
    className: string
    isMobileWeb?: boolean
    browserDisplayMediaMetaData?: MediaMetadata
    articleLink?: string
    articleData?: ArticleData
}

export function PodcastPlayer(props: PodcastPlayerProps) {
    const { className, title, isMobileWeb, articleLink, articleData } = props;

    return (
        <AudioPlayButton
            {...props}
            isLive={'false'}
            articleData={articleData}
            renderer={(props: AudioRendererProps) => (<ButtonRnederer {...props} className={className} />)}
            floatingPlayerRenderer={(props) => (<AodFloatingPlayer {...props} title={title} isMobileWeb={isMobileWeb} articleLink={articleLink} />)}
        />
    )
}

PodcastPlayer.siteScriptName = "PodcastPlayer"

export function ButtonRnederer({ isAudioPlaying, onTogglePlayPause, className }: AudioRendererProps & { className: string }) {
    return (
        <button className={className} onClick={onTogglePlayPause} aria-label={isAudioPlaying ? "pause" : "play"} >
            <div className={`audio-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
        </button>
    );
}