import * as map from "lodash/map"
import * as filter from "lodash/filter"
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"
import { ImageDetails } from "images/interfaces"
import { PplusLoadMoreList } from "./pplusLoadMoreList";
import { CategorySelectionItem } from "../../../../../global/components/categorySelectInput/categorySelectInput";
import { getImagesSrc } from "widgets/commonComponents/inline/siteSimpleImage"

require('./pplus_site_loadMore.less')
require('./m_pplus_loadMore.less')

export interface SitePplusLoadMoreData {
    articleId: string
    author: string
    credit: string
    categoryName: string
    title: string,
    subTitle: string,
    link: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean
    publishedLink: string
    premiumExpireDays: number
    category: CategorySelectionItem
    dateUpdatedOnSite: Date
    firstAuthorImage: string
}

interface InfinityHeadlinesViewProps {
    height: number
    articles: ArticleExtraData[]
    isCategoryVisible: boolean
    loader: boolean
    isMobileWeb?: boolean
    imagesDomain?:string
}

export class PplusLoadMoreView extends React.Component<InfinityHeadlinesViewProps, {}>  {

    public render() {
        const { height, articles, isCategoryVisible, isMobileWeb, imagesDomain } = this.props
        let itemList: SitePplusLoadMoreData[] = [];

        if (Object.keys(articles).length > 0) {
            itemList = filter(map(articles, (article: ArticleExtraData): SitePplusLoadMoreData =>
                ({
                    articleId: article.articleId,
                    author: article.author,
                    credit: article.promotionImageDetails ? article.promotionImageDetails.credit : null,
                    categoryName: article.category.textToShow,
                    title: article.title,
                    subTitle: article.subTitle,
                    link: article.publishedLink,
                    promotionImageDetails: article.promotionImageDetails,
                    hasVideo: article.hasVideo,
                    publishedLink: article.publishedLink,
                    premiumExpireDays: article.premiumExpireDays,
                    category: article.category,
                    dateUpdatedOnSite: article.dateUpdatedOnSite,
                    firstAuthorImage: getImagesSrc(imagesDomain, article.firstAuthorImage)
                })
            ))
        }

        return (
            <PplusLoadMoreList
                dataLength={articles.length}
                itemList={itemList}
                height={height}
                isCategoryVisible={isCategoryVisible}
                isMobileWeb={isMobileWeb}
            />
        )
    }
} 