
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import * as axios from "axios"
import { getNewlinkTargetProperties } from "widgets/widgetsUtils"


declare var window: Window & {
    googletag: any;
    AdUnitPropertiesClassObj: any;
}

interface CalcalistTwentyFourSevenWideMobileProps {
    numberOfItems: number
}

interface CalcalistTwentyFourSevenWideMobileState {
    currChannle: any
    articles: CalcalistTwentyFourSevenWideMobileItem[]
}

interface CalcalistTwentyFourSevenWideMobileItem {
    articleId: string
    dateUpdatedOnSite: string
    title: string
    publishedLink: string
}

export class CalcalistTwentyFourSevenWideMobile extends React.Component<CalcalistTwentyFourSevenWideMobileProps, CalcalistTwentyFourSevenWideMobileState> {
    public static siteScriptName = "CalcalistTwentyFourSevenWideMobile";
    constructor(props) {
        super(props)
        this.state = {
            currChannle: { description: 'כל הערוצים', id: '1' },
            articles: []
        }
    }

    componentDidMount() {
        this.getArticles()
    }

    private getArticles = () => {
        const { numberOfItems } = this.props;
        const { currChannle } = this.state
        const limitDateRequest = formatDate(new Date(), "YYYY-MM-DD");

        axios.get(`/iphone/json/api/twenty_four_seven_wide/${currChannle.id}/${numberOfItems}/1/0/0/${limitDateRequest}`)
            .then((res: any) => {
                const recivedArticles = res.data.data;
                this.setState({
                    articles: recivedArticles,
                }, () => this.onDfpRefresh())
            })
            .catch(err => console.error("error geting articles data:", err))
    }


    private onDfpRefresh = () => {
        if (window.googletag && window.AdUnitPropertiesClassObj && window.AdUnitPropertiesClassObj.Define_slots["flash.central"]) {
            window.googletag.cmd.push(function () { window.googletag.pubads().refresh([window.AdUnitPropertiesClassObj.Define_slots["flash.central"]]); });
        }
    }

    render() {
        const { articles } = this.state;
        const linkTarget = getNewlinkTargetProperties();
        return (
            <div className="CalcalistTwentyFourSevenWideMobile">
                {_.map(articles, item => {
                    const { articleId, dateUpdatedOnSite, title, publishedLink } = item;
                    return (
                        <HyperLinkBuilder key={articleId} href={publishedLink} linkTarget={linkTarget} className="flash-item">
                            <DateDisplay hiddenYesterday date={dateUpdatedOnSite} />
                            <div className="content">{title}</div>
                        </HyperLinkBuilder>
                    )
                })}
            </div>
        )
    }
}