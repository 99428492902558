import Slider from "react-slick"
import { imageListGalleryCalcalist } from "./articleImageSliderEditor";

interface MobileArticleImagesGalleryProps {
    images: imageListGalleryCalcalist[]
    articleId: string
    articleTitle: string
    onClose:()=>void

}
interface MobileArticleImagesGalleryState {
    currentImageIndex: number
  
}

export class MobileArticleImagesPopUp extends React.Component<MobileArticleImagesGalleryProps, MobileArticleImagesGalleryState>{
    constructor(props) {
        super(props)
        this.state = {
            currentImageIndex: 0,
            
        }
    }


    componentDidMount() {
       

    
    }

    public swipeRight = (): void => {
        const lastImageIndex = this.props.images.length - 1
        const { currentImageIndex } = this.state
        const nextImageIndex = currentImageIndex === 0 ? lastImageIndex : currentImageIndex - 1
        analystFunc("Browse Right", this.props.articleId)
        this.setState({ currentImageIndex: nextImageIndex })
    }

    public swipeLeft = (): void => {
        const lastImageIndex = this.props.images.length - 1
        const { currentImageIndex } = this.state
        const nextImageIndex = currentImageIndex === lastImageIndex ? 0 : currentImageIndex + 1
        analystFunc("Browse Left", this.props.articleId)
        this.setState({ currentImageIndex: nextImageIndex })
    }

    private close = (): void => {
        analystFunc("Close Gallery", this.props.articleId)
       this.props.onClose();
    }







    render() {
        const { images } = this.props
        const { currentImageIndex } = this.state
        const settings = {
            infinite: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            arrows: true,
            rtl: false,
            touchMove: true,
            initialSlide: currentImageIndex,
            afterChange: (next) => this.setState({ currentImageIndex: next }),
        }
        let numOfImages = images.length ? images.length : 0
        const isImageGotCaption = images && images[currentImageIndex] && images[currentImageIndex].caption
        const isImageGotCredit = images && images[currentImageIndex] && (images[currentImageIndex].credit !== "") && (images[currentImageIndex].credit !== " ")

         return (
            <div key={"MobileArticleImagesGallery"} role="dialog"
                aria-label="images gallery"
                id="images_gallery" className="MobileArticleImagesGallery"
                onClick={close} tabIndex={0}>

                <div className="GalleryWrapper" onClick={e => e.stopPropagation()}>
                    <div className={`galleryHeaderWrapper`}>
                        <button className="closeBtn yicon-x"
                            title={__("Close")} aria-label={__("Close")} onClick={this.close} />
                        {numOfImages > 1 && <div className="imagesCounter">
                            <span className="current">{currentImageIndex + 1 < 10 ? `${currentImageIndex + 1}` : currentImageIndex + 1}/</span>
                            <span className="totalNumOfImages">{numOfImages < 10 ? `${numOfImages}` : numOfImages}</span>
                        </div>}
                       
                    </div>
                    <Slider {...settings} >
                        {_.map(images, (image) =>
                            <div className="imageContainer" key={image.imageId}>
                                <img src={image.imageSrc} role="image" aria-label={image.caption} />
                            </div>
                        )}
                    </Slider>
                    <div className={`imageInfoAndShareOptions`}>
                        <div className="imageInfo">
                            {isImageGotCaption && <div className="caption">{images[currentImageIndex].caption}</div>}
                            {isImageGotCredit && <div className="credit">({images[currentImageIndex].credit})</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
       
    }
}

export const analystFunc = (action: string, articleId: string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'GA_Event',
        Category: 'Photo Gallery',
        Action: action,
        Label: articleId
    });
}

