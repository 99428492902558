
require("../calcalist_site_generalCommercialTeaserComponenta.less");
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"

import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { ContentSiteItemData } from "./generalCommercialTeaserComponenta";
import { SiteMediaData } from "../../../../interfaces";
import { MediaContentTypes } from "constants/contentTypes"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu"
import { SiteImageData } from "widgets/interfaces"
import { calculateShouldAddArticleAutoplayIndicator, separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { convertHtmlToString } from "../../../../../config/utils"


interface GeneralCommercialTeaserFrontProps {
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    tabBackgroundImage: string
    imageWidth: number
    imageHeight: number
    itemList: ContentSiteItemData[]
    fieldsDisplaySettings: any
    videoSettings: LinkedItemVideoSettings
    numberOfVisibleItems: number
    bottomLinkText: string
    tabTitleText: string
    tabSubTitleText: string
    commercialText: string
    gallerySeconds: number
    marginColor?: string
    titleFontSize?: string
    displayType?: string
}
interface CommertialTeaserFrontState {
    fromItemIndex: number
}


export class GeneralCommercialTeaserFront extends React.Component<GeneralCommercialTeaserFrontProps, CommertialTeaserFrontState>{
    public static siteScriptName = "GeneralCommercialTeaserFront"
    constructor(props) {
        super(props);
        this.state = {
            fromItemIndex: 0,
        }
    }
    componentDidMount() {
        if (this.props.itemList.length > 2) {
            this.startAutoGallery();
        }

    }
    private nextItems = () => {
        this.setState((prevState: CommertialTeaserFrontState) => {
            return { fromItemIndex: prevState.fromItemIndex + 2 }
        })

    }
    private prevItems = () => {
        this.setState((prevState: CommertialTeaserFrontState) => {
            return { fromItemIndex: prevState.fromItemIndex - 2 }
        })
    }
    private autoGalleryInterval: any;
    private startAutoGallery = () => {
        const { gallerySeconds, itemList } = this.props
        const self = this;
        if (typeof (gallerySeconds) === "number") {
            if (gallerySeconds > 0) {
                this.autoGalleryInterval = setInterval(() => {
                    this.setState((prevState: CommertialTeaserFrontState, props: GeneralCommercialTeaserFrontProps) => {
                        let newIndex = prevState.fromItemIndex + 2;
                        if (newIndex >= props.itemList.length - 1) newIndex = 0;
                        return { fromItemIndex: newIndex }
                    });
                }, gallerySeconds * 1000);
            }
            else {
                clearInterval(this.autoGalleryInterval)
            }
        }
    }

    render() {
        const { tabLink, tabLinkTarget, tabBackgroundImage, imageWidth, imageHeight, itemList, fieldsDisplaySettings, videoSettings, numberOfVisibleItems,
            bottomLinkText, tabTitleText, tabSubTitleText, commercialText, marginColor, titleFontSize, displayType } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const isMore = fieldsDisplaySettings.isAuthorVisible || fieldsDisplaySettings.isHourVisible || fieldsDisplaySettings.isDateVisible
        const isComma = fieldsDisplaySettings.isAuthorVisible && (fieldsDisplaySettings.isHourVisible || fieldsDisplaySettings.isDateVisible)

        return (
            <div className={`GeneralCommertialTeaserFront ${displayType}`} >

                <HyperLinkBuilder className="tabTitleLink"
                    title={tabTitleText}
                    href={tabLink}
                    linkTarget={tabLinkTarget}>
                    {commercialText && <span className="commercialText">{commercialText}</span>}
                    <span className="tabTitle" style={{ fontSize: `${titleFontSize}px`, color: marginColor }}>{tabTitleText}</span>
                    {tabSubTitleText && <span className="tabSubTitle" style={{color: marginColor }}>{tabSubTitleText}</span>}
                </HyperLinkBuilder>

                <div className="slotList">
                    {itemList.map((item, index) => {
                        const shouldAddArticleAutoplayIndicator = calculateShouldAddArticleAutoplayIndicator(item.icon, item.media);
                        if (index < this.state.fromItemIndex || index > this.state.fromItemIndex + 1) return
                        return (
                            <div className={`slotView`} key={index} style={{ width: imageWidth, background: `linear-gradient(to bottom, ${marginColor} 90px, transparent 0%)` }}>
                                <div className="mediaArea CalcalistComponentaShareMenuMedia">
                                    <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                        <SiteMedia
                                            allMedias={[]}
                                            data={item.media !== undefined ? item.media : emptyMedia}
                                            width={imageWidth}
                                            height={imageHeight}
                                            videoSettings={videoSettings}
                                            itemLinkUrl={item.titleLink}
                                            isImageLinkable={true}
                                            linkTarget={item.titleTargetLink}
                                            shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator}
                                        />
                                        <SlotIconDisplay itemId={item.itemId} icon={item.hasVideo ? "video" : "none"} iconClassName="medium" isIconVisible={shouldAddArticleAutoplayIndicator} />
                                    </div>
                                    <CalcalistShareMenu url={item.titleLink} title={convertHtmlToString(item.title)} isArticle={item.linkType === "ARTICLE"} />
                                </div>
                                <div className="slotTitle" style={{ width: imageWidth }}>
                                    <HyperLinkBuilder
                                        href={item.titleLink}
                                        linkTarget={item.titleTargetLink}>
                                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                    </HyperLinkBuilder>
                                </div>
                                {isMore &&
                                    <div className="moreDetails">
                                        <span className="dateView">
                                            {separateHourFromDateAndFormatToString(item.date, { showHour: fieldsDisplaySettings.isHourVisible, showDate: fieldsDisplaySettings.isDateVisible })}
                                        </span>
                                        {isComma && <span className="commaView">,</span>}
                                        {fieldsDisplaySettings.isAuthorVisible && <span className="authorInfo" dangerouslySetInnerHTML={{ __html: item.author }} />}
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>


                <div className="BottomComponentArea">
                    <HyperLinkBuilder className="bottomTitleLink" style={{color: marginColor}} href={tabLink} linkTarget={tabLinkTarget}>{bottomLinkText}</HyperLinkBuilder>
                    {numberOfVisibleItems > 2 &&
                        <div className="ArrowButtons">
                            <button className="RigthArrow" onClick={this.prevItems} disabled={this.state.fromItemIndex === 0}></button>
                            <button className="LeftArrow" onClick={this.nextItems} disabled={this.state.fromItemIndex === numberOfVisibleItems - 2}></button>
                        </div>}
                </div>

            </div >
        )
    }
}


