import * as axios from "axios"
import * as classNames from "classnames"
import { isWcmEditor } from "../../../../config/serverConfig";

interface ArticleNewsletterWrapperProps {
    itemId: string
    componentaId: string
    itemTitle: string
    accountName: string
}

interface ArticleNewsletterWrapperState {
    user: { props: { firstName: string } }
    sendStatus: SendStatus
    checkedTerms: boolean
    mailValue: string
}

enum SendStatus {
    beforeSend = 0,
    validationError = 1,
    sent = 2,
    networkError = 3,
    sendPending = 4,
}

declare var grecaptcha: any
declare var window: any

export class ArticleNewsletterWrapper extends React.Component<ArticleNewsletterWrapperProps, ArticleNewsletterWrapperState>{
    public static siteScriptName = "ArticleNewsletter";
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            sendStatus: 0,
            checkedTerms: false,
            mailValue: "",
        }
    }
    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({
                user: window.YitPaywall.user
            })
        }
    }

    private validateEmail = (email: string) => {
        if (!email) return false;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private changeMailValue = (e) => {
        this.setState({ mailValue: e.target.value })
    }

    private showError = (isMailError: boolean, checkedTerms: boolean) => {
        const { sendStatus } = this.state
        let text = "";
        if (sendStatus === SendStatus.validationError) {
            if (!checkedTerms) {
                text = "נדרש לאשר הסכמה לתנאי השימוש ומדיניות הפרטיות";
            }
            if (isMailError) {
                text = "יש למלא כתובת מייל תקינה";
            }
        } else if (sendStatus === SendStatus.networkError) {
            text = "אירעה שגיאה בשליחה, נסה מאוחר יותר"
        }
        return text;
    }

    private changeTerms = () => {
        this.setState({ checkedTerms: !this.state.checkedTerms })
    }

    private onHandleSend = () => {
        const isTest = window.location.search.includes("is_test=1");
        if (isTest) {
            this.sendSub("", true);
        } else if (window.wcmCaptchaSiteKey && grecaptcha) {
            grecaptcha.ready(() => {
                grecaptcha.execute(window.wcmCaptchaSiteKey, { action: 'submit' }).then(token => {
                    this.sendSub(token, false);
                })
            })
        }
    }

    private sendSub = async (token: string, isTest: boolean) => {
        const { itemId, componentaId, itemTitle, accountName } = this.props
        const { mailValue, checkedTerms } = this.state
        let postData = {
            groupId: itemId || '',
            email: mailValue,
            token,
            accountName,
        }
        if (this.validateEmail(mailValue) && checkedTerms) {
            try {
                this.setState({ sendStatus: SendStatus.sendPending });
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({ 'event': 'GA_event', 'Category': 'Newsletter', 'Action': 'Registered to Newsletter', 'Label': itemTitle });
                const response = await axios.post(`/api/newsletter/${componentaId}/subscribeToGroup${isTest ? "?is_test=1" : ""}`, postData)
                if (response.status === 200) {
                    this.setState({ sendStatus: SendStatus.sent });
                }
            } catch {
                this.setState({ sendStatus: SendStatus.networkError })
            }
        }
        else {
            this.setState({ sendStatus: 1 })
        }
    }

    render() {
        const { itemTitle } = this.props;
        const { mailValue, sendStatus, checkedTerms } = this.state;
        const termsLink = <a href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html" target="_blank">תנאי השימוש</a>;
        const policyLink = <a href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html" target="_blank">מדיניות הפרטיות</a>;
        const emailError = sendStatus == SendStatus.validationError && !this.validateEmail(mailValue);
        const checkboxError = sendStatus === 1 && !checkedTerms ? "error" : "";
        const emailErrorClass = emailError ? "error input-text-rpf" : "input-text";
        const emailInputClass = `email-input-wrapper ${isWcmEditor() ? "editor" : ""}`

        return (
            <div className="newsletter-componenta-wrapper">
                <div className={classNames("newsletter-signup", { "message-sent": sendStatus === 2 })}>
                    {sendStatus !== 2 &&
                        <>
                            <div className="title-and-icon">
                                <div className="newsletter-title newsletter-title-rpf">{itemTitle}</div>
                                <div className="newsletter-icon-pf newsletter-icon"></div>
                            </div>
                            <div className="email-input">
                                <div className={emailInputClass}>
                                    <input className={emailErrorClass} placeholder="כתובת מייל" type="text" name="mail" required value={mailValue} onChange={this.changeMailValue} />
                                    <button className="send-button send-button-rpf" disabled={sendStatus === 4} onClick={this.onHandleSend}>{sendStatus === 4 ? "שולח..." : "שליחה"}</button>
                                </div>
                                <div className="error-message error-message-rpf">{this.showError(emailError, checkedTerms)}</div>
                            </div>
                            <div className="accept-terms accept-terms-rpf">
                                <input type="checkbox" id="accept-terms-newsletter" className={checkboxError} onChange={this.changeTerms} />
                                <span> מסכים/ה ל{termsLink}, {policyLink} ולקבלת פניות שיווקיות מ-ynet כולל טלפוניות, מבלי שהנ"ל מהווה הסכמה לעריכת עסקה</span>
                            </div>
                        </>}
                    {sendStatus === 2 &&
                        <div className="send-success-wrapper">
                            <div className="newsletter-icon-pf newsletter-icon"></div>
                            <div className="newsletter-success-title-1 newsletter-success-title-1-rpf">תודה!</div>
                            <div className="newsletter-success-title-2 newsletter-success-title-2-rpf">הרשמתך התקבלה בהצלחה</div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}