import { DetachedPlayer } from "../../detachPlayer/detachedPlayer"

interface CalcalistDetachedPlayerProps {
    audioUrl: string
    title: string
    durationSeconds: number
    podcastImage: string
    classNames: string
    buttonTitle?: string
}

export const CalcalistDetachedPlayer = (props: CalcalistDetachedPlayerProps) => {
const {audioUrl, title, durationSeconds, podcastImage, classNames, buttonTitle } = props

    const playerProps = {
        audioUrl: audioUrl,
        title: title,
        durationSeconds: durationSeconds,
        podcastImage: podcastImage
    }

    return (
        <DetachedPlayer
            playerWidth="688"
            playerHeight="118"
            playerName="CalcalistPodcastPlayer"
            playerProps={playerProps}
            buttonRenderer={(renderProps) => (<button className={classNames} title={buttonTitle} onClick={renderProps.openPopupPlayer} />)}
        />
    )
}

CalcalistDetachedPlayer.siteScriptName = 'CalcalistDetachedPlayer'