import { SiteMediaData, SiteItemData, SiteImageData } from "../../../interfaces"
import { VideoSettingsData, LinkedItemType, ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { MediaContentTypes } from "constants/contentTypes"
require("../../multiImages/components/site_miltiImagesComponenta.less")


interface MultiImagesFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    itemList: SiteItemData[]
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    subTitleColor: string
    titleColor: string
    lastHeightSize?: number
    numberOfVisibleItems: number
    isBottomLinkVisible: boolean
    bottomLinkColor: string
}

interface MultiImagesFrontState {
    num_of_scrolls_left_available: number
    num_of_scrolls_right_available: number
    width_in_pixels_of_last_overflowing_item_in_row: number
    rightOffset: number
}


export class MultiImagesCommertialFront extends React.Component<MultiImagesFrontProps, MultiImagesFrontState>{
    public static siteScriptName = "MultiImagesCommertialFront";
    constructor(props) {
        super(props);
        this.state = {
            num_of_scrolls_left_available: null,
            num_of_scrolls_right_available: null,
            rightOffset: 0,
            width_in_pixels_of_last_overflowing_item_in_row: 0
        }
    }


    private getItemMargin = (componentWidth: number, imageWidth: number) => {
        const singleItemWidth = imageWidth
        let numberofVisibleItems = Math.floor(componentWidth / singleItemWidth)
        let imagesWidth = numberofVisibleItems * singleItemWidth
        let spaceForMargin = componentWidth - imagesWidth
        let marginLeft = spaceForMargin / (numberofVisibleItems - 1)
        return marginLeft
    }
    public componentWillMount() {
        const { imageWidth, width, numberOfVisibleItems } = this.props
        if (numberOfVisibleItems > 0) {
            this.setupLisScrolling(numberOfVisibleItems, width, imageWidth)
        }
    }


    private setupLisScrolling(numberOfVisibleItems: number, width: number, imageWidth: number) {
        const single_media_item_width = imageWidth + this.getItemMargin(width, imageWidth)
        const num_of_medias_fit_in_full_row = Math.floor(width / (imageWidth))
        const items_in_last_row = numberOfVisibleItems % num_of_medias_fit_in_full_row;
        let num_of_scrolls_left_available: number;
        if (items_in_last_row !== 0) {
            num_of_scrolls_left_available = Math.floor(numberOfVisibleItems / num_of_medias_fit_in_full_row)
        }
        else { num_of_scrolls_left_available = Math.floor(numberOfVisibleItems / num_of_medias_fit_in_full_row) - 1 }
        const num_of_scrolls_right_available = 0
        this.setState({ num_of_scrolls_left_available, num_of_scrolls_right_available, width_in_pixels_of_last_overflowing_item_in_row: this.getItemMargin(width, imageWidth) })
    }

    private handleScrollLeftClicked = () => {
        const { width_in_pixels_of_last_overflowing_item_in_row, num_of_scrolls_left_available, num_of_scrolls_right_available, rightOffset } = this.state
        const { width, imageWidth, imageHeight, numberOfVisibleItems } = this.props
        const single_media_item_width = imageWidth + width_in_pixels_of_last_overflowing_item_in_row

        if (num_of_scrolls_left_available === 1) {
            const num_of_medias_fit_in_full_row = Math.floor(width / (imageWidth))
            const rightOffset = (single_media_item_width * (numberOfVisibleItems - num_of_medias_fit_in_full_row)) - width_in_pixels_of_last_overflowing_item_in_row
            this.setState({
                rightOffset: - rightOffset - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })

            return null

        }
        if (num_of_scrolls_left_available > 0) {
            this.setState({
                rightOffset: rightOffset - width - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available - 1 !== 0 ? num_of_scrolls_left_available - 1 : 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })
        }
    }

    private handleScrollRightClicked = () => {
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, width_in_pixels_of_last_overflowing_item_in_row, rightOffset } = this.state
        const { width } = this.props
        if (num_of_scrolls_right_available === 1) {
            this.setState({
                rightOffset: 0,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: 0
            })
            return null
        }
        if (num_of_scrolls_right_available > 0) {
            this.setState({
                rightOffset: rightOffset + width + width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: num_of_scrolls_right_available - 1 > 0 ? num_of_scrolls_right_available - 1 : 0
            })
        }
    }
    public componentWillReceiveProps(nextProps) {
        const { imageWidth, width, numberOfVisibleItems } = nextProps

        if (nextProps.numberOfVisibleItems > 0) {
            this.setupLisScrolling(numberOfVisibleItems, width, imageWidth)
        }
    }
    private titleClassName = (isSubTitleVisible: boolean) => {
        if (isSubTitleVisible) return "slotTitle oneRow"
        else return "slotTitle twoRow"

    }
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }
    public render() {
        const { width, imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings, titleColor, subTitleColor, lastHeightSize, isBottomLinkVisible, bottomLinkColor } = this.props
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, rightOffset } = this.state
        const itemWidth = this.getItemMargin(width, imageWidth) + imageWidth
        let isLeftArrowVisible = num_of_scrolls_left_available !== 0
        let isRightArrowVisible = num_of_scrolls_right_available !== 0
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "" } as SiteImageData
        return (<div className="slotsContent" style={{ height: lastHeightSize }}>
            {isRightArrowVisible && <button className="scrollRightBtn slotListArrow" title="scroll right" aria-label="scroll right" onClick={(e) => this.handleScrollRightClicked()} />}
            <div className="slotListWrapper" style={{ "marginRight": rightOffset }} >
                {itemList.map((slot, index) =>
                    <div className="slotView" key={`slotItem_${slot.itemId}_${index}`}
                        style={{ marginLeft: this.getItemMargin(width, imageWidth) }}>
                        {slot.media.mediaType === "MEDIA_VIDEO" ? <div className="imageArea">
                            <SlotIconDisplay itemId={slot.itemId} icon={this.getIcon(slot.icon, slot.hasVideo)} iconClassName="medium" isIconVisible={slot.hasVideo} />
                            <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                <SiteMedia data={slot.media !== undefined ? slot.media : emptyMedia} width={imageWidth} height={imageHeight} videoSettings={videoSettings} />
                            </div></div> : <HyperLinkBuilder
                                href={slot.titleLink}
                                linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                            >
                                <div className="imageArea">
                                    <SlotIconDisplay itemId={slot.itemId} icon={this.getIcon(slot.icon, slot.hasVideo)} iconClassName="medium" isIconVisible={slot.hasVideo} />
                                    <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                        <SiteMedia data={slot.media !== undefined ? slot.media : emptyMedia} width={imageWidth} height={imageHeight} videoSettings={videoSettings} />
                                    </div></div> </ HyperLinkBuilder>}
                        <div className="slotTextArea">
                            {fieldsDisplaySettings.isTitleVisible && <div className={this.titleClassName(fieldsDisplaySettings.isSubTitleVisible && slot.subTitle !== undefined)}>
                                <HyperLinkBuilder
                                    href={slot.titleLink}
                                    linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                    children={slot.title} >
                                    <span style={{ color: titleColor }} > <div dangerouslySetInnerHTML={{ __html: slot.title }} /></span>
                                </HyperLinkBuilder>
                            </div>}
                            {fieldsDisplaySettings.isSubTitleVisible && slot.subTitle !== undefined && <div className="slotSubTitle" >
                                <HyperLinkBuilder
                                    href={slot.titleLink}
                                    linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                    children={slot.subTitle} >
                                    <span style={{ color: subTitleColor }} > <div dangerouslySetInnerHTML={{ __html: slot.subTitle }} /></span>
                                </HyperLinkBuilder>
                            </div>}
                            {isBottomLinkVisible && slot.bottomText !== undefined && <div className="slotBottomLink" >
                                <HyperLinkBuilder
                                    href={slot.bottomTextLink}
                                    linkTarget={slot.bottomTextLinkTarget !== undefined && slot.bottomTextLinkTarget}
                                    children={slot.bottomText} >
                                    <span style={{ color: bottomLinkColor }} > <div dangerouslySetInnerHTML={{ __html: slot.bottomText }} /></span>
                                </HyperLinkBuilder>
                            </div>}
                        </div>
                    </div>)}
            </div>
            {isLeftArrowVisible && <button className="scrollLeftBtn slotListArrow" title="scroll left" aria-label="scroll left" onClick={(e) => this.handleScrollLeftClicked()} />}
        </div>
        )
    }
}
// <div >