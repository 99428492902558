import { isWcmEditor } from "config/serverConfig"
require('./ctech_site_SearchComponenta.less')

interface SearchState {
    typedUserInput: string,
}
export class SearchComponenta extends React.Component<{domain:string}, SearchState> {
    constructor(props) {
        super(props)
        this.state = {
            typedUserInput: ""
        }
    }
    private updateInputValue = (evt) => {
        this.setState({ typedUserInput: evt.target.value });
    }

    private search = () => {
        const { typedUserInput } = this.state;
  const {domain} = this.props
        if (typedUserInput) {
           // console.log("typedUserInput in:", typedUserInput)
            let urlHead = ""
            let userInput = ""
            let fullUrl = ""
            let urlTail = ""
            if (!isWcmEditor()) {
                urlHead = `${domain}/ctechnews/category/5256`
                userInput = typedUserInput
                urlTail = "?cx=partner-pub-0047680307646695:6806626149&cof=FORID:10&ie=UTF-8&q="
                fullUrl = urlHead + urlTail + userInput
                location.href = fullUrl

            }

        }

    }

    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }

    render() {
        const { typedUserInput } = this.state

        return (
            <div className="SearchComponenta" >
                <div className="search-field">
                    <input
                        className="searchInput"
                        type="text"
                        id="queryString"
                        placeholder="Search"
                        autoComplete="off"
                        maxLength={25}
                        onKeyPress={this.handleInputKeyPress}
                        onChange={this.updateInputValue}
                        value={typedUserInput} />
                    <span className="search-btn" onClick={typedUserInput.length > 0 ? this.search : null}>
                        <img className="searchImg" src="https://images1.calcalist.co.il/images/1280/icons/search-icon.png" />
                    </span>
                </div>
            </div>
        )
    }
}
