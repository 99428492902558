import { useState } from "react";
import { NewsFlashItemType } from "./radionasNewsFlashComponent";
import { NewsFlashItem } from "./newsFlashItem"

interface NewsFlashListProps {
    itemsList: NewsFlashItemType[]
    audioDomain: string
    isMobileWeb: boolean
}

export const NewsFlashListWrapper = (props: NewsFlashListProps) => {
    const { itemsList, audioDomain, isMobileWeb } = props
    const [isTogglesOpen, setIsTogglesOpen] = useState<boolean>(true)

    const onRadioBtnClick = (state: boolean) => {
        setIsTogglesOpen(state)
    }

    return (
        <div className="news-flash-list-wrapper">
            <div className="radio-btn-wrapper">
                <input type="radio" className="radio-btn" checked={!isTogglesOpen} onChange={() => onRadioBtnClick(false)} />
                <div className="input-label close" style={{ marginLeft: 16 }}>العرض العادي</div>

                <input type="radio" className="radio-btn" checked={isTogglesOpen} onChange={() => onRadioBtnClick(true)} />
                <div className="input-label open">عرض ممتد</div>
            </div>

            <div className="news-flash-list">
                {itemsList.map(item => <NewsFlashItem key={item.articleId} article={item} isTogglesOpen={isTogglesOpen} audioDomain={audioDomain} isMobileWeb={isMobileWeb} />)}
            </div>
        </div>
    )
}

NewsFlashListWrapper.siteScriptName = "NewsFlashListWrapper"
