import { GalleryViewType } from "widgets/commonComponents/base/basicMediaCarousel"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { CSSTransitionGroup } from "react-transition-group"
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"
import { hexToRgbA } from "widgets/widgetsUtils"
import { SiteItemData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";

interface PlainGalleryProps {
    medias: SiteItemData[]
    width: number
    galleryViewOption: GalleryViewType
    videoSettings: VideoSettingsData
    intervalTime: number
    fieldsDisplaySettings: LinkedItemFieldDisplay
    componentaColor: string
}

interface PlainGalleryState {
    listFocusedMediaIndex: number
    selectedMediaIndex: number,
    num_of_scrolls_left_available: number
    num_of_scrolls_right_available: number
    width_in_pixels_of_last_overflowing_item_in_row: number
    rightOffset: number
    videoPlayStarted: boolean
}

export class PlainGallery1280 extends React.Component<PlainGalleryProps, PlainGalleryState>{
    constructor(props) {
        super(props as PlainGalleryProps);
        this.state = {
            listFocusedMediaIndex: 0,
            selectedMediaIndex: 0,
            num_of_scrolls_left_available: null,
            num_of_scrolls_right_available: null,
            rightOffset: 0,
            width_in_pixels_of_last_overflowing_item_in_row: 0,
            videoPlayStarted: false
        }
    }

    componentWillMount() {
        const { medias, width } = this.props
        if (medias.length > 0) {
            this.setupLisScrolling(medias, width)
        }
    }
    private autoGalleryInterval: any;
    private startAutoGallery = () => {
        const { intervalTime, medias } = this.props
        const self = this;
        if (typeof (intervalTime) === "number") {
            if (intervalTime > 0) {
                this.autoGalleryInterval = setInterval(() => {
                    this.setState((prevState: PlainGalleryState, props: PlainGalleryProps) => {
                        let newIndex = ++prevState.selectedMediaIndex;
                        if (newIndex >= props.medias.length) newIndex = 0;
                        return { selectedMediaIndex: newIndex }
                    });
                }, intervalTime * 1000);
            }
            else {
                clearInterval(this.autoGalleryInterval)
            }
        }
    }
    componentDidMount() {
        if (this.props.medias.length > 1) {
            this.startAutoGallery();
        }
    }
    public static siteScriptName = "PlainGallery1280";
    private single_List_item_width = 198

    private setupLisScrolling(medias: SiteItemData[], width: number) {
        const num_of_items = medias.length
        const single_media_item_width = this.single_List_item_width
        const num_of_medias_fit_in_full_row = Math.floor(width / single_media_item_width)
        const num_of_scrolls_left_available = Math.floor((num_of_items * single_media_item_width) / width)
        const num_of_scrolls_right_available = 0
        const width_in_pixels_of_last_overflowing_item_in_row = width - (single_media_item_width * num_of_medias_fit_in_full_row)
        this.setState({ num_of_scrolls_left_available, num_of_scrolls_right_available, width_in_pixels_of_last_overflowing_item_in_row })
    }

    private handleScrollLeftClicked = () => {
        const { width_in_pixels_of_last_overflowing_item_in_row, num_of_scrolls_left_available, num_of_scrolls_right_available, rightOffset } = this.state
        const { width, medias } = this.props

        if (num_of_scrolls_left_available === 1) {
            const num_of_medias_fit_in_full_row = Math.floor(width / this.single_List_item_width)
            const rightOffse = (this.single_List_item_width * (medias.length - num_of_medias_fit_in_full_row)) - width_in_pixels_of_last_overflowing_item_in_row
            this.setState({
                rightOffset: - rightOffse,
                num_of_scrolls_left_available: num_of_scrolls_left_available - 1 > 0 ? num_of_scrolls_left_available - 1 : 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })
            return null
        }
        if (num_of_scrolls_left_available > 0) {
            this.setState({
                rightOffset: rightOffset - width,
                num_of_scrolls_left_available: num_of_scrolls_left_available - 1 > 0 ? num_of_scrolls_left_available - 1 : 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })
        }
    }

    private handleScrollRightClicked = () => {
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, width_in_pixels_of_last_overflowing_item_in_row, rightOffset } = this.state
        const { width } = this.props
        if (num_of_scrolls_right_available === 1) {
            this.setState({
                rightOffset: 0,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: num_of_scrolls_right_available - 1 > 0 ? num_of_scrolls_right_available - 1 : 0
            })
            return null
        }
        if (num_of_scrolls_right_available > 0) {
            this.setState({
                rightOffset: rightOffset + width,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: num_of_scrolls_right_available - 1 > 0 ? num_of_scrolls_right_available - 1 : 0
            })
        }
    }
    private showPrevMedia = (medias) => {
        this.setState(state => state.selectedMediaIndex > 0 ? { selectedMediaIndex: state.selectedMediaIndex - 1 } : { selectedMediaIndex: medias.length - 1 })
        this.stopAutoGallery();
    }
    private showNextMedia = (medias) => {
        this.setState(state => state.selectedMediaIndex < medias.length - 1 ? { selectedMediaIndex: state.selectedMediaIndex + 1 } : { selectedMediaIndex: 0 })
        this.stopAutoGallery();
    }
    private stopAutoGallery = () => {
        if (this.props.intervalTime !== 0) {
            clearInterval(this.autoGalleryInterval)
        }

    }
    private onSelectMedia = (e, index: number) => {
        this.stopAutoGallery();
        const isVideo = this.props.medias[this.state.selectedMediaIndex].media.mediaType === "MEDIA_VIDEO"
        // const videoPlayStarted = this.state.selectedMediaIndex === index && isVideo
        this.setState({ selectedMediaIndex: index, videoPlayStarted: false })
    }

    private handleOnVideoPlayStarted = () => {
        this.setState({ videoPlayStarted: true })
    }
    private handleOnVideoPlayStopped = () => {
        this.setState({ videoPlayStarted: false })
    }

    componentWillReceiveProps(nextProps: PlainGalleryProps) {
        if (nextProps.medias.length > 0) {
            this.setupLisScrolling(nextProps.medias, nextProps.width)
        }
    }

    render() {
        const { medias, width, galleryViewOption, videoSettings, fieldsDisplaySettings, componentaColor } = this.props
        const { listFocusedMediaIndex, selectedMediaIndex, num_of_scrolls_right_available, num_of_scrolls_left_available, rightOffset, videoPlayStarted } = this.state
        const { isTitleLinkVisible, isTitleVisible } = fieldsDisplaySettings
        const noTitleLink = !medias[selectedMediaIndex].titleLink
        const mediaHeight = Math.floor(width / 1.77)

        if (medias.length === 0) { return null }
        const selectedDetailsStyle = { zIndex: 1, backgroundColor: hexToRgbA(componentaColor, "0.85") }
        const arrowStyle = { backgroundColor: componentaColor }
        const selectedMediaItem = medias[selectedMediaIndex]
        const mainTitle = !noTitleLink && isTitleLinkVisible ?
            <HyperLinkBuilder
                href={selectedMediaItem.titleLink}
                linkTarget={selectedMediaItem.titleTargetLink}
                children={selectedMediaItem.title} >
                <div className="title"> <div dangerouslySetInnerHTML={{ __html: selectedMediaItem.title }} /> <span className="linkTo">{__("Full Story")}</span></div>
            </HyperLinkBuilder> :
            <div className="title" dangerouslySetInnerHTML={{ __html: selectedMediaItem.title }} />
        return (
            <span>
                <div className="gallerySelectedMedia" >

                    {selectedMediaItem && (videoPlayStarted === false || null) &&
                        <div className="selectedMediaDetails" style={selectedDetailsStyle}>
                            {isTitleVisible ? mainTitle : <div className="title" />}
                            <div className="credit">{selectedMediaItem.media.credit} </div>
                        </div>}

                    <div className="galleryDroppedImage" style={{ height: mediaHeight }}>
                        {galleryViewOption === "arrows" && <button className="scrollRightBtn galleryArrow" title="scroll right"
                            style={arrowStyle}
                            aria-label="scroll right"
                            onClick={() => this.showPrevMedia(medias)} />}
                        <CSSTransitionGroup transitionName="media" transitionEnterTimeout={500} transitionLeave={false} >
                            <SiteMedia
                                onPlayStarted={() => this.handleOnVideoPlayStarted()}
                                onPlayStopped={() => this.handleOnVideoPlayStopped()}
                                data={selectedMediaItem.media} videoSettings={videoSettings} key={selectedMediaItem.media.url} width={width} height={mediaHeight} onSelect={this.stopAutoGallery} />
                        </ CSSTransitionGroup >
                        {galleryViewOption === "arrows" && <button className="scrollLeftBtn galleryArrow"
                            style={arrowStyle}
                            title="scroll left" aria-label="scroll left" onClick={() => this.showNextMedia(medias)} />}
                    </div>
                </div>

                {galleryViewOption === "bar" && <div className="mediaListContainer">
                    {num_of_scrolls_right_available !== 0 && <button className="scrollRightBtn galleryArrow"
                        style={arrowStyle}
                        title="scroll right" aria-label="scroll right" onClick={(e) => this.handleScrollRightClicked()} />}
                    {num_of_scrolls_left_available !== 0 && <button className="scrollLeftBtn galleryArrow"
                        style={arrowStyle}
                        title="scroll left" aria-label="scroll left" onClick={(e) => this.handleScrollLeftClicked()} />}
                    <div className="mediaListWrapper" style={{ "marginRight": rightOffset }} >
                        {medias.map((item, index) =>
                            <div key={`galleryItem_${item.media.mediaId}`} onClick={(e) => this.onSelectMedia(e, index)} onMouseOver={(e) => this.setState({ listFocusedMediaIndex: index })} className="mediaWrapper">
                                {selectedMediaIndex !== index && listFocusedMediaIndex !== index && <div className="unselectedOverlay" />}

                                {item.media.mediaType === "MEDIA_VIDEO" && <img className="lisPreviewImage" src={item.media.poster} style={index === selectedMediaIndex ? { border: `6px solid ${componentaColor}` } : { border: `6px solid transparent` }} />}
                                {item.media.mediaType === "MEDIA_IMAGE" && <img className="lisPreviewImage" title={item.media.title} style={index === selectedMediaIndex ? { border: `6px solid ${componentaColor}` } : { border: `6px solid transparent` }} aria-hidden={item.media.ariaHidden} alt={item.media.alt} src={item.media.url} />}
                            </div>)}
                    </div>
                </div>}
            </span>
        )
    }
}
