import { tagObj } from "./tagsSearchComponenta";
import {TagsSearchDict} from "./tagsSearchComponenta";
require("./ynet_site_tagPageMain.less")
interface TagsSearchProps {
    items: tagObj[]
    onChooseItem: (selectedItem: tagObj) => void
    isOpened: boolean
    selectedItem: string
    lang:string
}
export class TagListComponenta extends React.Component<TagsSearchProps> {

    render() {
        const { items, onChooseItem, isOpened, selectedItem , lang} = this.props;

        if (isOpened) {
            return (
                <div>
                    {items.length > 0 ?
                        <div className="tagsList">
                            {items.map((item, i) =>
                                <a className={`tagItem ${selectedItem === item.tagName ? "selected" : ""}`} onMouseOver={() => onChooseItem(item)} key={i} href={item.tagLink}>
                                    {item.tagName}
                                </a>
                            )}
                        </div>
                        :
                        <span className="noResult">{TagsSearchDict[lang].noResults}</span>}
                </div>
            )
        }
        else return null
    }
}