import { WhatsAppShareButton } from "widgets/siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"
import { MailShareButton } from "widgets/siteWidgets/socialShareButtons/mail/mailShareButton"
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../../../../widgetsUtils"
require("./yplus_site_premiumArticleHeaderShareButtons.less")

export class PremiumArticleHeaderShareButtons extends React.Component<{title: string}, {}>{
    public static siteScriptName = "PremiumArticleHeaderShareButtons"

    private handleShareBtnClicked = (button: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const canonicalElm = document.querySelector("link[rel=canonical]") as HTMLLinkElement;
            const current_url = (canonicalElm && canonicalElm.href) || window.location.href;

            switch (button) {
                case "facebook":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Top", title), "Article Top")
                    break;

                case "whatsapp":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Article Top", title), "Article Top")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, "he", () => analyticsPushFunc("Mail", "Article Top", title), "Article Top")
                    break;

                case "twitter":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Top", title), "Article Top")
                    break;
            }
        }
    }

    render() {
        return (
            <div className="PremiumArticleHeaderShareButtons">
                <div className="whatsapp icon-pf" onClick={e => this.handleShareBtnClicked("whatsapp")} />
                <div className="mail icon-pf" onClick={e => this.handleShareBtnClicked("mailto")} />
                <div className="twitter icon-pf" onClick={e => this.handleShareBtnClicked("twitter")} />
                <div className="facebook icon-pf" onClick={e => this.handleShareBtnClicked("facebook")} />
            </div>
        )
    }
}