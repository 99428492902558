import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { YnetPremiumLoginDesktop } from "../ynetPremiumLogin/ynetPremiumLoginDesktop"
import { YnetPianoLogin, YnetPlusUserMenuItems } from "../../../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin"
require("./yplus_site_premiumHeader.less")

interface PremiumHeaderProps {
    className?: string
    id?: string
    isArticlePage: boolean
    siteId?: string
    isStyckyBar?: boolean
    mainLogoLink: string
    parentSiteLink: string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}


export class PremiumHeader extends React.Component<PremiumHeaderProps, any>{
    static defaultProps = { className: "", isStyckyBar: false }

    render() {
        const { mainLogoLink, parentSiteLink, ynetPlusMenuItems } = this.props
        const className = `PremiumHeader ${this.props.className}`
        let logoImageUrl;
        //  let logoImageLink;
        const isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;


        if (this.props.isArticlePage) {
            logoImageUrl = this.props.siteId === "pplus" ? "/Common/images/pplus-logo.png" : "/Common/images/y-logo.png"
            //    logoImageLink = this.props.siteId === "pplus" ? "//pplus.ynet.co.il" : "//www.ynet.co.il"
        }
        else {
            logoImageUrl = "/Common/images/y-logo.png"
            //    logoImageLink = "//www.ynet.co.il"
        }
        //  logoImageLink += "?utm_source=premium.ynet.co.il&utm_medium=referral&utm_campaign=ynet_plus_Lounge"
        return (
            <div className={className} id={this.props.id}>

                <SimpleLinkRenderer className="rightLogo" href={parentSiteLink} >
                    <img src={logoImageUrl} />
                    <span>    ראשי</span>
                </SimpleLinkRenderer>
                {this.props.isStyckyBar ? <div className="rightSideHeader"><SimpleLinkRenderer className="midLogo withBgLogo" href={mainLogoLink} />
                    {this.props.children} </div> :
                    <SimpleLinkRenderer className="midLogo" href={mainLogoLink}  >
                       <img src="//www.ynet.co.il/images/logo-ynet-plus.png" />
                    </SimpleLinkRenderer>
                }
                <div className="menuWrapper">
              {isPianoLogin? <YnetPianoLogin isPremiumHeaderArticle={true} ynetPlusMenuItems={ynetPlusMenuItems} /> : <YnetPremiumLoginDesktop isArticlePage={this.props.isArticlePage} />}
                </div>
            </div>
        )
    }
}