import { getQueryStringValue } from "../../../widgetsUtils"
require("./yplus_site_SiteSearchInput.less")

interface SiteSearchInputProps {
    onResetClickCallback: () => void
    fetchMoreArticles: (searchValue: string) => void
}

interface SiteSearchInputState {
    searchValue: string
}

export class SiteSearchInput extends React.Component<SiteSearchInputProps, SiteSearchInputState>{
    constructor(props) {
        super(props)
        this.state = {
            searchValue: ""
        }
    }

    componentDidMount() {
        const { fetchMoreArticles } = this.props
        const initialSearchValue = getQueryStringValue("q")
        fetchMoreArticles(initialSearchValue)
        this.setInitialSearchValue(initialSearchValue)
    }

    private setInitialSearchValue = (searchValue: string) => {
        this.setState({ searchValue })
    }

    private setSearchValue = (e) => {
        const searchValue = (e.target as HTMLInputElement).value
        this.setState({ searchValue })
    }

    private onResetBtnClicked = () => {
        const { onResetClickCallback } = this.props
        this.setState({ searchValue: "" })
        onResetClickCallback()
    }

    private onEnterPress = (e) => {
        const { searchValue } = this.state
        const { fetchMoreArticles } = this.props
        if (e.key === "Enter") {
            window.location.href = `?q=${searchValue}`
        }
    }

    render() {
        const { searchValue } = this.state
        return (
            <div id="searchInputInputWrapper">
                <input type="text" value={searchValue} onKeyPress={this.onEnterPress} onChange={this.setSearchValue} />
                {searchValue.length > 0 && <span className="closeBtn" onClick={this.onResetBtnClicked}></span>}
                {searchValue.length === 0 && <span className="searchBtn" >&#9906;</span>}

            </div>
        )
    }
}