
import * as classNames from "classnames"
import { BuzzItemsList } from "./buzzItemsList"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"


interface SiteMobileCalcalistBuzzComponentaProps {
    upperLinkHref: string
    bottomLinkHref: string
    bottomLinkTitle: string
    data: any
    upperLinkTargetProperties: LinkTargetProperties
    bottomLinkTargetProperties: LinkTargetProperties
    includeAd: boolean
    isMobile?:any
}

export class SiteMobileCalcalistBuzzComponenta extends React.Component< SiteMobileCalcalistBuzzComponentaProps, {}>{
    static siteScriptName = "SiteMobileCalcalistBuzzComponenta"
    constructor(props) {
        super(props)
    }

    public render() {
        const { data, bottomLinkTitle, upperLinkHref, bottomLinkHref, upperLinkTargetProperties, bottomLinkTargetProperties, includeAd, isMobile } = this.props
        const itemsToShow =  data["last_day_data"]
        const componetaHeight = includeAd ? 385 : 338;
        return (
            <div className={"CalcalistBuzzMobileComponenta"}>
                <h2 className="tabComponenta">
                    <HyperLinkBuilder className="tabLink" linkTarget={upperLinkTargetProperties} href={upperLinkHref} isDataTbLink>
                        <div className="logo"></div>
                        <div className="textContainer">
                            <span className="tabTitle">באזז</span>
                            <span className="tabSubtitle">הכתבות הנצפות ביותר</span>
                        </div>
                    <div className="arrow-icon"></div>
                    </HyperLinkBuilder>
                </h2>
                <BuzzItemsList items={itemsToShow} includeAd={includeAd} isDotShow={true} isCtechMobile={false} isMobile={isMobile} />
            </div>
        )
    }

}

