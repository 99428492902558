var Cookies = require('js-cookie');
import * as ApiUrls from "config/apiUrls"
import * as axios from "axios"
import { ArticleCommentData, CommentFormType } from "./interfaces"

export interface ArticleCommentRenderProps {
    lang: string
    comment: ArticleCommentData
    isExpanded: boolean
    vote: VoteType
    talkback_like: number
    likes: number
    unlikes: number
    commentFormType: CommentFormType
    isAddCommentFormVisible: boolean
    articleId: string
    isAllCommentsPopupVisable: boolean
    toggleOpenedState: () => void
    handleVoteButtonsClick: (userVoteValue: boolean, voteType: TalkbackVoteType) => void
    handleCloseCommentForm: () => void
    handleFormVisibility: (formType: CommentFormType) => void
    handleCommentsPopupClose: () => void

}

export type ArticleCommentRender = (
    props: ArticleCommentRenderProps
) => React.ReactElement<any>

interface ArticleCommentWrapperProps {
    lang: string
    IdOfLastTalkbackExpandedByUser: number
    isAutoExpanded: boolean
    articleId: string
    comment: ArticleCommentData
    render: ArticleCommentRender
    handleUpdateArticleCommentsStorageState: (talkbackId, allCommentsExpanded?) => void
    isFirma: boolean
    isFirmaMobileWeb: boolean
    handleCommentsPopupClose: () => void
    isAllCommentsPopupVisable: boolean
}

type VoteType = 1 | 0 | -1;
type TalkbackVoteType = "3state" | "2state";

interface ArticleCommentWrapperState {
    isExpanded: boolean
    isAddCommentFormVisible: boolean
    commentFormType: CommentFormType
    vote: VoteType
    talkback_like: number
    likes: number
    unlikes: number
}

export class ArticleCommentWrapper extends React.Component<ArticleCommentWrapperProps, ArticleCommentWrapperState>{
    constructor(props: ArticleCommentWrapperProps) {
        super(props as ArticleCommentWrapperProps)
        const { comment } = props
        let vote: VoteType = 0;
        if (window && comment && comment.id) {
            const voteStr = Cookies.get(`talkback_${comment.id}`);
            switch (voteStr) {
                case "True":
                    vote = 1;
                    break;
                case "False":
                    vote = -1;
                    break;
                default:
                    vote = 0;
            }
        }
        this.state = {
            isExpanded: false,
            isAddCommentFormVisible: false,
            commentFormType: null,
            vote,
            talkback_like: comment.talkback_like,
            likes: comment.likes,
            unlikes: comment.unlikes
        }
    }

    private toggleOpenedState = (): void => {
        const { isExpanded } = this.state
        const { isAutoExpanded, handleUpdateArticleCommentsStorageState, comment: { id }, isFirma, isFirmaMobileWeb } = this.props
        if (isAutoExpanded) { return }
        this.setState((pState) => {
            return {
                isExpanded: !pState.isExpanded,
                isAddCommentFormVisible: isExpanded && !isFirma && !isFirmaMobileWeb
            }
        }, () => handleUpdateArticleCommentsStorageState(id));
    }

    private handleFormVisibility = (formType: CommentFormType = null): void => {
        this.setState((pState) => {
            return {
                isAddCommentFormVisible: !pState.isAddCommentFormVisible,
                commentFormType: formType
            }
        });
    }
    private handleCloseCommentForm = (): void => {
        this.setState({ isAddCommentFormVisible: false });
    }

    componentWillMount() {
        const { isAutoExpanded, IdOfLastTalkbackExpandedByUser, comment: { id } } = this.props
        this.setState({ isExpanded: (IdOfLastTalkbackExpandedByUser === id) || isAutoExpanded })
    }

    componentWillReceiveProps(nextProps: ArticleCommentWrapperProps): void {
        this.setState({ isExpanded: nextProps.isAutoExpanded })
    }

    private handleVoteButtonsClick = (userVoteValue: boolean, voteType: TalkbackVoteType): void => {
        const { vote } = this.state
        const { articleId, comment: { id } } = this.props


        const voteApiUrl = `/iphone/json/api/talkbacks/vote`
        const votePostData = {
            "article_id": articleId,
            "talkback_id": id,
            "talkback_like": userVoteValue,
            "talkback_unlike": !userVoteValue,
            "vote_type": voteType
        }

        if (userVoteValue) {
            switch (vote) {
                // case 1 = if the user already liked - set vote to 0 and unlike the vote
                case 1:
                    this.setState((pState) => {
                        return { vote: 0, likes: pState.likes - 1 }
                    })
                    votePostData.talkback_like = false
                    votePostData.talkback_unlike = true
                    axios.post(voteApiUrl, votePostData)
                    break;

                case 0:
                    this.setState((pState) => {
                        return { vote: 1, likes: pState.likes + 1, talkback_like: pState.talkback_like + 1 }
                    })
                    axios.post(voteApiUrl, votePostData)
                    break;

                case -1:
                    if (voteType === "3state") {
                        this.setState((pState) => {
                            return { vote: 1, unlikes: pState.unlikes - 1, likes: pState.likes + 1 }
                        })
                        votePostData.talkback_like = true
                        votePostData.talkback_unlike = true
                    }
                    else {
                        this.setState((pState) => {
                            return { vote: 0, talkback_like: pState.talkback_like + 1 }
                        })
                    }
                    axios.post(voteApiUrl, votePostData)
                    break;
            }
        }
        else {
            switch (vote) {
                // case -1 = if the user already unliked - set vote to 0 and like the vote
                case -1:
                    this.setState((pState) => {
                        return { vote: 0, unlikes: pState.unlikes - 1 }
                    })
                    votePostData.talkback_like = true
                    votePostData.talkback_unlike = false
                    axios.post(voteApiUrl, votePostData)
                    break;

                case 0:
                    this.setState((pState) => {
                        return { vote: -1, unlikes: pState.unlikes + 1, talkback_like: pState.talkback_like - 1 }
                    })
                    axios.post(voteApiUrl, votePostData)
                    break;

                case 1:
                    if (voteType === "3state") {
                        this.setState((pState) => {
                            return { vote: -1, likes: pState.likes - 1, unlikes: pState.unlikes + 1 }
                        })
                        votePostData.talkback_like = false
                        votePostData.talkback_unlike = false
                    }
                    else {
                        this.setState((pState) => {
                            return { vote: 0, talkback_like: pState.talkback_like - 1 }
                        })
                    }
                    axios.post(voteApiUrl, votePostData)
                    break;
            }
        }
    }

    render() {
        const { isExpanded, isAddCommentFormVisible, commentFormType, vote, talkback_like, likes, unlikes } = this.state
        const { articleId, lang, comment, handleCommentsPopupClose, isAllCommentsPopupVisable } = this.props
        return (

            this.props.render({
                lang,
                comment,
                isExpanded,
                vote,
                talkback_like,
                likes,
                unlikes,
                articleId,
                commentFormType,
                isAddCommentFormVisible,
                toggleOpenedState: this.toggleOpenedState,
                handleVoteButtonsClick: this.handleVoteButtonsClick,
                handleFormVisibility: this.handleFormVisibility,
                handleCloseCommentForm: this.handleCloseCommentForm,
                handleCommentsPopupClose,
                isAllCommentsPopupVisable
            })

        )
    }
}