import { IngredientListItem } from "./ingredientListItem"
import { useState, useEffect } from "react"
import { ArticleRecipeStageData } from "../data/articleRecipeStageData"
import { DataList } from "../../../../../commonDataObjects/dataList"
import { ArticleRecipeIngredientData } from "../data/articleRecipeIngredientData"
import * as ResizeObserver from "resize-observer-polyfill"
import { platform } from "os"

interface IngredientsSectionProps {
    stages: DataList<ArticleRecipeStageData>
    ingredients: DataList<ArticleRecipeIngredientData>
    servings: number
    isServingsMultiplier: boolean
    articleTitle: string
    articlePublishedLink: string
    componentaId: string
    isMobileWeb?: boolean
    isApp?: boolean
}

declare var window: Window & {
    recipeShoppingListData: any
}

export const IngredientsSection = (props: IngredientsSectionProps) => {
    const [state, setState] = useState<{ selections: number[] }>({ selections: [] });
    const [shopingListPopUpVisability, setshopingListPopUpVisability] = useState<boolean>(false);
    const [multiplier, setMultiplier] = useState<number>(1);
    const [presentedServings, setPresentedServings] = useState<number>(props.servings);
    const [stickyShoppingListBottomHeight, setStickyShoppingListBottomHeight] = useState<number>(0)
    const { stages, ingredients, servings, isServingsMultiplier, articleTitle, articlePublishedLink, isMobileWeb, isApp, componentaId } = props
    const multiplierAddition = servings > 9 ? servings === 10 ? servings / 100 : (100 / servings) / 100 : (10 / servings) / 10
    const isMobileDeviceView = isApp || isMobileWeb;
    const noStageId = stages.array.find(stage => stage.stageName === "ללא שלב").stageId;
    const shouldHideNoStage = ingredients.array.find(ingredient => ingredient.stageId === noStageId) === undefined;;

    useEffect(() => {
        window.recipeShoppingListData = window.recipeShoppingListData || {};
        window.recipeShoppingListData[componentaId] = {
            title: `רשימת קניות עבור המתכון: ${articleTitle}`,
            items: []
        }
    }, [])

    const handleCheckboxChange = (index: number) => {
        let selectionsArray = state.selections
        const find = selectionsArray.indexOf(index)

        if (find > -1) {
            selectionsArray.splice(find, 1)
        } else {
            selectionsArray.push(index)
        }

        setState({ selections: selectionsArray })

        if (selectionsArray.length > 0) {
            shopingListPopUpVisability ? "" : setshopingListPopUpVisability(true)
        } else {
            shopingListPopUpVisability ? setshopingListPopUpVisability(false) : ""
        }

        window.recipeShoppingListData[componentaId].items = getTextArrayToPrint()
    }

    const handelSelectAll = () => {
        let selectionsArray = state.selections

        if (selectionsArray.length === ingredients.array.length) {
            setState({ selections: [] })
            shopingListPopUpVisability ? setshopingListPopUpVisability(false) : ""
        } else {
            ingredients.array.map((ingredient, index) => {
                const find = selectionsArray.indexOf(index)
                find > -1 ? "" : selectionsArray.push(index)
            })
            setState({ selections: selectionsArray })
            shopingListPopUpVisability ? "" : setshopingListPopUpVisability(true)
        }

        window.recipeShoppingListData[componentaId].items = getTextArrayToPrint()
    }

    const handleCloseShopingList = () => {
        setshopingListPopUpVisability(false)
    }

    const handleIncreaseServings = () => {
        setMultiplier(multiplier + multiplierAddition)
        setPresentedServings(presentedServings + 1)
    }

    const handleDecreaseServings = () => {
        if (presentedServings === 1) return
        setMultiplier((multiplier - multiplierAddition) <= -1 ? 0 : multiplier - Math.abs(multiplierAddition))
        setPresentedServings(presentedServings - 1)
    }

    const getTextToShare = (platform) => {
        const boldS = platform === "print" ? "<b>" : "*"
        const boldE = platform === "print" ? "</b>" : "*"
        const newLine = platform === "print" ? "<br/>" : "\n"
        let text = platform === "mail" ? "" : `${boldS}רשימת קניות עבור המתכון: ${articleTitle}${boldE}${newLine}`;

        ingredients.array.map((ingredient, index) => {
            if (state.selections.indexOf(index) > -1) {
                const measureUnit = ingredient.ingredientMeasureUnit ? `${ingredient.ingredientMeasureUnit} ` : ""
                let amount

                if (ingredient.isFixedAmount) {
                    amount = `${ingredient.ingredientAmount} `
                } else if (!isNaN(ingredient.ingredientAmount)) { //if amount is a text
                    if (ingredient.ingredientAmount === "0" || ingredient.ingredientAmount === "") {
                        amount = ""
                    } else {
                        amount = `${Number((Number(ingredient.ingredientAmount) * multiplier).toFixed(2))} `
                    }
                } else {
                    amount = `${ingredient.ingredientAmount} `
                }

                text += `${amount}${measureUnit}${ingredient.ingredientName} ${ingredient.ingredientDescription ? `(${ingredient.ingredientDescription})` : ""}${newLine}`
            }
        })

        text += `${newLine}קישור למתכון זה: ${newLine}${articlePublishedLink}`

        return text
    }

    const getTextArrayToPrint = () => {
        let contentArray = [];

        ingredients.array.map((ingredient, index) => {
            if (state.selections.indexOf(index) > -1) {
                const measureUnit = ingredient.ingredientMeasureUnit ? `${ingredient.ingredientMeasureUnit} ` : ""
                let amount

                if (ingredient.isFixedAmount) {
                    amount = `${ingredient.ingredientAmount} `
                } else if (!isNaN(ingredient.ingredientAmount)) { //if amount is a text
                    if (ingredient.ingredientAmount === "0" || ingredient.ingredientAmount === "") {
                        amount = ""
                    } else {
                        amount = `${Number((Number(ingredient.ingredientAmount) * multiplier).toFixed(2))} `
                    }
                } else {
                    amount = `${ingredient.ingredientAmount} `
                }

                contentArray.push(`${amount}${measureUnit}${ingredient.ingredientName} ${ingredient.ingredientDescription ? `(${ingredient.ingredientDescription})` : ""}`)
            }
        })

        return contentArray;

    }

    const openMailShare = () => {
        const title = `רשימת קניות עבור המתכון: ${articleTitle}`
        window.location.href = `mailto:?subject=${title}&body=${encodeURIComponent(getTextToShare("mail"))}`
    }

    const openPrintShare = () => {
        const recipe = document.querySelector(".recipe");
        const printDialog = document.getElementById("print-dialog").cloneNode(true)

        recipe.classList.add("onlyIngredientsPrintScreen");
        document.body.appendChild(printDialog);

        setTimeout(() => {
            recipe.classList.remove("onlyIngredientsPrintScreen");
            document.body.removeChild(printDialog)
        }, 1000)

        window.print();
    }

    const onContentHeightChanged = (height: number) => {
        setStickyShoppingListBottomHeight(height)
    }

    useEffect(() => {
        const elementId = isMobileWeb ? "ads.banner" : "ads.sargel"
        const stickyAdElement = document.getElementById(elementId)

        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            onContentHeightChanged(r.contentRect.height)
        }))

        stickyAdElement && this.resizeObserver.observe(stickyAdElement);

        return () => {
            this.resizeObserver && this.resizeObserver.disconnect()
        }
    }, [])

    return (
        <div className="ingredients-section">
            <div className="ingredients-section-top-bar">
                <div className="top-bar-right-section">
                    <div className="ingredients-section-title">מצרכים</div>

                    {isServingsMultiplier ?
                        <div className="ingredients-section-servings-container">
                            <button className="servings-button plus" onClick={handleIncreaseServings}></button>
                            <div className="servings-amount">
                                {presentedServings}
                                <div className="servings-title">סועדים</div>
                            </div>
                            <button className={`servings-button minus ${presentedServings === 1 ? "disable" : ""}`} onClick={handleDecreaseServings}></button>
                        </div>
                        :
                        <div className="ingredients-section-servings-container">
                            <div className="servings-amount">
                                {presentedServings}
                                <div className="servings-title">סועדים</div>
                            </div>
                        </div>}
                </div>

                <a href="https://p.ynet.co.il/sizesconversion" target="_blank" className="top-bar-left-section">
                    <div className="measuring-cup-icon"></div>
                    <div className="measurement-conversion-table">לוח המרת מידות</div>
                </a>
            </div>


            <div className="ingredients-section-list">
                <div className="add-to-shoping-list-title">סמנו והוסיפו מצרכים לרשימת הקניות</div>

                <div className="check-all-wrapper">
                    <input type="checkbox" className="ingredient-checkbox check-all" checked={state.selections.length === ingredients.array.length} onChange={handelSelectAll} />
                    <div className="check-all-title">בחר הכל</div>
                </div>

                {stages.array.map((stage, index) => {
                    if (stage.stageName === "ללא שלב" && shouldHideNoStage) return ""

                    return (
                        <div className="ingredients-section-stage-wrapper" key={index}>
                            <div className="ingredients-section-stage-title">{stage.stageName === "ללא שלב" ? "" : stage.stageName + ":"}</div>

                            <div className="ingredients-section-stage-ingredients">
                                {ingredients.array.map((ingredient, index) => {
                                    if (ingredient.stageId === stage.stageId) {
                                        const { ingredientAmount, ingredientMeasureUnit, ingredientName, ingredientDescription, isFixedAmount } = ingredient
                                        let amount
                                        if (!isNaN(ingredientAmount)) {
                                            if (ingredientAmount === "0") {
                                                amount = ""
                                            } else {
                                                amount = isFixedAmount ? ingredientAmount : Number((Number(ingredientAmount) * multiplier).toFixed(2))
                                            }
                                        } else {
                                            amount = ingredientAmount
                                        }
                                        return (
                                            <IngredientListItem
                                                key={index}
                                                name={ingredientName}
                                                amount={amount}
                                                measureUnit={ingredientMeasureUnit}
                                                description={ingredientDescription}
                                                onCheckBoxChange={() => handleCheckboxChange(index)}
                                                selected={state.selections.includes(index)}
                                            />
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className={`sticky-shoping-list ${shopingListPopUpVisability ? "show" : "hide"}`} style={!isMobileDeviceView ? { bottom: stickyShoppingListBottomHeight } : {}}>
                <button className="close-sign" onClick={handleCloseShopingList} aria-label="close-shoping-list"></button>
                <div className={isApp ? "list-items-count-rpf" : "list-items-count"}>{state.selections.length}</div>
                <div className={isApp ? "items-on-list-title-rpf" : "items-on-list-title"}>פריטים נוספו לרשימת הקניות</div>

                {!isMobileDeviceView && <button className="print-icon" onClick={() => openPrintShare()}></button>}

                {isApp ?
                    <a className="mail-icon" href={`//recipe/shoppinglist/mailto/${componentaId}`} />
                    :
                    <button className="mail-icon" onClick={() => openMailShare()}></button>}

                {isApp ?
                    <a className="whatsapp-icon" href={`//recipe/shoppinglist/whatsapp/${componentaId}`} />
                    :
                    <button className="whatsapp-icon" onClick={() => window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(getTextToShare("whatsapp")))}></button>}
            </div>

            <div className="print-dialog-section" id="print-dialog">
                <div className="top-content">
                    <div className="main-logo" />
                    <div className="ynet-logo" />
                </div>
                <div className="separator" />
                <div className="mid-content">
                    <div className="content-main-title">
                        :רשימת קניות עבור המתכון
                    </div>
                    <div className="content-subtitle" dir="auto">
                        {articleTitle}
                    </div>
                    <ul className="recipe-ingredient-list">
                        {getTextArrayToPrint().map((curr) => (
                            <li className="list-item">{curr}</li>
                        ))}
                    </ul>
                </div>
                <div className="separator" />
                <div className="bottom-content">
                    <div className="bottom-title">:קישור למתכון</div>
                    <a href={articlePublishedLink} className="bottom-recipe-link">{articlePublishedLink}</a>
                </div>
            </div>
        </div>
    )
}

IngredientsSection.siteScriptName = "IngredientsSection"