
import { useEffect, useState } from "react"
import { SimpleLinkRenderer, HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { LogoutPopupComponent } from "../ynetPremiumLogin/LogoutPopupComponent";
import { analyticsPremiumPushFunc } from "../../../article/componentas/premium/utils";
import { LinkProperties, BaseLinkTypes } from "global/components/linkSettings/interfaces"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"
import { YnetPlusUserType } from "../../../ynet1280/ynetHeader/window/ynetplusnavigation/YnetplusSubCategoriesSlotEditor";
import * as classNames from "classnames"

interface YnetPianoLoginProps {
    promoText?: string
    promoLink?: string
    yplusLink?: string
    ynetPlusMenuItems: YnetPlusUserMenuItems[]
    layout?: string
    isPremiumHeaderArticle?:boolean
    domain?:string
}

export interface YnetPlusUserMenuItems {
    link: LinkProperties<BaseLinkTypes>
    title: string
    linkTarget: LinkTargetProperties
    userType: YnetPlusUserType
    itemId: string
}

export enum YnetPlusUserTypeOptions {
    GUEST = "GUEST",
    REGISTERED = "REGISTERED",
    SUBSCRIBED = "SUBSCRIBED"
}
export const PremiumResourceIDs = new Set(["BR6UBQMD", "BR6ZJXVR", "BRFT6AM7", "BRZYMV05", "BRV7Q7DK", "BRFIFUX9", "BR6JG464", "BR9JWA1I", "BROBJCTX", "BR941V52", "BR7A9GLU"]);

export const YnetPianoLogin = (props: YnetPianoLoginProps) => {
    const [user, setUser] = useState(null);
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isMenuOpened, setMenuOpened] = useState(false);
    const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
    const [userType, setUserType] = useState('');
    const ynetPlusMenuItems = props.ynetPlusMenuItems;
    const windowObj = typeof window != "undefined" && window;
    const YitPaywallObj = windowObj && windowObj.YitPaywall
    const loginClassName = classNames(props.isPremiumHeaderArticle ? "YnetArticlePremiumPianoLogin" : "YnetPianoLogin",{"isVisible":isMenuVisible})
    const displayName = user && user.firstName ? user.firstName : user && user.email.split("@")[0];
    const {domain} = props;
    const promoLabel = loginClassName === "YnetPianoLogin" || loginClassName === "YnetPianoLogin isVisible" ? "חודש ראשון ב5.90 ₪" : "";
    
    useEffect(() => {
        initializePianoUser();
    }, []);

    const initializePianoUser = () => {
        windowObj && windowObj.tp && windowObj.tp.push(["init", () => {
            const user = windowObj.tp.pianoId && windowObj.tp.pianoId.getUser();

            if (user) {
                setUser(user);
                setUserType(YnetPlusUserTypeOptions.REGISTERED);
                windowObj.tp.api.callApi("/access/list", {}, (response: any) => {
                    response.data.forEach(activeResource=>{
                        if(PremiumResourceIDs.has(activeResource.resource.rid)){
                            setUserType(YnetPlusUserTypeOptions.SUBSCRIBED);
                        }
                    });
                });
            }else {
                setUserType(YnetPlusUserTypeOptions.GUEST)
            }
      }])
    }

    const toggleMenu = () => {
        setMenuVisible(!isMenuVisible);
        setMenuOpened(!isMenuOpened);
    }

    const handleClickOutside = () => {
        setMenuVisible(false);
        setMenuOpened(false);
    }

    const handleUserDisconnect = () => {
        handleClickOutside();
        analyticsPremiumPushFunc('התנתקות')
        YitPaywallObj.logoutUser();
    }

    const handleOnLogoutClicked = () => {
        toggleMenu();
        setLogoutPopupOpen(true);
    }

    const closeLogoutPopup = () => {
        setLogoutPopupOpen(false)
    }

    const logOutFromPopUp = () => {
        closeLogoutPopup();
        handleUserDisconnect();
    }

    return (
        <div className={loginClassName}>
            {userType === YnetPlusUserTypeOptions.GUEST && <a className="login_premium"
                href={`javascript:YitPaywall.openLoginPopUp()`}>
                <div className="userIcon" />
                <span className="label">{"כניסה/הרשמה"}</span>
            </a>
            }

            {user &&
                <>
                {props.layout === "mobileWeb" && userType === YnetPlusUserTypeOptions.REGISTERED && <SimpleLinkRenderer href={`${domain}/plus/packages`} className="yPlusLabel" ></SimpleLinkRenderer>}
                {props.layout === "mobileWeb" && userType === YnetPlusUserTypeOptions.SUBSCRIBED && <SimpleLinkRenderer href={`${domain}/plus`} className="yPlusLabel" ></SimpleLinkRenderer>}      
                    <div className="loggedUser" onClick={() => toggleMenu()}>
                        <span className="userIcon" />
                        <span className="welcomeUserLabel"> שלום, <span>{displayName}</span></span>
                        <div className="menuTogglerWrapper">
                            <div className={isMenuVisible ? "menuToggler toClose" : "menuToggler toOpen"} />
                        </div>
                    </div>
                </>
            }
            {props.layout !== "mobileWeb" && userType === YnetPlusUserTypeOptions.REGISTERED && <SimpleLinkRenderer href={`${domain}/plus/packages`} className={"promoLabel"}>{/*promoLabel*/}</SimpleLinkRenderer>}
            {props.layout !== "mobileWeb" && userType === YnetPlusUserTypeOptions.SUBSCRIBED && <SimpleLinkRenderer href={`${domain}/plus`} className="yPlusLabel" ></SimpleLinkRenderer>}



            {<div className={classNames("menu",{"isVisible":isMenuVisible})}>
                <div className="menuTogglerUpsideDown" />
                {
                    user && ynetPlusMenuItems && ynetPlusMenuItems.filter(item => item.userType === userType).map(menuItem => {
                        return <div key={menuItem.itemId} className="menuOption"><HyperLinkBuilder href={menuItem.link.link.publishedLink} linkTarget={menuItem.linkTarget}><span dangerouslySetInnerHTML={{ __html: menuItem.title }}></span></HyperLinkBuilder></div>
                    })
                }
                <div className="menuOption" onClick={handleOnLogoutClicked} id="logoutDesktopYnet">התנתקות</div>
            </div>}
            {(isLogoutPopupOpen && user) && <LogoutPopupComponent logOutFromPopUp={logOutFromPopUp} closeLogoutPopupOpen={closeLogoutPopup} />}
        </div>
    )
}
