import { getImgAlt, isAriaHidden, separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"
import { SimpleLinkRenderer } from "../../../../commonComponents/inline/hyperLink"
import { CtechDatePickerMenu } from "../../../ctechWidgets/ctechWideBuzz/components/ctechDatePickerMenu"
import { CalcalistCartoonActions } from "./calcalistCartoonActions"

interface MainArticleItemProps {
    article: Partial<ArticleExtraData>
    toggleDateMenu: () => void
    isDateMenuVisible: boolean
    handleDayClick: (day: any, modifiers?: {}) => void
    isLTR: boolean
    limitDate: Date
    isInitialLimitDate: boolean
    publishedLink: string
}

export class MainArticleItem extends React.Component<MainArticleItemProps, {}>{
    public render() {
        const { article, article: { title, promotionImageDetails, publishedLink, dateUpdatedOnSite  },
            toggleDateMenu, isDateMenuVisible, handleDayClick, isLTR, limitDate, isInitialLimitDate} = this.props;
        const days = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
        const dayPickerPreferences = {
            showOutsideDays: true,
            month: new Date(),
            toMonth: new Date(),
            fixedWeeks: true,
            disabledDays: [
                {
                    after: new Date(new Date().setDate(new Date().getDate() ))
                }
            ]
        }
        const currDayName = days[new Date(`${dateUpdatedOnSite }`).getDay()]
        const currDayDate = separateHourFromDateAndFormatToString(new Date(`${dateUpdatedOnSite }`).toUTCString(), { showDate: true, showHour: false })
        const imageTitle = `${promotionImageDetails.caption}${promotionImageDetails.caption && promotionImageDetails.credit ? "," : ""}${` ${promotionImageDetails.credit}`}`

        return (
            <div className="MainArticleItem" >
                <div className="rightArea" >
                    <div className="currDayTitle">
                        <span className="currDayName">{`יום ${currDayName}`}</span>
                        <span className="currDayDate">{currDayDate}</span>
                    </div>
                    <CtechDatePickerMenu toggleDateMenu={toggleDateMenu} limitDate={limitDate}
                        isDateMenuVisible={isDateMenuVisible} handleDayClick={handleDayClick}
                        dayPickerPreferences={dayPickerPreferences} isLTR={isLTR} isInitialLimitDate={isInitialLimitDate} />
                    <CalcalistCartoonActions articleTitle={title}
                        showComments={true}
                        publishedLink={publishedLink} />
                </div>
                <div className="mediaArea">
                    <div className="MediaCarousel" >
                        <SimpleLinkRenderer href={publishedLink}>
                            <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 654, h: 442 })}
                                title={imageTitle}
                                style={{ width: 654, height: 442 }}
                                className="MainImageMedia"
                                alt={getImgAlt(promotionImageDetails)}
                                aria-hidden={isAriaHidden(promotionImageDetails)}
                            />
                        </SimpleLinkRenderer>
                    </div>
                </div>
            </div>

        )
    }
}
