import { DateDisplay } from "../../commonComponents/inline/dateDisplay/dateDisplay"
import { scrollToElement } from "../../defenitions/blogsAutoFeed/blogsAutoFeedUtils";
import { AccordionSocialsShareComponenta } from './accordionSocialsShareComponenta'
import { convertHtmlToString } from "../../../config/utils";
import {getTranslation} from "../../defenitions/ynet1280/autoTickerRowWidget/autoTickerRowTranslations"

export interface AccordionItem {
    date: Date
    text: string
    title: string
    articleId: string
    shareUrl: string
    author?:string
}

interface accordionItemProps {
    isOpen: boolean
    accordionItem: AccordionItem
    onClick: () => void
    isMobileWeb?: boolean
    languageCode: string
}



export class AccordionSection extends React.Component<accordionItemProps>{

    private lastPublished = () => {
        const { accordionItem } = this.props
        let secDiff = Math.floor((Date.now() - new Date(accordionItem.date).getTime()) / 1000);
        let minutesDiff = Math.floor(secDiff / 60);
        return minutesDiff;
    }

    componentDidMount() {
        const { accordionItem } = this.props
        const itemId = this.getSharedItem()
        if (itemId && itemId === accordionItem.articleId) {
            scrollToElement(itemId, "start", "center")
        }
    }
    getSharedItem = () => {
        const url = typeof window !== "undefined" ? window.location.href : "";
        const urlSliceIndex = url.indexOf("#")
        if (urlSliceIndex !== -1) {
            return url.slice(urlSliceIndex + 1)
        }

        return ""

    }
    render() {
        const { isOpen, accordionItem, onClick, isMobileWeb, languageCode } = this.props;

        const opened = isOpen ? "opened" : "";
        const newItem = isMobileWeb ? this.lastPublished() < 3 : this.lastPublished() < 1
        const newFlash = newItem ? "new" : "";
        const lang = languageCode ? languageCode.slice(0,2) : "he";
        const date = newItem ? getTranslation(lang, "Now") : <DateDisplay date={accordionItem.date}/>;
        const isAuthor = accordionItem.author && accordionItem.author.length > 0;
       return (
            <div className={`AccordionSection  ${accordionItem.articleId}`} id={accordionItem.articleId}>
                <div onClick={onClick} className={`titleRow ${opened}  ${newFlash}`}>
                    <div className="date">{date}
                        {!isOpen && <div className="itemSeparator" />}
                    </div>
                    <div className="title"> {convertHtmlToString(accordionItem.title)}</div>
                    <div className="arrow">
                        <div className={isOpen ? "itemToggler toClose" : "itemToggler toOpen"} />
                    </div>
                </div>
                {isOpen &&
                    <div className="itemBody">
                        {convertHtmlToString(accordionItem.text)}
                        {isAuthor && <br/>}
                        {isAuthor && `(${accordionItem.author})`}
                        <AccordionSocialsShareComponenta
                            shareUrl={accordionItem.shareUrl}
                            title={accordionItem.title}
                            articleId={accordionItem.articleId}
                            isMobileWeb={isMobileWeb}
                        />
                    </div>
                }
            </div>
        )
    }
}
