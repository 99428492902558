
interface yplusSearchProps {
    className: string
    seachPageLink?: string
    onFocusOutSearch?: () => void
    isInputOpened: boolean
    showInput?: () => void
    placeholder?: string
}
interface SearInputState {
    searchValue: string
}
export class YplusSearchInput extends React.Component<yplusSearchProps, SearInputState> {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: ""
        }
    }
    private searchInput;
    private handleSearchValueChanged = (e: React.FormEvent) => {
        const searchValue = (e.target as HTMLInputElement).value;
        this.setState({ searchValue })
    }
    private handleEnter = (e) => {
        const { seachPageLink } = this.props
        var keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13' && seachPageLink.length > 0 && this.state.searchValue.length > 0) {
            window.location.href = `${seachPageLink}?q=${this.state.searchValue}`
        }
    }
    private onShowInput = () => {
        this.props.showInput()
        if (this.searchInput) {
            this.searchInput.focus();
        }

    }

    public render() {
        const { className,
            seachPageLink, onFocusOutSearch, isInputOpened, placeholder } = this.props
        const { searchValue } = this.state
        const inputClassName = isInputOpened ? "opened" : "closed"
        return (
            <div className={`searchDiv ${className}`}>
                <input
                    ref={(input) => this.searchInput = input}
                    value={searchValue}
                    className={`searchInput ${inputClassName}`}
                    onChange={this.handleSearchValueChanged}
                    onKeyPress={(e) => this.handleEnter(e)}
                    placeholder={placeholder ? placeholder : "חפש בכתבות +ynet"}
                    onBlur={onFocusOutSearch} />
                {isInputOpened && searchValue.length > 0 && seachPageLink.length > 0 ?
                    <a href={`${seachPageLink}?q=${searchValue}`} className="searchButton" alt="חיפוש" title="חיפוש" />

                    :
                    <button
                        className="searchButton"
                        onClick={this.onShowInput} />

                }
            </div>

        )
    }
}