import * as classNames from "classnames"
import { isWcmEditor } from "config/serverConfig"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { DateType } from "../../../ticker/windows/dateSelector"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { FlashLinkType } from "../../../ticker/windows/flashLinkSelector"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { AccordionSocialsShareComponenta } from "../../../../siteWidgets/accordion/accordionSocialsShareComponenta"
import { getTranslation } from "../autoTickerRowTranslations"

interface TickerItemSlotProps {
    titleColor: string
    subTitleColor: string
    articlePublishDate: Date
    articleUpdateDate: Date
    title: string
    flashDate: Date
    flashDateType: DateType
    linkTarget: LinkTargetProperties
    link: string
    flashId: string
    flashLinkType: FlashLinkType
    articleId?: string
    isAuto?: boolean
    flashText: string
    tabHref: string
    isFirstItem: boolean
    hasArticleLink: boolean
    publishedLink: string
    onHoverIn: () => void
    onHoverOut: () => void
    author?: string
}

declare let window: Window & {
    languageCode: string
}
export class RowTickerItemSlot extends React.Component<TickerItemSlotProps>{
    private secondsDiff = (d1, d2) => {
        let secDiff = Math.floor((d1 - d2) / 1000);
        let minutesDiff = Math.floor(secDiff / 60);
        return minutesDiff;
    }
    public render() {
        const { title, author, flashDate, link, flashText, linkTarget, articleId, tabHref, isFirstItem, onHoverIn, onHoverOut, hasArticleLink, publishedLink } = this.props
        const href = hasArticleLink ? publishedLink : `${tabHref}#${articleId}`;
        let lastPublished = this.secondsDiff(Date.now(), flashDate.getTime());
        const lang = window && window.languageCode.slice(0, 2) ? window.languageCode.slice(0, 2) : "he";
        const showTooltip = flashText.length > 0 && !hasArticleLink;
        const isAuthor = author && author.length > 0;
        return (
            <div className={classNames(
                "slotView",
                {
                    "inEditMode": isWcmEditor(),
                    "emptySlot": title.length === 0,
                    "now": lastPublished < 3 && isFirstItem,
                    "firstItem": isFirstItem
                }
            )}
                onMouseEnter={onHoverIn}
                onMouseLeave={onHoverOut}
            >
                <div className={classNames("slotTitle", { "withWidth": title.length === 0 })}>
                    <span>
                        {(lastPublished < 3 && isFirstItem) ? getTranslation(lang, "Now") : <DateDisplay date={flashDate} />}
                    </span>
                </div>
                <div className="moreDetails" >
                    <HyperLinkBuilder href={href}
                        linkTarget={linkTarget}
                        children={title} >
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                    </HyperLinkBuilder>
                </div>
                {showTooltip && <div className="tooltip">
                    <div className="text">
                        {flashText}
                        {isAuthor && <br />}
                        {isAuthor && `(${author})`}
                    </div>
                    <AccordionSocialsShareComponenta shareUrl={this.props.link} title={this.props.title} articleId={this.props.articleId} />
                </div>}
            </div>
        )
    }
}