import { SiteItemData } from "../../../../interfaces";
import { isWcmEditor } from "config/serverConfig";
import { DynamicVideoGalleryVerticalDesktop } from "./dynamicVideoGalleryVerticalDesktop";

export interface categoryObj {
    id: string
    categoryColor: string
    name: string
}

interface VideoGalleryVerticalComponentaProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    category: categoryObj
    domain: string
    componentLayoutWidth: number
    channelIconUrl: string
    tabBackgroundColor: string
    tabColor: string
}


export class DynamicVideoGalleryVerticalComponenta extends React.Component<VideoGalleryVerticalComponentaProps, {}>{
    public static siteScriptName = "DynamicVideoGalleryVerticalComponenta";

    public render() {
        const { videoList, isTabVisible, tabTitleText, repeatAds, maxAds, firstAdPlace, category, domain, componentLayoutWidth, channelIconUrl, tabBackgroundColor, tabColor } = this.props;
        const componentClass = `dynamicVideoGalleryVerticalComponenta ${isWcmEditor() ? "editMode" : ""}`;
        return (
            <div className={componentClass}>
                <DynamicVideoGalleryVerticalDesktop
                    videoList={videoList}
                    isTabVisible={isTabVisible}
                    tabTitleText={tabTitleText}
                    repeatAds={repeatAds}
                    maxAds={maxAds}
                    firstAdPlace={firstAdPlace}
                    category={category}
                    domain={domain}
                    componentLayoutWidth={componentLayoutWidth}
                    channelIconUrl={channelIconUrl}
                    tabBackgroundColor={tabBackgroundColor}
                    tabColor={tabColor}/>
            </div>
        )

    }
}
