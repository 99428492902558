import { SiteMedia } from "../../../siteWidgets/siteMedia";
import {  SiteMediaData } from "widgets/interfaces"
import { SiteSimpleImage } from "../../../commonComponents/inline/siteSimpleImage";

export class ArticleStoryComponent extends React.Component<{data:SiteMediaData}, {}> {
    public static siteScriptName = "ArticleStoryComponent";
    render() {
        const { data } = this.props     
        if (data.mediaType == "MEDIA_VIDEO") {
         return (    
             <SiteMedia width={360} height={640} data={data} videoSettings={{isVideoLightbox:false, hideVideoAds:false}} />                
            )
        } else if (data.mediaType == "MEDIA_IMAGE") {
            return (
                <SiteSimpleImage src={data.url} alt={data.alt} width={360} height={640} />
            )
        }
    }
}
