import RadioScheduleListItem from "./radioScheduleListItem";
import { RadioProgramItem } from "../../../radioHompage/components/radioHomepagePlayerComponenta";
interface RadioScheduleListRowProps {
    radioScheduleList: RadioProgramItem[]
    currentShow: number
    isMobileWeb: boolean
    offsetStart: number
    offsetEnd: number
}

interface RadioScheduleListRowState {
    programToShow: number
}



export class RadioScheduleListRow extends React.Component<RadioScheduleListRowProps, RadioScheduleListRowState> {
    constructor(props) {
        super(props)
        this.state = {
            programToShow: 0
        }
    }

    private CARD_SIZE: number = 265

    componentDidMount() {
        const { currentShow, isMobileWeb, radioScheduleList } = this.props
        if (currentShow !== -1) {
            this.setState({ programToShow: currentShow })
            const selectedEl = document.querySelector(`#schedule-list-item_${currentShow}`)
            if (selectedEl && radioScheduleList.length) {
                let offsetCalculation = (selectedEl as any).offsetLeft - this.CARD_SIZE * 1.5
                if (isMobileWeb) {
                    this.CARD_SIZE = (document.querySelector(`#schedule-list-item_${currentShow}`) as HTMLElement).offsetWidth
                    offsetCalculation = (selectedEl as any).offsetLeft - this.CARD_SIZE / 2.6
                }
                document.querySelector('.schedule-list').scrollLeft = offsetCalculation;
            }
        }
    }

    moveToProgramShow = (action: string) => {
        const { isMobileWeb } = this.props
        if (isMobileWeb) {
            return
        }
        const options = {
            top: 0,
            left: 0,
            behavior: 'smooth'
        };
        options.left = this.CARD_SIZE
        if (action === "forward") {
            options.left = - this.CARD_SIZE
        }
        document.querySelector('.schedule-list').scrollBy(options as any)
    }

    render() {
        const { radioScheduleList, currentShow, offsetEnd, offsetStart } = this.props

        const items = []
        for (let index = 0; index < radioScheduleList.length; index++) {
            const item = radioScheduleList[index];
            if (index >= currentShow - offsetStart && index <= currentShow + offsetEnd - 1) {
                items.push(
                    <RadioScheduleListItem
                        item={item}
                        index={index}
                        isCurrentShow={index === currentShow}
                        key={index}
                        isProgramFinished={new Date().getTime() > new Date(item.endProgram).getTime()}
                    />
                )
            }
            if (index > currentShow + offsetEnd - 1) {
                break;
            }
        }

        return (
            <div className="schedule-list-row">
                <div className="schedule-list-header">
                    <div className="title">
                        לוח שידורים
                    </div>
                </div>

                <div className="schedule-row-wrapper">
                    <div className="white-fade-right">
                        <div className="schedule-list-arrow right" onClick={() => this.moveToProgramShow("backward")} />
                    </div>

                    <div className="schedule-list">
                        {items}
                    </div>

                    <div className="white-fade-left">
                        <div className="schedule-list-arrow left" onClick={() => this.moveToProgramShow("forward")} />
                    </div>
                </div>
            </div>
        )
    }
}