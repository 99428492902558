import { HyperLinkBuilder, HyperLink } from "../../../commonComponents/inline/hyperLink";
import { LinkTargetProperties, BaseLinkTypes, LinkProperties } from "../../../../global/components/linkSettings/interfaces";
import * as classNames from "classnames"
import { PageComponentaContext } from "../../../interfaces";
require('./calcalist_site_loginComponenta.less')

interface calcalistLoginState {
    user: { firstName: string }
    isMenuVisible: boolean
    isUserSubscribed: boolean
}

export interface CalcalistMenuItem {
    id: string
    name: string
    link: string
    catId: string
    url: string
    targetLink?: LinkTargetProperties
}

interface calcalistLoginProps {
    menuItems?: CalcalistMenuItem[]
    subscribeBtnLink?:{link:string,linkTarget:LinkTargetProperties};
}

export class CalcalistPianoLoginAndShare extends React.Component<Partial<PageComponentaContext> & calcalistLoginProps , calcalistLoginState>{
    
    static siteScriptName = "CalcalistPianoLoginAndShare"
    constructor(props) {
        super(props)
        if (typeof window != 'undefined') {
            window.tp = window.tp || [];
        }
        this.state = {
            user: null,
            isMenuVisible: false,
            isUserSubscribed: false
        }
    }
    componentDidMount() {

        window.tp.push(["init", () => {

            const user = window && window.tp && window.tp.pianoId.getUser();

            if (user) {
                window.tp.api.callApi("/access/list", {}, (response: any) => {
                    this.setState({ user, isUserSubscribed: response.count > 0 });
                });
            }

            window.tp.push(['addHandler', 'logout', () => {


                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: 'Logout_Events',
                    Category: 'CalcalistPro - Logout',
                    Action: 'CalcalistPro  User icon',
                    Label: 'Logout Successfully'
                });
            }])
        }])

    }

    private toggleMenu = () => {
        this.setState(pstate => {
            return {
                isMenuVisible: !pstate.isMenuVisible,
            }
        })
    }


    private discconectUser = () => {
        window.tp.pianoId.logout(()=>{
            this.setState({ isMenuVisible: false, user: null, isUserSubscribed: false },()=>{
                location.reload();
            });
        });
    }

    public handleConnectButton = () => {

        const tp = window.tp || [];

        tp.push(["init", () => {
            tp.pianoId.show({
                width: "360",
                screen: 'login',
                loggedIn: (data) => {
                    console.log('user ', data.user, ' logged in with token', data.token);
                    tp.api.callApi("/access/list", {}, (response) => {
                        this.setState({ user: data.user, isUserSubscribed: response.count > 0 });
                    });
                }
            });
        }]);

        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'Login_Events',
                Category: 'CalcalistPro - Login',
                Action: 'Login Source',
                Label: 'CalcalistPro User icon'
            });
        }
    }


    private handleMenuItem =(itemName)=>{
        const { user, isUserSubscribed } = this.state
        let action:string;

        if(!user){
            action = "Guest user"
        }else{
            action = isUserSubscribed?"Logged user with subscription": "Logged user without subscription";
        }

        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'GA_Events',
                Category: 'CalcalistPro - UserMenu',
                Action: action,
                Label: itemName
            });
        }

    }


    public render() {
        const { user, isMenuVisible, isUserSubscribed } = this.state
        const { menuItems,subscribeBtnLink} = this.props

        return (
            <>
                <div className="calcalistPianoLoginAndShare">
                    <a className="userAction RedMailLink" href='https://www.calcalist.co.il/redmail'>
                        <span className="userText" title="דואר אדום" >דואר אדום</span>
                        <span className="icon RedMail" title="דואר אדום" />
                    </a>
                    <a className="userAction ConfLink" target="_blank" href="https://www.calcalist.co.il/conference/home/0,7340,L-5144,00.html">
                        <span className="userText" title="ועידות">ועידות</span>
                        <span className="icon Conf" title="ועידות" />
                    </a>

                    <div className="verticalSeperator"></div>
                    {!isUserSubscribed && <HyperLinkBuilder className="subscribeButton" href={subscribeBtnLink.link} linkTarget={subscribeBtnLink.linkTarget}>מינוי לאתר  </HyperLinkBuilder> }
                </div>

                {!user &&
                    <div onClick={this.handleConnectButton} className="connectButton">התחברות</div>
                }

                {user &&
                    <div onClick={this.toggleMenu} className={classNames("logged-user-container", { "user-menu": isMenuVisible })}>
                        <div className="welcome-user-container">
                            <div className="user-login-icon"></div>
                            <div className="user-label-container">
                                <span className="user-label-welcome">שלום</span>
                                <span className="user-label-name">{user.firstName}</span>
                            </div>
                            <div className={classNames("user-login-arrow", { "rotate": isMenuVisible })}></div>
                        </div>

                        {isMenuVisible &&
                            <div className="user-menu">
                                <div className="menuItem" onClick={()=>this.handleMenuItem("ניהול חשבון")}>
                                    <a href={"https://qa.calcalist.co.il/category/31602"}>
                                        {"ניהול חשבון"}
                                    </a>
                                </div>
                                {menuItems.map((item, index) => {
                                    return (
                                        <div className="menuItem" key={index} id={item.id} onClick={()=>this.handleMenuItem(item.name)} >
                                            <HyperLinkBuilder href={item.link} linkTarget={item.targetLink}>
                                                {item.name}
                                            </HyperLinkBuilder>
                                        </div>
                                    )
                                })}
                                <div onClick={this.discconectUser} className="menuItem-disconnect">התנתקות</div>
                            </div>}
                    </div>}
            </>)
    }
}