import { YnetWeatherInHeader, WeatherData } from "../../ynetHeader/components/newYnetWeatherComponenta";
import * as axios from "axios"
import weatherCodeTranslate from '../../../weather/translations/weatherCodeTranslations'
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import * as filter from "lodash/filter"
import { isWcmEditor } from "../../../../../config/serverConfig";

interface WeatherProps {
    languageCode: string
    showDescription?:boolean
}

declare let window: Window & {
    languageCode: string
}

export class WeatherMultiArticleItem extends React.Component<WeatherProps,{}> {
    public static siteScriptName = "WeatherMultiArticleItem"
    constructor(props) {
        super(props)
    }

    public render() {
        const {showDescription,languageCode} = this.props
        const lang = isWcmEditor()? WCM_LANG_CODE : (typeof window !== "undefined" &&  languageCode  && languageCode.slice(0, 2)) || "" ;
        return (
            <div className="weatherItem">
                <span className="title">{getWordTranslation(lang,'עכשיו') }</span>
                <YnetWeatherInHeader showDescription={showDescription} placement={"weatherInMultiArticle"} lang={lang} />
                <SimpleLinkRenderer className="goToWeather" href={getWordTranslation(lang, 'linkToWeather')} >
                    {getWordTranslation(lang,'לתחזית המלאה') }
                </SimpleLinkRenderer>
            </div>
        )
    }
}

export const getWordTranslation = (lang, text: string) => {
    var obj = filter(words, function (v, k) {
        return k === lang;
    });
    var content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]

}

export const words =
{
    "he": {
        "עכשיו" : "עכשיו",
        'לתחזית המלאה' : 'לתחזית המלאה' ,
        'דני רופ'  :'דני רופ' ,
        'linkToWeather' : 'https://www.ynet.co.il/weather'

    },
    "en": {
        "עכשיו" : "now",
        'לתחזית המלאה'  : 'full forecast',
        'דני רופ'  : 'Dani Roop',
        'linkToWeather' : 'https://www.ynetnews.com/weather'
    },
    "ru": {
        "עכשיו" : "сейчас",
        'לתחזית המלאה'  : 'полный прогноз',
        'דני רופ'  : 'Данни Рупп',
        'linkToWeather' : 'https://www.vesty.co.il/weather'
    },
  
}

