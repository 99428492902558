import { FacebookShareButton } from "../facebook/facebookShareButton"
import { TwitterShareButton } from "../twitter/twitterShareButton"
import { MailShareButton } from "../mail/mailShareButton"
import { CopyButton } from "global/components/copyButton/copyButton"
interface ArticleShareButtonsProps {
    textToShare?: string
    className?: string
    handleOnClick?: () => void
    url?: string
    isCopyButtonVisible?: boolean
    categoryOfAnalytics?: string
    isEnglish?: boolean
    lang?: string
}
export class ArticleShareButtons extends React.Component<ArticleShareButtonsProps, {}>{
    static defaultProps = { isCopyButtonVisible: false, lang: "he" }

    render() {
        const { textToShare, className, isCopyButtonVisible, url, isEnglish, lang, ...props } = this.props
        const documentTitle = document.title
        return <div className={className}>
            {isCopyButtonVisible && <CopyButton id={"copy_" + url} classes="tickerPopUpCopyButton" isAlert={true} textToCopy={url} />}
            <FacebookShareButton isEnglish={isEnglish} url={url} textToShare={textToShare || documentTitle} {...props} />
            <TwitterShareButton isEnglish={isEnglish} textToShare={textToShare || documentTitle} {...props} />
            <MailShareButton isEnglish={isEnglish} lang={lang} textToShare={textToShare} {...props} />
        </div>
    }
}