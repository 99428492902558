import Slider from "react-slick"
import { useState, useEffect } from "react";
import { ArticleImagesFullScreenGalleryImageCounter } from "./articleImagesFullScreenGalleryImageCounter";
import { ArticleImageGallery } from "../articleImageGalleryComponenta/articleImageGalleryComponenta"

interface MobileArticleImagesGalleryProps {
    images: ArticleImageGallery[]
    articleId: string
    close: (currentImageIndex: number) => void
    isVisible: boolean
    index: number
}

export const ArticleImagesFullScreenGalleryMobile = ({ images, close, index,isVisible }: MobileArticleImagesGalleryProps) => {

    const [currentImageIndex, setCurrentImageIndex] = useState<number>(index)
    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
        rtl: false,
        touchMove: true,
        initialSlide: index,
        afterChange: (next) => setCurrentImageIndex(next),
    }
    let numOfImages = images.length ? images.length : 0
    const isImageGotCaption = images && images[currentImageIndex] && images[currentImageIndex].caption
    const isImageGotCredit = images && images[currentImageIndex] && (images[currentImageIndex].credit !== "") && (images[currentImageIndex].credit !== " ")

    const openShare = async () => {
        const shareData = { url: window.location.href, title: document.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }


    useEffect(() => {
        setCurrentImageIndex(index)
    }, [index]);

    if (isVisible) {
        return (
            <div key={"MobileArticleImagesGallery"} role="dialog"
                aria-label="images gallery"
                id="images_gallery" className="ArticleImagesFullScreenGalleryMobile"
                onClick={()=>close(currentImageIndex)} tabIndex={0}>

                <div className="GalleryWrapper" onClick={e => e.stopPropagation()}>
                    <div className={`galleryHeaderWrapper`}>
                        <button className="closeBtn yicon-x"
                            title={__("Close")} aria-label={__("Close")} onClick={()=>close(currentImageIndex)} />

                        <ArticleImagesFullScreenGalleryImageCounter
                        numOfImages={numOfImages}
                        isMobile={true}
                        currentImageIndex={currentImageIndex}
                        />
                        <button className="shareImageBtn" onClick={openShare}></button>
                    </div>
                    <Slider {...settings} >
                        {_.map(images, (image) =>
                            <div className="imageContainer" key={image.imageId}>
                                <img src={image.imageSrc} role="image" aria-label={image.caption} />
                            </div>
                        )}
                    </Slider>
                    <div className={`imageInfoAndShareOptions`}>
                        <div className="imageInfo">
                            {isImageGotCaption && <div className="caption">{images[currentImageIndex].caption}</div>}
                            {isImageGotCredit && <div className="credit">({images[currentImageIndex].credit})</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}
