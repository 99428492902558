import { MediaContentTypes } from "constants/contentTypes"
import { SiteMediaData, SiteVideoData, SiteImageData, YnetTvSiteVideoData } from "../interfaces"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { SiteYnetTvLightboxPlaylistVideoPlayer } from "./videoPlayer/ynetTvLightBoxPlayer"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../commonComponents/inline/hyperLink"
import { isYouTubeGlobalSettingEnabled } from "./videoPlayer/videoPlayerUtils"
import { SiteVideoLightbox } from "./videoPlayer/genericLightboxVideoPlayer/siteVideoLightbox";
import { SiteVideoInline } from "./videoPlayer/genericInlineVideoPlayer/siteVideoInline"
import { SiteYnetTvLightboxPlaylistYouTubePlayer } from "./videoPlayer/youtubePlayer/ynetTvYoutubeLightBoxPlayer";
import { FrontCopyButton } from "../commonComponents/inline/frontCopyButton/frontCopyButton";

export interface SiteMediaProps {
    height: number
    width: number
    data: SiteMediaData
    videoSettings: VideoSettingsData
    allMedias?: SiteMediaData[]
    onSelect?: () => void
    onPlayStarted?: () => void
    onPlayStopped?: () => void
    itemLinkUrl?: string
    linkTarget?: LinkTargetProperties
    shouldAddArticleAutoplayIndicator?: boolean
    isImageLinkable?: boolean
    onLightBoxOpened?: () => void
    onLightBoxClosed?: () => void
    articleId?: string
    isBigVideo?: boolean
    isDataTbLink?: boolean
    videoShareLink?: string
    videoCategoryId?: string
}
interface YnetTvMediaProps {
    height: number
    width: number
    data: SiteMediaData
    videoSettings: VideoSettingsData
    allMedias?: YnetTvSiteVideoData[]
    onSelect?: () => void
    onPlayStarted?: () => void
}
export const SiteMedia = (props: SiteMediaProps) => {
    const { allMedias, width, height, data, onSelect, isImageLinkable, itemLinkUrl, shouldAddArticleAutoplayIndicator, linkTarget, articleId, isBigVideo } = props;

    if (data.mediaType == MediaContentTypes.MEDIA_IMAGE) {
        if (isImageLinkable) return (<HyperLinkBuilder href={itemLinkUrl} articleId={articleId} linkTarget={linkTarget} shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator} isDataTbLink >
            <SiteImageMedia width={width} height={height} data={data} onSelect={onSelect} />
        </HyperLinkBuilder>)
        else return <SiteImageMedia width={width} height={height} data={data} onSelect={onSelect} />

    } else if (data.mediaType == MediaContentTypes.MEDIA_VIDEO) {
        return (
            <div style={{ width, height }} className="videoMediaContainer">
                <SiteVideoMedia {...props} data={data} onSelect={onSelect} />
            </div>
        )
    }
    else {
        if (data.mediaType == MediaContentTypes.MEDIA_LIVE) {
            const videoData = { ...data, isLive: true, mediaType: MediaContentTypes.MEDIA_VIDEO, isPlayerBarVisible: true, isBigVideo: isBigVideo }
            return <SiteVideoMedia {...props} data={videoData} onSelect={onSelect} />
        }
        if (data.mediaType == MediaContentTypes.MEDIA_IFRAME) {
            return <iframe src={data.url} width={"100%"} height={"100%"} frameBorder="0" scrolling={"no"} />
        }
    }

}
export const siteScriptName = "YnetTvSiteMedia";
export const YnetTvSiteMedia = (props: YnetTvMediaProps) => {

    const { width, height, data, onSelect, allMedias } = props;
    if (data.mediaType == MediaContentTypes.MEDIA_IMAGE) {
        return <SiteImageMedia width={width} height={height} data={data} onSelect={onSelect} />
    } else if (data.mediaType == MediaContentTypes.MEDIA_VIDEO) {
        return (
            <div style={{ width, height }}>
                <YnetTVSiteVideoMedia {...props} data={data} onSelect={onSelect} />
            </div>
        )
    }
}

YnetTvSiteMedia["siteScriptName"] = "YnetTvSiteMedia"

interface SiteVideoMediaProps {
    data: SiteVideoData | YnetTvSiteVideoData
    videoSettings: VideoSettingsData
    allMedias?: SiteMediaData[]
    runArticleAutoPlayLogic?: boolean
    videoShareLink?: string
    popupText?: string
    copyUrl?: string
    siteLayoutType?: string
    onSelect?: () => void
    onPlayStarted?: () => void
    onPlayStopped?: () => void
    onVideoEnded?: () => void
    onVideoError?: () => void
    onLightBoxClosed?: () => void
    onLightBoxOpened?: () => void
}

export const SiteVideoMedia = ({ data, allMedias, videoSettings: { isVideoLightbox, hideVideoAds }, onSelect, onPlayStarted, onPlayStopped, onVideoEnded, onVideoError, runArticleAutoPlayLogic, onLightBoxClosed, onLightBoxOpened, videoShareLink, popupText, copyUrl, siteLayoutType }: SiteVideoMediaProps) => {
    if (isVideoLightbox) {
        const allVideos = Array.isArray(allMedias) ?
            allMedias.filter(m => m.mediaType === MediaContentTypes.MEDIA_VIDEO) as SiteVideoData[]
            : [data];
        return <SiteVideoLightbox
            data={data}
            applyStickyness={false}
            allVideos={allVideos} onSelect={onSelect}
            onLightBoxClosed={onLightBoxClosed}
            onLightBoxOpened={onLightBoxOpened}
            hideVideoAds={hideVideoAds} />
    } else {
        return (
            <>
                <SiteVideoInline
                    data={data}
                    applyStickyness={true}
                    hideVideoAds={hideVideoAds}
                    onSelect={onSelect}
                    videoShareLink={videoShareLink}
                    onPlayStarted={onPlayStarted}
                    onPlayStopped={onPlayStopped}
                    onVideoEnded={onVideoEnded}
                    onVideoError={onVideoEnded}
                    runArticleAutoPlayLogic={runArticleAutoPlayLogic} />
                {videoShareLink && siteLayoutType !== "mobileWeb" && <FrontCopyButton popupText={popupText} url={copyUrl} />}
            </>
        )
    }
}
SiteVideoMedia["siteScriptName"] = "SiteVideoMedia"

const YnetTVSiteVideoMedia = ({ data, allMedias, videoSettings: { isVideoLightbox, hideVideoAds }, onSelect, onPlayStarted }: SiteVideoMediaProps) => {
    const allVideos = allMedias.filter(m => m.mediaType === MediaContentTypes.MEDIA_VIDEO) as YnetTvSiteVideoData[];

    if (isYouTubeGlobalSettingEnabled(data)) {
        return <SiteYnetTvLightboxPlaylistYouTubePlayer data={data} allVideos={allVideos} hideVideoAds={hideVideoAds} onSelect={onSelect} />
    }
    return <SiteYnetTvLightboxPlaylistVideoPlayer data={data} allVideos={allVideos} hideVideoAds={hideVideoAds} onSelect={onSelect} />
}

interface SiteImageMediaProps {
    height: number
    width: number
    data: SiteImageData
    onSelect?: () => void
}

export const SiteImageMedia = ({ width, height, data, onSelect }: SiteImageMediaProps) => {
    const { url, title, alt, ariaHidden } = data;
    const style = { width }
    return <img className="SiteImageMedia" src={url} style={style} title={title} alt={alt} aria-hidden={ariaHidden} onClick={onSelect} />
}

