

interface GoogleSearchComponentaProps {
    placeholder: string
    link: string
    disableInWcm: string
}

export class GoogleSearchComponenta extends React.Component<GoogleSearchComponentaProps> {

    public static siteScriptName = "GoogleSearchComponenta";

    private inputRef;

    constructor(props: GoogleSearchComponentaProps) {
        super(props);
    }

    handleOnSerach = (event: React.MouseEvent | React.KeyboardEvent) => {



        let searchLink;
        if ((event as React.KeyboardEvent).key === "Enter") {
            searchLink = `${this.props.link}?q=${((event.target as HTMLInputElement).value)}`;
            window.open(searchLink);
        }

        if (event.type === "click") {
            searchLink = `${this.props.link}?q=${this.inputRef.value}`;
            window.open(searchLink);
        }
    }


    render() {
        const { placeholder, disableInWcm } = this.props;

        return (
            <div className="google-search-contianer-componenta" style={{ pointerEvents: disableInWcm }}>
                <input ref={ref=>this.inputRef = ref} onKeyDown={(event) => this.handleOnSerach(event as React.KeyboardEvent)} alt="חיפוש" className="google-search" aria-label="חיפוש" type="text" placeholder={placeholder} />
                <div onClick={(event) => this.handleOnSerach(event as React.MouseEvent)} className="search-icon"></div>
            </div>
        )
    }
}
