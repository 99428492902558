import * as axios from "axios"

const ISO_8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/;

export const addAxiosDateTransformerSettings = (axiosSettings: Axios.AxiosXHRConfigBase<any> = {}) => {
    let transformResponseBase = axiosSettings.transformResponse || []// as [<U>(data: any, headers?: any) => U]
    const x = { transformResponse: transformResponseBase };
    if (!Array.isArray(transformResponseBase)) {
        transformResponseBase = [transformResponseBase];
    }
    var y = function transformResponse(data, headers?: any) {
        /*eslint no-param-reassign:0*/
        if (typeof data === 'string') {
            try {
                data = JSON.parse(data, dateParser);
            } catch (e) {
                return data;
            }
        }
        return data;
    }
    return { ...axiosSettings, transformResponse: [y] }
    // return {
    //     ...axiosSettings,
    //     transformResponse: transformResponseBase.concat([
    //         function transformResponse(data, headers:any) {
    //             /*eslint no-param-reassign:0*/
    //             if (typeof data === 'string') {
    //                 try {
    //                     data = JSON.parse(data, dateParser);
    //                 } catch (e) {
    //                     return data;
    //                 }
    //             }
    //             return data;
    //         }
    //     ])
    // }
}

export const dateParser = (key: string, value: any) => {

    if (typeof value === 'string' && ISO_8601.test(value)) {
        var newValue;
        if (!value.endsWith("Z")) {
            newValue = `${value}Z`;
            console.error("WARNING date without Z ending", value)
        }
        else newValue = value
        return new Date(newValue);
    }
    return value;
}
