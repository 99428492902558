import DropDownItem from "./DropDownItem"
const clickOutside = require('react-click-outside');

export interface CityLocation {
    id: number
    name: string
    nameHeb: string
    isWorld: boolean
    country: string
    countryHeb: string
    weatherCode: number
    desc: string
    minimumTemperature: number
    maximumTemperature: number
    currentTemperature: number
    feelsLike: number
    url: string
}

interface WeatherProps {
    selectedLocationId: number
    updateTime: string
    locations: CityLocation[]
    ariaLabelContent: string,
    lang: string,
}

interface State {
    selectedCity: CityLocation
    isSearchBoxVisibile: boolean
    searchQuery: string
}

@clickOutside
export class WeatherCitySelectorRow extends React.Component<Partial<WeatherProps>, State> {
    static siteScriptName = "WeatherCityDropDown";
    constructor(props: WeatherProps) {
        super(props);
        this.state = {
            selectedCity: props.locations.find(city => city.id === props.selectedLocationId),
            isSearchBoxVisibile: false,
            searchQuery: "",
        }
    }

    private inputRef: any;

    componentDidMount() {
        const { selectedLocationId } = this.props
        this.onCityChangeHandler(selectedLocationId)
        document.addEventListener("keydown", this.handleEscapeHandler, false);
    };

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleEscapeHandler, false);
    }

    onCityChangeHandler = (cityId: number) => {
        const { locations } = this.props
        const selectedCity = locations.find(city => city.id === cityId)
        this.setState({ selectedCity: selectedCity, searchQuery: "" })
    }

    onSearchHandler = (value) => {
        this.setState({ searchQuery: value })
    }

    toggleSearchBox = () => {
        this.setState({ isSearchBoxVisibile: !this.state.isSearchBoxVisibile })
    }

    handleClickOutside = () => {
        this.setState({ isSearchBoxVisibile: false, searchQuery: "" });
    }

    handleEscapeHandler = (e) => {
        const { isSearchBoxVisibile, searchQuery } = this.state
        const { locations } = this.props
        if (e.key === "Escape" && isSearchBoxVisibile) {
            this.handleClickOutside()
        } else if (e.key === "Tab") {
            this.toggleSearchBox()
        } else if (e.key === "Enter" && isSearchBoxVisibile) {
            const filteredCities = searchQuery ? locations.filter((city: CityLocation) => {
                return city.name.toLowerCase().includes(searchQuery.toLowerCase())
            }) : locations
            window.location.href = filteredCities[0].url;
        }
    }
    public render() {

        const { ariaLabelContent, locations, lang } = this.props
        const { searchQuery, isSearchBoxVisibile } = this.state

        const filteredCities = searchQuery ? lang === "he" ?
            locations.filter((city: CityLocation) => {
                return city.nameHeb.includes(searchQuery)
            })
            : locations.filter((city: CityLocation) => {
                return city.name.toLowerCase().includes(searchQuery.toLowerCase())
            }) : locations.filter((city: CityLocation) => {
                return !city.isWorld
            })

        const dropDownItems = !!filteredCities.length && filteredCities.map((city: CityLocation) => {
            return (
                <DropDownItem
                    key={city.id}
                    city={city}
                    lang={lang}
                />
            )
        })

        return (
            <div>
                <div style={{ width: "100%" }}>
                    {isSearchBoxVisibile ?
                        <div>
                            <div className="city-selector city-selector-bottom-border-off" aria-label={ariaLabelContent}>
                                <div className="search-box-bottom-border">
                                    <input
                                        placeholder={`${__("Search any location")}...`}
                                        value={searchQuery}
                                        onChange={(e: any) => this.onSearchHandler(e.target.value)}
                                        className="search-input"
                                        name="input"
                                        ref={ref => this.inputRef = ref}
                                        onBlur={() => this.inputRef.focus()}
                                        autoFocus
                                    />
                                </div>
                                <div className="arrow-up" onClick={() => this.toggleSearchBox()} />
                            </div>
                            <div className="dropdown">
                                <div className="dropdown-window">
                                    {dropDownItems}
                                </div>
                            </div>
                        </div> :
                        <div className="city-selector" onClick={() => this.toggleSearchBox()} aria-label={ariaLabelContent}>
                            <div className="city-title" >
                                {lang === "he" ? this.state.selectedCity && this.state.selectedCity.nameHeb : this.state.selectedCity && this.state.selectedCity.name}
                            </div>
                            <div className="arrow-down" />
                        </div>}
                </div>
            </div>
        )
    }
}