import { SiteMediaData, SiteItemData, SiteImageData } from "../../../interfaces"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { IFieldDisplayData } from "../../../commonDataObjects/interfaces"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { MediaContentTypes } from "constants/contentTypes"



interface MultiImagesLabelsFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    itemList: Partial<SiteItemData>[]
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    titleColor: string
    lastHeightSize?: number
    isBottomLinkVissible?: boolean
    bottomLinkColor: string
    isLabelVisible: boolean
    bgColor: string
}

interface MultiImagesLabelsFrontState {
    rightOffset: number
    selectedIndex: number
}


export class MultiImagesLabelsFront2 extends React.Component<MultiImagesLabelsFrontProps, MultiImagesLabelsFrontState>{
    public static siteScriptName = "MultiImagesLabelsFront2";
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            rightOffset: 0,

        }
    }
    private numberOfImagesToView = (width, imageWidth) => {
        const itemWidth = imageWidth + 17
        const numberOfImagesToView = Math.floor(width / itemWidth)
        return numberOfImagesToView;
    }

    private handleScrollLeftClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { itemList, width, imageWidth, imageHeight } = this.props
        const single_media_item_width = imageWidth + 17
        let newIndex = selectedIndex + 1;
        const scrollBarWidth = width - 76
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - this.numberOfImagesToView(width - 76, imageWidth) * single_media_item_width)
        const newRightOffset = rightOffset - single_media_item_width
        const lastNewOffset = rightOffset - lastScrollingsize
        if (selectedIndex < itemList.length - this.numberOfImagesToView(scrollBarWidth, imageWidth) - 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === itemList.length - this.numberOfImagesToView(scrollBarWidth, imageWidth) - 1) {
            this.setState({
                rightOffset: lastNewOffset,
                selectedIndex: newIndex
            })
        }

    }

    private handleScrollRightClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { itemList, width, imageWidth, imageHeight } = this.props
        const scrollBarWidth = width - 76
        const single_media_item_width = imageWidth + 17
        let newIndex = selectedIndex - 1
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - this.numberOfImagesToView(scrollBarWidth, imageWidth) * single_media_item_width)
        const firstOffset = rightOffset + lastScrollingsize
        const newRightOffset = rightOffset + single_media_item_width

        if (selectedIndex > 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === 1) {
            this.setState({
                rightOffset: 0,
                selectedIndex: 0
            })
        }
        /*  else if (selectedIndex === itemList.length - this.numberOfImagesToView(scrollBarWidth, imageWidth)) {
              this.setState({
                  rightOffset: firstOffset,
                  selectedIndex: newIndex
              })
          }*/
    }

    public render() {
        const { width, imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings, titleColor, lastHeightSize,
            isLabelVisible, isBottomLinkVissible, bottomLinkColor, bgColor } = this.props
        const { selectedIndex, rightOffset } = this.state
        let isRightArrowAvailble = selectedIndex !== 0
        let isLeftArrowAvailble = selectedIndex !== itemList.length - this.numberOfImagesToView(width - 76, imageWidth)
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        return (
            <div className="slotsContent" style={{ height: lastHeightSize }}>
                <button
                    disabled={!isRightArrowAvailble}
                    className="scrollRightBtn slotListArrow"
                    aria-label="scroll right"
                    title="scroll right"
                    style={{ top: isLabelVisible ? 22 : 2 }} onClick={(e) => this.handleScrollRightClicked()} />
                <div className="frame_forList">
                    <div className="slotListWrapper" style={{ "marginRight": rightOffset }} >
                        {itemList.map((slot, index) =>
                            <div className="slotView" key={`slotItem_${slot.itemId}_${index}`}
                                style={{ background: bgColor }}>
                                {isLabelVisible && <div className="mediaLabel">

                                    {slot.labelUrl !== "" && <HyperLinkBuilder
                                        href={slot.labelLink}
                                        linkTarget={slot.labelLink !== undefined && slot.labelLinkTarget}
                                        ariaLabel={slot.labelName}
                                    >
                                        <img src={slot.labelUrl} width={182} aria-hidden="true" title={slot.labelName} height={20} />
                                    </HyperLinkBuilder>}
                                </div>}
                                <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                    <SiteMedia itemLinkUrl={slot.titleLink} linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink} isImageLinkable={true} data={slot.media !== undefined ? slot.media : emptyMedia}
                                        width={imageWidth} height={imageHeight} videoSettings={videoSettings} />

                                </div>
                                <div className="slotTextArea">
                                    {fieldsDisplaySettings.isTitleVisible && <div className="slotTitle twoRow">
                                        <HyperLinkBuilder
                                            href={slot.titleLink}
                                            linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                            children={slot.title} >
                                            <span style={{ color: titleColor }} >
                                                <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                                            </span>
                                        </HyperLinkBuilder>
                                    </div>}
                                    {isBottomLinkVissible && slot.bottomText !== undefined && <div className="slotBottomLink" >
                                        <HyperLinkBuilder
                                            href={slot.bottomTextLink !== undefined && slot.bottomTextLink}
                                            linkTarget={slot.bottomTextLinkTarget !== undefined && slot.bottomTextLinkTarget}
                                            children={slot.bottomTextLink} >
                                            <span style={{ color: bottomLinkColor }} >
                                                <div dangerouslySetInnerHTML={{ __html: slot.bottomText }} />
                                            </span>
                                        </HyperLinkBuilder>
                                    </div>}
                                </div>     </div>)}</div>
                </div>
                <button
                    disabled={!isLeftArrowAvailble}
                    title="scroll left"
                    aria-label="scroll left"
                    className="scrollLeftBtn slotListArrow"
                    style={{ top: isLabelVisible ? 22 : 2 }}
                    onClick={(e) => this.handleScrollLeftClicked()} />



            </div>)
    }
}
