import { RadioProgramItem } from "./radioHomepagePlayerComponenta";
import Slider from "react-slick"
import { useEffect, useState, useRef, RefObject } from "react"
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { AudioRendererProps } from "../../floatingPlayer/audioPlayButton";

interface FrontRadioHomepagePlayerProps extends AudioRendererProps {
    tabColor: string
    title: string
    tabButtonTitle: string
    tabButtonHref: string
    tabButtonLink: LinkProperties<BaseLinkTypes>
    tabButtonLinkTarget: LinkTargetProperties
    programItem: RadioProgramItem
}


export const FrontRadioHomepagePlayer = (props: FrontRadioHomepagePlayerProps) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: props.programItem.title.length * 200,
        autoplaySpeed: 0,
        rtl: true,
        arrows: false,
        cssEase: "linear"
    }
    const { tabColor, title, programItem, isAudioPlaying, onTogglePlayPause, tabButtonTitle, tabButtonHref, tabButtonLink, tabButtonLinkTarget, onRefChanged } = props
    const [programTitleWidth, setProgramTitleWidth] = useState("0")
    const [isTitleOverFlowing, setIsTitleOverFlowing] = useState(null)


    const getShowTime = (item: RadioProgramItem) => {
        if (item && !item.hideHours) {
            return `${item.startTime} - ${item.endTime} | `
        }
        return ""
    }

    const getTitleOverflowStatus = () => {
        const titleElement = document.querySelector(".radio-player-show-info") as HTMLElement
        const wrapperElement = document.querySelector(".radio-player-show-info-wrapper") as HTMLElement
        let titleOverflow = false
        if (titleElement) {
            titleOverflow = titleElement.offsetWidth > wrapperElement.offsetWidth
        }
        return titleOverflow
    }

    const updateProgramTitleWidth = () => {
        const titleElement = document.querySelector(".radio-player-show-info") as HTMLElement
        setProgramTitleWidth(titleElement.offsetWidth + "px")
    }

    useEffect(() => {
        setIsTitleOverFlowing(getTitleOverflowStatus())
        updateProgramTitleWidth()
    }, [programItem])

    return (
        <div className="radio-homepage-player" ref={onRefChanged}>
            <div className="radio-player-tab" style={{ backgroundColor: tabColor }}>
                <div className="radio-player-tab-title">{title}</div>
                {tabButtonTitle &&
                    <HyperLinkBuilder className="radio-player-tab-btn-link" href={tabButtonHref} link={tabButtonLink} linkTarget={tabButtonLinkTarget}>
                        <div className="radio-player-tab-btn">{tabButtonTitle}</div>
                    </HyperLinkBuilder>}
            </div>

            <div className="radio-player-content">
                <HyperLinkBuilder className="ynet-radio-gif-wrapper" href={tabButtonHref} link={tabButtonLink} linkTarget={tabButtonLinkTarget}>
                    <img className="radio-player-equalizer-gif" src={"https://www.ynet.co.il/images/radio/new-radio-logo.gif"} />
                </HyperLinkBuilder>

                <div className="radio-player-show-info-wrapper">
                    {isTitleOverFlowing ?
                        <>
                            <div className="fadder-right"></div>
                            <div className="slider-wrapper" style={{ width: programTitleWidth }}>
                                <Slider {...settings}>
                                    <div className="radio-player-show-info">
                                        <div className="radio-player-show-time">{getShowTime(programItem)}</div>
                                        <div className="radio-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                                    </div>
                                    <div className="radio-player-show-info">
                                        <div className="radio-player-show-time">{getShowTime(programItem)}</div>
                                        <div className="radio-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                                    </div>
                                </Slider>
                            </div>
                            <div className="fadder-left"></div>
                        </> :
                        <div className="radio-player-show-info">
                            <div className="radio-player-show-time">{getShowTime(programItem)}</div>
                            <div className="radio-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                        </div>}
                </div>

                {programItem.isProgramLive && <div className="radio-player-tab-live-indication">LIVE</div>}

                <div className="radio-player-button" onClick={onTogglePlayPause}>
                    <div className={`radio-player-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                </div>
            </div>
        </div>
    )
}