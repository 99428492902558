import { getImgAlt, isAriaHidden, separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"

interface CartoonArticleItemSlotProps {
    article: Partial<ArticleExtraData>
    onSelectedItemIndexChange: (index) => void
    currIndex: number
}

export class CartoonArticleItemSlot extends React.Component<CartoonArticleItemSlotProps, {}>{
    public render() {
        const { article: { promotionImageDetails, dateUpdatedOnSite }, onSelectedItemIndexChange, currIndex } = this.props;
        const imageTitle = `${promotionImageDetails.caption}${promotionImageDetails.caption && promotionImageDetails.credit ? "," : ""}${` ${promotionImageDetails.credit}`}`
        return (
            <div className="slotItem" onClick={() => onSelectedItemIndexChange(currIndex)}>
                <div className="mediaArea">
                    <div className="MediaCarousel" >
                            <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 270, h: 182 })}
                                style={{ width: 270, height: 182 }}
                                alt={getImgAlt(promotionImageDetails)}
                                aria-hidden={isAriaHidden(promotionImageDetails)}
                                title={imageTitle}
                        />
                    </div>
                </div>
                <span className="dateView">
                    {separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: true, showHour: false })}
                </span>
            </div>

        )
    }
}