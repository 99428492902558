require("./site_channels.less")
import { SectionLabelWithUnderlineDecoration } from "../sectionLabelWithUnderlineDecoration/sectionLabelWithUnderlineDecoration"

export const Channels = () => {
    return (
        <div className="Channels">
            <div className="sectionLabelContainer">
                <SectionLabelWithUnderlineDecoration label="ערוצי תוכן" />
                <span className="ynetLogo"></span>
            </div>

            <div className="channelsContainer">
                <ul >
                    <li><a href="//www.ynet.co.il/home/0,7340,L-2,00.html" title="חדשות" target="_blank" >חדשות</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-6,00.html" title="כלכלה" target="_blank" >כלכלה</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-3,00.html" title="ספורט" target="_blank" >ספורט</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-538,00.html" title="תרבות" target="_blank" >תרבות</a></li>
                </ul>

                <ul>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-1208,00.html" title="בריאות" target="_blank">בריאות</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-544,00.html" title="מחשבים" target="_blank" >מחשבים</a></li>
                    <li><a href="//pplus.ynet.co.il/home/0,7340,L-11211,00.html" title="Pplus רכילות" target="_blank" >Pplus רכילות</a></li>
                    <li><a href="//b.ynet.co.il/pearl/~v090820/clientzone/vehicle/proposal.html?utm_source=ynet.hp&amp;utm_medium=buy_save_146_82&amp;utm_campaign=bituach.car&amp;utm_content=bituach.car-buy_save_146_82#/" title="מימון" target="_blank">מימון</a>
                    </li>
                </ul>

                <ul>
                    <li><a href="//xnet.ynet.co.il/home/0,14538,L-8,00.html?utm_source=ynet&amp;utm_medium=footer&amp;utm_campaign=november" title="Xnet" target="_blank" >Xnet</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-4403,00.html" title="יהדות" target="_blank" >יהדות</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-194,00.html" title="דעות" target="_blank" >דעות</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-5363,00.html" title="צרכנות" target="_blank" >צרכנות</a></li>
                </ul>

                <ul>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-598,00.html" title="תיירות" target="_blank" >תיירות</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-975,00.html" title="אוכל" target="_blank" >אוכל</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-550,00.html" title="רכב" target="_blank" >רכב</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-4880,00.html" title="בעלי חיים" target="_blank" >בעלי חיים</a></li>
                </ul>


                <ul>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-3925,00.html" title="יחסים" target="_blank" >יחסים</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-1069,00.html" title="אסטרולוגיה" target="_blank" >אסטרולוגיה</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-3262,00.html" title="מעורבות" target="_blank" >מעורבות</a></li>
                    <li><a href="//xnet.ynet.co.il/home/0,7340,L-11946,00.html" title="לאשה" target="_blank" >לאשה</a></li>
                </ul>

                <ul>
                    <li><a href="//www.bigdeal.co.il/" title="דילים" target="_blank" >דילים</a></li>
                    <li><a href="//www.calcalist.co.il/home/0,7340,L-8,00.html" title="כלכליסט" target="_blank" >כלכליסט</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-3456,00.html" title="בלייזר" target="_blank" >בלייזר</a></li>
                    <li><a href="//www.ynet.co.il/home/0,7340,L-1209,00.html" title="מנטה" target="_blank" >מנטה</a></li>
                </ul>

                <ul>
                    <li><a href="//www.mynet.co.il/" title="mynet" target="_blank" >mynet</a></li>
                    <li><a href="//www.frogi.co.il/?utm_source=ynet.co.il&utm_medium=referral&utm_campaign=Footer" title="פרוגי" target="_blank" >פרוגי</a></li>
                    <li><a href="//www.yschool.co.il/" title="Yschool" target="_blank" >Yschool</a></li>
                    <li><a href="//nadlan.ynet.co.il/?externalurl=true" title="nadlan" target="_blank" >ynet נדל"ן</a></li>
                </ul>

                <ul>
                    <li><a href="//www.vesty.co.il" title="Вести" target="_blank">Вести</a></li>
                </ul>
            </div>
        </div>
    )
}