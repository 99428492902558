import { RadioProgramItem } from "../../radioHompage/components/radioHomepagePlayerComponenta";
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { AudioRendererProps } from "../../floatingPlayer/audioPlayButton";

interface MutamRadioArticleLivePlayerProps extends AudioRendererProps {
    buttonTitle: string
    buttonHref: string
    buttonLink: LinkProperties<BaseLinkTypes>
    buttonLinkTarget: LinkTargetProperties
    programItem: RadioProgramItem
}

export const MutamRadioArticleLivePlayer = (props: MutamRadioArticleLivePlayerProps) => {

    const { buttonTitle, buttonHref, buttonLink, buttonLinkTarget, programItem, isAudioPlaying, onTogglePlayPause, onRefChanged } = props

    const getShowTime = (item: RadioProgramItem) => {
        if (item && !item.hideHours) {
            return `${item.startTime} - ${item.endTime}`
        }
        return ""
    }

    return (
        <div className="mutam-article-live-homepage-player" ref={onRefChanged}>
            <div className="radio-player-content">
                <div className="radio-player-button" onClick={onTogglePlayPause}>
                    <div className={`radio-player-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                </div>

                <div className="radio-player-show-info-wrapper">
                    <div className="radio-player-show-info">
                        <div className="radio-player-live-indication">{programItem.isProgramLive ? "LIVE" : ""}</div>
                        <img className="radio-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />
                        <div className="radio-player-show-time">{getShowTime(programItem)}</div>
                    </div>

                    <div className="radio-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                </div>
            </div>

            <div className="radio-player-goto-btn-wrapper">
                {buttonTitle &&
                    <div className="radio-player-goto-btn">
                        <HyperLinkBuilder className="radio-player-goto-btn-link" href={buttonHref} link={buttonLink} linkTarget={buttonLinkTarget}>
                            <div className="radio-player-goto-btn-title">{buttonTitle}</div>
                            <div className="radio-player-goto-btn-arrow"></div>
                        </HyperLinkBuilder>
                    </div>}
            </div>
        </div>
    )
}