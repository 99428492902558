
interface articleImagesFullScreenGalleryImageCounterProps {
    numOfImages: number
    currentImageIndex: number
    swipeLeft?: () => void
    swipeRight?: () => void
    isMobile?: boolean
}


export const ArticleImagesFullScreenGalleryImageCounter = ({ numOfImages, currentImageIndex, swipeLeft, swipeRight, isMobile }: articleImagesFullScreenGalleryImageCounterProps) => {
    return (
        <>
            {numOfImages > 1 && <div className={`imagesCounter`}>
                {!isMobile && <button className={`navigationArrow ${"swipeLeft"}`} title="prev" aria-label="prev" onClick={swipeLeft} />}
                <span className="current">{currentImageIndex + 1 < 10 ? `0${currentImageIndex + 1}` : currentImageIndex + 1}</span>
                <span className="totalNumOfImages">/{numOfImages < 10 ? `0${numOfImages}` : numOfImages}</span>
                {!isMobile && <button className={`navigationArrow ${"swipeRight"}`} title="next" aria-label="next" onClick={swipeRight} />}
            </div>}
        </>
    )
}
