import { SiteYPlusLoadMoreData } from "./YplusLoadMoreView";
import { YPlusLoadMoreItemSlot } from "./yplusLoadMoreItemSlot";
import { TaboolaItemArticleHeadlines } from "../../ynet1280/articleHeadlinesAutoWidget/components/taboolaItem"

interface YPlusLoadMoreListProps {
    itemList: SiteYPlusLoadMoreData[]
    height: number
    dataLength: number
    isCategoryVisible?: boolean
    ver?: number
    commercialRepetitionsNum?:number 
    commercialItemsNum?:number
}

export class YPlusLoadMoreList extends React.Component<YPlusLoadMoreListProps, {}>{
    public render() {
        const { itemList, commercialRepetitionsNum, commercialItemsNum } = this.props
        const articles = itemList;
        const articleLength = articles.length;
        const isMobile = typeof window !== "undefined" && window.isMobile1;
        let visibleItems = [];
        if(articleLength>0){
            const availibaleRepetitions = Math.floor(articleLength/commercialItemsNum);
            const hasTaboolaItems = commercialRepetitionsNum > 0 && commercialItemsNum > 0 && articleLength > 0;
            const numberOfTaboolaItems  = availibaleRepetitions < commercialRepetitionsNum ? availibaleRepetitions : commercialRepetitionsNum;
            for (var i = 0; i < articleLength; i++) {           
                    visibleItems.push(<YPlusLoadMoreItemSlot
                        key={i}
                        article={articles[i]}
                        {...this.props}
                    />)
                }
            
            if(hasTaboolaItems && isMobile){
                for (var i=0; i<numberOfTaboolaItems; i++){
                   visibleItems.splice(commercialItemsNum*(i+1)+i, 0, <TaboolaItemArticleHeadlines id={"taboola-mid-category-thumbnails-plus-"+(i+1)} />) 
                  }
              }
            }
        
        return (
            <div className="slotList">
                {visibleItems}
            </div>
        )
    }
}