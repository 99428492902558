
require("./calcalist_site_addCommentFormUI.less")
import * as classNames from "classnames"
import { AddCommentFormRenderProps } from "../addCommentFormWrapper"
import { getTranslation } from "../translations"
interface PremiumAddCommentFormProps {
    props: Partial<AddCommentFormRenderProps>
}

export class CalcalistAddCommentFormUI extends React.Component<PremiumAddCommentFormProps, {}>{

    private onConfirmAlertBtnPress = () => {
        const { closeAlert, resetFormData, handleCloseCommentForm } = this.props.props
        closeAlert()
        handleCloseCommentForm()
        resetFormData()
    }

    render() {
        const { isAddCommentFormVisible, isSent, title, name, email, comment, showAlert, formType, lang,
            alertBeforeClosing, handleCloseCommentForm, closeAlert, onInputChanged, handleCommentSend } = this.props.props
        const AddCommentFormClasses = classNames({
            "CalcalistAddCommentFormUI": true,
            "fadeIn": isAddCommentFormVisible,
            "hide": !isAddCommentFormVisible,
        })

        return (
            <div className={AddCommentFormClasses}>
                <div className="AddCommentForm" >
                    {!isSent &&
                        <div className="AddCommentFormContainer">
                            <div className="topTitleWrapper">
                                <span className="formTitle"> {formType === "REPLY" ? getTranslation(lang, "Add New Comment To Comment") : getTranslation(lang, "Add New Comment")} </span>
                                <button className="closeBtnWrapper" title={getTranslation(lang, "Close(Order Male)")} onClick={e => alertBeforeClosing()}>
                                    <span className="closeIcon" />
                                    <span>{getTranslation(lang, "Close")}</span>
                                </button>
                            </div>
                            {showAlert && <div className="commentSentMessageWrapepr alertMessage">
                                <div className="alertText"><span>{`${getTranslation(lang, "Warning")}`}</span></div>
                                <div className="successfullySentText"><span>{`${getTranslation(lang, "This Action Will Delete You Comment")}`}</span></div>
                                <div className="bottomPanel">
                                    <button className="closeText" title={getTranslation(lang, "Confirm")} onClick={this.onConfirmAlertBtnPress}><span>{getTranslation(lang, "Confirm")}</span></button>
                                    <button className="closeText" title={getTranslation(lang, "Cancel")} onClick={e => closeAlert()}><span>{getTranslation(lang, "Cancel")}</span></button>
                                </div>
                            </div>}
                            <div className="userDetailsInputs">
                                <input className="userDetailsInput inputStyle" maxLength={20} value={name} placeholder={getTranslation(lang, "Name")} onChange={(e) => onInputChanged(e, "name")} />
                                <input className="userDetailsInput inputStyle" maxLength={35} value={email} placeholder={getTranslation(lang, "Email")} onChange={(e) => onInputChanged(e, "email")} />
                            </div>
                            <div className="commentInputs">
                                <input className="title inputStyle" type="text" maxLength={60} value={title} placeholder={getTranslation(lang, "Title")} onChange={(e) => onInputChanged(e, "title")} />
                                <textarea className="comment inputStyle" type="text" maxLength={4000} value={comment} placeholder={getTranslation(lang, "Comment Content")} onChange={(e) => onInputChanged(e, "comment")}></textarea>
                            </div>
                            <div className="bottomPanel">
                                <div className="termsOfService">
                                    {" אין לשלוח תגובות הכוללות מידע המפר את"}
                                <a href="//z.calcalist.co.il/mvc/long/2018/OrganizationalStructure/About/Terms.html" target="_blank" title={getTranslation(lang, "terms of use")} style={{ margin: "auto 5px" }} >תנאי השימוש של כלכליסט</a>
                                    {" לרבות דברי הסתה, דיבה וסגנון החורג מהטעם הטוב."}
                                </div>
                                <div className="bottomPanelRow">
                                    <button className="sendBtn" id="addCommentSendButton" title={getTranslation(lang, "Post Comment")} onClick={e => handleCommentSend()}>
                                        <span>{getTranslation(lang, "Post Comment")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>}

                    {isSent && <div className="commentSentMessageWrapepr">
                        <div className="thankYouWrapper">
                            <div className="thankYou"><span>{getTranslation(lang, "Thank You")},</span></div>
                            <button className="closeTextWrapper" title={getTranslation(lang, "Close(Order Male)")} onClick={this.onConfirmAlertBtnPress}>
                                <span className="closeIcon" />
                                <span className="closeTextLabel" >{getTranslation(lang, "Close(Order Male)")}</span>
                            </button>
                        </div>
                        <div className="successfullySentText">
                            <span>{getTranslation(lang, "Comment Sent Confirmation Message")}</span>
                        </div>
                    </div>}
                </div>

            </div>
        )
    }
}