import { useState } from 'react';
import Slider from "react-slick";
import { HyperLink } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { LinkTargetProperties, LinkProperties, BaseLinkTypes } from "../../../../../global/components/linkSettings/interfaces"
import { CaricaturistItem } from "./caricaturistMobileWrapperComponenta";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"

interface ArrowProps {
    onClick?: () => void;
}

interface CaricaturistMobileComponentaProps {
    data: CaricaturistItem[]
    tabTitleLink: string
    tabTitleLinkTarget: LinkTargetProperties
}

interface CaricaturistMobileComponentaState {
    isDialogOpen: boolean
    currentSlide: number
}

export class CaricaturistMobileComponenta extends React.Component<CaricaturistMobileComponentaProps, CaricaturistMobileComponentaState> {
    public static siteScriptName = "CaricaturistMobileComponenta"

    constructor() {
        super();
        this.state = {
            isDialogOpen: false,
            currentSlide: 0
        }
    }


    public openDialog = () => {
        this.setState({ isDialogOpen: true})
    }

    public closeDialog = () => {
        this.setState({ isDialogOpen: false })
    }

    render() {
        const caricaturistItems = this.props.data;
        const { tabTitleLink, tabTitleLinkTarget } = this.props;
        const { isDialogOpen, currentSlide } = this.state;
        const NextArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == caricaturistItems.length - 1} onClick={onClick} className="custom-arrow left"><span className="arrow-icon left"></span></button>);
        const PrevArrow = ({ onClick }: ArrowProps): JSX.Element => (<button disabled={currentSlide == 0} onClick={onClick} className="custom-arrow right"><span className="arrow-icon right"></span></button>);

        let settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            rtl: true,
            
            className: 'caricaturist-carusel',
            prevArrow: <NextArrow />,
            nextArrow: <PrevArrow />,
            afterChange: (index) => {
                this.setState({ currentSlide: caricaturistItems.length - 1 - index });
            }
        }
        return (
            <>
                {isDialogOpen && <CaricaturistDialog {...caricaturistItems[currentSlide] as CaricaturistItem} onClose={this.closeDialog} />}
                <div className='caricaturistMobileComponenta'>
                    <HyperLinkBuilder className="header" title='קריקטוריסט' href={tabTitleLink} linkTarget={tabTitleLinkTarget}>
                        <span className="caricaturistIcon-pf"></span>
                        <span className="caricature-header-title">קריקטוריסט</span>
                        <span className="arrow-icon"></span>
                    </HyperLinkBuilder>
                    <Slider {...settings} >
                        {caricaturistItems.map((item, index) => {
                            const { name, credits, id, path } = item;
                            return <div key={id} onClick={() => this.openDialog()}>
                                <div className='image-container' title={name + ", " + credits}>
                                    <SiteSimpleImage src={path} alt={name + ", " + credits} />
                                    <p className="bottom-date-slug">{name}</p>
                                </div>
                            </div>
                        })}
                    </Slider>
                </div >
            </>
        )

    }
}



export const CaricaturistDialog = ({ credits, name, path, onClose }) => {
    return (
        <div className="caricaturist-dialog" onClick={onClose}>
            <div className="dialog-overlay"></div>
            <div className="close-btn"></div>
            <SiteSimpleImage src={path} alt={name + ", " + credits} />
        </div>
    )
}
