import { SitePplusLoadMoreData } from "./pplusLoadMoreView";
import { PplusLoadMoreItemSlot } from "./pplusLoadMoreItemSlot";

interface PplusLoadMoreListProps {
    itemList: SitePplusLoadMoreData[]
    height: number
    dataLength: number
    isCategoryVisible?: boolean
    isMobileWeb?: boolean
}

export class PplusLoadMoreList extends React.Component<PplusLoadMoreListProps, {}>{
    public render() {
        const { itemList } = this.props
        return (
            <div className="slotList" >
                {itemList.map((item, i) =>
                    <PplusLoadMoreItemSlot
                        key={i}
                        article={item}
                        {...this.props}
                    />)}
            </div>
        )
    }
}