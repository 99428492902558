require("./calcalist_site_newsLetterComponenta.less");
import * as axios from "axios";
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage";
import { isWcmEditor } from "../../../../../config/serverConfig";

const ApiUrl ="https://qa-primium.ynet.co.il/manager/api/Webhooks/UpdateCustomFields";
const subscribeText = "הרשמה";
const unSubscribeText = "הסרה";

interface CalcalistNewsLetterItemProps {
  slotId: string;
  imgUrl: string;
  title: string;
  subTitle: string;
  frequency: string;
  itemId: string;
  isPinaoSubscribed: boolean;
}

interface CalcalistNewsLetterItemState {
  registrationStatus: RecivedSubscriptoinStatus;
  loading: boolean;
}

enum RecivedSubscriptoinStatus{
  notSent = 0,
  registered = 1,
  unRegistered = 2
}

export class CalcalistNewsLetterItem extends React.Component<CalcalistNewsLetterItemProps,CalcalistNewsLetterItemState> {

  constructor(props) {
    super(props);
    this.state = {
      registrationStatus: RecivedSubscriptoinStatus.notSent,
      loading: false,
    };
  }

  private isUserCurrentlySubscribed=(registrationStatus:RecivedSubscriptoinStatus)=>{
    const {isPinaoSubscribed} = this.props;
    return registrationStatus === RecivedSubscriptoinStatus.notSent ? isPinaoSubscribed : registrationStatus === RecivedSubscriptoinStatus.registered
  }

  setActionButtonName = (): string => {
    let isSubscribed = this.isUserCurrentlySubscribed(this.state.registrationStatus);
    return isSubscribed ? unSubscribeText : subscribeText;
  };

  handleActionButton = (title: string) => {
    const isSubscribed=this.isUserCurrentlySubscribed(this.state.registrationStatus);
    if (!window.tp.pianoId.getUser()) {
      window.tp.pianoId.show({
        loggedIn: function (data) {},
      });
    } else {
      this.updatePianoCustomField();
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
          event: 'GA_Events',
          Category: 'Newsletter',
          Action: title,
          Label: isSubscribed ? "Unsubscribe" : "Subscribe"
      });
    }
  };

  handleButtonStyle = (buttonText: string) => {
    if (buttonText === subscribeText) {
      return {
        "--btn-bg-color": "#13B7B1",
        "--btn-color": "#fff",
        "--btn-border-color": "initial",
      };
    } else {
      return {
        "--btn-bg-color": "#fff",
        "--btn-color": "#13B7B1",
        "--btn-border-color": "#13B7B1",
      };
    }
  };

  updatePianoCustomField = () => {
    const isSubscribed=this.isUserCurrentlySubscribed(this.state.registrationStatus);
    this.setState({ loading: true }, () => {
      let data = {
        UserId: window.tp.pianoId.getUser().uid,
        CustomFieldsValues: [
          {
            CFName: this.props.itemId,
            CFValue: `${!isSubscribed}`,
          },
        ],
      };

      axios.post(ApiUrl,data)
        .then((response: any) => {
          if (response.data.code === 0) {
            this.setState((prevState)=>{
              const isUserCurrentlySubscribed=this.isUserCurrentlySubscribed(prevState.registrationStatus);
              let registrationStatus=isUserCurrentlySubscribed?RecivedSubscriptoinStatus.unRegistered:RecivedSubscriptoinStatus.registered;
              return {registrationStatus, loading: false}
              });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };



  public render() {
    const { imgUrl, title, subTitle, frequency } = this.props;
    const { registrationStatus, loading } = this.state;
    const isSubscribed=this.isUserCurrentlySubscribed(registrationStatus);

    return (
      <div className="newsLetterItem">
        <div className="newsLetterItemImage">
          <SiteSimpleImage src={imgUrl} />
        </div>
        <div className="newsLetterItemBody">
          <div className={"newsLetterItemTitle"}>{title}</div>
          <div className={"newsLetterItemSubTitle"}>{subTitle}</div>
          <div className="newsLetterItemBottom">
            <button
              className="newsLetterItemRegisterButton"
              style={this.handleButtonStyle(
                isSubscribed ? unSubscribeText : subscribeText
              )}
              disabled={isWcmEditor() || loading}
              onClick={() => this.handleActionButton(title)}
            >
              {loading ?  <div className="custom-loader"></div> : this.setActionButtonName()}
            </button>
            <div className="newsLetterItemFrequency">{frequency}</div>
          </div>
        </div>
      </div>
    );
  }
}
