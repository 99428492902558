import { ArticleCommentsTopTabRenderProps } from "../articleCommentsTopTabWrapper"
import { getTalkbackNumberView } from "../../articleSocialShare1280Component/articleSocialShareNew1280";
import { getTranslation } from "../translations"


interface ArticleCommentsTopTabUIMobileWebProps {
    props: ArticleCommentsTopTabRenderProps
}

export class ArticleCommentsCalcalistTopTabMobileWebUI extends React.Component<ArticleCommentsTopTabUIMobileWebProps, {}>{
    private handleOnAddCommentBtnPress = () => {
        const { handleFormVisibility, handleCommentsPopupVisibility } = this.props.props
        handleCommentsPopupVisibility()
        handleFormVisibility()
    }

    render() {
        const { lang, numOfComments, isAddCommentFormVisible } = this.props.props
        const commentsNum =numOfComments && numOfComments > 0 ? getTalkbackNumberView(numOfComments.toString()) : "";
        return (
            <div className="ArticleCommentsTopTabUI">
                <div className="tabTitle">
                    <div className="tabText">{`${commentsNum} ${getTranslation(lang, "Comments")}`}</div>
                </div>
                <div className="tabPanel">
                    {!isAddCommentFormVisible &&
                        <button
                            key="newCommentBtn"
                            className="newCommentBtn-pf"
                            title="לכתיבת תגובה"
                            disabled={isAddCommentFormVisible}
                            onClick={this.handleOnAddCommentBtnPress}>
                            <div className="btnText">לכתיבת תגובה</div>
                        </button>
                    }
                </div>
            </div >
        )
    }
}