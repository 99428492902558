
interface TaboolaItemArticleHeadlinesProps {
  id:string
}

export class TaboolaItemArticleHeadlines extends React.Component<TaboolaItemArticleHeadlinesProps, {}> {

   
    public render() {
        const { id } = this.props


        return   <div className={`slotView taboolaItemArticleHeadlines`} id={id}/>
                                      
    }
}