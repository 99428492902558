import { isWcmEditor } from "config/serverConfig"

export class SiteArticlePremiumCutter extends React.Component<{platform:string}, {}> {
    public static siteScriptName = "SiteArticlePremiumCutter";

    private handleOnClick() {
        window.YitPaywall.openLoginPopUp();
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'Login_Events',
            Category: 'Ynet+ - Login',
            Action: 'Login Source',
            Label: 'Ynet+ Article - Block Box'
        });
    }

    public render() {
        const {platform} = this.props
        if (!isWcmEditor()) {
            return (
                <div className="ArticlePremiumCutter" id="ynet_premium_blocked">
                    <div className="topShadow"></div>
                    <div className="premium_block">
                        <img className="premuim_image" alt="premuim_image" src="https://images1.ynet.co.il/static/article/images/ynet_dark_logo.png" height="33" width="auto" />
                        <p className="premium_slogan"><strong className="rtl-premium">עושים מינוי +ynet ונהנים מכל העולמות</strong></p>
                        <div className="premium-bullets">
                            <div className="premium-block-box">
                                <div className="mutam-check-image"></div>
                                <img className="box_image" alt="premuim_image" src="https://images1.ynet.co.il/static/article/images/v.png" height="38" width="38" />
                                <span className="rtl-premium">ניתן לבטל בקלות בכל עת</span>
                            </div>
                            <div className="premium-block-box">
                                <div className="mutam-check-image"></div>
                                <img className="box_image" alt="premuim_image" src="https://images1.ynet.co.il/static/article/images/v.png" height="38" width="38" />
                                <span className="rtl-premium">גישה בלתי מוגבלת לכל כתבות +ynet</span>
                            </div>
                            <div className="premium-block-box">
                                <div className="mutam-check-image"></div>
                                <img className="box_image" alt="premuim_image" src="https://images1.ynet.co.il/static/article/images/v.png" height="38" width="38" />
                                <span className="rtl-premium">אירועים, הטבות והרצאות למנויים</span>
                            </div>
                        </div>
                        <a id="register_premium" className="register_premium-pf" href={(platform==="android"|| platform==="iphone" || platform==="tablet") ? "https://premium.ynet.co.il/Web/Register#/campaigns" :`javascript:window.open(YitPaywall.ApiRroutesConfig.Origin+YitPaywall.ApiRroutesConfig.register)`}>הצטרפו עכשיו!</a>
                        <div className="premium-bullets links">
                            <a className="login_premium" id="login_premium" onClick={() => this.handleOnClick()}>כבר יש לכם מנוי? <u>התחברו</u></a>
                            <a target="_blank" id="register_premium_preview" href={(platform==="android"|| platform==="iphone"  || platform==="tablet") ? "https://www.ynet.co.il/plus?showAccessibility=false&IsApp=true" : "//premium.ynet.co.il/Web/Manage/Main"} onClick={() => "open_register()"}>עוד בפלוס</a>
                        </div>
                    </div>
                </div>
            )
        } else return null
    }
}


