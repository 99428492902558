import { openFacebookShare, openTwitterShare, analyticsPushFunc, openLinkedinShare } from "../../../widgetsUtils"
import { CalcalistBodyCommercialButton } from "../../../../articlePage/stateInterfaces"
import { MailShareButton } from "../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { WhatsAppShareButton } from "../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"

const siteArticleCommentsId = "SiteArticleComments"

interface CalcalistArticleActionsState {
    position: "top" | "bottom" | "fixed top" | "fixed bottom"
    pixelsFromLeft: number
}

interface CalcalistArticleActionsProps {
    articleTitle: string
    commercialBtn: CalcalistBodyCommercialButton
    commercialImgUrl: string
    showComments: boolean
    site?: string
}

export class CalcalistArticleActions extends React.Component<CalcalistArticleActionsProps, CalcalistArticleActionsState> {
    public static siteScriptName = "CalcalistArticleActions";

    constructor() {
        super();
        this.state = {
            position: "top",
            pixelsFromLeft: 0
        }
    }

    private ulRef: HTMLElement

    componentDidMount() {
        addEventListener("scroll", _.throttle(this.onScroll, 20));
        window.addEventListener("resize", this.resetPosition, false)
        this.resetPosition();
    }
    componentWillUnmount() {
        // const navbar = document.getElementById("YnetHeader")
        document.removeEventListener("scroll", _.throttle(this.onScroll, 20))
        window.removeEventListener("resize", this.resetPosition, false)

    }
    /*
        componentDidUpdate() {
            this.resetPosition();
        }
    */
    private resetPosition = () => {
        if (!this.ulRef) return;
        const position = this.props.site === "ctech" ? this.ulRef.getBoundingClientRect().right : this.ulRef.getBoundingClientRect().left
        if (this.state.pixelsFromLeft !== position)
            this.setState({
                pixelsFromLeft: this.ulRef.getBoundingClientRect().left
            })
    }

    private onScroll = () => {
        if (!this.ulRef) return;
        const { position } = this.state;
        const articleBodyElement = document.getElementById("calcalistArticleComponenta");
        const articleBodyBoundingClientRect = articleBodyElement.getBoundingClientRect();
        const sideBarBoundingClientRect = this.ulRef.getBoundingClientRect();
        const sideposition = this.props.site === "ctech" ? this.ulRef.getBoundingClientRect().right : this.ulRef.getBoundingClientRect().left
        if (articleBodyBoundingClientRect.height <= sideBarBoundingClientRect.height) {
            return;
        }

        switch (position) {
            case "top":
                if (articleBodyElement.getBoundingClientRect().top < 0) {
                    this.setState({
                        position: "fixed top",
                        pixelsFromLeft: this.ulRef.getBoundingClientRect().left
                    })
                }
                break;

            case "fixed top":
                if (articleBodyElement.getBoundingClientRect().bottom < this.ulRef.getBoundingClientRect().bottom) {
                    this.setState({
                        position: "bottom",
                        pixelsFromLeft: this.ulRef.getBoundingClientRect().left
                    })
                }
                if (articleBodyElement.getBoundingClientRect().top > this.ulRef.getBoundingClientRect().top) {
                    this.setState({
                        position: "top",
                        pixelsFromLeft: this.ulRef.getBoundingClientRect().left
                    })
                }
                break;

            case "bottom":
                if (this.ulRef.getBoundingClientRect().top > 0) {
                    this.setState({
                        position: "fixed top"
                    })
                }
                break;

        }
    }

    private getStyle = () => {
        const { position, pixelsFromLeft } = this.state;

        let style;
        // let positionLoR = this.props.site === "ctech" ? {  } : { left: pixelsFromLeft }
        let basicePosition = { left: "40px", right: "auto" }
        if (position == "fixed top") {
            style = {
                left: pixelsFromLeft,
                marginTop: "20px",
                marginBottom: 0,
                position: "fixed",
                top: "0px"
            }
        }

        if (position == "fixed bottom") {
            style = {
                left: pixelsFromLeft,
                marginTop: "20px",
                marginBottom: 0,
                position: "fixed",
                bottom: "0px"
            }
        }

        if (position == "top") {
            style = {
                top: "0px",
                ...basicePosition,
                bottom: "auto",
            }
        }

        if (position == "bottom") {
            style = {
                top: "auto",
                ...basicePosition,
                bottom: "0px",
            }
        }

        return style;
    }

    private printArticle = () => {
        if (typeof window != "undefined") {
            window.print();
        }
    }

    private handleShareBtnClicked = (button: string, lang: string) => {
        if (typeof window !== "undefined") {
            const current_url = window.location.href;
            const { articleTitle } = this.props
            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Side", articleTitle), "Article Side")
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Side", articleTitle), "Article Side")
                    break;
                case "ld":
                    openLinkedinShare(current_url, () => analyticsPushFunc("Linkedin", "Article Side", articleTitle))
                    break;
                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Article Top", articleTitle), "Article Top")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(articleTitle, current_url, lang, () => analyticsPushFunc("Mail", "Article Side", articleTitle), "Article Side")
                    break;
            }
        }
    }

    private isTalkbackElement = () => {
        if (typeof window !== 'undefined') {
            return !!document.getElementById(siteArticleCommentsId);
        }
    }

    private scrollTalkbacksIntoView = (e) => {
        document.getElementById(siteArticleCommentsId).scrollIntoView({ behavior: "smooth" }); // true = align to top        
    }

    public render() {
        const lang = this.props.site === "ctech" ? "enCtech" : "heCalcalist";
        const { commercialBtn, commercialImgUrl, showComments } = this.props

        const _showComments = showComments || this.isTalkbackElement();

        return (
            <div className="art-action-wrp">
                <ul className="art-action" data-articleid="3763857" ref={ref => this.ulRef = ref} style={this.getStyle()}>
                    {_showComments && this.props.site !== "ctech" && <li title="Talkback" className="share-icon talkback" onClick={this.scrollTalkbacksIntoView}></li>}
                    <li title="Email" className="share-icon email" onClick={e => this.handleShareBtnClicked("mailto", lang)}></li>
                   <li title="Whatsapp" className="share-icon whatsapp" onClick={(e) => this.handleShareBtnClicked("wa", lang)} />
                    <li title="Facebook" className="share-icon facebook" onClick={(e) => this.handleShareBtnClicked("fb", lang)} />
                    <li title="Twitter" className="share-icon twitter" onClick={(e) => this.handleShareBtnClicked("tw", lang)} />
                    <li title="Print" className="share-icon print" onClick={this.printArticle} />
                    {this.props.site === "ctech" && <li title="Linkedin" className="share-icon linkedin" onClick={(e) => this.handleShareBtnClicked("ld", lang)} />}
                    <li style={{ backgroundImage: `url(${commercialImgUrl})` }} title={commercialBtn.linkProperties.link.description} className="CommercialLink" aria-label="מודעה">
                        <a target="_blank" href={commercialBtn.linkProperties.link.publishedLink} style={{ height: "100%", width: "100%", display: "block" }} />
                    </li>
                </ul>
            </div>
        )
    }
}