import * as map from "lodash/map"
import { testAdblock, getCookie } from "../../../widgets/siteWidgets/siteWidgetUtils"
interface Props {
    analyticsObject: any
    mainClassName: string
}

export class SiteAnalyticsInjector extends React.Component<Props, {}> {

    public static siteScriptName = "SiteAnalyticsInjector"

    componentDidMount() {
        this.setAnalyticsAttrsOnMainClass();
        this.setOldAttrsOnbody();
        this.dispatchAnalyticsInjectionFinished();
    }

    private handleTestAdBlock = (adBlockPresent: boolean) => {
        const {  mainClassName } = this.props;
        const element = document.getElementsByClassName(mainClassName)[0];
        if (adBlockPresent) {
            element.setAttribute('data-yitan-ad_blocker', "true")
        }
    }

    private setAnalyticsAttrsOnMainClass = async () => {
        const { analyticsObject, mainClassName } = this.props;

        if (typeof document != "undefined") {
            const mainDiv = document.getElementsByClassName(mainClassName)[0];
            map(analyticsObject, (value, name) => {
                mainDiv.setAttribute(`data-yitan-${name}`, `${value}`)
            })

            const cid = getCookie("_gid");
            mainDiv.setAttribute('data-yitan-cid', cid);

            mainDiv.setAttribute('data-yitan-dc_path', window.dcPath);
            testAdblock(this.handleTestAdBlock);      }
    }

    private setOldAttrsOnbody = () => {
        for (let dataAttr in window.dataLayer[0]) {
            let gaAttrName = dataLayerToGaDict[dataAttr];
            if (!gaAttrName) continue;
            document.body.setAttribute("data-yitan-" + gaAttrName, window.dataLayer[0][dataAttr])
        }
    }

    private dispatchAnalyticsInjectionFinished = () => {
        const analyticsInjectionFinished = new Event("analyticsInjectionFinished");
        document.dispatchEvent(analyticsInjectionFinished);
    }

    public render() {
        return null
    }
}

const dataLayerToGaDict = {
    "contentPageType": "page_type",
    "dcPath": "dc_path",
    "userId": "yid"
}