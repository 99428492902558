import { CityLocation } from "./WeatherCitySelector"
import isSubZero from '../helpers/isSubZero'
interface DropDownItemProps {
    city: CityLocation
    lang: String
}

const DropDownItem = (props: DropDownItemProps) => {
    const { city, lang } = props

    const isHeb = () => {
        return lang === "he"
    }

    return (
        <a href={city.url} style={{ textDecoration: "none", color: "unset" }}>
            <div className="dropdown-item">
                <div className="dropdown-city-name" role="progressbar" aria-valuenow={isHeb() ? city.nameHeb : city.name}>{isHeb() ? city.nameHeb : city.name}</div>
                <div className="dropdown-country-name">{isHeb() ? city.countryHeb : city.country}</div>
                {/* <div className="dropdown-tempature">
                    <div> {isSubZero(city.minimumTemperature)} -  {isSubZero(city.maximumTemperature)}</div>
                    <div className="dropdown-tempature-icon">
                        <img role="presentation" className="icon" src={`/images/weather/${city.weatherCode}.svg`} />
                    </div>
                    <div className="dropdown-tempature-info">{city.desc}</div>
                </div> */}
            </div>
        </a>
    );
}
export default DropDownItem;