
require("./site_articleBottomShare1280Component.less")
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../../widgetsUtils"
import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { WhatsAppShareButton } from "../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"

interface SiteArticleSocialBottomShare1280Props {
    articleId: string
    author: string
    categorySubChannel: number
    title: string
}
export class SiteArticleSocialBottomShare1280 extends React.Component<SiteArticleSocialBottomShare1280Props, {}>{
    static siteScriptName = "SiteArticleSocialBottomShare1280"

    private handleShareBtnClicked = (button: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Top", title), "Article Top")
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Top", title), "Article Top")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, "he", () => analyticsPushFunc("Mail", "Article Top", title), "Article Top")
                    break;

                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Article Top", title), "Article Top")
                    break;

                case "print":
                    window.print();
                    break;

                case "tb":
                    (document.querySelector(".newCommentBtn") as HTMLElement).click();
                    setTimeout(() => {
                        document.querySelector('#addCommentSendButton').scrollIntoView({
                            behavior: 'smooth'
                        });
                    }, 0)
                    break;
            }
        }
    }

    private constructContactFormUrl = () => {
        const { categorySubChannel, author, articleId } = this.props
        return `/Ext/Comp/ArticleLayout/CdaSendArticleResponse/0,12358,L-${categorySubChannel},00.html?author=${author}&articleID=${articleId}`
    }

    private openContactPopup = () => {
        const newwindow = window.open(this.constructContactFormUrl(), __("Article response"), 'height=543,width=380');
        if (window.focus) { newwindow.focus() }
        return false;
    }

    render() {
        const { categorySubChannel } = this.props
        const textMail = "שלחו כתבה"
        const textTw = "שיתוף בטוויטר"
        const textFb = "שיתוף בפייסבוק"
        const textWa = "שיתוף בואטסאפ"
        const textPrint = "הדפס"
        const textNewPost = "פרסם תגובה חדשה"
        const contactFormUrl = this.constructContactFormUrl()
        return (
            <div className="SiteArticleSocialBottomShare1280">
                {(subChannelIdsToAccept as any).includes(categorySubChannel) ? <button className="contactWrapper" onClick={() => this.openContactPopup()}>
                    <span className="icon"></span>
                    <span className="label">פנייה לכתב/ת</span>
                </button> : <div />}
                <div className="iconsWrapper">
                    <button title={textNewPost} className="shareIcon1280 tb" onClick={e => this.handleShareBtnClicked("tb")}></button>
                    <button title={textPrint} className="shareIcon1280 print" onClick={e => this.handleShareBtnClicked("print")}></button>
                    <button title={textMail} className="shareIcon1280 mailto" onClick={e => this.handleShareBtnClicked("mailto")}></button>
                    <button title={textWa} className="shareIcon1280 wa" onClick={e => this.handleShareBtnClicked("wa")}></button>
                    <button title={textTw} className="shareIcon1280 tw" onClick={e => this.handleShareBtnClicked("tw")}></button>
                    <button title={textFb} className="shareIcon1280 fb" onClick={e => this.handleShareBtnClicked("fb")}></button>
                </div>
            </div>
        )
    }
}

const subChannelIdsToAccept = [8, 2, 6, 437, 3, 538, 1208, 544, 2142, 6377, 550, 598, 975, 194, 1069, 813, 5, 3262, 4872, 3052, 3925, 4403,
    1361, 4002, 3456, 3630, 5419, 5420, 5423, 5421, 5425, 5422, 5427, 5426, 5428, 5424, 5429, 2, 6, 3, 538, 11211, 544, 1208, 5363, 11679,
    8315, 598, 550, 975, 3925, 3052, 4403, 4880, 2142, 3262, 194, 8841, 11691]