import { LinkTargetProperties, LinkPropertiesObject, LinkProperties } from "global/components/linkSettings/interfaces"
import { BaseLinkTypes } from "global/components/linkSettings/interfaces"

export const colorOptions = ["#000", "#ccc", "#0000ff", "#ff0000"];

export const modalOpenOptions = [
    { value: "same", label: __("Same Window") },
    { value: "new", label: __("New Window") },
    { value: "lightBox", label: __("Light Box") },
];

const linkProperties: LinkProperties<BaseLinkTypes> = {
    type: BaseLinkTypes.EXTERNAL_URL,
    link: { id: "", description: "" },
}

const linkTargetProperties: LinkTargetProperties = {
    linkOpenType: "new",
    lightBoxWidth: 600,
    lightBoxHeight: 500,
    lightBoxOpacity: 70,
    lightBoxBgColor: colorOptions[0],
}

export const linkPropertiesObjectInput: LinkPropertiesObject<BaseLinkTypes> = { linkProperties, linkTargetProperties }