import { MainNavHeaderComponenta } from "./mainNavComponenta";
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { MenuPopupComponenta } from "./menuPopUpComponenta";
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { YnetNewsWeatherInHeader } from "./newYnetWeatherComponenta"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { CommercialHeaderButton } from "../../../ynet1280/ynetHeader/components/commercialHeaderButton"
import { DataList } from "widgets/commonDataObjects/dataList"
import { CommercialLinkItemData } from "../../../ynet1280/ynetHeader/data/commercialLinkItemData"
import { CommertialItem } from "../../../ynet1280/ynetHeader/components/siteYnetHeaderComponenta";
import { SearchBtnDisplay } from "./newSearchButton";

interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isSearchOpened: boolean

}
interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    flashPageLink: string
    flashLinkTarget: LinkTargetProperties
    redMailLink: string
    mailLinkTarget: LinkTargetProperties
    weatherData: any
    defaultCityIndex: any
    weatherUrl: string
    logoData: Logo
    tabBgColor: string
    yplusLink: string
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    linkToTelegram:string
    enableSticky: boolean
    searchPageLink: string
    commercialBtnTitle: string
    commercialLinksList: CommertialItem[]
    followUsLink:string
    folowUsLinkTarget:LinkTargetProperties
    displayType:string
}
export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}
export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}

declare let window: Window & {
    languageCode: string
}

export class SiteYnetNewsHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteYnetNewsHeaderComponenta"

    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isSearchOpened: false
        }
    }

    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened, isSearchOpened: false, })
    }

    private openSearchMenu = () => {
        this.setState({ isSearchOpened: !this.state.isSearchOpened, isOpened: false })
    }

    private stickyBar() {
        const headerPos = document.getElementById("YnetHeader").getBoundingClientRect().top
        if (headerPos + 130 < 0) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
    }

    componentWillUnmount() {
        const navbar = document.getElementById("YnetHeader")
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }

    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        const { mainNavItems, groupsWithItems, flashPageLink, flashLinkTarget, defaultCityIndex, weatherUrl, logoData, enableSticky, linkToTelegram,
            mailLinkTarget, redMailLink, weatherData, tabBgColor, yplusLink, followUsText, linkToInst, linkToFb, linkToTw, linkToYtube, searchPageLink, 
            commercialBtnTitle, commercialLinksList ,followUsLink,  folowUsLinkTarget, displayType} = this.props
        const { isSticky } = this.state
        const isStickyAvailable = isSticky && enableSticky;
        const lang = (typeof window !== "undefined" &&  window.languageCode)  || "";
        const isLightMode = displayType && displayType==="light";
        let updates = "Updates";
        let redMail = "Red Mail";
        let more = "More";
        let search="Search";
        const Ru = lang.includes("ru");
        if(Ru){
            updates = "Коротко";
            redMail = "Связь с редакцией";
            more = "Еще";
            search = "Поиск";
        }

        return (
            <div className={classNames("YnetnewsHeader", displayType)} id="YnetHeader">
                <div className="headerTop">
                    <div className={"leftSideLinks"}>
                        <HyperLinkBuilder href={flashPageLink} linkTarget={flashLinkTarget} >
                            <span className="headerIcon flash" id="flashBell" /> <span className="flashText">{updates}</span>
                        </HyperLinkBuilder>
                        <HyperLinkBuilder href={redMailLink} linkTarget={mailLinkTarget} >
                            <span className="headerIcon redMail" /> <span className="redMailText">{redMail}</span>
                        </HyperLinkBuilder>
                        {commercialBtnTitle &&  <CommercialHeaderButton btnTitle={commercialBtnTitle} linksData={commercialLinksList} followUsLink={followUsLink} followUsLinkTarget={folowUsLinkTarget}/>}
                        {weatherData && <YnetNewsWeatherInHeader weather={weatherData} defaultCityIndex={defaultCityIndex} weatherUrl={weatherUrl} />}
                    </div>

                    <div className="logo">
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                            <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>
                    </div>

                    <div className="followUsLinks">
                    {isLightMode && <SearchBtnDisplay lang={Ru ? "ru" : "en"} searchPageLink={searchPageLink}/>}
                        <div className="mediaLinks">
                           {linkToTelegram!=="" && <SimpleLinkRenderer href={linkToTelegram} target="_blank" className="mediaIcon linkToTelegram" />}
                           {linkToTw !== "" && <SimpleLinkRenderer href={linkToTw} target="_blank" className="mediaIcon linkToTw" />}
                            {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                            {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="mediaIcon linkToInst" />}
                            {linkToYtube !== "" && <SimpleLinkRenderer href={linkToYtube} target="_blank" className="mediaIcon linkToYtube" />}
                         {!isLightMode &&    <div id="mainAccessibilityButton">
                              <a id="INDmenu-btn" onClick={this.clickAcessibilityBtn} title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button"><span className="icon" ></span></a>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className={classNames("BottomHeaderArea", { "sticky": isStickyAvailable })} id="BottomHeaderArea">
                    <MainNavHeaderComponenta
                        isSticky={isStickyAvailable}
                        mainNavItems={mainNavItems}
                        logoData={logoData}
                        onToggleMenu={this.openMenu}
                        onToggleSearch={this.openSearchMenu}
                        isOpened={this.state.isOpened}
                        tabBgColor={tabBgColor}
                        more={more}
                        search={search}
                        displayType={displayType}
                    />

                    <MenuPopupComponenta
                        groupsWithItems={groupsWithItems}
                        isVisibleMenu={this.state.isOpened}
                        isVisibleSearch={this.state.isSearchOpened}
                        onCloseMenu={() => this.setState({ isOpened: false })}
                        onCloseSearch={() => this.setState({ isSearchOpened: false })}
                        followUsText={followUsText}
                        linkToInst={linkToInst}
                        linkToFb={linkToFb}
                        linkToTw={linkToTw}
                        linkToYtube={linkToYtube}
                        searchPageLink={searchPageLink}
                        lang={Ru ? "ru" : "en"}
                        displayType={displayType}
                    />
                </div>
            </div >
        )
    }
}