import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { CSSTransitionGroup } from "react-transition-group";
import { MobileSiteItemTickerData } from "./siteMobileManualTickerComponenta";
require("../../englishTicker/ynetnews_vesty_site_englishTicker.less")


interface TickerViewProps {
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    articles: MobileSiteItemTickerData[]

}

interface MobileTickerState {
    flashIndex: number

}

export class MobileTickerManualView extends React.Component<TickerViewProps, MobileTickerState>  {
    public static siteScriptName = "MobileTickerManualView";
    private timer = undefined;
    constructor(props) {
        super(props)
        this.state = {
            flashIndex: 0,

        }
    }
    private getHeaderPositon = (tickerLength: number, header: Element, header1: Element) => {
        if (tickerLength !== 0 && header !== undefined) {
            header.classList.add("withTicker")
            header1.classList.add("withTicker")
        }
    }
    componentDidMount() {
        var ticker = document.getElementsByClassName('manual-ticker')
        var header = document.getElementsByClassName('YnetNewsHeaderMobileComponenta')[0]
        var header1 = document.getElementsByClassName('YnetNewsHeaderMobile ')[0]
        this.getHeaderPositon(ticker.length, header, header1)
        const { articles } = this.props
        if (articles && articles.length > 0 && this.timer === undefined) {
            this.intervalTickerAnimation();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
    private nextFlash = () => {
        const { flashIndex } = this.state
        const { articles } = this.props
        if (flashIndex === articles.length - 1) {
            this.setState({
                flashIndex: 0
            });

        }
        else {
            this.setState({
                flashIndex: this.state.flashIndex + 1
            });
        }

    }

    private intervalTickerAnimation = () => {
        const { articles } = this.props
        if (articles.length > 0) {
            this.callTimer();
        }
    }

    private callTimer = () => {
        this.timer = setTimeout(() => {
            this.nextFlash()
            this.callTimer();
        }, this.calculateTime() + 200)
    }

    private calculateTime = (): number => {
        const { articles } = this.props
        let timeInterval: number;

        timeInterval = articles[this.state.flashIndex].title.length * 200
        return timeInterval

    }

    public render() {
        const { tabLink, tabLinkTarget, articles } = this.props
        const { flashIndex } = this.state

        return (
            <div className="MobileTickerComponenta">
                <div className="MobileTicker">
                    <HyperLinkBuilder className="tickerPageLink" href={tabLink} linkTarget={tabLinkTarget} >
                        <span>+</span>
                    </HyperLinkBuilder>
                    {articles.length > 0 && <CSSTransitionGroup transitionName="tickerDate" transitionEnterTimeout={500} transitionLeave={true} transitionLeaveTimeout={500}>
                        <div className="articleInfo"
                            key={articles[flashIndex].id}
                            id={articles[flashIndex].id}>
                            <span className="dateOfItem" >
                                {separateHourFromDateAndFormatToString(articles[flashIndex].flashDate, { showDate: false, showHour: true })} |
                            </span>
                            <HyperLinkBuilder href={articles[flashIndex].link}
                                className="titleOfItem"
                                linkTarget={articles[flashIndex].linkTarget}
                                children={articles[flashIndex].title} >
                                <div style={{ animationDuration: `${this.calculateTime()}ms` }} dangerouslySetInnerHTML={{ __html: articles[flashIndex].title }} />
                            </HyperLinkBuilder>
                        </div>
                    </CSSTransitionGroup>}
                    <HyperLinkBuilder className="openTicker" href={(articles.length == 0) ? null : articles[flashIndex].link}
                        linkTarget={(articles.length == 0) ? null : articles[flashIndex].linkTarget} />
                </div>
            </div>
        )
    }
} 
