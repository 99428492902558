import * as has from "lodash/has"

interface RedMailFileUploadProps {
    handleSelectedFile: (e: any) => void
    selectedFiles: any
    hashes: string[]
    invalidFilesName: string[]
    showFileStatusProgress: (fileKey: any) => string
}

export class RedMailFileUploadYnet extends React.Component<RedMailFileUploadProps, {}> {

    render() {
        const { handleSelectedFile, selectedFiles, invalidFilesName, hashes, showFileStatusProgress } = this.props;
        return (
            <div className="RedMailFilesUpload">
                <div style={{ display: Object.keys(selectedFiles).length > 0 && "none" }} className="FilesUploadButtonWrapper">
                    <input type="file" id="inputFile"
                        onChange={handleSelectedFile}
                        multiple
                    />
                    <button className="FilesUploadButton">
                    إضافة ملّف أو صور
                      
                    </button>
                </div>
                
                <div className="addFileWrapper">
                   
                    <div className="addFileInputWrapper">
                        {Object.keys(selectedFiles).length > 0 && <div className="fileWrapper" style={{ width: '100%' }}>
                            {Object.keys(selectedFiles).map(fileKey =>
                                <div className="fileName" key={fileKey}>
                                    <div className="fileNameIcon"></div>
                                    <input type="text" dir="rtl" value={selectedFiles[fileKey].fileName} />
                                </div>)}
                        </div>}
                        {Object.keys(selectedFiles).length > 0 && Object.keys(selectedFiles).length < 5 &&
                            <div className="addFile" onClick={() => document.getElementById("inputFile").click()}>
                                <div className="addFilePlusIcon">+</div>
                                <div className="textInstruction">
                                 <span className="addFileTitle">ارفاق ملف اخر</span><span>{`حتى 200 ميجا بايت mov,png,jpeg,jpg,mp4 `}</span></div></div>}
                       
                        {!!invalidFilesName.length && <div className="files-names-error-row">
                            {invalidFilesName.map(file => <span key={file}> الملف  {`${file}`}  كبير جدا، الرجاء ارفاق ملف سعته حتى ٢٠٠ ميجا </span>)}
                        </div>}
                       
                      
                    </div>
                </div>
            </div>
        )
    }
}
