require('./ynet_yplus_site_logoutPopupComponent.less')

export class LogoutPopupComponent extends React.Component<{ closeLogoutPopupOpen, logOutFromPopUp }>{
    render() {
        return (
            <div className="LogoutPopupComponent">
                <div className="LogoutPopup">
                    <div className="YnetPlusLogo"></div>
                    <div className="text-content">בחרת להתנתק מהמשתמש שלך.
                        <span>האם להמשיך?</span>
                    </div>
                    <div className="ButtonsWrapper">
                        <button onClick={this.props.closeLogoutPopupOpen} className="ReturnButton">חזור</button>
                        <button onClick={this.props.logOutFromPopUp} className="LogoutButton">כן, התנתק</button>
                    </div>
                </div>
            </div>
        )
    }
}