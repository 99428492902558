import { SiteMediaData, SiteItemData, SiteImageData } from "../../../interfaces"
import { VideoSettingsData, LinkedItemType, ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { IFieldDisplayData } from "../../../commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { MediaContentTypes } from "constants/contentTypes"
import { PayWallArctilceIcon } from "../../../commonComponents/inline/payWallHavak/payWallHavak";
import { MarketingSign } from "../../../commonComponents/inline/marketingSign/marketingSign";
import { isWcmEditor } from "../../../../config/serverConfig";
require("./site_miltiImagesComponenta.less")


interface MultiImagesFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    slot: SiteItemData
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    subTitleColor: string
    titleColor: string
    lastHeightSize?: number
    isGrid1280?: boolean
    isDoubleTab?: boolean
    margin: number
    index: number
    isYplusItem?: boolean
    isLtr?: boolean
}


export class MultiImagesFrontItem extends React.Component<MultiImagesFrontProps>{
    private commaDateView = (itemType) => {
        const { fieldsDisplaySettings } = this.props
        if (itemType === LinkedItemType.ARTICLE && (fieldsDisplaySettings.isDateVisible || fieldsDisplaySettings.isHourVisible))
            return true
        else return false
    }
    private dateView = (itemType) => {
        const { fieldsDisplaySettings } = this.props
        if (itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isDateVisible)
            return true
        else return false
    }
    private hourView = (itemType) => {
        const { fieldsDisplaySettings } = this.props
        if (itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isHourVisible)
            return true
        else return false
    }
    private titleClassName = (isSubTitleVisible: boolean) => {
        if (isSubTitleVisible) return "slotTitle oneRow"
        else return "slotTitle twoRow"

    }
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }

    public render() {
        const { imageWidth, slot, imageHeight, videoSettings, isDoubleTab, margin, isLtr,
            fieldsDisplaySettings, titleColor, subTitleColor, isGrid1280, index, isYplusItem } = this.props;
        const { itemId, icon, hasVideo, premiumExpireDays, dateUpdatedOnSite, marketingContentText, media, titleTargetLink, titleLink, subTitle, title, author, itemType, isCommertial } = slot;
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const marginStyle = isLtr ? { marginRight: margin } : { marginLeft: margin };

        return (
            <div className={`slotView ${isWcmEditor() ? "inEditMode" : ""}`} key={`slotItem_${itemId}_${index}`}
                style={marginStyle}>

                <div className="imageArea">
                    {marketingContentText && <MarketingSign isSmallItem={true} marketingContentText={marketingContentText} />}
                    <SlotIconDisplay itemId={itemId} icon={this.getIcon(icon, hasVideo)} iconClassName="medium" isIconVisible={hasVideo} />
                    <PayWallArctilceIcon
                        premiumExpireDays={premiumExpireDays}
                        dateUpdatedOnSite={dateUpdatedOnSite}
                        className="onImage" />
                    {isGrid1280 && isCommertial && <div className="commertialTExt">מודעה</div>}
                    <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                        <SiteMedia

                            data={media !== undefined ? media : emptyMedia}
                            width={imageWidth}
                            height={imageHeight}
                            videoSettings={videoSettings}
                            itemLinkUrl={titleLink}
                            isImageLinkable={true}
                            linkTarget={titleTargetLink !== undefined && titleTargetLink}
                        />
                    </div> </div>
                <div className="slotTextArea">
                    {fieldsDisplaySettings.isTitleVisible && <div className={this.titleClassName(fieldsDisplaySettings.isSubTitleVisible && subTitle !== undefined)}>
                        <HyperLinkBuilder
                            href={titleLink}
                            linkTarget={titleTargetLink !== undefined && titleTargetLink}
                            children={title} >
                            <span style={{ color: titleColor }} >
                                <div dangerouslySetInnerHTML={{ __html: title }} />
                            </span>
                        </HyperLinkBuilder>
                    </div>}
                    {fieldsDisplaySettings.isSubTitleVisible && subTitle !== undefined && !isDoubleTab && !isYplusItem && <div className="slotSubTitle" >
                        <HyperLinkBuilder
                            href={titleLink}
                            linkTarget={titleTargetLink !== undefined && titleTargetLink}
                            children={subTitle} >
                            <span style={{ color: subTitleColor }} >  <div dangerouslySetInnerHTML={{ __html: subTitle }} /></span>
                        </HyperLinkBuilder>
                    </div>}
                    {!isYplusItem && <div className="moreDetails">
                        {fieldsDisplaySettings.isAuthorVisible && <span className="authorField">{author}</span>}
                        {fieldsDisplaySettings.isAuthorVisible && this.commaDateView(itemType) && author.length > 0 && <span>, </span>}
                        {(this.dateView(itemType) || this.hourView(itemType)) && <span> {separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: this.dateView(itemType), showHour: this.hourView(itemType) })} </span>} </div>}
                </div>
            </div>
        )
    }
}