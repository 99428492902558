
import { secondsToHms } from "../../../siteWidgets/siteWidgetUtils";
import { TogglePlayAction, AudioPlayButton } from "../../radioWidgets/floatingPlayer/audioPlayButton"
require("./calcalist_site_calcalistPodcastPopupPlayer.less")

declare var window: Window & {
    wcmAudioPlayer: AudioPlayButton
}

export interface CalcalistPodcastPopupPlayerProps {
    isAudioPlaying: boolean
    playbackTime: number
    audioElement: HTMLAudioElement
    durationSeconds: number
    isControlsDisabled: boolean
    isSeeking: boolean
    isMuted: boolean
    loadingSource: boolean
    onseeked: (playbackTime: number) => void
    handleMuteSwitch: () => void
    onTogglePlayPause: () => TogglePlayAction
}

export interface CalcalistPodcastPopupPlayerState {
    loadingPlay: boolean
}

export class CalcalistPodcastPopupPlayer extends React.Component<CalcalistPodcastPopupPlayerProps & { audioUrl: string, title: string, podcastImage: string }, CalcalistPodcastPopupPlayerState> {

    public static siteScriptName = "CalcalistPodcastPopupPlayer"

    constructor() {
        super()
        this.state = {
            loadingPlay: false,
        }
    }

    componentDidMount() {
        this.props.audioElement.autoplay = true;
        this.props.audioElement.src = this.props.audioUrl

        let playAttempt = setInterval(() => {
            this.setState({ loadingPlay: true })

            this.props.audioElement
                .play()
                .then(() => {
                    clearInterval(playAttempt);
                    this.setState({ loadingPlay: false })
                })
                .catch((error) => {
                    console.log("Unable to play the video, User has not interacted yet.");
                });
        }, 3000);
    }

    private jumpForward = () => {
        this.props.audioElement.currentTime += 15;
    }

    private jumpPrev = () => {
        this.props.audioElement.currentTime -= 15;
    }

    private updateCurrentTime = (e) => {
        this.props.onseeked(Number(e.target.value));
    }

    render() {
        const { isAudioPlaying, title, podcastImage, durationSeconds, playbackTime, isSeeking, onTogglePlayPause, loadingSource } = this.props
        const { loadingPlay } = this.state
        const percentage = (playbackTime - 0) * 100 / (durationSeconds - 0);
        const rangeBackground = `linear-gradient(90deg, #ffffffde ${percentage}%, #ffffff61 ${percentage}%)`;

        return (
            <div className="calcalist-podcast-popup-player">
                <div className="calcalist-podcast-popup-player-wrapper">
                    <div className="popup-player-podcast-controls">
                        <div className="calcalist-popup-btns-container">
                            <button className={`forwardStep jumpto-button`} onClick={this.jumpForward} aria-label="skeep 15 seconds"></button>

                            <button className={`calcalist-popup-play-button ${loadingPlay || loadingSource || isSeeking ? "calcalist-popup-play-button-loader" : ""}`} onClick={onTogglePlayPause} aria-label={isAudioPlaying ? `pause - ${title}` : `play - ${title}`}>
                                <div className={`calcalist-popup-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                            </button>

                            <button className={`prevStep jumpto-button`} onClick={this.jumpPrev} aria-label="go backwards 15 seconds"></button>
                        </div>

                        <div className="calcalist-popup-controls-ruler">
                            <div className="time-text">{secondsToHms(durationSeconds)}</div>
                            <input type="range" className="timeline" max={durationSeconds} value={playbackTime} onChange={this.updateCurrentTime} style={{ background: rangeBackground }} />
                            <div className="time-text">{secondsToHms(playbackTime)}</div>
                        </div>
                    </div>

                    <div className="popup-player-podcast-title">{title}</div>

                    <div className="popup-player-podcast-image" style={{ backgroundImage: `url(${podcastImage})` }}></div>
                </div>
            </div>
        )
    }
}