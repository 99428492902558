require("./site_creditLogos.less")

export const CreditLogos = () => {
    return (
        <div className="CreditLogos">
            <div className="iconsWrapper">
                <a href="https://yit.co.il/#utm_source=ynet.co.il&utm_medium=referral&utm_campaign=footer2" target="_blank" rel="nofollow" className=" footer_yit_icon" title="אפיון ועיצוב UX UI, פיתוח"></a>
                <div className="homepage_footer_separator"></div>
                <a href="https://www.nagich.co.il/html5/sbs.py?_id=8675&amp;did=5905&amp;G=5905" target="_blank" rel="nofollow" className="footer_accessability_icon" title="נגיש בקליק"></a>
                <div className="homepage_footer_separator"></div>
                <a href="http://www.radware.com" target="_blank" rel="nofollow" className=" footer_radware_icon" title="Application delivery by radware"></a>
                <div className="homepage_footer_separator"></div>
                <a href="http://www.acum.org.il" target="_blank" rel="nofollow" className=" footer_acum_icon" title="האתר פועל ברישיון אקו&quot;ם"></a>
                <div className="homepage_footer_separator"></div>
                <a href="http://www.tali-rights.co.il/" target="_blank" rel="nofollow" className=" footer_tali_icon" title="האתר פועל ברישיון תל&quot;י"></a>
                <div className="homepage_footer_separator"></div>
                <a href="https://www.activetrail.co.il/?utm_source=ynet&amp;utm_medium=website&amp;utm_campaign=logo" target="_blank" rel="nofollow" className="footer_activetrail_icon " title="אקטיב טרייל"></a>
            </div>
        </div>
    )
}