require("./site_circleProgressBar.less")

interface circularProps {
    sqSize: number
    strokeWidth: number
    percentage: number
    index: number
}
class Circular extends React.Component<circularProps, {}> {
    constructor(props) {
        super(props);
        this.state = {};
    }
    static defaultProps = {
        sqSize: 60,
        percentage: 0,
        strokeWidth: 2
    }
    render() {
        const { sqSize, strokeWidth, percentage, index } = this.props
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (sqSize - strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * percentage / 100;

        return (
            <svg
                width={sqSize}
                height={sqSize}
                viewBox={viewBox}>
                <circle
                    className="circle-background"
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`} />
                <circle
                    className="circle-progress"
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth + 0.5}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} />
                <text
                    className="circle-text"
                    x="50%"
                    y="50%"
                    dy=".3em"
                    textAnchor="middle">
                    {`0${index + 1}`}
                </text>
            </svg>
        );
    }
}


interface CircularProgressState {
    percentage: number

}
interface CircularProgressProps {
    seconds?: number
    isActive: boolean
    index: number
    sqSize?: number
    key?: number
}

export class CircleProgressbarAnimated extends React.Component<CircularProgressProps, CircularProgressState> {
    constructor(props) {
        super(props);
        this.state = {
            percentage: 0
        }
    }
    static defaultProps = { sqSize: 60 }
    private changePrecentage = () => {
        const { isActive, seconds } = this.props;
        const diff = 1 / seconds;
        if (isActive) {
            this.setState(state => ({ percentage: state.percentage + diff }))
        } else {
            this.setState({ percentage: 0 })
        }
    }

    private animateBar;

    componentDidMount() {
        const { isActive, seconds } = this.props
        isActive && (this.animateBar = setInterval(this.changePrecentage, 10));
    }

    componentWillUnmount() {
        clearInterval(this.animateBar);
    }

    componentDidUpdate(prevProps: CircularProgressProps, prevState, snapshot) {
        if (prevProps.isActive !== this.props.isActive) {
            if (this.props.isActive) {
                this.setState({ percentage: 0 })
                this.animateBar = setInterval(this.changePrecentage, 10)
            } else {
                clearInterval(this.animateBar);
                this.setState({ percentage: 0 })
            }
        }
    }

    public render() {
        return (
            <Circular
                strokeWidth={2}
                sqSize={this.props.sqSize}
                percentage={this.state.percentage}
                index={this.props.index}
            />
        );
    }
}

