import { FooterLogos } from "./footerLogos/footerLogos"
import { CreditLogos } from "./creditLogos/creditLogos"
import { Channels } from "./channels/channels"
import { ToolsAndAbout } from "./toolsAndAbout/toolsAndAbout"

require('./ynet_magazine_site_ynetFooter.less')

export class SiteYnetFooter extends React.Component<{}, {}> {
    public static siteScriptName = "SiteYnetFooter";

    render() {
        return (
            <div className="ynetFooter">
                <CreditLogos />
                <ToolsAndAbout />
                <FooterLogos />
                <Channels />
            </div>
        )
    }
}
