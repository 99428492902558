import { LabelPlace } from "../../../topStory/labelIconUtils";
import { RichInput } from "widgets/commonComponents/base/richInput"


interface mediaLabelProps {
    labelText: string
    mediaIconPlace: LabelPlace
    className?: string
    onLabelTextChange: (val: string) => void
}

export class LabelText extends React.Component<mediaLabelProps, {}>{
    private getStyleForLabelPosition = (mediaIconPlace: string) => {
        switch (mediaIconPlace) {
            case "top right": return { top: "16px", right: "16px" }
            case "top left": return { top: "16px", left: "16px" }
            case "bottom right": return { bottom: "16px", right: "16px" }
            case "bottom left": return { bottom: "16px", left: "16px" }
        }
    }

    public render() {
        const { labelText, mediaIconPlace, onLabelTextChange, className } = this.props;
        if (labelText && labelText !== "") {
            return (
                <RichInput className={`labelText ${mediaIconPlace} ${className}`}
                    style={this.getStyleForLabelPosition(mediaIconPlace)}
                    value={labelText}
                    onChange={(val) => onLabelTextChange(val)} />

            )
        }
        else return null;
    }
} 