import { RadioProgramItem } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"

interface SingleShowItemProps {
    show: RadioProgramItem
    dayDate?: Date
    isMobileWeb: boolean
}

export const SingleShowItem = (props: SingleShowItemProps) => {
    const { show, dayDate, isMobileWeb } = props

    if (!show) {
        return <div className="single-show-item" />
    }

    const { image, title, anchor, startTime, endTime } = show
    const s = startTime.split(':')
    const e = endTime.split(':')
    const startDate = new Date(dayDate)
    const endDate = new Date(dayDate)
    startDate.setHours(Number(s[0]), Number(s[1]))
    endDate.setHours(Number(e[0]), Number(e[1]))

    const nowTime = new Date().getTime()
    const isProgramEnded = nowTime > startDate.getTime() && nowTime > endDate.getTime()
    const isNowPlaying = nowTime >= startDate.getTime() && nowTime <= endDate.getTime()

    return (
        <div className={`single-show-item ${isProgramEnded ? "disabled" : ""}`} >
            <div className="show-item-data">
                <div className="image-wrapper">
                    <img className="show-image" src={image} />

                    {isNowPlaying && isMobileWeb && <div className="now-playing-indication">الآن على الهواء</div>}
                </div>

                <div className="show-details">
                    {isNowPlaying && !isMobileWeb && <div className="now-playing-indication">الآن على الهواء</div>}

                    <div className="show-title">{title}</div>

                    <div className="show-info">
                        <div className="show-anchor">{anchor}</div>

                        <div className="separator"></div>

                        <div className="show-hours">{startTime} - {endTime}</div>
                    </div>
                </div>
            </div>

            <div className="under-show-separator"></div>
        </div>
    )
}