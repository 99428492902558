import { SiteItemData, SiteMediaData, SiteImageData } from "../../../interfaces"
import { IFieldDisplayData } from "../../../commonDataObjects/interfaces";
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { MediaContentTypes } from "constants/contentTypes"
import { isAriaHidden } from "../../../widgetsUtils"



interface MultiImagesFrontState {
    selectedIndex: number
}

interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    itemList: SiteItemData[]
    fieldsDisplaySettings?: IFieldDisplayData
    titleColor: string
    numberOfVisibleItems: number
    timeInterval: number
}

export class MultiImagesSpecialFront extends React.Component<MultiImagesFrontProps, MultiImagesFrontState>{
    public static siteScriptName = "MultiImagesSpecialFront";
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: this.rotateSelectedItemView()
        }
    }
    private rotateSelectedItemView = () => {
        const { timeInterval, numberOfVisibleItems } = this.props
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes()
        let timeMinutes = h * 60 + m
        let selectedIndex = Math.floor((timeMinutes / timeInterval) % numberOfVisibleItems)
        return selectedIndex
    }
    private handleScrollLeftClicked = () => {
        const { selectedIndex } = this.state
        const { numberOfVisibleItems } = this.props
        if (selectedIndex === numberOfVisibleItems - 1) { this.setState({ selectedIndex: 0 }) }
        else {
            this.setState({ selectedIndex: selectedIndex + 1 })
        }
    }

    private handleScrollRightClicked = () => {
        const { selectedIndex } = this.state
        const { numberOfVisibleItems } = this.props
        if (selectedIndex === 0)
            this.setState({ selectedIndex: numberOfVisibleItems - 1 })
        else {
            this.setState({ selectedIndex: selectedIndex - 1 })
        }
    }

    public render() {
        const { imageWidth, imageHeight, itemList, fieldsDisplaySettings, titleColor, numberOfVisibleItems } = this.props;
        const { selectedIndex } = this.state;
        const slot = itemList[selectedIndex]
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", credit: "", className: "", alt: "", isAriaHidden: true } as SiteImageData
        return (
            <div className="slotsContent">

                <div className="slotView" key={`slotItem_${slot.itemId}_${selectedIndex}`}>
                    <HyperLinkBuilder
                        href={slot.titleLink}
                        linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                    >  <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                            <SiteMedia data={slot.media !== undefined ? slot.media : emptyMedia} width={imageWidth} height={imageHeight} videoSettings={null} />
                        </div> </ HyperLinkBuilder>
                    <div className="slotTextArea">
                        {numberOfVisibleItems > 1 && <button className="scrollRightBtn slotListArrow"
                            aria-label="scroll right"
                            title="scroll right"
                            onClick={(e) => this.handleScrollRightClicked()} />}
                        {fieldsDisplaySettings.isTitleVisible && <div className="slotTitle">
                            <HyperLinkBuilder
                                href={slot.titleLink}
                                linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                children={slot.title}  >
                                <span style={{ color: titleColor }} ><div dangerouslySetInnerHTML={{ __html: slot.title }} /></span>
                            </HyperLinkBuilder>
                        </div>}
                        {numberOfVisibleItems > 1 && <button className="scrollLeftBtn slotListArrow" title="scroll left"
                            aria-label="scroll left"
                            onClick={(e) => this.handleScrollLeftClicked()} />}
                    </div>
                </div>

            </div>
        )
    }
}