import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { MultiImagesScrollPerItem } from "widgets/commonComponents/base/multiImagesScrollPerItem";


interface ynetShopsProps {
    items: any[]
    width: number
    imageWidth: number
}


export class YnetShops1280Gallery extends React.Component<ynetShopsProps, {}> {
    public static siteScriptName = "YnetShops1280Gallery";

    render() {
        const { items, width, imageWidth } = this.props


        return (
            <MultiImagesScrollPerItem imageWidth={imageWidth} width={width - 28} itemLength={items.length} marginRight={6}>
                {items.map((item, index) =>
                    <div className="slotView" key={`slotItem_${index}`} style={{ marginLeft: "6px" }} >

                        <SimpleLinkRenderer href={item.url} target="_blank">
                            <img src={item.image} title={item.title} />
                            <div className="titleItem">
                                {item.title}
                            </div>
                            <div className="subTitleItem">
                                {item.price_surfix} {item.price} ₪
                            </div>
                        </SimpleLinkRenderer>
                    </div>)}
            </MultiImagesScrollPerItem>
        )

    }
}
