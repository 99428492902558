import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"


interface YnetPremiumLoginState {
    user: { props: { firstName: string } }
}
export interface promItem {
    link: string
    urlLogo: string
    logoLink: string
    btnText: string
    title: string
    subTitle: string
    logoLinkTarget: LinkTargetProperties
    linkTarget: LinkTargetProperties
}
interface YnetPlusPropmotionProps {
    loggedView: promItem
    promView: promItem
}
export class YnetPlusPromotion extends React.Component<YnetPlusPropmotionProps, YnetPremiumLoginState>{
    public static siteScriptName = "YnetPlusPromotion";
    constructor(props) {
        super(props)
        this.state = {
            user: null,
        }
    }

    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({
                user: window.YitPaywall.user
            })
        }
    }



    render() {
        const { user } = this.state
        const { loggedView, promView } = this.props
        const item = !user ? promView : loggedView
        return (
            <div className="promotionItem">
                {item.urlLogo !== "" &&
                    <HyperLinkBuilder href={item.logoLink} linkTarget={item.logoLinkTarget}>
                        <SiteSimpleImage src={item.urlLogo} className="promLogo" width={90} height={33} />
                    </HyperLinkBuilder>}
                <div className="promTitle">{item.title}</div>
                <div className="promSubTitle">{item.subTitle}</div>
                <HyperLinkBuilder className="promButton" href={item.link} linkTarget={item.linkTarget}>{item.btnText}</HyperLinkBuilder>
            </div>
        )
    }
}