
import { MultiImagesScrollPerItem } from "widgets/commonComponents/base/multiImagesScrollPerItem";
import { SiteMedia } from "../../../siteWidgets/siteMedia";
import { VideoSettingsData } from "commonDataObjects/interfaces"
import { isWcmEditor } from "config/serverConfig"


interface ynetShopsProps {
    items: any[]

    videoSettings: VideoSettingsData
    isDfp: boolean
    dfpId: string
}


export class OneVideoGallery extends React.Component<ynetShopsProps, {}> {
    public static siteScriptName = "OneVideoGallery";
    render() {
        const { items, videoSettings, isDfp, dfpId } = this.props


        return (
            <div className="slotsContent">
                <div className="frame_forList">
                    <div className="slotListWrapper" >
                {items.map((item, index) =>
                    <div className="slotView" key={`slotItem_${index}`} style={{ marginLeft: "20px" }} >
                        <div className="imageArea" style={{ width: 190 + "px", height: 107 + "px" }}>
                            <SiteMedia
                                data={item.media}
                                width={190}
                                height={107}
                                videoSettings={videoSettings}
                                key={item.media.url}
                            />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item.title }} className="slotTitle medium" />
                    </div>)}
                        {isDfp && <div className="slotView dfpId" id={dfpId}>{isWcmEditor() && <div className="imageArea">{dfpId}</div>}</div>}
                    </div></div>
            </div>
        )

    }
}
