import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"
import { PplusLoadMoreView } from "./pplusLoadMoreView"
import * as axios from "axios"
import { Loader } from "./loader/loader"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";

const MAX_NUMBER_OF_ITEMS = 240

interface PplusLoadMoreProps {
    categoryId: string
    height?: number
    componentaId?: string
    showCategoryLabel?: boolean
    isMobileWeb?: boolean
    numberOfItems: number
    imagesDomain?:string
}

interface PplusLoadMoreState {
    articles: ArticleExtraData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export class SitePplusLoadMoreComponenta extends React.Component<PplusLoadMoreProps, PplusLoadMoreState> {
    public static siteScriptName = "SitePplusLoadMoreComponenta"

    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0,
            showLoader: false,
            showButton: true
        }
    }

    private showHIdeButton = (newArticles: ArticleExtraData[]) => {
        const { showLoader: loader, articles } = this.state;
        const showButton = !loader &&
            newArticles &&
            !(articles.length % this.props.numberOfItems) &&
            articles.length < MAX_NUMBER_OF_ITEMS;

        this.setState({ showButton })
    }

    private getArticles = () => {
        const { componentaId } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        this.setState({ showLoader: true, showButton: false })

        axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/pageNumber/${pageNumber}`)
            .then((res: any) => {
                const newArticles = res.data.data as ArticleExtraData[];

                this.setState(
                    {
                        articles: [...articles, ...newArticles],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                    }
                );
                if (typeof (window) !== "undefined") {
                    window.AddBanner && window.AddBanner();
                }
                this.showHIdeButton(newArticles)
            })
            .catch(err => console.error("error geting headline data:", err))
    }

    public render() {
        const { height, showCategoryLabel, isMobileWeb, imagesDomain } = this.props
        const { articles, showLoader: loader, showButton } = this.state;


        return (
            <div className={"pplusLoadMoreComponenta"}>
                <PplusLoadMoreView loader={loader} imagesDomain={imagesDomain} isCategoryVisible={showCategoryLabel} height={height} articles={articles} isMobileWeb={isMobileWeb} />
                <div className="buttonAndLoaderContainer">
                    {loader && <Loader />}
                    {showButton && <div className={"loadMoreArticlesButton"} onClick={() => this.getArticles()}>
                        <div className="btnImage"></div>
                        טען עוד...
                        </div>}
                </div>
            </div >
        )
    }
}