import * as axios from "axios"
import * as InfiniteScroll from "react-infinite-scroll-component"
import * as map from "lodash/map"
import { InfiniteArticleItem } from "./infiniteArticleItem"
import { isMobile } from "utils/isMobile"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";

const MOBILE_MAX_WIDTH = 700; // same as mobile media query

interface InfinityArticleComponentaProps {
    categoryId: string
    lastDate: Date
}

interface InfinityArticleComponentaState {
    lastDate: Date
    articlesList: {
        body: string
        id: string
    }[]
}

export class InfinityArticleComponenta extends React.Component<InfinityArticleComponentaProps, InfinityArticleComponentaState> {
    static siteScriptName = "InfinityArticleComponenta";
    constructor(props: InfinityArticleComponentaProps) {
        super(props)
        this.state = {
            lastDate: props.lastDate,
            articlesList: [],
        }
    }

    private loadNextArticle = () => {
        const { categoryId, } = this.props
        const { lastDate, articlesList, } = this.state;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/article/get_blazer_infinite_article/${categoryId}/${lastDate}`)
            .then((res: any) => {
                this.setState(
                    {
                        articlesList: [...articlesList, res.data.article],
                        lastDate: res.data.date
                    }
                );
            })
            .catch(err => console.error("error geting article data:", err))
    }

    render() {
        const { lastDate, articlesList } = this.state;
        if (typeof window !== "undefined") {
            return (
                !isMobile() && <div id="InfinityArticleComponenta">
                    <InfiniteScroll
                        dataLength={articlesList.length}
                        next={this.loadNextArticle}
                        hasMore={lastDate}
                        loader={<h4>Loading...</h4>}
                    >
                        {map(articlesList, (article, key) =>
                            <InfiniteArticleItem key={key} article={article} />
                        )}
                    </InfiniteScroll>
                </div>
            )
        } else return null
    }
}