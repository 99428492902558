require('./site_payWallHavak.less')


interface payWallProps {
    premiumExpireDays: number
    dateUpdatedOnSite: Date | string
    style?: any
    className?: string
    isVisible?: boolean
    dataTbHide?: boolean
    dataTbPremium?:boolean
}


export class PayWallArctilceIcon extends React.Component<payWallProps, {}> {



    public render() {
        const { className, style, isVisible, dataTbHide, dataTbPremium } = this.props;
        if (!isHavakVisible(this.props) && !isVisible) return null;
        return (
            <div className={`havakIcon ${className}`} style={style} data-tb-hide={dataTbHide && ''}  data-tb-premium={dataTbPremium && ''}/>
        )
    }
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function isHavakVisible({ dateUpdatedOnSite, premiumExpireDays }: { dateUpdatedOnSite?: Date | string, premiumExpireDays?: number }): number {
    const dateUpdated = typeof dateUpdatedOnSite === "string" ? new Date(dateUpdatedOnSite) : dateUpdatedOnSite
    if (!dateUpdated || !isFinite(dateUpdated as any) || !dateUpdated.getDate) { return 0 }
    if (premiumExpireDays) {
        var dateToExpire = addDays(dateUpdated, premiumExpireDays)
        return Number(dateToExpire > new Date());
    }
    else return 0;
}