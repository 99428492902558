import { isWcmEditor } from "config/serverConfig"
import { LinkProperties, LinkTargetProperties, BaseLinkTypes } from "global/components/linkSettings/interfaces"
import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext"
import { PageComponentaContext } from "widgets/interfaces"
import { hexToRgbA } from "../../widgetsUtils"
import { IdGenerator } from "utils/idGenerator"
import { injectArticleComponentaContext } from "../base/injectArticleComponentaContext"

interface HyperLinkBaseProps {
    linkTarget: LinkTargetProperties
    style?: React.CSSProperties
    className?: string
    isSuperFarm?: boolean
    shouldAddArticleAutoplayIndicator?: boolean
    ariaLabel?: string
    articleId?: string
    isDataTbLink?: boolean
}

interface HyperLinkProps extends HyperLinkBaseProps {
    link: LinkProperties<BaseLinkTypes>
}

@injectPageComponentaContext()
export class HyperLink extends React.Component<HyperLinkProps & Partial<PageComponentaContext>, {}> {
    static defaultProps = { isSuperFarm: false }
    public render() {
        const { link, getUrl, isSuperFarm, shouldAddArticleAutoplayIndicator, ariaLabel, articleId, ...rest } = this.props;
        return <HyperLinkBuilder link={link}{...rest} ariaLabel={ariaLabel} articleId={articleId} isSuperFarm={isSuperFarm} href={getUrl(link, shouldAddArticleAutoplayIndicator)}>{this.props.children}</HyperLinkBuilder>
    }
}

interface HyperLinkBuilderProps extends HyperLinkBaseProps {
    link?: LinkProperties<BaseLinkTypes>
    href: string
    children?: React.ReactNode
    extraProps?: object
}

export const HyperLinkBuilder: React.StatelessComponent<HyperLinkBuilderProps> = ({ link, href, linkTarget, style, className, isSuperFarm, children, ariaLabel, extraProps, articleId, isDataTbLink }) => {
    const iframeWidth = (linkTarget && linkTarget.lightBoxWidth) || 0
    const iframeHeight = (linkTarget && linkTarget.lightBoxHeight) || 0
    const linkOpenType = (linkTarget && linkTarget.linkOpenType) ? linkTarget.linkOpenType : null
    const iframeOpacity = (linkTarget && linkTarget.lightBoxOpacity / 100) || 0.7
    const iframeBackground = (linkTarget && linkTarget.lightBoxBgColor) || "#000000"
    const iframeFinalBg = hexToRgbA(iframeBackground, iframeOpacity.toString())
    const tag = href ? "a" : "span"
    const id = href && IdGenerator.generate();
    const props: any = { style, className, "aria-label": ariaLabel, id, ...extraProps, "data-articleid": articleId, "data-tb-link": isDataTbLink && '' };
    const linkType = link ? link.type : null
    let commercialScript = ""
    if (linkType === "COMMERCIAL") {
        const { providerId } = link.link.extraLinkData || ""
        const { id } = link.link
        commercialScript = `hpbClickTrans("${id}","","${providerId}")`
    }

    if (!isWcmEditor()) {
        if (href) {
            if (commercialScript) {
                let jshref;
                switch (linkOpenType) {
                    case "lightBox":
                        jshref = `document.injectLinkLightBox("${href}" ,${iframeWidth} ,${iframeHeight}, ${isSuperFarm}, "${iframeFinalBg}", "${props.id}")`
                        break;
                    case "new":
                        jshref = `window.open("${href}")`
                        break;
                    default:
                        jshref = `window.location.href = "${href}";`
                }
                props.href = `javascript:${commercialScript};${jshref}`
            } else {
                props.href = linkTarget.linkOpenType === "lightBox" ? `javascript:document.injectLinkLightBox("${href}" ,${iframeWidth} ,${iframeHeight}, ${isSuperFarm}, "${iframeFinalBg}", "${props.id}");` : href
                const target = linkTarget.linkOpenType === "new" ? "_blank" : "_top";
                if (target) {
                    props.target = target;
                }
            }
        }
    }
    return React.createElement(tag, props, children);
}


interface SimpleLinkRendererProps {
    href: string
    className?: string
    children?: React.ReactNode
    target?: string
    title?: string
}

export const SimpleLinkRenderer: React.StatelessComponent<SimpleLinkRendererProps> = ({ href, className, children, target, title }) => {
    const tag = !isWcmEditor() ? "a" : "span"
    const props: any = { href, className, target, title }
    return React.createElement(tag, props, children);
}