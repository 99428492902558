import { ArticleCommentData } from "./interfaces"

interface MutamArticleCommentsPopupWrapperProps {
    render: MutamArticleCommentsPopupRender
    lang: string
    articleId: string
    isFirmaMobileWeb: boolean
    numOfComments: number
    comments: ArticleCommentData[]
    isAddCommentFormVisible: boolean
    areAllCommentsExpanded: boolean
    IdOfLastTalkbackExpandedByUser: number
    updateArticleCommentsStorageState: () => void
    showFormWhenEmpty: boolean
    handleCloseCommentForm: () => void
    handleFormVisibility: () => void
    isAllCommentsPopupVisable: boolean
    handleCommentsPopupClose: () => void
    handleCommentsPopupVisibility: () => void
    isPopupClose: boolean
    loadMoreComments: () => void
    hasMore: boolean
}

export interface MutamArticleCommentsPopupRenderProps {
    lang: string
    articleId: string
    isFirmaMobileWeb: boolean
    numOfComments: number
    comments: ArticleCommentData[]
    isAddCommentFormVisible: boolean
    areAllCommentsExpanded: boolean
    IdOfLastTalkbackExpandedByUser: number
    updateArticleCommentsStorageState: () => void
    showFormWhenEmpty: boolean
    handleCloseCommentForm: () => void
    handleFormVisibility: () => void
    isAllCommentsPopupVisable: boolean
    handleCommentsPopupClose: () => void
    handleCommentsPopupVisibility: () => void
    isPopupClose: boolean
    loadMoreComments: () => void
    hasMore: boolean
}

export type MutamArticleCommentsPopupRender = (props: MutamArticleCommentsPopupRenderProps) => React.ReactElement<any>

export class MutamArticleCommentsPopupWrapper extends React.Component<MutamArticleCommentsPopupWrapperProps, {}>{

    render() {
        const { lang, articleId, comments, isFirmaMobileWeb, numOfComments, isAddCommentFormVisible, areAllCommentsExpanded, IdOfLastTalkbackExpandedByUser,
            updateArticleCommentsStorageState, showFormWhenEmpty, handleCloseCommentForm, handleFormVisibility, isAllCommentsPopupVisable,
            handleCommentsPopupClose, handleCommentsPopupVisibility, isPopupClose, loadMoreComments, hasMore } = this.props
        return (
            <div>
                {this.props.render({
                    lang,
                    articleId,
                    comments,
                    isFirmaMobileWeb,
                    isAddCommentFormVisible,
                    areAllCommentsExpanded,
                    IdOfLastTalkbackExpandedByUser,
                    numOfComments,
                    updateArticleCommentsStorageState,
                    showFormWhenEmpty,
                    handleCloseCommentForm,
                    handleFormVisibility,
                    isAllCommentsPopupVisable,
                    handleCommentsPopupClose,
                    handleCommentsPopupVisibility,
                    isPopupClose,
                    loadMoreComments,
                    hasMore
                })}
            </div>
        )
    }
}