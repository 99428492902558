import * as axios from "axios"
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils"
import { separateHourFromDateAndFormatToString } from "../../../../widgetsUtils"
import { CaricaturistDialog } from "../../caricaturist/components/caricaturistMobileComponenta";
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"


interface CartoonArchiveState {
    currPage: number
    articles: ArticleExtraData[]
    isLoadMore: boolean
    selectedItemIndex: number
    isDateMenuVisible: boolean
    limitDate: Date
    isInitialLimitDate: boolean
    isDialogOpen: boolean
    bodyOverflow: string
}
interface CartoonArchiveProps {
    firstPageArticles: any
    componentaId: string
    promotionImage: string
    promotionNotes: string
    publishedLink: string
    promotionImageText: string
    promotionImageLinkTarget: LinkTargetProperties
    promotionImageLink: string
    isLTR: boolean
    numberOfVisibleItems: number
}


export class CartoonArchiveComponentaMobile extends React.Component<CartoonArchiveProps, CartoonArchiveState>  {
    public static siteScriptName = "CartoonArchiveComponentaMobile"
    constructor(props) {
        super(props)
        this.state = {
            currPage: 0,
            articles: props.firstPageArticles ? props.firstPageArticles : [],
            isLoadMore: props.firstPageArticles && (props.firstPageArticles.length / this.props.numberOfVisibleItems) >= 1,
            selectedItemIndex: 0,
            isDateMenuVisible: false,
            limitDate: new Date(),
            isInitialLimitDate: true,
            isDialogOpen: false,
            bodyOverflow: '',
        }
    }


    componentDidMount() {
        this.setState({ bodyOverflow: document.body.style.overflow })
    }


    componentDidUpdate(prevProps: CartoonArchiveProps, prevState: CartoonArchiveState, prevContext: any): void {
        if (prevState.limitDate !== this.state.limitDate ||
            prevState.currPage !== this.state.currPage) {
            this.getArticles();
        }
        if (prevState.articles !== this.state.articles) {
            this.setState({
                isLoadMore: (this.state.articles.length) / ((this.state.currPage + 1) * this.props.numberOfVisibleItems) >= 1
            })
        }
    }
    private getArticles = () => {
        const { articles, currPage, limitDate } = this.state;
        const { componentaId } = this.props;
        const startDate = new Date('1992-04-01');
        const formatedStartDate = formatDate(startDate, "YYYY-MM-DD");
        const formatedEndDate = formatDate(limitDate, "YYYY-MM-DD");
        axios.get(`/iphone/json/api/article_list/${componentaId}/startDate/${formatedStartDate}/endDate/${formatedEndDate}/pageNumber/${currPage}`).then((res: any) => {
            const recivedArticles = res.data.data;
            this.setState({
                articles: currPage > 0 ? [...articles, ...recivedArticles] : recivedArticles,
            })
        }).catch(err => console.error("error geting articles data:", err))
    }
    public onSelectedItemIndexChange = (index) => {
        this.setState({ selectedItemIndex: index })
    }

    private loadMoreArticles = () => {
        this.setState({ currPage: this.state.currPage + 1 })
    }

    public openDialog = (index: number) => {
        this.onSelectedItemIndexChange(index);
        document.body.style.overflow = 'hidden';
        this.setState({ isDialogOpen: true })
    }

    public closeDialog = () => {
        this.setState({ isDialogOpen: false })
        document.body.style.overflow = this.state.bodyOverflow;
    }

    public render() {
        const { articles, selectedItemIndex, isLoadMore, isDialogOpen } = this.state
        const { title, promotionImageDetails: { url, credit } } = articles[selectedItemIndex];

        return (
            <>
                {isDialogOpen && <CaricaturistDialog
                    credits={credit}
                    name={title}
                    path={url}
                    onClose={this.closeDialog} />}
                <div className="CartoonArchiveComponenta">
                    {articles.map((item, index) => {
                        const { promotionImageDetails, dateUpdatedOnSite, title } = item;
                        const { imageId, url } = promotionImageDetails;
                        return (
                            <div key={imageId}>
                                <div className="date">קריקטורה יומית, {separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: true, showHour: false })}</div>
                                <SiteSimpleImage className="img" src={url} alt={title} onClick={() => this.openDialog(index)} />
                            </div>
                        )
                    })
                    }

                    {isLoadMore && <div className="loadMore">
                        <button className="loadMoreButton" disabled={!isLoadMore || articles.length === 0}
                            onClick={this.loadMoreArticles}>טען עוד</button>
                    </div>}

                </div >
            </>
        )
    }

}
