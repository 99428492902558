
import * as axios from "axios"
import { CategoryWideBuzzListItem } from "./calcalistWideBuzzMobileWrapperComponenta";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { getNewlinkTargetProperties } from "../../../ynet1280/ynetShops/data/ynetshopsData"

declare var window: Window & {
    googletag:any;
    AdUnitPropertiesClassObj:any;
}

interface CalcalistWideBuzzMobileComponentaProps {
    categories: CategoryWideBuzzListItem[]
    componentaId: string
}

interface CalcalistWideBuzzMobileComponentaState {
    articles: ArticleWideBuzzItem[]
}

interface ArticleWideBuzzItem {
    article_id: string
    title: string
    publishedLink: string

}

export class CalcalistWideBuzzMobileComponenta extends React.Component<CalcalistWideBuzzMobileComponentaProps, CalcalistWideBuzzMobileComponentaState> {
    public static siteScriptName = "CalcalistWideBuzzMobileComponenta";

    constructor() {
        super();
        this.state = {
            articles: []
        }
    }

    private getArticles = () => {
        const { componentaId, categories } = this.props;
        axios.get(`/iphone/json/api/calcalist_buzz_wide/${componentaId}/${categories[0].id}/1/0/0/0`)
            .then((res: any) => {
                const recivedArticles = res.data.data;
                this.setState({
                    articles: recivedArticles
                },()=>this.onDfpRefresh())
            }).catch(err => console.error("error geting articles data:", err))
    }

    componentDidMount() {
        this.getArticles();
    }

    private onDfpRefresh=()=>{
        if( window.googletag && window.AdUnitPropertiesClassObj && window.AdUnitPropertiesClassObj.Define_slots["text.strip.buzz"]){
            window.googletag.cmd.push(function() { window.googletag.pubads().refresh([window.AdUnitPropertiesClassObj.Define_slots["text.strip.buzz"]]); });
        }
    }

    render() {
        const { articles } = this.state;
        let defaultLinkTarget = getNewlinkTargetProperties();
        defaultLinkTarget.linkOpenType = "same";
        return (
            <div className="CalcalistWideBuzzMobileComponenta">
                {_.map(articles, (item, index) => (
                    <HyperLinkBuilder key={item.article_id} linkTarget={defaultLinkTarget} className="item-container" href={item.publishedLink}>
                        <div> {index + 1}. </div>
                        <div> {item.title}</div>
                    </HyperLinkBuilder>
                ))
                }
            </div>
        )
    }
}