
import { useState, useEffect, useCallback } from "react"
import { ArticleImagesFullScreenGalleryImageCounter } from "./articleImagesFullScreenGalleryImageCounter";
import ArticleImagesFullScreenGalleryMain from "./articleImagesFullScreenGalleryMain";
import { ArticleImageGallery } from "../articleImageGalleryComponenta/articleImageGalleryComponenta"


interface ArticleImagesFullScreenGalleryFrontProps {
    images: ArticleImageGallery[]
    index: number
    isLtr: boolean
    close: (currentImageIndex: number) => void
    isVisible: boolean
}

export const ArticleImagesFullScreenGalleryFront = ({ images, index, isLtr, close, isVisible }: ArticleImagesFullScreenGalleryFrontProps) => {
    const numOfImages = images.length;
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(index)

    const swipeRight = (): void => {
        const nextImageIndex = currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1
        setCurrentImageIndex(nextImageIndex)
    }

    const swipeLeft = (): void => {
        const nextImageIndex = currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1
        setCurrentImageIndex(nextImageIndex)
    }

    const handleKeyPress = (e: KeyboardEvent): void => {
        e.preventDefault();
        switch (e.key) {
            case 'Escape':
                close(currentImageIndex);
                break;
            case 'ArrowLeft':
                swipeLeft();
                break;
            case 'ArrowRight':
                swipeRight();
                break;
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress)
        return () => {
            document.removeEventListener("keydown", handleKeyPress)
        }
    }, [handleKeyPress]);

    useEffect(() => {
        setCurrentImageIndex(index)
    }, [index]);

    if (isVisible) {
        return(<div key={"ArticleImagesFullScreenGallery"} role="dialog"
            aria-label="images gallery"
            id="images_gallery" className="SiteArticleImagesGallery"
            onClick={() => close(currentImageIndex)} tabIndex={0}>
            <div className="GalleryWrapper" onClick={e => e.stopPropagation()}>
                <ArticleImagesFullScreenGalleryMain images={images} isLtr={isLtr} currentImageIndex={currentImageIndex} close={close} />
                <ArticleImagesFullScreenGalleryImageCounter numOfImages={numOfImages} currentImageIndex={currentImageIndex} swipeLeft={swipeLeft} swipeRight={swipeRight} />
            </div>
        </div>)
    }else{
        return null;
    }

}
