import { openTwitterShare, analyticsPushFunc } from "../../../widgetsUtils"

interface TwitterShareButtonProps {
    textToShare?: string
    className?: string
    handleOnClick?: () => void
    url?: string
    iconSrc?: string
    isEnglish?: boolean
}

export class TwitterShareButton extends React.Component<TwitterShareButtonProps, {}>{
    static defaultProps = { url: "", iconSrc: "", isEnglish: false }
    private twitterIcon = "/images/icons/twitter_icon.png"
    private current_url = window.location.href
    private getCurrentUrl = () => {
        const { url } = this.props
        let urlForPopUp: string;
        if (url !== "") {
            urlForPopUp = url
        }
        else { urlForPopUp = this.current_url }
        return urlForPopUp;
    }
    private getCurrentSrc = () => {
        const { iconSrc } = this.props
        let src: string;
        if (iconSrc !== "") {
            src = iconSrc
        }
        else { src = this.twitterIcon }
        return src;
    }

    private handleShareBtnClicked = () => {
        const { handleOnClick, textToShare } = this.props
        openTwitterShare(this.getCurrentUrl(), () => analyticsPushFunc("Twitter", "Photo Gallery", textToShare), "Photo Gallery")
        if (handleOnClick) { handleOnClick() }
    }

    render() {
        const { className, isEnglish } = this.props
        return <button className="twBtn" title={isEnglish ? "Share on Twitter" : "שתפו בטוויטר"} aria-label={isEnglish ? "Share on Twitter" : "שתפו בטוויטר"} onClick={e => this.handleShareBtnClicked()}>
            <img src={this.getCurrentSrc()} alt={isEnglish ? "Share on Twitter" : "שתפו בטוויטר"} aria-hidden={true} className={className} />
        </button>
    }
}