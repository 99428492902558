require("./calcalist_site_newsLetterComponenta.less")
import * as axios from "axios";
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { isWcmEditor } from "../../../../../config/serverConfig"
import { CalcalistNewsLetterItem } from "./calcalistNewsLetterItem";
import { cloningPage } from "../../../../../config/apiUrls"


interface CalcalistNewsLetterComponentItem {
    slotId: string
    imgUrl: string
    title: string
    subTitle: string
    frequency: string
    itemId: string
}

interface CalcalistNewsLetterComponentaProps {
    newsLetterItems: CalcalistNewsLetterComponentItem[]
}

interface CalcalistNewsLetterComponentState {
    customFields: {}
    email: string
    currentUser: Object
}

export class CalcalistNewsLetterComponenta extends React.Component<CalcalistNewsLetterComponentaProps, CalcalistNewsLetterComponentState>{

    public static siteScriptName = "CalcalistNewsLetterComponenta";
    public formName = "newsletter_form";
    constructor(props) {
        super(props);

        if (typeof window !== 'undefined') {
            window.tp = window.tp || [];
        }

        this.state = {
            currentUser: null,
            email: "",
            customFields: {},
        };

    }

    componentDidMount() {
        this.getCustomFieldsOnMount();
    }

    getCustomFieldsOnMount() {
        window.tp.push(["init", () => {
            const user = window && window.tp && window.tp.pianoId.getUser();
            const customFields = {};
            let parsedItemValue: boolean;
            if (user) {
                window.tp.pianoId.loadExtendedUser({
                    extendedUserLoaded: (data) => {
                        if (data && Object.entries(data).length !== 0) {
                            data.custom_field_values.map(fieldItem => {
                                parsedItemValue = fieldItem.value === '' ? false : JSON.parse(fieldItem.value.toLowerCase());
                                customFields[fieldItem.field_name] = parsedItemValue;
                            })
                            this.setState({ email: user.email, customFields:customFields, currentUser: user })
                        }
                    }, formName: this.formName
                });
            }
        }]);
    }



    public render() {
        const { newsLetterItems } = this.props;
        const { email, customFields } = this.state;
        const emailText = email ? `הניוזלטר יגיע אליכם לדוא"ל &nbsp;<span>${email}</span>` : "&nbsp;"; // for line height 
        
        return (
            <div className="CalcalistNewsLetterComponenta">
                <div className="CalcalistNewsLetterHeader">
                    <div className="headerImage"></div>
                    <div className="headerTitle">הרשמו לניוזלטרים שלנו ותישארו מעודכנים בכל הדברים החשובים והמעניינים שקורים בזירה הכלכלית</div>
                    <div className="headerSubTitle" dangerouslySetInnerHTML={{ __html: emailText }}></div>
                </div>
                <div className="separator"></div>
                <div className="itemsList">
                    {_.map(newsLetterItems, item => <CalcalistNewsLetterItem key={item.slotId} {...item} isPinaoSubscribed={customFields[item.itemId]}  />)}
                </div>
            </div>
        )
    }
}
