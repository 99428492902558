import { CalcalistArticleHeadlinesItem, SiteArticleHeadlinesData } from "./articleHeadlinesItemCalcalist";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { TaboolaItemArticleHeadlines } from "../../../ynet1280/articleHeadlinesAutoWidget/components/taboolaItem"
import { CalcalistArticleHeadlinesItemMutam } from "./articleHeadlinesItemMutamCalcalist";



interface CalcalistArticleHeadlinesListProps {
    itemList: SiteArticleHeadlinesData[]
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    isTaboolaVisible: boolean
    uid?: string
    hideVideoAds?: boolean
    commercialItemsNum:number
    commercialRepetitionsNum:number
    isMutam: boolean
}

export class CalcalistArticleHeadlinesList extends React.Component<CalcalistArticleHeadlinesListProps, {} >{
    public render() {
        const { itemList, isTaboolaVisible, commercialItemsNum, commercialRepetitionsNum, isMutam} = this.props
       // const availableTaboolaItems = (commercialItemsNum * commercialRepetitionsNum);
        const articles = itemList;
        const articleLength = articles.length;
          let visibleItems = [];
        if(articleLength>0){
            const availibaleRepetitions = Math.floor(articleLength/commercialItemsNum);
            const hasTaboolaItems = commercialRepetitionsNum > 0 && commercialItemsNum > 0 && articleLength > 0;
            const numberOfTaboolaItems  = availibaleRepetitions < commercialRepetitionsNum ? availibaleRepetitions : commercialRepetitionsNum;
            for (var i = 0; i < articleLength; i++) {
                if (isMutam) {
                    visibleItems.push(<CalcalistArticleHeadlinesItemMutam
                        key={i}
                        showTaboola={isTaboolaVisible && i == 7}
                        article={articles[i]}
                        {...this.props}
                        />)
                } else {
                        visibleItems.push(<CalcalistArticleHeadlinesItem
                            key={i}
                            showTaboola={isTaboolaVisible && i == 7}
                            article={articles[i]}
                            {...this.props}
                            />)
                }
            }
            if(hasTaboolaItems){
                for (var i=0; i<numberOfTaboolaItems; i++){
                   visibleItems.splice(commercialItemsNum*(i+1)+i, 0, <TaboolaItemArticleHeadlines id={"1x1-stream-widget-"+(i+1)}/>) 
                  }
              }
        }
        return (
            <div className="slotList" >
                {visibleItems}
            </div>
        )
    }
}

