import * as filter from "lodash/filter"

interface translationSettings {
    [lang: string]: any
}

export const articleCommentsTranslations =
{
    "he": {
        "Close Talkbacks": "סגור תגובות",
        "Load More Talkbacks": "טען תגובות נוספות",
        "Empty Content": "(לת)",
        "New Comment": "תגובה חדשה",
        "New Comment(premium)": "הוספת תגובה",
        "Comment To Coment": "הגיבו לתגובה",
        "Add New Comment To Comment": "הוספת תגובה לתגובה",
        "Add New Comment": " הוספת תגובה חדשה",
        "Close": "סגירה",
        "You Must Enter Title": "חובה להכניס כותרת",
        "Warning": "אזהרה:",
        "This Action Will Delete You Comment": "פעולה זו תמחק את התגובה שהתחלת להקליד",
        "Confirm": "אישור",
        "Cancel": "ביטול",
        "Name": "שם",
        "Email Talckbacks Placeholder": "כתובת דואל",
        "Location": "אזור מגורים",
        "Title": "כותרת",
        "Content": "תוכן",
        "The commenter agrees to the privacy policy and agrees not to submit comments that violate the": "המפרסם טוקבק מסכים למדיניות הפרטיות של YNET ומסכים שלא לפרסם תגובות הכוללות מידע המפר את",
        "terms of use": " תנאי השימוש",
        ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.": " לרבות דברי הסתה, דיבה וסגנון החורג מחופש ביטוי סביר.",
        "Preview": "תצוגה מקדימה",
        "Post Comment": "שלח תגובה",
        "Thank You": "תודה",
        "Comment Sent Confirmation Message": "קיבלנו את תגובתך ונשתדל לפרסמה, בכפוף לשיקולי המערכת.",
        "Close(Order Male)": "סגור",
        "Close All Comments": "סגירת כל התגובות",
        "Open All Comments": "פתיחת כל התגובות",
        "This Article Has One Comment": "לכתבה זו התפרסמה תגובה אחת",
        "This Article Has ": "לכתבה זו התפרסמו",
        "Comments In": "תגובות ב",
        "Discussions": "דיונים",
        "Present": "הצגת:",
        "End To Start": "מהסוף להתחלה",
        "Start To End": "מהתחלה לסוף",
        "Recommended": "מומלצות גולשים",
        "Interest": "עוררו עיניין",
        "Main Page": "עמוד ראשי YNET",
        "Sort Comments By": "הצג:",
        "Email": 'דוא"ל',
        "Comment Content": "תוכן תגובה",
        "Comments" : "תגובות",
        "term1": "אין לשלוח תגובות הכוללות מידע המפר את",
        "term2": "תנאי השימוש של Ynet",
        "term3": "לרבות דברי הסתה, דיבה וסגנון החורג מהטעם הטוב.",
    },
    "en": {
        "Close Talkbacks": "Close Talkbacks",
        "Load More Talkbacks": "Load more talkbacks",
        "Empty Content": "",
        "New Comment": "new comment",
        "New Comment(premium)": "New comment",
        "Comment To Coment": "reply",
        "Add New Comment To Comment": "add comment",
        "Add New Comment": "add comment",
        "Close": "cancel",
        "You Must Enter Title": "You must enter a headline",
        "Warning": "",
        "This Action Will Delete You Comment": "",
        "Confirm": "",
        "Cancel": "",
        "Name": "name",
        "Email Talckbacks Placeholder": "mail",
        "Email":"mail",
        "Location": "city",
        "Title": "title",
        "Content": "text",
        "The commenter agrees to the privacy policy and agrees not to submit comments that violate the": "The commenter agrees to the privacy policy of Ynet News and agrees not to submit comments that violate the ",
        "terms of use": "terms of use",
        ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.": ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.",
        "Preview": "Preview",
        "Post Comment": "send comment",
        "Thank You": "Thanks",
        "Comment Content": "text",
        "Comment Sent Confirmation Message": "We’ve received your comment and will publish, it pending moderation.",
        "Close(Order Male)": "Close",
        "Close All Comments": "Close all talkbacks",
        "Open All Comments": "See all talkbacks",
        "This Article Has One Comment": "1 Talkback for this article",
        "This Article Has ": "Talkbacks for this article",
        "Comments In": "",
        "Discussions": "",
        "Present": "",
        "End To Start": "older - newer",
        "Start To End": "newer - older",
        "Recommended": "recommended stories",
        "Interest": "popular stories",
        "Main Page": "main page  YNETNEWS",
        "Comments" : "Comments",
        "term1": "The commenter agrees to the privacy policy of Ynet News and agrees not to submit comments that violate the ",
        "term2": "terms of use",
        "term3": ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.",
    },
    "es": {
        "Close Talkbacks": "Cerrar comentarios",
        "Load More Talkbacks": "Cargar más comentarios",
        "Empty Content": "",
        "New Comment": "Nuevo comentario",
        "New Comment(premium)":"Nuevo comentario",
        "Comment To Coment": "Respuesta",
        "Add New Comment To Comment": "Agregar nuevo comentario",
        "Add New Comment": "Agregar nuevo comentario",
        "Close": "Cancelar",
        "You Must Enter Title": "Debes ingresar un títular",
        "Warning": "",
        "This Action Will Delete You Comment": "",
        "Confirm": "",
        "Cancel": "Cancelar",
        "Name": "Nombre",
        "Email Talckbacks Placeholder": "Correo",
        "Location": "Ciudad",
        "Title": "Titulo",
        "Content": "Texto",
        "The commenter agrees to the privacy policy and agrees not to submit comments that violate the": "Quien publica un comentario acepta la política de privacidad del Ynet Español, y acepta no publicar comentarios que incluyan información que viole los  ",
        "terms of use": "términos de uso",
        ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.": ", incluida la incitación, la difamación y cualquier otro tipo que viole la libertad de expresión adecuada.",
        "Preview": "Previsualizacion",
        "Post Comment": "Enviar comentario",
        "Thank You": "Gracias",
        "Comment Sent Confirmation Message": "Hemos recibido su comentario. Lo publicaremos sujeto a su moderación.",
        "Close(Order Male)": "Cerrar",
        "Close All Comments": "Cerrar todas los comentarios",
        "Open All Comments": "Ver todos",
        "This Article Has One Comment": "1 Comentario",
        "This Article Has ": "Comentarios",
        "Comments In": "",
        "Discussions": "",
        "Present": "",
        "End To Start": "Más antiguo - Más nuevo",
        "Start To End": "Más nuevo - Más antiguo",
        "Recommended": "Historias recomendadas",
        "Interest": "Historias populares",
        "Main Page": "Pagina principal",
        "Comments" : "комментарии",
    },
    "ru": {
        "Close Talkbacks": "Закрыть",
        "Load More Talkbacks": "Показать еще комментарии",
        "Empty Content": "",
        "New Comment": "новый комментарий",
        "New Comment(premium)": "новый комментарий",
        "Comment To Coment": "ответить на комментарий",
        "Add New Comment To Comment": "ответить на комментарий",
        "Add New Comment": "добавить комментарий",
        "Close": "отмена",
        "You Must Enter Title": "Вы должны написать заголовок",
        "Warning": "",
        "This Action Will Delete You Comment": "",
        "Confirm": "",
        "Cancel": "",
        "Name": "имя",
        "Email Talckbacks Placeholder": "эл. почта",
        "Location": "город",
        "Title": "заголовок",
        "Content": "содержимое",
        "The commenter agrees to the privacy policy and agrees not to submit comments that violate the": "Автор комментария принимает Условия конфиденциальности Вести и соглашается не публиковать комментарии, нарушающие ",
        "terms of use": "Правила использования",
        ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.": ", в том числе подстрекательство, клевету и выходящее за рамки приемлемого в определении свободы слова.",
        "Preview": "предварительный просмотр",
        "Post Comment": "опубликовать",
        "Thank You": "Спасибо",
        "Comment Sent Confirmation Message": "Ваш комментарий получен и будет опубликован, в ожидании модерации.",
        "Close(Order Male)": "Закрыть",
        "Close All Comments": "Закрыть все обсуждения",
        "Open All Comments": "См. все обсуждения",
        "This Article Has One Comment": "Новый отклик статьи",
        "This Article Has ": "",
        "Comments In": " - обсуждения статьи",
        "Discussions": "",
        "Present": "",
        "End To Start": "старое - новое",
        "Start To End": "новое - старое",
        "Recommended": "рекомендуемые",
        "Interest": "популярные",
        "Main Page": "Новости Израиля и Ближнего Востока",
        "Comments" : "Комментарии",
        "Email": "эл. почта",
        "Comment Content":"содержимое",
        "term1": "Автор комментария принимает Условия конфиденциальности Вести и соглашается не публиковать комментарии, нарушающие ",
        "term2": "Правила использования",
        "term3": ",  в том числе подстрекательство, клевету и выходящее за рамки приемлемого в определении свободы слова.",
    }
}


export const getTranslation = (lang, text: string) => {
    var obj = filter(articleCommentsTranslations, function (v, k) {
        return k === lang;
    });
    var content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]

}

