require("./ynet_site_commercialHeaderButton.less")
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { CommertialItem } from "./siteYnetHeaderComponenta";

const clickOutside = require('react-click-outside');

interface CommercialHeaderButtonState {
    isMenuOpened: boolean
}

interface CommercialHeaderButtonProps {
    btnTitle: string
    linksData: CommertialItem[]
    followUsLink: string
    followUsLinkTarget: LinkTargetProperties
}

@clickOutside
export class CommercialHeaderButton extends React.Component<CommercialHeaderButtonProps, CommercialHeaderButtonState> {
    constructor(props) {
        super(props)
        this.state = {
            isMenuOpened: false
        }
    }

    /* used for global clickOutside functionality don't remove */
    private handleClickOutside = () => {
        this.setState({ isMenuOpened: false });
    }

    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }

    render() {
        const { btnTitle, linksData, followUsLink, followUsLinkTarget } = this.props
        const { isMenuOpened } = this.state
        const hasFollowUsLink = followUsLink && followUsLink.length > 0;
        return (
            <div className="commercial-header-btn">
                <div className="commercial-btn-icon"></div>

                <div className="commercial-btn-wrapper" onClick={this.toggleMenu}>
                    {!hasFollowUsLink ? <div className="commercial-btn-title">{btnTitle}</div> : <HyperLinkBuilder href={followUsLink} className="commercial-btn-title" linkTarget={followUsLinkTarget}>
                        {btnTitle}
                    </HyperLinkBuilder>}
                    {!hasFollowUsLink && <div className={`menu-toggler ${isMenuOpened ? "to-close" : "to-open"}`} />}
                </div>

                {isMenuOpened && !hasFollowUsLink &&
                    <div className="commercial-link-select">
                        {linksData.map((linkData, i) =>
                            <HyperLinkBuilder className="commercial-link-btn" href={linkData.link} linkTarget={linkData.linkTarget} >
                                {linkData.title}
                            </HyperLinkBuilder>
                        )}
                    </div>}
            </div>
        )
    }
}