require("./ynet_ynetnews_vesty_site_siteAccordionSocialsShareComponenta.less")
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../widgetsUtils"
import { MailShareButton } from "widgets/siteWidgets/socialShareButtons/mail/mailShareButton"
import { WhatsAppShareButton } from "../socialShareButtons/whatsApp/whatsAppShareButton"
const clickOutside = require('react-click-outside');
import {getTranslation} from "../../defenitions/ynet1280/autoTickerRowWidget/autoTickerRowTranslations"


interface shareState {
    isMenuOpened: boolean
}
interface shareProps {
    shareUrl: string
    title: string
    articleId?: string
    isMobileWeb?: boolean
    className?:string
    isNew?:boolean
}
declare let window: Window & {
    languageCode: string
}

@clickOutside
export class AccordionSocialsShareComponenta extends React.Component<shareProps, shareState>{

    private handleShareBtnClicked = (button: string) => {
        if (typeof window !== "undefined") {
            const current_url = this.props.shareUrl;
            const {title, isNew} = this.props;
            const current_article_id = this.props.articleId ? this.props.articleId : null;
            switch (button) {
                case "fb":
                   openFacebookShare(current_url, "",   isNew ? () => analyticsPushFunc("Facebook",  "Share", title):() => analyticsPushFunc("Share News Flash", "News Flash Tab", "Facebook"), "Flash", current_article_id)
                    break;

                case "tw":
                    openTwitterShare(current_url, isNew ? () => analyticsPushFunc("Twitter", "Share", title): () => analyticsPushFunc("Share News Flash", "News Flash Tab", "Twitter"), "Flash", current_article_id)
                    break;

                case "mailto":
                    MailShareButton.openMailShare(title, current_url, "heFlash", isNew ? () => analyticsPushFunc( "Mail", "Share", title): () => analyticsPushFunc("Share News Flash", "News Flash Tab", "Mail"), "Flash", current_article_id)
                    break;

                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, isNew ? () => analyticsPushFunc("Whatsapp", "Share", title):() => analyticsPushFunc("Share News Flash", "News Flash Tab", "Whatsapp") , "Flash", current_article_id)
                    break;
            }
        }
    }

    private openNativeShare = async () => {
        const shareData = { url: this.props.shareUrl, title: this.props.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    render() {
        const { isMobileWeb , className} = this.props
        const lang = window && window.languageCode.slice(0, 2) ? window.languageCode.slice(0, 2) : "he";
        const textMail = getTranslation(lang, "ShareByMail");
        const textTw = getTranslation(lang, "ShareByTwitter");
        const textFb = getTranslation(lang, "ShareByFacebook");
        const textWa = getTranslation(lang, "ShareByWhatsApp");

        return (
            <div className={`AccordionShareWrapper ${className ? className : ""}`}>
                {isMobileWeb ?
                    <div className="mobileShareBtn" onClick={this.openNativeShare}></div> :
                    <div className="AccordionSocialsShareComponenta">
                        <button title={textMail} className="shareIcon1280 mailto" onClick={e => this.handleShareBtnClicked("mailto")}></button>
                        <button title={textTw} className="shareIcon1280 tw" onClick={e => this.handleShareBtnClicked("tw")}></button>
                        <button title={textWa} className="shareIcon1280 wa" onClick={e => this.handleShareBtnClicked("wa")}></button>
                        <button title={textFb} className="shareIcon1280 fb" onClick={e => this.handleShareBtnClicked("fb")}></button>
                    </div>}
            </div>
        )
    }
}
