import { VestiMobileMenuComponenta } from "./vestiMobileMenu";
import { EsMobileMenuComponenta } from "./esMobileMenu"
import * as classNames from "classnames"
import { YnetNewsMenuComponenta } from "./ynetNewsMobileMenu";
import { menuItems } from "./mobileMenu";
var Cookies = require('js-cookie');
require('./ynetnews_vesty_site_ynetNewsHeader.less')

interface MobileHeaderProps {
    weather: any
    lang: string
    items: menuItems[]
}
interface YnetWeatherState {
    selectedCity: string
    isMenuOpen: boolean
}


export class MobileHeaderComponenta extends React.Component<MobileHeaderProps, YnetWeatherState> {
    public static siteScriptName = "MobileHeaderComponenta"
    constructor(props) {
        super(props)
        let city = Cookies.get('weatherCity');
        if (city) {
            this.state = {
                selectedCity: city,
                isMenuOpen: null
            }
        }
        else {
            this.state = {
                selectedCity: '0', ...this.state
            }
        }
    }

    private toggleMenu = () => {
        let toggleMenu = !this.state.isMenuOpen
        this.setState({ isMenuOpen: toggleMenu })
    }

    private getLogo = () => {
        const { lang } = this.props
        if (lang === "ru") return "//www.vesty.co.il/images/ynetnewsresp/logo-menu_vesti.png"
        else if (lang === "es") return "//www.ynetespanol.com/picserver/ynetespanol/wcm_upload/2019/07/07/SkEWoOJbB/espnyol_logo_new.jpg"
        else return "//www.ynetnews.com/images/ynetnewsresp/logo-menu.png"
    }

    private getMainLink = () => {
        const { lang } = this.props
        if (lang === "ru") return "/home/0,7340,L-50,00.html"
        else if (lang === "es") return "//www.ynetespanol.com/"
        else return "//www.ynetnews.com/"
    }
    private getMenu = (lang: string) => {
        switch (lang) {
            case "ru": return <VestiMobileMenuComponenta isOpened={this.state.isMenuOpen} items={this.props.items} />
            case "en": return <YnetNewsMenuComponenta isOpened={this.state.isMenuOpen} items={this.props.items} />
            case "es": return <EsMobileMenuComponenta items={this.props.items} isOpened={this.state.isMenuOpen} />
        }
    }

    private getWeatherLink = () => {
        const { lang } = this.props
        if (lang === "ru") return "//www.vesty.co.il/weather"
        else if (lang === "en") return "//www.ynetnews.com/weather"
        else return "//weather.ynet.co.il/"
    }

    render() {
        const { weather, lang } = this.props
        const { selectedCity, isMenuOpen } = this.state
        let weatherData;
        if (lang !== "es") {
            weatherData = weather ? weather : [{ temp: "20", imageName: "1220.png" }]
        }
        else weatherData = []
        let cityNum = weather && lang !== "es" ? selectedCity : 0
        let openClass = isMenuOpen ? "open" : "";
        let menuClass = classNames("inner-menu", { "isMenuOpen": isMenuOpen === true, "isMenuClose": isMenuOpen === false, "": isMenuOpen === null })
        let vesty = lang === "ru" ? "vestyHeader" : ""
        return (
            <div className={`YnetNewsHeaderMobile ${vesty}`}>
                <div className="YnetNewsHeaderMobileComponenta">
                    <div className="inner-text"></div>
                    <div className="inner-logo">
                        <a href={this.getMainLink()}>
                            <img src={this.getLogo()} />
                        </a>
                    </div>

                    {lang !== "es" && <div className="weather_resp">
                        <div id="cdanwmansrch_weather1">
                            <div id="cdanwmansrch_weathertempsdiv1">
                                <a href={this.getWeatherLink()} className="main_search_nounderlined_link"><span id="cdanwmansrch_weathertemps1">{weatherData[cityNum].temp + 'C'}</span></a>
                                <span id="cdanwmansrch_weatherimage1">
                                    <a href={this.getWeatherLink()}>
                                        <img src={"//www.ynet.co.il/Cnt/Images/Weather/" + weatherData[cityNum].imageName} width="20" height="20" />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>}

                    <div className={menuClass} >
                        <div className="menu-btn" onClick={this.toggleMenu}></div>
                        <div className="menu-btn-right-line"></div>
                    </div>
                    <div className={`MobileBg ${openClass}`} onClick={this.toggleMenu} />
                    {this.getMenu(lang)}
                </div>
            </div>
        )
    }
}

