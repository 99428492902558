import { NewsLetterSignBox } from "./newsletterBox";


interface NewsLetterSignUpPopUpProps {
    itemId: string
    componentaId: string
    isPlus: boolean
    itemTitle:string
    accountName: string
}

interface NewsLetterSignUpPopUpState {
    isPopUpVisible: boolean
    hasSend: boolean
    user: { props: { firstName: string } }
    isLoginOpened: boolean,
}



export class NewsLetterSignUpPopUp extends React.Component<NewsLetterSignUpPopUpProps, NewsLetterSignUpPopUpState>{
    public static siteScriptName = "NewsLetterSignUpPopUp";
    constructor(props) {
        super(props)
        this.state = {
            isPopUpVisible: false,
            hasSend: false,
            user: null,
            isLoginOpened: false

        }
    }
    componentDidMount() {
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({
                user: window.YitPaywall.user
            })
        }
    }
    private openPopUp = () => {
        const { itemId, componentaId, isPlus } = this.props
        const { isPopUpVisible, user } = this.state
        if (!isPlus || (isPlus && user)) {
            this.setState({ isPopUpVisible: true })
            document.body.style.overflow = "hidden";
        }
        else {
            window && window.YitPaywall.openLoginPopUp()
        }

    }

    private closePopUp = () => {
        this.setState({ isPopUpVisible: false })

        document.body.style.overflow = "";
    }

    render() {
        const { itemId, componentaId, itemTitle, accountName} = this.props
        const { isPopUpVisible } = this.state

        return (
            <>
                <button className="signUpBtn" onClick={this.openPopUp}>להרשמה</button>
                {isPopUpVisible && <div className="popUpMask">
                    <NewsLetterSignBox itemTitle={itemTitle} itemId={itemId} onClose={this.closePopUp} componentaId={componentaId} accountName={accountName}/>
                </div>
                }
            </>
        )


    }
}
