require('./calcalist_site_loginComponenta.less')
interface calcalistLoginState {
    user: { props: { firstName: string } }
    isMenuVisible: boolean
}

export class CalcalistLoginAndShare extends React.Component<{}, calcalistLoginState>{
    static siteScriptName = "CalcalistLoginAndShare"
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            isMenuVisible: false,
        }
    }
    componentDidMount() {
        if (window && window.calcalist && window.calcalist.user) {
            this.setState({
                user: window.calcalist.user
            })
        }
    }

    private toggleMenu = () => {
        this.setState(pstate => {
            return {
                isMenuVisible: !pstate.isMenuVisible,
            }
        })
    }


    public render() {
        const { isMenuVisible, user } = this.state
        return (
            <div className="calcalistLoginAndShare">
                <a className="userAction" href="//www.facebook.com/calcalistonline" target="_blank">
                    <span className="icon header-facebook" title="פייסבוק" />
                </a>
                <a className="userAction" href="//twitter.com/calcalist" target="_blank">
                    <span className="icon header-twitter" title="טוויטר" />
                </a>
                <a className="userAction ConfLink" target="_blank" href="https://www.calcalist.co.il/conference/home/0,7340,L-5144,00.html">
                    <span className="userText" title="ועידות">ועידות</span>
                    <span className="icon Conf" title="ועידות" />
                </a>
                <a className="userAction RedMailLink" href="https://www.calcalist.co.il/redmail">
                    <span className="userText" title="דואר אדום" >דואר אדום</span>
                    <span className="icon RedMail" title="דואר אדום" />
                </a>
                <div className="calcalistLogin">
                    {!user &&
                        <a className="login_calcalist"
                        href={`//www.calcalist.co.il/homenc/1,7340,L-4158,00.html`}>

                            <span className="userLabel">שלום אורח/ת</span>
                            <div className="userLogin icon" />
                        </a>}

                    {user &&
                        <div className="loggedUser" onClick={this.toggleMenu}>
                            <span className="userLabel"> {`שלום ${user.props.firstName}`}</span>
                            <div className="userLogin icon" />
                        </div>
                    }

                    {isMenuVisible &&
                        <div className="loginMenu">
                        <a href={"//www.calcalist.co.il/home/1,7340,L-4159,00.html"} className="menuOption">פרופיל אישי</a>
                        <a className="menuOption" href={"//www.calcalist.co.il/home/0,7340,4163,00.html"}>תיק אישי</a>
                            <a className="menuOption logout"
                            href="/Ext/App/Billing/Login/CdaRegBill_GeneralLogout/0,12660,4158,00.html?continue=https://www.calcalist.co.il/home/1,7340,L-4158,00.html&amp;history=https://www.calcalist.co.il/home/1,7340,L-4163,00.html">
                                התנתקות</a>
                        </div>}
                </div>
            </div>
        )
    }
}