import { SiteMediaData, SiteItemData, SiteImageData } from "../../../interfaces"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { IFieldDisplayData } from "../../../commonDataObjects/interfaces"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { MediaContentTypes } from "constants/contentTypes"
import { SiteSimpleImage } from "../../../commonComponents/inline/siteSimpleImage";
require("./ynet_site_miltiImagesLabelsComponenta.less")


interface MultiImagesLabelsFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    itemList: Partial<SiteItemData>[]
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    titleColor: string
    lastHeightSize?: number
    isBottomLinkVissible?: boolean
    bottomLinkColor: string
    isLabelVisible: boolean
    bgColor: string
}

interface MultiImagesLabelsFrontState {
    num_of_scrolls_left_available: number
    num_of_scrolls_right_available: number
    width_in_pixels_of_last_overflowing_item_in_row: number
    rightOffset: number
}


export class MultiImagesLabelsFront extends React.Component<MultiImagesLabelsFrontProps, MultiImagesLabelsFrontState>{
    public static siteScriptName = "MultiImagesLabelsFront";
    constructor(props) {
        super(props);
        this.state = {
            num_of_scrolls_left_available: null,
            num_of_scrolls_right_available: null,
            rightOffset: 0,
            width_in_pixels_of_last_overflowing_item_in_row: 0
        }
    }


    private getItemMargin = (componentWidth: number, imageWidth: number) => {
        const singleItemWidth = imageWidth
        let numberofVisibleItems = Math.floor(componentWidth / singleItemWidth)
        let imagesWidth = numberofVisibleItems * singleItemWidth
        let spaceForMargin = componentWidth - imagesWidth
        let marginLeft = spaceForMargin / (numberofVisibleItems - 1)
        return marginLeft
    }
    public componentWillMount() {
        const { itemList, imageWidth, width } = this.props
        if (itemList.length > 0) {
            this.setupLisScrolling(itemList, width, imageWidth)
        }
    }


    private setupLisScrolling(medias: any[], width: number, imageWidth: number) {
        const num_of_items = medias.length
        const single_media_item_width = imageWidth + this.getItemMargin(width, imageWidth)
        const num_of_medias_fit_in_full_row = Math.floor(width / (imageWidth))
        const items_in_last_row = num_of_items % num_of_medias_fit_in_full_row;
        let num_of_scrolls_left_available: number;
        if (items_in_last_row !== 0) {
            num_of_scrolls_left_available = Math.floor(num_of_items / num_of_medias_fit_in_full_row)
        }
        else { num_of_scrolls_left_available = Math.floor(num_of_items / num_of_medias_fit_in_full_row) - 1 }
        const num_of_scrolls_right_available = 0
        this.setState({ num_of_scrolls_left_available, num_of_scrolls_right_available, width_in_pixels_of_last_overflowing_item_in_row: this.getItemMargin(width, imageWidth) })
    }

    private handleScrollLeftClicked = () => {
        const { width_in_pixels_of_last_overflowing_item_in_row, num_of_scrolls_left_available, num_of_scrolls_right_available, rightOffset } = this.state
        const { itemList, width, imageWidth, imageHeight } = this.props
        const single_media_item_width = imageWidth + width_in_pixels_of_last_overflowing_item_in_row

        if (num_of_scrolls_left_available === 1) {
            const num_of_medias_fit_in_full_row = Math.floor(width / (imageWidth))
            const rightOffset = (single_media_item_width * (itemList.length - num_of_medias_fit_in_full_row)) - width_in_pixels_of_last_overflowing_item_in_row
            this.setState({
                rightOffset: - rightOffset - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })

            return null

        }
        if (num_of_scrolls_left_available > 0) {
            this.setState({
                rightOffset: rightOffset - width - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available - 1 !== 0 ? num_of_scrolls_left_available - 1 : 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })
        }
    }

    private handleScrollRightClicked = () => {
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, width_in_pixels_of_last_overflowing_item_in_row, rightOffset } = this.state
        const { width } = this.props
        if (num_of_scrolls_right_available === 1) {
            this.setState({
                rightOffset: 0,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: 0
            })
            return null
        }
        if (num_of_scrolls_right_available > 0) {
            this.setState({
                rightOffset: rightOffset + width + width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: num_of_scrolls_right_available - 1 > 0 ? num_of_scrolls_right_available - 1 : 0
            })
        }
    }
    public componentWillReceiveProps(nextProps) {
        const { itemList, imageWidth, width } = nextProps

        if (nextProps.itemList.length > 0) {
            this.setupLisScrolling(itemList, width, imageWidth)
        }
    }


    public render() {
        const { width, imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings, titleColor, lastHeightSize,
            isLabelVisible, isBottomLinkVissible, bottomLinkColor, bgColor } = this.props
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, rightOffset } = this.state
        const itemWidth = this.getItemMargin(width, imageWidth) + imageWidth
        let isLeftArrowVisible = num_of_scrolls_left_available !== 0
        let isRightArrowVisible = num_of_scrolls_right_available !== 0
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        return (
            <div className="slotsContent" style={{ height: lastHeightSize }}>
                {isRightArrowVisible && <button
                    className="scrollRightBtn slotListArrow"
                    aria-label="scroll right"
                    title="scroll right"
                    style={{ top: isLabelVisible ? 48 : 28 }} onClick={(e) => this.handleScrollRightClicked()} />}
                <div className="slotListWrapper" style={{ "marginRight": rightOffset }} >
                    {itemList.map((slot, index) =>
                        <div className="slotView" key={`slotItem_${slot.itemId}_${index}`}
                            style={{ marginLeft: this.getItemMargin(width, imageWidth), background: bgColor }}>
                            {isLabelVisible && <div className="mediaLabel">

                                {slot.labelUrl !== "" && <HyperLinkBuilder
                                    href={slot.labelLink}
                                    linkTarget={slot.labelLink !== undefined && slot.labelLinkTarget}
                                    ariaLabel={slot.labelName}
                                >
                                    <SiteSimpleImage src={slot.labelUrl} width={146} aria-hidden="true" title={slot.labelName} height={20} />
                                </HyperLinkBuilder>}
                            </div>}
                            <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }}>
                                <SiteMedia itemLinkUrl={slot.titleLink} linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink} isImageLinkable={true} data={slot.media !== undefined ? slot.media : emptyMedia}
                                    width={imageWidth} height={imageHeight} videoSettings={videoSettings} />

                            </div>
                            <div className="slotTextArea">
                                {fieldsDisplaySettings.isTitleVisible && <div className="slotTitle twoRow">
                                    <HyperLinkBuilder
                                        href={slot.titleLink}
                                        linkTarget={slot.titleTargetLink !== undefined && slot.titleTargetLink}
                                        children={slot.title} >
                                        <span style={{ color: titleColor }} >
                                            <div dangerouslySetInnerHTML={{ __html: slot.title }} />
                                        </span>
                                    </HyperLinkBuilder>
                                </div>}
                                {isBottomLinkVissible && slot.bottomText !== undefined && <div className="slotBottomLink" >
                                    <HyperLinkBuilder
                                        href={slot.bottomTextLink !== undefined && slot.bottomTextLink}
                                        linkTarget={slot.bottomTextLinkTarget !== undefined && slot.bottomTextLinkTarget}
                                        children={slot.bottomTextLink} >
                                        <span style={{ color: bottomLinkColor }} >{slot.bottomText}</span>
                                    </HyperLinkBuilder>
                                </div>}
                            </div>     </div>)}</div>
                {isLeftArrowVisible && <button
                    title="scroll left"
                    aria-label="scroll left"
                    className="scrollLeftBtn slotListArrow"
                    style={{ top: isLabelVisible ? 48 : 28 }}
                    onClick={(e) => this.handleScrollLeftClicked()} />}



            </div>)
    }
}
