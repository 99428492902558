import { SiteYPlusLoadMoreData } from "./YplusLoadMoreView"
import { calculateAutomaticComponentaUrl } from "widgets/widgetsUtils"
import * as apiUrls from "config/apiUrls"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { getImgAlt, isAriaHidden, getImgTitle } from "../../../widgetsUtils"
interface YPlusLoadMoreItemSlotProps {
    article: Partial<SiteYPlusLoadMoreData>
    isCategoryVisible?: boolean
    ver?: number
}

export class YPlusLoadMoreItemSlot extends React.Component<YPlusLoadMoreItemSlotProps, {}>{
    static defaultProps = { isCategoryVisible: true }

    private getIcon = (hasVideo: boolean) => {
        if (hasVideo) return "video"
        else return "none"
    }
    public render() {
        const { article, isCategoryVisible, ver } = this.props;
        const { categoryName, title, subTitle, author, credit, promotionImageDetails, articleId } = article
        const width = ver > 1 ? 292 : 314;
        const height = ver > 1 ? 164 : 177;
        return (
            <a href={calculateAutomaticComponentaUrl(article)} articleid={articleId} className="slotView fadeInUp">
                <div className="mediaArea">
                    <div className="MediaCarousel" >
                        <img src={apiUrls.getImageUrl(promotionImageDetails, { w: width, h: height })} style={{ width: width, height: height }} alt={getImgAlt(promotionImageDetails)} aria-hidden={isAriaHidden(promotionImageDetails)} title={getImgTitle(promotionImageDetails)} />
                    </div>
                    {isCategoryVisible && <div className="categoryName">
                        {categoryName}
                    </div>}
                    {ver > 1 && <SlotIconDisplay itemId={article.articleId} icon={"video"} iconClassName="medium" isIconVisible={article.hasVideo} />}
                </div>
                <div className="textDiv slotTextArea" >
                    {isCategoryVisible && <div className="categoryName">
                        {categoryName}
                    </div>}

                    <div className="slotTitle">
                        <span>{title}</span>
                    </div>
                    <div className="slotSubTitle">
                        <span>{subTitle}</span>
                    </div>
                    {author && author.length > 0 && <div className="moreDetails">
                        <span>{author} </span>
                    </div>}
                </div>
            </a>

        )
    }
}