import * as axios from "axios";
import { SpotimScriptInjector } from "./spotimScriptInjector";
import { toggleSpotImPopUp } from "../articleSocialShare1280Component/articleSocialShareNew1280";

interface BlogsSpotimScriptInjectorProps {
    spotimTalkbackCount?: string;
    isPopUp?: boolean;
    isFirmaApp?: boolean;
    isMobileWeb?: boolean;
    isApp?: boolean;
}

interface BlogsSpotimScriptInjectorState {
    currentPostId: string;
  
}


export class BlogsSpotimScriptInjector extends React.Component<
    BlogsSpotimScriptInjectorProps,
    BlogsSpotimScriptInjectorState
> {
    public static siteScriptName = "BlogsSpotimScriptInjector";
    constructor(props: BlogsSpotimScriptInjectorProps) {
        super(props);
        this.state = {
            currentPostId: "",
           
        };
    }

    private clickListeners = [];

    componentDidMount() {
        this.renderCommentCounters();
    }

    componentWillUnmount() {
        this.removeCommentsClickListener();
    }

    private handleCommentsClick = (commentsPopUp) => {
        const { currentPostId } = this.state;
        if (this.props.isApp) {
            const newUrl = `go_to_comment?postId=${currentPostId}`;
            window.location.href = newUrl;            
        }
        
        else {
            toggleSpotImPopUp(commentsPopUp);
        }
    }

    private renderCommentCounters = () => {
        const spotimBlogsCmtCounters = document.querySelectorAll(
            `.social-icon.tb[data-post-id]`
        );
        if (spotimBlogsCmtCounters.length > 0) {
            let postsIds = [] as string[];
            spotimBlogsCmtCounters.forEach((element) => {
                const postId = element.getAttribute("data-post-id");
                postsIds.push(postId);
            });

            axios
                .get(
                    `https://open-api.spot.im/v1/messages-count?spot_id=sp_AJXaDckj&posts_ids=${postsIds.join(
                        ","
                    )}`
                )
                .then((res: any) => {
                    spotimBlogsCmtCounters.forEach((element) => {
                        const postId = element.getAttribute("data-post-id");
                        const spotimCmtCounter: HTMLElement =
                            element.querySelector(".spotim-comments-count");
                        const zeroComment = "הוספת תגובה";
                        const severalComments = `${res.data.messages_count[postId]} תגובות`;
                        const text =
                            res.data.messages_count[postId] === 0 || !res.data.messages_count[postId]
                                ? zeroComment
                                : severalComments;
                        spotimCmtCounter.innerText = text; 
                        console.log("spotimCmtCounter", spotimCmtCounter);
                        const listener = () => {                            
                            const currentPostId =
                                element.getAttribute("data-post-id");
                            this.setState({ currentPostId }, () => {
                                const commentsPopUp =
                                    document.querySelector(".spotimWrapper");
                                    this.handleCommentsClick(commentsPopUp)
                                
                            });
                        };
                        element.addEventListener("click", listener);
                    });
                })
                .catch((err) =>
                    console.error("error geting ticker data:", err)
                );
        }
    };

    private removeCommentsClickListener = () => {
        const blogsSpotimElements = document.querySelectorAll(
            ".social-icon.tb[data-spot-id]"
        );
        blogsSpotimElements.forEach((element, index) => {
            const listener = this.clickListeners[index];
            console.log("listener", listener);
            if (listener) {
                element.removeEventListener("click", listener);
            }
        });
        this.clickListeners = [];
    };

    render() {
        const { currentPostId } = this.state;

        return (
            <SpotimScriptInjector
                {...this.props}
                articleId={currentPostId}
                key={currentPostId}
                className="spotimWrapper articleBlogsSpotimWrapper"
            />
        );
    }
}
