export const logoutPremium = () => {
    window && window.YitPaywall.httpGet(window.YitPaywall.ApiRroutesConfig.Origin + window.YitPaywall.ApiRroutesConfig.logout + '?_=' + new Date().getTime(), 'DELETE', function (response) {
        setTimeout(function () { window.top.location.href = window.YitPaywall.freePath; }, 500);
    });
}

export const analyticsLogoutPushFunc = () => {
    if (typeof window !== "undefined") {
        let action = "Side Menu"
        if (document.querySelector("meta[property='og:IsPay']")) {
            action = "Header"
        }
        else if (window.dcPath != undefined) {
            const path_category = window.dcPath;
            let patch_array = path_category.split("-");
            if (patch_array[0].indexOf("8.") !== -1) {
                action = "Header"
            }
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'GA_Event',
            Category: 'Ynet+ - Logout',
            Action: `${action}`,
            Label: 'Click to Logout'
        });
    }
}

export const analyticsPremiumPushFunc = (label: string) => {
    if (typeof window !== "undefined") {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'Navigation_Events',
            Category: 'Side Menu',
            Action: 'User Mini-Menu',
            Label: label
        });
    }
}

