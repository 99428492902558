import * as filter from "lodash/filter"

export const remMailTranslations =
{
    "en-GB": {
        "red": "Red",
        "mail": "Mail",
        "headlineTitleRow1": "Have exclusive info, photos or videos?",
        "headlineTitleRow2": "We'd love to hear from you",
        "share your info here": "Share your info here",
        "the information": "The information",
        "add a file": "Add a file",
        "more details": "More details (not required)",
        "mail pattern": "An email address is required to be in this format: ynet@ynet.com ",
        "terms1": "I confirm my content is: original, not fraudulent or staged, not funded, not copied, and does not involve illegal act such as trespassing or invasion of privacy. The content will not be sent to any other party at the same time and is transferred to your sole ownership, at no cost and without limits on its use. Personal details are required for the purpose of crediting the owner of the content, should you choose to publish elsewhere or be required to provide them to an official body. I have read, understood, and agreed to the",
        "terms2": "and",
        "terms3": ". I agree to be added to Ynet's mailing list",
        "terms of use": "terms of use",
        "privacy policy": "privacy policy",
        "send": "Send",
        "terms of use must be approved": "Terms of use must be approved",
        "required": "Required",
        "add additional file": "Add additional file",
        "up to": "up to",
        "the file": "The file",
        "too heavy": "is too heavy- Upload file up to 200 MB only",
        "photo credit": "Photo credit",
        "sender name": "Sender name",
        "e-mail": "E-mail",
        "phone number": "Phone number",
        "phone validation text": "The number should consist of 10 digits without a hyphen",
        "add credit": "Add credit",
        "type in your name": "Type in your name",
        "type in your e-mail": "Type in your e-mail",
        "type in your number": "Type in your number",
        "the information was received": "The information was received and sent for review",
        "thank you for sharing": "Thank you for sharing",
        "homepage": "Homepage",
        "just a moment": "Just a moment...",
    },
    "ru-RU": {
        "red": "Красная ",
        "mail": "почта",
        "headlineTitleRow1": "Располагаете эксклюзивной информацией, фото, видео?",
        "headlineTitleRow2": "Заинтересованы в публикации? Поделитесь с нами",
        "share your info here": "Разместите здесь",
        "the information": "Информация",
        "add a file": "Прикрепите файл",
        "more details": "Дополнительная информация (заполнение необязательно)",
        "mail pattern": "Адрес электронной почты должен быть в следующем формате: ynet@ynet.com",
        "terms1": "Подтверждаю, что предоставленные материалы носят оригинальный характер, не являются продуктом мошенничества или инсценировки, не скопированы из постороннего источника и не связаны с незаконной деятельностью - проникновением на частную территорию, вторжением в частную жизнь и нарушением конфиденциальности. Данный материал или материалы не будут одновременно переданы третьей стороне и поступают в вашу исключительную собственность, безвозмездно и без каких-либо ограничений. Личные данные необходимы для указания авторства - в случае, если вы будете публиковать материалы на другом ресурсе или получите требование представить их официальным инстанциям. Я ознакомился/ознакомилась с",
        "terms2": "и",
        "terms3": ", и согласен/согласна их выполнять.",
        "terms of use": "правилами пользования Красной почтой",
        "privacy policy": "требованиями конфиденциальности",
        "send": "Отправить",
        "terms of use must be approved": "Условия пользования должны быть одобрены",
        "required": "Обязательное поле",
        "add additional file": "добавить файл",
        "up to": " до",
        "the file": "Файл",
        "too heavy": "слишком тяжелый - загрузите файл размером не более 200 МБ.",
        "photo credit": "Авторские данные фото/видео",
        "sender name": "Имя и фамилия отправителя",
        "e-mail": "Ваш E-mail",
        "phone number": "Ваш номер телефона",
        "phone validation text": "Номер должен состоять из 10 цифр без дефиса",
        "add credit": "Ввести авторские данные",
        "type in your name": "Ввести имя",
        "type in your e-mail": "Ввести адрес",
        "type in your number": "Ввести номер",
        "the information was received": "Информация получена и отправлена на проверку",
        "thank you for sharing": "Благодарим за сообщение",
        "homepage": "Главная страница",
        "just a moment": "Момент...",
    }
}


export const getTranslation = (lang, text: string) => {
    var obj = filter(remMailTranslations, function (v, k) {
        return k === lang;
    });
    var content = filter(obj[0], function (v, k) {
        return k === text;
    })
    return content[0]

}

