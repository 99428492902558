require("./pplus_site_shareMenu.less")
import { openFacebookShare, openTwitterShare, openMailShare, analyticsPushFunc, openWhatsAppShare } from "../../../widgetsUtils"

interface shareMenuProps {
    url: string
    title?: string
    isMobileWeb: boolean
}

export class PplusShareMenu extends React.Component<shareMenuProps, {}>{
    public static siteScriptName = "PplusShareMenu";

    private openShare = async () => {
        const shareData = { url: this.props.url, title: this.props.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    render() {
        const { url, title, isMobileWeb } = this.props
        let isShareButtonvisible = true;
        if (typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1) {
            isShareButtonvisible = false
        }

        return (
            <div>
                {isMobileWeb ?
                    <div className={`${isShareButtonvisible ? 'mobileShareBtn' : 'mobileShareBtnNone'}`} onClick={this.openShare}>
                    </div> :
                    <div className="shareMenu">
                        <div className="whatsapp socialIcon" onClick={() => openWhatsAppShare(url, () => analyticsPushFunc("Whatsapp", "Article", title), "Article Top")}></div>
                        <div className="twitter socialIcon" onClick={() => openTwitterShare(url, () => analyticsPushFunc("Tweeter", "Article", title), "Article Main Image")}></div>
                        <div className="facebook socialIcon" onClick={() => openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article", title), "Article Main Image")}></div>
                        <div className="email socialIcon" onClick={() => openMailShare(title, "פנאי פלוס", url, () => analyticsPushFunc("Mail", "Article", title), "Article Main Image")}></div>
                    </div>}
            </div>
        )
    }
}