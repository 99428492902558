
import { calculateAutomaticComponentaUrl, separateHourFromDateAndFormatToString, getImgAlt, isAriaHidden, getImgTitle } from "widgets/widgetsUtils";
import * as apiUrls from "config/apiUrls";
import { ImageDetails } from "images/interfaces";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay";
import { MediaVideoData } from "../../../../../media/interfaces";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { PayWallArctilceIcon } from "../../../../commonComponents/inline/payWallHavak/payWallHavak";
import { CategorySelectionItem } from "../../../../../global/components/categoryMultiSelect/categoryMultiSelect";

interface TwentyFourSeven1280SlotItemProps {
    article: Partial<TwentyFourSeven1280SlotItemData>
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    hideVideoAds?: boolean
    isMobileWeb: boolean
}

export interface TwentyFourSeven1280SlotItemData {
    articleId: string
    author: string
    credit: string
    title: string
    subTitle: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean
    publishedLink: string
    dateUpdatedOnSite: Date
    premiumExpireDays: number
    video?: MediaVideoData[]
    category: CategorySelectionItem
    subchannelName:string
}

export class TwentyFourSeven1280SlotItem extends React.Component<TwentyFourSeven1280SlotItemProps, {}>{

    private getIcon = (hasVideo: boolean) => {
        if (hasVideo) return "video"
        else return "none"
    }

    public render() {
        const { article, fieldsDisplaySettings, titleColor, subTitleColor, hideVideoAds, isMobileWeb } = this.props;
        const { title, subTitle, author, promotionImageDetails, publishedLink, dateUpdatedOnSite, hasVideo, video, premiumExpireDays, category: { textToShow }, subchannelName } = article;
        const { isAuthorVisible, isDateVisible, isHourVisible, isTitleVisible, isCategoryVisible } = fieldsDisplaySettings;
        const isMoreDetails = isAuthorVisible || isDateVisible || isHourVisible || isCategoryVisible;
        const isDateView = isDateVisible || isHourVisible;
        const articleMainVideo = article.video && article.video.length && article.video[0];
        const shouldDisplayAuthor = isAuthorVisible && author;
        const shouldDisplaySubTitle = fieldsDisplaySettings.isSubTitleVisible && !isMobileWeb;

        return (
            <>
                <div className="slotItem">
                    <div className="mediaArea">
                        <div className="MediaCarousel" >

                            {articleMainVideo ?
                                <a>
                                    <SiteMedia
                                        data={{
                                            ...articleMainVideo,
                                            url: articleMainVideo.main_link,
                                            poster: apiUrls.getImageUrl(promotionImageDetails, { w: 190, h: 112 }),
                                            mediaType: "MEDIA_VIDEO",
                                            title,
                                            showAds: !hideVideoAds
                                        } as any}
                                        width={190}
                                        height={112}
                                        videoSettings={{
                                            isVideoLightbox: true,
                                            hideVideoAds: hideVideoAds
                                        }}
                                        itemLinkUrl={title}
                                        isImageLinkable={true}
                                    />
                                </a>
                                :
                                <a href={publishedLink}>
                                    <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 190, h: 112 })}
                                        style={{ width: 190, height: 112 }}
                                        className="SiteImageMedia"
                                        alt={getImgAlt(promotionImageDetails)}
                                        aria-hidden={isAriaHidden(promotionImageDetails)}
                                        title={getImgTitle(promotionImageDetails)}
                                    />
                                </a>}

                        </div>
                        {!video && <SlotIconDisplay itemId={""} icon={this.getIcon(hasVideo)} iconClassName="medium" isIconVisible={hasVideo} />}
                        <PayWallArctilceIcon
                            premiumExpireDays={premiumExpireDays}
                            dateUpdatedOnSite={dateUpdatedOnSite}
                            className="onImage" />
                    </div>
                    <a href={calculateAutomaticComponentaUrl(article)} className="textDiv" >
                        {isTitleVisible && <div className="slotTitle" style={{ color: titleColor }}>
                            <span>{title}</span>
                        </div>}
                        {shouldDisplaySubTitle &&
                            <div className="slotSubTitle" style={{ color: subTitleColor }}>
                                <span>{subTitle}</span>
                            </div>}
                        {isMoreDetails &&
                            <div className="moreDetails">
                                {isCategoryVisible &&
                                    <>
                                        <div className="categoryView">{subchannelName}</div>
                                        {(isDateView || shouldDisplayAuthor) && <span className="separator">|</span>}
                                    </>
                                }
                                {shouldDisplayAuthor &&
                                    <>
                                        <span className="author">{author} </span>
                                        {isDateView && <span className="separator">|</span>}
                                    </>
                                }
                                {isDateView &&
                                    <span className="dateView">
                                        {separateHourFromDateAndFormatToString(dateUpdatedOnSite,
                                            {
                                                showDate: isDateVisible,
                                                showHour: isHourVisible,
                                                dateTimeSeperator: " | ",
                                            })}
                                    </span>}
                            </div>}
                    </a>
                </div>
            </>
        )
    }
}