import * as classNames from "classnames"
import { AddCommentFormWrapper, AddCommentFormRenderProps } from "../addCommentFormWrapper";
import { MobileWebAddCommentFormUI } from "./mutamAddCommentFormUI";
import { ArticleCommentWrapper, ArticleCommentRender, ArticleCommentRenderProps } from "../articleCommentWrapper"
import { getTalkbackNumberView } from "../../articleSocialShare1280Component/articleSocialShareNew1280";
import { ArticleCommentMobileWebUI } from "./mutamArticleCommentAppUI";
import { MutamArticleCommentsPopupRenderProps } from "../mutamArticleCommentsPropsWrapper";
import { getTranslation } from "../translations"
import { MobileWebCalcalistAddCommentFormUI } from "./mutamCalcalistAddCommentFormUI";

interface ArticleCommentsPopupProps {
    props: MutamArticleCommentsPopupRenderProps
}

export class MutamArticleCommentsCalcalistPopupUI extends React.Component<ArticleCommentsPopupProps, {}> {

    private handleOnAddCommentBtnPress = (e) => {
        const { handleFormVisibility } = this.props.props
        handleFormVisibility()
    }

    render() {
        const { lang, articleId, comments, IdOfLastTalkbackExpandedByUser, updateArticleCommentsStorageState, areAllCommentsExpanded, numOfComments, showFormWhenEmpty,
            isAddCommentFormVisible, handleCloseCommentForm, isAllCommentsPopupVisable, handleCommentsPopupClose, handleCommentsPopupVisibility, isFirmaMobileWeb,
            isPopupClose, loadMoreComments, hasMore } = this.props.props

        const commentsPopupClasses = classNames({
            "ArticleCommentsPopup": true,
            "hide": !isAllCommentsPopupVisable && !isPopupClose,
            "fadeIn": isAllCommentsPopupVisable,
            "fadeOut": isPopupClose,
        })
        const addCommentBtnClasses = classNames({
            "addCommentBtn-pf": true,
            "fadeIn": !isAddCommentFormVisible,
            "hide": isAddCommentFormVisible,
        })

        let ArticleCommentRender: ArticleCommentRender;
        ArticleCommentRender = (props: ArticleCommentRenderProps) => <ArticleCommentMobileWebUI props={props} />;
        const commentsNum =numOfComments && numOfComments > 0 ? getTalkbackNumberView(numOfComments.toString()) : "";
        return (
            <div>
                <div id="ArticleCommentsPopup" className={commentsPopupClasses}>
                    <div className="commentsTopBar">
                        <div className="commentsTopBarHeadline">
                            <div className="headlineTitle">{`${commentsNum} ${getTranslation(lang, "Comments")}`}</div>
                        </div>
                        <button className="popupCloseIcon" onClick={handleCommentsPopupClose}></button>
                    </div>

                    <div className="commentsWrapper">

                        <div className={addCommentBtnClasses} onClick={this.handleOnAddCommentBtnPress}>{getTranslation(lang, "New Comment(premium)")}</div>

                        <AddCommentFormWrapper
                            render={(props: AddCommentFormRenderProps) => <MobileWebCalcalistAddCommentFormUI props={props} />}
                            isAddCommentFormVisible={isAddCommentFormVisible}
                            lang={lang}
                            articleId={articleId}
                            formType={"NEW"}
                            handleCloseCommentForm={handleCloseCommentForm}
                            isFirstComment={showFormWhenEmpty && numOfComments === 0}
                        />

                        <div className="commentsContainer">
                            {comments.map((comment, i) =>
                                <div key={`comment_${comment.id}`}>
                                    <ArticleCommentWrapper
                                        render={ArticleCommentRender}
                                        lang={lang}
                                        IdOfLastTalkbackExpandedByUser={IdOfLastTalkbackExpandedByUser}
                                        handleUpdateArticleCommentsStorageState={updateArticleCommentsStorageState}
                                        isAutoExpanded={areAllCommentsExpanded}
                                        articleId={articleId}
                                        comment={comment}
                                        isFirma={null}
                                        isFirmaMobileWeb={isFirmaMobileWeb}
                                        handleCommentsPopupClose={handleCommentsPopupClose}
                                        isAllCommentsPopupVisable={isAllCommentsPopupVisable}
                                    />
                                </div>
                            )}
                        </div>

                        {hasMore &&
                            <div className="showMoreCommentsButton" onClick={e => loadMoreComments()}>
                                {getTranslation(lang, "Load More Talkbacks")}
                            </div>}

                    </div>
                </div>

            </div>
        )
    }
}