require("./ctech_calcalist_site_buzzItemsList.less")
import * as map from "lodash/map"
import { BuzzItem } from "./buzzItem"
import { ExternalScriptToDivInjector } from "../../../../../global/components/externalScriptToDivInjector/externalScriptToDivInjector"
import { isWcmEditor } from "../../../../../config/serverConfig"

export const BuzzItemsList = ({ items, includeAd, isDotShow, isCtechMobile, isMobile }:{
    items:any,includeAd:boolean,isDotShow:boolean,isCtechMobile:boolean,isMobile?:boolean}) => {
    const list = map(items, (item, index) => {
        return <BuzzItem key={index} item={item} position={index + 1} isDotShow={isDotShow} isMobile={isMobile} />
    })
    let new_dcPath = "central";
    if (typeof window !== "undefined") {
        if (window.dcPath != undefined) {
            const path_category = window.dcPath;
            let patch_array = path_category.split("-");
            if (patch_array[0].indexOf(".") !== -1) {
                let new_string = patch_array[0].indexOf(".") + 1;
                new_dcPath = patch_array[0].substring(new_string).toLowerCase();
            }
        }
    }
    return (
        <div className="BuzzItemsList">
            {list} 
            {!isWcmEditor() && !isMobile && !isCtechMobile && includeAd && <div id={`native.text.${new_dcPath}`} className="calcalist_banner"></div>}
            {!isWcmEditor() && isCtechMobile && includeAd && <div id="buzz.6" className="calcalist_banner show-small-vp"></div>}
            {!isWcmEditor() && !isCtechMobile && isMobile && includeAd && <div id="buzz6.mobile" className="c-gpt__template show-small-vp"></div>}
        </div>
    )
}