import { RenderReactAtSite } from "widgets/commonComponents/inline/renderReactAtSite"
import { ExtededImageDetails } from "../../../../../images/interfaces"
import { SiteSettingsHOC, SiteSettings } from "../../../../../global/components/siteSettingsHOC";
import { getMobileGalleryArrayObj } from "../../../../widgetsUtils";
import { SiteLayoutType } from "pagesPage/stateInterfaces"
import { ArticleImagesFullScreenGalleryMobile } from "./articleImagesFullScreenGalleryMobile";
import { ArticleImagesFullScreenGalleryFront } from "./articleImagesFullScreenGalleryFront";
import { ArticleImageGallery } from "../articleImageGalleryComponenta/articleImageGalleryComponenta"



interface ArticleImagesGalleryProps {
    articleId: string
    siteLayoutType: SiteLayoutType
    images: ArticleImageGallery[]
    index: number
    isLtr: boolean
    close: (currentImageIndex: number) => void
    open: () => void
    show: boolean
}



interface CombinedProps extends ArticleImagesGalleryProps, Partial<SiteSettings> { }

@SiteSettingsHOC()
export class ArticleImagesFullScreenGallery extends React.Component<CombinedProps, {}>{

    public render() {
        const { articleId, isLTR, siteLayoutType, platform, images, index, close, isLtr, open, show } = this.props
        if (siteLayoutType === "mobileWeb") {
            return (
                <ArticleImagesFullScreenGalleryMobile
                    images={images}
                    articleId={articleId}
                    isLtr={isLTR}
                    close={close}
                    open={open}
                    isVisible={show}
                    index={index}
                />
            )
        }
        return (
            <ArticleImagesFullScreenGalleryFront
                close={close}
                open={open}
                images={images}
                index={index}
                articleId={articleId}
                isLtr={isLtr}
                isVisible={show}
            />
        )
    }
}

