
require("./pplus_ynet_site_addCommentFormU1280.less")
import * as classNames from "classnames"
import { AddCommentFormRenderProps } from "../addCommentFormWrapper";
import { getTranslation } from "../translations";
import { disablePageRefresh, enablePageRefresh } from "../../../../../siteWidgets/siteWidgetUtils";

interface AddCommentFormProps {
    props: AddCommentFormRenderProps
}

export class AddCommentFormUI1280 extends React.Component<AddCommentFormProps, {}>{
    private getTermsLinkByLang = () => {
        const { lang } = this.props.props
        if (lang === "he") return "//www.ynet.co.il/articles/0,7340,L-3049332,00.html";
        else if(lang==="ru") return "https://www.vesty.co.il/articles/0,7340,L-4867560,00.html";
        else return "//www.ynetnews.com/articles/0,7340,L-3048342,00.html"
    }

    private onConfirmAlertBtnPress = () => {
        const { closeAlert, resetFormData, handleCloseCommentForm } = this.props.props
        closeAlert()
        handleCloseCommentForm()
        resetFormData()
    }

    handleInputFocus = () => {
        disablePageRefresh()
    }
    
    handleInputOutOfFocus = () => {
        enablePageRefresh()
    }

    render() {
        const { isAddCommentFormVisible, isSent, title, name, email, comment, showAlert, formType,
            lang, alertBeforeClosing, handleCloseCommentForm, closeAlert, onInputChanged, handleCommentSend, isFirstComment } = this.props.props
        const AddCommentFormClasses = classNames({
            "AddCommentFormUI1280": true,
            "fadeIn": isAddCommentFormVisible,
            "hide": !isAddCommentFormVisible,
        })
        let term1 = " אין לשלוח תגובות הכוללות מידע המפר את";
        let term2 ="תנאי השימוש של Ynet";
        let term3 = " לרבות דברי הסתה, דיבה וסגנון החורג מהטעם הטוב.";
        if(lang==="en"){
            term1 = "The commenter agrees to the privacy policy of Ynet News and agrees not to submit comments that violate the ";
            term2 = "terms of use";
            term3 = ", including incitement, libel and expressions that exceed the accepted norms of freedom of speech.";
        }
        if(lang==="ru"){
            term1 = "Автор комментария принимает Условия конфиденциальности Вести и соглашается не публиковать комментарии, нарушающие ";
            term2 = "Правила использования";
            term3 = ",  в том числе подстрекательство, клевету и выходящее за рамки приемлемого в определении свободы слова.";
        }
        return (
            <div className={AddCommentFormClasses}>
                <div className="AddCommentForm" >
                    {!isSent &&
                        <div className="AddCommentFormContainer">
                        {!isFirstComment && <div className="topTitleWrapper">
                            <span className="formTitle"> {formType === "REPLY" ? getTranslation(lang, "Add New Comment To Comment") : getTranslation(lang, "Add New Comment")} </span>
                                <button className="closeBtnWrapper" title={getTranslation(lang, "Close(Order Male)")} onClick={e => alertBeforeClosing()}>
                                    <span className="closeIcon" />
                                    <span>{getTranslation(lang, "Close")}</span>
                                </button>
                        </div>}
                            {showAlert && <div className="commentSentMessageWrapepr alertMessage">
                                <div className="alertText"><span>{`${getTranslation(lang, "Warning")}`}</span></div>
                                <div className="successfullySentText"><span>{`${getTranslation(lang, "This Action Will Delete You Comment")}`}</span></div>
                                <div className="bottomPanel">
                                    <button className="closeText" title={getTranslation(lang, "Confirm")} onClick={this.onConfirmAlertBtnPress}><span>{getTranslation(lang, "Confirm")}</span></button>
                                    <button className="closeText" title={getTranslation(lang, "Cancel")} onClick={e => closeAlert()}><span>{getTranslation(lang, "Cancel")}</span></button>
                                </div>
                            </div>}
                            <div className="userDetailsInputs">
                            <input
                                className="userDetailsInput inputStyle"
                                maxLength={20}
                                value={name}
                                placeholder={getTranslation(lang, "Name")}
                                onChange={(e) => onInputChanged(e, "name")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            />
                            <input
                                className="userDetailsInput inputStyle"
                                maxLength={35} value={email}
                                placeholder={getTranslation(lang, "Email")}
                                onChange={(e) => onInputChanged(e, "email")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            />
                            </div>
                            <div className="commentInputs">
                            <input
                                className="title inputStyle"
                                type="text"
                                maxLength={60}
                                value={title}
                                placeholder={getTranslation(lang, "Title")}
                                onChange={(e) => onInputChanged(e, "title")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            />
                            <textarea
                                className="comment inputStyle"
                                type="text" maxLength={4000}
                                value={comment}
                                placeholder={getTranslation(lang, "Comment Content")}
                                onChange={(e) => onInputChanged(e, "comment")}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputOutOfFocus}
                            >
                            </textarea>
                            </div>
                            <div className="bottomPanel">
                            <div className="bottomPanelRow">
                                <div className="termsOfService">
                                {term1}
                                    <a href={this.getTermsLinkByLang()} target="_blank" title={getTranslation(lang, "terms of use")} style={{ margin: "auto 5px" }} >{term2}</a>
                                    {term3}
                                </div>
                                    <button className="sendBtn" id="addCommentSendButton" title={getTranslation(lang, "Post Comment")} onClick={e => handleCommentSend()}>
                                        <span>{getTranslation(lang, "Post Comment")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>}

                    {isSent && <div className="commentSentMessageWrapepr">
                        <div className="thankYouWrapper">
                            <div className="thankYou"><strong >{getTranslation(lang, "Thank You")},</strong> <span>{getTranslation(lang, "Comment Sent Confirmation Message")}</span></div>
                            <button className="closeTextWrapper" title={getTranslation(lang, "Close")} onClick={this.onConfirmAlertBtnPress}>
                                <span className="closeIcon" />
                                <span className="closeTextLabel" >{getTranslation(lang, "Close")}</span>
                            </button>
                        </div>
                    </div>}
                </div>

            </div>
        )
    }
}