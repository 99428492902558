interface MultiImagesFrontProps {
    width: number
    imageWidth: number
    itemLength: number
    isLTR?: boolean
}

interface MultiImagesFrontState {
    num_of_scrolls_left_available: number
    num_of_scrolls_right_available: number
    width_in_pixels_of_last_overflowing_item_in_row: number
    rightOffset: number
}
export const getItemMargin = (componentWidth: number, imageWidth: number) => {
    const singleItemWidth = imageWidth
    let numberofVisibleItems = Math.floor(componentWidth / singleItemWidth)
    let imagesWidth = numberofVisibleItems * singleItemWidth
    let spaceForMargin = componentWidth - imagesWidth
    let marginLeft = spaceForMargin / (numberofVisibleItems - 1)
    return marginLeft
}

export class MultiImagesScroller extends React.Component<MultiImagesFrontProps, MultiImagesFrontState>{
    constructor(props: MultiImagesFrontProps) {
        super(props);
        this.state = {
            num_of_scrolls_left_available: null,
            num_of_scrolls_right_available: null,
            rightOffset: 0,
            width_in_pixels_of_last_overflowing_item_in_row: 0
        }
    }


    public componentWillMount() {
        const { itemLength, imageWidth, width } = this.props

        if (itemLength > 0) {
            this.setupLisScrolling(itemLength, width, imageWidth)
        }
    }

    public componentDidUpdate({ itemLength, width }: MultiImagesFrontProps) {

        if (this.props.itemLength !== itemLength || this.props.width !== width) {
            this.setupLisScrolling(itemLength, width, this.props.imageWidth)
        }
    }


    private setupLisScrolling(num_of_items: number, width: number, imageWidth: number) {
        const num_of_medias_fit_in_full_row = Math.floor(width / imageWidth)
        const items_in_last_row = num_of_items % num_of_medias_fit_in_full_row;
        let num_of_scrolls_left_available: number;
        if (items_in_last_row !== 0) {
            num_of_scrolls_left_available = Math.floor(num_of_items / num_of_medias_fit_in_full_row)
        }
        else { num_of_scrolls_left_available = Math.floor(num_of_items / num_of_medias_fit_in_full_row) - 1 }
        const num_of_scrolls_right_available = 0
        this.setState({ num_of_scrolls_left_available, num_of_scrolls_right_available, width_in_pixels_of_last_overflowing_item_in_row: getItemMargin(width, imageWidth) })
    }

    private handleScrollLeftClicked = () => {
        const { width_in_pixels_of_last_overflowing_item_in_row, num_of_scrolls_left_available, num_of_scrolls_right_available, rightOffset } = this.state
        const { itemLength, width, imageWidth } = this.props
        const single_media_item_width = imageWidth + width_in_pixels_of_last_overflowing_item_in_row

        if (num_of_scrolls_left_available === 1) {
            const num_of_medias_fit_in_full_row = Math.floor(width / imageWidth)
            const rightOffset = (single_media_item_width * (itemLength - num_of_medias_fit_in_full_row)) - width_in_pixels_of_last_overflowing_item_in_row
            this.setState({
                rightOffset: - rightOffset - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })

            return null

        }
        if (num_of_scrolls_left_available > 0) {
            this.setState({
                rightOffset: rightOffset - width - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available - 1 !== 0 ? num_of_scrolls_left_available - 1 : 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })
        }
    }

    private handleScrollRightClicked = () => {
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, width_in_pixels_of_last_overflowing_item_in_row, rightOffset } = this.state
        const { width } = this.props
        if (num_of_scrolls_right_available === 1) {
            this.setState({
                rightOffset: 0,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: 0
            })
            return null
        }
        if (num_of_scrolls_right_available > 0) {
            this.setState({
                rightOffset: rightOffset + width + width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: num_of_scrolls_right_available - 1 > 0 ? num_of_scrolls_right_available - 1 : 0
            })
        }
    }


    public render() {
        const { width, imageWidth, isLTR } = this.props
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, rightOffset } = this.state
        const itemWidth = getItemMargin(width, imageWidth) + imageWidth
        let isLeftArrowVisible = num_of_scrolls_left_available !== 0
        let isRightArrowVisible = num_of_scrolls_right_available !== 0

        return (<div className="slotsContent">
            {isRightArrowVisible && <button className={`${isLTR ? 'scrollLeft' : 'scrollRight'} arrowBtn`}
                onClick={(e) => this.handleScrollRightClicked()}
                aria-label="scroll right" />}
            <div className="slotListWrapper" style={isLTR ? { "marginLeft": rightOffset } : { "marginRight": rightOffset }} >
                {this.props.children}
            </div>
            {isLeftArrowVisible && <button className= {`${isLTR ? 'scrollRight' : 'scrollLeft'} arrowBtn`}  aria-label="scroll left" onClick={(e) => this.handleScrollLeftClicked()} />}
        </div>
        )
    }
}

