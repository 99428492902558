import { RadioProgramItem } from "./radioHomepagePlayerComponenta";
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { AudioRendererProps } from "../../floatingPlayer/audioPlayButton";

interface MutamRadioHomepagePlayerProps extends AudioRendererProps{
    tabColor: string
    title: string
    tabButtonTitle: string
    tabButtonHref: string
    tabButtonLink: LinkProperties<BaseLinkTypes>
    tabButtonLinkTarget: LinkTargetProperties
    programItem: RadioProgramItem
}

export const MutamRadioHomepagePlayer = (props: MutamRadioHomepagePlayerProps) => {

    const { tabColor, title, tabButtonTitle, tabButtonHref, tabButtonLink, tabButtonLinkTarget, programItem, isAudioPlaying , onTogglePlayPause, onRefChanged } = props

    const getShowTime = (item: RadioProgramItem) => {
        if (item && !item.hideHours) {
            return `${item.startTime} - ${item.endTime}`
        }
        return ""
    }

    return (
        <div className="mutam-radio-homepage-player" ref={onRefChanged}>
            <div className="radio-player-tab" style={{ backgroundColor: tabColor }}>
                <div className="radio-player-tab-title">{title}</div>
                <div className="radio-player-tab-live-indication">{programItem.isProgramLive ? "LIVE" : ""}</div>
            </div>

            <div className="radio-player-content">
                <div className="radio-player-button" onClick={onTogglePlayPause}>
                    <div className={`radio-player-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                </div>

                <div className="radio-player-show-info-wrapper">
                    <div className="radio-player-show-info">
                        <div className="radio-player-show-time">{getShowTime(programItem)}</div>
                        <div className="radio-player-show-title">{programItem.title || 'אין תוכנית לנגן'}</div>
                    </div>
                </div>

                <img className="radio-player-equalizer-gif" src={"https://www.ynet.co.il/images/radio/equalizer.gif"} />
            </div>

            {tabButtonTitle &&
                <div className="radio-player-goto-btn">
                    <HyperLinkBuilder className="radio-player-goto-btn-link" href={tabButtonHref} link={tabButtonLink} linkTarget={tabButtonLinkTarget}>
                        <div className="radio-player-goto-btn-title">{tabButtonTitle}</div>
                        <div className="radio-player-goto-btn-arrow"></div>
                    </HyperLinkBuilder>
                </div>}
        </div>
    )
}