require("../../ynetPlusHeader/components/yplus_site_menu.less")
import * as classNames from "classnames"
import * as map from "lodash/map"
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { PremiumHeader } from "../../article/componentas/premium/componentas/premiumHeader/premiumHeader";
import { YplusSearchInput } from "./searchInput";
import { YnetPlusUserMenuItems } from "../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";
const clickOutside = require('react-click-outside');

export interface yPlusHeaderMenuItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    catId: string
}

interface yPlusHeaderMenuProps {
    items: yPlusHeaderMenuItem[]
    categoryId: string
    id?: string
    isMenuVisible: boolean
    siteId?: string
    isInline?: boolean
    seachPageLink: string
    mainLogoLink: string
    parentSiteLink: string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}



interface yPlusHeaderMenuState {
    isMenuOpened: boolean
    isInputOpened: boolean
    isUserLogged: boolean
}
@clickOutside
export class YplusSubChannelMenu extends React.Component<yPlusHeaderMenuProps, yPlusHeaderMenuState>{
    constructor(props) {
        super(props as yPlusHeaderMenuProps);
        this.state = {
            isMenuOpened: false,
            isInputOpened: false,
            isUserLogged: false
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.onScrollCallback)
        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLogged: true })
        }
    }


    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScrollCallback)
    }


    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }
    private showInput = () => {
        this.setState({ isInputOpened: true })
    }
    private hideInput = () => {
        this.setState({ isInputOpened: false })
    }
    private handleClickOutside = () => {
        this.setState({ isMenuOpened: false })
    }
    private onScrollCallback = (e) => {
        this.setState({ isMenuOpened: false })
    }
    render() {
        const { items, categoryId, id, isMenuVisible, isInline, seachPageLink, mainLogoLink, parentSiteLink, ynetPlusMenuItems } = this.props
        const { isInputOpened, isUserLogged } = this.state
        let firstList: yPlusHeaderMenuItem[];
        let secondList: yPlusHeaderMenuItem[];
        if (items.length > 6) {
            firstList = items.slice(0, 6)
            secondList = items.slice(6, items.length)
        }
        else firstList = items
        const isMenuHidden = !this.state.isMenuOpened ? "hidden" : ""
        const isStycky = isInline ? "inEditNotSticky" : ""
        return (
            <div className="YplusMenu subChannelPageMenu" >
                <div className={`yPlusStyckyHeader stickyAllTime ${isStycky}`} >
                    <PremiumHeader  className="yPlusPageHeader" isArticlePage={false} isStyckyBar={true}
                        mainLogoLink={mainLogoLink}
                        parentSiteLink={parentSiteLink}
                        ynetPlusMenuItems={ynetPlusMenuItems}>

                        {isMenuVisible &&
                            <div className="YplusNavigationList">
                                <ul>
                                    {map(firstList, (item, i) => {
                                        return (
                                            <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                </HyperLinkBuilder>
                                            </li>
                                        )
                                    }
                                    )}
                                    {items.length > 6 && <li className="menuItem secondLevelH"><span onClick={this.toggleMenu}>עוד
                        </span>
                                        <span className="menuTogglerWrapper" onClick={this.toggleMenu}>
                                            <span className="menuToggler"></span>
                                        </span>
                                        <ul className={`secondLevelMenu ${isMenuHidden}`}>
                                            {map(secondList, (item, i) => {
                                                return (
                                                    <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                                        <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                        </HyperLinkBuilder>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </li>
                                    }
                                </ul>       {seachPageLink !== undefined && (isUserLogged || (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL)) && <YplusSearchInput
                                    className="mobileInput"
                                    seachPageLink={seachPageLink}
                                    isInputOpened={isInputOpened}
                                    showInput={this.showInput}
                                    onFocusOutSearch={this.hideInput}

                                />}</div>}

                    </PremiumHeader>
                </div>

            </div>
        )
    }
}

export class SiteYplusSubChannelMenu extends React.Component<yPlusHeaderMenuProps, {}>{
    public static siteScriptName = "SiteYplusSubChannelMenu";
    render() {
        const { items, categoryId, id, isMenuVisible } = this.props

        return (
            <YplusSubChannelMenu {...this.props} isInline={false} />
        )

    }
}