import { AudioPlayButton, AudioRendererProps } from "../floatingPlayer/audioPlayButton";
import { AodFloatingPlayer } from "../floatingPlayer/aodFloatingPlayer";
import { AodFloatingPlayerMobileWeb } from "../floatingPlayer/aodFloatingPlayerMobileWeb";

interface RadioNewsflashPlayerProps {
    audioUrl: string
    hour: string
    durationSeconds: number
    isMobileWeb?: boolean
}

export function RadioNewsflashPlayer(props: RadioNewsflashPlayerProps) {
    const { hour, durationSeconds, isMobileWeb } = props;
    const playerTitle = `ynet Radio | מהדורת החדשות של שעה ${hour}`;
    const mobileWebPlayerTitle = "מהדורת החדשות האחרונה";

    return (
        <>
            {isMobileWeb ?
                <AudioPlayButton
                    {...props}
                    durationSeconds={durationSeconds}
                    renderer={(props: AudioRendererProps) => (<RadioNewsflashMobileWeb {...props} />)}
                    floatingPlayerRenderer={(props) => (<AodFloatingPlayerMobileWeb {...props} title={mobileWebPlayerTitle} />)}
                />
                :
                <AudioPlayButton
                    {...props}
                    durationSeconds={durationSeconds}
                    renderer={(props: AudioRendererProps) => (<RadioNewsflash {...props} hour={hour} />)}
                    floatingPlayerRenderer={(props) => (<AodFloatingPlayer {...props} title={playerTitle} isMobileWeb={isMobileWeb} />)}
                />
            }
        </>
    )
}

RadioNewsflashPlayer.siteScriptName = "RadioNewsflashPlayer"

function RadioNewsflash({ hour, isAudioPlaying, onTogglePlayPause, }: AudioRendererProps & { hour: string }) {
    return (
        <div onClick={onTogglePlayPause} className="RadioNewsflash">
            <div className="rnf-text">למהדורת החדשות של {hour}</div>
            <div className={`rnf audio-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
        </div>
    );
}

function RadioNewsflashMobileWeb({ isAudioPlaying, onTogglePlayPause, }: AudioRendererProps) {
    return (
        <div className="RadioNewsflashMobileWebWrapper" onClick={analyticsPushFunc}>
            <div onClick={onTogglePlayPause} className="RadioNewsflashMobileWeb">
                <div className="rnf-text">להאזנה למהדורת החדשות האחרונה</div>
                <div className={`rnf audio-headphones-button ${isAudioPlaying ? "pause" : ""}`} ></div>
            </div>
        </div>
    );
}

export const analyticsPushFunc = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: "GA_Events",
        Category: "Navigation Bar",
        Action: "Click to latest audio news edition",
        Label: "",
    });
}