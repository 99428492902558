import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { HamburgerMenuItems } from "../../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { YnetWeatherMutam } from "./ynetWeatherMutam"
import { HeaderScrolledMenu } from "./headerScrolledMenu";
import { MainNavMutam } from "./mainNavMutam";
import { FontSlider } from "../../../../ynet1280/ynetHeader/components/mutamComponents/fontSliderComponent";
import { openWhatsAppShare, analyticsPushFunc } from "../../../../../widgetsUtils";
import { YnetShareButtons } from "./ynetShareButtons"

interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isFontMenuOpen: boolean
    url: string
}

interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    flashPageLink: string
    flashLinkTarget: LinkTargetProperties
    redMailLink: string
    mailLinkTarget: LinkTargetProperties
    weatherData: any
    defaultCityIndex: any
    weatherUrl: string
    logoData: Logo
    ynetSearchPageLink: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    linkToInst: string
    linkToTelegram: string
    isArticle: boolean
}

export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}

export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}

declare let window: Window & {
    languageCode: string
}

export class SiteMutamYnetNewsHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteMutamYnetNewsHeaderComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isFontMenuOpen: false,
            url: ""
        }
    }

    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened })
        document.body.classList.toggle('lock-scroll');
    }

    private stickyBar() {
        const header = document.querySelector(".ynetnews-header");
        const headerPos = header && header.getBoundingClientRect().top;
        if (headerPos && headerPos + 40 < 0) {
            this.setState({ isSticky: true })
        } else {
            this.setState({ isSticky: false })
        }
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        let element = document.getElementById('ArticleBodyComponent');
        let headerElRect = document.querySelector(".ArticleHeaderMobileComponent") && document.querySelector(".ArticleHeaderMobileComponent").getBoundingClientRect();
        const progressBar = document.getElementById("progress-bar");
        if (!headerElRect) {
            return
        }
        let scrolled = element && headerElRect && (winScroll / (element.offsetTop + element.offsetHeight - headerElRect.height)) * 100;
        if (progressBar) {
            if (scrolled < 100) {
                progressBar.style.width = scrolled + "%";
            } else {
                progressBar.style.width = "100%";
            }
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
        this.props.isArticle && this.setfontSize();
        const url = typeof window !== "undefined" && window.location.href
        this.setState({ url: url })
    }

    private setfontSize = () => {
        if (localStorage && localStorage.fontSize) {
            let size = localStorage.fontSize;
            document.documentElement.style.fontSize = size * parseFloat(getComputedStyle(document.documentElement).fontSize) + "px";
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }

    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private openShare = async () => {
        const current_url = window.location.href;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    private handleCommentsPopupVisability = () => {
        if (typeof window !== "undefined") {
            window.handleCommentsPopupVisability && window.handleCommentsPopupVisability();
        }
    }

    private showCommentsBtn = () => {
        return typeof window !== "undefined" && window.handleCommentsPopupVisability;
    }

    private handleFontSizePopupVisability = () => {
        this.setState({ isFontMenuOpen: true })
    }

    private handleFontSizePopupClose = () => {
        this.setState({ isFontMenuOpen: false })
    }
    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }


    render() {
        const { mainNavItems, groupsWithItems, defaultCityIndex, weatherUrl, logoData,
            weatherData, ynetSearchPageLink, linkToFb, linkToTw, linkToYtube, isArticle, linkToInst, linkToTelegram } = this.props
        const { isSticky, isFontMenuOpen } = this.state
        const numOfComment = typeof window !== "undefined" && window.wcmNumOfComments ? window.wcmNumOfComments : "";
        const hidden = isSticky ? "hidden" : ""
        const tooltipHidden = !isSticky ? "hidden" : "";
        const lang = typeof window !== "undefined" && window && window.languageCode.slice(0, 2);
        const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;
        if (isArticle) {
            return (
                <div className={classNames("YnetNewsMutamHeader Article")} id="YnetMutamHeader">
                    <div className="progress-container">
                        <div className="progress-bar" id="progress-bar"></div>
                    </div>

                    {!isSticky &&
                        <>
                            {isApp ? <a href="share_article" title={"share"} className="topBtns mobileShareBtn"></a> : <button className="topBtns mobileShareBtn" onClick={this.openShare} title={"share"} />}
                            <div className={`logo`}>
                                <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                                    <div className="darkModeLogo"></div>
                                    <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                                </HyperLinkBuilder>
                            </div>
                        </>}

                    <div className={`btnTooltips ${tooltipHidden}`}>
                        <button className="topBtns fontSizeMenuOpen" onClick={this.handleFontSizePopupVisability}></button>
                        <button className="topBtns accessibiltyBtn" onClick={this.clickAcessibilityBtn} title="אתר נגיש" aria-labelledby="INDbtnTooltip"  accessKey="m"></button>
                        <a onClick={() => openWhatsAppShare(this.state.url, () => analyticsPushFunc("Whatsapp", "Article", ""), "Article Top")} className="topBtns whatsAppShareBtn" />
                        <button className="topBtns mobileShareBtn" onClick={this.openShare} title={"share"} />
                       <button style={{ display: this.showCommentsBtn() ? "block" : "none" }}  id="commentButtonInHeader"  className="topBtns commentsOpen" onClick={this.handleCommentsPopupVisability}>{numOfComment}</button>
                    </div>

                    <a href={"javascript:history.back()"} className="goBack" title="back" />
                    {isFontMenuOpen && <FontSlider onClose={this.handleFontSizePopupClose} lang={lang} />}
                </div>
            )
        } else
            return (
                <div className={classNames("YnetNewsMutamHeader")} id="YnetMutamHeader">
                    <div className={`logo ${hidden}`}>
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                            <div className="darkModeLogo"></div>
                            <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>
                    </div>

                    <MainNavMutam
                        mainNavItems={mainNavItems}
                        openHam={this.openMenu}
                    />

                    <div className={`bottomHeaderArea ${hidden}`} >
                        {weatherData && <YnetWeatherMutam weather={weatherData} defaultCityIndex={defaultCityIndex} weatherUrl={weatherUrl} />}
                        <YnetShareButtons facebookLink={linkToFb} telegramLink={linkToTelegram} instagramLink={linkToInst} />
                    </div>

                    <HeaderScrolledMenu
                        groupsWithItems={groupsWithItems}
                        isVisible={this.state.isOpened}
                        onClose={this.openMenu}
                        ynetSearchPageLink={ynetSearchPageLink}
                        linkToFb={linkToFb}
                        linkToTw={linkToTw}
                        linkToYtube={linkToYtube}
                        linkToInst={linkToInst}
                        linkToTelegram={linkToTelegram}
                    />
                </div>
            )
    }
}