require("./ynet_site_tickerAutoRowView.less")
require("./ynetnews_vesty_site_tickerAutoRowView.less")
import * as map from "lodash/map"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { TickerViewData } from "../../../tickerAuto/interfaces"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { SiteTickerData } from "../../../ticker/components/tickerComponenta"
import { RowTickerList } from "./rowTickerList"

interface TickerAutoRowViewProps {
    data: TickerViewData
    articles: Array<any>
    isEnglish: boolean
    hasArticleLink: boolean
}

export class TickerAutoRowView extends React.Component<TickerAutoRowViewProps, {}>  {

    public render() {
        const { data, articles, isEnglish, hasArticleLink } = this.props
        const targetTitleLink: LinkTargetProperties = {
            linkOpenType: "same",
            lightBoxBgColor: "",
            lightBoxHeight: null,
            lightBoxOpacity: null,
            lightBoxWidth: 0
        }
        let itemList = [];
        const shiftValue = 9 - articles.length;
        const arrayFixFunction = articles.length > 5 ? rotate : reverse;
        
        if (Object.keys(articles).length > 0) {
            itemList = map(articles, (article: any, index) => {
                return {
                    articlePublishDate: article.launchDate,
                    articleUpdateDate: article.dateUpdatedOnSite,
                    slotId: article.articleId,
                    title: article.title,
                    flashText: article.plainText,
                    flashDate: article.dateUpdatedOnSite,
                    flashDateType: "update",
                    link: article.publishedLink,
                    linkTarget: targetTitleLink,
                    flashLinkType: "flash",
                    itemImg: article.promotionImageDetails,
                    articleId: article.articleId,
                    author: article.author,
                    publishedLink: article.publishedLink,
                } as SiteTickerData
            })

            !isEnglish ? itemList = arrayFixFunction(itemList, shiftValue) : itemList 
        }

        return (
            <div className={`TickerComponenta1280 ${isEnglish ? "ltr" : ""}`}>
                <div className="slotsContent">
                    <RowTickerList 
                        siteFlashList={itemList}
                        titleColor={data.titleColor}
                        subTitleColor={data.subTitleColor} 
                        isAuto={true}
                        isLTR={isEnglish}
                        tabHref={data.tabHref}
                        seconds={data.seconds || 4}
                        firstArticleId={articles && articles.length > 0 ? articles[0].articleId : ""}
                        hasArticleLink={hasArticleLink}
                    />
                </div>
                <div className="moreTickersLink">
                    <HyperLinkBuilder href={data.tabHref} linkTarget={data.tabTitleLinkTarget}>
                        {data.tabTitleText}
                    </HyperLinkBuilder>
                </div>
            </div>
        )
    }
}

function rotate(arr: Array<any>, moveBy: number) {
    const dest = [];
    let current = moveBy <= 0 ? Math.abs(moveBy) : arr.length - moveBy;
    for (let index = 0; index < arr.length; index++) {
        dest[current] = arr[index];
        current++;
        if (current === arr.length) current = 0;
    }
    return dest;
}

function reverse(src: Array<any>) {
    const dest = [];
    for (let index = src.length - 1; index >= 0; index--) {
        dest.push(src[index]);
    }
    return dest;
}