import * as map from "lodash/map"
import { ExternalScriptToDivInjector } from "../../../../../global/components/externalScriptToDivInjector/externalScriptToDivInjector";
import { ImageDetails } from "images/interfaces"


export interface carouselItems {
    title: string
    publishedLink: string
    path?: string
    author: string
    id: string
    credit: string
    promotionImageDetails?: ImageDetails
}
interface carouselProps {
    items: carouselItems[]
    disableScript?: boolean
    marginRightPerComp: number
}
interface carouselState {
    marginRight: number
}

export class CalcalistCarouselComponenta extends React.Component<carouselProps, carouselState> {
    static siteScriptName = "CalcalistCarouselComponenta"
    constructor(props) {
        super(props)
        this.state = {
            marginRight: 0
        }
    }
    private scrolltoRight = () => {
        this.setState({ marginRight: 0 })
    }
    private scrolltoLeft = () => {
        const { marginRightPerComp } = this.props
        this.setState({ marginRight: marginRightPerComp })
    }
    public render() {
        const { items, disableScript } = this.props
        const { marginRight } = this.state;

        let firstItems = items !== undefined ? items.slice(0, 3) : []
        let secondItems = items !== undefined ? items.slice(3, items.length) : []

        return (
            <div className="ArticleCalcalistSlider">
                <div>
                    {marginRight !== 0 && <button className="arrowRight" onClick={this.scrolltoRight} />}
                    <div className="articlesList" style={{ marginRight: marginRight }}>
                        {map(firstItems, (item, i) => {
                            return (
                                <a className="articleItem" key={item.id} href={item.publishedLink} title={item.title}>
                                    <img src={item.path} className="SiteImageMedia" alt={item.title} title={item.credit} />
                                    <div className="textArea">
                                        <div className="title">
                                            {item.title}
                                        </div>
                                        {!disableScript && <div className="author">
                                            {item.author}
                                        </div>}
                                    </div>
                                </a>
                            )
                        })
                        }
                        {!disableScript && <div className="articleItem">
                                <div id="carusella.article" className="calcalist_banner"></div>
                        </div>}
                        {map(secondItems, (item, i) => {
                            if (item) {
                                return (
                                    <a className="articleItem" key={item.id} href={item.publishedLink} title={item.title}>
                                        <img src={item.path} className="SiteImageMedia" alt={item.title} title={item.credit} />
                                        <div className="textArea">
                                            <div className="title">
                                                {item.title}
                                            </div>
                                            {!disableScript && <div className="author">
                                                {item.author}
                                            </div>}
                                        </div>
                                    </a>
                                )
                            }
                        })
                        }
                    </div>
                    {marginRight === 0 && <button className="arrowLeft" onClick={this.scrolltoLeft} />}
                </div>
            </div>
        )
    }
}
// <SiteSimpleImage src={item.path} title={item.credit} alt={item.credit} />
