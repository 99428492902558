import { PageComponentaContext, SiteComponentaProps } from "widgets/interfaces";
import { SiteSettings, SiteSettingsHOC } from "global/components/siteSettingsHOC";
import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext";
import { NoData } from "widgets/commonDataObjects/noData";
import { RenderReactAtSite } from "../../../commonComponents/inline/renderReactAtSite";
import { HotTagsFrontComponenta } from "./hotTagsFront";
require("./ynet_site_hotTagsComponenta.less");
require("./vesty_ynetnews_site_hotTagsComponenta.less");

interface HotTagsProps extends Partial<PageComponentaContext>, SiteComponentaProps<NoData> { }

export const HotTagsDict = {
    "he": {
        hotTags: "תגיות חמות",
        today: "היום",
        week: "השבוע",
        month: "החודש",
    },
    "en": {
        hotTags: "Hot Tags",
        today: "Today",
        week: "This Week",
        month: "This Month",
    },
    "ru": {
        hotTags: "Популярные теги",
        today: "Сегодня",
        week: "Неделя",
        month: "Месяц",
    }
};

declare let window: Window & {
    languageCode: string
}

@injectPageComponentaContext()
@SiteSettingsHOC()
export class HotTagsComponenta extends React.Component<Partial<SiteSettings> & { WidgetType?: string } & HotTagsProps, {}> {
    render() {
        const { extraData, widgetType, languageCode } = this.props;
        const langcode = typeof window !== "undefined" && window && window.languageCode  || languageCode;
        const lang = langcode.slice(0,2) || "he";

        return (
            <div className="RelatedTags HotTags">
                <div className="componentaTab">
                    <span>{HotTagsDict[lang].hotTags}</span>
                </div>
                <RenderReactAtSite>
                    <HotTagsFrontComponenta
                     data={extraData}
                     widgetType={widgetType}
                     languageCode={langcode}/>
                </RenderReactAtSite>
            </div>
        )
    }
}

