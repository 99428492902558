import * as map from "lodash/map"
import { SiteTickerData } from "./tickerComponenta";
import { TickerItemSlotMynet } from "./tickerItemSlot"
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"


interface TickerListProps {
    siteFlashList: Partial<SiteTickerData>[]
    titleColor: string
    subTitleColor: string
    fieldsDisplaySettings: LinkedItemFieldDisplay
}

export class TickerList extends React.Component<TickerListProps, {}>{
    public static siteScriptName = "TickerListMynet"
    private getNumberofDuplicates = () => {
        const { siteFlashList } = this.props
        let number;
        if (siteFlashList.length > 0 && siteFlashList.length < 40) {
            number = Math.round(40 / siteFlashList.length)
        }
        else { number = 1 }

        return number

    }
    private replicateArray(array, n) {
        // Create an array of size "n" with undefined values
        // console.time("answer time");
        var arrays = Array.apply(null, new Array(n));

        // Replace each "undefined" with our array, resulting in an array of n copies of our array
        arrays = arrays.map(function () { return array });

        // Flatten our array of arrays
        const r = [].concat.apply([], arrays);
        return r;
    }
    public render() {
        const { siteFlashList, titleColor, subTitleColor, fieldsDisplaySettings } = this.props

        const arrayOfTitles = siteFlashList.length !== 0 && this.replicateArray(siteFlashList, this.getNumberofDuplicates()).map((flashItem => flashItem.title))
        const n = arrayOfTitles && arrayOfTitles.reduce((a, b) => a + b)
        const duration = n ? n.length * 0.18 : 0
        if (!siteFlashList || siteFlashList.length == 0) {
            return null
        }
        return (
            <div className="ticker-wrap"  >
                <div className="animationDiv" style={{ animationDuration: `${duration}s, ${duration}s`, animationDelay: `${0}s, ${duration}s` }}>
                    {this.replicateArray(siteFlashList, this.getNumberofDuplicates()).map((flashItem, i) => <TickerItemSlotMynet
                        title={flashItem.title}
                        flashDate={flashItem.flashDate}
                        linkTarget={flashItem.linkTarget}
                        link={flashItem.link}
                        key={i}
                        titleColor={titleColor}
                        subTitleColor={subTitleColor}
                        flashId={flashItem.slotId}
                        flashLinkType={flashItem.flashLinkType}
                        fieldsDisplaySettings={fieldsDisplaySettings}

                    />)}
                </div>

            </div>
        )
    }
}