import { SiteTickerData } from "../../../ticker/components/tickerComponenta";
import { RowTickerItemSlot } from "./rowTickerItemSlot"
import * as map from "lodash/map"
import Slider from "react-slick"
import { isWcmEditor } from "config/serverConfig"
import * as classNames from "classnames"


interface RowTickerListProps {
    siteFlashList: Partial<SiteTickerData>[]
    titleColor: string
    subTitleColor: string
    isAuto: boolean
    tabHref: string
    seconds: number
    firstArticleId: string
    isLTR: boolean
    hasArticleLink: boolean
}

interface ComponentState {
    isHovered: boolean
}


export class RowTickerList extends React.Component<RowTickerListProps, ComponentState>{
    constructor(props) {
        super(props)
        this.state = {
            isHovered: false
        }
    }



    private handleItemHoverIn = () => {
        this.setState({ isHovered: true })
    }

    private handleItemHoverOut = () => {
        this.setState({ isHovered: false })
    }

    public render() {
        let { siteFlashList, titleColor, subTitleColor, isAuto, tabHref, seconds, firstArticleId, isLTR, hasArticleLink } = this.props

        const initialSlide = 0// 9 - siteFlashList.length;

        let wrappedImageClassNames = isWcmEditor() ? "animationDiv isEditor" : "animationDiv tickerAnimation";

        let settings = {
            draggable: false,
            infinite: true,
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: seconds * 1000,
            pauseOnHover: true,
            arrows: false,
            rtl: !isLTR,
            centerMode: false,
            initialSlide: initialSlide
        }

        if (!siteFlashList || siteFlashList.length == 0) {
            return null
        }

        return (
            <div className={classNames("slotListRow", { "hovered": this.state.isHovered })}  >
                <Slider {...settings} className={wrappedImageClassNames}>
                    {map(siteFlashList, (flashItem, i) => <RowTickerItemSlot
                        articlePublishDate={flashItem.articlePublishDate}
                        articleUpdateDate={flashItem.articleUpdateDate}
                        title={flashItem.title}
                        author={flashItem.author}
                        flashDate={flashItem.flashDate}
                        flashDateType={flashItem.flashDateType}
                        linkTarget={flashItem.linkTarget}
                        link={flashItem.link}
                        key={i}
                        titleColor={titleColor}
                        subTitleColor={subTitleColor}
                        flashId={flashItem.slotId}
                        flashLinkType={flashItem.flashLinkType}
                        isAuto={isAuto}
                        articleId={flashItem.articleId}
                        flashText={flashItem.flashText}
                        publishedLink={flashItem.publishedLink}
                        tabHref={tabHref}
                        isFirstItem={firstArticleId === flashItem.articleId}
                        onHoverIn={this.handleItemHoverIn}
                        onHoverOut={this.handleItemHoverOut}
                        hasArticleLink={hasArticleLink}
                    />)}
                </Slider>
            </div>
        )
    }
}