import * as classNames from "classnames"
import {getTranslation} from "../../redMailTranslations";


interface TextAreaYnetnewsProps {
    value: string
    onChange: (value: any) => void
    isTextAreaBlank: boolean
    textAreaData: any
    isChecked: boolean
    lang: string
}
export class TextAreaYnetnews extends React.Component<TextAreaYnetnewsProps, State> {

    render() {
        const { value, onChange, isTextAreaBlank, textAreaData, isChecked, lang } = this.props
        return (
            <div id="RedMailTextAreaElement" className="RedMailTextArea">
                <div className="redMailTextAreaTop">
                    <p className="TextAreaHeadline">{textAreaData.textAreaHeadline}</p>
                    {isChecked && 
                        <div className="shareButtonsWrapper">
                            <a href="https://wa.me/972548243550" className="whatsappButton">
                                <img/>
                                Whatsapp
                            </a>
                            <a href="https://t.me/ynetTbot" className="telegramButton">
                                <img/>
                                Telegram
                            </a>
                        </div>
                    }
                </div>
                <div className="textAreaWrapper">
                    <textarea dir="ltr" placeholder={textAreaData.placeholder} value={value} onChange={(e) => onChange(e)} required className={classNames("textAre", { "placeholderErrorStyle": isTextAreaBlank })}></textarea>
                    <div>{getTranslation(lang, "share your info here")}</div>
                </div>
            </div>
        )
    }
}