import * as classNames from "classnames";
import * as map from "lodash/map";
import { calculateAutomaticComponentaUrl } from "widgets/widgetsUtils";
import { HotTagsDict } from "./hotTagsComponenta";


enum HotTabsEnum {
    Today,
    Weekly,
    Month,
}

interface HotTagsComponentaProps {
    data: any,
    widgetType: string
    languageCode: string
}

interface HotTagsComponentaState {
    selectedTab: HotTabsEnum
}

const hotTabsObj = {
    0: "last_day_data", 
    1: "last_week_data", 
    2: "last_month_data", 
}

export class HotTagsFrontComponenta extends React.Component<HotTagsComponentaProps, HotTagsComponentaState>{
    static siteScriptName = "HotTagsFrontComponenta"
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: HotTabsEnum.Today
        }
    }
    private onTodayTabClicked = (e) => {
        this.setSelectedTab(HotTabsEnum.Today)
    }
    private onWeeklyTabClicked = (e) => {
        this.setSelectedTab(HotTabsEnum.Weekly)
    }
    private onMonthTabClicked = (e) => {
        this.setSelectedTab(HotTabsEnum.Month)
    }
    private setSelectedTab = (tabId: number) => {
        this.setState({ selectedTab: tabId })
    }
    public render() {
        const { selectedTab } = this.state
        const { data, widgetType, languageCode } = this.props
        const todayTabClassNames = classNames("tab", { "active": selectedTab === HotTabsEnum.Today })
        const weeklyTabClassNames = classNames("tab", { "active": selectedTab === HotTabsEnum.Weekly })
        const monthTabClassNames = classNames("tab", { "active": selectedTab === HotTabsEnum.Month })
        const selectedSlotsContent = widgetType === "hot-tags-1280" ? classNames("slotsContent", { "active": selectedTab === HotTabsEnum.Today }, { "active": selectedTab === HotTabsEnum.Weekly }, { "active": selectedTab === HotTabsEnum.Month }) : "slotsContent";
        const lang = languageCode.slice(0, 2) || "he";
        const listData = data[hotTabsObj[selectedTab]];

        const list = map(listData, (item: any, index) => {
            return <a className="slotItem" key={index} href={calculateAutomaticComponentaUrl(item)} >{item.title}</a>
        })

        return (
            <div className="HotTagsContent">
                <div className="tabsWrapper">
                    <div className={todayTabClassNames} onClick={this.onTodayTabClicked}>{HotTagsDict[lang].today}</div>
                    <div className={weeklyTabClassNames} onClick={this.onWeeklyTabClicked}>{HotTagsDict[lang].week}</div>
                    <div className={monthTabClassNames} onClick={this.onMonthTabClicked}>{HotTagsDict[lang].month}</div>
                </div>

                <div className={selectedSlotsContent}>
                    <div className="contentBox"> {list}</div>

                </div>
            </div>
        )
    }

}
/**
 * let data = {
            "last_day_data": [{ publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "יקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "ברזיל" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "ביבי נתניהו" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הדמוקרטית" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הרפובליקה הדמוקרטית של קונגו" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "סמים" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הרפובליקה  של קונגו" }],
            "last_week_data": [{ publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "lkjh" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "ברזhgfל" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "ביבי נתניהו" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הדמוקרטית" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הרפובליקה  של קונגו" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "סמים" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "  של קונגו" }],
            "last_month_data": [{ publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "אמריקה" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "lkjh" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "ברזhgfל" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "ביבי נתניהו" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הדמוקרטית" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "הרפובליקה  של קונגו" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "סמים" },
            { publishedLink: "https://dev-v2.ynet.co.il/news", title: "  של קונגו" }]
        }
 */
