import { VideoSettingsData, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SiteItemData } from "widgets/interfaces"
import { ArticleData } from "articlePage/stateInterfaces"
import { MultiImagesItemFrontMobile } from "./frontItemMobile";

interface MultiImagesFrontProps {
    itemList: SiteItemData[]
    width: number
    fieldsDisplaySettings: IFieldDisplayData
    videoSettings: VideoSettingsData
    imageWidth: number
    imageHeight: number
    titleColor: string
    displayType: string
    isImageOnly?: boolean
    getArticle(articleId: string): ArticleData
}

export class MultiImagesRadionasFrontMobile extends React.Component<MultiImagesFrontProps>{
    public static siteScriptName = "MultiImagesRadionasFrontMobile";
    
    public render() {
        const { imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings, titleColor, getArticle, displayType, isImageOnly } = this.props
        const className = `slotList ${isImageOnly ? "imageOnly" : ""}`;

        return (
            <div className="slotsContent">
                <div className={className}>
                    {itemList.map((slot, index) => {
                        return (
                            <MultiImagesItemFrontMobile
                                imageWidth={imageWidth}
                                index={index}
                                imageHeight={imageHeight}
                                videoSettings={videoSettings}
                                fieldsDisplaySettings={fieldsDisplaySettings}
                                item={slot}
                                titleColor={titleColor}
                                isMutamView={true}
                                getArticle={getArticle}
                                displayType={displayType}
                                isImageOnly={isImageOnly}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}