import { DefaultPlaceholderImage } from "../images/interfaces"
let _isServerRendering = false
declare var window: Window & {
    dateFormat: string
    timezone: string
}
export const isWcmEditor = () => IS_WCM_EDITOR
export const isServerRendering = () => RUNNING_IN_SERVER ? true : _isServerRendering;

export const setServerRendering = () => _isServerRendering = true;
export const unsetServerRendering = () => _isServerRendering = false;


let cls = ALLOW_SERVER_PREVIEW && require('continuation-local-storage');

export function checkIfInServerPreviewMode() {
    return cls && cls.getNamespace("ynet.cls").get("isPreview");
}

export function getLocaleDateFormat(): string {
    return (cls && cls.getNamespace("ynet.cls").get("dateFormat")) || (typeof window !== 'undefined' && window.dateFormat) || "dmy";
}
export function getTimezone(): string {
    return (cls && cls.getNamespace("ynet.cls").get("timezone")) || (typeof window !== 'undefined' && window.timezone) || "Asia/Jerusalem";
}

export function getDefaultplaceholderImage(): DefaultPlaceholderImage {
    return (cls && cls.getNamespace("ynet.cls").get("defaultPlaceholderImage")) || {};
}
