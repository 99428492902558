import { dateData } from "../interfaces"
declare var window: Window & {
    pageRefreshDisable(sourceType?: string): void;
    pageRefreshEnable(sourceType?: string): void;
}

export function disablePageRefresh(sourceType?: string) {
    window && window.pageRefreshDisable && window.pageRefreshDisable(sourceType);
}

export function enablePageRefresh(sourceType?: string) {
    window && window.pageRefreshEnable && window.pageRefreshEnable(sourceType);
}

export function isPageNotReloaded() {
    const isNewMethodExist = window.performance && window.performance.getEntriesByType("navigation").length > 0;
    const isOldMethodExist = window.performance && performance.navigation;
    if (isNewMethodExist) {
        // @ts-ignore
        return window.performance.getEntriesByType("navigation")[0].type !== "reload";
    }
    if (isOldMethodExist) {
        return performance.navigation.type !== performance.navigation.TYPE_RELOAD;
    }
    return true
}

export function hebrewTimeAgoTranslate(number: number, index: number) {
    return [
        ['זה עתה', 'עכשיו'],
        ['לפני %s שניות', 'בעוד %s שניות'],
        ['לפני ד׳', 'בעוד ד׳'],
        ["לפני %s ד׳", "בעוד %s ד׳"],
        ['לפני %s ש׳', 'לפני ש׳'],
        number === 2 ? ['לפני 2 ש׳', 'בעוד שעתיים'] : ['לפני %s ש׳', 'בעוד %s ש׳'],
        ['אתמול', 'מחר'],
        number === 2 ? ['לפני יומיים', 'בעוד יומיים'] : ['לפני %s ימים', 'בעוד %s ימים'],
        ['לפני שבוע', 'בעוד שבוע'],
        number === 2 ? ['לפני שבועיים', 'בעוד שבועיים'] : ['לפני %s שבועות', 'בעוד %s שבועות'],
        ['לפני חודש', 'בעוד חודש'],
        number === 2 ? ['לפני חודשיים', 'בעוד חודשיים'] : ['לפני %s חודשים', 'בעוד %s חודשים'],
        ['לפני שנה', 'בעוד שנה'],
        number === 2 ? ['לפני שנתיים', 'בעוד שנתיים'] : ['לפני %s שנים', 'בעוד %s שנים'],
    ][index] as [string, string];
}

function formatNum(f1: string, f: string, s: string, t: string, n: number): string {
    const n10 = n % 10;
    let str = t;

    if (n === 1) {
        str = f1;
    } else if (n10 === 1 && n > 20) {
        str = f;
    } else if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
        str = s;
    }

    return str;
}

const seconds = formatNum.bind(null, 'секунду', '%s секунду', '%s секунды', '%s секунд'),
    minutes = formatNum.bind(null, 'минуту', '%s минуту', '%s минуты', '%s минут'),
    hours = formatNum.bind(null, 'час', '%s час', '%s часа', '%s часов'),
    days = formatNum.bind(null, 'день', '%s день', '%s дня', '%s дней'),
    weeks = formatNum.bind(null, 'неделю', '%s неделю', '%s недели', '%s недель'),
    months = formatNum.bind(null, 'месяц', '%s месяц', '%s месяца', '%s месяцев'),
    years = formatNum.bind(null, 'год', '%s год', '%s года', '%s лет');

export function russianTimeAgoTranslate(number: number, index: number): [string, string] {
    switch (index) {
        case 0:
            return ['только что', 'через несколько секунд'];
        case 1:
            return [seconds(number) + ' назад', 'через ' + seconds(number)];
        case 2: // ['минуту назад', 'через минуту'];
        case 3:
            return [minutes(number) + ' назад', 'через ' + minutes(number)];
        case 4: // ['час назад', 'через час'];
        case 5:
            return [hours(number) + ' назад', 'через ' + hours(number)];
        case 6:
            return ['вчера', 'завтра'];
        case 7:
            return [days(number) + ' назад', 'через ' + days(number)];
        case 8: // ['неделю назад', 'через неделю'];
        case 9:
            return [weeks(number) + ' назад', 'через ' + weeks(number)];
        case 10: // ['месяц назад', 'через месяц'];
        case 11:
            return [months(number) + ' назад', 'через ' + months(number)];
        case 12: // ['год назад', 'через год'];
        case 13:
            return [years(number) + ' назад', 'через ' + years(number)];
        default:
            return ['', ''];
    }
}

export function executeScriptsFromHtml(html) {
    const scriptsFromHtml = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    let matchedScripts;
    while (matchedScripts = scriptsFromHtml.exec(html)) {
        try {
            eval(matchedScripts[1])
        } catch (ex) {
            console.error(`error in blog script: ${matchedScripts[1]}:`, ex);
        }
    }
}

export const secondsToHms = (numInSec) => {
    numInSec = parseInt(numInSec);
    let h = Math.floor(numInSec / 3600);
    let m = Math.floor(numInSec % 3600 / 60);
    let s = Math.floor(numInSec % 3600 % 60);

    let hDisplay = h > 0 ? h < 10 ? "0" + h + ":" : h.toString() + ":" : "";
    let mDisplay = m > 0 ? m < 10 ? "0" + m + ":" : m.toString() + ":" : "00:";
    let sDisplay = s > 0 ? s < 10 ? "0" + s : s.toString() : "00";
    return hDisplay + mDisplay + sDisplay;
}

export const getCookie = (name: string) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}

export const testAdblock = (cb: any) => {
    const url = "https://ads.google.com";
    fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(cb(false)).catch(() => { 
        cb(true)
    });
}

type dateFormat =  'YYYY-MM-DD' | 'DD/MM/YYYY' | 'DD-MM-YYYY' | 'DD.MM.YYYY';

export const formatDate=(date:Date,format:dateFormat)=>{
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    switch(format){
        case "YYYY-MM-DD":{
            return  `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;;
        }
        case "DD/MM/YYYY":{
            return  `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
        }
        case "DD-MM-YYYY":{
            return  `${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}-${year}`;
        }
        case "DD.MM.YYYY":{
            return  `${String(day).padStart(2, '0')}.${String(month).padStart(2, '0')}.${year}`;
        }
    }
}

