import * as classNames from "classnames"
import * as map from "lodash/map"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"

export interface SiteList {
    name: string
    link: string
    linkTarget: LinkTargetProperties
    url?: string
    subSite?: string
    mainSiteSubNav: SubNavItem[]
}
export interface SubNavItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
}
interface CitiesProps {
    mainSite: SiteList
    siteList: SiteList[]
    onClose: () => void
}

export class CitiesMenuComponenta extends React.Component<CitiesProps, {}> {

    render() {
        const { mainSite, siteList, onClose } = this.props

        return (
            <div className="MobileCitiesMenu">
                <div className="mainHeader">
                    <button className="backBtnArrow btn" title="חזרה" onClick={onClose} >&#10095;</button>
                    <div className="logo">
                        <HyperLinkBuilder href={mainSite.link} ariaLabel={mainSite.name} linkTarget={mainSite.linkTarget}>
                            <SiteSimpleImage src={mainSite.url} alt={mainSite.name} title={mainSite.name} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>
                    </div>
                </div>
                <div className="navTitle">עבור עיר</div>
                <div className="CitiesList" >

                    {map(siteList, (item, i) => {
                        return (
                            <div key={i} className={classNames("menuItem", { "selected": item.name === mainSite.name })}>
                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                    <div dangerouslySetInnerHTML={{ __html: item.name }} />
                                </HyperLinkBuilder>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        )

    }
}