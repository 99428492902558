require("./site_toolsAndAbout.less")
import { SectionLabelWithUnderlineDecoration } from "../sectionLabelWithUnderlineDecoration/sectionLabelWithUnderlineDecoration"
import { AboutLinks } from "../aboutLinks/aboutLinks"
import { ToolsLinks } from "../toolsLinks/toolsLinks"
import { SocialLinks } from "../socialLinks/socialLinks"

export const ToolsAndAbout = () => {
    return (
        <div className="ToolsAndAbout">
            <div className="wrapper">
                <div className="aboutWrapper">
                    <SectionLabelWithUnderlineDecoration label={"אודות ועזרה"} />
                    <AboutLinks />
                </div>
                <div className="toolsWrapper">
                    <div className="sectionTitleWrapper">
                        <SectionLabelWithUnderlineDecoration label={"כלים ושירותים"} />
                        <div className="socialNetworks"><SocialLinks /></div>
                    </div>
                    <ToolsLinks />
                </div>
            </div>
        </div>
    )
}