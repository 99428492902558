export function AddAnalytics(api: any, videoId: string, wcmPopularityLink: string) {
    addStartEvent(api, videoId, wcmPopularityLink)
}

function addStartEvent(api: any, videoId: string, wcmPopularityLink: string) {
    api.on("cuepoint", (e, api, cuepoint) => {
        if (cuepoint.startEvent) {
            // window.dataLayer = window.dataLayer || []
            // window.dataLayer.push({
            //     event: "video_start",
            //     related_url: `${window.video_ad_description_url}&dcPath=${window.dcPath}`,
            //     video_url: `${api.video.src}&source=${window.video_analytics_src}`
            // });
            sendPopularityEvent(videoId, api.video.duration, wcmPopularityLink);
        }
    })
}

export function sendPopularityEvent(videoId: string, videoTime: number, wcmPopularityLink: string) {
    let typeNum = "1";
    typeNum = window.isMobile1 ? "2" : "1";
    if (wcmPopularityLink) {
        const url = `${wcmPopularityLink}?content=${videoId}&type=${typeNum}&time=${videoTime}&random=${Math.floor(Math.random() * 999999)}`
        new Image().src = url;
    }
}

export function sendVideoStikyShow(videoSrc: string) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: "GA_Event_zynet",
        Category: "Video - Mini Player",
        Action: "Show",
        Label: `${window.location.href}&video=${videoSrc}`
    });
}

export function sendVideoStikyClose(videoSrc: string) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: "GA_Event_zynet",
        Category: "Video - Mini Player",
        Action: "Closed",
        Label: `${window.location.href}&video=${videoSrc}`
    });
}
