import { PageComponenta } from "widgets/commonComponents/base/pageComponenta"
require('./calcalist_site_footerComponenta.less')


export class CalcalistFooter extends React.Component<{}, {}>{
    public static siteScriptName = "CalcalistFooter";

    
    public render() {

        return (
            <div className="CalcalistFooter">
                <div className="calcalistTopBg" />
                <div className="calcalistFooterTop">

                    <div id="f_logo">
                        <a title="כלכליסט"
                            href="//www.calcalist.co.il/home/0,7340,L-8,00.html">
                            <img src="//www.calcalist.co.il/images/1280/footer/f_logo.png" alt="לוגו כלכליסט" />
                        </a>
                    </div>
                    <ul className="rightLinksRow">
                        <li><a className="shahor" title="כלכליסט מפת האתר" href="/home/0,7340,L-3839,00.html">כלכליסט מפת האתר</a></li>
                        <li><a title="כתבו לנו" className="shahor" href="/home/0,7340,L-3800,00.html">כתבו לנו</a></li>
                        <li><a title="המערכת" className="shahor" href="/home/0,7340,L-3802,00.html">המערכת</a></li>
                        <li><a title="פודקאסט" className="shahor" target="_blank" href="//www.calcalist.co.il/podcast">פודקאסט</a></li>
                        <li><a title="פוטו כלכליסט" className="shahor" href="//www.calcalist.co.il/photo">פוטו כלכליסט</a></li>

                        <li><a title="אודות כלכליסט" className="shahor" href="/home/0,7340,L-3854,00.html">אודות כלכליסט</a></li>
                        <li><a title="המייל האדום" className="shahor redMail" href="https://www.calcalist.co.il/redmail" >המייל האדום</a></li>
                        <li><a title="ההנהלה" className="shahor" href="/home/0,7340,L-3801,00.html">ההנהלה</a></li>
                        <li><a className="shahor" title="תנאי שימוש" href="/home/0,7340,L-3856,00.html">תנאי שימוש</a></li>
                        <li><a title="המרת מט&quot;ח" className="shahor" target="_blank" href="//www.hamara.co.il/">המרת מט"ח</a></li>

                        <li><a title="ועידות כלכליסט" className="shahor" href="/conference/home/0,7340,L-5144,00.html">ועידות כלכליסט</a></li>
                        <li><a title="מדיניות פרטיות" className="shahor" href="/home/0,7340,L-3855,00.html">מדיניות פרטיות</a></li>
                        <li><a className="shahor" title="פרסמו אצלנו" href="/home/0,7340,L-3799,00.html">פרסמו אצלנו</a></li>
                        <li><a title="הפוך לדף הבית" className="shahor setHomePage" href="/">הפוך לדף הבית</a></li>
                        <li><a title="עמוד מט&quot;ח כללי" className="shahor" href="/stocks/home/0,7340,L-4135,00.html">עמוד מט"ח כללי</a></li>

                        <li><a title="מוסף כלכליסט" className="shahor" href="//www.calcalist.co.il/supplement">מוסף כלכליסט</a></li>
                        <li><a title="כלכליסט TV" className="shahor" target="_blank" href="//www.calcalist.co.il/tv">כלכליסט TV</a></li>

                        <li><a title="קורסים אונליין" className="shahor" href="https://study.calcalist.co.il/">קורסים אונליין</a></li>
                        <li><a title="קריקטוריסט" className="shahor" href="/home/0,7340,L-3837,00.html	">קריקטוריסט</a></li>
                    
                    </ul>


                    <div className="leftLinksWrp">
                        <ul className="leftLinksRow">
                            <li>
                                <a title="שרות לקוחות" href="//www.calcalist.co.il/home/0,7340,L-3800,00.html">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/header/t-service.png" alt="שרות לקוחות" />
                                    <p className="adom  ShualBold" id="p_alert">שרות לקוחות</p>
                                </a>
                            </li>
                            <li>
                                <a title="פייסבוק" href="//www.facebook.com/calcalistonline" target="_blank">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/f_fb.png" alt="פייסבוק" />
                                    <p className="shahor ShualBold">פייסבוק</p>
                                </a>
                            </li>
                        </ul>
                        <ul className="leftLinksRow">
                            <li>
                                <a title="RSS" href="/home/0,7340,L-3695,00.html">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/f_rss.png" alt="RSS" />
                                    <p className="tzahov ShualBold" id="p_rss">RSS</p>
                                </a>
                            </li>
                            <li>
                                <a title="טוויטר" href="//twitter.com/calcalist" target="_blank">
                                    <span className="f_icon twitter"  alt="טוויטר" />
                                    <p className="shahor ShualBold">טוויטר</p>
                                </a>
                            </li>
                        </ul>
                        <ul className="leftLinksRow">
                        <li>
                                <a title="מינוי לעיתון"
                                    href="//z.calcalist.co.il/mvc/short/2018/Dreamcard/Home/Index" target="_blank">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/f_sub.png" alt="מינוי לעיתון" />
                                    <p className="shahor ShualBold">מינוי לעיתון</p>
                                </a>
                            </li>
                            <li>
                                <a title="כלכליסט TV" href="//www.calcalist.co.il/tv">
                                <span className="f_icon tv"  alt="טוויטר" />
                                    <p className="adom ShualBold" id="p_personal">כלכליסט TV</p>
                                </a>
                            </li>
                       
                        </ul>
                        <ul className="leftLinksRow">
                            <li>
                                <a title="ניוזלטר" href="/home/0,7340,L-3857,00.html">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/f_newsletter.png" alt="ניוזלטר" />
                                    <p className="yarok ShualBold" id="p_newsletter">ניוזלטר</p>
                                </a>
                            </li>
                            <li>
                                <a title="פודקאסט" href="//www.calcalist.co.il/podcast" className="SiteNagish" target="_blank">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/podcast.png" alt="פודקאסט" />
                                    <p className="shahor ShualBold">פודקאסט</p>
                                </a>
                            </li>
                        </ul>
                        <ul className="leftLinksRow">
                            <li>
                                <a title="דואר אדום" className="redMail" href="https://www.calcalist.co.il/redmail">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/f_mail.png" alt="דואר אדום" />
                                    <p className="adom ShualBold" id="p_mail">דואר אדום</p>
                                </a>
                            </li>
                            <li>
                                <a title="כלכליסט ביוטיוב" href="//www.youtube.com/calcalist" target="_blank">
                                    <img className="f_icon" src="//www.calcalist.co.il/images/1280/footer/f_youtube.png" alt="כלכליסט ביוטיוב" />
                                    <p className="shahor ShualBold">כלכליסט ביוטיוב</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="calcalistFooterBottom">
                    <a href="//www.yit.co.il/" target="_blank" rel="nofollow">
                        <span className="developed-yit shahor">Developed by</span>
                        <span className="developed-yit-logo"><img src="//www.calcalist.co.il/images/1280/footer/YIT.png" alt="YIT" /></span>
                    </a>
                    <a href="http://www.basch.co.il/" target="_blank" rel="nofollow">
                        <span className="basch shahor">UI &amp; UX by <span className="basch-bold shahor">Basch_Interactive</span></span>
                    </a>
                </div>
            </div>

        )
    }
}
