require("./ynet_pplus_site_messageToAuthor.less")
import * as axios from "axios"
import * as classNames from "classnames"
const clickOutside = require('react-click-outside');
declare var grecaptcha: any
declare var window: any

interface MessageToAuthorProps {
    articleId: string
    onClose: () => void
    isApp?: boolean
}
interface MessageToAuthorState {
    phone: string
    email: string
    name: string
    content: string
    isError: boolean
    isSend: boolean
}
interface itemToSend {
    articleId: string
    phone: string
    email: string
    name: string
    content: string
}

@clickOutside
export class MessageToAuthor extends React.Component<MessageToAuthorProps, MessageToAuthorState>{
    constructor(props) {
        super(props)
        this.state = {
            phone: "",
            email: "",
            name: "",
            content: "",
            isError: false,
            isSend: false
        }
    }
    private handleClickOutside = () => {
        this.props.onClose()
    }
    private finalClassnames = (classname) => {
        const name = this.props.isApp ? classname + "-pf" : classname
        return name
    }
    private onHandleSend = () => {
        const { articleId } = this.props
        const { phone, email, name, content } = this.state
        const isTest = window.location.search.includes("is_test=1");
        if (content.length > 0) {
            let postData = {
                articleId: articleId,
                name: name,
                phone: phone,
                email: email,
                content: content
            }

            if (window.wcmCaptchaSiteKey && grecaptcha) {
                grecaptcha.ready(() => {
                    grecaptcha.execute(window.wcmCaptchaSiteKey, { action: 'submit' }).then(token => {
                        postData = { ...postData, token: token }
                    })
                });
            }
            setTimeout(() => {
                axios.post(`/api/contact_author${isTest ? "?is_test=1" : "/"}`, postData)
                this.setState({ isSend: true })
            }, 3000);

        }
        else {
            this.setState({ isError: true })
        }
    }

    render() {
        const { phone, email, name, content, isError, isSend } = this.state
        const { onClose, isApp } = this.props
        if (isSend) return (
            <div className={this.finalClassnames("MessageToAuthor")}>
                <div>
                    <div className={this.finalClassnames("successMsg")}>ההודעה נשלחה בהצלחה!</div>
                    <div className={this.finalClassnames("infoArea")}>
                        <button className={this.finalClassnames("closeWindow")} onClick={onClose} >{isApp ? "סגירה" : ""}</button>
                    </div>
                </div>
            </div>
        )
        else return (
            <div className={this.finalClassnames("MessageToAuthor")}>
                <div className="content-pf">
                    {isApp && <div className="msgTitle-pf">תוכן הפנייה</div>}
                    <textarea value={content} className={classNames(this.finalClassnames("input"), { "error": isError })} placeholder={isApp ? "נשמח לשמוע ממך" : "מה רצית לכתוב?"} onChange={(e) => this.setState({ content: (e.target as HTMLInputElement).value, isError: false })} />
                    <div className={this.finalClassnames("infoArea")}>
                        <button className={this.finalClassnames("closeWindow")} onClick={onClose} >{isApp ? "סגירה" : ""}</button>
                        <div className={this.finalClassnames("title")}>פרטים אישיים</div>
                        <input className={this.finalClassnames("input")} value={name} placeholder="שם" onChange={(e) => this.setState({ name: (e.target as HTMLInputElement).value })} />
                        <input className={this.finalClassnames("input")} value={phone} placeholder="טלפון" onChange={(e) => this.setState({ phone: (e.target as HTMLInputElement).value })} />
                        <input className={this.finalClassnames("input")} value={email} placeholder="דוא''ל" onChange={(e) => this.setState({ email: (e.target as HTMLInputElement).value })} />
                        <button className={this.finalClassnames("sendToAuthor")} onClick={this.onHandleSend} >לשליחה</button>
                </div>
                </div>
            </div>
        )
    }
}
