
import { calculateAutomaticComponentaUrl, separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu";
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { CtechDateDisplay } from "../../../../commonComponents/inline/dateDisplay/ctechDateDisplay"
import { getDiffFromToday } from "../../../../siteWidgets/formatDateDisplay";



interface CtechArticleItemProps {
    article: Partial<SiteArticleData>
    index: number
    isLTR?: boolean
}
export interface SiteArticleData {
    'article_id': string
    'author': string
    'caption': string
    'title': string
    'sub_title': string
    'path': string
    'hasVideo': boolean
    'publishedLink': string
    'launch_date': Date
}

export class CtechArticleItem extends React.Component<CtechArticleItemProps, {}>{
    static defaultProps = { isCategoryVisible: true }

    private getIcon = (hasVideo: boolean) => {
        if (hasVideo) return "video"
        else return "none"
    }
    private viewDate = (): boolean => {
        const { article } = this.props
        const dateToManipulateTemp = new Date(article.launch_date)
        const diffFromToday = getDiffFromToday(dateToManipulateTemp)
        return diffFromToday > 0
    }

    public render() {
        const { article, index, isLTR } = this.props;
        const { article_id, author, caption, title, sub_title, path, publishedLink, hasVideo, launch_date } = article
        const textStyle = (!this.viewDate() && !isLTR) ? { color: '#C82027' } : {}
        return (
            <div className="slotItem" data-wcm-article-id={article_id}>
                <div className="articleIndex">{index + 1}<span className="mobileDot">.</span></div>
                <div className="mediaArea CalcalistComponentaShareMenuMedia">
                    <div className="MediaCarousel" >
                        <a href={publishedLink}>
                            <img src={path}
                                alt={caption}
                                title={caption}
                                style={{ width: 270, height: 146 }}
                                className="SiteImageMedia"
                            />
                        </a>
                    </div>
                    <SlotIconDisplay itemId={""} icon={this.getIcon(hasVideo)} iconClassName="medium" isIconVisible={hasVideo} />
                    <CalcalistShareMenu url={publishedLink} title={this.props.article.title} isLTR={true} isArticle={true} />
                </div>
                <a href={calculateAutomaticComponentaUrl(article)} className="textDiv" >
                    <div className="slotTitle">
                        <span>{title}</span>
                    </div>
                    <div className="moreDetails">
                        <span className="dateView" style={textStyle}>
                            {isLTR ? <CtechDateDisplay date={launch_date} /> :
                                separateHourFromDateAndFormatToString(launch_date,
                                    {
                                        showDate: this.viewDate(),
                                        showHour: !this.viewDate()
                                    })}
                        </span>
                        {author && <span className="separator">|</span>}
                        {author && <span className="author">{author} </span>}
                    </div>
                    <div className="slotSubTitle">
                        <span>{sub_title}</span>
                    </div>
                </a>
            </div>
        )
    }
}