import * as has from "lodash/has"

interface RedMailFileUploadProps {
    handleSelectedFile: (e: any) => void
    selectedFiles: any
    hashes: string[]
    invalidFilesName: string[]
    showFileStatusProgress: (fileKey: any) => string
}

export class RedMailFileUploadCalcalist extends React.Component<RedMailFileUploadProps, {}> {

    render() {
        const { handleSelectedFile, selectedFiles, invalidFilesName, hashes, showFileStatusProgress } = this.props;
        return (
            <div className="RedMailFilesUpload">
                <div style={{ display: Object.keys(selectedFiles).length > 0 && "none" }} className="FilesUploadButtonWrapper">

                    <input type="file" id="inputFile"
                        onChange={handleSelectedFile}
                        multiple
                    />
                    <button className="FilesUploadButton">הוספת קובץ
                        <div className="uploadIcon"></div>
                    </button>
                </div>
                {hashes.length === 0 && <span className="fileDescription">  תמונה, וידאו, מסמך עד 200 מגה</span>}
                {Object.keys(selectedFiles).length > 0 && <div className="fileWrapper" style={{ width: '100%' }}>
                    {Object.keys(selectedFiles).map(fileKey =>
                        <div className="fileName" key={fileKey}>
                            <input type="text" className="FileNameText" value={selectedFiles[fileKey].fileName} />
                            <div className="FileNameIconsWrapper">
                                <span className="FileNameUploadProgress">{showFileStatusProgress(fileKey)}</span>
                                {has(selectedFiles[fileKey], "progress") && <div className="FileNameLoadingIcon"></div>}
                                <div className="FileNameUploadIcon"></div>
                            </div>
                        </div>)}
                </div>}
                {Object.keys(selectedFiles).length > 0 && Object.keys(selectedFiles).length < 5 && 
                   <div className="addFile" onClick={() => document.getElementById("inputFile").click()}>
                       <div className="addFileIcon"></div>
                         הוספת קובץ נוסף (תמונה, וידאו, מסמך)
                   </div>}
                {!!invalidFilesName.length && <div className="files-names-error-row">
                    {invalidFilesName.map(file => <span key={file}> הקובץ {`${file}`}  כבד מדיי – העלה קובץ עד 200 מגה בלבד </span>)}
                </div>}
            </div>
        )
    }
}
