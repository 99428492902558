export const isYoutubeVideo = (youtube_id: string, low_res_link: string) => {
    return !low_res_link && youtube_id
}

export const attachYoutubeAnalytics = (component: any, videoId) => {
    const player = component.player;
    const container = component.youtubePlayerContainerAnchor;
    const analyticsLabel = document.URL + '&dcPath=' + window.dcPath + '&video=https://www.youtube.com/watch?v=' + videoId;

    let progressTimer;

    player.addEventListener("onStateChange", onPlayerStateChange)
    gaPushEvent(container.id, 'Load');

    const visibilityPercentage = getVisibilityPercentage()

    if (visibilityPercentage >= 50) {
        gaPushEvent(container.id, 'In-View');
    }

    function onPlayerStateChange(event) {
        let isPlay = 1 === event.data;
        let isPause = 2 === event.data;
        let isEnd = 0 === event.data;

        if (isPlay) {
            gaPushEvent(container.id, 'Play');
            progressTimer = setInterval(() => {

                ReportProgress();
            }, 1000);

            sendPopularityEvent(videoId, player.getDuration());
        }

        if (isPause) {
            gaPushEvent(container.id, 'Pause');
            clearTimeout(progressTimer);
        }
        if (isEnd) {
            ReportProgress()
            clearTimeout(progressTimer);
        }
    }


    function gaPushEvent(containerId, action) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'youtube_event',
            'Category': 'YouTube Player Events',
            'Action': 'YouTube Player ' + action,
            'Label': analyticsLabel
        });
    }


    function getVisibilityPercentage() {
        const windowHeight = document.documentElement.clientHeight;
        const docScroll = document.documentElement.scrollTop;
        const divPosition = container.offsetTop;
        const divHeight = container.clientHeight;
        const hiddenBefore = docScroll - divPosition;
        const hiddenAfter = (divPosition + divHeight) - (docScroll + windowHeight);

        if ((docScroll > divPosition + divHeight) || (divPosition > docScroll + windowHeight)) {
            return 0;
        } else {
            var result = 100;
            if (hiddenBefore > 0) {
                result -= (hiddenBefore * 100) / divHeight;
            }
            if (hiddenAfter > 0) {
                result -= (hiddenAfter * 100) / divHeight;
            }
            return Math.ceil(result);
        }
    }

    function sendPopularityEvent(videoId: string, videoTime: number) {
        if (!player.popularity) {
            const url = `//stats.ynet.co.il/popularity/popularity.php?content=${videoId}&type=1&time=${videoTime}&random=${Math.floor(Math.random() * 999999)}`
            new Image().src = url;

            window.dataLayer.push({
                'event': 'youtube_event',
                'Category': 'YouTube Video',
                'Action': analyticsLabel,
                'Label': player.getVideoUrl() + ' & Play'
            });
            player.popularity = true;
        }
    }

    function ReportProgress() {
        let percentage = Math.round((player.getCurrentTime() / player.getDuration()) * 100);
        let containerId = container.id;

        if (percentage > 0 && percentage <= 100) {
            if (percentage > 25 && percentage < 50 && !player.ga_25) {
                gaPushEvent(containerId, 'Quartiles 25%');
                player.ga_25 = true;
            } else if (percentage > 50 && percentage < 75 && !player.ga_50) {
                gaPushEvent(containerId, 'Quartiles 50%');
                player.ga_50 = true;
            } else if (percentage > 75 && percentage < 100 && !player.ga_75) {
                gaPushEvent(containerId, 'Quartiles 75%');
                player.ga_75 = true;
            } else if (percentage >= 100 && !player.ga_100) {
                gaPushEvent(containerId, 'Quartiles 100%');
                player.ga_100 = true;
            } else {
                return false;
            }
        }
    }
}
