require("./radionas_site_simpleLiveFloatingPlayer.less")
import { RadioProgramItem } from "../radioHompage/components/radioHomepagePlayerComponenta";
import Slider from "react-slick"
import * as ResizeObserver from "resize-observer-polyfill"
import { TogglePlayAction } from "./audioPlayButton";

interface SimpleLiveFloatingPlayerProps {
    programItem: RadioProgramItem
    isMobileWeb: boolean
    isAudioPlaying: boolean
    isFloatingPlayerVisible: boolean
    audioElement: HTMLAudioElement
    isSeeking: boolean
    onClose: () => void
    onTogglePlayPause: () => TogglePlayAction
}

interface SimpleLiveFloatingPlayerState {
    isTitleOverFlowing: boolean
    floatingPlayerBottomHeight: number
}

export class SimpleLiveFloatingPlayer extends React.Component<SimpleLiveFloatingPlayerProps & { site?: string }, SimpleLiveFloatingPlayerState> {
    constructor() {
        super()
        this.state = {
            isTitleOverFlowing: null,
            floatingPlayerBottomHeight: 0
        }
    }

    private resizeObserver: ResizeObserver;

    componentDidMount() {
        const elementId = this.props.isMobileWeb ? "ads.banner" : "ads.sargel"
        const stickyAdElement = document.getElementById(elementId)

        this.setState({ isTitleOverFlowing: this.getTitleOverflowStatus() })


        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            this.onContentHeightChanged(r.contentRect.height)
        }))

        stickyAdElement && this.resizeObserver.observe(stickyAdElement);
    }

    componentDidUpdate(prevProps) {
        if (this.props.programItem !== prevProps.programItem) {
            this.setState({ isTitleOverFlowing: this.getTitleOverflowStatus() })
        }
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect()
    }

    private getTitleOverflowStatus = () => {
        const titleElement = document.querySelector(".floating-player-show-title") as HTMLElement
        const wrapperElement = document.querySelector(".floating-player-show-title-wrapper") as HTMLElement
        let titleOverflow = false
        if (titleElement && wrapperElement) {
            titleOverflow = titleElement.offsetWidth > wrapperElement.offsetWidth
        }
        return titleOverflow
    }

    private onContentHeightChanged = (height: number) => {
        this.setState({ floatingPlayerBottomHeight: height })
    }

    render() {
        const { programItem: { title, anchor }, isAudioPlaying, isFloatingPlayerVisible, onClose, isSeeking, isMobileWeb, onTogglePlayPause, site } = this.props
        const { floatingPlayerBottomHeight, isTitleOverFlowing } = this.state

        const liveIndicationText = site === "radionas" ? "بث مباشر" : "שידור חי"
        const noShowsText = site === "radionas" ? "لا توجد خطة للعب" : "אין תוכנית לנגן"
        const settings = {
            dots: false, infinite: true, slidesToShow: 1, slidesToScroll: 1, autoplay: true,
            speed: title.length * 200, autoplaySpeed: 0, rtl: true, arrows: false, cssEase: "linear"
        }

        const titleToShow = title ?
            <>
                <div className="title">{title}</div>
                <div className="separator"></div>
                <div className="anchor">{anchor}</div>
            </>
            :
            noShowsText;

        return (
            <div className={`simple-floating-player ${isFloatingPlayerVisible ? "show" : "hide"}`} style={{ bottom: floatingPlayerBottomHeight }}>
                <button className="floating-player-close-sign" onClick={onClose} aria-label="close player"></button>

                <button className={`floating-player-button ${isSeeking ? "floating-play-button-loader" : ""}`} onClick={onTogglePlayPause} aria-label={isAudioPlaying ? `pause - ${title}` : `play - ${title}`}>
                    <div className={`floating-player-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                </button>

                <div className="live-indication">
                    <div className="dot"></div>
                    <div className="live-text">{liveIndicationText}</div>
                </div>

                <div className="floating-player-content">
                    <div className="floating-player-show-title-wrapper">
                        {isTitleOverFlowing ?
                            <>
                                <div className="fadder-right"></div>
                                <div className="slider-wrapper">
                                    <Slider {...settings}>
                                        <div className="floating-player-show-title">{titleToShow}</div>
                                        <div className="floating-player-show-title">{titleToShow}</div>
                                    </Slider>
                                </div>
                                <div className="fadder-left"></div>
                            </> :
                            <div className="floating-player-show-title">{titleToShow}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}