
import { isWcmEditor } from "../../../../../../config/serverConfig";
import { analyticsPushFunc, openFacebookShare, openMailShare, openTwitterShare, openWhatsAppShare, openLinkedinShare } from "../../../../../widgetsUtils";
import { SiteLayoutType } from "../../../../../../pagesPage/stateInterfaces";
require('./calcalist_site_calcalistPodcastShareMenu.less');
const clickOutside = require('react-click-outside');
interface CalcalistPodcastShareMenuProps {
    title: string
    url: string
    siteLayoutType?: SiteLayoutType
}

interface CalcalistPodcastShareMenuState {
    showVerticalMenu: boolean
}



@clickOutside
export class CalcalistPodcastShareMenu extends React.Component<CalcalistPodcastShareMenuProps, CalcalistPodcastShareMenuState>{
    public static siteScriptName = 'CalcalistPodcastShareMenuComponenta';

    constructor(props) {
        super(props)
        this.state = {
            showVerticalMenu: false,

        }
    }

    private handleClickOutside = () => {
        this.setState({
            showVerticalMenu: false
        });
    }


    handleVerticalMenu = () => {
        this.setState({ showVerticalMenu: !this.state.showVerticalMenu })
    }


    CalcalistPodcastShareMenuMutam = () => {
        const { siteLayoutType, url, title } = this.props;
        const { showVerticalMenu } = this.state;
        return (
            <div className='calcalist-podcast-menu-share-mutam-container'>
                <div className="share-container">
                    <span className="share-icon-pf red" onClick={this.handleVerticalMenu}></span>
                    <span className="close-icon-pf" onClick={() => history.back()}></span>
                </div>

                {showVerticalMenu && <div className="vertical-share-menu">
                    <button onClick={() => !isWcmEditor() ? openWhatsAppShare(url, () => analyticsPushFunc("Whatsapp", "Article Podcast", title), "Article Podcast") : null} className="podcast-share-btn wa" />
                    <button onClick={() => !isWcmEditor() ? openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article Podcast", title), "Article Podcast") : null} className="podcast-share-btn fb" />
                    <button onClick={() => !isWcmEditor() ? openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article Podcast", title), "Article Podcast") : null} className="podcast-share-btn tw" />
                    <button onClick={() => !isWcmEditor() ? openMailShare(title, "Calcalist",url, () => analyticsPushFunc("Mail", "Article Podcast", title), "Article Podcast") : null} className="podcast-share-btn mail" />
                </div>}
            </div>
        )
    }

    CalcalistPodcastShareMenuApp = () => {
        const { siteLayoutType, url, title } = this.props;
        const { showVerticalMenu } = this.state;
        return (
            <div className='calcalist-podcast-menu-share-app-container'>
                <div className="share-container">
                    <span className="share-icon-pf red" onClick={this.handleVerticalMenu}></span>
                    <a href="close"  title="back" >
                    <span className="close-icon-pf"></span>
                    </a>
                </div>

                <div className={`vertical-share-menu ${showVerticalMenu ? "visible": ""}`}  >
                            <a href="whatsapp" data-intent="doShare">
                                <span className="podcast-share-btn wa"></span>
                            </a>
                            <a href="facebook"  data-intent="doShare">
                                <span className="podcast-share-btn fb"></span>
                            </a>
                            <a href="twiitter" data-intent="doShare">
                                <span className="podcast-share-btn tw"></span>
                            </a>
                            <a href="mail" data-intent="doShare">
                                <span className="podcast-share-btn mail"></span>
                            </a>
                </div>
            </div>
        )
    }



    CalcalistPodcastShareMenuDesktop = () => {
        const { siteLayoutType, url, title } = this.props;
        return (
            <div className='calcalist-podcast-menu-share-container'>
                <button onClick={() => !isWcmEditor() ? openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn fb" />
                <button onClick={() => !isWcmEditor() ? openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn tw" />
                <button onClick={() => !isWcmEditor() ? openMailShare(title, "כלכליסט", url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn mail" />
                <button onClick={() => !isWcmEditor() ? openWhatsAppShare(url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn wa" />
            </div>
        )
    }

    render() {
        const { siteLayoutType, url, title } = this.props;
        const { showVerticalMenu } = this.state;

        const openMobileShare = async () => {
            const shareData = { url: url, title: title }
            try {
                // @ts-ignore 
                await navigator.share(shareData)
            } catch (err) {
                console.log("error with sharing function", err);
            }
        }

        switch (siteLayoutType) {
            case 'mobileWeb':
                return <this.CalcalistPodcastShareMenuMutam />
            case 'desktop':
                return <this.CalcalistPodcastShareMenuDesktop />
            case 'mobileApp':
                return <this.CalcalistPodcastShareMenuApp />
            default:
                return <this.CalcalistPodcastShareMenuDesktop />
        }
    }

}

