import * as startsWith from "lodash/startsWith"
import { JsonIconType, ArticleLinkDataJson, LinkDataJson } from "../widgetJsonInterfaces"
import { ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import { MultiArticleItemSlotData } from "../../defenitions/multiArticle/data/multiArticleItemSlotData"
import { LinkedItemSlotData } from "widgets/commonDataObjects/linkedItemSlotData"
import { ArticleLinkDataJsonVer2, LinkDataJsonVer2 } from "../widgetJsonInterfacesVer2";
import { JsonProps } from "../../interfaces";
import { channelIconUrl, ITitleLink } from "widgets/widgetsUtils"

export function dateToString(d: Date) {
    if (!d) return null;
    d = typeof d === "string" ? new Date(d) : d;
    return `${zeroPad2(d.getDate())}/${zeroPad2(d.getMonth() + 1)}/${d.getFullYear()} ${zeroPad2(d.getHours())}:${zeroPad2(d.getMinutes())}`
}

function zeroPad2(n: number) {
    if (n < 0) throw `${n} is less then zero and cannot be padded`;
    return n < 10 ? `0${n}` : n.toString();
}

export function bool2number(b: boolean) {
    return b ? 1 : 0;
}

export function stringToNumber(s: string) {
    return Number(s);
}

export function getIconType(slotItem: LinkedItemSlotData, link_data: LinkDataJson): JsonIconType {
    const slot = slotItem as MultiArticleItemSlotData; // just so TS will not complain
    if (slot.icon) {
        if (slot.icon == ComponentaIconType.video ||
            (slot.icon == ComponentaIconType.automatic &&
                link_data &&
                link_data.type == "article" &&
                (<ArticleLinkDataJson>link_data).article_data.isVideo == 1)) {
            return "video";
        }
    }
    return undefined;
}
export function getIconTypeVer2(slotItem: LinkedItemSlotData, link_data: LinkDataJsonVer2): JsonIconType {
    const slot = slotItem as MultiArticleItemSlotData; // just so TS will not complain
    const isVideo = link_data.link && (<ArticleLinkDataJsonVer2>link_data).link.hasVideo
    if (slot.icon) {
        if (slot.icon == ComponentaIconType.video ||
            (slot.icon == ComponentaIconType.automatic &&
                link_data &&
                link_data.type == "article" &&
                isVideo)) {
            return "video";
        }
    }
    return undefined;
}
const htmlReplacements = {
    "&quot;": '"',
    "&#39;": "'",
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
}
const htmlReplacementsRegexString = Object.keys(htmlReplacements).join("|")
const htmlReplacementsRegex = new RegExp(htmlReplacementsRegexString, "g");

export function removeHtmlTagsFromString(input: string) {
    if (input) {
        return input
            .replace(/<(?:.|\n)*?>/gm, '')
            .replace(/&[n]bsp;/g, ' ')
            .replace(htmlReplacementsRegex, s => htmlReplacements[s]).trim();
    }
    return ""
}

export function fixComponentaTitle(title) {
    return title || " ";
}

export function addHttpsIfMissing(url: string) {
    return startsWith(url, "//") ? `https:${url}` : url;
}

export function channelIconUrlForJson(props: JsonProps<ITitleLink>) {
    const icon = channelIconUrl(props.data, props.getPageCategory)
    if (icon !== null) {
        return props.imagesDomain + icon
    }
    return null
}