import * as map from "lodash/map"
import * as filter from "lodash/filter"
import { ArticleExtraData } from "../../../../articlePage/stateInterfaces"
import { ImageDetails } from "images/interfaces"
import { YPlusLoadMoreList } from "./yplusLoadMoreList";


require('./yplus_site_loadMore.less')
require('./yplus_site_loadMore-mobile.less')

export interface SiteYPlusLoadMoreData {
    articleId: string
    author: string
    credit: string
    categoryName: string
    title: string,
    subTitle: string,
    link: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean
    publishedLink: string

}

interface InfinityHeadlinesViewProps {
    height: number
    articles: ArticleExtraData[]
    isCategoryVisible: boolean
    loader: boolean
    ver?: number 
    commercialItemsNum?:number
    commercialRepetitionsNum?:number
}


export class YPlusLoadMoreView extends React.Component<InfinityHeadlinesViewProps, {}>  {

    public render() {
        const { height, articles, isCategoryVisible, ver,     commercialItemsNum, commercialRepetitionsNum} = this.props
        let itemList: SiteYPlusLoadMoreData[] = [];

        if (Object.keys(articles).length > 0) {
            itemList = filter(map(articles, (article: ArticleExtraData): SiteYPlusLoadMoreData =>
                ({
                    articleId: article.articleId,
                    author: article.author,
                    credit: article.promotionImageDetails ? article.promotionImageDetails.credit : null,
                    categoryName: article.category.textToShow,
                    title: article.title,
                    subTitle: article.subTitle,
                    link: article.publishedLink,
                    promotionImageDetails: article.promotionImageDetails,
                    hasVideo: article.hasVideo,
                    publishedLink: article.publishedLink
                })
            ))
        }

        return (
            <YPlusLoadMoreList
                dataLength={articles.length}
                itemList={itemList}
                height={height}
                isCategoryVisible={isCategoryVisible}
                ver={ver}
                commercialItemsNum={commercialItemsNum}
                commercialRepetitionsNum={commercialRepetitionsNum}
            />
        )
    }
} 