require("./floatingColorPicker.less")
import { ToolbarButton } from "textEditor/components/toolbarButton"
import { ColorPicker } from "../../../textEditor/components/colorPicker"
import { hexToRgbA } from "widgets/widgetsUtils"
import settings from "config/settings"

interface FloatingColorPickerProps {
    top?: number
    bottom?: number
    left?: number
    right?: number
    selectedText?: Selection
    showBold?: boolean
    fontWeight?: string
}
interface FloatingColorPickerState {
    isColorPickerVisible: boolean
    isTheWholeComponentVisible: boolean
}


export class FloatingColorPicker extends React.Component<FloatingColorPickerProps, FloatingColorPickerState>{
    constructor(props: FloatingColorPickerProps) {
        super(props)
        this.state = {
            isColorPickerVisible: false,
            isTheWholeComponentVisible: true
        }
    }

    private textColorsArr = settings.defaultTitleColorPickerColors;

    private createColorOptions = (colors) => {
        return _.map(colors, i => ({
            label: i,
            value: i,
        }))
    }

    private onEmphasize = (hexColor: string): void => {
        const { selectedText } = this.props
        if (!selectedText) { return null }

        const text = window.getSelection().toString();

        document.execCommand('styleWithCSS', false, true as any);
        if (hexColor === `#${__("Without Color")}`) {
            const selection = window.getSelection();
            if (selection.rangeCount) {
                const range = selection.getRangeAt(0);
                const selectedElement: any = range.startContainer.parentNode;
                if (selectedElement.nodeName === 'SPAN' && selectedElement.style.webkitTextFillColor !== '') {
                    const parent = selectedElement.parentNode;
                    while (selectedElement.firstChild) {
                        parent.insertBefore(selectedElement.firstChild, selectedElement);
                    }
                    parent.removeChild(selectedElement);
                    parent.normalize();
                }
            }
        }
        else {
            const emphasizeColor = hexToRgbA(hexColor); //"rgb(255, 0, 0)"; // always use rgb() because document.queryCommandValue return value is rgb
            const selection = window.getSelection();
            if (selection.rangeCount) {
                const range = selection.getRangeAt(0);
                const selectedText = range.cloneContents();
                const span = document.createElement('span');
                span.style.webkitTextFillColor = emphasizeColor;
                span.style.color = emphasizeColor;
                // add a class to the span
                span.className = "redTextColorSpan";
                span.appendChild(selectedText);
                range.deleteContents();
                range.insertNode(span);
            }
        }
        this.hideTextColorPicker();
        this.setState({ isTheWholeComponentVisible: false })

        // article update workarround
        document.execCommand("bold", false, null)
        document.execCommand("bold", false, null)
    }

    private showTextColorPicker = (): void => {
        this.setState({ isColorPickerVisible: true })
    }

    private hideTextColorPicker = (): void => {
        this.setState({ isColorPickerVisible: false })
    }

    private handleToolbarOnClick = (e) => {
    const { isColorPickerVisible } = this.state
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        isColorPickerVisible ? this.hideTextColorPicker() : this.showTextColorPicker()
    }

    private onBoldClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        document.execCommand("bold", false, null)
        this.setState({ isTheWholeComponentVisible: false })
    }
    render() {
        const { top, bottom, left, right, showBold } = this.props
        const { isTheWholeComponentVisible, isColorPickerVisible } = this.state
        const style = { position: "absolute", top, bottom, right, left }
        if (!isTheWholeComponentVisible) { return null }
        return (
            <div className="FloatingColorPicker" style={style}>
                <div className="btnWrapper">
                    {
                        showBold &&
                        <ToolbarButton
                            id="textEditorBoldBtn"
                            className={""}
                            onClick={this.onBoldClick}
                            iconClassName="yicon-text_editor-bold"
                            title={__("Bold")}
                        />
                    }
                    
                    <ToolbarButton
                        id="textEditorTextColorPickerBtn"
                        onClick={e => this.handleToolbarOnClick(e)}
                        className={""}
                        iconClassName="yicon-text_editor-textColor"
                        title={__("Text color")}
                    />
                    <button
                        className="yicon-drop_down"
                        onMouseDown={e => { e.preventDefault(); e.stopPropagation() }}
                        onClick={e => this.handleToolbarOnClick(e)}>
                    </button>
                </div>

                {isColorPickerVisible && <ColorPicker
                    className="textEditor-textColorDropDown"
                    colorOptions={this.createColorOptions(this.textColorsArr)}
                    colorPickerOpen={true}
                    toggleColor={value => this.onEmphasize(value)}
                    toggleColorPicker={() => null}
                    prefix={""}
                    style={{ width: "80px" }}
                />}
            </div>
        )
    }
}