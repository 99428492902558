import { minutesDiff } from '../../../ynet1280/newsflashAndTwentyFourSeven/components/newsFlashAndTwentyFourSevenMobileComponenta';
import { ArticleExtraData } from '../../../../../articlePage/stateInterfaces';
import { addAxiosDateTransformerSettings } from '../../../../../utils/network/axiosDateTransformer';
import { RadionasNewsFlashHomepageViewData } from '../utils';
import { RadionasNewsFlashItemsList } from './radionasNewsFlashItemsList';
import * as axios from "axios"
require("./radionas_site_radionasNewsFlashHomepageComponent.less")

interface RadionasNewsFlashHomepageComponentProps {
    data: RadionasNewsFlashHomepageViewData
    isMobileWeb: boolean
    audioDomain: string
    componentaId: string
    listHeight: number
}
interface TickerAutoRowViewState {
    tickerArticles: ArticleExtraData[]
    isFirstInterval: boolean
    shouldDisplayLoader: boolean
}

export default class RadionasNewsFlashHomepageComponent extends React.Component<RadionasNewsFlashHomepageComponentProps, TickerAutoRowViewState> {

    public static siteScriptName = "RadionasNewsFlashHomepageComponent"

    constructor(props) {
        super(props)
        this.state = {
            tickerArticles: [],
            isFirstInterval: true,
            shouldDisplayLoader: false,
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.getTickerArticles(newProps.data)
        }
    }

    componentDidMount() {
        const { data } = this.props;
        const highlightingDuration = 3;
        if (data.category.link.id) {
            this.getTickerArticles(highlightingDuration)
            setInterval(() => {
                this.getTickerArticles(highlightingDuration)
            }, 60000);
        }
    }

    private getTickerArticles(highlightingDuration: number) {
        const { componentaId } = this.props;
        const { isFirstInterval, shouldDisplayLoader, tickerArticles } = this.state;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());

        axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/`)
            .then((res: any) => {
                this.setState({ tickerArticles: res.data.data });
            }).then(() => {
                const zeroMinDiff = tickerArticles.some((article) => minutesDiff(article) < 1);
                const upToFiveMinDiff = tickerArticles.some((article) => minutesDiff(article) < highlightingDuration);

                if ((isFirstInterval && upToFiveMinDiff) || zeroMinDiff) {
                    this.setState({ shouldDisplayLoader: true })
                    setTimeout(() => {
                        this.setState({ shouldDisplayLoader: false })
                    }, 2000)
                };
            })
            .catch(err => console.error("error getting ticker data:", err))
    }
    render() {
        const { data, isMobileWeb, audioDomain, listHeight } = this.props

        return (
            <RadionasNewsFlashItemsList
                data={data}
                listHeight={listHeight}
                audioDomain={audioDomain}
                isMobileWeb={isMobileWeb}
                articles={this.state.tickerArticles}
            />
        )
    }
}
