import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { MenuPopupComponenta } from "./menuPopUpComponenta";
import { MainNavHeaderComponenta } from "./mainNavComponenta";



interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isSearchOpened: boolean
}

interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    radioLink: string
    radioPageLinkTarget: LinkTargetProperties
    logoData: Logo
    tabBgColor: string
    shoppingPageUrl: string
    shoppingPageUrlTarget: LinkTargetProperties
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToRadioNas: string
    linkToTiktok: string
    enableSticky: boolean
    followUsLink: string
    folowUsLinkTarget: LinkTargetProperties
    domain: string
    searchPageLink: string
}

export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}

export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}
export interface CommertialItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
}

export class SiteRadionasHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteRadionasHeaderComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isSearchOpened: false
        }
    }

    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened, isSearchOpened: false, })
    }

    private openSearchMenu = () => {
        const isExternalPage = typeof window != "undefined" && window.isExternalPage;
        if (isExternalPage) {
            top.location.href = '';
        }
        else this.setState({ isSearchOpened: !this.state.isSearchOpened, isOpened: false })
    }

    private stickyBar() {
        const headerPos = document.getElementById("RadionasHeader").getBoundingClientRect().top
        if (headerPos + 130 < 0) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())

    }

    componentWillUnmount() {
        const navbar = document.getElementById("RadionasHeader")
        document.removeEventListener("scroll", this.throttledScrollHandler())

    }

    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        const { mainNavItems, groupsWithItems, logoData, enableSticky, radioLink, searchPageLink, shoppingPageUrl, shoppingPageUrlTarget,
            tabBgColor, followUsText, linkToInst, linkToFb, linkToRadioNas, linkToTiktok, radioPageLinkTarget,
        } = this.props
        const { isSticky } = this.state
        const isStickyAvailable = isSticky && enableSticky;
        return (
            <div className={classNames("RadionasHeader")} id="RadionasHeader">
                <button className="accessibiltyFixedBtn" onClick={this.clickAcessibilityBtn} id="INDmenu-btn-fixed" title="אתר נגיש" aria-labelledby="INDbtnTooltip" accessKey="m"></button>
                <div className="headerTop">
                    <div className="logo">
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                            <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>

                    </div>
                    <div className="leftSideLinks">
                      {shoppingPageUrl && shoppingPageUrl.length>0 &&  <HyperLinkBuilder href={shoppingPageUrl} linkTarget={shoppingPageUrlTarget} title={"ناسكم"} >
                            <span className="headerIcon shopping" />
                        </HyperLinkBuilder>}
                        {shoppingPageUrl && shoppingPageUrl.length>0 && radioLink && radioLink.length>0  && <span className="separator"/>}
                      {radioLink && radioLink.length>0 && <HyperLinkBuilder href={radioLink} linkTarget={radioPageLinkTarget}  title={"راديو الناس"}>
                            <span className="headerIcon radio" />
                        </HyperLinkBuilder>}
                        </div>



                </div>

                <div className={classNames("BottomHeaderArea", { "sticky": isStickyAvailable })} id="BottomHeaderArea">
                    <MainNavHeaderComponenta
                        isSticky={isStickyAvailable}
                        mainNavItems={mainNavItems}
                        logoData={logoData}
                        onToggleMenu={this.openMenu}
                        onToggleSearch={this.openSearchMenu}
                        isOpened={this.state.isOpened}
                        tabBgColor={tabBgColor}
                    />

                    <MenuPopupComponenta
                        groupsWithItems={groupsWithItems}
                        isVisibleMenu={this.state.isOpened}
                        isVisibleSearch={this.state.isSearchOpened}
                        onCloseMenu={() => this.setState({ isOpened: false })}
                        onCloseSearch={() => this.setState({ isSearchOpened: false })}
                        followUsText={followUsText}
                        linkToInst={linkToInst}
                        linkToFb={linkToFb}
                        linkToRadioNas={linkToRadioNas}
                        linkToTiktok={linkToTiktok}
                        ynetSearchPageLink={searchPageLink}
                    />
                </div>
            </div>
        )
    }
}