import { isWcmEditor } from "../../../../../config/serverConfig";

require("./ynetnews_vesty_site_ynetSearchForm.less")

type SelectedTypes = "web" | "siteName";
interface YnetSearchFormProps {
    lang: string
    placeholder?: string
    searchPageLink?: string
    isGrid1280?: boolean
    closeX?:boolean
    closeSearchInput?:()=>void
}
interface YnetSearchFormState {
    selectedSearchVal: SelectedTypes
    userInput: string
    isInFocus: boolean
    backgroundImg?: string
}

export class YnetSearchForm extends React.Component<YnetSearchFormProps, YnetSearchFormState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedSearchVal: (props.lang === "ru" && !this.props.isGrid1280) ? "siteName" : "web",
            userInput: "",
            isInFocus: false,
        }
    }



    private proffesionsUtf8Encode = (src: string): string => {
        let encodedString = ""
        for (let i = 0; i < src.length; i++) {
            let cha = src.charCodeAt(i)
            if (cha >= 1488 && cha <= 1514) {
                cha -= 1264
                encodedString += "%" + cha.toString(16).toUpperCase()
            } else {
                encodedString += encodeURI(src.charAt(i))
            }
        }
        return encodedString
    }
    private search = () => {
        const { lang, searchPageLink, isGrid1280, closeX } = this.props
        const { selectedSearchVal, userInput: typedUserInput } = this.state;
        if (!typedUserInput) {
            return
        }

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let urlTail = ""

        if (!isWcmEditor()) {
            let urlTWeb: string;
            let urlHWeb: string;
            let urlHSite: string;
            let urlSearchInSite: string;

            switch (lang) {
                case "en":
                    if (isGrid1280) {
                        urlTWeb = "";
                        urlHWeb = searchPageLink ? searchPageLink + "?q=" : "//www.google.com/search?as_sitesearch=www.ynetnews.com&q=";
                        urlHSite = "&as_sitesearch=www.ynetnews.com"
                    }
                    else {
                        urlTWeb = ""
                        urlHWeb = "//search.ynetnews.com/serp?q="
                        urlHSite = searchPageLink ? searchPageLink + "?q=" : "//search.ynetnews.com/serp?q="
                    }
                    break;
                case "ru":
                    if (isGrid1280) {
                        urlTWeb = "";
                        urlHWeb = searchPageLink ? searchPageLink + "?q=" : "//www.google.com/search?as_sitesearch=www.vesty.co.il&q=";
                        urlHSite = "&as_sitesearch=www.vesty.co.il"
                    }
                    else{
                        urlTWeb = "&cx=partner-pub-4207657971126930%3A3070512948&cof=FORID%3A10&ie=UTF-8&sa=Search&mainSearchGoogle=Web"
                        urlHWeb = "//www.vesty.co.il/home/0,7340,L-13223,00.html?sort=date&q="
                        urlHSite = "//www.vesty.co.il/home/0,7340,L-13223,00.html?sort=date&q="
                        urlSearchInSite = "&cx=partner-pub-4207657971126930:7839563526&cof=FORID%3A10&ie=UTF-8&sa=Search&mainSearchGoogle=Vesti"
                    }
                    break;
                case "es":
                    urlTWeb = "&as_sitesearch=www.ynetespanol.com"
                    urlHWeb = "//www.google.com/search?q="
                    urlHSite = "//www.google.com/search?q="
                    break;
            }

            switch (selectedSearchVal) {
                case "web":
                    urlHead = urlHWeb
                    userInput = typedUserInput
                    urlTail = urlTWeb
                    fullUrl = lang !== "es" ? urlHead + userInput + urlTail : urlHead + userInput
                    location.href = fullUrl
                    break;
                case "siteName":
                    urlHead = urlHSite
                    userInput = typedUserInput
                    if (lang == "ru") {
                        fullUrl = urlHead + userInput + urlSearchInSite
                    } else {
                        fullUrl = lang !== "es" ? urlHead + userInput : urlHead + userInput + urlTWeb
                    }

                    location.href = fullUrl
                    break;
            }
        }

    }

    private updateSearchSelection = (data) => {
        this.setState({ selectedSearchVal: data.target.value });
    }

    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }

    private handleInputFocus = () => {
        this.setState({ isInFocus: true });
    }

    private handleInputBlur = () => {
        this.setState({ isInFocus: false });
    }


    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }

    public render() {
        const { lang , placeholder} = this.props
        const { selectedSearchVal, userInput } = this.state;
        let SearchSiteName: string;
        let SearchWeb: string;

        switch (lang) {
            case "en":
                SearchSiteName = "ynetNews"
                SearchWeb = "web"
                break;
            case "ru":
                SearchSiteName = "Вести"
                SearchWeb = "веб"
                break;
            case "es":
                SearchSiteName = "YnetEspanol"
                SearchWeb = "web"
                break;
        }
        return (

            <div className="main_search_elements" id="mainSrchElements">
                <a href="javascript:void(0);" id="MsBtn" className={lang} role="button" onClick={this.search} />
                <div id="main_search_bg">
                    {lang !== "en" &&
                        <div id="mainSearchSelect">
                            <select id="mainSearchSelectType" aria-label="Choose select type" onChange={(data) => this.updateSearchSelection(data)} value={selectedSearchVal}>
                                <option value="siteName">
                                    {SearchSiteName}
                                </option>
                                <option value="web">
                                    {SearchWeb}
                                </option>
                            </select>
                        </div>
                    }
                    <div id="mainSrchBoxInput">
                        <input
                            alt="חיפוש"
                            className="mainSrchclass"
                            id="mainSrchBox"
                            aria-label="חיפוש"
                            type="text"
                            value={userInput}
                            placeholder={placeholder && placeholder}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            onChange={this.updateInputValue}
                            onKeyPress={this.handleInputKeyPress}
                            style={{ background: "#fff" }}
                        />

                    </div>
                </div>
                {this.props.closeX &&<button className="closeBtn" onClick={this.props.closeSearchInput}></button>}
            </div>
        )
    }
}