import { RadioProgramItem } from "../../radioHompage/components/radioHomepagePlayerComponenta";
import Slider from "react-slick"
import { useEffect, useState } from "react"
import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { AudioRendererProps } from "../../floatingPlayer/audioPlayButton";

interface FrontRadioArticleLivePlayerProps extends AudioRendererProps {
    buttonTitle: string
    buttonHref: string
    buttonLink: LinkProperties<BaseLinkTypes>
    buttonLinkTarget: LinkTargetProperties
    programItem: RadioProgramItem
}

export const FrontRadioArticleLivePlayer = (props: FrontRadioArticleLivePlayerProps) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: props.programItem.title.length * 200,
        autoplaySpeed: 0,
        rtl: true,
        arrows: false,
        cssEase: "linear"
    }
    const { programItem, isAudioPlaying, onTogglePlayPause, onRefChanged, buttonTitle, buttonHref, buttonLink, buttonLinkTarget,
        programItem: { title, isProgramLive, hideHours } } = props
    const [programTitleWidth, setProgramTitleWidth] = useState("0")
    const [isTitleOverFlowing, setIsTitleOverFlowing] = useState(null)
    const shouldeShowEqualizer = isProgramLive || !hideHours

    const getShowTime = (item: RadioProgramItem) => {
        if (item && !item.hideHours) {
            return `${item.startTime} - ${item.endTime}`
        }
        return ""
    }

    const getTitleOverflowStatus = () => {
        const titleElement = document.querySelector(".radio-player-show-title") as HTMLElement
        const wrapperElement = document.querySelector(".radio-player-show-info-wrapper") as HTMLElement
        let titleOverflow = false
        if (titleElement) {
            titleOverflow = titleElement.offsetWidth > wrapperElement.offsetWidth
        }
        return titleOverflow
    }

    const updateProgramTitleWidth = () => {
        const titleElement = document.querySelector(".radio-player-show-title") as HTMLElement
        setProgramTitleWidth(titleElement.offsetWidth + "px")
    }

    useEffect(() => {
        setIsTitleOverFlowing(getTitleOverflowStatus())
        updateProgramTitleWidth()
    }, [programItem])

    return (
        <div className="radio-article-live-player" ref={onRefChanged}>
            <HyperLinkBuilder className="ynet-radio-gif-wrapper" href={buttonHref} link={buttonLink} linkTarget={buttonLinkTarget}>
                <img className="radio-player-y-equalizer-gif" src={"https://www.ynet.co.il/images/radio/new-radio-logo.gif"} />
            </HyperLinkBuilder>

            <div className="radio-player-content">
                <div className="live-and-time-indication">
                    {isProgramLive && <div className="radio-player-live-indication">LIVE</div>}
                    {shouldeShowEqualizer && <img className="radio-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />}
                    <div className="radio-player-show-time">{getShowTime(programItem)}</div>
                </div>

                <div className="radio-player-show-info-wrapper">
                    {isTitleOverFlowing ?
                        <>
                            <div className="fadder-right"></div>
                            <div className="slider-wrapper" style={{ width: programTitleWidth }}>
                                <Slider {...settings}>
                                    <div className="radio-player-show-title">{title || 'אין תוכנית לנגן'}</div>
                                    <div className="radio-player-show-title">{title || 'אין תוכנית לנגן'}</div>
                                </Slider>
                            </div>
                            <div className="fadder-left"></div>
                        </> :
                        <div className="radio-player-show-title">{title || 'אין תוכנית לנגן'}</div>
                    }
                </div>

                {buttonTitle &&
                    <HyperLinkBuilder className="radio-player-goto-btn-link" href={buttonHref} link={buttonLink} linkTarget={buttonLinkTarget}>
                        <div className="radio-player-goto-btn">{buttonTitle}</div>
                        <div className="radio-btn-arrow"></div>
                    </HyperLinkBuilder>}
            </div>

            <div className="radio-player-button" onClick={onTogglePlayPause}>
                <div className={`radio-player-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
            </div>
        </div>
    )
}