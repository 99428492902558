import { City } from '../WeatherMapExtendedTab/WeatherMapExtendedTab'

interface MapProps {
    citiesList: Array<City>
    isNewLayout: boolean
}



const WeatherUvMap = (props: MapProps) => {
    const { citiesList, isNewLayout } = props

    const chosenCities = ["Elat", "Ashdod", "Beer Sheva", "Bet Shean", "Haifa", "Tiberias", "Jerusalem", "Mizpe Ramon", "Zefat", "Tel Aviv Yafo"]

    const getDotColor = (uv) => {
        const {isNewLayout} = props
        switch (uv) {
            case "L":
                return { backgroundColor: isNewLayout? "linear-gradient(to bottom, #9bde42, #ecc400 86%)" : "#62ac00" }
                break;
            case "M":
                return { backgroundColor: isNewLayout?"linear-gradient(to bottom, #e28800 70%)" :"#ecc500" }
                break;
            case "H":
                return { backgroundColor: isNewLayout?"linear-gradient(to bottom, #ea6528 53%)" :"#e28800" }
                break;
            case "V":
                return { backgroundColor: isNewLayout?"linear-gradient(to bottom, #d10000 35%)" :"#d20000" }
                break;
            case "E":
                return { backgroundColor: isNewLayout?"linear-gradient(to bottom, #d100a1 16%)" :"#d200a1" }
                break;
            default:
                return { backgroundColor: isNewLayout?"#ff009c" :"#62ac00" }
                break;
        }
    }

    const getImageColor = (uv) => {
        const baseIconUrl = `/images/weather-new-icons/weatherMapIcons/uv_`
        switch (uv) {
            case "L":
                return baseIconUrl + "low.svg"
                break;
            case "M":
                return baseIconUrl + "medium.svg"
                break;
            case "H":
                return baseIconUrl + "high.svg"
                break;
            case "V":
                return baseIconUrl + "very_high.svg"
                break;
            case "E":
                return baseIconUrl + "extreme.svg"
                break;
            default:
                return baseIconUrl + "low.svg"
                break;
        }
    }

    let cities = citiesList && citiesList.map((city: City) => {
        if (city && city.name && (chosenCities as any).includes(city.name)) {
            return (
                <div key={city.name} className={`city-item ${city.name.toLowerCase().replace(/ /g, "-")}`} style={isNewLayout ? {} : { display: "block" }}>
                    <div>
                        {isNewLayout ?
                            <img src={getImageColor(city.radiation)} className="uv-img" /> :
                            <span className="dot" style={getDotColor(city.radiation)} />}
                    </div>
                    <div className="info-title">
                        <span className="temp-info">{isNewLayout ? city.radiationIndex : city.radiation}</span>
                            <br />
                            <span className="city-title">{city.nameHeb}</span>
                    </div>
                </div>
            )
        }
        return null
    })

    const indicators = () =>{
        return (
            <>
              <div className="indicator-box">
                    <div className="indicator-block border-radius-top" style={getDotColor("E")}></div>
                    <div className="indicator-info">
                        <div className="range">{`11 ומעלה`}</div>
                        <div className="desc">קיצוני</div>
                    </div>
                </div>


                <div className="indicator-box">
                    <div className="indicator-block" style={getDotColor("V")}></div>
                    <div className="indicator-info">
                        <div className="range">{`8 - 10`}</div>
                        <div className="desc">גבוה מאוד</div>
                    </div>
                </div>

                <div className="indicator-box">
                    <div className="indicator-block" style={getDotColor("H")}></div>
                    <div className="indicator-info">
                        <div className="range">{`6 - 7`}</div>
                        <div className="desc">גבוה</div>
                    </div>
                </div>

                <div className="indicator-box">
                    <div className="indicator-block" style={getDotColor("M")}></div>
                    <div className="indicator-info">
                        <div className="range">{`3 - 5`}</div>
                        <div className="desc">בינוני</div>
                    </div>
                </div>

                <div className="indicator-box">
                    <div className="indicator-block border-radius-bottom" style={getDotColor("L")}></div>
                    <div className="indicator-info">
                        <div className="range">{`1 - 2`}</div>
                        <div className="desc">נמוך</div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="weather-extended-map-module" role="presentation">
            <div className="map-container">
                {cities}
            </div>

            <div className="indicator-container">

            {isNewLayout? 
            <>
            <div className="indicator-legend uv-map" />
            <div className="indicator-box-container">
                {indicators()}
            </div>
            </>
             : indicators()}

              
            
            </div>
        </div>
    )
}

export default WeatherUvMap