import { secondsToHms } from "../../../siteWidgets/siteWidgetUtils"

interface LiveProgressBarProps {
    playbackTime: number
    onSeeked: (num: number) => void
    toPlayback: (startPostion: number) => void
    startProgram: Date | string
    isDisabled: boolean
    isLive: boolean
}

interface LiveProgressBarState {
    seekTime: number
}

export class LiveProgressBar extends React.Component<LiveProgressBarProps, LiveProgressBarState> {
    constructor() {
        super()
        this.state = {
            seekTime: null,
        }
    }

    private updateCurrentTime = (e) => {
        this.props.toPlayback(Number(e.target.value))
    }

    private onChange = (e) => {
        this.setState({ seekTime: e.target.value })
    }

    render() {
        const { toPlayback, startProgram, isDisabled, isLive } = this.props
        const { seekTime } = this.state

        const programCurrentPlayTime = (new Date().getTime() - new Date(startProgram).getTime()) / 1000
        const playbackTimeString = secondsToHms(programCurrentPlayTime)
        const bubbleValue = seekTime !== null ? secondsToHms(seekTime) : playbackTimeString
        const bubbleElement = document.querySelector(".range-bubble");
        
        const percentage = ((seekTime !== null ? seekTime : programCurrentPlayTime) - 0) * 100 / (programCurrentPlayTime - 0);
        const bubbleWidth = bubbleElement && (bubbleElement.clientWidth / 2)
        const bubbleMovment = percentage < 25 ? bubbleWidth - 5 : percentage > 85 ? bubbleWidth + 5 : bubbleWidth;
        const bubbleLeftMovment = `calc(${percentage}% - ${bubbleMovment}px)`;

        const rangeBackground = `linear-gradient(90deg, #de1a1a ${percentage}%, #cacdce ${percentage}%)`;

        return (
            <>
                <div className="control-ruler-buttons">
                    <button className="go-live-btn ruler-btn disabled" aria-label="go to live" disabled={false}></button>
                    <button className={`start-over-btn ruler-btn ${isDisabled || !isLive ? " disabled" : ""}`} onClick={() => toPlayback(0)} aria-label="start program over" disabled={isDisabled || !isLive}></button>
                </div>

                <div className="control-ruler-timeline">
                    <div className="range-wrap">
                        <div className="range-bubble" style={{ left: bubbleLeftMovment }} >{bubbleValue}</div>
                        <input type="range" className={`live-timeline ${!isLive ? "disabled" : ""}`} max={programCurrentPlayTime} style={{ background: rangeBackground }} value={seekTime !== null ? seekTime : programCurrentPlayTime} onChange={this.onChange} onMouseUp={this.updateCurrentTime} onTouchEnd={this.updateCurrentTime} disabled={isDisabled || !isLive} />
                    </div>
                    <div className={`live-feedback ${!isLive ? "disabled" : ""}`}>
                        <div className="dot"></div>
                        <div className="live-text">שידור חי</div>
                    </div>
                </div>
            </>
        )
    }
}