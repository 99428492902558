import * as shortid from "shortid"
shortid.characters("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$!");

export interface IIdGenerator {
    generate: () => string
}

function defaultGenerator(): IIdGenerator {
    return shortid;
}

function replaceUnsafeChars(s: string) {
    return s.replace(/[\$!]/g, r => r == "$" ? "00" : "11");
}

class IdGeneratorFactory implements IIdGenerator {
    private static generator: IIdGenerator = defaultGenerator();
    static setGenerator(customGenerator: IIdGenerator) {
        IdGeneratorFactory.generator = customGenerator;
    }
    public generate() {
        return replaceUnsafeChars(IdGeneratorFactory.generator.generate());
    }
}

class FakeIdGenerator implements IIdGenerator {
    private ids: string[];
    private idCounter = 0;
    constructor(...fakeIds: string[]) {
        this.ids = fakeIds;
    }

    generate() {
        if (this.ids.length == 0) {
            this.ids = ["newId"];
        }
        let id = this.ids[this.idCounter];
        this.idCounter++;
        if (this.idCounter >= this.ids.length) {
            this.idCounter = 0;
        }
        return id;
    }
}

/**for tests only: setup a predictable id generator that will cycle the given fake ids */
export function setupFakeIdGenerator(...fakeIds: string[]) {
    IdGeneratorFactory.setGenerator(new FakeIdGenerator(...fakeIds));
}

/** Generates a random string value that can be used as new id */
export const IdGenerator = new IdGeneratorFactory();