import isSubZero from '../../../weather/components/helpers/isSubZero'
import { City } from '../WeatherMapExtendedTab/WeatherMapExtendedTab'
import iconTranslate from '../../../weather/translations/weatherIconTranslations'
import getWeatherCode from '../../../weather/components/helpers/getWeatherCode';
interface MapProps {
    citiesList: Array<City>
    isNewLayout?: boolean
}


const WeatherMapTemprMap = (props: MapProps) => {
    const { citiesList, isNewLayout } = props

    const chosenCities = ["Elat", "Ashdod", "Beer Sheva", "Bet Shean", "Haifa", "Tiberias", "Jerusalem", "Mizpe Ramon", "Zefat", "Tel Aviv Yafo"]

    if(isNewLayout){
        chosenCities.push("En Gedi")
    }

    let cities = citiesList && citiesList.map((city: City) => {
        if (city && city.name && (chosenCities as any).includes(city.name)) {
            return (
                <a href={city.url} key={city.name} style={{ textDecoration: "none", color: "unset" }}>
                    <div className={`city-item ${city.name.toLowerCase().replace(/ /g, "-")}`}>
                        <div>
                            <img src={getWeatherCode(city.weatherCode, !!isNewLayout)} className="weather-img" />
                        </div>
                        <div className="info-title">
                            <span className="temp-info">{isSubZero(city.minimumTemperature)}-{isSubZero(city.maximumTemperature)}</span>
                            <br />
                            <span className="city-title">{city.nameHeb}</span>
                        </div>
                    </div>
                </a>
            )
        }
        return null
    })

    return (
        <div className="weather-extended-map-module" role="presentation">
            <div className="map-container">
                {cities}
            </div>
        </div>
    )
}

export default WeatherMapTemprMap