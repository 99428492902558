import { ArticleData } from "../../../../../../articlePage/stateInterfaces";
import { ArticleBlogsReadMoreHandler } from "../../../../../../front/staticServerRendering/articleBlogsReadMoreHandler/articleBlogsReadMoreHandler";
import { MailShareButton } from "../../../../../siteWidgets/socialShareButtons/mail/mailShareButton";
import { WhatsAppShareButton } from "../../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton";
import {
    openFacebookShare,
    analyticsPushFunc,
    openTwitterShare,
} from "../../../../../widgetsUtils";
import { toggleSpotImPopUp } from "../../articleSocialShare1280Component/articleSocialShareNew1280";

interface Props {
    articleData: ArticleData;
    isApp?: boolean;
    siteName?: string;
}

interface State {
    isReverseOrder: boolean;
    limitIndex: number;
    showUrlCopiedTooltip: boolean;
}

const CHUNCK_SIZE = 5;
const SEQUENCE_FROM = 1;

export class BlogPostsSorter extends React.Component<Props, State> {
    public static siteScriptName = "BlogPostsSorter";
    constructor(props) {
        super(props);
        this.state = {
            isReverseOrder: false,
            limitIndex: CHUNCK_SIZE - 1,
            showUrlCopiedTooltip: false,
        };
    }

    private intersectionObserver: IntersectionObserver;

    componentDidMount() {
        this.sortPinnedItems();
        this.handleReadMore();
        this.handleShareButtons();
        this.initObserver();
    }

    private hideRestOfFeed = () => {
        console.log("Hiding rest of feed...");
        // set hidden to the items in the feed that are index is higher than the limitIndex
        const { limitIndex } = this.state;
        const items = document.querySelectorAll(".blogs-item-wrapper");
        items.forEach((item, index) => {
            if (index > limitIndex) {
                item.classList.add("hidden");
            } else {
                item.classList.remove("hidden");
            }
        });
    };

    private initObserver = () => {
        if (window.location.href.includes("#")) return;

        if (IntersectionObserver) {
            this.intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.onNextItemsLoad(entry.isIntersecting);
                });
            });
        }
        this.getObserverTargetIndex();
        this.hideRestOfFeed();
    };

    private getObserverTargetIndex = () => {
        const items = document.querySelectorAll(".blogs-item-wrapper");
        const { limitIndex } = this.state;

        if (items.length > limitIndex) {
            console.log("Observing intersection...");
            this.intersectionObserver.observe(
                document.querySelectorAll(".blogs-item-wrapper")[
                    limitIndex - SEQUENCE_FROM
                ]
            );
        }
    };

    private onNextItemsLoad = (isIntersecting: boolean) => {
        const { limitIndex } = this.state;
        if (isIntersecting) {
            console.log("Intersection detected!");
            this.setState({ limitIndex: limitIndex + CHUNCK_SIZE }, () => {
                this.initObserver();
            });
        }
    };

    private sortPinnedItems = () => {
        console.log("Sorting pinned items...");
        var feed = document.querySelector(".public-DraftEditor-content > div");
        var items = Array.from(feed.children);

        items.sort(function (a, b) {
            var aIsPinned: any = a.querySelector(".isPinned") !== null;
            var bIsPinned: any = b.querySelector(".isPinned") !== null;
            return bIsPinned - aIsPinned;
        });

        items.forEach(function (item) {
            feed.appendChild(item);
        });
    };

    private handleShareButtons = () => {
        console.log("Handling share buttons...");
        document.addEventListener("click", (e) =>
            this.handleShareBtnClicked(e)
        );
    };

    private handleReadMore = () => {
        const { siteName } = this.props;
        console.log("Handling read more...");
        document
            .querySelectorAll(".article-blog-componenta-wrapper")
            .forEach((blogElement) => {
                const blogContentDiv = blogElement.querySelector(
                    'div[data-contents="true"]'
                );
                if (!blogContentDiv) return;
                // get it's children (blocks)
                const blogContentDivChildren = blogContentDiv.children;
                if (!blogContentDivChildren) return;

                let restOfTheBlocks;
                let restOfTheBlocksDiv =
                    blogElement.querySelector(".restOfTheBlocks");

                // if there is a class of "TextEditorBlogEndOfPreviewTextFlag", start wrapping the blocks from it
                const endOfPreviewTextFlag = blogElement.querySelector(
                    ".TextEditorBlogEndOfPreviewTextFlag"
                );
                if (endOfPreviewTextFlag) {
                    // find the node with the div with class of "TextEditorBlogEndOfPreviewTextFlag" inside the children
                    const endOfPreviewTextFlagIndex = Array.from(
                        blogContentDivChildren
                    ).findIndex((child) =>
                        child.contains(endOfPreviewTextFlag)
                    );

                    restOfTheBlocks = Array.from(blogContentDivChildren).slice(
                        endOfPreviewTextFlagIndex + 1,
                        blogContentDivChildren.length
                    );
                }

                // if there are more than 4 blocks, wrap the rest with a div and hide it
                else if (blogContentDivChildren.length > 4) {
                    restOfTheBlocks = Array.from(blogContentDivChildren).slice(
                        4,
                        blogContentDivChildren.length
                    );
                }

                if (!restOfTheBlocksDiv) {
                    restOfTheBlocksDiv = document.createElement("div");
                    restOfTheBlocksDiv.classList.add("restOfTheBlocks");
                    restOfTheBlocksDiv.classList.add("hidden_blocks");
                    if (restOfTheBlocks) {
                        restOfTheBlocksDiv.append(
                            ...(Array.from(restOfTheBlocks) as Node[])
                        );
                    }
                    blogContentDiv.append(restOfTheBlocksDiv);
                    
                    if (!restOfTheBlocks) {
                        // hide read more button
                        (
                            blogElement.querySelector(
                                ".continue-read-wrapper-pf"
                            ) as any
                        ).style.display = "none";
                    }
                }

                // add event listener to the button
                const button = blogElement.querySelector(
                    ".continue-read-wrapper-pf"
                );
                const buttonContent = blogElement.querySelector(
                    ".continue-read-wrapper-pf .continue-read-pf"
                );
                const littleArrow = blogElement.querySelector(
                    ".continue-read-wrapper-pf .little-arrow-pf"
                );
                if (!button) return;
                button.addEventListener("click", () => {
                    const restOfTheBlocksDiv =
                        blogElement.querySelector(".restOfTheBlocks");
                    if (!restOfTheBlocksDiv) return;
                    restOfTheBlocksDiv.classList.toggle("hidden_blocks");
                    if (buttonContent.innerHTML === "צמצם") {
                        if (siteName === "calcalist") {
                            buttonContent.innerHTML = "המשך";
                        } else {
                            buttonContent.innerHTML = "להמשך קריאה";
                        }

                        littleArrow.classList.toggle("up");
                    } else {
                        buttonContent.innerHTML = "צמצם";
                        littleArrow.classList.toggle("up");
                    }
                    button.classList.toggle("active");
                });
            });
    };

    private reverseOrderWithJS = () => {
        console.log("Reversing order with JS...");
        const postsParent =
            document.querySelector(
                `.ArticleBodyComponent div[data-contents="true"]`
            ) ||
            document.querySelector(
                `.ArticleBodyAppComponent div[data-contents="true"]`
            );
        const posts = postsParent.children;
        const postsArray = Array.from(posts);
        const postsArrayReversed = postsArray.reverse();

        postsArrayReversed.forEach((post) => {
            // Exclude children with descendants that have the isPinned class
            const descendantsWithIsPinned = post.querySelectorAll(".isPinned");
            if (descendantsWithIsPinned.length === 0) {
                postsParent.appendChild(post);
            }
        });
    };

    private reverseOrder = () => {
        console.log("Reversing order...");
        const { isReverseOrder } = this.state;
        this.setState({ isReverseOrder: !isReverseOrder }, () =>
            this.reverseOrderWithJS()
        );
    };

    private openMobileShare = async (
        current_url: string,
        blogId: string,
        blogTitle: string
    ) => {
        console.log("Opening mobile share...");
        const { isApp } = this.props;
        const shareData = { url: current_url + "#" + blogId, title: blogTitle };

        if (isApp) {
            return;
        } else
            try {
                // @ts-ignore
                await navigator.share(shareData);
            } catch (err) {
                console.log("error with sharing function", err);
            }
    };

    private copyPostUrlToClipboard = () => {
        console.log("Copying post URL to clipboard...");
        const { articleData } = this.props;
        const url = articleData.publishedLink;
        if (!url) {
            return;
        }
        navigator.clipboard.writeText(`${url}`);
        this.showUrlCopiedTooltip();
    };

    private showUrlCopiedTooltip = () => {
        console.log("Showing URL copied tooltip...");
        this.setState({ showUrlCopiedTooltip: true });
        setTimeout(() => {
            this.setState({ showUrlCopiedTooltip: false });
        }, 2000);
    };

    private handleShareBtnClicked = (event: MouseEvent) => {
        console.log("Handling share button click...");
        const eventTarget = event.target as Element;
        let button: string;
        if (eventTarget.classList.contains("mobileShareBtn")) {
            button = "mobile";
        } else {
            button = eventTarget.classList[eventTarget.classList.length - 1];
        }

        if (button === "copy") {
            // this.copyPostUrlToClipboard();

            return;
        }

        if (
            !eventTarget.classList.contains("social-icon") &&
            !eventTarget.classList.contains("mobileShareBtn")
        ) {
            return;
        }

        const blogItemWrapper = eventTarget.closest(".blogs-item-wrapper");
        const blogId = blogItemWrapper ? blogItemWrapper.id : "";
        const blogTitle = blogItemWrapper.querySelector(".title-section");
        const blogTitleText = blogTitle ? blogTitle.textContent : "";

        if (typeof window !== "undefined") {
            let current_url = `${
                document.querySelector("link[rel=canonical]")
                    ? (
                          document.querySelector(
                              "link[rel=canonical]"
                          ) as HTMLLinkElement
                      ).href
                    : window.location.href
            }`;
            current_url = current_url.includes("#")
                ? current_url.split("#")[0]
                : current_url; // remove hash if exists e.g. resharing
            if (!current_url) return;
            switch (button) {
                case "fb":
                    openFacebookShare(
                        current_url,
                        "",
                        () =>
                            analyticsPushFunc(
                                "Facebook",
                                "Blogs Auto Feed",
                                blogTitleText
                            ),
                        "Blogs Auto Feed",
                        blogId,
                        blogId
                    );
                    break;

                case "tw":
                    openTwitterShare(
                        current_url,
                        () =>
                            analyticsPushFunc(
                                "Twitter",
                                "Blogs Auto Feed",
                                blogTitleText
                            ),
                        "Blogs Auto Feed",
                        blogId,
                        blogId
                    );
                    break;

                case "wtap":
                    WhatsAppShareButton.openWhatsAppShare(
                        current_url,
                        () =>
                            analyticsPushFunc(
                                "Whatsapp",
                                "Blogs Auto Feed",
                                blogTitleText
                            ),
                        "Blogs Auto Feed",
                        blogId,
                        blogId
                    );
                    break;
                case "mail":
                    MailShareButton.openMailShare(
                        blogTitleText,
                        current_url,
                        "heBlog",
                        () =>
                            analyticsPushFunc(
                                "Mail",
                                "Blogs Auto Feed",
                                blogTitleText
                            ),
                        "Blogs Auto Feed",
                        blogId,
                        blogId
                    );
                    break;
                case "tb":
                    break;
                    const elementToScroll = document.querySelector(
                        "#SiteArticleComments"
                    );
                    const commentsPopUp =
                        document.querySelector(".spotimWrapper");
                    if (elementToScroll) {
                        setTimeout(() => {
                            elementToScroll.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }, 0);
                    }
                    if (commentsPopUp) {
                        toggleSpotImPopUp(commentsPopUp);
                    }
                    break;
                case "copy":
                    navigator.clipboard.writeText(current_url + "#" + blogId);
                    break;
                default: // mobile
                    this.openMobileShare(current_url, blogId, blogTitleText);
            }
        }
    };

    public render() {
        const { isReverseOrder } = this.state;
        const { siteName } = this.props;

        return (
            <div className="blogs-auto-feed-header-wrapper">
                <div className="postsSorter_title">עדכונים שוטפים</div>
                {/* <div className="blogs-auto-feed-header"><a href={headerLinkUrl}>{feedTitleText}</a></div> */}
                <div className="orderCheckBoxes articleBlogsOCB-pf">
                    {siteName === "calcalist" ? (
                        <div
                            className="switch_wrapper"
                            onClick={() => this.reverseOrder()}
                        >
                            <label>
                                {isReverseOrder ? "מהישן לחדש" : "מהחדש לישן"}
                            </label>
                            <div class="switch">
                                <input
                                    type="checkbox"
                                    checked={!isReverseOrder}
                                />
                                <span className="slider round"></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div>
                                <input
                                    className="regularOrder"
                                    type="radio"
                                    checked={!isReverseOrder}
                                    onClick={() => this.reverseOrder()}
                                />
                                <div>הצג מהחדש לישן</div>
                            </div>
                            <div>
                                <input
                                    className="regularOrder"
                                    type="radio"
                                    checked={isReverseOrder}
                                    onClick={() => this.reverseOrder()}
                                />
                                <div>הצג מהישן לחדש</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
