import { VideoGalleryVerticalDesktop } from "./videoGalleryVerticalDesktop";
import { SiteItemData } from "../../../../interfaces";
import { isWcmEditor } from "config/serverConfig";

export interface categoryObj {
    id: string
    categoryColor: string
    name: string
}

interface VideoGalleryVerticalComponentaProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    category: categoryObj
    domain: string
    showPrerollAds: boolean
}


export class VideoGalleryVerticalComponenta extends React.Component<VideoGalleryVerticalComponentaProps, {}>{
    public static siteScriptName = "VideoGalleryVerticalComponenta";

    public render() {
        const { videoList, isTabVisible, tabTitleText, repeatAds, maxAds, firstAdPlace, category, domain, showPrerollAds } = this.props;
        const componentClass = `videoGalleryVerticalComponenta ${isWcmEditor() ? "editMode" : ""}`;
        
        return (
            <div className={componentClass}>
                <VideoGalleryVerticalDesktop
                    videoList={videoList}
                    isTabVisible={isTabVisible}
                    tabTitleText={tabTitleText}
                    repeatAds={repeatAds}
                    maxAds={maxAds}
                    firstAdPlace={firstAdPlace}
                    category={category}
                    domain={domain}
                    showPrerollAds={showPrerollAds}/>
            </div>
        )

    }
}
