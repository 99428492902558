import { VideoArchiveArticleItem } from "./videoArchiveArticleItem";
import {VideoArchiveArticle} from "./siteVideoArchiveComponenta";
interface VideoArchivehArticleListProps {
    itemList: VideoArchiveArticle[]
    isLTR?: boolean
    getFormattedDate: (dateUpdated:Date) => string
}
export class VideoArchiveArticleList extends React.Component<VideoArchivehArticleListProps, {}>{
    public render() {
        const { itemList, isLTR, getFormattedDate } = this.props
        return (
            <div className="slotList" >
                {itemList.map((item, i) =>
                        <VideoArchiveArticleItem
                            key={i}
                            article={item}
                            isLTR={isLTR}
                            getFormattedDate={getFormattedDate}
                            {...this.props}
                        />)}
            </div>
        )
    }
}