import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { SiteVideoData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"

export interface videoList {
    video: SiteVideoData
    link: string
    linkTarget: LinkTargetProperties
    title: string
    id: string
    subTitle?: string
    author?: string
    date?: Date
}

interface SideVideoProps {
    videoItems: videoList[]
    videoSettings: VideoSettingsData
    numOfItems: number
    titleColor: string
}

interface SideVideoState {
    selectedId: string
    videoPlayStarted: boolean
}

export class FrontSideVideoCalcalist extends React.Component<SideVideoProps, SideVideoState>{
    public static siteScriptName = "FrontSideVideoCalcalist";
    constructor(props) {
        super(props);
        this.state = {
            selectedId: props.videoItems[0].id,
            videoPlayStarted: false
        }
    }
    private selectItem = (id: string) => {
        this.setState({ selectedId: id })
    }

    private handleOnVideoPlayStarted = () => {
        this.setState({ videoPlayStarted: true })
    }

    private handleOnVideoPlayStopped = () => {
        this.setState({ videoPlayStarted: false })
    }

    render() {
        const { videoItems, videoSettings, numOfItems, titleColor } = this.props
        const { selectedId } = this.state
        const notSelectedItems = videoItems.filter(i => i.id !== selectedId)
        const selectedItem = videoItems.find(i => i.id === selectedId)


        return (
            <div className="SideVideoContent">
                <div className="mainVideo">
                    <div className="videoPlayerPlaceholder">
                        {selectedItem.video !== null ? <SiteMedia
                            key={selectedItem.video.mediaId}
                            onPlayStarted={() => this.handleOnVideoPlayStarted()}
                            onPlayStopped={() => this.handleOnVideoPlayStopped()}
                            data={selectedItem.video} videoSettings={videoSettings} width={300} height={168} />
                            : <div />}
                    </div>
                    <HyperLinkBuilder className="itemTitle" href={selectedItem.link} linkTarget={selectedItem.linkTarget}>
                        <div style={{ color: titleColor }} dangerouslySetInnerHTML={{ __html: selectedItem.title }} />

                    </HyperLinkBuilder>
                </div>
                {numOfItems > 1 && notSelectedItems.map((item, i) => <div className="videoItem" key={i} onClick={() => this.selectItem(item.id)}>
                    <div className="lisPreviewImage">
                        <img src={item.video.poster} />
                        <SlotIconDisplay itemId={""} icon={"video"} iconClassName="medium" isIconVisible={true} />
                    </div>

                    <div className="itemTitle">
                        <div style={{ color: titleColor }} dangerouslySetInnerHTML={{ __html: item.title }} />
                    </div>
                </div>)}
            </div>
        )
    }
}