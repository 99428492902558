import { AudioPlayButton } from "../../radioWidgets/floatingPlayer/audioPlayButton";
import { CalcalistPodcastPopupPlayer } from "./calcalistPodcastPopupPlayer";

interface CalcalistPodcastPlayerProps {
    audioUrl: string
    title: string
    durationSeconds: number
    podcastImage: string
    openPopupPlayer: () => void
}

export function CalcalistPodcastPlayer(props: CalcalistPodcastPlayerProps) {
    const { audioUrl, title, durationSeconds, podcastImage } = props;
    
    return (
        <AudioPlayButton
            audioUrl={audioUrl}
            durationSeconds={durationSeconds}
            renderer={() => null}
            floatingPlayerRenderer={(props) => (<CalcalistPodcastPopupPlayer {...props} audioUrl={audioUrl} title={title} podcastImage={podcastImage}/>)}
        />
    )
}

CalcalistPodcastPlayer.siteScriptName = 'CalcalistPodcastPlayer'