import { LinkProperties, BaseLinkTypes, LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { RadioProgramItem, DvrRadioPlayer } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"
import { ButtonRnederer } from "../../../radioWidgets/floatingPlayer/podcastPlayerButton";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"

interface NasRadioHomepageComponentProps {
    title: string;
    subTitle?: string;
    tabButtonHref: string;
    tabButtonLink: LinkProperties<BaseLinkTypes>;
    tabButtonLinkTarget?: LinkTargetProperties;
    liveBroadCastSource: string;
    radioScheduleList: RadioProgramItem[];
    startingIndex: number;
    isMobileWeb: boolean;
}

export const NasRadioHomepageComponent = (props: NasRadioHomepageComponentProps) => {

    const { title, subTitle, tabButtonHref, tabButtonLink, tabButtonLinkTarget, liveBroadCastSource, radioScheduleList, startingIndex, isMobileWeb } = props
    const currentShow = radioScheduleList[startingIndex]
    const nextShows = radioScheduleList.filter((item, index) => index > startingIndex).slice(0, 4)

    return (
        <div className="nas-radio-homepage-component">
            <div className="componentaTab">
                <div className="homepage-tab-title">{title}</div>

                <HyperLinkBuilder className="homepage-tab-btn-link" href={tabButtonHref} link={tabButtonLink} linkTarget={tabButtonLinkTarget}>
                    <div className="homepage-tab-sub-title">{subTitle}</div>
                </HyperLinkBuilder>
            </div>

            {radioScheduleList.length > 0 ?
                <div className="radio-homepage-shows">
                    <div className="current-show">
                        <div className="show-image">
                            <div className="image-wrapper">
                                <img src={currentShow.image} alt={currentShow.title} />
                                {isMobileWeb && <div className="now-playing-indication">راديو مباشر</div>}
                            </div>

                            <DvrRadioPlayer
                                title={title}
                                site={"radionas"}
                                isSimplePlayer={true}
                                isMobileWeb={isMobileWeb}
                                liveBroadCastDVRSource={""}
                                startingIndex={startingIndex}
                                radioScheduleList={radioScheduleList}
                                liveBroadCastSource={liveBroadCastSource}
                                // onCurrentShowIndexChange={handleCurrentShowIndexChange}
                                playerComponentaRenderer={(props) => (<ButtonRnederer {...props} className={"audio-play-button"} />)}
                            />
                        </div>

                        <div className="show-details">
                            {!isMobileWeb && <div className="now-playing-indication">راديو مباشر</div>}

                            <div className="show-title">{currentShow.title}</div>

                            <div className="show-sub-title">{currentShow.subTitle}</div>

                            <div className="show-info">
                                <div className="show-anchor">{currentShow.anchor}</div>

                                <div className="separator"></div>

                                <div className="show-hours">{currentShow.startTime} - {currentShow.endTime}</div>
                            </div>
                        </div>
                    </div>

                    <div className="next-shows">
                        {nextShows.map((show, index) => (
                            <div className="show-item" key={index}>
                                <div className="image-wrapper">
                                    <img src={show.image} alt={show.title} />

                                    <div className="show-hours-label">
                                        <div className="radio-icon" />
                                        
                                        <div className="hours">{show.endTime} - {show.startTime}</div>
                                    </div>
                                </div>

                                <div className="show-info-wrapper">
                                    <div className="show-title">{show.title}</div>

                                    <div className="show-anchor">{show.anchor}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                :
                <div className="no-shows-in-schedule">لا يوجد عروض للعرض - No Shows To Display</div>
            }
        </div>
    )
}

NasRadioHomepageComponent.siteScriptName = "NasRadioHomepageComponent";
