import * as classNames from "classnames"

import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { ArticleCommentRenderProps } from "../articleCommentWrapper";
import { getTranslation } from "../translations";
import { AddCommentFormWrapper, AddCommentFormRenderProps } from "../addCommentFormWrapper";
import { AddCommentFormUI1280 } from "./ynetAddCommentFormUI1280";
require("./pplus_ynet_site_articleCommentUI1280.less")

interface ArticleCommenUI1280Props {
    props: ArticleCommentRenderProps
}

export class ArticleCommentUI1280 extends React.Component<ArticleCommenUI1280Props, {}>{
    render() {
        const { lang, isExpanded, vote, talkback_like, likes, unlikes, toggleOpenedState, commentFormType, articleId,
            isAddCommentFormVisible, handleVoteButtonsClick, handleFormVisibility, handleCloseCommentForm,
            comment: {
                id,
                number,
                title,
                text,
                author,
                pubDate,
                level,
                recommended,
                talkback_source,
            } } = this.props.props

        const articlePublishDate = separateHourFromDateAndFormatToString(pubDate, { showHour: false, dateTimeSeperator: "|" })
        const expandedCommentClasses = classNames({
            "ArticleComment1280": true,
            "expanded": isExpanded,
            "level1": level === 1,
            "level2": level === 2,
        })

        const likeClasses = classNames({
            "likeSvg": true,
            "likePressed": vote === 1,
        })


        const dislikeClasses = classNames({
            "dislikeSvg": true,
            "dislikePressed": vote === -1,
        })

        const commentTitleClasses = classNames({
            "commentTitle": true,
            "redTitleText": recommended,
            "expanded": isExpanded
        })
        const commentTextStyles = classNames({
            "commentText": true,
            "level1": level === 1,
            "level2": level === 2,
            "expanded": isExpanded
        })
        const extendedBottomWrapperStyles = classNames({
            "bottomButtonsWrapper": true,
            "level1": level === 1,
            "level2": level === 2,
        })
        const commentNumClasses = classNames({
            "commentNum": true,
            //"redTitleText": recommended,
        })
        const positiveLikes = likes < 0 ? 0 : likes
        const positiveUnlikes = unlikes < 0 ? 0 : unlikes
        return (
            <div id="ArticleComment1280" className={expandedCommentClasses}>
                <div className="topView" >
                    {level === 1 && <div className={commentNumClasses} onClick={(e) => toggleOpenedState()}>{number}.</div>}
                    {level === 2 && <div className="level2ArrowContainer" onClick={(e) => toggleOpenedState()}><span className="level2Arrow" /></div>}
                    <div className="topLeftView">

                        <div className="commentDetailsWrapper" >

                            <div className={commentTitleClasses} onClick={(e) => toggleOpenedState()}>   <span>{title}</span>
                                {text.length === 0 && <span>{getTranslation(lang, "Empty Content")}</span>}
                            </div>
                        </div>
                        <div className={commentTextStyles}>{text}</div>

                        <div className="details">

                            <div>  {author !== "" ? <span className="author">{`${author}`}</span> : null}
                                {author !== "" && <span>|</span>}
                                <span className="publishDate"> {`${articlePublishDate}${talkback_source ? " , " + talkback_source : ""}`}</span></div>
                            <div>
                                <div className="voteButtonsWrapper">
                                    <span className="likesCounter">{positiveLikes}</span>
                                    <svg className={likeClasses}
                                        onClick={(e) => handleVoteButtonsClick(true, "3state")}
                                        xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
                                        <g fill="none" fillRule="evenodd">
                                            <g stroke="#888" strokeWidth=".6">
                                                <g>
                                                    <g transform="translate(-931 -355) translate(932 353) rotate(-180 5.5 7.5)">
                                                        <path d="M2.4 6.071c.945-.786 1.417-1.902 1.417-3.35V.925c0-.51.414-.925.925-.925.599 0 1.131.382 1.324.949.233.686.35 1.382.35 2.087V5.48h2.848c.848 0 1.536.688 1.536 1.536v.862c0 .648-.577 1.802-1.73 3.463-.288.413-.76.659-1.262.659H3.749c-.418 0-.818-.17-1.108-.472l-.241-.251h0V6.07z" />
                                                        <rect width="2.4" height="6" y="6" rx=".768" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span className="likesCounter unlikesCounter">{positiveUnlikes}</span>
                                    <svg
                                        className={dislikeClasses}
                                        onClick={(e) => handleVoteButtonsClick(false, "3state")}
                                        xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
                                        <g fill="none" fillRule="evenodd">
                                            <g stroke="#888" strokeWidth=".6">
                                                <g>
                                                    <g transform="translate(-931 -355) translate(932 353) rotate(-180 5.5 7.5)">
                                                        <path d="M2.4 6.071c.945-.786 1.417-1.902 1.417-3.35V.925c0-.51.414-.925.925-.925.599 0 1.131.382 1.324.949.233.686.35 1.382.35 2.087V5.48h2.848c.848 0 1.536.688 1.536 1.536v.862c0 .648-.577 1.802-1.73 3.463-.288.413-.76.659-1.262.659H3.749c-.418 0-.818-.17-1.108-.472l-.241-.251h0V6.07z" />
                                                        <rect width="2.4" height="6" y="6" rx=".768" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                </div>

                                <button className="commentToComent" title={getTranslation(lang, "Comment To Coment")} disabled={isAddCommentFormVisible}
                                    onClick={e => handleFormVisibility("REPLY")}>
                                    <span> {getTranslation(lang, "Comment To Coment")}</span>
                                </button>
                            </div>


                        </div>


                    </div>
                </div>
                <AddCommentFormWrapper
                            render={(props: AddCommentFormRenderProps) => <AddCommentFormUI1280 props={props} />}
                            lang={lang}
                            articleId={articleId}
                            formType={commentFormType}
                            handleCloseCommentForm={handleCloseCommentForm}
                            parentTalkbackId={id}
                            isAddCommentFormVisible={isAddCommentFormVisible}
                />

            </div>)
    }
}