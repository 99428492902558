import { RadioProgramItem } from "../../radioHompage/components/radioHomepagePlayerComponenta";
import { LinkProperties, BaseLinkTypes } from "../../../../../global/components/linkSettings/interfaces"

interface AppRadioArticleLivePlayerProps {
    buttonTitle: string
    buttonLink: LinkProperties<BaseLinkTypes>
    startingIndex: number
    radioScheduleList: any[]
}

interface AppRadioArticleLivePlayerState {
    showPlayingIndex: number
}

export class AppRadioArticleLivePlayer extends React.Component<AppRadioArticleLivePlayerProps, AppRadioArticleLivePlayerState> {
    public static siteScriptName = "AppRadioArticleLivePlayer"

    private timer;

    constructor(props) {
        super(props)
        this.state = {
            showPlayingIndex: props.startingIndex,
        }
    }

    componentDidMount() {
        this.createTimerForNextShow()
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    createTimerForNextShow = () => {
        const { radioScheduleList } = this.props
        const { showPlayingIndex } = this.state

        if (radioScheduleList.length > 0 && showPlayingIndex !== -1 && showPlayingIndex + 1 < radioScheduleList.length && radioScheduleList[showPlayingIndex] && radioScheduleList[showPlayingIndex].endProgram) {
            this.timer = setTimeout(() => {
                this.setState({ showPlayingIndex: showPlayingIndex + 1 }, () => {
                    //recursive until no show is available
                    const program = radioScheduleList[this.state.showPlayingIndex]
                    this.createTimerForNextShow()
                })
            }, new Date(radioScheduleList[showPlayingIndex].endProgram).getTime() - new Date().getTime());
        }
    }

    private getShowTime = (item: RadioProgramItem) => {
        if (item && !item.hideHours) {
            return `| ${item.startTime} - ${item.endTime}`
        }
        return ""
    }

    render() {
        const { buttonTitle, buttonLink, radioScheduleList } = this.props;
        const { showPlayingIndex } = this.state;
        const programItem = radioScheduleList && radioScheduleList[showPlayingIndex] || emptyProgramItem;

        return (
            <>
                <div className="app-article-live-homepage-player">
                    <a href={`//appRadioArticleLivePlayer/`}>

                        <div className="radio-player-content">
                            <div className="radio-player-button">
                                <div className="radio-player-button-status" ></div>
                            </div>

                            <div className="radio-player-show-info-wrapper">
                                <div className="radio-player-show-info">
                                    <div className="radio-player-live-indication-rpf">{programItem.isProgramLive ? "LIVE" : ""}</div>
                                    {!programItem.hideHours && <img className="radio-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />}
                                    {!programItem.hideHours && <div className="radio-player-label-rpf">ynet radio</div>}
                                    <div className="radio-player-show-time-rpf">{this.getShowTime(programItem)}</div>
                                </div>

                                <div className="radio-player-show-title-rpf">{programItem.title || 'אין תוכנית לנגן'}</div>
                            </div>
                        </div>
                    </a>

                    <div className="radio-player-goto-btn-wrapper">
                        {buttonTitle &&
                            <div className="radio-player-goto-btn">
                                <a className="radio-player-goto-btn-link-rpf" href={buttonLink.link.publishedLink}>
                                    <div className="radio-player-goto-btn-title-rpf">{buttonTitle}</div>
                                    <div className="radio-player-goto-btn-arrow"></div>
                                </a>
                            </div>}
                    </div>
                </div>
            </>
        )
    }
}

const emptyProgramItem: RadioProgramItem = {
    title: "",
    startTime: "",
    endTime: "",
    isProgramLive: false,
    hideHours: false,
}