import { YnetTvSiteVideoData } from "../../interfaces"
import { getVideoTitle, addAdsCloseButton, getVideoSources, attachPageRefrashUtils, generateVideoId, getLogoUrl, isAboveTheFold } from "./videoPlayerUtils"

import { addMidrollAds, getImaSettings } from "./videoPlayerAds"
import { getYnetTvLogoUrl, addVideoInfoToFixList, addClassToPlayer, addYnetTvLogoToHeader } from "./ynetTvPlayerUtils"

interface SiteLightboxPlaylistVideoPlayerProps {
    data: YnetTvSiteVideoData
    allVideos: YnetTvSiteVideoData[]
    hideVideoAds: boolean
    onSelect?: () => void
}

export class SiteYnetTvLightboxPlaylistVideoPlayer extends React.Component<SiteLightboxPlaylistVideoPlayerProps, {}>{

    private posterId = generateVideoId();
    private playerContainer: HTMLDivElement;
    private flowplayerApi: any;

    public componentDidMount() {
        const { data, allVideos, hideVideoAds } = this.props;
        let startIndex;
        if (allVideos.indexOf(data) === -1) { startIndex = 0 }
        else { startIndex = allVideos.indexOf(data) }

        const playlist = allVideos.map(v => ({
            title: v.title,
            subTitle: v.subTitle,
            linkText: v.linkText,
            sources: getVideoSources(v.url, v.downGradeUrl),
        })
        )
        const container = this.playerContainer;

        const settings: any = {
            logo: getLogoUrl(),
            share: false,
            embed: false,
            overlay: `#${this.posterId}`,
            customPlaylist: true,
            playlist,
            startIndex,
        }
        if (hideVideoAds) {
            settings.ima = false;
        } else {
            settings.ima = getImaSettings(false, isAboveTheFold(this.playerContainer));
        }

        this.flowplayerApi = flowplayer(container, settings)
        if (!this.props.hideVideoAds) {
            addMidrollAds(this.flowplayerApi, false, isAboveTheFold(this.playerContainer));
        }
        attachPageRefrashUtils(this.flowplayerApi)
        this.flowplayerApi.on("load", () => fixPlayList(container, allVideos.map(v => v.poster)));
        this.flowplayerApi.on("load", (e, api) => {
            const index = api.video.index;
            const videoData = allVideos[index];
            if (videoData) {
                addVideoInfoToFixList(container, videoData);
            }
        })
        addYnetTvLogoToHeader(container);
        addClassToPlayer(container);
        addAdsCloseButton(container, this.flowplayerApi);
    }

    public render() {
        const { onSelect } = this.props
        const { poster } = this.props.data
        return (
            <div id={this.posterId} className="siteYnetTvLightBoxVideoPlayer" onClick={onSelect}>
                <img src={poster} className="poster" />
                <span className="videoIcon  bigInCenter"><span className="icon"></span></span>
                <div className="fp-edgy fp-mute" style={{ direction: "ltr" }} ref={r => this.playerContainer = r} >
                    <a className="fp-prev"></a>
                    <a className="fp-next"></a>
                </div>
            </div>
        )
    }
}

function fixPlayList(playerContainer: HTMLDivElement, posters: string[]) {
    const playList = playerContainer.querySelector(".fp-playlist");
    const anchors = playList.querySelectorAll("a");
    for (let i = 0; i < anchors.length; i++) {
        anchors[i].style.backgroundImage = `url(${posters[i]})`
    }
}