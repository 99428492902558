interface WhatsAppShareButtonProps {
    className?: string
    handleOnClick?: () => void
    url?: string
    iconSrc?: string
    isEnglish?: boolean
}

export class WhatsAppShareButton extends React.Component<WhatsAppShareButtonProps, {}>{
    static defaultProps = { url: "", iconSrc: "" }

    private getCurrentSrc = () => {
        const { iconSrc } = this.props
        let src: string;
        if (iconSrc !== "") {
            src = iconSrc
        }
        else { src = "" }
        return src;
    }

    static openWhatsAppShare = (url: string, analyticsCb?: () => void, content: string = "", current_article_id?: string, hashPart?: string) => {
        const hostname = window.location.hostname.replace("www.", "");
        const utmContent = content.trim() ? `&utm_content=${encodeURIComponent(content)}` : "";
        const utmTerm = `${current_article_id || window.articleId ? `utm_term=${current_article_id ? current_article_id : window.articleId}` : ""}`;
        url = `${url}?utm_source=${hostname}&utm_medium=Share&utm_campaign=whatsapp${utmTerm}${utmContent}`;
        url = hashPart ? url + "#" + hashPart : url;
        window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(url))
        analyticsCb && typeof analyticsCb === "function" && analyticsCb()
    }

    private handleShareBtnClicked = () => {
        const { handleOnClick } = this.props
        if (typeof window !== "undefined") {
            WhatsAppShareButton.openWhatsAppShare(window.location.href)
        }
        if (handleOnClick) { handleOnClick() }
    }

    render() {
        const { className, isEnglish } = this.props
        return <button title={isEnglish ? "Share on Whatsapp" : "שתפו בוואטסאפ"} aria-label={isEnglish ? "Share on Whatsapp" : "שתפו בוואטסאפ"} onClick={e => this.handleShareBtnClicked()}>
            <img src={this.getCurrentSrc()} alt={isEnglish ? "Share on Whatsapp" : "שתפו בוואטסאפ"} className={className} aria-hidden={true} />
        </button>
    }
}


