import { MobileMenuComponenta, menuItems } from "./mobileMenu";
import * as classNames from "classnames"


interface MobileMenuProps {
    isOpened: boolean
    items: menuItems[]
}


export class YnetNewsMenuComponenta extends React.Component<MobileMenuProps, {}> {

    render() {
        const { isOpened, items } = this.props
        let menuClassName = classNames("MobileMenu", { "opened": isOpened === true, "closed": isOpened === false })

        return (
            <div className={menuClassName} >
                <div className="head">
                    <span className="cp-big-h1">Channels</span>
                    <a id="INDmenu-btn3" className="follow"
                        accessKey="m" aria-labelledby="INDbtnTooltip" style={{ position: "absolute", top: "15px", left: "5%" }} >
                        <img src="//www.vesty.co.il/images/nagishot.png" style={{ width: "21px", height: "21px" }} />
                    </a>
                </div>
                < MobileMenuComponenta items={items} >
                    <li><div className="color"></div><a href="//www.ynetnews.com/home/0,7340,L-3089,00.html"><div className="icon">Breaking News
             <img src="/images/ynetnewsresp/mivzakim_menu_item.png" className="menu-img-item menu-img-size" /></div></a></li>
                </MobileMenuComponenta>

            </div>
        )
    }
}
