import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../../widgetsUtils"
import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { WhatsAppShareButton } from "../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"
require("./ynet_pplus_magazine_site_siteArticleSocialShare1280.less")

export class SiteArticleSocialShare1280 extends React.Component<{title?: string}, {}>{
    static siteScriptName = "SiteArticleSocialShare1280"

    private handleShareBtnClicked = (button: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Top", title), "Article Top")
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Top", title), "Article Top")
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, "he", () => analyticsPushFunc("Mail", "Article Top", title), "Article Top")
                    break;

                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url, () => analyticsPushFunc("Whatsapp", "Article Top", title), "Article Top")
                    break;

                case "print":
                    window.print();
                    break;

                case "tb":
                    const newCommentBtn = document.querySelector(".newCommentBtn") as HTMLElement
                    if (newCommentBtn) {
                        newCommentBtn.click();
                    }
                    const elementToScroll = document.querySelector('#addCommentSendButton') || document.querySelector('.spotimWrapper')

                    setTimeout(() => {
                        elementToScroll.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    }, 0)
                    break;

            }
        }
    }

    render() {
        const textMail = "שלחו כתבה"
        const textTw = "שיתוף בטוויטר"
        const textFb = "שיתוף בפייסבוק"
        const textWa = "שיתוף בואטסאפ"
        const textPrint = "הדפס"
        const textNewPost = "פרסם תגובה חדשה"

        return (
            <div className="SiteArticleSocialShare1280">
                <button title={textTw} className="shareIcon1280 tw" onClick={e => this.handleShareBtnClicked("tw")}></button>
                <button title={textFb} className="shareIcon1280 fb" onClick={e => this.handleShareBtnClicked("fb")}></button>
                <button title={textWa} className="shareIcon1280 wa" onClick={e => this.handleShareBtnClicked("wa")}></button>
                <button title={textPrint} className="shareIcon1280 print" onClick={e => this.handleShareBtnClicked("print")}></button>
                <button title={textMail} className="shareIcon1280 mailto" onClick={e => this.handleShareBtnClicked("mailto")}></button>
                <button title={textNewPost} className="shareIcon1280 tb" onClick={e => this.handleShareBtnClicked("tb")}></button>
            </div>
        )
    }
}