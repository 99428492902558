require("./../defenitions/article/componentas/articleComments/site_articleCommentsComponenta.less")
require("./../defenitions/article/componentas/articleComments/calcalist_magazine_yplus_site_articleCommentsComponenta.less")
require("../defenitions/article/componentas/articleComments/ynet1280Comments/app_ynet_yplus_pplus_articleComments.less")

import * as axios from "axios"
import * as uniqBy from "lodash/uniqBy"
import * as take from "lodash/take"
import { ArticleCommentData, SortingOptions, ArticleCommentsStorageState } from "../defenitions/article/componentas/articleComments/interfaces"
import { SiteWebStorage } from "../widgetsUtils"
import { AddCommentFormWrapper, AddCommentFormRender, AddCommentFormRenderProps } from "../defenitions/article/componentas/articleComments/addCommentFormWrapper"
import { AddCommentFormYnetUI } from "../defenitions/article/componentas/articleComments/addCommentFormYnetUI"
import { PremiumAddCommentFormUI } from "../defenitions/article/componentas/articleComments/premiumAddCommentFormUI"
import { ArticleCommentWrapper, ArticleCommentRender, ArticleCommentRenderProps } from "../defenitions/article/componentas/articleComments/articleCommentWrapper"
import { PremiumArticleCommentUI } from "../defenitions/article/componentas/articleComments/premiumArticleCommentUI"
import { ArticleCommentYnetUi } from "../defenitions/article/componentas/articleComments/articleCommentYnetUi"
import { ArticleCommentsTopTabWrapper, ArticleCommentsTopTabRender, ArticleCommentsTopTabRenderProps } from "../defenitions/article/componentas/articleComments/articleCommentsTopTabWrapper"
import { PremiumArticleCommentsTopTabUI } from "../defenitions/article/componentas/articleComments/premiumArticleCommentsTopTabUI"
import { ArticleCommentsTopTabYnetUi } from "../defenitions/article/componentas/articleComments/articleCommentsTopTabYnetUi"
import { addAxiosDateTransformerSettings } from "../../utils/network/axiosDateTransformer";
import { getTranslation } from "../defenitions/article/componentas/articleComments/translations"
import { Grid1280ArticleCommentsTopTabUI } from "../defenitions/article/componentas/articleComments/grid1280ArticleCommentsTopTabUi";
import { CalcalistArticleCommentsTopTabUI } from "../defenitions/article/componentas/articleComments/calcalistCommets/commentsTabUI"
import { CalcalistAddCommentFormUI } from "../defenitions/article/componentas/articleComments/calcalistCommets/calcalistAddCommentFormUI"
import { CalcalistArticleCommentUI } from "../defenitions/article/componentas/articleComments/calcalistCommets/calcalistArticleCommentUI"
import { ArticleCommentsTopTabUI1280 } from "../defenitions/article/componentas/articleComments/ynet1280Comments/ynetArticleCommentsTopTabUI1280";
import { AddCommentFormUI1280 } from "../defenitions/article/componentas/articleComments/ynet1280Comments/ynetAddCommentFormUI1280";
import { ArticleCommentUI1280 } from "../defenitions/article/componentas/articleComments/ynet1280Comments/ynetArticleCommentUI1280"
import { ArticleCommentsTopTabAppUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/ynetArticleCommentTopTabApp";
import { ArticleCommentAppUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/ynetArticleCommentAppUI";
import { ArticleCommentMobileWebUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/mutamArticleCommentAppUI";
import { ArticleCommentsTopTabMobileWebUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/mutamArticleCommentTopTabUI";
import { MutamArticleCommentsPopupUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/mutamArticleCommentsPopup";
import { MutamArticleCommentsPopupWrapper, MutamArticleCommentsPopupRenderProps, MutamArticleCommentsPopupRender } from "../defenitions/article/componentas/articleComments/mutamArticleCommentsPropsWrapper";
import { ArticleCommentsCalcalistTopTabMobileWebUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/mutamArticleCalcalistCommentTopTabUI"
import { ArticleCommentMobileCalcalistWebUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/mutamArticleCalcalistCommentAppUI";
import { MutamArticleCommentsCalcalistPopupUI } from "../defenitions/article/componentas/articleComments/ynet1280Comments/mutamArticleCommentsCalcalistPopup";

interface SiteArticleCommentsProps {
    articleId: string
    lang: string
    isLtr: boolean
    displayType: string
    isGrid1280?: boolean
    version?: number
    showFormWhenEmpty?: boolean
    isGptType: string
}

interface SiteArticleCommentsState {
    comments: ArticleCommentData[]
    numOfDiscussions: number
    numOfComments: number
    isAddCommentFormVisible: boolean
    areAllCommentsExpanded: boolean
    sortSelection: SortingOptions
    IdOfLastTalkbackExpandedByUser: number
    page: number
    hasMore: boolean
    isAllCommentsPopupVisable: boolean
    isPopupClose: boolean
    bodyOverflowState: string
}

export class SiteArticleComments extends React.Component<SiteArticleCommentsProps, SiteArticleCommentsState>{
    public static siteScriptName = "SiteArticleComments";
    public isWcmCommetnsRecived = false;
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            numOfDiscussions: 0,
            numOfComments: 0,
            isAddCommentFormVisible: false,
            areAllCommentsExpanded: false,
            sortSelection: "end_to_start",
            IdOfLastTalkbackExpandedByUser: null,
            page: 0,
            hasMore: false,
            isAllCommentsPopupVisable: false,
            isPopupClose: false,
            bodyOverflowState: ""
        }
    }

    componentDidMount() {
        const { articleId, showFormWhenEmpty } = this.props
        const articleCommentsState: ArticleCommentsStorageState = SiteWebStorage.getSessionStorageItem("articleCommentsState");
        if (articleCommentsState && (articleCommentsState.articleId === articleId)) {
            this.setState({
                areAllCommentsExpanded: articleCommentsState.areAllCommentsExpanded,
                sortSelection: articleCommentsState.sortSelection,
                IdOfLastTalkbackExpandedByUser: articleCommentsState.IdOfLastTalkbackExpandedByUser,
                page: 0,
            })
            this.getCommentsData(articleCommentsState.sortSelection);
        }
        else {
            this.getCommentsData();
        }

        if (typeof window !== "undefined") {
            window.handleCommentsPopupVisability = this.handleCommentsPopupVisibility
        }
    }

    private getTalkbackNumberView = (tbNumber: number) => {
        const stringNumber = tbNumber.toString()
        let number = stringNumber

        if (stringNumber.length >3) {
            number = stringNumber.charAt(0) + "k"
        } else if(stringNumber.length > 4) {
            number = stringNumber.charAt(0) + stringNumber.charAt(1) + "k"
        } else if (parseInt(stringNumber) === 0) {
            number = "";
        }

        return number
    }

    private updateArticleCommentsStorageState = (talkbackId = null, allCommentsExpanded?) => {
        const { articleId } = this.props
        const { areAllCommentsExpanded, sortSelection, IdOfLastTalkbackExpandedByUser, page, numOfComments } = this.state
        const plainArticleCommentsState: ArticleCommentsStorageState = {
            articleId,
            areAllCommentsExpanded: allCommentsExpanded || areAllCommentsExpanded,
            IdOfLastTalkbackExpandedByUser: talkbackId || IdOfLastTalkbackExpandedByUser,
            sortSelection,
        }
        SiteWebStorage.setSessionStorageItem("articleCommentsState", plainArticleCommentsState)
    }

    private getCommentsData = (sortBy: SortingOptions = "end_to_start", page: number = 0) => {
        const { articleId, showFormWhenEmpty } = this.props
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/iphone/json/api/talkbacks/list/${articleId}/${sortBy}/${page}`)
            .then((res: any) => {
                if (res.data.rss.channel && res.data.rss.channel.item) {
                    const comments: ArticleCommentData[] = res.data.rss.channel.item
                    const numOfDiscussions: number = res.data.rss.channel.sum_discussions;
                    const numOfComments: number = res.data.rss.channel.sum_talkbacks;
                    const hasMore: boolean = res.data.rss.channel.hasMore === 1;
                    this.setState((pState) => {
                        const newComments = uniqBy([...pState.comments, ...comments], 'id')
                        return {
                            comments: newComments,
                            numOfDiscussions,
                            numOfComments,
                            hasMore,
                        }
                    })
                }
                else {

                    this.setState({ isAddCommentFormVisible: this.props.showFormWhenEmpty })
                }
                window.wcmNumOfComments = this.getTalkbackNumberView(this.state.numOfComments)
                
                if(!this.isWcmCommetnsRecived){
                    document.dispatchEvent(new CustomEvent('onWcmCommetnsRecived',{ 
                        detail:{
                            wcmNumOfComments: window.wcmNumOfComments
                        }}));
                    this.isWcmCommetnsRecived = true;
                }
        })}

    private handleSortSelection = (e) => {
        const sortSelectionValue = e.target.value as SortingOptions
        this.getCommentsData(sortSelectionValue)
        this.setState({
            page: 0,
            comments: [],
            sortSelection: sortSelectionValue,
            areAllCommentsExpanded: (sortSelectionValue === "recommended" || sortSelectionValue === "interest") ? true : false
        }, () => this.updateArticleCommentsStorageState(null, false))
    }

    private expandAllComments = (shouldExpandComments: boolean) => {
        this.setState({ areAllCommentsExpanded: shouldExpandComments }, () => this.updateArticleCommentsStorageState())
    }

    private handleFormVisibility = () => {
        this.setState((pState: SiteArticleCommentsState) => {
            return { isAddCommentFormVisible: !pState.isAddCommentFormVisible }
        })
    }

    private handleCloseCommentForm = () => {
        this.setState({ isAddCommentFormVisible: false })
    }

    private handleCommentsPopupVisibility = () => {
        this.setState(state => ({ isAllCommentsPopupVisable: !state.isAllCommentsPopupVisable }), () => {
            if (this.state.isAllCommentsPopupVisable) {
                this.setState({
                    bodyOverflowState: document.querySelector("body").style.overflow,
                    isPopupClose: false
                });
                document.querySelector("body").style.overflow = "hidden";
                if (this.state.hasMore) {
                    this.loadMoreComments();
                }
            } else {
                this.setState({ isPopupClose: true });
                document.querySelector("body").style.overflow = this.state.bodyOverflowState;
            }
        });
    }

    private handleCommentsPopupClose = () => {
        const { sortSelection } = this.state  
        this.setState({ comments: [], page: 0 }, () => {
            this.getCommentsData(sortSelection, 0)
        })
        this.setState({  isAllCommentsPopupVisable: false, isPopupClose: true})
 
        document.querySelector("body").style.overflow = this.state.bodyOverflowState;
        this.handleCloseCommentForm();
      //  this.onCloseCommentsBtnPress();
    }

    private handlePaginationBtnPress = (step) => {
        const { page, sortSelection } = this.state
        const nextPage = page + step
        this.setState({ page: nextPage })
        this.getCommentsData(sortSelection, nextPage)
    }

    private loadMoreComments = (sortSelectionValue?: SortingOptions) => {
        const { page, sortSelection } = this.state;
        const nextPage = page + 1;
        this.setState({ page: nextPage }, () => {
            this.getCommentsData(sortSelectionValue ? sortSelectionValue : sortSelection, nextPage);
        });
        this.analyticsLoadMoreCommentsPushFunc()
    }

    private analyticsLoadMoreCommentsPushFunc = () => {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'comments_events',
                Category: 'Comments',
                Action: 'Watch More Comments'
            });
        }
    }

    private onCloseCommentsBtnPress = () => {
        const { sortSelection } = this.state
        this.setState({ comments: [], page: 0 }, () => {
            this.getCommentsData(sortSelection, 0)
            var elmnt = document.getElementById("SiteArticleComments");
            elmnt.scrollIntoView();
        })
    }

    render() {
        const { comments, numOfDiscussions, numOfComments, isAddCommentFormVisible, areAllCommentsExpanded,
            sortSelection, IdOfLastTalkbackExpandedByUser, page, hasMore, isAllCommentsPopupVisable, isPopupClose } = this.state
        const { articleId, lang, isLtr, displayType, isGrid1280, version, showFormWhenEmpty, isGptType } = this.props
        let EnglishSiteCss = isLtr ? "englishSite" : ""

        const isYnetPlus = displayType === "premium"
        const calcalist = displayType === "calcalist"
        const isFirmaDesign = displayType === "firma"
        const isFirmaApp = displayType === "firmaApp"
        const isFirmaMobileWeb = displayType === "firmaMobileWeb"
        const isCalcalistMobileWeb = displayType === "calclistMobileWeb"
        const isMobileWeb = displayType === "firmaMobileWeb" || displayType === "calclistMobileWeb";

        let AddCommentFormRender: AddCommentFormRender;
        let ArticleCommentRender: ArticleCommentRender;
        let ArticleCommentsTopTabRender: ArticleCommentsTopTabRender;
        let ArticleCommentsPopupRender: MutamArticleCommentsPopupRender;
        let limitComments = 0;

        if (isYnetPlus) {
            ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => isGrid1280 ? <Grid1280ArticleCommentsTopTabUI props={props} /> : <PremiumArticleCommentsTopTabUI props={props} />
            AddCommentFormRender = (props: AddCommentFormRenderProps) => <PremiumAddCommentFormUI props={props} />
            ArticleCommentRender = (props: ArticleCommentRenderProps) => <PremiumArticleCommentUI props={props} />
        }

        else if (calcalist) {
            ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => <CalcalistArticleCommentsTopTabUI props={props} />
            AddCommentFormRender = (props: AddCommentFormRenderProps) => <CalcalistAddCommentFormUI props={props} />
            ArticleCommentRender = (props: ArticleCommentRenderProps) => <CalcalistArticleCommentUI props={props} />
        }

        
        else if (isFirmaDesign) {
            AddCommentFormRender = (props: AddCommentFormRenderProps) => <AddCommentFormUI1280 props={props} />
            ArticleCommentRender = (props: ArticleCommentRenderProps) => <ArticleCommentUI1280 props={props} />
            ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => <ArticleCommentsTopTabUI1280 props={props} />
        }
        else if (isFirmaApp) {
            AddCommentFormRender = (props: AddCommentFormRenderProps) => null,
                ArticleCommentRender = (props: ArticleCommentRenderProps) => version > 2 ? <ArticleCommentAppUI props={props} /> : null,
                ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => version > 2 ? <ArticleCommentsTopTabAppUI props={props} /> : null
        }
        else if (isFirmaMobileWeb) {
            AddCommentFormRender = (props: AddCommentFormRenderProps) => null;
            ArticleCommentRender = (props: ArticleCommentRenderProps) => <ArticleCommentMobileWebUI props={props} />;
            ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => <ArticleCommentsTopTabMobileWebUI props={props} />;
            ArticleCommentsPopupRender = (props: MutamArticleCommentsPopupRenderProps) => <MutamArticleCommentsPopupUI props={props} />;
            limitComments = 3;
        }
        else if(isCalcalistMobileWeb){
            AddCommentFormRender = (props: AddCommentFormRenderProps) => null;
            ArticleCommentRender = (props: ArticleCommentRenderProps) => <ArticleCommentMobileCalcalistWebUI props={props} />;
            ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => <ArticleCommentsCalcalistTopTabMobileWebUI props={props} />;
            ArticleCommentsPopupRender = (props: MutamArticleCommentsPopupRenderProps) => <MutamArticleCommentsCalcalistPopupUI props={props} />;
          limitComments = 5;

        }
        else {
            AddCommentFormRender = (props: AddCommentFormRenderProps) => <AddCommentFormYnetUI props={props} />
            ArticleCommentRender = (props: ArticleCommentRenderProps) => <ArticleCommentYnetUi props={props} />
            ArticleCommentsTopTabRender = (props: ArticleCommentsTopTabRenderProps) => <ArticleCommentsTopTabYnetUi props={props} />
        }

        const commentsToShow = limitComments ? take(comments, limitComments) : comments;
        const showAds = (isFirmaApp && version > 2) || (isGrid1280 && isYnetPlus) || isFirmaMobileWeb;
        const script_display = isGptType && isGptType == "new" ? <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `googletag.cmd.push(function() { googletag.display("ads.box"); });` }}></script> : "";
        return (
            <div className={isFirmaApp ? "SiteAppArticleComments" : `SiteArticleComments ${EnglishSiteCss}`} id="SiteArticleComments">
                <ArticleCommentsTopTabWrapper
                    render={ArticleCommentsTopTabRender}
                    lang={lang}
                    isLtr={isLtr}
                    numOfComments={numOfComments}
                    numOfDiscussions={numOfDiscussions}
                    sortSelection={sortSelection}
                    areAllCommentsExpanded={areAllCommentsExpanded}
                    isAddCommentFormVisible={isAddCommentFormVisible}
                    expandAllComments={this.expandAllComments}
                    handleFormVisibility={this.handleFormVisibility}
                    handleSortSelection={this.handleSortSelection}
                    handleCommentsPopupVisibility={this.handleCommentsPopupVisibility}
                    handleCommentsPopupClose={this.handleCommentsPopupClose}
                />

                <AddCommentFormWrapper
                    render={AddCommentFormRender}
                    isAddCommentFormVisible={isAddCommentFormVisible}
                    lang={lang}
                    articleId={articleId}
                    formType={"NEW"}
                    handleCloseCommentForm={this.handleCloseCommentForm}
                    isFirstComment={showFormWhenEmpty && numOfComments === 0}
                />


                <div className="commentsContainer">
                    {commentsToShow.map((comment, i) =>
                        <div key={`comment_${comment.id}`}>
                            <ArticleCommentWrapper
                                render={ArticleCommentRender}
                                lang={lang}
                                IdOfLastTalkbackExpandedByUser={IdOfLastTalkbackExpandedByUser}
                                handleUpdateArticleCommentsStorageState={this.updateArticleCommentsStorageState}
                                isAutoExpanded={areAllCommentsExpanded}
                                articleId={articleId}
                                comment={comment}
                                isFirma={isFirmaDesign}
                                isFirmaMobileWeb={isFirmaMobileWeb}
                                handleCommentsPopupClose={this.handleCommentsPopupClose}
                                isAllCommentsPopupVisable={isAllCommentsPopupVisable}
                            />
                        </div>
                    )}
                </div>

                {isMobileWeb &&
                    <MutamArticleCommentsPopupWrapper
                        render={ArticleCommentsPopupRender}
                        lang={lang}
                        articleId={articleId}
                        comments={comments}
                    isFirmaMobileWeb={isMobileWeb}
                    isAddCommentFormVisible={isAddCommentFormVisible}
                        IdOfLastTalkbackExpandedByUser={IdOfLastTalkbackExpandedByUser}
                        updateArticleCommentsStorageState={this.updateArticleCommentsStorageState}
                        areAllCommentsExpanded={areAllCommentsExpanded}
                        numOfComments={numOfComments}
                        showFormWhenEmpty={showFormWhenEmpty}
                        handleCloseCommentForm={this.handleCloseCommentForm}
                        handleFormVisibility={this.handleFormVisibility}
                        isAllCommentsPopupVisable={isAllCommentsPopupVisable}
                        handleCommentsPopupClose={this.handleCommentsPopupClose}
                        handleCommentsPopupVisibility={this.handleCommentsPopupVisibility}
                    isPopupClose={isPopupClose}
                    loadMoreComments={this.loadMoreComments}
                    hasMore={hasMore}
                    />}

                {hasMore && isFirmaApp && version > 2 ? <a href={"go_to_all_comments"} className="showMoreCommentsButton-pf firmaApp">לכל התגובות</a> : null}
                {((!hasMore  && isMobileWeb) || hasMore ) && !isFirmaApp &&
                    <div className="showMoreCommentsButton" onClick={isMobileWeb ? ()=>this.handleCommentsPopupVisibility() : e => this.loadMoreComments()}>
                        {isCalcalistMobileWeb ? "לכל התגובות" : getTranslation(lang, "Load More Talkbacks")}
                    </div>}
                {showAds && <div id="ads.box" className="CommentsBox">{script_display}</div>}
                {!hasMore && page > 0 && !isMobileWeb && 
                    <div className="closeCommentsButton" onClick={this.onCloseCommentsBtnPress}>
                        {getTranslation(lang, "Close Talkbacks")}
                    </div>}
            </div>
        )
    }
}
