import { AudioPlayButton } from "../radioWidgets/floatingPlayer/audioPlayButton";

declare var window: Window & {
    radioWindow: Window
    YITSiteWidgets: any
    YITSiteWidgetsCallbacks: any
    wcmAudioPlayer: AudioPlayButton
    pageRefreshDisable(sourceType?: string): void;
    pageRefreshEnable(sourceType?: string): void;
}

interface DetachedPlayerRendererProps {
    openPopupPlayer: () => void
}

interface DetachedPlayerProps {
    playerWidth: string
    playerHeight: string
    playerName: string
    playerProps: any
    buttonRenderer: (props: DetachedPlayerRendererProps) => React.ReactElement<DetachedPlayerRendererProps>
}

export class DetachedPlayer extends React.Component<DetachedPlayerProps> {

    private newWin;

    private addScriptSrc = (src) => {
        const script = this.newWin.document.createElement('script');
        script.setAttribute('src', src);
        this.newWin.document.head.appendChild(script);
    }

    private addInlineScript = (innerHtml) => {
        const inlineScript = this.newWin.document.createElement('script');
        inlineScript.innerHTML = innerHtml
        this.newWin.document.body.appendChild(inlineScript);
    }

    private openPopupPlayer = () => {
        const { playerWidth, playerHeight, playerName, playerProps } = this.props

        if (window.radioWindow) window.radioWindow.close()

        this.newWin = window.open("", "hello", `innerWidth=${playerWidth}, innerHeight=${playerHeight}`);
        this.newWin.document.title = "Podcast Player";
        window.radioWindow = this.newWin;

        setTimeout(() => {
            if (this.newWin.YITSiteWidgets) return;

            /** add css and widgets list  based on environment*/
            const css = document.createElement('link')
            
            if (process.env.NODE_ENV === 'dev') { 
                const jsFile = (document.querySelector(`script[src*="/build/site/"]`) as any).src;
                const cssFile = (document.querySelector(`link[href*="/build/site/"]`) as any).href;

                this.addScriptSrc(jsFile);
                css.setAttribute('href', cssFile);
            } else {
                const jsFilesList = document.querySelectorAll(`script[src*="/Common/frontend/site"]`);
                const cssFilesList = document.querySelectorAll(`link[href*="/Common/frontend/site"]`);

                jsFilesList.forEach(element => { this.addScriptSrc((element as any).src); });
                cssFilesList.forEach(element => { css.setAttribute('href', (element as any).href); });
            }

            css.setAttribute('rel', 'stylesheet')
            this.newWin.document.head.appendChild(css);

            this.newWin.YITSiteWidgets = this.newWin.YITSiteWidgets || [];
            this.newWin.YITSiteWidgetsCallbacks = this.newWin.YITSiteWidgetsCallbacks || [];

            /** create div */
            const div = this.newWin.document.createElement('div');
            const divId = 'detachedPopupPlayer';
            div.setAttribute("id", divId)
            this.newWin.document.body.appendChild(div);

            /** create Widget */
            const widgetScript = [divId, playerName, playerProps]
            this.addInlineScript("window.isInit=true;")
            this.addInlineScript("window.YITSiteWidgets.push(" + JSON.stringify(widgetScript) + ");")
        }, 0)
    }

    render() {
        const { buttonRenderer } = this.props

        return buttonRenderer({ openPopupPlayer: this.openPopupPlayer })
    }
}