require("./site_blockedVideo.less")
import { SiteSettings, SiteSettingsHOC } from "global/components/siteSettingsHOC"
import { convertLanguageCodeToISO } from "../../../../config/utils";

interface AbroadBlockedProps {
    poster: string
}

@SiteSettingsHOC()
export class AbroadBlocked extends React.Component<AbroadBlockedProps & Partial<SiteSettings>, {}>{

    private getAlertText = () => {
        const { languageCode } = this.props
        const lang = convertLanguageCodeToISO(languageCode);
        let text = "";
        switch (lang) {
            case "he":
                text = "חסום"
                break;

            case "es":
                text = "Blocked"
                break;

            case "ru":
                text = "Blocked"
                break;

            case "en":
                text = "Blocked"
                break;

            default:
                text = "Blocked"
        }
        return text;
    }




    render() {
        const { poster, languageCode } = this.props
        return (
            <div className="blockedVideo" style={{ backgroundImage: `url(${poster})` }}><span>{this.getAlertText()}</span></div>
        )
    }
}

