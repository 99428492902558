import { HamburgerButtonComponenta } from "./hamburgerMenuList/hamburgerButton";
import { SearchButtonComponenta } from "./searchInput/openSearchButton";
import { MenuListComponenta, HamburgerMenuItems } from "./hamburgerMenuList/menuList"
import { CalcalistSearchComponenta } from "./searchInput/searchArea";

require('././hamburgerMenuList/ctech_calcalist_site_menu.less')
require('./searchInput/ctech_calcalist_site_search_area.less')
interface HamburgerProps {
    menuItems: HamburgerMenuItems[]
    width: number
    domain:string
}

interface HamburgerState {
    isOpenedHam: boolean
    isOpenedSearch: boolean
}
export class LeftSideMenuComponenta extends React.Component<HamburgerProps, HamburgerState> {
    public static siteScriptName = "LeftSideMenuComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpenedHam: false,
            isOpenedSearch: false
        }
    }

    private onToggleHam = () => {
        this.setState({ isOpenedHam: !this.state.isOpenedHam, isOpenedSearch: false })
    }
    private onToggleSearch = () => {
        this.setState({ isOpenedSearch: !this.state.isOpenedSearch, isOpenedHam: false })
    }
    render() {
        const { menuItems, width , domain} = this.props
        return (

            <div className="LeftSideMenu no-print">
                <SearchButtonComponenta onToggleSearch={this.onToggleSearch} isOpened={this.state.isOpenedSearch} />
                <HamburgerButtonComponenta onToggleMenu={this.onToggleHam} isOpened={this.state.isOpenedHam} />
                <MenuListComponenta menuItems={menuItems} width={width} isOpenedHam={this.state.isOpenedHam} />
                {this.state.isOpenedSearch && <CalcalistSearchComponenta width={width} domain={domain} />}
            </div>
        )


    }
}