import { secondsToHms } from "../../../siteWidgets/siteWidgetUtils"
import { AudioPlayButton } from "../floatingPlayer/audioPlayButton"

interface PlaybackProgressBarProps {
    durationSeconds: number
    playbackTime: number
    onSeeked: (num: number) => void
    toLive: () => void
    isDisabled: boolean
}

declare var window: Window & {
    wcmAudioPlayer: AudioPlayButton
}

export class PlaybackProgressBar extends React.Component<PlaybackProgressBarProps> {

    private updateCurrentTime = (e) => {
        this.props.onSeeked(Number(e.target.value));
    }

    private onStartOverClick = () => {
        this.props.onSeeked(0)
        window.wcmAudioPlayer.play()
        document.dispatchEvent(new CustomEvent("dvrRadioPlayerToPlayBack"));
    }

    render() {
        const { durationSeconds, playbackTime, toLive, isDisabled } = this.props
        const bubbleElement = document.querySelector(".range-bubble");
        const playbackTimeString = secondsToHms(playbackTime)
        const bubbleValue = playbackTimeString

        const percentage = (this.props.playbackTime - 0) * 100 / (this.props.durationSeconds - 0);
        const bubbleWidth = bubbleElement && (bubbleElement.clientWidth / 2)
        const bubbleMovment = percentage < 25 ? bubbleWidth - 5 : percentage > 85 ? bubbleWidth + 5 : bubbleWidth;
        const bubbleLeftMovment = `calc(${percentage}% - ${bubbleMovment}px)`;

        const rangeBackground = `linear-gradient(90deg, #de1a1a ${percentage}%, #cacdce ${percentage}%)`;

        return (
            <>
                <div className="control-ruler-buttons">
                    <button className={`go-live-btn ruler-btn ${isDisabled ? " disabled" : ""}`} onClick={toLive} aria-label="go to live" disabled={isDisabled}></button>
                    <button className={`start-over-btn ruler-btn ${isDisabled ? " disabled" : ""}`} onClick={this.onStartOverClick} aria-label="start program over" disabled={isDisabled}></button>
                </div>

                <div className="control-ruler-timeline">
                    <div className="range-wrap">
                        <div className="range-bubble" id="range-bubble" style={{ left: bubbleLeftMovment }}>{bubbleValue}</div>
                        <input type="range" className="dvr-timeline" max={durationSeconds ? durationSeconds : playbackTime} value={playbackTime} onChange={this.updateCurrentTime} style={{ background: rangeBackground }} disabled={isDisabled} />
                    </div>
                    <div className="live-feedback disabled">
                        <div className="dot disabled"></div>
                        <div className="live-text">שידור חי</div>
                    </div>
                </div >
            </>
        )
    }
}