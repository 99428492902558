import * as Urls from "config/apiUrls"
import { AppService } from "../utils/serviceLocator"
import { TreeNodeId } from "pagesPage/components/categoryTree/categoryTree"
import { CategoryTreeType } from "pagesPage/stateInterfaces"
import * as routes from "config/routePathes"
import { getCurrentPathName } from "./store/selectors";

export const setWindowExitListener = (getService: (serviceId: AppService) => any) => {
    window.addEventListener('unload', (e) => {
        const currentPath = window.location.pathname;
        const pathMatcher = currentPath.match(/\/(pages|articles)\/([^\/]+)$/)
        if (pathMatcher) {
            let url;
            switch (pathMatcher[1]) {
                case "articles":
                    const articleId = pathMatcher[2];
                    url = Urls.releaseArticleLock(articleId);
                    break;
                case "pages":
                    const pageId = pathMatcher[2];
                    url = Urls.releaseMyLocksInPage(pageId);
                    break;
            }
            if (url) {
                // var request = new XMLHttpRequest();
                // request.open('POST', url, false);  // `false` makes the request synchronous
                // request.send(null);
                //-------------------------
                var xhr = new XMLHttpRequest();
                xhr.open("POST", url, false);
                xhr.setRequestHeader('Authorization', SERVER_USER_AUTHORIZATION);
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                const data = { lock: false }
                xhr.send(JSON.stringify(data));
            }
        }
    });
}


export const getNodeIdFromPath = (path: string): TreeNodeId => {
    const matchFunctions: { type: CategoryTreeType | "article", match: (string) => any }[] = [
        { type: "article-template", match: routes.matchArticleTemplateDetailsPath },
        { type: "waiting-category", match: routes.matchCategoryPath },
        { type: "category", match: routes.matchCategoryPath },
        { type: "page", match: routes.matchPagePath },
        { type: "article", match: routes.matchArticlePath }
    ]
    for (const matchFunction of matchFunctions) {
        const result = matchFunction.match(path);
        if (result) {
            const resultId = _.values(result)[0].toString()
            return {
                type: matchFunction.type,
                id: resultId
            }
        }
    }
}

export const convertHtmlToString = (text: string) => {
    const ltrDiv = '<div style="direction: ltr;"></div>'
    const ltrDivRegex = new RegExp(ltrDiv, 'g')
    return text ? text
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(ltrDivRegex, '') : ""
}



export const convertLanguageCodeToISO = (languageCode: string) => {
    return languageCode && languageCode.slice(0, 2) || "en"
}

export const isEntityActive = (reduxState, type, id) => {
    const path = getCurrentPathName(reduxState);
    const match = getNodeIdFromPath(path)
    return match && match.type == type && match.id === id
}

