import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { HamburgerMenuItems, BottomLinksItems, CtechMenuList } from "./ctechMenuList"

interface SiteMobileCtechHeaderProps {
    menuItems: HamburgerMenuItems[]
    bottomLinksItems: BottomLinksItems[]
    generalLinksItems: BottomLinksItems[]
    categoryId: string
    categoryName: string
}
interface SiteMobileCtechHeaderState {
    isOpenedHam: boolean
    isOpenedSearch: boolean
    prevScrollPos: number
}
export class SiteMobileCtechHeaderComponenta extends React.Component<SiteMobileCtechHeaderProps, SiteMobileCtechHeaderState> {
    public static siteScriptName = "SiteMobileCtechHeaderComponenta";
    constructor(props) {
        super(props)
        this.state = {
            isOpenedHam: false,
            isOpenedSearch: false,
            prevScrollPos: 0
        };
    }
    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }
    toggleBodyScroll = () => {
        if (this.state.isOpenedHam) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }

    handleScroll = () => {
        const { prevScrollPos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const el = document.querySelector(".MobileCtechHeaderComponenta")
        if (currentScrollPos <= 43) {
            this.setState({ prevScrollPos: currentScrollPos })
            el.classList.remove("fadeHeader");
        } else if (prevScrollPos < currentScrollPos) {
            this.setState({ prevScrollPos: currentScrollPos })
            el.classList.add("fadeHeader");
        } else {
            this.setState({ prevScrollPos: currentScrollPos })
            el.classList.remove("fadeHeader");
        }
    }
    private onToggleHam = () => {
        this.setState({ isOpenedHam: !this.state.isOpenedHam, isOpenedSearch: false }, this.toggleBodyScroll)
    }
    render() {
        const { menuItems, bottomLinksItems, categoryId, categoryName, generalLinksItems } = this.props
        const { isOpenedHam } = this.state
        const isHomePage = categoryId === "5211"
        const leftLogoLink = isHomePage ? generalLinksItems.filter(link => link.name === 'twentyFourSeven')[0]['url'] : generalLinksItems.filter(link => link.name === 'logo')[0]['url']
        const mainLogoLink = isHomePage ? generalLinksItems.filter(link => link.name === 'logo')[0]['url'] : "javascript:void(0);"

        const blurScreenStyle = {
            display: 'block',
            position: 'absolute',
            top: '43px',
            width: '100%',
            height: '100vh',
            left: 0,
            backdropFilter: 'blur(1.5rem) contrast(100%)',
            WebkitBackdropFilter: 'blur(1.5rem) contrast(100%)'
        }

        return (
            <div className="SiteMobileCtechHeaderComponenta">
                <SimpleLinkRenderer href={leftLogoLink} className="leftLogo">
                    {isHomePage ?
                        <span className="logoTitle">24/7</span> :
                        <div className="logoImg"></div>}
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href={mainLogoLink} className="mainLogo">
                    {isHomePage ?
                        <div className="logoImg"></div> :
                        <span className="logoTitle">{categoryName}</span>}
                </SimpleLinkRenderer>

                <button className="mobilHamburger" onClick={this.onToggleHam}></button>
                <div className="blurScreen" style={isOpenedHam ? blurScreenStyle : {}} />
                <CtechMenuList menuItems={menuItems} isOpenedHam={isOpenedHam} bottomLinksItems={bottomLinksItems} />
            </div>
        )

    }
}