import { stockLinkInlineInfo } from "./stockLinkInlineInfo"
import * as axios from "axios"
import * as map from "lodash/map"
import * as forEach from "lodash/forEach"

const getStock = (id: string) => `/Ext/I-Invest/StockInfo/CmmApiIinvest_getStockInfoJson/0,16471,${id},00.html`

interface StockData {
    name: string
    id: string
    url: string
    type: string
    lastShaar: string
    dailyChgPercentage: number
    dailyChgMoney: number
}

interface SiteStockLinksInjectorProps {
    stocksIds: string[]
}

export class SiteStockLinksInjector extends React.Component<SiteStockLinksInjectorProps, {}> {
    public static siteScriptName = "SiteStockLinksInjector";

    private loadStockLinksData = async () => {
        const { stocksIds } = this.props;
        const stocksPromises = map(stocksIds, async stockId => {
            const stock = await axios.get(getStock(stockId));
            return stock.data[0] as StockData;
        })

        return await Promise.all(stocksPromises);
    }

    componentDidMount() {

        const stockList = this.loadStockLinksData()
        if (stockList) {
            stockList.then(l =>
                forEach(l, (stockData, i) => {
                    let stockLinkElements = document.getElementsByClassName("stockLink") as HTMLCollectionOf<HTMLAnchorElement>;
                    if (!stockData) return
                    const url = stockData.url
                    stockLinkElements[i].href = url;
                    let stockInfo = document.createElement("span");
                    stockInfo.className = "stockLinkInlineInfoWrapper"
                    stockInfo.innerHTML = stockLinkInlineInfo(stockData)
                    stockLinkElements[i].insertAdjacentElement("afterend", stockInfo)
                })
            )
        }
    }

    public render() {
        return null;
    }
}
