import { registerDataClass } from "widgets/dataObjectFacrories"
import { DataObjectBase } from "widgets/commonDataObjects/dataObjectBase"
import { LinkTargetProperties, LinkProperties, BaseLinkTypes } from "global/components/linkSettings/interfaces"
import { LinkedPageResource } from "widgets/interfaces"
import { getLinkResource, getNewLinkProperties } from "widgets/widgetsUtils"
import { LinkedItemVideoSettings } from "widgets/commonDataObjects/linkedItemsVideoSettings"
import { VideoBehaiforData } from "widgets/commonDataObjects/interfaces"



@registerDataClass
export class YnetshopsData extends DataObjectBase {

    get rightTitleText(): string {
        return this.data.rightTitleText;
    }
    setRightTitleText(rightTitleText: string) {
        return this.setData({ rightTitleText })
    }

    get rightTitleLink(): LinkProperties<BaseLinkTypes> {
        return this.data.rightTitleLink;
    }
    setRightTitleLink(rightTitleLink: LinkProperties<BaseLinkTypes>) {
        return this.setData({ rightTitleLink }).setRightTitleLinkTarget(getNewlinkTargetProperties());
    }

    get rightTitleLinkTarget(): LinkTargetProperties {
        return this.data.rightTitleLinkTarget;
    }
    setRightTitleLinkTarget(rightTitleLinkTarget: LinkTargetProperties) {
        return this.setData({ rightTitleLinkTarget });
    }



    get tabTitleColor(): string {
        return this.data.tabTitleColor;
    }

    setTabTitleColor(tabTitleColor: string) {
        return this.setData({ tabTitleColor })
    }

    get tabBackgroundColor(): string {
        return this.data.tabBackgroundColor;
    }

    setTabBackgroundColor(tabBackgroundColor: string) {
        return this.setData({ tabBackgroundColor, tabBackgroundImageUrl: "" })
    }

    get leftTitleText(): string {
        return this.data.leftTitleText;
    }

    setLeftTitleText(leftTitleText: string) {
        return this.setData({ leftTitleText })
    }

    get leftTitleLink(): LinkProperties<BaseLinkTypes> {
        return this.data.leftTitleLink;
    }

    setLeftTitleLink(leftTitleLink: LinkProperties<BaseLinkTypes>) {
        return this.setData({ leftTitleLink })
    }



    get leftTitleLinkTarget(): LinkTargetProperties {
        return this.data.leftTitleLinkTarget;
    }

    setLeftTitleLinkTarget(leftTitleLinkTarget: LinkTargetProperties) {
        return this.setData({ leftTitleLinkTarget })
    }
    get videoSettings(): LinkedItemVideoSettings {
        return this.data.videoSettings
    }
    setVideoSettings(videoSettings: LinkedItemVideoSettings) {
        return this.setData({ videoSettings })
    }

    get videoBehaivior(): VideoBehaiforData {
        return this.data.videoBehaivior
    }
    setVideoBehaivior(videoBehaivior: VideoBehaiforData) {
        return this.setData({ videoBehaivior })
    }

    get isDfp(): boolean {
        return this.data.isDfp
    }
    setIsDfp(isDfp: boolean) {
        return this.setData({ isDfp })
    }

    get dfpId(): string {
        return this.data.dfpId
    }

    setDfpId(dfpId: string) {
        return this.setData({ dfpId })
    }
    protected GetLinkedResourcesForCurrent() {
        const resources: LinkedPageResource[] = [
            getLinkResource(this.leftTitleLink),
            getLinkResource(this.rightTitleLink),
        ];
        return _.filter(resources); //remove links that are not resources
    }

    protected createNewInstance(data) {
        return new YnetshopsData(data) as this;
    }
    protected getDefaultData() {
        const tabBackgroundColor: string = "#556775"
        const tabTitleColor: string = "#ffffff"

        const leftTitleText: string = ""
        const leftTitleLink: LinkProperties<BaseLinkTypes> = getNewLinkProperties()
        const leftTitleLinkTarget: LinkTargetProperties = getNewlinkTargetProperties()

        const rightTitleText: string = ""
        const rightTitleLink: LinkProperties<BaseLinkTypes> = getNewLinkProperties()
        const rightTitleLinkTarget: LinkTargetProperties = getNewlinkTargetProperties()

        return {
            tabTitleColor,
            tabBackgroundColor,

            rightTitleText,
            rightTitleLink,
            rightTitleLinkTarget,

            leftTitleText,
            leftTitleLink,
            leftTitleLinkTarget,
            videoSettings: new LinkedItemVideoSettings,
            videoBehaivior: {
                isAutoPlaying: false,
                isLoopPlaying: false,
                isPlayerBarVisible: true,
                isSoundButtonVisible: true,
                isMuteAvailable: true
            },
            isDfp: false,
            dfpId: ""
        }
    }
}
export const getNewlinkTargetProperties = (): LinkTargetProperties => ({
    linkOpenType: "new",
    lightBoxWidth: 803,
    lightBoxHeight: 700,
    lightBoxOpacity: 70,
    lightBoxBgColor: "#000000",
})
