import * as axios from "axios"
import * as classNames from "classnames"
import { SiteArticleData } from "./ctechArticleItem"
import { CtechArticleList } from "./ctechArticleList"
import { CtechDatePickerMenu } from "./ctechDatePickerMenu"
import { CtechChannelMenu } from "./ctechChannelMenu"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils"

interface CtechWideBuzzState {
    currChannle: any
    isChannelMenuVisible: boolean
    isDateMenuVisible: boolean
    currPage: number
    daysToShow: number
    limitDate: string
    articles: SiteArticleData[]
    isLoadMore: boolean
    videoOnly: boolean
    wordsTranslations: any
}
interface CtechWideBuzzProps {
    categoryList: CategoryListItem[]
    numberOfItems: number
    isLTR: boolean
    componentaId: string
}
export interface CategoryListItem {
    description: string
    id: string
}
export class SiteCtechWideBuzzComponenta extends React.Component<CtechWideBuzzProps, CtechWideBuzzState> {
    public static siteScriptName = "SiteCtechWideBuzzComponenta"
    constructor(props) {
        super(props)
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            currChannle: { description: '', id: '' },
            isChannelMenuVisible: false,
            isDateMenuVisible: false,
            currPage: 0,
            daysToShow: 1,
            limitDate: '',
            articles: [],
            isLoadMore: false,
            videoOnly: false,
            wordsTranslations: {
                "he": {
                    "Load more": "טען עוד",
                    "Buzz": "באזז",
                    "Daily": "היום",
                    "Weekly": "השבוע",
                    "Monthly": "החודש",
                    "All Times": "כל הזמנים",
                    "Most Popular": "הנצפות",
                    "Articles": "כתבות",
                    "Video": "וידאו",
                    "No results": "אין תוצאות"
                },
                "en": {
                    "Load more": "Load more",
                    "Buzz": "Buzz",
                    "Daily": "Daily",
                    "Weekly": "Weekly",
                    "Monthly": "Monthly",
                    "All Times": "All Times",
                    "Most Popular": "Most Popular",
                    "Articles": "Articles",
                    "Video": "Video",
                    "No results": "No results"
                }

            }
        }
    }
    componentDidMount() {
        const channel = this.props.categoryList[0]
        this.setState({ currChannle: channel })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.limitDate !== prevState.limitDate ||
            this.state.daysToShow !== prevState.daysToShow ||
            this.state.currPage !== prevState.currPage ||
            this.state.videoOnly !== prevState.videoOnly ||
            this.state.currChannle !== prevState.currChannle) {
            this.getArticles();
        }
        if (this.state.articles !== prevState.articles) {
            this.setState({
                isLoadMore: (this.state.articles.length) / ((this.state.currPage + 1) * this.props.numberOfItems) >= 1
            })
        }


    }
    private onChannelChanged = (newChannle) => {
        this.toggleChannelMenu()
        this.setState({
            currChannle: newChannle,
            currPage: 0
        })
    }
    private toggleChannelMenu = () => {
        this.setState({
            isChannelMenuVisible: !this.state.isChannelMenuVisible,
            isDateMenuVisible: (this.state.isDateMenuVisible && !this.state.isChannelMenuVisible) ? false : this.state.isDateMenuVisible
        })
    }
    private toggleDateMenu = () => {
        this.setState({
            isDateMenuVisible: !this.state.isDateMenuVisible,
            isChannelMenuVisible: (this.state.isChannelMenuVisible && !this.state.isDateMenuVisible) ? false : this.state.isChannelMenuVisible
        })

    }
    private onDayToShowTypeChanged = (newDaysToShow) => {
        this.setState({
            daysToShow: newDaysToShow,
            limitDate: "",
            currPage: 0
        })
    }
    private onTypeToShowChanged = (isVideo) => {
        this.setState({
            currPage: 0,
            videoOnly: isVideo
        })
    }
    private handleDayClick = (day, modifiers = {}) => {
        if (modifiers['disabled']) {
            return;
        } else {
            this.setState({
                limitDate: day ? day : '',
                daysToShow: 0,
                currPage: 0
            });
            this.toggleDateMenu();
        }
    }
    private loadMoreArticles = () => {
        this.setState({ currPage: this.state.currPage + 1 })
    }
    private getArticles = () => {
        const { componentaId } = this.props
        const { currChannle, currPage, daysToShow, limitDate, articles, videoOnly } = this.state
        axios.get(`/iphone/json/api/calcalist_buzz_wide/${componentaId}/${currChannle.id}/${limitDate ? '-' : daysToShow}/${videoOnly ? 1 : 0}/${limitDate ? formatDate(new Date(limitDate),"DD-MM-YYYY") : 0}/${currPage}`)
            .then((res: any) => {
                const recivedArticles = res.data.data;
                this.setState({
                    articles: currPage > 0 ? [...articles, ...recivedArticles] : recivedArticles
                })
            }).catch(err => console.error("error geting articles data:", err))
    }
    private getTranslation = (lang, text: string) => {
        let obj = this.state.wordsTranslations[lang]
        let textToShow = obj[text]
        return textToShow
    }
    render() {
        const { categoryList, isLTR } = this.props
        const { currChannle, isChannelMenuVisible, limitDate, daysToShow, articles, isDateMenuVisible, isLoadMore, videoOnly } = this.state
        const daysToShowTypes = [
            { title: this.getTranslation(isLTR ? "en" : "he", "Daily"), value: 1 },
            { title: this.getTranslation(isLTR ? "en" : "he", "Weekly"), value: 7 },
            { title: this.getTranslation(isLTR ? "en" : "he", "Monthly"), value: 30 },
            { title: this.getTranslation(isLTR ? "en" : "he", "All Times"), value: 0 }
        ]
        const typesToShow = [
            { title: this.getTranslation(isLTR ? "en" : "he", "Articles"), value: false },
            { title: this.getTranslation(isLTR ? "en" : "he", "Video"), value: true }
        ]
        const dayPickerPreferences = {
            showOutsideDays: true,
            month: new Date(),
            fromMonth: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            toMonth: new Date(),
            fixedWeeks: true,
            disabledDays: [
                {
                    after: new Date(),
                    before: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                }
            ]
        }
        return (

            <div className="CtechWideBuzzComponenta">
                <div className="filters">
                    <CtechChannelMenu toggleChannelMenu={this.toggleChannelMenu} currChannle={currChannle}
                        isChannelMenuVisible={isChannelMenuVisible} categoryList={categoryList} onChannelChanged={this.onChannelChanged} isLTR={isLTR} />
                    <CtechDatePickerMenu toggleDateMenu={this.toggleDateMenu} limitDate={limitDate}
                        isDateMenuVisible={isDateMenuVisible} handleDayClick={this.handleDayClick}
                        dayPickerPreferences={dayPickerPreferences} isLTR={isLTR} />
                    {!isLTR && <div className="typesToShowFilter">
                        {_.map(typesToShow, (type, i) => {
                            return (<span onClick={() => this.onTypeToShowChanged(type.value)} key={i}
                                className={classNames("filterItem", { 'active': (videoOnly === type.value) })}>{type.title}</span>)
                        })}
                    </div>}
                </div>

                <div className="buzzMain">
                    <div className="mostPopularTitle"><span>{this.getTranslation(isLTR ? "en" : "he", "Most Popular")}</span></div>
                    <div className="daysToShowFilter">
                        {_.map(daysToShowTypes, (type, i) => {
                            return (<span onClick={() => this.onDayToShowTypeChanged(type.value)} key={i}
                                className={classNames("filterItem", { 'active': (daysToShow === type.value && limitDate === "") })}>{type.title}</span>)
                        })}
                    </div>
                    {(articles.length > 0) ?
                        (< CtechArticleList itemList={articles} isLTR={isLTR} />) :
                        <div className="noResultsMsg">{this.getTranslation(isLTR ? "en" : "he", "No results")}</div>
                    } 
                    <div className="loadMore">
                        <button className="loadMoreButton" disabled={!isLoadMore || articles.length === 0} onClick={this.loadMoreArticles}>{this.getTranslation(isLTR ? "en" : "he", "Load more")}</button>
                    </div>
                </div>
            </div>

        )

    }
}