require("./calcalist_site_articleImageSlider.less")
import Slider from "react-slick"
import { imageListGalleryCalcalist } from "./articleImageSliderEditor";

interface ArticleImageSliderProps {
    imageList: imageListGalleryCalcalist[]
}



function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", height: "40px", width: "40px", content: "<", background: "#b3b3b3" }}
            onClick={onClick}
        />
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", height: "40px", width: "40px", content: ">", background: "#b3b3b3" }}
            onClick={onClick}
        />
    );
}






export class ArticleImageSlider extends React.Component<ArticleImageSliderProps, {}> {
    public static siteScriptName = "ArticleImageSlider"
    public render() {
        const { imageList } = this.props;


        let settings = {

            infinite: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            arrows: true,
            rtl: false,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />



        }
        return (
            <div className="article-image-slider" style={{ width: '700px' }}>
                <Slider {...settings}   >
                    {imageList.map((image, i) =>
                        <div key={i}>
                            <img src={image.imageSrc} style={{ width: '590px', height: '320px' }} />
                            <div className="slider-credit-caption">
                                <div className="slider-image-caption">{image.caption}</div>
                                <div className="slider-image-credit">({image.credit})</div>
                            </div>
                        </div>
                    )}
                </Slider>
            </div>
        )
    }
}