import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { calculateAutomaticComponentaUrl, getImgAlt, isAriaHidden, getImgTitle } from "../../../../widgetsUtils";
import * as apiUrls from "../../../../../config/apiUrls"
import { CalcalistDetachedPlayer } from "../../calcalistPodcastPopupPlayer/calcalistDetachedPlayer"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils";

interface PodcastArchiveItemProps {
    item: Partial<ArticleExtraData>
    spotifyLink: string
    applePodcastLink: string
}

export class PodcastArchiveItem extends React.Component<PodcastArchiveItemProps, {}>{

    public render() {
        const { item } = this.props;
        const { title, dateUpdatedOnSite, promotionImageDetails, author, category, specialData } = item
        const { audioUrl, durationSeconds, imageUrl } = item.specialData.audioData

        return (
            <div className="slot-view" >
                <a className="slot-category" href={category.publishedLink}>{category.textToShow}</a>
                <div className="media-area">
                    <div className="media-carousel" >
                        <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 270, h: 147 })}
                            style={{ width: 270, height: 147 }}
                            className="site-image-media"
                            alt={getImgAlt(promotionImageDetails)}
                            aria-hidden={isAriaHidden(promotionImageDetails)}
                            title={getImgTitle(promotionImageDetails)}
                        />

                        <CalcalistDetachedPlayer
                            audioUrl={audioUrl}
                            title={title}
                            durationSeconds={durationSeconds}
                            podcastImage={imageUrl}
                            classNames={"podcast-btn"}
                        />

                        <div className="podcast-icon" />
                    </div>
                </div>

                <a href={calculateAutomaticComponentaUrl(item)} className="slot-title">
                    <span>{title}</span>
                </a>

                <a href={calculateAutomaticComponentaUrl(item)} className="more-details">
                    <span className="author-info">{specialData.audioData.alternatePresenterName}</span>
                    <span className="date-view">{formatDate(dateUpdatedOnSite, "DD.MM.YYYY")}</span>
                </a>
            </div>
        )
    }
}