import * as axios from "axios"
import { TickerViewData } from "../tickerAuto/interfaces";
import { MobileTickerAutoView } from "./mobileTickerAutoView";
import { addAxiosDateTransformerSettings } from "../../../utils/network/axiosDateTransformer";

interface SiteTickerMobileAutoComponentaProps {
    data: TickerViewData
    componentaId: string
}

interface SiteTickerMobileAutoComponentaState {
    articles: any
}

export class SiteTickerMobileAutoComponenta extends React.Component<SiteTickerMobileAutoComponentaProps, SiteTickerMobileAutoComponentaState>{
    public static siteScriptName = "SiteTickerMobileAutoComponenta"

    constructor(props) {
        super(props)
        this.state = {
            articles: []
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setArticles(newProps.data)
        }
    }

    componentDidMount() {
        const { data } = this.props;
        if (data.category.link.id) {
            this.setArticles(data)
            setInterval(() => {
                this.setArticles(data)
            }, 60000);
        }
    }

    private setArticles(data) {
        const { componentaId } = this.props
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        axiosInstance.get(`/iphone/json/api/auto_ticker/${componentaId}`)
            .then((res: any) => {
                this.setState({ articles: res.data.data });
            })
            .catch(err => console.error("error geting ticker data:", err))
    }


    render() {
        const { data } = this.props
        const { articles } = this.state
        return (
            <MobileTickerAutoView data={data} articles={articles} />
        )
    }
}