import { openFacebookShare, analyticsPushFunc, openWhatsAppShare, openTwitterShare, openMailShare } from "../../../widgetsUtils";
import { FontSlider } from "../../ynet1280/ynetHeader/components/mutamComponents/fontSliderComponent";

interface ArticleMutamHeaderShareProps{
    url:string 
    title:string 
    showComments:boolean
}
interface ArticleMutamHeaderShareState{
    isFontMenuOpen:boolean
}
export class ArticleMutamHeaderShareComponent extends React.Component<ArticleMutamHeaderShareProps, ArticleMutamHeaderShareState>{
    public static siteScriptName = "ArticleMutamHeaderShareComponent";
    constructor(props) {
        super(props)
        this.state = {
            isFontMenuOpen: false
        }
    }
    componentDidMount() { 
    this.setfontSize();
    }

    private setfontSize = () => {
        if (localStorage && localStorage.fontSize) {
            let size = localStorage.fontSize;
            document.documentElement.style.fontSize = size * parseInt(getComputedStyle(document.documentElement).fontSize) + "px";
        }

    }
    private handleFontSizePopupClose = () => {   
             this.setState({ isFontMenuOpen: false })     
       
    }
    private handleFontSizePopupVisability =(isFontMenuOpen) =>{
     if(isFontMenuOpen) return;
     else{
        this.setState({ isFontMenuOpen: true }) 
     }
          
    }


    render() {
       const {title, url,  showComments} = this.props
       const {isFontMenuOpen}= this.state 
        return (
            <div className={`articleShareButtons-calcalist`} id="share-icons-wrapper" >      
                {isFontMenuOpen && <FontSlider onClose={this.handleFontSizePopupClose} lang="he" />}
               <div className="shareBtns">
                <div onClick={()=>this.handleFontSizePopupVisability(isFontMenuOpen)} >
                    <span className="icon-pf resize_font" />
                </div>
                <div  onClick={()=>openMailShare(title, "כלכליסט",  url, () => analyticsPushFunc("Mail", "Article", title), "Article Main Image")}>
                    <span className="icon-pf share" />
                </div>
                <div  onClick={() => openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article", title), "Article")}>
                    <span className="icon-pf twitter" />
                </div>
                <div  onClick={() => openWhatsAppShare(url, () => analyticsPushFunc("Whatsapp", "Article", title), "Article")} >
                    <span className="icon-pf whatsapp" />
                </div>
                <div  onClick={() => openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article", title), "Article Main Image")}>
                    <span className="icon-pf facebook" />
                </div>
              {showComments &&  <a href="#SiteArticleComments">
                <span className="icon-pf comments" />
                </a>}
              </div>
            </div>
        )

    }
}
