require("./site_articleBottomShareComponent.less")
require("../site_shareButtons.less")
import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton";
import { SiteSettings, SiteSettingsHOC } from "../../../../../global/components/siteSettingsHOC"
import { getShareTranslations } from "../articleSideShareComponenta/translations";
import { openFacebookShare, openTwitterShare, analyticsPushFunc } from "../../../../widgetsUtils"
import { injectArticleComponentaContext } from "../../../../commonComponents/base/injectArticleComponentaContext";
import { ArticleComponentaContext } from "../../../../interfaces"

export class SiteArticleBottomShare extends React.Component<{ lang: string, isLTR: boolean, articleId?: string, title?: string }, {}> {
    public static siteScriptName = "SiteArticleBottomShare"
    static defaultProps = { lang: "he" }

    private handleShareBtnClicked = (button: string, lang: string) => {
        const {title} = this.props;
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "", () => analyticsPushFunc("Facebook", "Article Footer", title), "Article Footer")
                    break;

                case "tw":
                    openTwitterShare(current_url, () => analyticsPushFunc("Twitter", "Article Footer", title), "Article Footer")
                    break;

                case "print":
                    window.print();
                    break;

                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, lang, () => analyticsPushFunc("Mail", "Article Footer", title), "Article Footer")
                    break;

                case "talkback":
                    // window.location.href = `${current_url}#articleComments`;
                    const newCommentBtn = (document.querySelector(".newCommentBtn") as HTMLElement).click();
                    setImmediate(() => {
                        document.querySelector('#addCommentSendButton').scrollIntoView({
                            behavior: 'smooth'
                        });
                    })
                    break;
            }
        }
    }

    public render() {
        const { isLTR, lang } = this.props
        let englishSiteCss = isLTR ? "englishSite" : ""

        return (
            <div className={`ArticleBottomShareComponent ${englishSiteCss}`}>
                <ul>
                    {isLTR && <li className="ArticleBottomShareComponent_talkback">
                        <button title={getShareTranslations(lang, "comment")}
                            onClick={(e) => this.handleShareBtnClicked("talkback", lang)}>
                            <span className="articleShareButton talkback" />{getShareTranslations(lang, "comment")}</button></li>
                    }
                    <li className="ArticleBottomShareComponent_mailto">
                        <button title={getShareTranslations(lang, "mailto")}
                            onClick={(e) => this.handleShareBtnClicked("mailto", lang)}>
                            <span className="articleShareButton mailto" />{getShareTranslations(lang, "mailto")}</button></li>

                    <li className="ArticleBottomShareComponent_facebook" >
                        <button title={getShareTranslations(lang, "share")}
                            onClick={(e) => this.handleShareBtnClicked("fb", lang)}>
                            <span className="fc">{getShareTranslations(lang, "share")}</span>&thinsp;<span className="facebook articleShareButton" /></button></li>

                    <li className="ArticleBottomShareComponent_twitter">
                        <button title={getShareTranslations(lang, "share")}
                            onClick={(e) => this.handleShareBtnClicked("tw", lang)}><span className="tw">{getShareTranslations(lang, "share")}</span>&thinsp;<span className="twitter articleShareButton" />
                        </button></li>
                    {/* <li onClick={(e) => this.handleShareBtnClicked("print")}><span className="ArticleBottomShareComponent_print"><img />הדפסה</span></li> */}
                </ul>
            </div>

        )
    }
}