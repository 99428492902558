import * as axios from "axios"
import { ArticleExtraData } from "articlePage/stateInterfaces"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { Loader } from "../../../yPlusLoadMore/components/loader/loader";
import { PodcastCategoryItemList } from "./podcastCategoryItemList";
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { GoogleSearchComponenta } from "../../../googleSearch/components/googleSearchComponenta";
import { StandartSlotItemMutam } from "../../generalMutamComps/standartSlotItemMutam";
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage"
import { ImageSize } from "../../../../../constants/imageSizes"
import { PodcastStandartSlotItemMutam } from "./podcastStandartSlotItemMutam";

interface PodcastCategoryComponentaProps {
    categoryId: string
    componentaId?: string
    firstPageArticles?: ArticleExtraData[]
    numberOfVisibleItems: number
    spotifyLink: string
    applePodcastLink: string
    archivePageLink: string
    archivePageLinkTarget: LinkTargetProperties
    pageSearchLink: string
    showSearchField: boolean
    isMobileWeb?: boolean
}

interface PodcastCategoryComponentaState {
    articles: ArticleExtraData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export class PodcastCategoryComponenta extends React.Component<PodcastCategoryComponentaProps, PodcastCategoryComponentaState> {
    public static siteScriptName = "PodcastCategoryComponenta"
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0,
            showLoader: false,
            showButton: true
        }
    }

    componentDidUpdate(prevProps: PodcastCategoryComponentaProps) {
        if (this.props.firstPageArticles !== prevProps.firstPageArticles) {
            this.setState(
                {
                    articles: this.props.firstPageArticles
                }
            );
        }
    }

    componentDidMount() {
        const { categoryId } = this.props;
        if (categoryId) {
            this.getArticles()
        }
    }

    private getArticles = () => {
        const { componentaId, } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        this.setState(
            {
                articles: articles,
                showLoader: true,
                showButton: false
            }
        );


        axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/pageNumber/${pageNumber}`)
            .then((res: any) => {
                const recivedArticles = res.data.data as ArticleExtraData[];
                this.setState(
                    {
                        articles: [...articles, ...recivedArticles],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                        showButton: recivedArticles && recivedArticles.length === this.props.numberOfVisibleItems,
                    }
                );
            })
            .catch(err => console.error("error geting headline data:", err))
    }



    public render() {
        const { spotifyLink, applePodcastLink, archivePageLink, archivePageLinkTarget, pageSearchLink, showSearchField, isMobileWeb } = this.props;
        const { articles, showLoader: loader, showButton } = this.state;
        const tabStyle = { justifyContent: showSearchField ? "space-between" : "flex-end" };

        return (
            <div className="podcast-category-componenta">
                {isMobileWeb ?
                    <div className="mutam-podcasts-item-list">
                        {articles.map((item) => (
                            <PodcastStandartSlotItemMutam
                                item={{
                                    title: item.title,
                                    link: item.publishedLink,
                                    imageSrc: getFrontImageUrl(item.promotionImageDetails, { imageSize: ImageSize.medium }),
                                    publishedDate: item.dateUpdatedOnSite,
                                    author: item.specialData && item.specialData.audioData && item.specialData.audioData.alternatePresenterName
                                }}
                                fieldsDisplaySettings={{ isDateVisible: true, isAuthorVisible: true }}
                            />
                        ))}
                    </div>
                    :
                    <>
                        <div className="search-and-archive-tab" style={tabStyle}>
                            {showSearchField &&
                                <div className="search-wrapper">
                                    <GoogleSearchComponenta placeholder="מה תרצו לחפש?" link={pageSearchLink} disableInWcm={"all"} />
                                </div>
                            }

                            <HyperLinkBuilder href={archivePageLink} linkTarget={archivePageLinkTarget}>
                                <div className="archive-wrapper">
                                    <span className="archive-text">ארכיון</span>
                                    <span className="archive-icon" />
                                </div>
                            </HyperLinkBuilder>
                        </div>

                        <PodcastCategoryItemList
                            itemList={articles}
                            spotifyLink={spotifyLink}
                            applePodcastLink={applePodcastLink}
                        />

                        <div className="button-container">
                            {loader && <Loader />}
                            {showButton &&
                                <div className="load-more-button" onClick={() => this.getArticles()}>
                                    <div className="load-more-text">טען עוד פודקאסטים</div>
                                    <div className="load-more-icon"></div>
                                </div>}
                        </div>
                    </>
                }
            </div >
        )
    }
}