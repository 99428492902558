import { RadioProgramItem } from "../../../radioHompage/components/radioHomepagePlayerComponenta";
import { ButtonRnederer } from "../../../floatingPlayer/podcastPlayerButton";
import { isWcmEditor } from "config/serverConfig"
import { DvrRadioPlayer } from "../../../dvrRadioPlayer/dvrRadioPlayer";

interface RadioScheduleMediaBoxProps {
    radioCurrentProgram: RadioProgramItem
    liveBroadCastSource: string
    liveBroadCastDVRSource: string
    isMobileWeb: boolean
    radioScheduleList: RadioProgramItem[]
    startingIndex: number
    onCurrentShowIndexChange: (currentShowIndex: number) => void
}

interface RadioScheduleMediaBoxState {}

export class RadioScheduleMediaBox extends React.Component<RadioScheduleMediaBoxProps, RadioScheduleMediaBoxState> {
    render() {
        const { radioCurrentProgram, liveBroadCastDVRSource, isMobileWeb, radioScheduleList, startingIndex, onCurrentShowIndexChange, liveBroadCastSource } = this.props
      
        if (!radioCurrentProgram) {
            return <div className="radio-schedule-media-box" />
        }

        const { image, title, endTime, startTime, isProgramLive } = radioCurrentProgram

        return (
            <div className="radio-schedule-media-box">
                <img src={image} className="media-box-background" />

                {isProgramLive && <div className="live-box">
                    <img className="radio-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />
                    LIVE
                </div>}

                <div className={`radio-player-btn-container ${isWcmEditor() ? "wcm-editor" : ""}`}>
                    <DvrRadioPlayer 
                        isMobileWeb={isMobileWeb}
                        liveBroadCastDVRSource={liveBroadCastDVRSource}
                        liveBroadCastSource={liveBroadCastSource}
                        radioScheduleList={radioScheduleList}
                        startingIndex={startingIndex}
                        title="ynet Radio"
                        onCurrentShowIndexChange={onCurrentShowIndexChange}
                        playerComponentaRenderer={(props)=>(<ButtonRnederer {...props} className={"audio-play-button"} />)}

                    />
                </div>

                <div className="black-fade">
                    <div className="info-box">
                        {!radioCurrentProgram.hideHours &&
                            <div className="hours-row">
                                <div className="headphones" />
                                {startTime} - {endTime}
                            </div>}
                        <div className="box-title">
                            {title}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}