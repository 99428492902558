import { secondsToHms } from "../../../../../siteWidgets/siteWidgetUtils"
import { TogglePlayAction } from "../../../../radioWidgets/floatingPlayer/audioPlayButton"
require("./calcalist_site_calcalistPodcastPlayerControls.less")

interface PodcastPlayerControlsProps {
    isAudioPlaying: boolean
    playbackTime: number
    audioElement: HTMLAudioElement
    durationSeconds: number
    isControlsDisabled: boolean
    isSeeking: boolean
    isMuted: boolean
    loadingSource: boolean
    onseeked: (playbackTime: number) => void
    handleMuteSwitch: () => void
    onTogglePlayPause: () => TogglePlayAction
}

export class PodcastPlayerControls extends React.Component<PodcastPlayerControlsProps>{

    private jumpForward = () => {
        this.props.audioElement.currentTime += 15;
    }

    private jumpPrev = () => {
        this.props.audioElement.currentTime -= 15;
    }

    private updateCurrentTime = (e) => {
        this.props.onseeked(Number(e.target.value));
    }

    public render() {
        const { isAudioPlaying, durationSeconds, playbackTime, isSeeking, onTogglePlayPause, loadingSource } = this.props
        const percentage = (this.props.playbackTime - 0) * 100 / (this.props.durationSeconds - 0);
        const rangeBackground = `linear-gradient(90deg, #ffffffde ${percentage}%, #ffffff61 ${percentage}%)`;

        return (
            <div className="calcalist-article-podcast-controls">
                <div className="podcast-controls-btns-container">
                    <button className={`forwardStep jumpto-button `} onClick={this.jumpForward} aria-label="skeep 15 seconds"></button>

                    <button className={`calcalist-article-podcast-play-button ${loadingSource || isSeeking ? "calcalist-article-podcast-play-button-loader" : ""}`} onClick={onTogglePlayPause} aria-label={isAudioPlaying ? 'pause' : 'play'}>
                        <div className={`calcalist-article-podcast-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                    </button>

                    <button className={`prevStep jumpto-button`} onClick={this.jumpPrev} aria-label="go backwards 15 seconds"></button>
                </div>

                <div className="calcalist-article-podcast-controls-ruler">
                    <input type="range" className="timeline" max={durationSeconds} value={playbackTime} onChange={this.updateCurrentTime} style={{ background: rangeBackground }} />
                    <div className="time-text-wrapper">
                        <div className="time-text">{secondsToHms(durationSeconds)}</div>
                        <div className="time-text">{secondsToHms(playbackTime)}</div>
                    </div>
                </div>
            </div>
        )
    }
}