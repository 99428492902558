import { RadioProgramItem } from "../../radioHompage/components/radioHomepagePlayerComponenta";
import { RadioScheduleListRow } from "./radioScheduleListRow/radioScheduleListRow"
import { RadioScheduleMediaBox } from "./radioScheduleMediaBox/radioScheduleMediaBox";
import { AudioPlayButton } from "../../floatingPlayer/audioPlayButton"

interface RadioScheduleComponentProps {
    liveBroadCastSource: string
    liveBroadCastDVRSource: string
    radioScheduleList: RadioProgramItem[]
    startingIndex: number
    isMobileWeb: boolean
    offsetStart: number
    offsetEnd: number
}

interface RadioScheduleComponentState {
    currentShow: number
}

declare var window: Window & {
    wcmAudioPlayer: AudioPlayButton
}

export class RadioScheduleComponent extends React.Component<RadioScheduleComponentProps, RadioScheduleComponentState> {
    public static siteScriptName = "RadioScheduleComponent"

    constructor(props) {
        super(props)
        this.state = {
            currentShow: props.startingIndex,
        }
    }

    private handleCurrentShowIndexChange = (currentShowIndex: number) => {
        this.setState({ currentShow: currentShowIndex })
    }

    render() {
        const { radioScheduleList, isMobileWeb, liveBroadCastSource, liveBroadCastDVRSource, offsetEnd, offsetStart, startingIndex } = this.props
        const { currentShow } = this.state

        return (
            <div className="radio-schedule-component">
                <RadioScheduleMediaBox
                    radioCurrentProgram={radioScheduleList[currentShow]}
                    liveBroadCastSource={liveBroadCastSource}
                    liveBroadCastDVRSource={liveBroadCastDVRSource}
                    isMobileWeb={isMobileWeb}
                    startingIndex={startingIndex}
                    radioScheduleList={radioScheduleList}
                    onCurrentShowIndexChange={this.handleCurrentShowIndexChange}
                />
                <RadioScheduleListRow
                    radioScheduleList={radioScheduleList}
                    currentShow={currentShow}
                    isMobileWeb={isMobileWeb}
                    offsetEnd={offsetEnd}
                    offsetStart={offsetStart}
                />
            </div>
        )
    }
}