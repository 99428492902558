import { isWcmEditor } from "../../../../../config/serverConfig"

interface counterState {
    distance: number
}
interface counterProps {
    electionDate: Date
    electionYear: string
    electionTitle: string

}

const electionsDict = {
    ELECTIONS: "בחירות",
    SECONDS: "שניות",
    MINUTES: "דקות",
    HOURS: "שעות",
    DAYS: "ימים",
}
export class ElectionDateCounter extends React.PureComponent<counterProps, counterState>{
    constructor(props) {
        super(props);
        this.state = {
            distance: 0,
        }
    }
    public static siteScriptName = "ElectionDateCounter"
    private counter: number;

    public componentDidMount() {
        if (isWcmEditor()) return;
        const { electionDate } = this.props;
        if (!electionDate) return;
        const electionDateAsNumber = new Date(electionDate).getTime()
        this.counter = window.setInterval(() => {

            const distance = Math.max(electionDateAsNumber - new Date().getTime(), 0);
            // Time calculations for days, hours, minutes and seconds

            this.setState({
                distance
            })

            // If the count down is finished, write some text
            if (distance == 0) {
                clearInterval(this.counter);

            }
        }, 1000);
    }

    public render() {
        let distance = 0;
        if (isWcmEditor()) {
            const { electionDate } = this.props;
            const electionDateAsNumber = new Date(electionDate).getTime()
            distance = Math.max(electionDateAsNumber - new Date().getTime(), 0);
        }
        else {
            distance = this.state.distance;
        }
        // if (distance == 0) return null;

        const { electionTitle, electionYear } = this.props;
        const {ELECTIONS, SECONDS, MINUTES, HOURS, DAYS} = electionsDict;
        const isCounterEnded = distance === 0;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        return (
            <div className="electionDateCounter">
                {isCounterEnded ?
                    <div  className="electionYearTitle">
                        <div className="upperText">{ELECTIONS}</div>
                        <div className="lowerText">
                            <div className="yearText">{electionYear}</div>
                            <div className="ynetLogo"></div>
                        </div>
                    </div>
                    :
                    <div className="dateCounterWrapper">
                        <div className="electionTitle">{electionTitle}</div>
                        <div className="dateDisplay">
                            <div className="countItem">
                                <div className="number">{seconds}</div>
                                <div className="text">{SECONDS}</div>
                            </div>
                            <div className="separatorBar" />
                            <div className="countItem">
                                <div className="number">{minutes}</div>
                                <div className="text">{MINUTES}</div>
                            </div>
                            <div className="separatorBar" />
                            <div className="countItem">
                                <div className="number">{hours}</div>
                                <div className="text">{HOURS}</div></div>
                            <div className="separatorBar" />
                            <div className="countItem ">
                                <div className="number bold">{days}</div>
                                <div className="text">{DAYS}</div></div>

                        </div>
                    </div>
                }
            </div>
        )
    }
}