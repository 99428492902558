import {getTranslation} from "../../redMailTranslations";

interface RedMailFileUploadYnetnewsProps {
    handleSelectedFile: (e: any) => void
    selectedFiles: any
    hashes: string[]
    invalidFilesName: string[]
    showFileStatusProgress: (fileKey: any) => string
    lang: string
}

export class RedMailFileUploadYnetnews extends React.Component<RedMailFileUploadYnetnewsProps, {}> {

    render() {
        const { handleSelectedFile, selectedFiles, invalidFilesName, lang } = this.props;
        return (
            <div className="RedMailFilesUpload">
                <div style={{ display: Object.keys(selectedFiles).length > 0 && "none" }} className="FilesUploadButtonWrapper">

                    <input type="file" id="inputFile"
                        onChange={handleSelectedFile}
                        multiple
                    />
                    <button className="FilesUploadButton">
                        {getTranslation(lang, "add a file")}
                        <div className="uploadIcon"></div>
                    </button>
                </div>
                <div className="addFileWrapper">
                    <div className="addFileInputWrapper">
                        {Object.keys(selectedFiles).length > 0 && <div className="fileWrapper" style={{ width: '100%' }}>
                            {Object.keys(selectedFiles).map(fileKey =>
                                <div className="fileName" key={fileKey}>
                                    <div className="fileNameIcon"></div>
                                    <input type="text" dir="ltr" value={selectedFiles[fileKey].fileName} />
                                </div>)}
                        </div>}
                        {Object.keys(selectedFiles).length > 0 && Object.keys(selectedFiles).length < 5 &&
                            <div className="addFile" onClick={() => document.getElementById("inputFile").click()}> <span>{`mov,png,jpeg,jpg,mp4 ${getTranslation(lang, "up to")} 200 MB`}</span> &nbsp;{getTranslation(lang, "add additional file")}<div className="addFilePlusIcon"></div> </div>}
                        {!!invalidFilesName.length && <div className="files-names-error-row">
                            {invalidFilesName.map(file => <span key={file}> {getTranslation(lang, "the file")} {`${file}`}  {getTranslation(lang, "too heavy")} </span>)}
                        </div>}
                    </div>
                    <div className="addFileText" style={{ display: Object.keys(selectedFiles).length === 0 && "none" }}>{getTranslation(lang, "add a file")}</div>
                </div>
            </div>
        )
    }
}
