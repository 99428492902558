var Cookies = require('js-cookie');
import * as map from "lodash/map"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
require('./ynetnews_vesty_site_ynetNewsHeader.less')

export interface menuItems {
    name: string
    link: string
    mobileIcon?: string
    color?: string
}
interface MobileMenuProps {
    items: menuItems[]

}


export class MobileMenuComponenta extends React.Component<MobileMenuProps, {}> {
    componentDidMount() {
        const element: HTMLElement = document.getElementById("INDmenu-btn3");
        element && element.addEventListener("click", (e) => this.clickAcessibilityBtn())
    }

    private clickAcessibilityBtn = () => {
        const element: HTMLElement = document.getElementById('INDmenu-btn')
        element.click();
    }


    render() {
        const { items } = this.props
        const dataItems = items ? items : [{ name: "", link: "", mobileIcon: "", color: "" }]
        const firstItem = dataItems[0]
        const otherItems = dataItems.slice(1)
        return (
            <ul>
                <li >
                    <div className="color"></div>
                    <a href={firstItem.link}>
                        <div className="icon" style={{ color: firstItem.color }}>{firstItem.name}
                            {firstItem.mobileIcon === "" ? null :
                                <SiteSimpleImage src={firstItem.mobileIcon} aria-hidden="true" className="menu-img-item menu-img-size" />
                            }
                        </div>
                    </a></li>
                {this.props.children}
                {map(otherItems, (item, i) => <li key={i}>
                    <div className="color"></div>
                    <a href={item.link}>
                        <div className="icon" style={{ color: item.color }}>{item.name}
                            {item.mobileIcon === "" ? null : <SiteSimpleImage src={item.mobileIcon} aria-hidden="true" className="menu-img-item menu-img-size" />}
                        </div>
                    </a></li>
                )}
            </ul>

        )
    }
}
