require("../../../article/componentas/articleSocialShare1280Component/ynet_pplus_magazine_site_siteArticleSocialShare1280.less")
import { openFacebookShare, openTwitterShare } from "../../../../widgetsUtils"
import { MailShareButton } from "../../../../siteWidgets/socialShareButtons/mail/mailShareButton"
import { WhatsAppShareButton } from "../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton"
import { FrontCopyButton } from "../../../../commonComponents/inline/frontCopyButton/frontCopyButton";
const clickOutside = require('react-click-outside');

interface shareState {
    isMenuOpened: boolean
}

interface shareProps {
    categorySubChannel: string
    credit: string
    articleId: string
    isMobileWeb: boolean
    lang?: string
    verticalSidebar?: boolean
    title?: string
    domain: string
}

@clickOutside
export class VideoMainPageMediaShare extends React.Component<shareProps, shareState>{
    static siteScriptName = "VideoMainPageMediaShare"
    constructor() {
        super();
        this.state = {
            isMenuOpened: null,
        }
    }

    private handleClickOutside = () => {
        this.setState({
            isMenuOpened: false
        });
    }

    private handleShareBtnClicked = (button: string) => {
        const { lang } = this.props;
        this.setState({ isMenuOpened: false })
        if (typeof window !== "undefined") {
            const current_url = window.location.href;

            switch (button) {
                case "fb":
                    openFacebookShare(current_url, "")
                    break;
                case "tw":
                    openTwitterShare(current_url)
                    break;
                case "mailto":
                    MailShareButton.openMailShare(document.title, current_url, lang)
                    break;
                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(current_url)
                    break;  
            }
        }
    }

    private openShare = async () => {
        const { domain } = this.props;
        const current_url = `${window.location.href}?utm_source=${domain}&utm_medium=social&utm_campaign=general_share`;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    render() {
        const { title, isMobileWeb } = this.props;
        const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;
        const dict = {
            he: {
                textMail: "שלחו כתבה",
                textTw: "שיתוף בטוויטר",
                textFb: "שיתוף בפייסבוק",
                textWa: "שיתוף בואטסאפ",
            }
        };
        const { he: { textMail, textFb, textTw, textWa } } = dict;

        return (
            <div className="SiteArticleSocialShareNew1280">
                {isApp ?
                    <>
                        <a href="whatsapp:">
                            <span className="shareIcon1280 wa" />
                        </a>
                        <a href="share_article">
                            <span className="shareIcon1280 shareBtn" />
                        </a>
                    </>
                    :
                    <>
                        <button title={textWa} className="shareIcon1280 wa" onClick={e => this.handleShareBtnClicked("wa")}></button>
                        {isMobileWeb && <div className="shareIcon1280 shareBtn" onClick={this.openShare}></div>}
                        {!isMobileWeb &&
                            <>
                                <button title={textFb} className="shareIcon1280 fb" onClick={e => this.handleShareBtnClicked("fb")}></button>
                                <button title={textTw} className={`shareIcon1280 tw`} onClick={e => this.handleShareBtnClicked("tw")}></button>
                                <button title={textMail} className="shareIcon1280 mailto" onClick={e => this.handleShareBtnClicked("mailto")}></button>
                                <FrontCopyButton popupText="הקישור הועתק ללוח" title={title} />
                            </>
                        }
                    </>
                }
            </div>
        )
    }
}