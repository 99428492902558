import * as throttle from "lodash/throttle"
import { sendVideoStikyClose, sendVideoStikyShow } from "./videoAnalytics";
import { windowIsWideEnoughForStickyVideo } from "./videoPlayerUtils";

interface IFlowPlayer {
    playerState: {
        "is-playing": boolean;
    };
    ads: {
        adPlaying: boolean;
        adPaused: boolean;
    };
    opts: {
        src: {
            src: string;
        }[];
    };
    pause: () => void;
    on: (event: string, cb) => void;

}

export function attachFpStickinessAPI(api: IFlowPlayer, container: HTMLDivElement, poster: string) {
    const viewportHeight = document.documentElement.clientHeight;

    const showStickyIfNotInViewPort = () => {
        if (!windowIsWideEnoughForStickyVideo()) return;
        const videoContainer = container;
        const videoComponenta = container.closest(".isStikyness") as HTMLDivElement;
        const videoComponentaRect = videoComponenta.getBoundingClientRect();
        const threshold = videoComponentaRect.height / 4;
        const playing = isPlaying();

        if (isVideoOutOfViewport(videoComponentaRect, threshold) && (playing) && !hasStickyClass(videoContainer)) {
            sendVideoStikyShow(api.opts.src[0].src);
            addPlaceholder(videoContainer, poster);
            addStickyClasses(videoContainer);
            addCloseButton(videoContainer, api);
        } else if (isVideoInViewPort(videoComponentaRect, threshold) && (hasStickyClass(videoContainer))) {
            removeStickyClass(videoContainer);
            removeStickyClass(videoComponenta);
            removePlaceholder();
        }
    };

    const closeStickyVideos = () => {
        const stickyVideos = document.querySelectorAll(".is-sticky");
        stickyVideos.forEach((stickyVideo) => {

            const stickyVideoPlayer = stickyVideo.querySelector(".fp-engine") as any;
            stickyVideoPlayer.pause();
            stickyVideoPlayer.ads && stickyVideoPlayer.ads.pause();

            removeStickyClass(stickyVideo);
            removePlaceholder();
        });
    };

    const isVideoOutOfViewport = (rect, threshold) => {
        const videoOutOfViewport = rect.top + threshold < 0 || rect.bottom - threshold > viewportHeight;
        return videoOutOfViewport;
    };

    const isVideoInViewPort = (rect, threshold) => {
        const entireVideoInViewPort = rect.top > - threshold && rect.bottom < viewportHeight + threshold;
        return entireVideoInViewPort;
    }

    const isPlayerPlaying = () => {
        const playerIsPlaying = api.playerState["is-playing"];
        return playerIsPlaying;
    };

    const isAdPlaying = () => {
        const adIsPlaying = api.ads && api.ads.adPlaying;
        return adIsPlaying;
    };

    const isPlaying = () => {
        const playing = isPlayerPlaying() || isAdPlaying();
        return playing;
    };

    const hasStickyClass = (videoContainer) => {
        const hasStickyClass = videoContainer.classList.contains("is-sticky");
        return hasStickyClass
    };

    const addStickyClasses = (videoContainer) => {
        if (hasSargelAd()) {
            videoContainer.classList.add("is-sticky-sargel");
        }
        videoContainer.classList.add("is-sticky");
    };

    const hasSargelAd = () => {
        return document.getElementById('ads.sargel') !== null;
    };

    const removeStickyClass = (container) => {
        container.classList.remove("is-sticky");
    };

    const throttledOnScroll = throttle(showStickyIfNotInViewPort, 50);

    function attachSticky(e, api) {
        window.addEventListener("scroll", throttledOnScroll);
        document.addEventListener("click", handlePlaybuttonClick);

    }

    const handlePlaybuttonClick = (e) => {

        if (e.target.getAttribute("aria-label") === "Play" && !hasStickyClass(e.target.closest("#player"))) {
            closeStickyVideos();
        }
    };

    function addCloseButton(playerContainer: HTMLDivElement, api: any) {
        const anchorElement = document.createElement("a");
        anchorElement.className = "ynetfp-close-button fp-icon";
        anchorElement.onclick = (e) => {
            terminateSticky(playerContainer, api);
        }
        playerContainer.appendChild(anchorElement);
    }

    function terminateSticky(playerContainer: HTMLDivElement, api: any) {
        api.pause()
        setImmediate(()=>api.ads && api.ads.pause());
        setImmediate(() => {
            removeStickyClass(playerContainer);
            removePlaceholder();
            sendVideoStikyClose(api.opts.src[0].src);
        });
    }

    attachSticky(null, api);
}


function addPlaceholder(playerContainer: HTMLDivElement, poster: string) {
    let containerPlaceholder = playerContainer.cloneNode(false) as HTMLDivElement;
    containerPlaceholder.id = "[placeholder]_" + playerContainer.id;
    containerPlaceholder.classList.remove("is-playing"); // this class holds the rule: "background-image: none !important" which blocks the poster
    containerPlaceholder.classList.add("StickyPlayerPlaceholder")
    containerPlaceholder.classList.add("is-starting")
    containerPlaceholder.style.backgroundImage = `url(${poster})`;
    containerPlaceholder.style.height = playerContainer.clientHeight.toString() + "px";
    playerContainer.parentElement.insertBefore(containerPlaceholder, playerContainer);
}

function removePlaceholder() {
    let placeholders = document.getElementsByClassName('StickyPlayerPlaceholder');
    while (placeholders[0]) {
        placeholders[0].parentNode.removeChild(placeholders[0]);
    }
}
