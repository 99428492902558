

import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "../../../../global/components/linkSettings/interfaces";
import { CircleProgressbarAnimated } from "./circlePropgressBar";
import * as classNames from "classnames"
import { CSSTransitionGroup } from "react-transition-group";
import { YplusMenu, yPlusHeaderMenuItem } from "./menu";
import { NewsLetterYplusSignUp } from "./newsLetterSendRequest";
import { YnetPlusUserMenuItems } from "../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";


export interface yPlusHeaderItem {
    title: string
    articleId: string
    link: string
    linkTarget: LinkTargetProperties
    imgUrl: string
    imgCredit: string
    author: string
    freeText: string
    subTitle?: string
    hasVideo?: boolean

}
interface yPlusHeaderProps {
    items: yPlusHeaderItem[]
    seconds: number
    menuItems: yPlusHeaderMenuItem[]
    isMenuVisible: boolean
    categoryId: string
    seachPageLink: string
    mainLogoLink: string
    parentSiteLink: string
    version: number
    componentaId?:string
    newsLetterVisible?:boolean
    accountName?:string 
    itemId?:string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}


interface yPlusHeaderState {
    selectedItem: number
    isActive: boolean
    isSticky: boolean
    isInputOpened: boolean
    isUserLogged: boolean
    mailValue:string
}

export class FrontYnetPlusHeader extends React.Component<yPlusHeaderProps, yPlusHeaderState>{
    constructor(props) {
        super(props as yPlusHeaderProps);
        this.state = {
            selectedItem: 0,
            isActive: true,
            isSticky: false,
            isInputOpened: false,
            isUserLogged: false,
            mailValue:""
        }
    }
    public static siteScriptName = "FrontYnetPlusHeader";

    private handleSelect = (itemIndex: number) => {
        this.setState({ selectedItem: itemIndex, isActive: false  })
        clearInterval(this.autoGalleryInterval)
    }
    private showInput = () => {
        this.setState({ isInputOpened: true })
    }
    private hideInput = () => {
        this.setState({ isInputOpened: false })
    }
    private autoGalleryInterval: any;
    private startAutoGallery = () => {
        const { seconds, items } = this.props
        const self = this;
        if (typeof (seconds) === "number") {
            if (seconds > 0) {
                this.autoGalleryInterval = setInterval(() => {
                    this.setState((prevState: yPlusHeaderState, props: yPlusHeaderProps) => {
                        let newIndex = ++prevState.selectedItem;
                        if (newIndex >= props.items.length) newIndex = 0;
                        return { selectedItem: newIndex }
                    });
                }, seconds * 1000);
            }
            else {
                clearInterval(this.autoGalleryInterval)
                this.setState({ isActive: false })
            }
        }
    }
    private changeMailValue = (value) => {
        this.setState({ mailValue:value })
    }
    componentDidMount() {
        //  document.addEventListener("scroll", this.throttledScrollHandler)
        if (window && window.YitPaywall && window.YitPaywall.user && window.YitPaywall.user.props.uid ) {
            this.setState({ isUserLogged: true })
        }
        if (window.innerWidth > 700) {
            if (this.props.items.length > 1) {
                this.startAutoGallery();
            }
        }
        else {
            clearInterval(this.autoGalleryInterval)
            this.setState({ isActive: false })
        }
    }

    componentWillUnmount() {
        clearInterval(this.autoGalleryInterval)
        this.setState({ isActive: false })
    }
    render() {
        const { items, seconds, menuItems, isMenuVisible, categoryId, seachPageLink, mainLogoLink,
            parentSiteLink, version, componentaId, newsLetterVisible, ynetPlusMenuItems } = this.props
        const { selectedItem, isActive, isInputOpened, isUserLogged, mailValue } = this.state
        return (

            <div className={classNames("YnetPlusHeaderComponenta", { "ver2": version === 2 })}
                id="YPlusHeader"
            >
                <CSSTransitionGroup transitionName="media" transitionLeave={true}
                    transitionLeaveTimeout={600}
                    transitionEnterTimeout={600} >
                    <div className="bgDiv" key={selectedItem} style={{
                        backgroundImage: `url("${items[selectedItem].imgUrl}")`, backgroundRepeat: "no-repeat"
                    }} >
                        <span className="imgCredit">{items[selectedItem].imgCredit}</span>
                        <HyperLinkBuilder className="selectedItemText" articleId={items[selectedItem].articleId} href={items[selectedItem].link} linkTarget={items[selectedItem].linkTarget}>
                            <span className="categoryLabel" dangerouslySetInnerHTML={{ __html: items[selectedItem].freeText }} />
                            <div className="mainTitle" dangerouslySetInnerHTML={{ __html: items[selectedItem].title }} />
                            {items[selectedItem].author.length > 0 && <div className="moreDetails">
                                {items[selectedItem].author}
                            </div>}
                        </HyperLinkBuilder>
                    </div>
                </CSSTransitionGroup >
                <YplusMenu items={menuItems}
                    categoryId={categoryId}
                    isMenuVisible={isMenuVisible}
                    seachPageLink={seachPageLink}
                    isInputOpened={isInputOpened}
                    showInput={this.showInput}
                    onFocusOutSearch={this.hideInput}
                    isUserLogged={isUserLogged}
                    mainLogoLink={mainLogoLink}
                    parentSiteLink={parentSiteLink}
                    ynetPlusMenuItems={ynetPlusMenuItems}
                    inputHasValue={mailValue.length>0} >
                        {!isUserLogged && newsLetterVisible && <NewsLetterYplusSignUp 
                        componentaId={componentaId}
                         mailValue={mailValue} 
                         changeMailValue={this.changeMailValue}
                         accountName={this.props.accountName}
                         itemId={this.props.itemId}
                         />}
                        </YplusMenu>


                <div className="itemListContainer">
                    {items.map((item, i) => {
                        return (
                            <div onClick={() => this.handleSelect(i)}
                                key={i}
                                className={classNames("slotView", { "selected": (i === selectedItem && isActive), "onSelectWithoutAutoplay": (i === selectedItem && !isActive) })}>
                                <CircleProgressbarAnimated
                                    isActive={this.state.isActive && i === selectedItem}
                                    index={i}
                                    seconds={seconds} />
                           {/*     <div className="textArea">
                                    <div className="slotTitle" dangerouslySetInnerHTML={{ __html: items[i].title }} />
                        </div>*/}
                            </div>)
                    }
                    )}
                </div>
            </div>
            /***/
        )
    }
}
