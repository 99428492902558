import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"

interface articleHeaderProps {
    logoLink: string
}

export class PplusMobileArticleHeader extends React.Component<articleHeaderProps, {}> {

    private openShare = async () => {
        const current_url = window.location.href;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    render() {
        const { logoLink } = this.props

        return (
            <div className="pplusMobileArticleHeader">
                <div className="rightSideWrapper">
                    <a className="goBackArrow" href={"javascript:history.back()"}></a>
                    <div className="seperator"></div>
                </div>

                <SimpleLinkRenderer className="logoLinkWrapper" href={logoLink} >
                    <div className="pplusLogo"></div>
                </SimpleLinkRenderer>

                <div className="shareBtn" onClick={this.openShare}></div>
            </div>
        )
    }
}