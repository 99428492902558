import * as classNames from "classnames"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { ArticleCommentRenderProps } from "../articleCommentWrapper";
import { getTranslation } from "../translations";
import { AddCommentFormWrapper, AddCommentFormRenderProps } from "../addCommentFormWrapper";
import { MobileWebAddCommentFormUI } from "./mutamAddCommentFormUI";

interface ArticleCommentsUIMobileWebProps {
    props: ArticleCommentRenderProps
}

export class ArticleCommentMobileCalcalistWebUI extends React.Component<ArticleCommentsUIMobileWebProps, {}>{
    render() {
        const { lang, isExpanded, toggleOpenedState,  
            comment: {
                id,
                number,
                title,
                text,
                author,
                pubDate,
                level,
                recommended,
                talkback_source,
            } } = this.props.props
        const articlePublishDate = separateHourFromDateAndFormatToString(pubDate, { showHour: false, dateTimeSeperator: "|" })
        const expandedCommentClasses = classNames({
            "ArticleCommentMobileWeb": true,
            "expanded": isExpanded,
            "level1": level === 1,
        })
    
        const commentTitleClasses = classNames({
            "commentTitle": true,
            "redTitleText": recommended,
            "expanded": isExpanded
        })
        const commentTextStyles = classNames({
            "commentText": true,
            "level1": level === 1,
            "expanded": isExpanded
        })
 
        const commentNumClasses = classNames({
            "commentNum": true,
        })
      
        return (
            <div id="ArticleCommentMobileWeb" className={expandedCommentClasses}>
                <div className="topView" >
                    {level === 1 && <div className={commentNumClasses} onClick={(e) => toggleOpenedState()}>{number}.</div>}
                    {level === 2 && <div className="level2ArrowContainer" onClick={(e) => toggleOpenedState()}><span className="level2Arrow" /></div>}

                    <div className="topLeftView">

                        <div className="commentDetailsWrapper" >
                            <div className={commentTitleClasses} onClick={(e) => toggleOpenedState()}>
                                <span className="titleText">{title}</span>
                                {text.length === 0 && <span>{getTranslation(lang, "Empty Content")}</span>}
                            </div>
                        </div>

                        <div className={commentTextStyles}>{text}</div>

                        <div className="details">
                            <div>  {author !== "" ? <span className="author">{`${author}`}</span> : null}
                                {author !== "" && <span>|</span>}
                                <span className="publishDate"> {`${articlePublishDate}${talkback_source ? " , " + talkback_source : ""}`}</span></div>
                            <div>

                            

                           
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}