const script = `
<script>
window.addEventListener("load", () => {
    document.querySelectorAll(".article-blog-componenta-wrapper").forEach((blogElement) => {
        const blogContentDiv = blogElement.querySelector(
            'div[data-contents="true"]'
        );
        if (!blogContentDiv) return;
        // get it's children (blocks)
        const blogContentDivChildren = blogContentDiv.children;
        if (!blogContentDivChildren) return;

        let restOfTheBlocks;
        let restOfTheBlocksDiv;

        // if there is a class of "TextEditorBlogEndOfPreviewTextFlag", start wrapping the blocks from it
        const endOfPreviewTextFlag = blogElement.querySelector(
            ".TextEditorBlogEndOfPreviewTextFlag"
        );
        if (endOfPreviewTextFlag) {
            // find the node with the div with class of "TextEditorBlogEndOfPreviewTextFlag" inside the children
            const endOfPreviewTextFlagIndex = Array.from(blogContentDivChildren).findIndex(
                (child) => child.contains(endOfPreviewTextFlag)
            );          

            restOfTheBlocks = Array.from(blogContentDivChildren).slice(
                endOfPreviewTextFlagIndex + 1,
                blogContentDivChildren.length
            );
        }

        // if there are more than 3 blocks, wrap the rest with a div and hide it
        else if (blogContentDivChildren.length > 3) {
            restOfTheBlocks = Array.from(blogContentDivChildren).slice(
                3,
                blogContentDivChildren.length
            );
        }
        
        restOfTheBlocksDiv = document.createElement("div");
        restOfTheBlocksDiv.classList.add("restOfTheBlocks");
        restOfTheBlocksDiv.classList.add("hidden_blocks");
        restOfTheBlocksDiv.append(...restOfTheBlocks);
        blogContentDiv.append(restOfTheBlocksDiv);
        

        // add event listener to the button
        const button = blogElement.querySelector(".continue-read-wrapper");
        if (!button) return;
        button.addEventListener("click", () => {
            const restOfTheBlocksDiv = blogElement.querySelector(
                ".restOfTheBlocks"
            );
            if (!restOfTheBlocksDiv) return;
            restOfTheBlocksDiv.classList.toggle("hidden_blocks");
            button.classList.toggle("active");
        });
        
    });
});
</script>
`;

export class SiteArticleBlogsReadMoreHandler extends React.Component<{}, {}> {
    public render() {
        return <div dangerouslySetInnerHTML={{ __html: script }} />;
    }
}
