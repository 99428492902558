require("./site_socialLinks.less")

export class SocialLinks extends React.Component<{}, {}>  {

    componentDidMount() {
        const element: HTMLElement = document.getElementById("INDmenu-btn3");
        element && element.addEventListener("click", this.clickAcessibilityBtn)
    }

    componentWillUnmount() {
        const element: HTMLElement = document.getElementById("INDmenu-btn3");
        element && element.removeEventListener("click", this.clickAcessibilityBtn)
    }

    private clickAcessibilityBtn = () => {
        const element: HTMLElement = document.getElementById('INDmenu-btn')
        element.click();
    }

    render() {
        return (
            <div className="SocialLinks">
                <a href="" target="_blank" rel="nofollow" className="ynetLogo" title="ynet ברשת"></a>
                <a href="https://www.instagram.com/ynetgram/" target="_blank" rel="nofollow" className="logo instagram" title="הדף שלנו באינסטגרם"></a>
                <a href="https://www.youtube.com/channel/UCpSSzrovhI4fA2PQNItecUA" target="_blank" rel="nofollow" className="logo youtube" title="ynet ביוטיוב"></a>
                <a href="https://twitter.com/ynetalerts" target="_blank" rel="nofollow" className="logo twitter" title="עקוב אחרינו בטוויטר"></a>
                <a href="https://www.facebook.com/ynetnews" target="_blank" rel="nofollow" className="logo facebook" title="הדף שלנו בפייסבוק"></a>
                <span className="footer_tools_separator"></span>
                <a id="INDmenu-btn3" className="logo negishut" title="אתר נגיש"></a>
            </div>
        )
    }
}