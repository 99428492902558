import * as throttle from "lodash/throttle"
import { sendVideoStikyClose, sendVideoStikyShow } from "./videoAnalytics";
import { windowIsWideEnoughForStickyVideo } from "./videoPlayerUtils";

export function attachStickinessYouTubeAPI(api: YT.Player, container: HTMLDivElement, poster: string) {
    if (!container.classList) return; //not supported in IE <10
    if (!windowIsWideEnoughForStickyVideo()) return;

    let isSticky = false;
    const threshold = container.getBoundingClientRect().height / 4;
    addCloseButton(container, api);

    const showStickyIfNotInViewPort = function () {
        const rect = container.parentElement.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;
        if (rect.top < -threshold || rect.bottom > viewportHeight + threshold) {
            if (!container.classList.contains("is-sticky")) {
                sendVideoStikyShow(api.getVideoUrl());
                addPlaceholder();
                if (document.getElementById('ads.sargel')) {
                    container.classList.add("is-sticky-sargel");
                }
                container.classList.add("is-sticky");
            }
        } else {
            removePlaceholder()
            container.classList.remove("is-sticky");
        }
    }
    const throttledOnScroll = throttle(showStickyIfNotInViewPort, 250);

    function attachSticky(e) {
        if (!isSticky) {
            showStickyIfNotInViewPort();
            window.addEventListener("scroll", throttledOnScroll);
            isSticky = true;
        }
    }

    function detachSticky(event, api: YT.Player) {
        const player = event.target

        if (isSticky) {
            window.removeEventListener("scroll", throttledOnScroll);
            container.classList.remove("is-sticky");
            removePlaceholder();
            isSticky = false
        }
    }

    function onPlayerStateChange(event) {
        const player = event.target
        switch (event.data) {
            case YT.PlayerState.PLAYING:
                attachSticky(event);
                break;
        }
    }

    function addCloseButton(playerContainer: HTMLDivElement, api: YT.Player) {
        const anchorElement = document.createElement("a");
        anchorElement.className = "ynetfp-close-button fp-icon";
        anchorElement.onclick = function (e) {
            api.stopVideo();
            detachSticky(e, api);
            // removePlaceholder();
            sendVideoStikyClose(api.getVideoUrl());
        }
        playerContainer.appendChild(anchorElement);
    }

    function addPlaceholder() {
        let containerPlaceholder = document.createElement("div");
        containerPlaceholder.id = "[placeholder]_" + container.id;
        containerPlaceholder.classList.add("YTStickyPlayerPlaceholder")
        containerPlaceholder.style.backgroundImage = poster;
        containerPlaceholder.style.height = container.clientHeight.toString() + "px";
        container.parentElement.insertBefore(containerPlaceholder, container);
    }

    function removePlaceholder() {
        let placeholders = document.getElementsByClassName('YTStickyPlayerPlaceholder');
        while (placeholders[0]) {
            placeholders[0].parentNode.removeChild(placeholders[0]);
        }
        // container.parentElement.removeChild(container.parentElement.getElementsByClassName('YTStickyPlayerPlaceholder')[0]);
    }

    api.addEventListener("onStateChange", onPlayerStateChange)

    window.removeEventListener("scroll", throttledOnScroll);

}

