interface MarketngProps {
    isSmallItem: boolean
    marketingContentText: string
}
interface MarketingState {
    isOpened?: boolean
}
declare let window: Window & {
    languageCode: string
}
export class MutamMarketingSign extends React.Component<MarketngProps, MarketingState> {
    public static siteScriptName = "MutamMarketingSign";
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false
        }
    }

    private toggleInfo = () => {
        this.setState({ isOpened: !this.state.isOpened })
    }
    public render() {
        const { isSmallItem, marketingContentText } = this.props
        const { isOpened } = this.state
        const lang  =typeof window !== "undefined" && window && window.languageCode.slice(0, 2);
       let mText = "תוכן בשיתוף עם הגורם המפרסם, הכתבה הופקה בידי המחלקה המסחרית של ynet בהשתתפות מימונית של גורם חיצוני ופורסמה לאחר עריכה עיתונאית";
        if (lang==="en") {
            mText = "Content  was produced by the commercial department of Ynet with the financial support of an external party and edited by our journalists.";
        }
       else if (lang==="ru") {
            mText = 'Текст подготовлен коммерческим отделом "Вестей" при финансовом участии внешнего спонсора, опубликован после редактирования журналистами';
        }
        const text = marketingContentText !== "" ? marketingContentText : mText;
        return (
            <>   <div className={`MutamMarketingSign`}>
               
                {(lang==="en" || lang==="ru")
                    ? <div className="marketingImage" onClick={this.toggleInfo} ></div> :
                    <img src="https://www.ynet.co.il/images/market_strip.png" className="marketingImage" onClick={this.toggleInfo} />}
            </div>
                {isOpened && <div className={`MutamMarketingText ${isSmallItem ? "coverAll" : ""}`}><span>{text}</span>
                    <button className="closeInfoiTxt" onClick={this.toggleInfo} />
                </div>}</>
        )
    }
}