import { SiteMediaData, SiteItemData, SiteImageData } from "../../../interfaces"
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { IFieldDisplayData } from "../../../commonDataObjects/interfaces"
import { MediaContentTypes } from "constants/contentTypes"
import { MultiImagesFrontItem } from "./multiImagesFrontItem";
import { googleScript } from "widgets/widgetsUtils"

require("./site_miltiImagesComponenta.less")


interface MultiImagesFrontProps {
    width: number
    imageWidth: number
    imageHeight: number
    itemList: SiteItemData[]
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    subTitleColor: string
    titleColor: string
    lastHeightSize?: number
    isGrid1280?: boolean
    isDoubleTab?: boolean
    isLtr?: boolean
    ads_infrastructure_type?: string
}

interface MultiImagesFrontState {
    num_of_scrolls_left_available: number
    num_of_scrolls_right_available: number
    width_in_pixels_of_last_overflowing_item_in_row: number
    rightOffset: number
}


export class MultiImagesFront extends React.Component<MultiImagesFrontProps, MultiImagesFrontState>{
    public static siteScriptName = "MultiImagesFront";
    constructor(props) {
        super(props);
        this.state = {
            num_of_scrolls_left_available: null,
            num_of_scrolls_right_available: null,
            rightOffset: 0,
            width_in_pixels_of_last_overflowing_item_in_row: 0
        }
    }


    private getItemMargin = (componentWidth: number, imageWidth: number) => {
        const singleItemWidth = imageWidth
        let numberofVisibleItems = Math.floor(componentWidth / singleItemWidth)
        let imagesWidth = numberofVisibleItems * singleItemWidth
        let spaceForMargin = componentWidth - imagesWidth
        let marginLeft = spaceForMargin / (numberofVisibleItems - 1)
        return marginLeft
    }
    public componentWillMount() {
        const { itemList, imageWidth, width } = this.props
        if (itemList.length > 0) {
            this.setupLisScrolling(itemList, width, imageWidth)
        }
    }


    private setupLisScrolling(medias: any[], width: number, imageWidth: number) {
        const num_of_items = medias.length
        const single_media_item_width = imageWidth + this.getItemMargin(width, imageWidth)
        const num_of_medias_fit_in_full_row = Math.floor(width / imageWidth)
        const items_in_last_row = num_of_items % num_of_medias_fit_in_full_row;
        let num_of_scrolls_left_available: number;
        if (items_in_last_row !== 0) {
            num_of_scrolls_left_available = Math.floor(num_of_items / num_of_medias_fit_in_full_row)
        }
        else { num_of_scrolls_left_available = Math.floor(num_of_items / num_of_medias_fit_in_full_row) - 1 }
        const num_of_scrolls_right_available = 0
        this.setState({ num_of_scrolls_left_available, num_of_scrolls_right_available, width_in_pixels_of_last_overflowing_item_in_row: this.getItemMargin(width, imageWidth) })
    }

    private handleScrollLeftClicked = () => {
        const { width_in_pixels_of_last_overflowing_item_in_row, num_of_scrolls_left_available, num_of_scrolls_right_available, rightOffset } = this.state
        const { itemList, width, imageWidth, imageHeight } = this.props
        const single_media_item_width = imageWidth + width_in_pixels_of_last_overflowing_item_in_row

        if (num_of_scrolls_left_available === 1) {
            const num_of_medias_fit_in_full_row = Math.floor(width / imageWidth)
            const rightOffset = (single_media_item_width * (itemList.length - num_of_medias_fit_in_full_row)) - width_in_pixels_of_last_overflowing_item_in_row
            this.setState({
                rightOffset: - rightOffset - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })

            return null

        }
        if (num_of_scrolls_left_available > 0) {
            this.setState({
                rightOffset: rightOffset - width - width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available - 1 !== 0 ? num_of_scrolls_left_available - 1 : 0,
                num_of_scrolls_right_available: num_of_scrolls_right_available + 1
            })
        }
    }

    private handleScrollRightClicked = () => {
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, width_in_pixels_of_last_overflowing_item_in_row, rightOffset } = this.state
        const { width } = this.props
        if (num_of_scrolls_right_available === 1) {
            this.setState({
                rightOffset: 0,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: 0
            })
            return null
        }
        if (num_of_scrolls_right_available > 0) {
            this.setState({
                rightOffset: rightOffset + width + width_in_pixels_of_last_overflowing_item_in_row,
                num_of_scrolls_left_available: num_of_scrolls_left_available + 1,
                num_of_scrolls_right_available: num_of_scrolls_right_available - 1 > 0 ? num_of_scrolls_right_available - 1 : 0
            })
        }
    }
    public componentWillReceiveProps(nextProps) {
        const { itemList, imageWidth, width } = nextProps

        if (nextProps.itemList.length > 0) {
            this.setupLisScrolling(itemList, width, imageWidth)
        }
    }

    public render() {
        const { width, imageWidth, itemList, imageHeight, videoSettings, isDoubleTab, isLtr, 
            fieldsDisplaySettings, titleColor, subTitleColor, lastHeightSize, isGrid1280, ads_infrastructure_type } = this.props
        const { num_of_scrolls_right_available, num_of_scrolls_left_available, rightOffset } = this.state
        const itemWidth = this.getItemMargin(width, imageWidth) + imageWidth
        let isLeftArrowVisible = num_of_scrolls_left_available !== 0
        let isRightArrowVisible = num_of_scrolls_right_available !== 0
        const marginStyle = isLtr ? { marginLeft: rightOffset } : { marginRight: rightOffset }
        const dfpMarginStyle = isLtr ? { marginRight: this.getItemMargin(width, imageWidth) } : { marginLeft: this.getItemMargin(width, imageWidth) }
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const allMediaItems: SiteMediaData[] = itemList.map(item => { return item.media })
        let AutoIndex = 0;
        const scrollNextClass = isLtr ? "scrollRightBtn slotListArrow" : "scrollLeftBtn slotListArrow"
        const scrollPrevClass = isLtr ? "scrollLeftBtn slotListArrow" : "scrollRightBtn slotListArrow"
        return (<div className="slotsContent" style={{ height: lastHeightSize }}>
            {isRightArrowVisible && <button className={scrollPrevClass}
                onClick={(e) => this.handleScrollRightClicked()}
                title="scroll prev"
                aria-label="scroll prev" />}
            <div className="slotListWrapper" style={marginStyle} >
                {itemList.map((slot, index) => {
                    if (slot.isDFP) return <div id={slot.dfpId} key={index} className="slotView dfpId" style={dfpMarginStyle} >
                        {ads_infrastructure_type && ads_infrastructure_type === "new" && <script type="text/javascript" dangerouslySetInnerHTML={{ __html: googleScript(slot.dfpId) }}></script>}
                    </div>
                    else return (<MultiImagesFrontItem
                        key={index}
                        slot={slot}
                        index={index}
                        margin={this.getItemMargin(width, imageWidth)}
                        {...this.props} />)
                })}
            </div>
            {isLeftArrowVisible && <button className={scrollNextClass} title="scroll next" aria-label="scroll next" onClick={(e) => this.handleScrollLeftClicked()} />}
        </div>
        )
    }
}

