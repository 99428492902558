import { VideoMainPageMediaShare } from "./videoMainPageMediaShare";
import { SiteVideoData, SiteItemData } from "../../../../interfaces";
import { SiteVideoInline } from "../../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";

interface VideoPageMainProps {
    video: SiteItemData
    languageCode: string
    isMobileWeb: boolean
    domain: string
}

export class RadionasVideoPageMain extends React.Component<VideoPageMainProps, {}>{
    public static siteScriptName = "RadionasVideoPageMain";

    public render() {
        const { video, languageCode, isMobileWeb, domain } = this.props;
        const { media, categoryName } = video;
        const { dateCreated, credit, videoName, mediaId, aspectRatio } = media as SiteVideoData;
        const lang = languageCode.split("-")[0];
        const videoMainPageClass = `videoPageMain ${aspectRatio === "9:16" ? "vertical": ""}`;
        
        return (
            <div className={videoMainPageClass}>
                <div className="contentDiv">
                    {!isMobileWeb && <div className="videoPageMainCategory">{categoryName}</div>}
                    <div className="videoPageMainTitle">{videoName}</div>
                    <div className="videoPageMainTop">
                        <div className="videoPageMainDetails">
                            <span className="videoPageMainCredit">{credit}</span>
                            <span className="separator"></span>
                            <DateDisplay date={dateCreated} isArticle={true} />
                        </div>
                        <VideoMainPageMediaShare
                            categorySubChannel={"categorySubChannel"}
                            credit={credit}
                            articleId={mediaId}
                            showComments={false}
                            lang={lang}
                            isMobileWeb={isMobileWeb}
                            title={videoName}
                            domain={domain} />
                    </div>
                </div>
                <div className="videoWrapper">
                    <SiteVideoInline
                        data={media as SiteVideoData}
                        applyStickyness={true}
                        hideVideoAds={false}
                        isShareButton={true}
                        runArticleAutoPlayLogic={false} />
                </div>
            </div>

        )
    }
}
