require('./site_slotIconDisplay.less')
import { ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import * as classNames from "classnames"
import { injectPageComponentaContext } from "widgets/commonComponents/base/injectPageComponentaContext"
import { PageComponentaContext } from "widgets/interfaces"
import { ArticleData } from "../../../../articlePage/stateInterfaces"

interface SlotIconDisplayProps {
    icon: ComponentaIconType
    itemId: string
    iconClassName: string
    isIconVisible?: boolean
    durationShow?: boolean
    duration?: string
    dataTbHide?: boolean
}

@injectPageComponentaContext()
export class SlotIconDisplay extends React.Component<SlotIconDisplayProps & Partial<PageComponentaContext>, {}>{
    static defaultProps = { isIconVisible: true }
    public render() {
        const { icon, itemId, iconClassName, isIconVisible, durationShow, duration, dataTbHide } = this.props
        const getArticle = this.props.getArticle || ((itemId) => null);
        if (isIconVisible) return <SimpleSlotIconDisplay
            icon={calculateComponentaIconType(icon, getArticle(itemId))}
            iconClassName={iconClassName}
            durationShow={durationShow}
            duration={duration}
            dataTbHide={dataTbHide}
        />
        else return null
    }
}

export function calculateComponentaIconType(icon: ComponentaIconType, article: ArticleData): ComponentaIconType {
    if (icon === "automatic" && article && article.hasVideo) return "video"
    if (icon === "automatic" && article && article.specialData && article.specialData.audioData && article.specialData.audioData.durationSeconds) return "audio"
    return icon;
}

interface SimpleSlotIconDisplayProps {
    icon: ComponentaIconType
    iconClassName: string
    durationShow?: boolean
    duration?: string
    dataTbHide?: boolean
}

export class SimpleSlotIconDisplay extends React.Component<SimpleSlotIconDisplayProps, {}>{
    public render() {
        const { icon, iconClassName, durationShow, duration, dataTbHide } = this.props

        const video = classNames("videoIcon", iconClassName)
        const audio = classNames("audioIcon", iconClassName)
        if (icon === "video") return <div className={video} data-tb-hide={dataTbHide && ''} data-tb-video><span className="icon" />{durationShow && <span className="duration">{duration}</span>}</div>
        if (icon === "audio") return <div className={audio} data-tb-hide={dataTbHide && ''}><span className="icon" />{durationShow && <span className="duration">{duration}</span>}</div>
        else return null
    }
}

export class SpecialSlotIconDisplay extends React.Component<Partial<SimpleSlotIconDisplayProps>, {}>{
    public render() {
        const { icon } = this.props
        if (icon === "video") return <span className="recommendedVideoIcon" data-tb-video/>
        else return null

    }
}
