import { YnetPremiumLoginBase, YnetPremiumLoginBaseRendererProps } from "./ynetPremiumLoginBase";
import { LogoutPopupComponent } from '../../../../../../../../app/widgets/defenitions/ynetSearch/components/ynetPremiumLogin/LogoutPopupComponent'


export class YnetPremiumLoginDesktop extends React.Component<{ isArticlePage: boolean }, {}>{
    private handleOnClick() {
        window.YitPaywall.openLoginPopUp();
        window.dataLayer = window.dataLayer || []
        const label = this.props.isArticlePage ? 'Ynet+ Article - Header' : 'Lounge - Header'
        window.dataLayer.push({
            event: 'Login_Events',
            Category: 'Ynet+ - Login',
            Action: 'Login Source',
            Label: `${label}`
        });
    }

    public render() {
        return <YnetPremiumLoginBase renderer={({ user, isMenuVisible, isLogoutPopupOpen, closeLogoutPopup, logOutFromPopUp, toggleMenu, handleOnLogoutMenuClicked,
            handleOnManageAccountClicked, handleOnArticlesClicked, handleOnContactUsClicked, }: YnetPremiumLoginBaseRendererProps) => (
            <div className="YnetArticlePremiumLogin">
                {!user &&
                    <a className="login_premium"
                        onClick={() => this.handleOnClick()}>
                        <div className="userIcon" />
                        <span className="label">התחברות</span>
                    </a>}

                {user &&
                    <div className="loggedUser" onClick={toggleMenu}>
                        <div className="userIcon" />
                        <span className="welcomeUserLabel"> {`שלום ${user.props.firstName}`}</span>
                        <div className="menuTogglerWrapper"  >
                            <div className="menuToggler" />
                        </div>
                    </div>
                }

                {isMenuVisible &&
                    <div className="menu">
                        <div className="menuTogglerUpsideDown" />
                        <div className="menuOption" onClick={handleOnArticlesClicked} id="mainPageLink">כתבות +ynet</div>
                        <div className="menuOption" onClick={handleOnManageAccountClicked} id="manageAccount">ניהול חשבון</div>
                        <div className="menuOption" onClick={handleOnContactUsClicked} id="contactUsLink">שירות לקוחות</div>
                    <div className="menuOption" onClick={handleOnLogoutMenuClicked} id="logoutDesktop">התנתקות</div>
                    </div>}

                {(isLogoutPopupOpen && user) && <LogoutPopupComponent logOutFromPopUp={logOutFromPopUp} closeLogoutPopupOpen={closeLogoutPopup} />}

            </div>
        )} />

    }
}
