
type DateFormat = "shortYear" | ""

export const getHourAndMinutes = (date: Date) => {
    return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
}

export const getFullDate = (date: Date, devideUnitChar: string = ".", format: DateFormat = "") => {
    switch (format) {
        case "shortYear":
            return `${date.getDate()}${devideUnitChar}${date.getMonth() + 1}${devideUnitChar}${`${date.getFullYear()}`.slice(2)}`
            break;

        default:
            return `${date.getDate()}${devideUnitChar}${date.getMonth() + 1}${devideUnitChar}${date.getFullYear()}`
    }

}

export const isSameDate = (date1: Date, date2: Date) => {
    return (getFullDate(date1).localeCompare(getFullDate(date2))) === 0
}

export const scrollToElement = (id: string, block: ScrollLogicalPosition = "start", inline: ScrollLogicalPosition = "nearest") => {
    const el = document.querySelector(`.${id}`)
    el && el.scrollIntoView({ behavior: "smooth", block: block, inline: inline })
}

export const isToday = (date: Date) => {
    const today = new Date()
    return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
}

export const isYesterday = (date: Date) => {
    const yesterday = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24); // love was such an easy game to play
    return date.getDate() == yesterday.getDate() &&
        date.getMonth() == yesterday.getMonth() &&
        date.getFullYear() == yesterday.getFullYear()
}

export const isXMinutesOld = (date: Date, minutes: number) => {
    if (!isToday(date)) return true;
    const today = new Date();
    let diffMs = (date.getTime() - today.getTime()); // milliseconds between now & date  
    let diffMins = Math.abs(Math.round(((diffMs % 86400000) % 3600000) / 60000)); // minutes

    return diffMins >= minutes
}