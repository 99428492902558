import * as classNames from "classnames"
import { separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { ArticleCommentRenderProps } from "../articleCommentWrapper";



interface ArticleCommenUI1280Props {
    props: ArticleCommentRenderProps
}

export class ArticleCommentAppUI extends React.Component<ArticleCommenUI1280Props, {}>{
    render() {
        const { lang, isExpanded, vote, talkback_like, likes, unlikes, toggleOpenedState, commentFormType, articleId,
            handleVoteButtonsClick,
            comment: {
                id,
                number,
                title,
                text,
                author,
                pubDate,
                level,
                recommended,
                talkback_source,
            } } = this.props.props

        const articlePublishDate = separateHourFromDateAndFormatToString(pubDate, { showHour: true, dateTimeSeperator: " | " })
        const expandedCommentClasses = classNames({
            "ArticleCommentApp": true,
            "expanded": isExpanded,
            "level1": level === 1,
            "level2": level === 2,
        })



        const commentTitleClasses = classNames({
            "commentTitle": true,
            "redTitleText": recommended,
        })
        const commentTextStyles = classNames({
            "commentText": true,
            "level1": level === 1,
            "level2": level === 2,
        })
        const extendedBottomWrapperStyles = classNames({
            "bottomButtonsWrapper": true,
            "level1": level === 1,
            "level2": level === 2,
        })
        const commentNumClasses = classNames({
            "commentNum": true,
           // "redTitleText": recommended,
        })
        const positiveLikes = likes < 0 ? 0 : likes
        const positiveUnlikes = unlikes < 0 ? 0 : unlikes
        return (
            <div id="ArticleCommentApp" className={expandedCommentClasses}>
                <div className="topView" >

                    <div className="topLeftView">

                        <div className="commentDetailsWrapper">
                            {level === 1 && <div className={`num-pf ${commentNumClasses}`}>{number}.</div>}
                            {level === 2 && <div className="level2ArrowContainer"><span className="level2Arrow-pf" /></div>}
                            <div className={`title-pf ${commentTitleClasses}`} onClick={toggleOpenedState}>   <span>{title}</span>
                                {text.length === 0 && <span>{"(לת)"}</span>}
                            </div>

                        </div>
                        {isExpanded && text.length > 0 && <div className={`text-pf ${commentTextStyles}`}>{text}</div>}

                        <div className="details-pf">
                            <div>
                                {author !== "" ? <span className="author">{`${author}`}</span> : null}
                                {author !== "" && <span className="span-pf">|</span>}
                                <span className="publishDate date-pf"> {`${articlePublishDate}${talkback_source ? " , " + talkback_source : ""}`}</span>

                            </div>
                            <a className="commentToComent-pf" title="הגיבו לתגובה"
                                    href={`add_new_comment_to_commment/${id}`}>
                                    <span>הגיבו לתגובה</span>
                            </a>
                        </div>

                    </div>
                </div>





            </div>)
    }
}