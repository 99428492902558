require("./yplus_site_SiteYplusSearchResultsWidgetComponenta.less")
require("./../../yPlusLoadMore/components/yplus_site_loadMore.less")
require("./../../yPlusLoadMore/components/yplus_site_loadMore-mobile.less")
import * as axios from "axios"
import * as filter from "lodash/filter"
import * as map from "lodash/map"
import { ArticleExtraData } from "../../../../articlePage/stateInterfaces"
import { getQueryStringValue } from "../../../widgetsUtils";
import { YPlusLoadMoreList } from "../../yPlusLoadMore/components/yplusLoadMoreList"
import { SiteYPlusLoadMoreData } from "../../yPlusLoadMore/components/YplusLoadMoreView"
import { ImageDetails } from "../../../../images/interfaces"
import { Loader } from "../../yPlusLoadMore/components/loader/loader"
import { SiteSearchInput } from "./SiteSearchInput"


const SERVER_SEARCH_URL = '/api/premium/articles_search/';

interface YplusArticleSearchArticleEntity extends Partial<ArticleExtraData> {
    articleId: string
    author: string
    credit: string
    categoryName: string
    title: string
    subTitle: string
    promotionImageDetails: ImageDetails
    hasVideo: boolean,
    publishedLink: string
}

interface SiteYplusSearchResultsWidgetComponentaState {
    articles: YplusArticleSearchArticleEntity[]
    page: number
    showLoader: boolean
    searchValueForLoadMore: string
    showNoResultsMessage: boolean
    hasMore: boolean
}

export class SiteYplusSearchResultsWidgetComponenta extends React.Component<{ ver?: number }, SiteYplusSearchResultsWidgetComponentaState>{
    public static siteScriptName = "SiteYplusSearchResultsWidgetComponenta"

    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            page: 0,
            showLoader: false,
            searchValueForLoadMore: "",
            showNoResultsMessage: false,
            hasMore: true
        }
    }

    componentDidMount() {
        this.setState({ searchValueForLoadMore: getQueryStringValue("q") })
    }

    private getApiUrl = () => {
        if (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL) {
            return window.AWS_PLUS_SEARCH_URL;
        }
        return SERVER_SEARCH_URL;
    }

    private fetchMoreArticles = (searchValue: string): void => {
        const { page, articles } = this.state
        if (searchValue.length === 0) { return null }
        this.setState({ showLoader: true })
        axios.post(this.getApiUrl(), { page, query: searchValue.toLowerCase() })
            .then((res: any) => {
                const results = res.data.results
                const hasMore = res.data.hasMore
                this.setState((pState) =>
                    ({
                        showLoader: false,
                        hasMore,
                        articles: [...articles, ...results],
                        page: results.length > 0 ? pState.page + 1 : pState.page,
                        showNoResultsMessage: results.length === 0 && pState.page === 0
                    }))
            })
            .catch(err => {
                this.setState({ showLoader: false })
            })
    }

    private resetSearch = (): void => {
        this.setState({
            articles: [],
            page: 0,
            showLoader: false,
            searchValueForLoadMore: ""
        })
    }

    private updateSearchValueForLoadMore = (searchValueForLoadMore) => {
        this.setState({ searchValueForLoadMore })
    }

    render() {
        const { articles, showLoader, searchValueForLoadMore, showNoResultsMessage, hasMore } = this.state
        const { ver } = this.props
        const showLoadMoreBtn = !showLoader && hasMore && searchValueForLoadMore
        let itemList: SiteYPlusLoadMoreData[] = [];
        if (Object.keys(articles).length > 0) {
            itemList = filter(map(articles, (article: YplusArticleSearchArticleEntity): SiteYPlusLoadMoreData =>
                ({
                    articleId: article.articleId,
                    author: article.author,
                    credit: article.credit,
                    categoryName: article.categoryName,
                    title: article.title,
                    subTitle: article.subTitle,
                    promotionImageDetails: article.promotionImageDetails,
                    hasVideo: article.hasVideo || false,
                    publishedLink: article.publishedLink,
                    link: ""
                })
            ))
        }
        const verClass = ver && ver > 1 ? "ver2" : ""
        return (
            <div className={`SiteYplusSearchResultsWidgetComponenta ${verClass}`}>
                <div id="mainLabel"><span>תוצאות חיפוש </span></div>
                <SiteSearchInput
                    onResetClickCallback={this.resetSearch}
                    fetchMoreArticles={this.fetchMoreArticles}
                />

                {!showNoResultsMessage && <div id="yplusArticlesList">
                    <YPlusLoadMoreList
                        dataLength={articles.length}
                        itemList={itemList}
                        height={400}
                        isCategoryVisible={true} />
                </div>}

                {showNoResultsMessage && <span id="noResultsMessage">לא נמצאו תוצאות עבור חיפוש זה</span>}

                {!showNoResultsMessage && <div className="ButtonAndLoaderContainer">
                    {showLoader && <Loader />}
                    {showLoadMoreBtn && <div className="loadMoreArticlesButton" onClick={() => this.fetchMoreArticles(searchValueForLoadMore)} >טען עוד כתבות</div>}
                </div>}
            </div >
        )
    }
}