import { openFacebookShare, openTwitterShare, openMailShare, analyticsPushFunc, openWhatsAppShare } from "../../../widgetsUtils"
import { isWcmEditor } from "config/serverConfig"


interface CalcalistMutamShareMenuState {
    isButtonsVisible: boolean
}
interface shareMenuProps {
    url: string
    title?: string
}

export class CalcalistMutamShareMenu extends React.Component<shareMenuProps, CalcalistMutamShareMenuState>{
    public static siteScriptName = "CalcalistMutamShareMenu";
    constructor(props: shareMenuProps) {
        super(props)
        this.state = {
            isButtonsVisible: false,

        }
    }
    private toggleMenu = () => {
        this.setState({
            isButtonsVisible: !this.state.isButtonsVisible
        })
    }
    render() {
        const { isButtonsVisible } = this.state
        const { url, title } = this.props
        const isVisible = isButtonsVisible ? "opened" : "";
        return (
            <div className="CalcalistMutamShareMenu">
                <div className={`shareBtnsBox-pf ${isVisible}`}>
                    <button onClick={() => !isWcmEditor() ? openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare-pf fb" />
                    <button onClick={() => !isWcmEditor() ? openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare-pf tw" />
                   <button onClick={() => !isWcmEditor() ? openMailShare(title, "כלכליסט", url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare-pf mail" />
                    <button onClick={() => !isWcmEditor() ? openWhatsAppShare(url, () => analyticsPushFunc("Whatsapp", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare-pf wa" />
                </div>
                <button className={`toggleShareButtons-pf ${isVisible}`} onClick={this.toggleMenu} />
            </div>
        )
    }

}

