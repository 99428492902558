export class PopUp extends React.Component<{ onClose: () => void, loading: boolean }, {}> {
    public render() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="redMailPopUp">
                    <div className="loading"></div>
                    <p className="title">لحظات وننتهي...</p>
                </div>
            )
        } else {
            return (
                <div className="redMailPopUp">
                    <div className="finish"></div>
                    <p className="title"> تم تلقي المعلومات وارسالها للفحص</p>
                    <p className="subTitle">شكرا على المشاركة</p>
                    <button className="popUpButton"><a href="/">الرجوع لصفحة البيت</a></button>
                </div>
            )
        }
    }

}



